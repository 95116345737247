import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchTurnServerCreds } from "../../store/actions/turnserver";
import { fetchTestRequest } from "../../store/actions/test";
import { fetchTestProctorAssign } from "../../services/test.service";
import { fetchMyProfile } from "../../store/actions/user";
import { toggleSidebar } from "../../store/actions/sidebar";
import VideoMonitor from "./VideoMonitor";

const SuperProctorVideoMonitoring = () => {
  const { id: testId, proctor: proctorId } = useParams();
  const dispatch = useDispatch();

  // Redux States Values
  const { opened: sideBarOpened } = useSelector((state) => state.sidebar);

  // Local State Varible
  const [proctors, setProctors] = useState([]);

  useEffect(() => {
    if (sideBarOpened) dispatch(toggleSidebar());

    dispatch(fetchTurnServerCreds());
    dispatch(fetchMyProfile());
    dispatch(fetchTestRequest(testId));
    fetchTestProctorAssign(testId)
      .then((res) => setProctors(res))
      .catch((err) => {
        throw err;
      });
  }, []);

  return (
    <div className="p-2  h-[94vh] flex flex-col">
      <div className="w-full bg-red-200 p-3 flex">
        <h3 className="text-lg w-1/3 text-left flex items-center pl-2 font-semibold">
          Select any proctor from dropdown:
        </h3>

        <select
          onChange={(e) => {
            if (e.target.value === "Select") {
              return window.open(
                `${window.location.protocol}//${window.location.host}/tests/superproctor/${testId}`,
                "_self"
              );
            }
            window.open(
              `${window.location.protocol}//${window.location.host}/tests/superproctor/${testId}/${e.target.value}?page=1`,
              "_self"
            );
          }}
          className={"w-1/3 px-4 py-2 rounded-md"}
        >
          <option className="border border-blue-400 p-2 pl-4 mt-2 overflow-x-scroll whitespace-nowrap cursor-pointer">
            Select
          </option>
          {proctors.map((e) => {
            return (
              <>
                <option
                  className="border border-blue-400 p-2 pl-4 mt-2 overflow-x-scroll whitespace-nowrap cursor-pointer"
                  selected={proctorId === e._id}
                  value={e._id}
                >
                  {e.name.toUpperCase()} ({e.email})
                </option>
              </>
            );
          })}
        </select>
      </div>
      {proctorId ? (
        <VideoMonitor type="sp" />
      ) : (
        <div className="flex items-center justify-center text-2xl h-full">
          Select any proctor from dropdown to view students
        </div>
      )}
    </div>
  );
};

export default SuperProctorVideoMonitoring;
