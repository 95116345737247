import {
  UPDATE_TEST_RESPONSE,
  FETCH_TEST_REQUESTED,
  FETCH_TEST_SUCCESS,
  FETCH_TEST_FAILURE,
  UPDATE_TEST_LOADING,
  UPDATE_TEST_STARTER_CARD_ACTIVE,
  SUBMIT_TEST_REQUESTED,
  SUBMIT_TEST_SUCCESS,
  SUBMIT_TEST_FAILURE,
  UPDATE_TEST_ACCORDIAN_INDEX,
  UPDATE_TEST_ACCORDIAN_PAGE_INDEX,
  UPDATE_TEST_SECTION_SUBMIT_MODAL_ACTIVE,
  UPDATE_TEST_SUBMITTED_SECTIONS_COUNT,
  UPDATE_TEST_MAIN_DATA_WITH_KEY,
  UPDATE_TEST_DELETE_MODAL_ACTIVE,
  DELETE_TEST_REQUESTED,
  DELETE_TEST_FAILURE,
  UPDATE_CODE_RESPONSE,
  DELETE_TEST_SUCCESS,
  UPDATE_TEST_SECTION_DATA_WITH_KEY,
  DELETE_TEST_SECTION,
  DUPLICATE_TEST_SECTION,
  ADD_TEST_SECTION,
  UPDATE_TEST_QUESTION_DATA_WITH_KEY,
  ADD_TEST_QUESTION,
  DELETE_TEST_QUESTION,
  ASSIGN_TEST_SUCCESS,
  ASSIGN_TEST_FAILURE,
  ASSIGN_TEST_PROCTOR_SUCCESS,
  UPDATE_USER_BANDWIDTH,
  DUPLICATE_TEST_QUESTION,
  SET_USER_SCREENSTREAM,
  SET_USER_VIDEOSTREAM,
  IS_VIDEO,
  IS_AUDIO,
  IS_SCREEN,
  FETCH_ALL_TESTS_SUCCESS,
  FETCH_STUDENT_RESPONSE_SUCCESS,
  FETCH_QUIZ_PROCTORING_DETAILS_SUCCESS,
  UPDATE_CURRENT_QUESTION_INDEX,
  UPDATE_CURRENT_SECTION_INDEX,
  FETCH_TEST_AUTO_SUCCESS,
  FETCH_TEST_AUTO_FAILURE,
  SET_TEST_STATUS,
  FETCH_QUIZ_PROCTORING_DETAILS_FAILURE,
  FETCH_STUDENT_RESPONSE_FAILURE,
  FETCH_ALL_TESTS_FAILURE,
  FETCH_TEST_SCORE_FAILURE,
  FETCH_TEST_SCORE_SUCCESS,
  DELETE_RESPONSE_REQUESTED,
  DELETE_RESPONSE_SUCCESS,
  DELETE_RESPONSE_FAILURE,
  FETCH_COMPANIES_SUCCESS,
  FETCH_TEST_ASSIGN_REQUESTED,
  FETCH_TEST_ASSIGN_SUCCESS,
  FETCH_TEST_ASSIGN_FAILURE,
  UPDATE_TEST_ASSIGN,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAILURE,
  SEND_EMAILS_FAILURE,
  SEND_EMAILS_SUCCESS,
  REARRANGE_ALL_TESTS,
  FETCH_ALL_GROUP_DETAILS_REQUESTED,
  FETCH_ALL_GROUP_DETAILS_SUCCESS,
  ADD_GROUP_DETAILS_SUCCESS,
  ADD_GROUP_DETAILS_REQUESTED,
  DELETE_GROUP_DETAILS_REQUESTED,
  DELETE_GROUP_DETAILS_SUCCESS,
  UPDATE_GROUP_DETAILS_SUCCESS,
  UPDATE_GROUP_DETAILS_REQUESTED,
  SET_SUBMISSION_RESPONSE,
  TOGGLE_RIGHT_SIDEBAR,
  UPDATE_PUBLIC_TEST_MODAL,
  UPDATE_PUBLIC_TEST_REGISTERED_DETAILS,
  UPDATE_TEST_DATA,
  FETCH_STUDENT_RESPONSE_FROM_REDIS,
  FETCH_STUDENT_RESPONSE_FAILURE_FROM_REDIS,
  FETCH_STUDENT_RESPONSE_SUCCESS_FROM_REDIS,
  FETCH_USER_TABLES_REQUESTED,
  FETCH_USER_TABLES_FAILURE,
  FETCH_USER_TABLES_SUCCESS,
  CREATE_STUDENT_SCHEMA,
  UPDATE_QUESTION_BANK_MODAL,
  UPDATE_QUESTION_FROM_QUESTIONBANK,
  ASSIGN_TEST_REQUESTED,
  ASSIGN_TEST_PROCTOR_REQUESTED,
  ASSIGN_TEST_PROCTOR_FAILURE,
  UPDATE_AUDIO_RECORDING_STATUS,
  UPDATE_YTVIDEO_RECORDING_STATUS,
  UPDATE_TEST_DATA_ON_ARRANGE,
  UPDATE_TEST_END_TIME_SUCCESS,
  UPDATE_TEST_END_TIME_FAILURE,
  UPDATE_TEST_END_TIME_REQUESTED,
  CLONE_TEST_SUCCESS,
} from "../actionTypes";

import {
  shuffleTest,
  initialTestData,
  initialTestSectionData,
  initialTestQuestionData,
} from "../../components/tests/utils";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../assets/js/toast_config";
import {
  DELETE_INTERVIEW_REQUESTED,
  DELETE_INTERVIEW_SUCCESS,
} from "../actionTypes/interviewpanel";

const initialState = {
  test: initialTestData(),
  submission_response: {},
  assign: [],
  proctor_assign: [],
  testAuto: {},
  companies: [],
  allTests: { upcoming: {}, ongoing: {}, ended: {}, all: [] },
  response: {},
  successMessage: "",
  errorMessage: "",
  codeResponse: {},
  testScoreDetails: {},
  proctoringDetails: {},
  loading: false,
  submitLoading: false,
  cardNumber: 2,
  isTestStarted: false,
  currentBandWidth: 0,
  screenStream: null,
  videoStream: null,
  isVideo: false,
  isAudio: false,
  isScreen: false,
  startCardActive: true,
  currentQuestionIndex: 0,
  currentSectionIndex: 0,
  currentAccordianIndex: 0,
  currentAccordianPageIndex: 0,
  submitSectionModalActive: false,
  submittedSectionsCount: 0,
  deleteTestModalActive: false,
  loadingDelete: false,
  isSubmitted: false,
  feedbackMessage: "",
  allTestGroups: [],
  isRightSidebar: false,
  public_test_modal: false,
  public_test_registered_data: {},
  quizAssignDetails: {},
  tables: [],
  fetchTablesFailure: false,
  questionbank_modal: false,
  questions_used: [],
  completedPreparationTime: false,
  startPreparationTime: false,
  isAnswerTimeCompleted: false,
  YTRecordingStatus: null,
  isEndTimeUpdateApiCalled: false,
  assignTestLoading: false,
  deleteTestLoading:false
};

const testReducer = function (state = initialState, action) {
  const { type } = action;
  let newTest = JSON.parse(JSON.stringify(state.test));
  let _components = [...newTest?.components];
  switch (type) {
    case FETCH_TEST_ASSIGN_REQUESTED:
      return {
        ...state,
        assign: [],
      };

    case UPDATE_PUBLIC_TEST_REGISTERED_DETAILS:
      return {
        ...state,
        public_test_registered_data: action.payload,
        public_test_modal: false,
      };

    case UPDATE_PUBLIC_TEST_MODAL:
      return {
        ...state,
        public_test_modal: action.payload,
      };

    case SET_SUBMISSION_RESPONSE:
      // console.log("reducer", action.payload);
      return {
        ...state,
        submission_response: { ...action.payload.response },
      };

    case FETCH_TEST_ASSIGN_SUCCESS:
      return {
        ...state,
        [action.payload.key]: action.payload.data,
      };

    case FETCH_ALL_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        allTestGroups: [...action.payload],
      };

    case UPDATE_GROUP_DETAILS_SUCCESS:
      const modifiedArray = [...state.allTestGroups];
      //finding the index of the company to update
      const itemIndex = modifiedArray.findIndex(
        (group) => group._id === action.payload.id
      );

      //updating the index
      if (itemIndex > -1) {
        modifiedArray[itemIndex] = action.payload.response;
      }
      return {
        ...state,
        allTestGroups: [...modifiedArray],
        loading: false,
      };

    case UPDATE_GROUP_DETAILS_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case ADD_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        allTestGroups: [...state.allTestGroups, action.payload],
        loading: false,
      };

    case ADD_GROUP_DETAILS_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackMessage: action.payload.msg,
      };

    case SEND_EMAILS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload.msg,
      };

    case SEND_EMAILS_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.msg,
      };

    case SUBMIT_FEEDBACK_FAILURE:
      return {
        ...state,
        feedbackMessage: action.payload.msg,
        error: true,
      };

    case UPDATE_TEST_ASSIGN:
      return {
        ...state,
        [action.payload.key]: [...action.payload.data],
      };

    case UPDATE_TEST_RESPONSE:
      const new_response = { ...state.response };
      if (!new_response[action.payload.sectionIndex]) {
        new_response[action.payload.sectionIndex] = {};
      }
      // if (action.payload.value) {
      new_response[action.payload.sectionIndex][action.payload.questionIndex] =
        action.payload.value;
      // }

      return {
        ...state,
        response: new_response,
      };

    case UPDATE_CODE_RESPONSE:
      const new_codeResponse = JSON.parse(JSON.stringify(state.codeResponse));
      if (!new_codeResponse[action.payload.sectionIndex]) {
        new_codeResponse[action.payload.sectionIndex] = {};
      }
      new_codeResponse[action.payload.sectionIndex][
        action.payload.questionIndex
      ] = action.payload.value;
      // localStorage.setItem("code", JSON.stringify(new_codeResponse));
      return {
        ...state,
        codeResponse: new_codeResponse,
      };

    case FETCH_STUDENT_RESPONSE_SUCCESS:
      if (action.payload.length) {
        return {
          ...state,
          response: null,
          loading: false,
          quizAssignDetails: action.payload[0],
        };
      }
      return {
        ...state,
      };

    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: [...action.payload],
      };

    case FETCH_TEST_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case FETCH_TEST_SCORE_FAILURE:
      // console.log(a)
      return {
        ...state,
        errorMessage: action.payload.msg,
      };

    case FETCH_TEST_SCORE_SUCCESS:
      return {
        ...state,
        testScoreDetails: { ...action.payload },
      };

    case FETCH_ALL_TESTS_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload.msg,
      };
    }

    case FETCH_STUDENT_RESPONSE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.msg,
      };

    case FETCH_STUDENT_RESPONSE_FROM_REDIS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_STUDENT_RESPONSE_FAILURE_FROM_REDIS:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.msg,
      };
    case FETCH_STUDENT_RESPONSE_SUCCESS_FROM_REDIS:
      return {
        ...state,
        loading: false,
        response: action?.payload?.response,
        quizAssignDetails: action?.payload?.quiz_assign,
      };

    case FETCH_QUIZ_PROCTORING_DETAILS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload.msg,
      };

    case FETCH_TEST_AUTO_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };

    case SET_TEST_STATUS:
      return {
        ...state,
        isTestStarted: action.payload,
      };

    case FETCH_TEST_AUTO_SUCCESS:
      if (action.payload.payload?.allow_flexible_exam_time) {
        if (
          action.payload?.data.quiz_assign?.end_time !==
          state?.quizAssignDetails?.end_time
        ) {
          return {
            ...state,
            quizAssignDetails: {
              ...state.quizAssignDetails,
              end_time: action.payload?.data.quiz_assign?.end_time,
            },
          };
        }
      } else if (action.payload.data.end_duration !== state.test.end_duration) {
        return {
          ...state,
          test: {
            ...state.test,
            end_duration: action.payload.data.end_duration,
          },
        };
      }
      return {
        ...state,
      };

    case FETCH_QUIZ_PROCTORING_DETAILS_SUCCESS:
      return {
        ...state,
        proctoringDetails: { ...action.payload },
      };

    case UPDATE_CURRENT_QUESTION_INDEX:
      return {
        ...state,
        currentQuestionIndex: action.payload,
      };

    case UPDATE_CURRENT_SECTION_INDEX:
      return {
        ...state,
        currentSectionIndex: action.payload,
      };

    case REARRANGE_ALL_TESTS:
      let currentOngoing = [
        ...state.allTests.ongoing,
        state.allTests.upcoming[action.payload.index],
      ];
      let currentUpcoming = state.allTests.upcoming.filter(
        (item, index) => index !== action.payload.index
      );
      return {
        ...state,
        allTests: {
          ...state.allTests,
          upcoming: [...currentUpcoming],
          ongoing: [...currentOngoing],
        },
      };

    case FETCH_ALL_TESTS_SUCCESS:
      return {
        ...state,
        allTests: {
          ...state.allTests,
          [action.payload.filter.quiz_type]: action.payload.data,
        },
      };

    case SET_USER_SCREENSTREAM:
      return {
        ...state,
        screenStream: action.payload,
      };

    case SET_USER_VIDEOSTREAM:
      return {
        ...state,
        videoStream: action.payload,
      };
    case IS_VIDEO:
      return {
        ...state,
        isVideo: action.payload,
      };
    case IS_AUDIO:
      return {
        ...state,
        isAudio: action.payload,
      };
    case IS_SCREEN:
      return {
        ...state,
        isScreen: action.payload,
      };

    case UPDATE_USER_BANDWIDTH:
      return {
        ...state,
        currentBandWidth: action.payload,
      };

    case FETCH_TEST_SUCCESS:
      let test_Ids = [];
      action.payload.data.components.map((section) => {
        section.components.map((question) => {
          test_Ids.push(question._id);
        });
      });
      let test =
        action.payload.options && action.payload.options.shuffle
          ? shuffleTest(action.payload.data)
          : action.payload.data;
      let newTests = test.components.map((section) => {
        return {
          ...section,
          isOpen: false,
        };
      });
      return {
        ...state,
        questions_used: [...test_Ids],
        test: { ...newTest, ...test, components: newTests },
        loading: false,
      };

    case UPDATE_TEST_DATA:
      let newUpdatedComponent = [...newTest.components];
      newUpdatedComponent[action.payload].isOpen =
        !newTest.components[action.payload].isOpen;
      return {
        ...state,
        test: { ...newTest, components: newUpdatedComponent },
      };

    case UPDATE_TEST_DATA_ON_ARRANGE:
      return {
        ...state,
        test: { ...action.payload },
      };

    case FETCH_TEST_FAILURE:
      return {
        ...state,
        isSubmitted: true,
        loading: false,
      };

    case UPDATE_TEST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case UPDATE_TEST_STARTER_CARD_ACTIVE:
      if (action.payload === 6) {
        return {
          ...state,
          startCardActive: false,
        };
      } else if (action.payload === 7) {
        return {
          ...state,
          startCardActive: true,
          cardNumber: 2,
        };
      } else {
        console.log("checking reducer", action.payload);
        return {
          ...state,
          // startCardActive: action.payload,
          cardNumber: action.payload,
        };
      }
    case SUBMIT_TEST_REQUESTED:
      return {
        ...state,
        submitLoading: action.payload.isSubmit,
      };

    // case SUBMIT_TEST_SUCCESS:
    //   return {
    //     ...state,
    //     submitLoading: false,
    //   };

    case UPDATE_TEST_ACCORDIAN_INDEX:
      return {
        ...state,
        currentAccordianIndex: action.payload,
        // currentAccordianPageIndex: 0,
      };

    case UPDATE_TEST_ACCORDIAN_PAGE_INDEX:
      return {
        ...state,
        currentAccordianPageIndex: action.payload,
      };

    case UPDATE_TEST_SECTION_SUBMIT_MODAL_ACTIVE:
      return {
        ...state,
        submitSectionModalActive: action.payload,
      };

    case UPDATE_TEST_SUBMITTED_SECTIONS_COUNT:
      return {
        ...state,
        submittedSectionsCount: action.payload,
      };

    case UPDATE_TEST_MAIN_DATA_WITH_KEY:
      if (newTest) {
        let key = action.payload.key;
        let value = action.payload.value;
        newTest = { ...state.test, [key]: value };
      }
      return {
        ...state,
        test: newTest,
      };

    case UPDATE_TEST_DELETE_MODAL_ACTIVE:
      return {
        ...state,
        deleteTestModalActive: action.payload,
      };

    case DELETE_TEST_REQUESTED:
      return {
        ...state,
        deleteTestLoading: true,
      };

    case DELETE_INTERVIEW_REQUESTED:
      return {
        ...state,
        loadingDelete: true,
      };

    case DELETE_INTERVIEW_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
      };

    case DELETE_TEST_FAILURE:
      return {
        ...state,
        deleteTestLoading:false
      };

    case DELETE_TEST_SUCCESS:
      return {
        ...state,
        deleteTestLoading: false,
      };

    case DELETE_RESPONSE_REQUESTED:
      return {
        ...state,
        loadingDelete: true,
      };

    case DELETE_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
      };

    case DELETE_GROUP_DETAILS_REQUESTED:
      return {
        ...state,
        loadingDelete: true,
      };

    case DELETE_RESPONSE_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
      };

    case DELETE_RESPONSE_FAILURE:
      return {
        ...state,
        loadingDelete: false,
        errorMessage: action.payload.msg,
      };

    case UPDATE_TEST_SECTION_DATA_WITH_KEY:
      return {
        ...state,
        test: {
          ...newTest,
          components: Object.assign([...newTest.components], {
            [action.payload.sectionIndex]: {
              ...newTest.components[action.payload.sectionIndex],
              [action.payload.key]: action.payload.value,
            },
          }),
        },
      };

    case DELETE_TEST_SECTION:
      _components = [...newTest.components];
      _components.splice(action.payload.sectionIndex, 1);
      return {
        ...state,
        test: {
          ...newTest,
          components: _components,
        },
      };

    case DUPLICATE_TEST_SECTION:
      _components = [...newTest.components];
      _components.push(
        JSON.parse(
          JSON.stringify(newTest.components[action.payload.sectionIndex])
        )
      );
      return {
        ...state,
        test: {
          ...newTest,
          components: _components,
        },
      };

    case ADD_TEST_SECTION:
      return {
        ...state,
        test: {
          ...newTest,
          components: [...newTest.components, initialTestSectionData()],
        },
      };

    case UPDATE_TEST_QUESTION_DATA_WITH_KEY:
      return {
        ...state,
        test: {
          ...newTest,
          components: Object.assign([...newTest.components], {
            [action.payload.sectionIndex]: {
              ...newTest.components[action.payload.sectionIndex],
              components: Object.assign(
                [...newTest.components[action.payload.sectionIndex].components],
                {
                  [action.payload.questionIndex]: {
                    ...newTest.components[action.payload.sectionIndex]
                      .components[action.payload.questionIndex],
                    [action.payload.key]: action.payload.value,
                  },
                }
              ),
            },
          }),
        },
      };

    case ADD_TEST_QUESTION:
      let add_test_question_type = "description";
      // if (_components[action.payload.sectionIndex].section_type === "G") {
      //   add_test_question_type = "description";
      // } else if (
      //   _components[action.payload.sectionIndex].section_type === "C"
      // ) {
      //   add_test_question_type = "description";
      // }
      _components[action.payload.sectionIndex].components = [
        ..._components[action.payload.sectionIndex].components,
        initialTestQuestionData(),
      ];
      return {
        ...state,
        test: {
          ...newTest,
          components: _components,
        },
      };

    case DELETE_TEST_QUESTION:
      _components = [...newTest.components];
      const question_id =
        _components[action.payload.sectionIndex].components[
          action.payload.questionIndex
        ]._id;
      const questions = state.questions_used.filter((id) => id !== question_id);
      _components[action.payload.sectionIndex].components.splice(
        action.payload.questionIndex,
        1
      );
      return {
        ...state,
        questions_used: [...questions],
        test: {
          ...newTest,
          components: _components,
        },
      };

    case ASSIGN_TEST_SUCCESS:
      return {
        ...state,
        assignTestLoading: false,
        assign: action.payload.assign,
      };

    case ASSIGN_TEST_PROCTOR_SUCCESS:
      return {
        ...state,
        assignTestLoading: false,
        proctor_assign: action.payload.proctor_assign,
      };

    case ASSIGN_TEST_PROCTOR_REQUESTED:
      return {
        ...state,
        assignTestLoading: true,
      };

    case ASSIGN_TEST_PROCTOR_FAILURE:
      return {
        ...state,
        assignTestLoading: false,
      };

    case DUPLICATE_TEST_QUESTION:
      _components[action.payload.sectionIndex].components = [
        ..._components[action.payload.sectionIndex].components,
        JSON.parse(
          JSON.stringify(
            _components[action.payload.sectionIndex].components[
              action.payload.questionIndex
            ]
          )
        ),
      ];

      return {
        ...state,
        test: {
          ...newTest,
          components: _components,
        },
      };

    case TOGGLE_RIGHT_SIDEBAR:
      return {
        ...state,
        isRightSidebar: !state.isRightSidebar,
      };

    case FETCH_USER_TABLES_REQUESTED:
      return {
        ...state,
      };

    case FETCH_USER_TABLES_FAILURE:
      return {
        ...state,
        tables: [],
        fetchTablesFailure: true,
      };

    case FETCH_USER_TABLES_SUCCESS:
      return {
        ...state,
        tables: action.payload,
      };

    case CREATE_STUDENT_SCHEMA:
      return state;

    case UPDATE_QUESTION_BANK_MODAL:
      return {
        ...state,
        questionbank_modal: action.payload,
      };

    case UPDATE_QUESTION_FROM_QUESTIONBANK:
      const { question, sectionIndex, questionIndex } = action.payload;
      if (
        (newTest.components[sectionIndex].section_type === "C" &&
          question["type"] !== "coding") ||
        (newTest.components[sectionIndex].section_type === "G" &&
          question["type"] === "coding")
      ) {
        toast.warning("Section type not matching", defaultToastSetting);
        return {
          ...state,
        };
      } else {
        // for (var i in question) {
        //   if (old_question.hasOwnProperty(i)) {
        //     if (i === "type" && question["type"] === "coding") {
        //       old_question[i] = "description";
        //     } else {
        //       old_question[i] = question[i];
        //     }
        //   }
        // }
        return {
          ...state,
          questionbank_modal: false,
          questions_used: [...state.questions_used, question._id],
          test: {
            ...newTest,
            components: Object.assign([...newTest.components], {
              [sectionIndex]: {
                ...newTest.components[sectionIndex],
                components: Object.assign(
                  [...newTest.components[sectionIndex].components],
                  {
                    [questionIndex]: {
                      ...question,
                      marks: 0,
                    },
                  }
                ),
              },
            }),
          },
        };
      }

    case ASSIGN_TEST_REQUESTED:
      return {
        ...state,
        assignTestLoading: true,
      };

    case ASSIGN_TEST_FAILURE:
      return {
        ...state,
        assignTestLoading: false,
      };

    case UPDATE_AUDIO_RECORDING_STATUS:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case UPDATE_YTVIDEO_RECORDING_STATUS:
      return {
        ...state,
        YTRecordingStatus: action.payload,
      };

    case UPDATE_TEST_END_TIME_SUCCESS:
      return {
        ...state,
        loading: false,
        quizAssignDetails: { ...action.payload },
      };

    case UPDATE_TEST_END_TIME_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_TEST_END_TIME_REQUESTED:
      return {
        ...state,
        isEndTimeUpdateApiCalled: true,
        loading: true,
      };

    case CLONE_TEST_SUCCESS: {
      const e_ind = state.allTests[action.payload.type].findIndex(
        (ele) => ele._id === action.payload.id
      );
      const arr = state.allTests[action.payload.type];
      arr.splice(e_ind + 1, 0, action.payload.res);

      const all_tests = { ...state.allTests, [action.payload.type]: arr };
      console.log(action.payload, all_tests);

      return {
        ...state,
        allTests: all_tests,
      };
    }

    default:
      return state;
  }
};

export default testReducer;
