import React from "react";
import { toast } from "react-toastify";
import Peer from "peerjs";
import io from "socket.io-client";
import CountDownBar from "./giveTest/CountDownBar";
import { getType, getUserToken } from "../common/utils";
import RenderQuestion from "./giveTest/RenderQuestion";
import StarterCard from "./giveTest/StarterCard";
import Modal from "../common/Modal/Modal";
import FullFillments from "./beforeTest/FullFillments";
import SystemMetrics from "./beforeTest/SystemMetrics";
import RecordingNotice from "./beforeTest/RecordingNotice";
import TestHeader from "../tests/createTest/TestHeader";

// Store
import { connect } from "react-redux";
import {
  updateTestLoading,
  submitTestRequested,
  updateTestSectionSubmitModalActive,
  updateTestAccordianIndex,
  updateTestAccordianPageIndex,
  updateTestSubmittedSectionsCount,
  updateStarterCardActive,
  updateBandWidth,
  setUserScreen,
  setUserVideo,
  setIsVideo,
  setIsAudio,
  setIsScreen,
  fetchTestAuto,
  setTestStatus,
  fetchStudentResponse,
  fetchStudentResponseFromRedis,
  createSchemaForStudent,
  updateAudioRecordingStatus,
} from "../../store/actions/test";
import KyronSideLetter from "../../assets/img/Kyron/kyron_logo.png";
import {
  generatePeerId,
  generateStudentProctorPeerId,
  isAdmin,
  isTeacher,
  peerConfig,
} from "./utils";
import "./giveTest.css";
import TabSwitchModal from "./extras/TabSwitchModal";
import StudentChat from "./giveTest/StudentChat";
import AlertModal from "./giveTest/AlertModal";
import SubmitModal from "./giveTest/SubmitModal";
import SideNavBar from "./giveTest/SideNavBar";
import screenfull from "screenfull";
import testService from "../../services/test.service";
import { uploadFile } from "../../services/upload.service";
import { defaultToastSetting } from "../../assets/js/toast_config";
import { fetchChatMsgsRequested } from "../../store/actions/chat";
import UniversalButton from "../common/StandardButton/UniversalButton";
import { copyInsideCodeEditor } from "../../store/actions/codeeditor";
import { withRouter } from "../common/WithRouter";
import { Navigate } from "react-router-dom";

class GiveNewTest extends React.Component {
  constructor(props) {
    super(props);
    const uniqueUserId = this.props.currentUserId;
    this.state = {
      screenshotModal: false,
      setEventListener: false,
      screenshotCount: 0,
      chatMessageLoader: false,
      showModal: false,
      errorMessage: "",
      studentState: false,
      inputMessage: "",
      tabChangecounter: 0,
      testData: {
        section_type: "G",
        title: "",
        description: "",
        start_duration: null,
        end_duration: null,
        components: [],
      },
      messages: [],
      notify: false,
      unseenMessageCount: 0,
      uniqueUserId: uniqueUserId,
      socketId: null,
      teacherPeerId: null,
      teacherVideoPeerId: null,
      teacherDisplayPeerId: null,
      isVideo: false,
      peerId: generatePeerId("student", {
        testId: this.props.params.id,
        userId: uniqueUserId,
      }),
      videoPeerId: generateStudentProctorPeerId("student", {
        peerType: "video",
        testId: this.props.params.id,
        userId: uniqueUserId,
      }),
      displayPeerId: generateStudentProctorPeerId("student", {
        peerType: "display",
        testId: this.props.params.id,
        userId: uniqueUserId,
      }),
      currentAudioInputDevice: null,
      currentAudioOutputDevice: null,
      currentVideoDevice: null,
      submitModal: false,
      tabSwitchModal: false,
      proctorConnected: false,
      isEntireScreenShared: false,
      showSettingsModal: false,
      teacherStream: null,
      isTeacherMuted: true,
      isFullscreen: false,
      keyLoggerTimer: null,
    };
    this.localStream = null;
    this.socketRef = React.createRef();
    this.isCaptureIntervalTimer = null;
    this.pageNumberArray = this.pageNumberArray.bind(this);
    this.socket = io(process.env.REACT_APP_SOCKET_URL, {
      secure: true,
      withCredentials: true,
      transports: ["websocket"],
    });

    const peerconfig = peerConfig(this.props.credentials);
    this.peer = new Peer(this.state.peerId, peerconfig);
    this.videoPeer = new Peer(this.state.videoPeerId, peerconfig);
    this.displayPeer = new Peer(this.state.displayPeerId, peerconfig);
    this.videoRef = React.createRef(null);
    this.scrollRef = React.createRef(null);
    this.handleCloseTabSwitchModal = this.handleCloseTabSwitchModal.bind(this);
    this.futurePeer = null;
    this.mouseKeys = React.createRef(null);
    this.mouseKeys.current = [];
    this.keyboardKeys = React.createRef(null);
    this.keyboardKeys.current = [];
    this.prevHtmlContent = React.createRef("");
  }

  componentDidMount = () => {
    this.props.setTestStatus(true);
    const testId = this.props.params.id;
    const { is_disable_tab_switch, tab_switch_count } = this.props.test;
    if (is_disable_tab_switch) {
      if (localStorage.getItem(`${testId}-tabChangecounter`) === null) {
        localStorage.setItem(`${testId}-tabChangecounter`, 0);
      } else {
        const count = JSON.parse(
          localStorage.getItem(`${testId}-tabChangecounter`)
        );
        this.setState((prev) => {
          return {
            ...prev,
            tabChangecounter: count,
            tabSwitchModal: count > tab_switch_count ? true : false,
          };
        });
      }
      document.addEventListener("visibilitychange", this.handleTabSwitch);
    }
    this.scrollToBottom();
    // if (
    //   !this.props.test.is_public &&
    //   (!this.props.credentials.username || !this.props.credentials.password)
    // ) {
    //   this.redirectToWelcomePage(testId)
    // }
    // else {
    if (this.props.test?.components?.some((c) => c.section_type === "PSQL"))
      this.props.createSchemaForStudent({ testId, type: "T" });
    this.responseInterval = setInterval(() => this.saveResponse(), 10000);
    const preview = this.props.location.pathname.split("/");
    this.props.updateTestLoading(true);
    const is_proctoring = this.props.test.is_proctoring;
    this.timer = setInterval(() => this.checkTimer(), 15000);
    console.log("checking is prcotoring", is_proctoring);
    if (!is_proctoring) {
      console.log("went inside ");
      this.props.updateStarterCardActive(5);
    } else if (preview[preview.length - 2] !== "preview" && is_proctoring) {
      console.log("went inside 2");
      window.addEventListener("offline", () => {
        toast.warning("You are offline", defaultToastSetting);
        this.props.updateBandWidth(0);
      });
      this.shareVideo();
      this.shareScreen();
      // NEW CODE START
      // console.log({ "type": "peers checking", "video": this.videoPeer, "display": this.displayPeer, "peer": this.peer })

      if (!this.socketRef.current) {
        // console.log({
        //   "type": "socket establishment from student",
        //   "socket details": {
        //     userId: this.state.uniqueUserId,
        //     socketId: this.socket.id,
        //     testId: testId,
        //     peerId: this.state.peerId,
        //     videoPeerId: this.state.videoPeerId,
        //     displayPeerId: this.state.displayPeerId,
        //     userType: "student",
        //     token: getUserToken(),
        //     startTime: Date.now(),
        //     userAgent: this.getUserBrowser(),
        //     timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        //   }
        // })
        this.socket.on("connect", () => {
          this.socketRef.current = true;
          this.setState({ socketId: this.socket.id });
          this.socket.emit("new-socket", {
            userId: this.state.uniqueUserId,
            socketId: this.socket.id,
            testId: testId,
            peerId: this.state.peerId,
            videoPeerId: this.state.videoPeerId,
            displayPeerId: this.state.displayPeerId,
            userType: "student",
            token: getUserToken(),
            startTime: Date.now(),
            userAgent: this.getUserBrowser(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          });
        });
      }

      this.socket.on("take-msgs", (data) => {
        this.setState({ messages: data });
      });

      this.peer.on("call", (call) => {
        this.setState((prev) => {
          return {
            ...prev,
            teacherPeerId: call.peer,
          };
        });
        call.answer(this.props.videoStream);
        call.on("stream", (remoteStream) => {
          this.setState({ teacherStream: remoteStream });
        });
      });
      this.videoPeer.on("call", (call) => {
        this.setState((prev) => {
          return {
            ...prev,
            teacherVideoPeerId: call.peer,
          };
        });
        call.answer(this.props.videoStream);
        call.on("stream", (remoteStream) => {});
      });

      this.displayPeer.on("call", (call) => {
        this.setState((prev) => {
          return {
            ...prev,
            teacherDisplayPeerId: call.peer,
          };
        });
        call.answer(this.props.screenStream);
        call.on("stream", (remoteStream) => {});
      });

      this.socket.on("private-message", (msgData) => {
        this.setState((prev) => {
          return {
            ...prev,
            messages: [...prev.messages, msgData.msg],
            showModal: true,
          };
        });
        this.scrollToBottom();

        toast.info("New Message from Proctor", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: false,
        });
      });

      this.socket.on("proctor-connected", async (data) => {
        this.setState({
          proctorConnected: true,
        });
      });

      this.socket.on("disconnect-yourself", () => {
        this.peer.destroy();
        if (this.futurePeer) this.futurePeer.destroy();
        const peerconfig = peerConfig(this.props.credentials);
        this.futurePeer = new Peer(this.state.peerId, peerconfig);
        this.futurePeer.on("call", (call) => {
          this.setState((prev) => {
            return {
              ...prev,
              teacherPeerId: call.peer,
            };
          });
          call.answer(this.props.videoStream);
          call.on("stream", (remoteStream) => {
            this.setState({ teacherStream: remoteStream });
          });
        });
      });

      this.peer.on("close", () => {
        console.log("peer disconncted");
      });

      this.socket.on("broadcast-msg-to-students", (data) => {
        toast.info(data.msg, {
          position: "top-center",
          autoClose: 2000000000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: false,
        });
      });

      this.socket.on("unmute-teacher-to-student", (data) => {
        if (this.state.socketId === data.socketId && data.verdict) {
          this.setState({ isTeacherMuted: false });
          return;
        }
        this.setState({ isTeacherMuted: true });
        return;
      });

      this.socket.on("broadcast-voice-msg-to-students", (data) => {
        if (data.data === null && this.state.isTeacherMuted === true)
          toast.info(
            "Turn up your system volume. Teacher is speaking something.",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: false,
            }
          );
        if (data.data) {
          toast.info(
            "Turn up your system volume. Teacher is speaking something.",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: false,
            }
          );
          const blob = new Blob([data.data], { type: "audio/mp3" });
          var blobUrl = URL.createObjectURL(blob);
          const audio = new Audio(blobUrl);
          audio.play();
          audio.volume = 1;
          return;
        }
        this.setState((prev) => {
          return { ...prev, isTeacherMuted: !prev.isTeacherMuted };
        });
      });
    }
    // }

    if (this.props.quizAssignDetails._id) {
      if (getType() === "S")
        testService.updateResponsequestionTime({
          id: this.props.quizAssignDetails._id,
          data: {
            s: {
              sectionIndex: 0,
              questionIndex: 0,
            },
          },
        });
    }

    // Full screen Logic
    if (document.body && this.props.test.is_disable_fullscreen) {
      document.body.addEventListener("fullscreenerror", function () {
        console.log("error in fullscreening");
      });

      if (screenfull.isEnabled) {
        screenfull.on("change", () => {
          if (screenfull.isFullscreen) {
            this.setState((prev) => {
              return { ...prev, isFullscreen: true };
            });
          }
          if (!screenfull.isFullscreen) {
            this.setState((prev) => {
              return { ...prev, isFullscreen: false };
            });
          }
        });
      }
    }

    // call keylogger in every 30 second
    this.keyLoggerTimer = this.props.test?.is_disable_keystrokes
      ? setInterval(() => {
          this.saveKeysData();
        }, 30000)
      : null;

    // for ss(screenshot)
    this.screenshot = (e) => {
      if (e.key === "PrintScreen") {
        if (this.state.screenshotCount < 1)
          this.setState((prev) => {
            return { ...prev, screenshotModal: true };
          });
        if (this.state.screenshotCount === 1)
          window.open(`${window.location.origin}/tests`, "_self");
      }
    };

    document.addEventListener("keyup", this.screenshot);
  };

  handleCloseTabSwitchModal = () => {
    const { tab_switch_count } = this.props.test;
    this.setState((prev) => {
      return {
        ...prev,
        tabSwitchModal: false,
      };
    });
    if (this.state.tabChangecounter > tab_switch_count) {
      this.submitTestAutomatic();
    }
  };

  capturePicture(path) {
    if (this.localStream.active) {
      var canvas = document.createElement("canvas");
      canvas.width = path.current.videoWidth + 320;
      canvas.height = path.current.videoHeight + 300;
      var context = canvas.getContext("2d");
      context.drawImage(path.current, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(
        async (context) => {
          this.UploadEachCameraPicture(context);
        },
        "image/png",
        1.0
      );
    }
  }

  blobToDataURL(blob) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  storeImageLocally = async (context) => {
    const blobToDataURL = (blob) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });
    };

    const storedImages = JSON.parse(
      localStorage.getItem("storedImages") || "[]"
    );
    const Url = await blobToDataURL(context);
    storedImages.push(Url);
    localStorage.setItem("storedImages", JSON.stringify(storedImages));
  };

  UploadEachCameraPicture = (context) => {
    uploadFile(context, {
      type: "TEST-CAPTURE-CAMERA",
      id: this.props.params.id + "/" + this.props.quizAssignDetails._id,
    }).then((filename) => {
      if (getType() === "S")
        testService
          .uploadUserCameraLogs({
            testId: this.props.params.id,
            studentId: this.props.quizAssignDetails._id,
            imageName: filename,
            time_interval: new Date(),
          })
          .then((res) => {
            console.log("response0", res);
          })
          .catch((err) => console.log("error", err));
    });
  };

  captureCameraInterval = (vref, time) => {
    if (vref) {
      this.isCaptureIntervalTimer = setInterval(
        () => this.capturePicture(vref),
        time * 1000
      );
    }
  };

  getUserBrowser() {
    let userAgent = navigator.userAgent;
    let browserName;
    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "Chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "Firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "Safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "Opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "Edge";
    } else {
      browserName = "No browser detection";
    }
    return browserName;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      getType() === "S" &&
      this.props.params.id &&
      this.props.response === null &&
      this.props.loading === false
    ) {
      this.props.fetchStudentResponseFromRedis(this.props.params.id);
    }
    if (
      this.props.test.is_proctoring &&
      this.props.test.is_user_capture_camera &&
      !this.props.startCardActive
    ) {
      if (
        this.props.YTRecordingStatus === "PLAYING" &&
        !this.isCaptureIntervalTimer
      ) {
        this.captureCameraInterval(
          this.videoRef,
          this.props.test.set_camera_capture_interval
        );
      }
      if (
        this.props.YTRecordingStatus !== "PLAYING" &&
        this.isCaptureIntervalTimer
      ) {
        clearInterval(this.isCaptureIntervalTimer);
        this.isCaptureIntervalTimer = null;
      }
    }

    if (
      this.props.test.is_proctoring &&
      (prevState.currentVideoDevice !== this.state.currentVideoDevice ||
        prevState.currentAudioInputDevice !==
          this.state.currentAudioInputDevice ||
        prevState.currentAudioOutputDevice !==
          this.state.currentAudioOutputDevice)
    ) {
      this.videoIdChanges();
    }
    if (
      this.props.cardNumber === 5 &&
      !this.props.startCardActive &&
      this.props.videoStream &&
      this.props.isVideo &&
      this.videoRef !== null &&
      this.localStream === null
    ) {
      this.localStream = this.props.videoStream;
      this.videoRef.current.srcObject = this.props.videoStream;
    }

    if (
      this.props.cardNumber === 5 &&
      !this.props.starterCardActive &&
      this.props.videoStream &&
      this.props.screenStream &&
      !this.state.setEventListener
    ) {
      this.setState((prev) => ({
        ...prev,
        setEventListener: true,
      }));
      this.props.screenStream
        ?.getVideoTracks()[0]
        ?.addEventListener("ended", () => {
          this.props.setIsScreen(false);
        });
      this.props.videoStream
        ?.getVideoTracks()[0]
        ?.addEventListener("ended", () => {
          this.props.setIsVideo(false);
          this.props.setIsAudio(false);
        });
    }

    if (prevProps.error !== this.props.error) {
      toast.warning(this.props.error, defaultToastSetting);
    }
    this.scrollToBottom();
    if (
      !this.props.isMsgsLoading &&
      prevProps.isMsgsLoading &&
      this.props.chatMsgs.length
    ) {
      this.setState((prev) => ({
        ...prev,
        messages: this.props.chatMsgs,
      }));
      this.scrollToBottom();
    }
    const prevQuestion = prevProps.currentAccordianPageIndex;
    const currQuestion = this.props.currentAccordianPageIndex;
    const prevSection = prevProps.currentAccordianIndex;
    const currSection = this.props.currentAccordianIndex;

    if (this.props.quizAssignDetails._id) {
      if (prevSection !== currSection) {
        if (getType() === "S")
          testService.updateResponsequestionTime({
            id: this.props.quizAssignDetails._id,
            data: {
              s: {
                sectionIndex: currSection,
                questionIndex: currQuestion,
              },
              e: {
                sectionIndex: prevSection,
                questionIndex: prevQuestion,
              },
            },
          });
      }
      if (prevSection === currSection && prevQuestion !== currQuestion) {
        if (getType() === "S")
          testService.updateResponsequestionTime({
            id: this.props.quizAssignDetails._id,
            data: {
              s: {
                sectionIndex: currSection,
                questionIndex: currQuestion,
              },
              e: {
                sectionIndex: currSection,
                questionIndex: prevQuestion,
              },
            },
          });
      }
    }
  };

  checkTimer() {
    this.props.fetchTestAuto({
      id: this.props.params.id,
      allow_flexible_exam_time: this.props.test?.allow_flexible_exam_time,
    });
  }

  videoIdChanges = async () => {
    await this.shareVideo();
    this.showVideo();
  };

  saveResponse() {
    if (!isAdmin() && !isTeacher() && !this.props.startCardActive) {
      const testId = this.props.test._id;
      const submit = () =>
        this.props.submitTestRequested({
          id: testId,
          response: this.props.response,
          isSubmit: false,
        });
      submit();
    }
  }

  async shareVideo() {
    if (this.props.videoStream) {
      this.props.videoStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    await navigator.mediaDevices
      .getUserMedia({
        video: {
          height: { exact: 144 },
          deviceId: this.state.currentVideoDevice
            ? { exact: this.state.currentVideoDevice }
            : undefined,
        },
        audio: {
          deviceId: this.state.currentAudioInputDevice
            ? { exact: this.state.currentAudioInputDevice }
            : undefined,
          noiseSuppression: true,
          echoCancellation: true,
        },
      })
      .then((stream) => {
        this.props.setUserVideo(stream);
        this.props.setIsVideo(true);
        this.props.setIsAudio(true);
        this.record_and_send(stream);
      })
      .catch((err) => {
        this.setState((prevState) => {
          return {
            ...prevState,
            errorMessage: err,
          };
        });
      });
  }

  record_and_send = (stream) => {
    const recorder = new MediaRecorder(stream, {
      mimeType: "video/webm; codecs=vp9",
    });
    const chunks = [];
    recorder.ondataavailable = (e) => chunks.push(e.data);
    recorder.onstop = (e) => {
      this.sendToServer(new Blob(chunks, { type: "video/webm; codecs=vp9" }));
    };
    recorder.start();
  };

  sendToServer = (blob) => {
    this.socket.emit("take-blob", {
      userId: this.state.uniqueUserId,
      blob,
    });
  };

  async shareScreen() {
    await navigator.mediaDevices
      .getDisplayMedia({
        video: {
          height: 480,
        },
      })
      .then(async (screenStream) => {
        if (
          screenStream.getVideoTracks()[0].getSettings().displaySurface ===
          "monitor"
        ) {
          this.props.setUserScreen(screenStream);
          this.props.setIsScreen(true);
          this.setState({ isEntireScreenShared: true });
        } else {
          this.setState({ isEntireScreenShared: false });
        }
      })
      .catch((err) => console.log(err));
  }

  getConnection() {
    return (
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection ||
      navigator.msConnection
    );
  }

  checkStrem() {
    if (this.props.test.is_proctoring) {
      if (this.props.videoStream && !this.props.videoStream.active) {
        this.props.setIsVideo(false);
        this.props.setIsAudio(false);
      }
      if (this.props.screenStream && !this.props.screenStream.active) {
        this.props.setIsScreen(false);
      }
    }
  }

  handleChatModal = () => {
    if (!this.state.showModal) {
      this.props.fetchChatMsgsRequested({
        testId: this.props.test._id,
        userId: this.props?.quizAssignDetails?.proctor,
      });
      this.setState((prev) => ({
        ...prev,
        unseenMessageCount: 0,
        notify: false,
        showModal: true,
      }));
    } else {
      this.setState({
        showModal: false,
        unseenMessageCount: 0,
        notify: false,
      });
    }
    this.scrollToBottom();
  };

  handleChatPrompt = (message) => {
    this.setState({
      inputMessage: message,
    });
  };

  submitTestAutomatic() {
    const testId = this.props.test._id;
    const submit = () =>
      this.props.submitTestRequested({
        id: testId,
        response: this.props.response,
        isSubmit: true,
      });
    localStorage.removeItem(`${this.props.params.id}-tabChangecounter`);
    submit();
    this.props.history(`/tests/feedback/${testId}`, {
      state: this.props.test?.is_show_advertisement,
    });
    window.location.reload();
    this.props.updateStarterCardActive(7);
  }

  checkAnswers(isAnswered) {
    for (var i = 0; i < isAnswered.length; i++) {
      if (isAnswered[i].includes(false)) {
        return false;
      }
    }
    return true;
  }

  submitTest = () => {
    const testId = this.props.test._id;
    const submit = () =>
      this.props.submitTestRequested({
        id: testId,
        response: this.props.response,
        isSubmit: true,
      });
    submit();
    toast.success("You Submitted the test!", defaultToastSetting);
    if (!this.props.test.is_public) {
      const timer = () =>
        setTimeout(() => {
          this.props.history(`/tests/feedback/${testId}`, {
            state: this.props.test?.is_show_advertisement,
          });
          window.location.reload();
          this.props.setTestStatus(false);
          this.props.updateStarterCardActive(7);
        }, 3000);
      timer();
      return () => clearTimeout(timer);
    } else {
      const timer = () =>
        setTimeout(() => {
          this.props.history(`/tests/welcome-page/${testId}?public=true`);
          window.location.reload();
          this.props.setTestStatus(false);
          this.props.updateStarterCardActive(7);
        }, 3000);
      timer();
      return () => clearTimeout(timer);
    }
  };

  editTest = () => {
    if (!screenfull.isEnabled) {
      screenfull.toggle();
    }
    this.props.setTestStatus(false);
    const testId = this.props.params.id;
    this.props.history(`/tests/edit/${testId}`);
  };

  handleNextQuestion = async () => {
    this.props.updateTestAccordianPageIndex(
      this.props.currentAccordianPageIndex + 1
    );
    this.saveResponse();
  };

  handlePreviousQuestion = async () => {
    this.props.updateTestAccordianPageIndex(
      this.props.currentAccordianPageIndex - 1
    );
    this.saveResponse();
  };

  handleNextSection = async () => {
    this.props.updateTestAccordianIndex(this.props.currentAccordianIndex + 1);
    this.props.updateTestAccordianPageIndex(0);
    this.saveResponse();
  };

  pageNumberArray(section) {
    let numberOfPages = Math.ceil(
      section.components.length / section.questions_per_page
    );
    let number_array = [];
    for (let i = 0; i < numberOfPages; i++) {
      number_array.push(i);
    }
    return number_array;
  }

  handlePreviousSection = async () => {
    this.props.updateTestAccordianPageIndex(
      this.pageNumberArray(
        this.props.test.components[this.props.currentAccordianIndex - 1]
      ).length - 1
    );
    this.props.updateTestAccordianIndex(this.props.currentAccordianIndex - 1);
    this.saveResponse();
  };

  pageNumberQuestions = (questionIndex, section) => {
    return (
      questionIndex + 1 <=
        (this.props.currentAccordianPageIndex + 1) *
          section.questions_per_page &&
      questionIndex + 1 >
        this.props.currentAccordianPageIndex * section.questions_per_page
    );
  };

  handleSubmission = () => {
    this.props.updateTestSubmittedSectionsCount(
      this.state.submittedSectionsCount + 1
    );
  };

  showVideo = async () => {
    if (
      this.props.videoStream?.active &&
      this.props.isVideo &&
      this.videoRef !== null &&
      this.videoRef?.current
    ) {
      this.localStream = this.props.videoStream;
      this.videoRef.current.srcObject = this.props.videoStream;
    }
  };

  handleTabSwitch = () => {
    if (document.visibilityState === "hidden" && !this.props.startCardActive) {
      this.props.copyInsideCodeEditor(false);
      if (getType() === "S")
        testService.updateTabChanges({ id: this.props.quizAssignDetails._id });
      this.setState(
        (prev) => {
          return {
            ...prev,
            tabSwitchModal: true,
            tabChangecounter: prev.tabChangecounter + 1,
          };
        },
        () =>
          localStorage.setItem(
            `${this.props.params.id}-tabChangecounter`,
            this.state.tabChangecounter
          )
      );
    }
  };

  componentWillUnmount() {
    document.removeEventListener("keyup", this.screenshot);
    clearTimeout(this.keyLoggerTimer);
    clearInterval(this.interval);
    clearInterval(this.isCaptureIntervalTimer);
    clearInterval(this.timer);
    clearInterval(this.responseInterval);
    this.socket.close();
    document.removeEventListener(
      "visibilitychange",
      () => this.handleTabSwitch
    );
    if (this.localStream) {
      this.localStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    this.peer.destroy();
    if (this.props.quizAssignDetails._id && getType() === "S") {
      testService.updateResponsequestionTime({
        id: this.props.quizAssignDetails._id,
        data: {
          type: "e",
          sectionIndex: this.props.currentAccordianIndex,
          questionIndex: this.props.currentAccordianPageIndex,
        },
      });
    }
  }

  checkGiveTestEligibility = () => {
    return this.props.test?.allow_flexible_exam_time
      ? new Date(this.state.quizAssignDetails.end_time).getTime() >
          new Date().getTime()
      : new Date(this.state.testData.end_duration).getTime() >
          new Date().getTime();
  };

  handleAudioInputChange = (id) => {
    this.setState({ currentAudioInputDevice: id });
  };

  handleAudioOutputChange = (id) => {
    this.setState({ currentAudioOutputDevice: id });
  };

  handleVideoChange = (id) => {
    this.setState({ currentVideoDevice: id });
  };

  scrollToBottom = () => {
    if (this.scrollRef.current)
      this.scrollRef.current.scrollIntoView({ behaviour: "smooth" });
  };

  handleSubmitModal = () => {
    this.setState({ submitModal: !this.state.submitModal });
  };

  saveKeysData = () => {
    if (
      this.keyboardKeys.current.length > 0 ||
      this.mouseKeys.current.length > 0
    ) {
      const tempKeyboardKeys = this.keyboardKeys.current;
      const tempMouseKeys = this.mouseKeys.current;
      this.keyboardKeys.current = [];
      this.mouseKeys.current = [];
      if (getType() === "S")
        testService
          .uploadUserLogs(
            {
              keyboard_data: tempKeyboardKeys,
              mouse_data: tempMouseKeys,
            },
            this.props.params.id
          )
          .then((res) => console.log(res))
          .catch((e) => console.log(e))
          .finally((e) => {});
    }
  };

  keyDownFunction = (e) => {
    let keyDown = [
      "KD",
      e.key,
      Date.now(),
      this.props.currentAccordianIndex,
      this.props.currentAccordianPageIndex,
    ];
    this.keyboardKeys.current.push(keyDown);
  };

  keyUpFunction = (e) => {
    let keyRelease = [
      "KU",
      e.key,
      Date.now(),
      this.props.currentAccordianIndex,
      this.props.currentAccordianPageIndex,
    ];
    this.keyboardKeys.current.push(keyRelease);
  };

  onClickMouse = (e) => {
    let mouseClick = [
      "MC",
      e.screenX,
      e.screenY,
      e.clientX,
      e.clientY,
      e.pageY,
      e.pageX,
      Date.now(),
      this.props.currentAccordianIndex,
      this.props.currentAccordianPageIndex,
    ];
    this.mouseKeys.current.push(mouseClick);
  };

  ondoubleClick = (e) => {
    let mouseDoubleClick = [
      "MDC",
      e.screenX,
      e.screenY,
      e.clientX,
      e.clientY,
      e.pageY,
      e.pageX,
      Date.now(),
      this.props.currentAccordianIndex,
      this.props.currentAccordianPageIndex,
    ];
    this.mouseKeys.current.push(mouseDoubleClick);
  };

  onMouseMoveHandler = (e, id) => {
    e.stopPropagation();
    if (
      this.prevHtmlContent.current !== id &&
      this.props.test?.is_disable_keystrokes
    ) {
      let currMouseData = [
        id,
        e.screenX,
        e.screenY,
        e.clientX,
        e.clientY,
        e.pageY,
        e.pageX,
        Date.now(),
        this.props.currentAccordianIndex,
        this.props.currentAccordianPageIndex,
      ];
      this.prevHtmlContent.current = id;
      this.mouseKeys.current.push(currMouseData);
    }
  };

  onCopyHandler = (e) => {
    this.props.copyInsideCodeEditor(true);
  };

  onPasteHandler = (e) => {
    if (this.props.is_copy_inside_editor) {
      this.props.copyInsideCodeEditor(false);
    } else {
      e.preventDefault();
    }
  };

  showPrevQuestion = (section) => {
    if (
      this.props.currentAccordianPageIndex !== 0 &&
      (!this.props.AudioRecordingStatus?.startPreparationTime ||
        this.props.AudioRecordingStatus?.isAnswerTimeCompleted) &&
      this.props.YTRecordingStatus !== "PLAYING"
    ) {
      return true;
    } else {
      return false;
    }
  };

  showPrevSection = (section) => {
    if (
      this.props.currentAccordianIndex !== 0 &&
      (!this.props.AudioRecordingStatus?.startPreparationTime ||
        this.props.AudioRecordingStatus?.isAnswerTimeCompleted) &&
      this.props.YTRecordingStatus !== "PLAYING"
    ) {
      return true;
    } else {
      return false;
    }
  };

  showNextQuestion = (section) => {
    if (
      this.props.currentAccordianPageIndex !==
        this.pageNumberArray(section).length - 1 &&
      (!this.props.AudioRecordingStatus?.startPreparationTime ||
        this.props.AudioRecordingStatus?.isAnswerTimeCompleted) &&
      this.props.YTRecordingStatus !== "PLAYING"
    ) {
      return true;
    } else {
      return false;
    }
  };

  showNextSection = (section) => {
    if (
      this.props.currentAccordianIndex !==
        this.props.test.components.length - 1 &&
      (!this.props.AudioRecordingStatus?.startPreparationTime ||
        this.props.AudioRecordingStatus?.isAnswerTimeCompleted) &&
      this.props.YTRecordingStatus !== "PLAYING"
    ) {
      return true;
    } else {
      return false;
    }
  };

  showPrev = () => {
    if (this.props.currentAccordianPageIndex === 0) return true;
    else return false;
  };

  showNext = (section) => {
    if (
      this.props.currentAccordianPageIndex ===
      this.pageNumberArray(section).length - 1
    )
      return true;
    else return false;
  };

  render() {
    const testId = this.props.params.id;
    const handleSendingMessage = () => {
      this.setState((prev) => {
        return {
          ...prev,
          chatMessageLoader: true,
        };
      });
      this.socket.emit(
        "msg-from-student",
        {
          testId: this.props.params.id,
          msg: this.state.inputMessage,
          studentPeer: this.state.peerId,
          userType: "student",
          userId: this.state.uniqueUserId,
          timestamp: Date.now(),
        },
        (message) => {
          this.setState((prev) => {
            return {
              ...prev,
              messages: [...prev.messages, message],
              inputMessage: "",
              chatMessageLoader: false,
            };
          });
          this.scrollToBottom();
        }
      );
    };

    const allPages = () => {
      console.log("checking card number", this.props.cardNumber);
      switch (this.props.cardNumber) {
        case 5:
          return (
            <>
              <StarterCard
                socket={this.socket}
                userId={this.state.uniqueUserId}
                testId={this.props.params.id}
              />
            </>
          );
        case 2:
          return (
            <>
              <SystemMetrics
                socket={this.socket}
                userId={this.state.uniqueUserId}
                testId={this.props.params.id}
                error={this.state.errorMessage}
                isEntireScreenShared={this.state.isEntireScreenShared}
                currentAudioOutputDevice={this.state.currentAudioOutputDevice}
                currentVideoDevice={this.state.currentVideoDevice}
                currentAudioInputDevice={this.state.currentAudioInputDevice}
                handleAudioInputChange={this.handleAudioInputChange}
                handleAudioOutputChange={this.handleAudioOutputChange}
                handleVideoChange={this.handleVideoChange}
                type="T"
              />
            </>
          );
        case 3:
          return (
            <>
              <FullFillments
                type="T"
                socket={this.socket}
                userId={this.state.uniqueUserId}
                testId={this.props.params.id}
                currentVideoDevice={this.state.currentVideoDevice}
              />
            </>
          );
        case 4:
          return (
            <>
              <RecordingNotice
                socket={this.socket}
                userId={this.state.uniqueUserId}
                testId={this.props.params.id}
              />
            </>
          );
        default:
          return <></>;
      }
    };

    return !this.props.test.is_public &&
      (!this.props.credentials.username || !this.props.credentials.password) ? (
      <Navigate to={`/tests/welcome-page/${testId}`} />
    ) : (
      <div
        className={`relative ${
          this.state.isFullscreen && this.props.test.is_disable_fullscreen
            ? "fullscreenClass"
            : ""
        }`}
        id="toggle-fullscreen"
      >
        {this.state.tabSwitchModal && (
          <TabSwitchModal
            type="T"
            counter={this.state.tabChangecounter}
            tabSwitchCount={this.props.test?.tab_switch_count}
            handleCloseTabSwitchModal={this.handleCloseTabSwitchModal}
          />
        )}
        <>
          <Modal
            onClose={() =>
              this.setState((prev) => {
                return {
                  ...prev,
                  screenshotModal: false,
                  screenshotCount: 1 + this.state.screenshotCount,
                };
              })
            }
            isModal={this.state.screenshotModal}
            showCloseButton
            className={"md:w-[50%]"}
          >
            <div className="m-3">
              <p className="p-2 text-center">
                Remember, no more screenshots allowed! If you take one now, your
                test will be submitted automatically. Please honor the rules of
                the test. Good luck, and do your best!
              </p>
            </div>
          </Modal>
        </>
        {this.state.showSettingsModal ? (
          <div
            className={`overflow-y-auto overflow-x-hidden  fixed flex flex-col z-50 justify-center 
                  items-center h-full w-full top-0 left-0 `}
            id="popup-modal"
            style={{ backgroundColor: "rgb(128 ,128 ,128,0.7)" }}
          >
            <SystemMetrics
              socket={this.socket}
              userId={this.state.uniqueUserId}
              testId={this.props.params.id}
              error={this.state.errorMessage}
              isEntireScreenShared={this.state.isEntireScreenShared}
              currentAudioOutputDevice={this.state.currentAudioOutputDevice}
              currentVideoDevice={this.state.currentVideoDevice}
              currentAudioInputDevice={this.state.currentAudioInputDevice}
              handleAudioInputChange={this.handleAudioInputChange}
              handleAudioOutputChange={this.handleAudioOutputChange}
              handleVideoChange={this.handleVideoChange}
              isModal={true}
              type="T"
              setIsModal={() => this.setState({ showSettingsModal: false })}
            />
          </div>
        ) : null}

        <Modal
          isModal={
            !this.state.isFullscreen && this.props.test.is_disable_fullscreen
          }
          className="flex gap-5 justify-center items-center flex-col"
        >
          <p>
            You can continue with the test only while you are on fullscreen.
            Click below to go Fullscreen again
          </p>
          <UniversalButton
            onClickFunction={() => {
              if (screenfull.isEnabled) {
                screenfull.toggle();
              }
            }}
            classNames="mb-6 mr-2"
            title="Go full screen"
          />
        </Modal>
        {/* ) : null} */}
        <div>
          {this.props.startCardActive ? (
            <>{allPages()}</>
          ) : (
            <div
              tabIndex="0"
              onCopy={(e) =>
                this.props.test.is_disable_copy_paste
                  ? this.onCopyHandler(e)
                  : null
              }
              onCut={(e) =>
                this.props.test.is_disable_copy_paste
                  ? this.onCopyHandler(e)
                  : null
              }
              onPaste={(e) =>
                this.props.test.is_disable_copy_paste
                  ? this.onPasteHandler(e)
                  : null
              }
              onKeyDown={
                this.props.test?.is_disable_keystrokes
                  ? (e) => this.keyDownFunction(e)
                  : null
              }
              onKeyUp={
                this.props.test?.is_disable_keystrokes
                  ? (e) => this.keyUpFunction(e)
                  : null
              }
              onClick={
                this.props.test?.is_disable_keystrokes
                  ? (e) => this.onClickMouse(e)
                  : null
              }
              onDoubleClick={
                this.props.test?.is_disable_keystrokes
                  ? (e) => this.ondoubleClick(e)
                  : null
              }
            >
              {this.props.test.is_proctoring &&
              (!this.props.isScreen || !this.props.isVideo) ? (
                <AlertModal
                  saveResponse={() => this.saveResponse()}
                  submitTest={() => this.submitTest()}
                />
              ) : null}
              {this.state.submitModal ? (
                <SubmitModal
                  type="T"
                  submitTest={() => this.submitTest()}
                  handleSubmitModal={() => this.handleSubmitModal()}
                />
              ) : null}

              <div className="w-full">
                <div className="w-full bg-white top-0 shadow-sm">
                  <nav
                    onMouseOver={(e) =>
                      this.onMouseMoveHandler(e, "navbar-body")
                    }
                    className="flex justify-between items-center px-4 py-2 border-b border-border fixed w-[100%] z-[49] bg-white drop-shadow-[2px_0px_2px_rgba(0,0,0,0.25)]"
                  >
                    <img
                      onMouseOver={(e) =>
                        this.onMouseMoveHandler(e, "navbar-icon")
                      }
                      className="h-[40px] object-contain"
                      src={KyronSideLetter}
                      title="Logo"
                      alt="kyron"
                    />
                    <div
                      onMouseOver={(e) =>
                        this.onMouseMoveHandler(e, "navbar-counter")
                      }
                    >
                      <CountDownBar
                        saveAutomatic={() =>
                          getType() === "S" && this.submitTestAutomatic()
                        }
                        endDate={
                          this.props.test.allow_flexible_exam_time
                            ? this.props.quizAssignDetails?.end_time
                            : this.props.test?.end_duration
                        }
                      />
                    </div>

                    {this.props.test.company && this.props.test.company.url ? (
                      <img
                        className=" h-[32px] object-contain hidden lg:block"
                        src={this.props.test?.company?.url}
                        alt="company_logo"
                      />
                    ) : (
                      <div className="hidden lg:block"></div>
                    )}
                  </nav>
                </div>

                <div
                  className={`flex w-full items-start justify-between relative min-h-screen flex-col-reverse lg:flex-row ${
                    this.props.isRightSidebar
                      ? "overflow-hidden lg:flex-col-reverse pt-6"
                      : "pt-6"
                  } `}
                >
                  {/* Main section */}
                  <div
                    className={`flex mb-[8rem] md:mb-[2rem] lg:mb-0 lg:w-4/5 ${
                      this.props.isRightSidebar
                        ? "sm:py-5 sm:pt-0 lg:w-full"
                        : "sm:py-10"
                    }  flex-col w-full`}
                  >
                    {this.props.test.components.map((section, sectionIndex) =>
                      sectionIndex === this.props.currentAccordianIndex ? (
                        <div key={`section-${sectionIndex}`}>
                          <TestHeader
                            title={this.props.test.title}
                            styles="text-start pl-3 mb-[-1rem] sm:mb-0"
                            onMouseOverEvent={(e) =>
                              this.onMouseMoveHandler(e, "main-body-title")
                            }
                          />
                          <div
                            key={`sectionContainer-${sectionIndex}`}
                            className="flex-col rounded-lg m-3"
                          >
                            <h3
                              onMouseOver={(e) =>
                                this.onMouseMoveHandler(
                                  e,
                                  "main-white-body-title"
                                )
                              }
                              className="text-center font-regular font-['poppins'] font-semibold text-xl "
                            >
                              {section.title}
                            </h3>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: section.description,
                              }}
                              className="text-center px-2 py-1 pb-3 font-regular font-['poppins']  text-[#666666] "
                              onMouseOver={(e) =>
                                this.onMouseMoveHandler(
                                  e,
                                  "main-white-body-description"
                                )
                              }
                            ></p>

                            {section.components.map(
                              (question, questionIndex) =>
                                this.pageNumberQuestions(
                                  questionIndex,
                                  section
                                ) ? (
                                  <>
                                    <div
                                      key={`section-${sectionIndex}-question-${questionIndex}`}
                                    >
                                      <div>
                                        <RenderQuestion
                                          type="T"
                                          handleSubmitModal={
                                            this.handleSubmitModal
                                          }
                                          handleNextQuestion={
                                            this.handleNextQuestion
                                          }
                                          handleNextSection={
                                            this.handleNextSection
                                          }
                                          testId={testId}
                                          question={question}
                                          index={questionIndex + 1}
                                          section={section}
                                          sections={this.props.test.components}
                                          onMouseOverEvent={(e, name) =>
                                            this.onMouseMoveHandler(e, name)
                                          }
                                          response={this.props.response}
                                          is_disable_copy_paste={
                                            this.props.test
                                              .is_disable_copy_paste
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : null
                            )}
                          </div>
                          {!this.props.test.is_preparation_time && (
                            <div
                              className={`fixed w-full bottom-0 lg:w-4/5 bg-white shadow-xl shadow-outer`}
                            >
                              <div className="flex flex-row w-full sm:flex-col gap-2 mt-2 justify-center sm:justify-between">
                                <div className="flex px-4 w-full py-2 justify-between gap-3 items-center">
                                  {!this.showPrev() ? (
                                    // <button
                                    //   disabled={!this.showPrevQuestion(section)}
                                    //   onClick={() => {
                                    //     this.handlePreviousQuestion(
                                    //       sectionIndex
                                    //     );
                                    //   }}
                                    //   className={` ${
                                    //     this.props.test.navigation_type === "F"
                                    //       ? "hidden"
                                    //       : "block"
                                    //   } ${
                                    //     this.showPrevQuestion(section)
                                    //       ? "cursor-pointer hover:bg-[#001437] hover:text-[#a0db32]"
                                    //       : "cursor-not-allowed opacity-70"
                                    //   }  px-3 py-2 mr-2 rounded-lg text-[#001437] w-[200px] bg-[#BCFB46]
                                    //          font-medium rounded-full text-md  flex justify-center mr-2 mb-2
                                    //         font-['nunito']`}
                                    //   onMouseOver={(e) =>
                                    //     this.onMouseMoveHandler(
                                    //       e,
                                    //       "prev-question-button"
                                    //     )
                                    //   }
                                    // >
                                    //   Previous Question
                                    // </button>
                                    <UniversalButton
                                      disabled={!this.showPrevQuestion(section)}
                                      onClickFunction={() => {
                                        this.handlePreviousQuestion(
                                          sectionIndex
                                        );
                                      }}
                                      classNames={`!w-[8rem] sm:!w-[10rem] mb-3 !px-2 ${
                                        this.props.test.navigation_type === "F"
                                          ? "hidden"
                                          : "block"
                                      } ${
                                        this.showPrevQuestion(section)
                                          ? "cursor-pointer"
                                          : "!text-[#001437] !bg-[#BCFB46] opacity-70"
                                      }`}
                                      onMouseOver={(e) =>
                                        this.onMouseMoveHandler(
                                          e,
                                          "prev-question-button"
                                        )
                                      }
                                      title="Previous Question"
                                    />
                                  ) : (
                                    // <button
                                    //   onClick={() =>
                                    //     this.handlePreviousSection()
                                    //   }
                                    //   disabled={!this.showPrevSection(section)}
                                    //   className={` ${
                                    //     this.props.test.navigation_type === "F"
                                    //       ? "hidden"
                                    //       : "block"
                                    //   }
                                    //         ${
                                    //           this.showPrevSection(section)
                                    //             ? "cursor-pointer hover:bg-[#001437] hover:text-[#a0db32]"
                                    //             : "cursor-not-allowed opacity-70 opacity-70"
                                    //         }
                                    //         px-3  py-2 mr-2  rounded-lg text-[#001437] w-[200px] bg-[#BCFB46]
                                    //          font-medium rounded-full text-md  flex justify-center mr-2 mb-2
                                    //         font-['nunito']`}
                                    //   onMouseOver={(e) =>
                                    //     this.onMouseMoveHandler(
                                    //       e,
                                    //       "prev-section-button"
                                    //     )
                                    //   }
                                    // >
                                    //   Previous Section
                                    // </button>
                                    <UniversalButton
                                      onClickFunction={() =>
                                        this.handlePreviousSection()
                                      }
                                      disabled={!this.showPrevSection(section)}
                                      classNames={`!w-[8rem] sm:!w-[10rem] mb-3 !px-2 ${
                                        this.props.test.navigation_type === "F"
                                          ? "hidden"
                                          : "block"
                                      } 
                                          ${
                                            this.showPrevSection(section)
                                              ? "cursor-pointer "
                                              : "!text-[#001437] !bg-[#BCFB46] opacity-70"
                                          }`}
                                      onMouseOver={(e) =>
                                        this.onMouseMoveHandler(
                                          e,
                                          "prev-section-button"
                                        )
                                      }
                                      title="Previous Section"
                                    />
                                  )}

                                  <h5
                                    className="flex lg:block hidden font-['poppins'] px-2 text-sm text-[#808080] text-center"
                                    onMouseOver={(e) =>
                                      this.onMouseMoveHandler(e, `footer-text`)
                                    }
                                  >
                                    In case you face any issues, you can reach
                                    out to us at
                                    <span className="mx-1 text-[#a0db32]">
                                      helpdesk@kyron.in
                                    </span>
                                    or
                                    <span className="mx-1 text-[#a0db32] text-sm">
                                      +91-8800966082
                                    </span>
                                  </h5>

                                  {!this.showNext(section) ? (
                                    // <button
                                    //   onClick={() =>
                                    //     this.handleNextQuestion(sectionIndex)
                                    //   }
                                    //   disabled={!this.showNextQuestion(section)}
                                    //   className={`px-3 py-2  rounded-lg text-[#001437] w-[200px] bg-[#BCFB46]
                                    //          font-medium ${
                                    //            this.showNextQuestion(section)
                                    //              ? "cursor-pointer hover:bg-[#001437] hover:text-[#a0db32]"
                                    //              : "cursor-not-allowed opacity-70"
                                    //          } rounded-full text-md  flex justify-center mr-2 mb-2
                                    //         font-['nunito']`}
                                    //   onMouseOver={(e) =>
                                    //     this.onMouseMoveHandler(
                                    //       e,
                                    //       "next-question-button"
                                    //     )
                                    //   }
                                    // >
                                    //   Next Question
                                    // </button>
                                    <UniversalButton
                                      onClickFunction={() =>
                                        this.handleNextQuestion(sectionIndex)
                                      }
                                      disabled={!this.showNextQuestion(section)}
                                      classNames={`!w-[8rem] sm:!w-[10rem] mb-3 !px-2 ${
                                        this.showNextQuestion(section)
                                          ? "cursor-pointer"
                                          : "!text-[#001437] !bg-[#BCFB46] opacity-70"
                                      } `}
                                      onMouseOver={(e) =>
                                        this.onMouseMoveHandler(
                                          e,
                                          "next-question-button"
                                        )
                                      }
                                      title="Next Question"
                                    />
                                  ) : (
                                    // <button
                                    //   onClick={() => this.handleNextSection()}
                                    //   disabled={!this.showNextSection(section)}
                                    //   className={`px-3 py-2
                                    //     ${
                                    //       this.showNextSection(section)
                                    //         ? "cursor-pointer hover:bg-[#001437] hover:text-[#a0db32]"
                                    //         : "cursor-not-allowed opacity-70"
                                    //     }
                                    //          rounded-lg text-[#001437] w-[200px] bg-[#BCFB46]
                                    //          font-medium rounded-full text-md  flex justify-center mr-2 mb-2
                                    //         font-['nunito']`}
                                    //   onMouseOver={(e) =>
                                    //     this.onMouseMoveHandler(
                                    //       e,
                                    //       "next-section-button"
                                    //     )
                                    //   }
                                    // >
                                    //   Next Section
                                    // </button>
                                    <UniversalButton
                                      onClickFunction={() =>
                                        this.handleNextSection()
                                      }
                                      disabled={!this.showNextSection(section)}
                                      classNames={`!w-[8rem] sm:!w-[10rem] mb-3 !px-2 ${
                                        this.showNextSection(section)
                                          ? "cursor-pointer "
                                          : "!text-[#001437] !bg-[#BCFB46] opacity-70"
                                      }`}
                                      onMouseOver={(e) =>
                                        this.onMouseMoveHandler(
                                          e,
                                          "next-section-button"
                                        )
                                      }
                                      title="Next Section"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : null
                    )}

                    {/* Footer */}
                  </div>
                  {/* Sidenav bar */}

                  <SideNavBar
                    editTest={this.editTest}
                    handleSubmitModal={this.handleSubmitModal}
                    proctorConnected={this.state.proctorConnected}
                    currentVideoDevice={this.state.currentVideoDevice}
                    videoRef={this.videoRef}
                    saveResponse={() => this.saveResponse()}
                    teacherStream={this.state.teacherStream}
                    isTeacherMuted={this.state.isTeacherMuted}
                    isRightSidebar={this.props.isRightSidebar}
                    setSetttings={() =>
                      this.setState({ showSettingsModal: true })
                    }
                    onMouseOverEvent={(e, name) =>
                      this.onMouseMoveHandler(e, name)
                    }
                  />
                  {/* </div> */}
                </div>
              </div>

              {/* Chat message */}

              <StudentChat
                isInterviewChat={false}
                showModal={this.state.showModal}
                handleChatModal={() => this.handleChatModal()}
                messages={this.state.messages}
                unseenMessageCount={this.state.unseenMessageCount}
                notify={this.state.notify}
                uniqueUserId={this.state.uniqueUserId}
                proctorConnected={this.state.proctorConnected}
                scrollRef={this.scrollRef}
                handleChatPrompt={(data) => this.handleChatPrompt(data)}
                handleSendingMessage={() => handleSendingMessage()}
                inputMessage={this.state.inputMessage}
                chatMessageLoader={this.state.chatMessageLoader}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test, turnserver, user, chat, codeeditor }) => {
  return {
    error: test.errorMessage,
    is_copy_inside_editor: codeeditor.is_copy_inside_editor,
    isVideo: test.isVideo,
    isAudio: test.isAudio,
    isScreen: test.isScreen,
    YTRecordingStatus: test.YTRecordingStatus,
    AudioRecordingStatus: test,
    test: test.test,
    response: test.response,
    screenStream: test.screenStream,
    videoStream: test.videoStream,
    loading: test.loading,
    cardNumber: test.cardNumber,
    startCardActive: test.startCardActive,
    currentAccordianIndex: test.currentAccordianIndex,
    currentAccordianPageIndex: test.currentAccordianPageIndex,
    submitSectionModalActive: test.submitSectionModalActive,
    bandWidth: test.currentBandWidth,
    submittedSectionsCount: test.submittedSectionsCount,
    credentials: turnserver.credentials,
    proctoringDetails: test.proctoringDetails,
    currentUserId: user.profile ? user.profile._id : null,
    chatMsgs: chat.messages,
    isMsgsLoading: chat.isLoading,
    studentProctor: test.studentProctor,
    isRightSidebar: test.isRightSidebar,
    quizAssignDetails: test.quizAssignDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStudentResponse: (data) => dispatch(fetchStudentResponse(data)),
    fetchStudentResponseFromRedis: (data) =>
      dispatch(fetchStudentResponseFromRedis(data)),
    updateTestLoading: (data) => dispatch(updateTestLoading(data)),
    submitTestRequested: (data) => dispatch(submitTestRequested(data)),
    updateTestAccordianIndex: (data) =>
      dispatch(updateTestAccordianIndex(data)),
    updateTestSectionSubmitModalActive: (data) =>
      dispatch(updateTestSectionSubmitModalActive(data)),
    updateTestAccordianPageIndex: (data) =>
      dispatch(updateTestAccordianPageIndex(data)),
    setTestStatus: (data) => dispatch(setTestStatus(data)),
    updateTestSubmittedSectionsCount: (data) =>
      dispatch(updateTestSubmittedSectionsCount(data)),
    updateStarterCardActive: (data) => dispatch(updateStarterCardActive(data)),
    setUserScreen: (data) => dispatch(setUserScreen(data)),
    setUserVideo: (data) => dispatch(setUserVideo(data)),
    updateBandWidth: (data) => dispatch(updateBandWidth(data)),
    fetchTestAuto: (data) => dispatch(fetchTestAuto(data)),
    setIsAudio: (data) => dispatch(setIsAudio(data)),
    setIsVideo: (data) => dispatch(setIsVideo(data)),
    setIsScreen: (data) => dispatch(setIsScreen(data)),
    createSchemaForStudent: (data) => dispatch(createSchemaForStudent(data)),
    fetchChatMsgsRequested: (data) => dispatch(fetchChatMsgsRequested(data)),
    updateAudioRecordingStatus: (data) =>
      dispatch(updateAudioRecordingStatus(data)),
    copyInsideCodeEditor: (data) => dispatch(copyInsideCodeEditor(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GiveNewTest));
