import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import { defaultToastSetting } from "../../assets/js/toast_config";
import {
  addEmailTemplateService,
  deleteEmailTemplateService,
  getAllEmailTemplatesService,
  updateEmailTemplateService,
} from "../../services/emailtemplate.service";
import {
  addEmailTemplateFailure,
  addEmailTemplateSuccess,
  deleteEmailTemplateFailure,
  deleteEmailTemplateSuccess,
  fetchAllEmailTemplatesFailure,
  fetchAllEmailTemplatesSuccess,
  updateEmailTemplateFailure,
  updateEmailTemplateSuccess,
} from "../actions/emailtemplates";
import { deleteTestRequest, deleteTestSuccess } from "../actions/test";
import {
  ADD_EMAIL_TEMPLATE_REQUESTED,
  DELETE_EMAIL_TEMPLATE_REQUESTED,
  FETCH_ALL_EMAIL_TEMPLATES_REQUESTED,
  UPDATE_EMAIL_TEMPLATE_REQUESTED,
} from "../actionTypes";

function* fetchAllEmailTemplatesSaga(action) {
  try {
    const allEmailTemplates = yield call(
      getAllEmailTemplatesService,
      action.payload
    );
    yield put(fetchAllEmailTemplatesSuccess(allEmailTemplates));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(fetchAllEmailTemplatesFailure(e));
  }
}

function* addEmailTemplateSaga(action) {
  try {
    const questionres = yield call(addEmailTemplateService, action.payload);
    yield put(addEmailTemplateSuccess(questionres));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(addEmailTemplateFailure(e));
  }
}

function* updateEmailTemplateSaga(action) {
  try {
    const questionres = yield call(updateEmailTemplateService, action.payload);
    yield put(updateEmailTemplateSuccess(questionres));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(updateEmailTemplateFailure(e));
  }
}

function* deleteEmailTemplateSaga(action) {
  try {
    yield put(deleteTestRequest());
    const questionres = yield call(deleteEmailTemplateService, action.payload);
    yield put(deleteTestSuccess());
    yield put(deleteEmailTemplateSuccess(questionres));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(deleteEmailTemplateFailure(e));
  }
}

function* emailtemplatesSaga() {
  yield takeEvery(
    FETCH_ALL_EMAIL_TEMPLATES_REQUESTED,
    fetchAllEmailTemplatesSaga
  );
  yield takeEvery(ADD_EMAIL_TEMPLATE_REQUESTED, addEmailTemplateSaga);
  yield takeEvery(DELETE_EMAIL_TEMPLATE_REQUESTED, deleteEmailTemplateSaga);
  yield takeEvery(UPDATE_EMAIL_TEMPLATE_REQUESTED, updateEmailTemplateSaga);
}

export default emailtemplatesSaga;
