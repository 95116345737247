import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_TEST_REQUESTED,
  SUBMIT_TEST_REQUESTED,
  DELETE_TEST_REQUESTED,
  ASSIGN_TEST_REQUESTED,
  FETCH_ALL_TESTS,
  FETCH_STUDENT_RESPONSE,
  FETCH_QUIZ_PROCTORING_DETAILS,
  FETCH_TEST_AUTO,
  FETCH_TEST_SCORE_REQUESTED,
  FETCH_COMPANIES_REQUESTED,
  DELETE_RESPONSE_REQUESTED,
  ASSIGN_TEST_PROCTOR_REQUESTED,
  FETCH_TEST_ASSIGN_REQUESTED,
  SUBMIT_FEEDBACK_REQUESTED,
  SEND_EMAILS_REQUESTED,
  SEND_TEST_INVITE_EMAILS_REQUESTED,
  SEND_REMINDER_EMAILS_REQUESTED,
  ADD_GROUP_DETAILS_REQUESTED,
  FETCH_ALL_GROUP_DETAILS_REQUESTED,
  DELETE_GROUP_DETAILS_REQUESTED,
  UPDATE_GROUP_DETAILS_REQUESTED,
  UPDATE_PUBLIC_TEST_STUDENT_DETAILS,
  UPDATE_OVERALL_RANK_REQUESTED,
  FETCH_STUDENT_RESPONSE_FROM_REDIS,
  FETCH_USER_TABLES_REQUESTED,
  CREATE_STUDENT_SCHEMA,
  UPDATE_TEST_END_TIME_REQUESTED,
  CLONE_TEST_REQUESTED,
  UPDATING_CUTOFF_REQUESTED,
} from "../actionTypes";
import {
  fetchTestSuccess,
  fetchTestFailure,
  submitTestSuccess,
  submitTestFailure,
  deleteTestSuccess,
  deleteTestFailure,
  assignTestSuccess,
  assignTestFailure,
  fetchAllTestsSuccess,
  fetchStudentResponseSuccess,
  fetchQuizProctoringDetailsSuccess,
  fetchTestAutoSuccess,
  fetchTestAutoFailure,
  fetchAllTestsFailure,
  fetchStudentResponseFailure,
  fetchQuizProctoringDetailsFailure,
  fetchTestScoreFailure,
  fetchTestScoreSuccess,
  deleteResponseSuccess,
  deleteResponseFailure,
  fetchCompaniesSuccess,
  assignTestProctorSuccess,
  assignTestProctorFailure,
  fetchTestAssignSuccess,
  submitFeedbackSuccess,
  submitFeedbackFailure,
  sendEmailsSuccess,
  sendEmailsFailure,
  fetchAllGroupDetailsSuccess,
  addGroupDetailsSuccess,
  updateTestDeleteModalActive,
  deleteGroupDetailsSuccess,
  updateGroupDetailsSuccess,
  fetchQuizProctoringDetails,
  fetchStudentResponse,
  updatePublicTestModal,
  updatePublicTestRegisteredDetails,
  fetchStudentResponseFailureFromRedis,
  fetchStudentResponseSuccessFromRedis,
  fetchUserTablesSuccess,
  fetchUserTablesFailure,
  updateTestEndTimeSuccess,
  updateTestEndTimeFailure,
  cloneTestSuccess,
  cloneTestFailure,
  updatingCutoffSuccess,
  updatingCutoffFailure,
} from "../actions/test";
import {
  fetchTest,
  submitTest,
  deleteTest,
  assignTest,
  fetchTests,
  fetchQuizProctoring,
  fetchTestResponse,
  fetchTestScore,
  deleteResponse,
  fetchCompanies,
  assignTestProctor,
  fetchTestAssign,
  fetchTestProctorAssign,
  submitFeedback,
  sendEmails,
  sendTestInviteEmails,
  sendReminderEmails,
  addGroupDetails,
  fetchAllGroupDetails,
  deleteGroupDetails,
  updateGroupDetails,
  updatePublicTestStudentDetailsService,
  updateOverallRank,
  fetchTestResponseFromRedis,
  createStudentSchemaService,
  updateEndTimeDuration,
  cloneTestApi,
  updatingCutoffApi,
} from "../../services/test.service";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../assets/js/toast_config";
import { fetchTurnServerCreds } from "../actions/turnserver";
import { fetchMyProfile } from "../actions/user";
import { getAllTables } from "../../services/questionbank.service";

function* fetchTestSaga(action) {
  try {
    const test = yield call(fetchTest, action.payload.data);
    if (!test.is_public) {
      yield put(fetchMyProfile());
    } else if (test.is_public) {
      yield put(updatePublicTestModal(true));
    }
    if (test.is_proctoring) {
      yield put(fetchTurnServerCreds());
      yield put(fetchQuizProctoringDetails(test._id));
    }
    yield put(fetchTestSuccess(test, action.payload.options));
  } catch (e) {
    toast.error(e.msg, defaultToastSetting);
    yield put(fetchTestFailure(e));
  }
}

function* fetchTestAutoSaga(action) {
  try {
    const testAuto = action.payload.allow_flexible_exam_time
      ? yield call(fetchTestResponseFromRedis, action.payload.id)
      : yield call(fetchTest, action.payload.id);
    // console.log("testAuto saga", testAuto);
    yield put(
      fetchTestAutoSuccess({ data: testAuto, payload: action.payload })
    );
  } catch (e) {
    yield put(fetchTestAutoFailure(e));
  }
}

function* fetchTestsSaga(action) {
  try {
    const tests = yield call(fetchTests, action.payload);
    yield put(fetchAllTestsSuccess(tests, action.payload));
  } catch (e) {
    // console.log("saga", e);
    yield put(fetchAllTestsFailure({ msg: e }));
  }
}

function* submitTestSaga(action) {
  try {
    const test = yield call(submitTest, action.payload);
    localStorage.removeItem("registered_details");
    yield put(submitTestSuccess(test));
  } catch (e) {
    yield put(submitTestFailure(e));
  }
}

function* deleteTestSaga(action) {
  try {
    const test = yield call(deleteTest, action.payload);
    yield put(deleteTestSuccess(test));
    window.location.reload();
  } catch (e) {
    toast.error(e.msg, defaultToastSetting);
    yield put(deleteTestFailure({ msg: e.msg }));
  }
}

function* assignTestSaga(action) {
  try {
    const test = yield call(assignTest, action.payload);
    toast.success("Students assigned successfully.", defaultToastSetting);
    yield put(assignTestSuccess(test));
  } catch (e) {
    toast.error("Failed to assign students.", defaultToastSetting);
    yield put(assignTestFailure(e));
  }
}

function* assignTestProctorSaga(action) {
  try {
    const test = yield call(assignTestProctor, action.payload);
    toast.success("Proctors assigned successfully.", defaultToastSetting);
    yield put(assignTestProctorSuccess(test));
  } catch (e) {
    toast.error("Failed to assign proctors.", defaultToastSetting);
    yield put(assignTestProctorFailure(e));
  }
}

function* fetchStudentResponseSaga(action) {
  try {
    const response = yield call(fetchTestResponse, action.payload);
    yield put(fetchStudentResponseSuccess(response));
  } catch (e) {
    yield put(fetchStudentResponseFailure({ msg: e.message }));
  }
}

function* fetchStudentResponseFomRedisSaga(action) {
  try {
    const response = yield call(fetchTestResponseFromRedis, action.payload);
    yield put(fetchStudentResponseSuccessFromRedis(response));
  } catch (e) {
    yield put(fetchStudentResponseFailureFromRedis({ msg: e.message }));
  }
}

function* fetchQuizProctoringDetailsSaga(action) {
  try {
    const response = yield call(fetchQuizProctoring, action.payload);
    yield put(fetchQuizProctoringDetailsSuccess(response));
  } catch (e) {
    yield put(fetchQuizProctoringDetailsFailure({ msg: e.message }));
  }
}

function* fetchTestScoreSaga(action) {
  try {
    const response = yield call(fetchTestScore, action.payload);
    yield put(fetchTestScoreSuccess(response));
  } catch (e) {
    yield put(fetchTestScoreFailure({ msg: e.message }));
  }
}

function* deleteResponseSaga(action) {
  try {
    const response = yield call(deleteResponse, action.payload);
    yield put(deleteResponseSuccess(response));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(deleteResponseFailure({ msg: e.message }));
  }
}

function* fetchComapaniesSaga(action) {
  try {
    const response = yield call(fetchCompanies, action.payload);
    yield put(fetchCompaniesSuccess(response));
  } catch (e) {
    console.log(e);
    // yield put(deleteResponseFailure(e));
  }
}

function* fetchTestAssignSaga(action) {
  try {
    let response = [];
    if (action.payload.key == "assign") {
      response = yield call(fetchTestAssign, action.payload.id);
    } else if (action.payload.key == "proctor_assign") {
      response = yield call(fetchTestProctorAssign, action.payload.id);
    }
    yield put(
      fetchTestAssignSuccess({ key: action.payload.key, data: response })
    );
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    console.log(e);
    // yield put(deleteResponseFailure(e));
  }
}

function* submitFeedbackSaga(action) {
  try {
    const response = yield call(submitFeedback, action.payload);
    yield put(submitFeedbackSuccess(response));
  } catch (e) {
    yield put(submitFeedbackFailure({ msg: e.msg }));
  }
}

function* sendEmailsSaga(action) {
  try {
    const response = yield call(sendEmails, action.payload);
    if (response.success) {
      toast.success("Email sent successfully to candidates", {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(
        sendEmailsSuccess({ msg: "Email sent successfully to candidates" })
      );
    }
  } catch (e) {
    toast.error(e.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(sendEmailsFailure({ msg: e.message }));
  }
}

function* sendTestInviteEmailsSaga(action) {
  try {
    const response = yield call(sendTestInviteEmails, action.payload);
    if (response.success) {
      toast.success("Email sent successfully to candidates", {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(
        sendEmailsSuccess({ msg: "Email sent successfully to candidates" })
      );
    }
  } catch (e) {
    toast.error(e.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(sendEmailsFailure({ msg: e.message }));
  }
}

function* sendReminderEmailsSaga(action) {
  try {
    const response = yield call(sendReminderEmails, action.payload);
    toast.success("Email sent successfully to candidates", {
      position: toast.POSITION.TOP_RIGHT,
    });
    if (response.reminded) {
      yield put(
        sendEmailsSuccess({ msg: "Email sent successfully to candidates" })
      );
    }
  } catch (e) {
    toast.error(e.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    yield put(sendEmailsFailure({ msg: e.message }));
  }
}

function* addGroupDetailsSaga(action) {
  try {
    const response = yield call(addGroupDetails, action.payload);
    yield put(addGroupDetailsSuccess(response));
  } catch (e) {
    toast.error("Could not add group", defaultToastSetting);
  }
}

function* updateGroupDetailsSaga(action) {
  try {
    const response = yield call(updateGroupDetails, action.payload);
    yield put(updateGroupDetailsSuccess({ response, id: action.payload.id }));
  } catch (e) {
    toast.error("Could not update group", defaultToastSetting);
  }
}

function* updatePublicTestStudentDetailsSaga(action) {
  try {
    const response = yield call(
      updatePublicTestStudentDetailsService,
      action.payload
    );
    yield put(updatePublicTestRegisteredDetails(response));
    toast.success("Registered Successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (e) {
    toast.error("Duplicate entry", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchAllGroupDetailsSaga(action) {
  try {
    console.log(action.payload);
    const response = yield call(fetchAllGroupDetails, action.payload.type);
    yield put(fetchAllGroupDetailsSuccess(response));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    console.log(e);
  }
}

function* deleteGroupDetailsSaga(action) {
  try {
    const response = yield call(deleteGroupDetails, action.payload);
    yield put(deleteGroupDetailsSuccess());
  } catch (e) {
    toast.error("Could not delete the group", defaultToastSetting);
  }
}

function* updateOverallRankSaga(action) {
  try {
    const response = yield call(updateOverallRank, action.payload);
    if (response.success) {
      toast.success("Overall rank updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
  }
}

function* fetchUserTablesSaga(action) {
  try {
    const tables = yield call(getAllTables);
    yield put(fetchUserTablesSuccess(tables.data));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(fetchUserTablesFailure(e));
  }
}
function* createSchemaForStudentSaga(action) {
  try {
    yield call(createStudentSchemaService, action.payload);
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
  }
}

function* updateTestEndTimeSaga(action) {
  try {
    const response = yield call(updateEndTimeDuration, action.payload);
    yield put(updateTestEndTimeSuccess(response.data));
  } catch (e) {
    yield put(updateTestEndTimeFailure());
    toast.error(e.message, defaultToastSetting);
  }
}

function* cloneTest(action) {
  try {
    const { id, type } = action.payload;
    const response = yield call(cloneTestApi, id);
    console.log(response);
    yield put(
      cloneTestSuccess({ id, type, res: response.clonedQuizPopulated })
    );
  } catch (e) {
    yield put(cloneTestFailure());
    toast.error(e.msg, defaultToastSetting);
  }
}

function* updatingCutoff(action) {
  try {
    // const { id, type } = action.payload;
    const response = yield call(updatingCutoffApi, action.payload);
    yield put(updatingCutoffSuccess());
  } catch (e) {
    yield put(updatingCutoffFailure());
    toast.error(e.message, defaultToastSetting);
  }
}

function* testSaga() {
  yield takeEvery(FETCH_TEST_REQUESTED, fetchTestSaga);
  yield takeEvery(FETCH_TEST_AUTO, fetchTestAutoSaga);
  yield takeEvery(SUBMIT_TEST_REQUESTED, submitTestSaga);
  yield takeEvery(DELETE_TEST_REQUESTED, deleteTestSaga);
  yield takeEvery(ASSIGN_TEST_REQUESTED, assignTestSaga);
  yield takeEvery(ASSIGN_TEST_PROCTOR_REQUESTED, assignTestProctorSaga);
  yield takeEvery(FETCH_ALL_TESTS, fetchTestsSaga);
  yield takeEvery(FETCH_STUDENT_RESPONSE, fetchStudentResponseSaga);
  yield takeEvery(
    FETCH_QUIZ_PROCTORING_DETAILS,
    fetchQuizProctoringDetailsSaga
  );
  yield takeEvery(
    FETCH_STUDENT_RESPONSE_FROM_REDIS,
    fetchStudentResponseFomRedisSaga
  );
  yield takeEvery(FETCH_TEST_SCORE_REQUESTED, fetchTestScoreSaga);
  yield takeEvery(DELETE_RESPONSE_REQUESTED, deleteResponseSaga);
  yield takeEvery(FETCH_COMPANIES_REQUESTED, fetchComapaniesSaga);
  yield takeEvery(FETCH_TEST_ASSIGN_REQUESTED, fetchTestAssignSaga);
  yield takeEvery(SUBMIT_FEEDBACK_REQUESTED, submitFeedbackSaga);
  yield takeEvery(SEND_EMAILS_REQUESTED, sendEmailsSaga);
  yield takeEvery(SEND_TEST_INVITE_EMAILS_REQUESTED, sendTestInviteEmailsSaga);
  yield takeEvery(SEND_REMINDER_EMAILS_REQUESTED, sendReminderEmailsSaga);
  yield takeEvery(ADD_GROUP_DETAILS_REQUESTED, addGroupDetailsSaga);
  yield takeEvery(UPDATE_GROUP_DETAILS_REQUESTED, updateGroupDetailsSaga);
  yield takeEvery(FETCH_ALL_GROUP_DETAILS_REQUESTED, fetchAllGroupDetailsSaga);
  yield takeEvery(DELETE_GROUP_DETAILS_REQUESTED, deleteGroupDetailsSaga);
  yield takeEvery(
    UPDATE_PUBLIC_TEST_STUDENT_DETAILS,
    updatePublicTestStudentDetailsSaga
  );
  yield takeEvery(UPDATE_OVERALL_RANK_REQUESTED, updateOverallRankSaga);
  yield takeEvery(FETCH_USER_TABLES_REQUESTED, fetchUserTablesSaga);
  yield takeEvery(CREATE_STUDENT_SCHEMA, createSchemaForStudentSaga);
  yield takeEvery(UPDATE_TEST_END_TIME_REQUESTED, updateTestEndTimeSaga);
  yield takeEvery(CLONE_TEST_REQUESTED, cloneTest);
  yield takeEvery(UPDATING_CUTOFF_REQUESTED, updatingCutoff);
}

export default testSaga;
