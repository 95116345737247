import React, { memo } from 'react'
import { useEffect } from 'react';
import Countdown from 'react-countdown'

const CountDownResponse = memo(({ date, stopRecording, setIsAnswerTimeCompleted }) => {
    const startDate = React.useRef(Date.now());
    const rendererStopRecording = ({ minutes, seconds, completed }) => {
        console.log(completed, "ijini");
        if (completed) {
            stopRecording();
            setIsAnswerTimeCompleted();

            return <div className="text-md">00:00</div>;
        } else {
            return (
                <span>
                    {("0" + minutes).slice(-2)}M {("0" + seconds).slice(-2)}S
                </span>
            );
        }
    };


    // useEffect(() => {

    //     const beforeUnloadCallback = (event) => {
    //         console.log("che")
    //         event.preventDefault()
    //         event.returnValue = "x"
    //         return "x"
    //     }
    //     window.addEventListener('beforeunload', beforeUnloadCallback)
    //     return () => {
    //         window.removeEventListener('beforeunload', beforeUnloadCallback)
    //     }

    // }, [])

    return (
        <div>
            <Countdown
                date={startDate.current + date}
                renderer={rendererStopRecording}
            />
        </div>
    )
});

export default CountDownResponse;
