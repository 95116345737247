import React, { Component } from "react";
import { ReactComponent as TestLogo } from "../../assets/img/svg/spreadsheet.svg";
import { ReactComponent as Analytics } from "../../assets/img/icons/analytics.svg";
import { ReactComponent as Companies } from "../../assets/img/icons/companies.svg";
import { ReactComponent as Eye } from "../../assets/img/icons/eye.svg";
import { ReactComponent as Students } from "../../assets/img/icons/students.svg";
import { ReactComponent as StudentsL } from "../../assets/img/icons/students_left.svg";
import load from "../../assets/img/svg/load.svg";
import HistogramChart from "../analytics/HistogramChart";
import DashboardTable from "./DashBoardTable";
import DashboardCard from "./DashboardCard";
import {
  fetchAllTests,
  fetchCompaniesRequested,
} from "../../store/actions/test";
import { connect } from "react-redux";
import testService, {
  fetchTestSubmissionsCSV,
} from "../../services/test.service";
import {
  fetchHrGraphStatsRequested,
  fetchHrSummaryStatsRequested,
} from "../../store/actions/hrdashboard";
import { viewFile } from "../../services/upload.service";
import { DropDown } from "./ReactSelect/ReactSelect";
import { formateDate } from "../tests/utils/TimeFormat";
import UniversalButton from "./StandardButton/UniversalButton";
import { withRouter } from "./WithRouter";

class HrDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: "",
      selectedtests: null,
      allTestOptions: [],
      studentList: [],
      testToggle: false,
      filter_date: {
        from_date: "",
        to_date: "",
      },
    };
  }

  componentDidMount() {
    this.props.fetchHrSummaryStatsRequested({
      from_date: "",
      to_date: "",
    });
    this.props.fetchAllTests({
      quiz_type: "all",
      status: true,
      is_a_set: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.allTests?.all !== this.props?.allTests?.all) {
      const Options = this.props.allTests.all.map((test) => {
        // console.log("insidedropdown", test, formateDate(test.start_duration));
        return {
          value: test._id,
          label: `${test.title} (${formateDate(test.start_duration)})`,
        };
      });
      this.setState((prev) => {
        return {
          ...prev,
          allTestOptions: Options,
        };
      });
    }
  }

  // onChangeTest(e) {
  //   const id = e.target.value;
  //   const { selectedtests } = this.state;
  //   if (selectedtests.indexOf(id) > -1) {
  //     let newTest = [...selectedtests.filter((e) => e !== id)];
  //     this.setState(
  //       (prev) => {
  //         return {
  //           ...prev,
  //           selectedtests: newTest,
  //         };
  //       },
  //       () => this.loadStudents()
  //     );
  //   } else {
  //     this.setState(
  //       (prev) => {
  //         return {
  //           ...prev,
  //           selectedtests: [...prev.selectedtests, id],
  //         };
  //       },
  //       () => this.loadStudents()
  //     );
  //   }
  // }

  onChangeCompany(e) {
    this.setState(
      (prev) => {
        return {
          ...prev,
          company: e.target.value,
        };
      },
      () =>
        this.props.fetchAllTests({
          quiz_type: "all",
          company: e.target.value,
          status: true,
        })
    );
  }

  loadStudents() {
    let selectStudentIds = this.state.selectedtests
      ? this.state.selectedtests?.map((student) => student.value)
      : [];
    // let id = selectStudentIds.join(",");

    this.props.fetchHrGraphStatsRequested({
      ids: selectStudentIds,
      ...this.state.filter_date,
    });
    this.props.fetchHrSummaryStatsRequested({
      quiz: selectStudentIds,
      ...this.state.filter_date,
    });
    if (
      selectStudentIds?.length !== 0 ||
      this.state.filter_date?.from_date !== "" ||
      this.state.filter_date?.to_date !== ""
    ) {
      testService
        .getAssignedUsers({ quiz: selectStudentIds, ...this.state.filter_date })
        .then(
          (response) => {
            this.setState({ studentList: response.data });
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.setState({ studentList: [] });
    }
  }

  handleAllTests() {
    this.setState(
      (prev) => {
        return {
          ...prev,
          allTestsSelected: !prev.allTestsSelected,
        };
      },
      () => this.assignAllTests()
    );
  }

  // assignAllTests() {
  //   const { allTestsSelected } = this.state;
  //   if (allTestsSelected) {
  //     let tests = this.props.allTests?.all?.map((test) => {
  //       return test._id;
  //     });
  //     this.setState(
  //       (prev) => {
  //         return {
  //           ...prev,
  //           selectedtests: [...tests],
  //         };
  //       },
  //       () => this.loadStudents()
  //     );
  //   } else {
  //     this.setState(
  //       (prev) => {
  //         return {
  //           ...prev,
  //           selectedtests: [],
  //           studentList: [],
  //         };
  //       },
  //       () => this.loadStudents()
  //     );
  //   }
  // }

  onChangeUniversal(e) {
    const { name, value } = e.target;
    let selectStudentIds =
      this.state.selectedtests !== null
        ? this.state.selectedtests.map((student) => student.value)
        : [];
    this.setState(
      (prev) => {
        return {
          ...prev,
          filter_date: {
            ...prev.filter_date,
            [name]: value,
          },
        };
      },
      () => this.loadStudents()
    );
    this.props.fetchAllTests({
      quiz_type: "all",
      ...this.state.filter_date,
      [name]: value,
      status: true,
    });
    // this.props.fetchHrSummaryStatsRequested({
    //   ...this.state.filter_date,
    //   [name]: value,
    //   quiz: selectStudentIds,
    // });
  }

  toggleTestDropdown() {
    this.setState((prev) => {
      return {
        ...prev,
        testToggle: !prev.testToggle,
      };
    });
  }

  downloadCSVReport = () => {
    const { selectedtests } = this.state;
    let selectStudentIds = selectedtests.map((student) => student.value);
    fetchTestSubmissionsCSV({ "ids": [...selectStudentIds] }).then(async (data) => {
      const fileURL = await viewFile(data.csv_file, "TEMPORARY");
      window.open(fileURL);
    });
  };

  handleChange = (value) => {
    this.setState({ selectedtests: value }, () => this.loadStudents());
  };

  render() {
    const { companies, allTests, summary, graph_stats } = this.props;
    return (
      <div className="p-3 sm:p-5">
        <h1 className="text-2xl font-semibold font-['poppins']">
          Welcome {this.props.currentUser}
        </h1>
        <div className="flex  justify-between items-center w-full gap-x-5">
          {/* <select
            id="small"
            className="block p-2 font-['poppins'] font-medium  text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            onChange={(e) => this.onChangeCompany(e)}
            value={this.state.company}
          >
            <option defaultChecked>Select company</option>
            {companies.map((company) => {
              return (
                <option key={company._id} value={company._id}>
                  {company.name}
                </option>
              );
            })}
          </select> */}
          {/* <select
            id="small"
            class="block p-2 font-['poppins'] font-medium  text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          >
            <option selected>Select job role</option>
            <option value="US">United States</option>
          </select> */}
          {/* <select
            id="small"
            class="block p-2 font-['poppins'] font-medium  text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            onChange={(e) => this.onChangeTest(e)}
            value={this.state.test}
          >
            <option selected>Select test</option>
            {allTests?.all.map((test) => {
              return (
                <>
                  {test.title && <option value={test._id}>{test.title}</option>}
                </>
              );
            })}
          </select> */}

          <div
            className="relative min-w-[300px] font-['nunito']"
            id="dropdownSearchButton"
          >
            {/* <h3 className="font-['poppins'] text-md font-medium">
              {" "}
              Select Test{" "}
            </h3> */}
            {/* <button
              data-dropdown-toggle="dropdownSearch"
              className="inline-flex items-center w-full py-2 px-4 block p-2 font-['poppins'] font-medium  text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              type="button"
              onClick={() => {
                this.toggleTestDropdown();
              }}
            >
              Select test
              <svg
                className="ml-auto w-4 h-4"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button> */}

            <DropDown
              placeholder="Select tests"
              value={this.state.selectedtests}
              options={this.state.allTestOptions}
              handleChange={this.handleChange}
              multi={true}
              width={200}
            />

            {/* {this.state.testToggle && (
              <ul
                className="overflow-y-scroll absolute bg-white h-[250px] w-full text-sm text-gray-700 dark:text-gray-200"
                style={{
                  boxShadow: "5px 5px 24px rgba(41,43,88,0.12)",
                  borderRadius: "0 0 10px 10px",
                }}
              >
                <li>
                  <div className="flex items-center p-2 rounded hover:bg-gray-100 cursor-pointer">
                    <input
                      type="checkbox"
                      onChange={() => this.handleAllTests()}
                      checked={this.state.allTestsSelected}
                      className="w-4 h-4 text-blue-600 bg-gray-100 cursor-pointer rounded border-gray-300"
                    />
                    <label className="ml-2 w-full text-sm font-medium cursor-pointer text-gray-500">
                      Select all tests
                    </label>
                  </div>
                </li>
                {allTests?.all.map((test, index) => {
                  return (
                    <>
                      <li key={test._id}>
                        <div className="flex items-center p-2 rounded hover:bg-gray-100 cursor-pointer">
                          <input
                            type="checkbox"
                            name={test.title}
                            id={test._id}
                            value={test._id}
                            onChange={(e) => this.onChangeTest(e)}
                            checked={
                              this.state.selectedtests.indexOf(test._id) > -1
                            }
                            className="w-4 h-4 text-blue-600 bg-gray-100 cursor-pointer rounded border-gray-300"
                          />
                          <label
                            htmlFor={test._id}
                            className="ml-2 w-full text-sm font-medium cursor-pointer text-gray-500"
                          >
                            {test.title}
                          </label>
                        </div>
                      </li>
                    </>
                  );
                })}
              </ul>
            )} */}
          </div>
          <div className="flex gap-x-2">
            <div>
              <h3 className="font-['poppins'] text-md font-medium">From : </h3>
              <input
                type="date"
                name="from_date"
                value={this.state.from_date}
                onChange={(e) => this.onChangeUniversal(e)}
                className="block p-2 font-['poppins'] font-medium  text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <h3 className="font-['poppins'] text-md font-medium">To : </h3>
              <input
                type="date"
                name="to_date"
                value={this.state.to_date}
                onChange={(e) => this.onChangeUniversal(e)}
                className="block p-2 font-['poppins'] font-medium  text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
          {/* <select
            id="small"
            className="block ml-auto p-2 font-['poppins'] font-medium  text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          >
            <option defaultValue>Last 30 days</option>
            <option value="US">United States</option>
          </select> */}
        </div>
        <h1 className="text-lg text-[#37474F] font-medium font-['poppins'] pt-2">
          Quick Stats
        </h1>
        <div className="flex w-full gap-x-8 justify-between py-3">
          <DashboardCard
            svg={
              <TestLogo
                color="#566474"
                alt="icon"
                className="w-[25px] h-[25px]"
              />
            }
            value={summary?.num_tests}
            text="Total Tests"
          />
          <DashboardCard
            svg={
              <Students
                fill="#566474"
                color="#666666"
                alt="icon"
                className="w-[25px] h-[25px]"
              />
            }
            value={summary?.num_students}
            text="Total students"
          />
          <DashboardCard
            svg={
              <Companies
                fill="#566474"
                alt="icon"
                className="w-[25px] h-[25px]"
              />
            }
            value={summary?.num_institutes}
            text="Total Institutes"
          />
          {/* <DashboardCard
            svg={
              <Eye fill="#566474" alt="icon" className="w-[25px] h-[25px]" />
            }
            value="50"
            text="Cheating caught"
          /> */}
        </div>

        <div className="flex w-full py-10 gap-x-10">
          <div className="w-1/2 p-5 shadow-lg shadow-[#e1f7b5] rounded-2xl">
            <HistogramChart
              data={this.props.graph_data}
              options={this.props.graph_options}
            />
          </div>
          <div className="w-1/2 flex gap-x-5">
            <DashboardCard
              svg={
                <StudentsL
                  fill="#566474"
                  alt="icon"
                  className="w-[25px] h-[25px]"
                />
              }
              value={graph_stats?.students_assigned || 0}
              text="Students attempted"
            />
            <DashboardCard
              svg={
                <Analytics
                  fill="#566474"
                  alt="icon"
                  className="w-[25px] h-[25px]"
                />
              }
              value={graph_stats?.normalized_mean?.toFixed(2) || 0}
              text={"Normalized mean"}
            />
          </div>
        </div>
        {this.props.loading ? (
          <img alt="loading" src={load} className="mx-auto spinner w-6 h-6" />
        ) : (
          <div className="flex w-full">
            <DashboardTable studentList={this.state.studentList} type='H' />
          </div>
        )}
        {this.state.studentList.length !== 0 && (
          <div className="flex w-full pt-2">
            <UniversalButton onClickFunction={() => this.downloadCSVReport()} title="Download Report" classNames="ml-auto" />
          </div>
          // <button
          //   onClick={this.downloadCSVReport}
          //   type="button"
          //   className="flex ml-auto text-white border border-[#047575] bg-[#047575] hover:text-[#047575]
          //   hover:bg-white text-['nunito'] font-semibold uppercase text-sm
          // px-4 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150
          // disabled:opacity-25 disabled:cursor-not-allowed disabled:bg-gray-500"
          // >
          //   Download Report
          // </button>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ test, hrdashboard }) => {
  return {
    companies: test.companies,
    allTests: test.allTests,
    summary: hrdashboard.summary,
    graph_stats: hrdashboard.graph_stats,
    graph_data: hrdashboard.graph_data,
    graph_options: hrdashboard.graph_options,
    loading: hrdashboard.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompaniesRequested: () => dispatch(fetchCompaniesRequested()),
    fetchAllTests: (data) => dispatch(fetchAllTests(data)),
    fetchHrSummaryStatsRequested: (data) =>
      dispatch(fetchHrSummaryStatsRequested(data)),
    fetchHrGraphStatsRequested: (data) =>
      dispatch(fetchHrGraphStatsRequested(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HrDashboard));
