export const FETCH_ALL_EMAIL_TEMPLATES_REQUESTED =
  "FETCH_ALL_EMAIL_TEMPLATES_REQUESTED";

export const FETCH_ALL_EMAIL_TEMPLATES_SUCCESS =
  "FETCH_ALL_EMAIL_TEMPLATES_SUCCESS";

export const FETCH_ALL_EMAIL_TEMPLATES_FAILURE =
  "FETCH_ALL_EMAIL_TEMPLATES_FAILURE";

export const ADD_EMAIL_TEMPLATE_REQUESTED = "ADD_EMAIL_TEMPLATE_REQUESTED";

export const ADD_EMAIL_TEMPLATE_SUCCESS = "ADD_EMAIL_TEMPLATE_SUCCESS";

export const ADD_EMAIL_TEMPLATE_FAILURE = "ADD_EMAIL_TEMPLATE_FAILURE";

export const UPDATE_EMAIL_TEMPLATE_REQUESTED =
  "UPDATE_EMAIL_TEMPLATE_REQUESTED";

export const UPDATE_EMAIL_TEMPLATE_SUCCESS = "UPDATE_EMAIL_TEMPLATE_SUCCESS";

export const UPDATE_EMAIL_TEMPLATE_FAILURE = "UPDATE_EMAIL_TEMPLATE_FAILURE";

export const DELETE_EMAIL_TEMPLATE_REQUESTED =
  "DELETE_EMAIL_TEMPLATE_REQUESTED";

export const DELETE_EMAIL_TEMPLATE_SUCCESS = "DELETE_EMAIL_TEMPLATE_SUCCESS";

export const DELETE_EMAIL_TEMPLATE_FAILURE = "DELETE_EMAIL_TEMPLATE_FAILURE";

export const UPDATE_EMAIL_TEMPLATE_MODAL = "UPDATE_EMAIL_TEMPLATE_MODAL";

export const UPDATE_EMAIL_TEMPLATE_WITH_KEY = "UPDATE_EMAIL_TEMPLATE_WITH_KEY";
