import { call, put, takeEvery } from "redux-saga/effects";
import { FETCH_PROCTORED_USER_DETAILS_REQUESTED } from "../actionTypes/userdetails";
import {
  fetchProctoredUserDetailsSuccess,
  fetchProctoredUserDetailsFailure,
} from "../actions/userdetails";

import { getProctoredUserDetails } from "../../services/proctor.service";

function* fetchProctoredUserDetails(action) {
  try {
    const user_details = yield call(getProctoredUserDetails, action.payload);
    yield put(fetchProctoredUserDetailsSuccess(user_details));
  } catch (e) {
    yield put(fetchProctoredUserDetailsFailure(e));
  }
}

function* userdetailsSaga() {
  yield takeEvery(
    FETCH_PROCTORED_USER_DETAILS_REQUESTED,
    fetchProctoredUserDetails
  );
}

export default userdetailsSaga;
