import React, { Component } from "react";
import DragComponent from "./DragComponent";
import TestQuestionEditor from "../extras/TestQuestionEditor";
import { ReactComponent as Plus } from "../../../assets/img/svg/plus4.svg";

// Store
import { connect } from "react-redux";
import {
  addTestQuestion,
  deleteTestQuestion,
  duplicateTestQuestion,
  updateQuestionModal,
  updateTestDataOnExchange,
  updateTestQuestionDataWithKey,
} from "../../../store/actions/test";

class AddQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuestion: 0,
      dragData: null,
    };
    this.deleteTestQuestion = this.deleteTestQuestion.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  deleteTestQuestion = (questionIndex, sectionIndex) => {
    this.props.deleteTestQuestion({ questionIndex, sectionIndex });
  };

  onDragStart = (event, sectionIndex, questionIndex) => {
    this.setState(prev => {
      return {
        ...prev,
        dragData: JSON.stringify({ sectionIndex, questionIndex })
      }
    })
  };

  onDrop = (event, sectionIndex, questionIndex) => {
    event.preventDefault();
    const draggedItem = JSON.parse(this.state.dragData);
    if (draggedItem.sectionIndex === sectionIndex || draggedItem.questionIndex !== questionIndex) {
      const _sections = this.props.test.components;
      const updatedQuestions = [...this.props.test.components[sectionIndex].components];
      const [draggedQuestion] = updatedQuestions.splice(draggedItem.questionIndex, 1);
      updatedQuestions.splice(questionIndex, 0, draggedQuestion);
      _sections[sectionIndex].components = updatedQuestions;
      this.props.updateTestDataOnExchange({ ...this.props.test, components: [..._sections] });
    }
  };

  moveQuestionDown = ({ sectionIndex, questionIndex }) => {
    console.log("check", sectionIndex, questionIndex);

    if (
      questionIndex >= 0 &&
      questionIndex < this.props.test.components[sectionIndex].components.length - 1
    ) {
      const _sections = this.props.test.components;
      const updatedItems = [...this.props.test.components[sectionIndex].components];
      const temp = updatedItems[questionIndex];
      updatedItems[questionIndex] = updatedItems[questionIndex + 1];
      updatedItems[questionIndex + 1] = temp;
      _sections[sectionIndex].components = updatedItems;
      this.props.updateTestDataOnExchange({ ...this.props.test, components: [..._sections] });
    }
  };

  moveQuestionUp = ({ sectionIndex, questionIndex }) => {
    if (questionIndex > 0 && questionIndex < this.props.test.components[sectionIndex].components.length) {
      const _sections = this.props.test.components;
      const updatedItems = [...this.props.test.components[sectionIndex].components];
      const [movedItem] = updatedItems.splice(questionIndex, 1);
      updatedItems.splice(questionIndex - 1, 0, movedItem);  // Corrected index here
      _sections[sectionIndex].components = updatedItems;
      // console.log("after move", { ...this.props.test, components: [..._sections] });
      this.props.updateTestDataOnExchange({ ...this.props.test, components: [..._sections] });
    }
  };


  render() {
    const { sectionIndex } = this.props;
    const section = this.props.test.components[sectionIndex];
    return (
      <div className="mt-3">
        {section.components.map((questionData, questionIndex) => (
          <div
            key={sectionIndex + "-" + questionIndex}
            draggable
            droppable
            onDragStart={(e) => this.onDragStart(e, sectionIndex, questionIndex)}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => this.onDrop(e, sectionIndex, questionIndex)}
            test={this.props.test}
          >

            <DragComponent
              type="q"
              sectionIndex={sectionIndex}
              questionIndex={questionIndex}
              test={this.props.test}
              openQuestionBankModal={(data) =>
                this.props.openQuestionBankModal(data)
              }
              moveQuestionDown={(data) => this.moveQuestionDown(data)}
              moveQuestionUp={(data) => this.moveQuestionUp(data)}
              cardState={section?.isOpen}
              content={
                <>
                  <TestQuestionEditor
                    questionIndex={questionIndex}
                    sectionIndex={sectionIndex}
                  />
                </>
              }
              deleteAction={() =>
                this.props.deleteTestQuestion({ questionIndex, sectionIndex })
              }
              duplicateAction={() =>
                this.props.duplicateTestQuestion({ questionIndex, sectionIndex })
              }
            />
          </div>
        ))}
        <div className="flex justify-center text-center">
          <button
            onClick={() => this.props.addTestQuestion({ sectionIndex })}
            className="flex gap-[0.4rem] items-center justify-center text-[#a0db32] border border-[#a0db32] hover:bg-[#001437] hover:text-[#a0db32] font-semibold uppercase text-base px-4 py-2 mb-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150 "
          >
            <Plus className="h-4 w-4 mx-2 [&>*]:!fill-[#a0db32] " />
            Add Question
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    test: test.test,
    questionbank_modal: test.questionbank_modal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTestQuestion: (data) => dispatch(addTestQuestion(data)),
    duplicateTestQuestion: (data) => dispatch(duplicateTestQuestion(data)),
    deleteTestQuestion: (data) => dispatch(deleteTestQuestion(data)),
    updateTestQuestionDataWithKey: (data) =>
      dispatch(updateTestQuestionDataWithKey(data)),
    updateQuestionModal: (data) => dispatch(updateQuestionModal(data)),
    updateTestDataOnExchange: (data) => dispatch(updateTestDataOnExchange(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestion);
