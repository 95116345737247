import React, { Component } from "react";
import { ReactComponent as EyeIcon } from "../assets/img/svg/heye.svg";
import { ReactComponent as EditIcon } from "../assets/img/svg/edit.svg";
import { ReactComponent as DeleteIcon } from "../assets/img/svg/delete.svg";
import { ReactComponent as MoreIcon } from "../assets/img/svg/more.svg";
import { ReactComponent as StartIcon } from "../assets/img/svg/start.svg";
import { ReactComponent as PublicBadge } from "../assets/img/svg/public_badge.svg";
import { ReactComponent as MockBadge } from "../assets/img/svg/mock.svg";
import {
  ConvertTimeToCurrentTimezone,
  getType,
} from "../components/common/utils";
import { ReactComponent as VideoIcon } from "../assets/img/svg/video.svg";
import { ReactComponent as DefaultCompanyIcon } from "../assets/img/svg/default_company_logo.svg";
import dayjs from "dayjs";
import { toggleSidebar } from "../store/actions/sidebar";
import { connect } from "react-redux";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { withRouter } from "../components/common/WithRouter";
import { cloneTestRequest } from "../store/actions/test";
dayjs.extend(utc);
dayjs.extend(timezone);

class CurrentTestCard extends Component {
  constructor() {
    super();
    this.showDropDown = [];
    this.hideDropDown = [];
  }

  handleDropDown = (data_index) => {
    if (this.props.sidebar.opened) {
      this.props.toggleSidebar();
    }
    this.showDropDown[data_index].classList.toggle("hidden");
  };

  showTime = () => {
    return ConvertTimeToCurrentTimezone(this.props.data.start_duration).format(
      "dddd, MMMM D YYYY, h:mm a"
    );
  };

  checkGiveTestEligibility = () => {
    return (
      new Date(this.props.data.end_duration).getTime() > new Date().getTime() &&
      new Date(this.props.data.start_duration).getTime() <=
        new Date().getTime() &&
      !this.props.data.isSubmitted
    );
  };

  checkViewTestEligibility = () => {
    return (
      new Date(this.props.data.start_duration).getTime() >=
        new Date().getTime() ||
      (new Date(this.props.data.end_duration).getTime() >
        new Date().getTime() &&
        new Date(this.props.data.start_duration).getTime() <=
          new Date().getTime())
    );
  };

  onNextPage = (x) => {
    this.props.history(`/tests/welcome-page/${x}`);
    window.location.reload();
  };

  onVideoMonitoring = (x) => {
    window.open(
      `${window.location.protocol}//${window.location.host}/tests/videomonitor/${x}?page=1`,
      "_self"
    );
  };

  componentDidMount() {
    window.addEventListener("click", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { data_index } = this.props;
    if (
      this.hideDropDown[data_index] &&
      !this.hideDropDown[data_index].contains(event.target)
    ) {
      if (!this.showDropDown[data_index].classList.contains("hidden")) {
        this.handleDropDown(data_index);
      }
    }
  };

  isProctor = () => {
    var type = localStorage["priksha_type"];
    return type === "P";
  };
  isSuperProctor = () => {
    var type = localStorage["priksha_type"];
    return type === "SP";
  };

  handleStart = (id) => {
    this.props.history(`/tests/welcome-page/${id}`);
  };

  render() {
    const {
      data,
      data_index,
      handleDeleteTest,
      isAdmin,
      isTeacher,
      isKAInstructor,
      isAssistant,
      type,
    } = this.props;

    return (
      <div className="relative py-2">
        {data.is_public && (
          <PublicBadge className="absolute right-0 top-[8px]" />
        )}
        {!data?.status && <MockBadge className="absolute right-0 top-[8px]" />}
        <div
          className="flex flex-col rounded  border shadow"
          style={{ boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)" }}
        >
          {this.props.data.company ? (
            <img
              src={this.props.data.company.url}
              alt="logo"
              title="Company"
              className="block w-full h-24 flex-none object-contain p-2 self-center"
            />
          ) : (
            <DefaultCompanyIcon className="block w-full h-24 flex-none object-contain p-2 self-center" />
          )}

          <div className=" grow bg-white rounded-b-none rounded-r px-4 py-3 flex flex-col justify-between leading-normal font-['Poppins']">
            <p className="w-full text-center text-[1rem] font-semibold text-ellipsis whitespace-nowrap overflow-hidden leading-tight">
              {data.title ? data.title : ""}
            </p>
            <p className="text-center text-[#666666] text-[0.75rem] mt-1 mb-3 text-ellipsis whitespace-nowrap overflow-hidden">
              {this.showTime()}
            </p>

            <div className="flex mb-3 mt-2 items-center h-4 justify-center gap-[1rem] xl:gap-[1.5rem]">
              {isAdmin() ||
              isTeacher() ||
              isKAInstructor() ||
              isAssistant() ||
              this.isProctor() ||
              this.isSuperProctor() ? (
                <>
                  {getType() === "P" ? (
                    <button
                      className="hover:bg-gray-100 rounded-lg"
                      onClick={() => {
                        if (this.isSuperProctor()) {
                          return window.open(
                            `${window.location.protocol}//${window.location.host}/tests/superproctor/${data._id}`,
                            "_self"
                          );
                        }
                        this.onVideoMonitoring(data._id);
                      }}
                    >
                      <VideoIcon
                        className="w-5 h-5"
                        alt="svg"
                        title="Video Monitoring"
                        color="#a0db32"
                      />
                    </button>
                  ) : null}

                  {!this.isProctor() && !this.isSuperProctor() && (
                    <>
                      <button
                        className="hover:bg-gray-100 rounded-lg"
                        onClick={() => this.onNextPage(data._id)}
                      >
                        <EyeIcon
                          className="w-6 h-6"
                          alt="svg"
                          title="View Test"
                          color="#a0db32"
                          fill="#a0db32"
                        />
                      </button>
                      <button
                        className="hover:bg-gray-100 rounded-lg"
                        onClick={() =>
                          this.props.history(`/tests/edit/${data._id}`)
                        }
                      >
                        <EditIcon
                          className="w-5 h-5"
                          alt="svg"
                          color="#a0db32"
                          title="Edit Test"
                        />
                      </button>
                      <div className="" style={{ position: "relative" }}>
                        <button
                          ref={(newref) =>
                            (this.hideDropDown[data_index] = newref)
                          }
                          id="dropdownButton"
                          data-dropdown-toggle="dropdown"
                          className="block text-slate-700 hover:bg-gray-100  focus:outline-none focus:ring-4 focus:ring-gray-200 rounded-lg text-sm"
                          type="button"
                          onClick={() => this.handleDropDown(data_index)}
                        >
                          <MoreIcon
                            className="w-5 h-5"
                            alt="svg"
                            color="#a0db32"
                            title="More Details"
                          />
                        </button>
                        <div
                          ref={(newref) =>
                            (this.showDropDown[data_index] = newref)
                          }
                          id="dropdown"
                          className="hidden z-10 w-44 text-base list-none bg-white rounded divide-y divide-gray-100 shadow"
                          style={{
                            position: "absolute",
                            top: "110%",
                            right: 0,
                          }}
                        >
                          <ul
                            style={{ margin: 0, padding: 0 }}
                            className="py-1"
                            aria-labelledby="dropdownButton"
                          >
                            <li
                              className="cursor-pointer"
                              onClick={() =>
                                this.props.cloneTest({
                                  id: data._id,
                                  type,
                                })
                              }
                            >
                              <div className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">
                                Copy
                              </div>
                            </li>
                            <li
                              className="cursor-pointer"
                              onClick={() =>
                                this.props.history(
                                  `/tests/students/${data._id}`
                                )
                              }
                            >
                              <div className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">
                                Submissions
                              </div>
                            </li>
                            {!data.is_public && (
                              <li
                                className="cursor-pointer"
                                onClick={() =>
                                  this.props.history(`/assign-test/${data._id}`)
                                }
                              >
                                <div className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">
                                  Assign Test
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                      <button
                        className="hover:bg-gray-100 rounded-lg"
                        onClick={() => handleDeleteTest(data._id)}
                      >
                        <DeleteIcon
                          className="w-5 h-5 "
                          alt="svg"
                          title="Delete Test"
                        />
                      </button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {this.checkGiveTestEligibility() ? (
                    <button
                      className="hover:bg-gray-100 flex justify-center w-full rounded-lg"
                      onClick={() => this.handleStart(data._id)}
                    >
                      <StartIcon
                        className="w-5 h-5"
                        color="#a0db32"
                        alt="svg"
                        title="Give Test"
                      />
                    </button>
                  ) : (
                    <>
                      {/* {data?.is_aits && data?.candidate_report_id && (
                        <div
                          onClick={() =>
                            this.props.history(
                              `/tests/candidatereport/${data?.candidate_report_id}`
                            )
                          }
                        >
                          <button
                            className="hover:bg-gray-100 rounded-lg p-2"
                            style={{
                              display: this.checkViewTestEligibility()
                                ? "none"
                                : "block",
                            }}
                          >
                            <EyeIcon
                              className="w-5 h-5"
                              alt="svg"
                              title="View Test"
                              fill="#009696"
                            />
                          </button>
                        </div>
                      )} */}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test, sidebar }) => {
  return {
    test: test.test,
    sidebar: sidebar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSidebar: () => dispatch(toggleSidebar()),
    cloneTest: (data) => dispatch(cloneTestRequest(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CurrentTestCard));
