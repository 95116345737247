import React, { Component } from "react";
import { ReactComponent as TestLogo } from "../../assets/img/svg/spreadsheet.svg";
import { ReactComponent as Analytics } from "../../assets/img/icons/analytics.svg";
import { ReactComponent as Companies } from "../../assets/img/icons/companies.svg";
import { ReactComponent as Eye } from "../../assets/img/icons/eye.svg";
import { ReactComponent as Students } from "../../assets/img/icons/students.svg";
import { ReactComponent as StudentsL } from "../../assets/img/icons/students_left.svg";
import HistogramChart from "../analytics/HistogramChart";
import DashboardTable from "./DashBoardTable";
import DashboardCard from "./DashboardCard";

export default class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        // labels: [0.75, 1.25, 1.75, 2.25],
        datasets: [
          {
            label: "x-axis",
            data: [
              { x: 0, y: 10 },
              { x: 1, y: 20 },
              { x: 2, y: 10 },
              { x: 3, y: 10 },
              { x: 4, y: 10 },
              { x: 5, y: 10 },
              { x: 6, y: 50 },
              { x: 7, y: 55 },
              { x: 8, y: 60 },
              { x: 9, y: 40 },
              { x: 10, y: 50 },
            ],
            borderWidth: 1,
            // borderColor: "black",
            backgroundColor: "#B8FB3C",
            barPercentage: 0.8,
            categoryPercentage: 1,
            // hoverBackgroundColor: "red"
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            beginAtZero: true,
            type: "linear",
            ticks: {
              stepSize: 1,
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 10,
            },
          },
        },
      },
    };
  }
  render() {
    return (
      <div className="p-3 sm:p-5">
        <h1 className="text-2xl font-semibold font-['poppins']">
          Welcome {this.props.currentUser}
        </h1>
        {/* <h1 className="text-lg text-[#37474F] font-medium font-['poppins'] pt-2">
          Quick Stats
        </h1>
        <div className="flex w-full gap-x-8 justify-between py-3">
          <DashboardCard
            svg={
              <TestLogo
                fill="#566474"
                alt="icon"
                className="w-[25px] h-[25px]"
              />
            }
            value="50"
            text="Total Tests"
          />
          <DashboardCard
            svg={
              <Students
                fill="#566474"
                alt="icon"
                className="w-[25px] h-[25px]"
              />
            }
            value="50"
            text="Total students"
          />
          <DashboardCard
            svg={
              <Companies
                fill="#566474"
                alt="icon"
                className="w-[25px] h-[25px]"
              />
            }
            value="50"
            text="Total companies"
          />
          <DashboardCard
            svg={
              <Eye fill="#566474" alt="icon" className="w-[25px] h-[25px]" />
            }
            value="50"
            text="Cheating caught"
          />
        </div>

        <div className="flex w-full pt-5 gap-x-5">
          <select
            id="small"
            class="block p-2 font-['poppins'] font-medium  text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          >
            <option selected>Select company</option>
            <option value="US">United States</option>
          </select>
          <select
            id="small"
            class="block p-2 font-['poppins'] font-medium  text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          >
            <option selected>Select job role</option>
            <option value="US">United States</option>
          </select>
          <select
            id="small"
            class="block p-2 font-['poppins'] font-medium  text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          >
            <option selected>Select test</option>
            <option value="US">United States</option>
          </select>
          <select
            id="small"
            class="block ml-auto p-2 font-['poppins'] font-medium  text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          >
            <option selected>Last 30 days</option>
            <option value="US">United States</option>
          </select>
        </div>

        <div className="flex w-full py-10 gap-x-10">
          <div className="w-1/2 p-5 shadow-lg shadow-[#00969626] rounded-2xl">
            <HistogramChart
              data={this.state.data}
              options={this.state.options}
            />
          </div>
          <div className="w-1/2 flex gap-x-5">
            <DashboardCard
              svg={
                <StudentsL
                  fill="#566474"
                  alt="icon"
                  className="w-[25px] h-[25px]"
                />
              }
              value="200/250"
              text="Students attempted"
            />
            <DashboardCard
              svg={
                <Analytics
                  fill="#566474"
                  alt="icon"
                  className="w-[25px] h-[25px]"
                />
              }
              value="200/250"
              text=" Average Scores"
            />
          </div>
        </div> */}

        {/* <div className="flex w-full"> */}

        {/* <DashboardTable /> */}
        {/* </div> */}
      </div>
    );
  }
}
