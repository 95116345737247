import React from "react";
import { toast } from "react-toastify";
import UniversalButton from "../common/StandardButton/UniversalButton";
import AsyncSelect from "react-select/async";
import { BASE_URL } from "../../assets/js/config";
import axios from "axios";
import { useState } from "react";
import Modal from "../common/Modal/Modal";
import { useEffect } from "react";

let local_token = "";

const CollegeModal = ({ handleCollegeChange, collegeValue }) => {
  const [addCollegeLoading, setAddCollegeLoading] = useState(false);
  const [showAddCollegeModal, setShowAddCollegeModal] = useState(false);
  const [n_college_name, setN_college_name] = useState("");

  const handleNewCollegeSave = async () => {
    setAddCollegeLoading(true);
    await axios
      .post(
        `${BASE_URL}api/college`,
        { name: n_college_name },
        {
          headers: {
            "x-auth-token": local_token,
            "x-referer-sec-bool":
              "ZW50QHN0dWRlbnQuY29tIiwiX2lkIjoiNjI1ZWRhNmZkNTJmOTgwZjhmMGM2OTZjIiwidHlwZSI6IlMiLCJpYXQiOjE2NjI2MTYyMTcsImV4cCI6MTY2MjY1OTQxNywiYXVkIjoiaHR0cHM6Ly9hcHAua3lyb24uaW4vIiwiaXNzIjoiS1lST04i",
          },
        }
      )
      .then((response) => {
        setAddCollegeLoading(false);
        setShowAddCollegeModal(false);
        setN_college_name("");

        handleCollegeChange({
          college_id: response.data._id,
          label: response.data.name,
          value: response.data.name,
        });

        toast.success("Your college successfully added", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        setAddCollegeLoading(false);
        setShowAddCollegeModal(false);
        setN_college_name("");

        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const promiseOptions = async (inputValue) => {
    return await axios
      .get(`${BASE_URL}api/college?name=${inputValue}`)
      .then((response) => {
        const arrayMap = response.data.slice(0, 20).map((data) => {
          return { value: data.name, label: data.name, college_id: data._id };
        });
        return arrayMap;
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const debounce = (f, interval) => {
    let timer = null;

    return (...args) => {
      clearTimeout(timer);
      return new Promise((resolve) => {
        timer = setTimeout(() => resolve(f(...args)), interval);
      });
    };
  };

  const fetchTempToken = async () => {
    await axios
      .post(`${BASE_URL}api/auth/temp`)
      .then((response) => {
        local_token = response?.data.token;
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  useEffect(() => {
    fetchTempToken();
  }, []);

  return (
    <>
      <Modal
        onClose={() => setShowAddCollegeModal(false)}
        isModal={showAddCollegeModal}
        showCloseButton
        className={"w-[90%] md:w-[40%]"}
      >
        <h2 className="text-xl font-['poppins'] font-medium pb-4 ">
          Add your college
        </h2>
        <div className="mb-10">
          <label className="font-['poppins'] text-neutral-400 ">
            Your college name<span>*</span>
          </label>
          <br />
          <small>Make sure your college is not available in our list</small>
          <input
            name="college_name"
            placeholder="Enter your text here"
            className="login-form-emailInput uppercase"
            value={n_college_name}
            onChange={(e) => setN_college_name(e.target.value.toUpperCase())}
          />
        </div>

        <UniversalButton
          onClickFunction={() => handleNewCollegeSave()}
          title="Add"
          loading={addCollegeLoading}
        />
      </Modal>

      <AsyncSelect
        menuPlacement="top"
        loadOptions={debounce(promiseOptions, 500)}
        value={collegeValue}
        onChange={(e) => handleCollegeChange(e)}
        className="login-form-selectInput w-full"
        placeholder="Select your college"
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            border: "none",
            background: "#eefecf",
            color: "#87cf00",
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            color: "#87cf00",
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: "rgba(135, 207, 0, 0.7)",
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            color: "#87cf00",
          }),
        }}
        noOptionsMessage={({ inputValue }) =>
          !inputValue ? (
            "Please enter keyword to search your college"
          ) : (
            <button
              onClick={(e) => {
                e.preventDefault();
                setShowAddCollegeModal(true);
              }}
            >
              No results found!
              <span className="login-form-selectInput-button">Click here</span>
              to add your college
            </button>
          )
        }
      />
    </>
  );
};

export default CollegeModal;
