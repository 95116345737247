import React, { useState } from "react";
import { ReactMediaRecorder } from "react-media-recorder";
import { toast } from "react-toastify";
import Modal from "../../common/Modal/Modal";

// Images
import { ReactComponent as Micoff } from "../../../assets/img/svg/mic2off.svg";
import { ReactComponent as Micon } from "../../../assets/img/svg/mic2on.svg";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { getType } from "../../common/utils";
import UniversalButton from "../../common/StandardButton/UniversalButton";
import { useParams } from "react-router-dom";

export const BroadcastModal = ({
  socket,
  handleBroadcastModal,
  broadcastModal,
  type
}) => {
  const [active, setActive] = useState(0);
  const [isTeacherSpeaking, setIsTeacherSpeaking] = useState(false);
  const [isTeacherRecording, setIsTeacherRecording] = useState(false);
  const [voiceMessageState, setVoiceMessageState] = useState("live");
  const [blobUrl, setBlobUrl] = useState(null);
  const [blob, setBlob] = useState(null);
  const [isAudioLoaded, setIsAudioLoaded] = useState(false);
  const [broadcastInputMsg, setBroadcastInputMsg] = useState("");
  const { proctor } = useParams();

  const broadcastAudioRef = useRef();
  const test = useSelector((state) => state.test.test);
  const profile = useSelector((state) => state.user.profile);


  // useEffect(() => {
  //   dispatch(fetchMyProfile())
  //   dispatch(fetchTestRequest())
  // }, [])

  const handleBroadcastVoiceMessage = (mediaBlobUrl) => {
    if (getType() === "P") {
      socket.emit("broadcast-voice-msg-from-proctor", {
        testId: test._id,
        userId: profile._id,
        data: mediaBlobUrl,
      });
    } else {
      socket.emit("broadcast-voice-msg-from-proctor", {
        testId: test._id,
        userId: proctor,
        data: mediaBlobUrl,
      });
    }

    broadcastAudioRef.current = null;

    toast.info("Message sent to all Students", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: false,
    });

    if (voiceMessageState === "live") {
      setIsTeacherSpeaking(!isTeacherSpeaking);
    }
  };

  const handleBroadcastInputChange = (e) => {
    setBroadcastInputMsg(e.target.value);
  };


  const handleSendingBroadcastMessage = () => {
    if (broadcastInputMsg === "") return;

    if (getType() === "P") {
      socket.emit("broadcast-msg-from-proctor", {
        testId: test._id,
        userId: profile._id,
        msg: broadcastInputMsg,
      });
    } else {
      socket.emit("broadcast-msg-from-proctor", {
        testId: test._id,
        userId: proctor,
        msg: broadcastInputMsg,
      });
    }


    toast.info("Message sent to all Students", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: false,
    });

    setBroadcastInputMsg("");
  };

  return (
    <Modal
      onClose={() => handleBroadcastModal(false)}
      isModal={broadcastModal}
      showCloseButton
      className=" w-[80vw] md:w-[60vw] lg:w-[50vw] font-['nunito']"
    >
      <h4 className=" pl-4 text-[0.9rem] font-bold md:text-lg font-bold font-['nunito']">
        Broadcast Message to All Students
      </h4>
      <div className="flex flex-col w-full p-4 ">
        <div className="w-full h-9 flex  gap-2">
          <button
            onClick={() => setActive(0)}
            className={` w-full font-semibold text-[#001437] flex justify-center items-center rounded-md hover:bg-[#BCFB46]  transition-all
               ${active === 0 ? " bg-[#BCFB46]" : "bg-[#F1F1F1]"}`}
          >
            Voice Message
          </button>
          <button
            onClick={() => setActive(1)}
            className={` w-full font-semibold text-[#001437] flex justify-center items-center rounded-md hover:bg-[#BCFB46]  transition-all
               ${active === 1 ? " bg-[#BCFB46]" : "bg-[#F1F1F1]"}`}
          >
            Text Message
          </button>
        </div>
        {active === 0 && (
          <div className="w-full h-[325px] flex flex-col md:flex-row my-4 flex-grow">
            <div className="w-full md:w-1/3 flex flex-col gap-y-2 relative">
              <div
                onClick={() => {
                  if (!isTeacherSpeaking && !isTeacherRecording)
                    setVoiceMessageState("live");
                }}
                className={`px-3 py-2 shadow-md flex justify-start items-center cursor-pointer hover:bg-gray-200 transition-all rounded-l-md gap-x-1 after:content-[""] after:h-10 after:w-[5px] ${voiceMessageState === "live"
                  ? "after:bg-[#BCFB46]"
                  : "after:bg-white-400"
                  } ${isTeacherSpeaking || isTeacherRecording
                    ? "opacity-50"
                    : "opacity-100"
                  } after:absolute after:right-0`}
              >
                Live Voice Message
              </div>
              {/* <div
                    onClick={() =>
                      setState((prev) => {
                        return { ...prev, voiceMessageState: "recorded" };
                      })
                    }
                    className={`px-3 py-2 shadow-md  flex justify-start items-center cursor-pointer hover:bg-gray-200 transition-all rounded-l-md gap-x-1 after:content-[""] after:h-10 after:w-[5px] ${
                      voiceMessageState === "recorded"
                        ? "after:bg-blue-300"
                        : "after:bg-white-400"
                    } after:absolute after:right-0`}
                  >
                    Upload Voice Message
                  </div> */}
              <div
                onClick={() => {
                  if (!isTeacherSpeaking && !isTeacherRecording)
                    setVoiceMessageState("record");
                }}
                className={`px-3 py-2 shadow-md  flex justify-start items-center cursor-pointer hover:bg-gray-200 transition-all rounded-l-md gap-x-1 after:content-[""] after:h-10 after:w-[5px] ${voiceMessageState === "record"
                  ? "after:bg-[#BCFB46]"
                  : "after:bg-white-400"
                  } ${isTeacherSpeaking || isTeacherRecording
                    ? "opacity-50"
                    : "opacity-100"
                  } after:absolute after:right-0`}
              >
                Record and Send
              </div>
            </div>
            <div className="bg-gray-300 w-[0px] md:w-[1px] h-[100%] mx-2"></div>
            <div className="w-full md:w-2/3 flex items-center justify-center p-2 relative">
              {voiceMessageState === "recorded" && (
                <div className="flex justify-between items-stretch flex-col h-full w-full">
                  <div className="flex justify-center items-center w-full">
                    <label
                      for="dropzone-file"
                      className="flex flex-col justify-center items-center w-full h-24 bg-gray-200 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-100 "
                    >
                      <div className="flex flex-col justify-center items-center pt-5 pb-6">
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">Click to upload</span>{" "}
                          or drag and drop
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          MP3 or WAV only (Max 2 MB)
                        </p>
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                      />
                    </label>
                  </div>
                  <div className="flex justify-start items-center flex-col w-full my-4">
                    <h4 className="w-full text-left my-2 font-bold">
                      Edit configuration
                    </h4>
                    <div className="w-full text-left flex my-2 gap-2">
                      <span>No of Repeats: </span>
                      <div>
                        <input
                          type="number"
                          name=""
                          id=""
                          className="border border-gray-300"
                        />
                      </div>
                    </div>
                    <div className="w-full text-left flex my-2 gap-2">
                      <span>Repeat after (in seconds): </span>
                      <div>
                        <input
                          type="number"
                          name=""
                          id=""
                          className="border border-gray-300"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {voiceMessageState === "live" && (
                <div onClick={() => handleBroadcastVoiceMessage(null)}>
                  {isTeacherSpeaking ? (
                    <div className="flex flex-col justify-center items-center cursor-pointer">
                      <Micon
                        alt="Micoff"
                        color={"#a0db32"}
                        title="Mic is off"
                        className="w-10 h-10" />
                      {/* <img
                        src={micon}
                        alt="Micoff"
                        title="Mic off"
                        className="w-10 h-10"
                      /> */}
                      <div id="r1" class="ring"></div>
                      <div id="r2" class="ring"></div>
                      <div id="r3" class="ring"></div>

                      <span className="absolute bottom-8 left-[40%]">
                        Speaking Now
                      </span>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center flex-col cursor-pointer gap-y-3">
                      <div className="flex justify-center items-center rounded-[50%] p-3 border border-red-400 hover:bg-gray-200">
                        <Micoff alt="Micoff"
                          title="Mic is off"
                          className="w-10 h-10" />
                        {/* <img
                          src={micoff}
                          alt="Micoff"
                          title="Mic off"
                          className="w-10 h-10"
                        /> */}
                      </div>
                      <span> Click here to speak</span>
                    </div>
                  )}
                </div>
              )}
              {voiceMessageState === "record" && (
                <div>
                  <ReactMediaRecorder
                    audio={true}
                    askPermissionOnMount
                    onStop={(blobUrl, blob) => {
                      setBlobUrl(blobUrl);
                      setBlob(blob);
                      setIsTeacherRecording(false);
                      broadcastAudioRef.current.src = blobUrl;
                      broadcastAudioRef.current.load();
                      broadcastAudioRef.current.play();
                      broadcastAudioRef.current.muted = true;
                      broadcastAudioRef.current.playbackRate = 16;
                      broadcastAudioRef.current.addEventListener(
                        "ended",
                        (event) => {
                          broadcastAudioRef.current.playbackRate = 1;
                          broadcastAudioRef.current.currentTime = 0;
                          broadcastAudioRef.current.muted = false;
                          setIsAudioLoaded(true);
                        }
                      );
                    }}
                    render={({
                      startRecording,
                      stopRecording,
                      mediaBlobUrl,
                      error,
                    }) => (
                      <div>
                        {error && (
                          <div>
                            There was some error recoridng the audio. Make sure
                            you check your system compatibility{" "}
                            <a href="https://app.kyron.in/systemcompatibility">
                              here
                            </a>
                            .
                          </div>
                        )}
                        {isTeacherRecording ? (
                          <div
                            className="flex flex-col justify-center items-center cursor-pointer"
                            onClick={() => {
                              setIsTeacherRecording(false);
                              stopRecording();
                            }}
                          >
                            <Micon
                              alt="Micon"
                              color={"#a0db32"}
                              title="Mic is on"
                              className="w-10 h-10" />
                            {/* <img
                              src={micon}
                              alt="Micon"
                              title="Mic is on"
                              className="w-10 h-10"
                            /> */}
                            <div id="r1" class="ring"></div>
                            <div id="r2" class="ring"></div>
                            <div id="r3" class="ring"></div>

                            <span className="absolute bottom-8 left-[40%]">
                              Speaking Now
                            </span>
                          </div>
                        ) : (
                          <div className="flex items-center justify-center flex-col cursor-pointer gap-y-3">
                            <div
                              className="flex justify-center items-center rounded-[50%] p-3 border border-red-400 hover:bg-gray-200"
                              onClick={() => {
                                setIsTeacherRecording(true);
                                startRecording();
                              }}
                            >
                              <Micoff alt="Micoff"
                                title="Mic is off"
                                className="w-10 h-10" />

                            </div>
                            <span>
                              {blobUrl
                                ? "Record again"
                                : "Click here to start recording"}
                            </span>

                            <audio
                              src={broadcastAudioRef.current || mediaBlobUrl}
                              className={isAudioLoaded ? "block" : "hidden"}
                              autoPlay
                              controls
                              ref={broadcastAudioRef}
                              onLoadedData={() => setIsAudioLoaded(true)}
                            ></audio>

                            <button
                              className={`text-[#001437]  bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32] my-8 cursor-${mediaBlobUrl ? "pointer" : "normal"
                                } rounded-md shadow opacity-${mediaBlobUrl ? "100" : "50"
                                }  w-3/4 py-2 transition-all`}
                              onClick={() => handleBroadcastVoiceMessage(blob)}
                              disabled={!broadcastAudioRef.current}
                            >
                              Send the message
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {active === 1 && (
          <>
            <textarea
              name="broadcast"
              id="broadcast"
              cols="80"
              rows="10"
              style={{
                resize: "none",
              }}
              onChange={(e) => handleBroadcastInputChange(e)}
              className="p-2 my-2 border border-solid border-black rounded-md outline-none w-full"
              placeholder="Type your message here"
            ></textarea>
            <div className="mt-3 flex justify-center">
              <UniversalButton title="Send to all students" onClickFunction={() => handleSendingBroadcastMessage()} />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default BroadcastModal;
