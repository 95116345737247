import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_HR_GRAPH_STATS_REQUESTED,
  FETCH_HR_QUICK_STATS_REQUESTED,
  FETCH_HR_SUMMARY_STATS_REQUESTED,
} from "../actionTypes";
import {
  getGraphStatsHr,
  getQuickStatsHr,
  getSummaryStatsHr,
} from "../../services/hrdashboard.service";
import {
  fetchHrGraphStatsFailure,
  fetchHrGraphStatsSuccess,
  fetchHrQuickStatsSuccess,
  fetchHrSummaryStatsSuccess,
} from "../actions/hrdashboard";
import { toast } from "react-toastify";

function* fetchHrQuickStatsSaga(action) {
  try {
    const quick_stats = yield call(getQuickStatsHr, action.payload);
    yield put(fetchHrQuickStatsSuccess(quick_stats));
  } catch (e) {
    toast.error("Failed to fetch quick stats", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchHrGraphStatsSaga(action) {
  try {
    const graph_stats = yield call(getGraphStatsHr, action.payload);
    yield put(fetchHrGraphStatsSuccess(graph_stats));
  } catch (e) {
    yield put(fetchHrGraphStatsFailure());
    toast.error("Failed to fetch students summary", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* fetchHrSummaryStatsSaga(action) {
  try {
    const summary_stats = yield call(getSummaryStatsHr, action.payload);
    // console.log("check", summary_stats);
    yield put(fetchHrSummaryStatsSuccess(summary_stats));
  } catch (e) {
    toast.error("Failed to fetch summary", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* hrdashboardSaga() {
  yield takeEvery(FETCH_HR_QUICK_STATS_REQUESTED, fetchHrQuickStatsSaga);
  yield takeEvery(FETCH_HR_GRAPH_STATS_REQUESTED, fetchHrGraphStatsSaga);
  yield takeEvery(FETCH_HR_SUMMARY_STATS_REQUESTED, fetchHrSummaryStatsSaga);
}

export default hrdashboardSaga;
