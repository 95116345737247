import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import { defaultToastSetting } from "../../assets/js/toast_config";
import {
  deleteInterviewService,
  fetchAllInterviews,
  fetchInterviewDetails,
  // getInterviewResponseService,
  submitInterviewResponseService,
  updateCurrentInterviewQuestion,
  updateInterviewQuestion,
  updateSectionLock,
} from "../../services/interviewpanel.service";
import {
  deleteInterviewSuccess,
  fetchAllInterviewsSuccess,
  fetchInterviewDetailsSuccess,
  // getInterviewResponseSuccess,
  submitInterviewSuccess,
  submitOverallFeedbackFailure,
  submitOverallFeedbackSuccess,
  updateCurrentQuestionScoreSuccess,
  updateInterviewQuestionSuccess,
} from "../actions/interviewpanel";
import {
  DELETE_INTERVIEW_REQUESTED,
  FETCH_ALL_INTERVIEWS_REQUESTED,
  FETCH_INTERVIEW_DETAILS_REQUESTED,
  // GET_INTERVIEW_RESPONSE_REQUESTED,
  SET_CURRENT_QUESTION_SCORE_REQUESTED,
  SUBMIT_INTERVIEW_RESPONSE_REQUESTED,
  SUBMIT_OVERALL_FEEDBACK_REQUESTED,
  UPDATE_INTERVIEW_QUESTION_REQUESTED,
} from "../actionTypes/interviewpanel";
import { deleteTestRequest, deleteTestSuccess } from "../actions/test";

function* fetchAllInterviewsSaga(action) {
  try {
    const interviews = yield call(fetchAllInterviews, action.payload);
    if (interviews.success) {
      yield put(
        fetchAllInterviewsSuccess({
          type: action.payload.type,
          interviews: interviews.data,
        })
      );
    }
  } catch (e) {
    console.log(e);
    if (action.payload.type === "ongoing")
      toast.error(e.message, defaultToastSetting);
  }
}

function* fetchInterviewDetailsSaga(action) {
  try {
    const interviews = yield call(fetchInterviewDetails, action.payload);
    if (interviews.success) {
      yield put(fetchInterviewDetailsSuccess(interviews.data));
    }
  } catch (e) {
    console.log(e);
    toast.error(e.message, defaultToastSetting);
  }
}

function* updateInterviewQuestionSaga(action) {
  try {
    const interviews = yield call(updateInterviewQuestion, action.payload);
    if (interviews.success) {
      yield put(updateInterviewQuestionSuccess(interviews.data.components));
    }
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
  }
}

function* updateCurrentQuestionScoreSaga(action) {
  try {
    const interviews = yield call(
      updateCurrentInterviewQuestion,
      action.payload
    );
    if (interviews.success) {
      toast.success(
        `${action.payload.data.key} updated successfully`,
        defaultToastSetting
      );
      yield put(updateCurrentQuestionScoreSuccess(interviews.data.components));
    }
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
  }
}

function* deleteInterviewSaga(action) {
  try {
    const interviews = yield call(deleteInterviewService, action.payload);
    yield put(deleteInterviewSuccess(interviews));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
  }
}

function* submitInterviewResponseSaga(action) {
  try {
    const interviews = yield call(
      submitInterviewResponseService,
      action.payload
    );
    yield put(submitInterviewSuccess(interviews));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
  }
}

function* submitOverallFeedbackRequested(action) {
  try {
    const interviews = yield call(updateSectionLock, action.payload);
    if (interviews.success) {
      toast.success(interviews.data, defaultToastSetting);
      yield put(submitOverallFeedbackSuccess());
    }
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(submitOverallFeedbackFailure());
  }
}

function* interviewpanelSaga() {
  yield takeEvery(FETCH_ALL_INTERVIEWS_REQUESTED, fetchAllInterviewsSaga);
  yield takeEvery(FETCH_INTERVIEW_DETAILS_REQUESTED, fetchInterviewDetailsSaga);
  yield takeEvery(
    UPDATE_INTERVIEW_QUESTION_REQUESTED,
    updateInterviewQuestionSaga
  );
  yield takeEvery(
    SET_CURRENT_QUESTION_SCORE_REQUESTED,
    updateCurrentQuestionScoreSaga
  );
  yield takeEvery(DELETE_INTERVIEW_REQUESTED, deleteInterviewSaga);
  yield takeEvery(
    SUBMIT_INTERVIEW_RESPONSE_REQUESTED,
    submitInterviewResponseSaga
  );
  yield takeEvery(
    SUBMIT_OVERALL_FEEDBACK_REQUESTED,
    submitOverallFeedbackRequested
  );
}

export default interviewpanelSaga;
