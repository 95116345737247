import React, { Component } from "react";
import "../assets/css/Dashboard.css";
import { connect } from "react-redux";
import TestGroupCard from "./TestGroupCard";
import {
  addGroupDetailsRequested,
  fetchAllGroupDetailsRequested,
  fetchCompaniesRequested,
  updateTestDeleteModalActive,
} from "../store/actions/test";
import DeleteTest from "../components/tests/createTest/DeleteTest";
import GroupFormModal from "./GroupFormModal";
import { toggleSidebar } from "../store/actions/sidebar";
import UniversalButton from "../components/common/StandardButton/UniversalButton";
import { withRouter } from "../components/common/WithRouter";

class GroupTests extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      showEditModal: false,
      deleteModal: false,
      groupId: "",
      currentGroup: {},
    };
    this.handleDeleteGroup = this.handleDeleteGroup.bind(this);
    this.handleShowEditModal = this.handleShowEditModal.bind(this);
  }

  componentDidMount() {
    if (this.props.sidebar.opened) {
      this.props.toggleSidebar();
    }
    this.props.fetchAllGroupDetailsRequested({ type: "" });
    this.props.fetchCompaniesRequested();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.groups !== this.props.groups) {
      this.setState({ showModal: false, showEditModal: false });
    }
  }

  handleDeleteGroup = (id) => {
    this.setState({ groupId: id });
    this.props.updateTestDeleteModalActive(true);
  };

  handleShowModal = () => {
    this.setState((prev) => {
      return {
        ...prev,
        showModal: !prev.showModal,
      };
    });
  };

  handleShowEditModal = (group) => {
    this.setState((prev) => {
      return {
        ...prev,
        showEditModal: !prev.showEditModal,
        currentGroup: { ...group },
      };
    });
  };

  render() {
    return (
      <>
        <DeleteTest title="group" testId={this.state.groupId} />
        {this.state.showModal && (
          <GroupFormModal
            handleShowModal={this.handleShowModal}
            isEdit={false}
          />
        )}
        {this.state.showEditModal && (
          <GroupFormModal
            handleShowModal={this.handleShowEditModal}
            isEdit={true}
            group={this.state.currentGroup}
          />
        )}
        {!this.props.groups.length ? (
          <div className="flex w-full pt-[10rem] items-center justify-ceneter flex-col">
            <h1 className="text-center text-[1.3rem] font-bold">
              NO Groups present till now.
            </h1>
            <div className="flex mt-2 p-3">
              <UniversalButton
                classNames="py-3"
                onClickFunction={() => this.handleShowModal()}
                title="Create new Group"
              />
            </div>
          </div>
        ) : (
          <div className="flex mt-2 p-3">
            <UniversalButton
              classNames="py-3"
              onClickFunction={() => this.handleShowModal()}
              title="Create new Group"
            />
          </div>
        )}
        <div className="grid sm:grid-cols-2 sm:gap-1 lg:grid-cols-3 lg:gap-3 md:gap-2 px-3">
          {this.props.groups.map((each) => (
            <TestGroupCard
              group={each}
              index={each._id}
              key={each._id}
              handleShowEditModal={this.handleShowEditModal}
              handleDeleteGroup={this.handleDeleteGroup}
            />
          ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ test, sidebar }) => {
  return {
    companies: test.companies,
    groups: test.allTestGroups,
    sidebar: sidebar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllGroupDetailsRequested: (data) =>
      dispatch(fetchAllGroupDetailsRequested(data)),
    fetchCompaniesRequested: () => dispatch(fetchCompaniesRequested()),
    addGroupDetailsRequested: (data) =>
      dispatch(addGroupDetailsRequested(data)),
    updateTestDeleteModalActive: (data) =>
      dispatch(updateTestDeleteModalActive(data)),
    toggleSidebar: () => dispatch(toggleSidebar()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GroupTests));
