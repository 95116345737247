import React, { useEffect, useState } from "react";
import TestHeader from "../tests/createTest/TestHeader";
import { useSelector } from "react-redux";
import { ReactComponent as EmptyIcon } from "../../assets/img/svg/empty.svg";
import InterviewCard from "./InterviewCard";
import { slickSetting } from "../tests/Arrow";
import Slider from "react-slick";
import "../common/Common.css";

const UpcomingInterviews = (props) => {
  const { upcoming } = useSelector(
    (state) => state.interviewpanelReducer.interviews
  );

  return (
    <div className="w-full m-auto">
      <div className="flex items-center justify-between w-full">
        <TestHeader title="Upcoming Interview" />
        {/* <h1 className="text-[1rem] text-[#87CF00] font-medium mr-3 cursor-pointer">
          See All
        </h1> */}
      </div>
      <div className="mx-[1.5rem] mt-[0.8rem] w-[96%]">
        {upcoming.length > 0 ? (
          <Slider {...slickSetting}>
            {upcoming.map((data, data_index) => (
              <InterviewCard
                data={data}
                data_index={data_index}
                handleDeleteTest={(data) => props.handleDeleteTest(data)}
                setEditInterview={props.setEditInterview}
                key={`CURRENT-INTERVIEW${data._id}`}
              />
            ))}
          </Slider>
        ) : (
          <div className="w-full h-[23rem] flex flex-col justify-center items-center p-5">
            <EmptyIcon style={{ height: "100%" }} />
            <h3>No Interviews to show</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpcomingInterviews;
