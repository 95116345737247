import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import TestService from "../../services/test.service";
import RenderQuestion from "./createTest/RenderQuestion";
import load from "../../assets/img/svg/load.svg";

//Store
import { connect } from "react-redux";
import { fetchTestRequest } from "../../store/actions/test";
import { withRouter } from "../common/WithRouter";

class GetScore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testId: this.props.params.id,
      pageLoading: false,
      studentResponse: {},
      marking: {},
      testData: {
        components: [],
        start_duration: null,
        end_duration: null,
        description: "",
        title: "",
        section_type: "G",
      },
    };
  }

  componentDidMount = () => {
    // this.setState({ pageLoading: true });
    TestService.getOneTest(this.state.testId).then(
      async (res) => {
        const data = res.data;
        this.setState((prevState) => ({
          ...prevState,
          testData: {
            ...prevState.testData,
            ...data,
          },
        }));

        let token = localStorage["priksha_token"].toString();
        // let decode = jwt_decode(token);
        let decode = {};

        await TestService.getOneResponse(decode["email"]).then(
          (res) => {
            this.setState({ studentResponse: res.data[0]["response"] });
            this.setState({ marking: res.data[0]["marking"] });
          },
          (error) => {
            return <Navigate to="/dashboard" />;
          }
        );
        // this.setState({ pageLoading: false });
      },
      (error) => {
        return <Navigate to="/dashboard" />;
      }
    );
  };

  showTests = (e) => {
    this.props.history("/tests/");
  };

  render() {
    if (this.state.pageLoading) {
      return (
        <div className="hardCenter">
          <img
            alt="loading"
            src={load}
            className="spinner mr-2 w-6 h-6 text-primary "
          />
        </div>
      );
    }
    return (
      <div fluid style={{ padding: "15px 7%" }}>
        <div>
          <div className="ml-3" md="9">
            <div>
              <h2 className="mt-3 font-bold text-dark mb-0">
                {this.state.testData.title}
              </h2>
            </div>
            <div>
              <p className="ml-1"> Description:</p>

              <p
                dangerouslySetInnerHTML={{
                  __html: this.state.testData.description,
                }}
                className="text-muted"
              ></p>
            </div>
          </div>
          <div className="mt-3">
            <div className="flex flex-col">
              <div
                label="Hours"
                type="text"
                disabled={true}
                value={this.state.testData.start_duration}
              />
              <div
                label="Minutes"
                type="text"
                disabled={true}
                value={this.state.testData.end_duration}
              />
            </div>
          </div>
        </div>
        <hr />
        {this.state.testData.components.map((section, sectionIndex) => (
          <div
            key={sectionIndex}
            className="bg-white rounded p-4 p-lg-5 shadow-sm mt-4"
            fluid
          >
            <h4 className="text-center font-bold mb-0">{section.title}</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: section.description,
              }}
              className="text-center text-muted mb-0"
            ></p>
            <hr />
            {section.components.map((question, questionIndex) => (
              <div key={questionIndex}>
                <div className="mt-3 mb-4">
                  <RenderQuestion
                    testId={this.state.testId}
                    question={question}
                    index={questionIndex + 1}
                    section={section}
                    question_details={question}
                    response={this.state.studentResponse}
                    marking={this.state.marking}
                    UserType="S"
                    isTestCreation={false}
                    type='T'
                  />
                </div>
                <hr />
              </div>
            ))}
          </div>
        ))}
        <div>
          <button
            className="py-2 px-3 bg-emerald-600 text-white font-bold"
            onClick={(e) => this.showTests(e)}
          >
            Back To tests
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTestRequest: (data, options) =>
      dispatch(fetchTestRequest(data, options)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GetScore));
