import React, { Component } from "react";
import load from "../../../assets/img/svg/load.svg";
export default class Loading extends Component {
  render() {
    return (
      <div
        className="justify-content-center align-items-end d-flex"
        style={{ minHeight: "250px" }}
      >
        <img 
        alt="loading"
        src={load}
        className="spinner mr-2 w-6 h-6 text-primary "/>
      </div>
    );
  }
}
