import React, { useState } from "react";
import UniversalButton from "../common/StandardButton/UniversalButton";
import CollegeModal from "./CollegeModal";
import { useDispatch, useSelector } from "react-redux";
import { updateUserRequest } from "../../store/actions/user";

const EditProfileModal = ({ data, closeModal }) => {
  const dispatch = useDispatch();
  const { editProfileLoading } = useSelector((state) => state.user);
  const [collegeData, setCollegeData] = useState(data?.college || {});
  const [updatedData, setUpdatedData] = useState({
    name: data.name,
    mobile: data.mobile,
    college: data?.college,
  });

  const handleCollegeChange = (e) => {
    setCollegeData(e);
    setUpdatedData((prev) => {
      return {
        ...prev,
        college: e.college_id,
      };
    });
  };

  return (
    <>
      <h1 className="text-[1.5rem] mt-[0.5rem] mb-[1.2rem] font-semibold w-full text-center">
        Edit Your Profile
      </h1>
      <div className="flex w-[22rem] flex-col items-center">
        <div className="w-full flex mb-3 flex-col items-start gap-[0.2rem]">
          <p className="font-bold font-['nunito'] text-[1rem]">Your Name</p>
          <input
            className="login-form-passInput inputPlaceholder"
            type="text"
            name="name"
            value={updatedData.name}
            onChange={(e) =>
              setUpdatedData((prev) => {
                return {
                  ...prev,
                  [e.target.name]: e.target.value,
                };
              })
            }
          />
        </div>
        <div className="w-full flex mb-3 flex-col items-start gap-[0.2rem]">
          <p className="font-bold font-['nunito'] text-[1rem]">Your Mobile</p>
          <input
            className="login-form-passInput inputPlaceholder"
            type="number"
            name="mobile"
            value={updatedData.mobile}
            onChange={(e) =>
              setUpdatedData((prev) => {
                return {
                  ...prev,
                  [e.target.name]: e.target.value,
                };
              })
            }
          />
        </div>
        <div className="w-full flex mb-3 flex-col items-start gap-[0.2rem]">
          <p className="font-bold font-['nunito'] text-[1rem]">Your college</p>
          <CollegeModal
            handleCollegeChange={handleCollegeChange}
            collegeValue={collegeData}
          />
        </div>

        <UniversalButton
          classNames="m-auto mt-[1.5rem]"
          loading={editProfileLoading}
          title="Edit"
          onClickFunction={() => {
            // console.log(updatedData);
            dispatch(updateUserRequest({ updatedData, closeModal }));
          }}
        />
      </div>
    </>
  );
};

export default EditProfileModal;
