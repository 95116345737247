export const FETCH_CHAT_MSGS_REQUESTED = "FETCH_CHAT_MSGS_REQUESTED";

export const FETCH_CHAT_MSGS_SUCCESS = "FETCH_CHAT_MSGS_SUCCESS";

export const FETCH_CHAT_MSGS_FAILURE = "FETCH_CHAT_MSGS_FAILURE";

export const SEND_MESSAGE = "SEND_MESSAGE";

export const RECEIVE_MESSAGE = "RECEIVE_MESSAGE";

export const FETCH_ALL_CHAT_MSGS_REQUESTED = "FETCH_ALL_CHAT_MSGS_REQUESTED";

export const FETCH_ALL_CHAT_MSGS_SUCCESS = "FETCH_ALL_CHAT_MSGS_SUCCESS";

export const FETCH_ALL_CHAT_MSGS_FAILURE = "FETCH_ALL_CHAT_MSGS_FAILURE";

export const SET_NOTIFICATION_DATA = "SET_NOTIFICATION_DATA";
