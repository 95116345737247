import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";

export default class HistogramChart extends Component {
  render() {
    return (
      <div>
        <Bar
          options={this.props.options}
          data={this.props.data}
          height={90}
        ></Bar>
      </div>
    );
  }
}
