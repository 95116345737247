export const Judge0Token = '798ff74bcdmsh7c641014453b3ccp1f5b5ejsn071aabbda23d';

export const TemplateCode = {
	c: '#include <stdio.h>\n\nint main() {\n    // your code goes here\n    return 0;\n}',
	cpp: '#include <iostream>\nusing namespace std;\n\nint main() {\n    // your code goes here\n    return 0;\n}',
	c_9_2_0: '#include <stdio.h>\n\nint main() {\n    // your code goes here\n    return 0;\n}',
	cpp_9_2_0: '#include <iostream>\nusing namespace std;\n\nint main() {\n    // your code goes here\n    return 0;\n}',
	c_sharp: 'using System;\n\nclass Program\n{\n    static void Main()\n    {\n        // your code goes here\n    }\n}',
	go: 'package main\n\nimport "fmt"\n\nfunc main() {\n    // your code goes here\n}',
	java: 'import java.util.*;\nimport java.lang.*;\nimport java.io.*;\n\nclass Main\n{\n    public static void main (String[] args) throws java.lang.Exception\n    {\n        // your code goes here\n    }\n}',
	javascript: "// your code goes here",
	python_2_7_17: '# Your code goes here',
	python_3_8_1: '# Your code goes here',
	ruby: '# your code goes here',
	rust: 'fn main() {\n    // your code goes here\n}',
	typescript: "// your code goes here",
};
