import React, { createRef } from "react";
import "./Test.css";
import io from "socket.io-client";
import Peer from "peerjs";
import dayjs from "dayjs";
import send from "../../assets/img/svg/send.svg";
import load from "../../assets/img/svg/load.svg";
import chatcolor from "../../assets/img/svg/chatcolor.svg";
import uparrow from "../../assets/img/svg/uparrow.svg";
import downarrow from "../../assets/img/svg/downarrow.svg";
import { chat_prompts } from "../../assets/js/chat_prompts";
import { connect } from "react-redux";
import { fetchProctoredUsersRequested } from "../../store/actions/proctor";
import { fetchChatMsgsRequested } from "../../store/actions/chat";
import { timeFormat } from "../../assets/js/common";
import { withRouter } from "../common/WithRouter";

class Chat extends React.Component {
  constructor() {
    super();

    this.state = {
      rightClick: 0,
      leftClick: 0,
      inputMessage: "",
    };
    this.scrollRef = createRef(null);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.messageBox === nextProps.messageBox ||
      this.props.messageBox !== nextProps.messageBox ||
      this.state.inputMessage.length !== nextState.inputMessage.length ||
      this.props.messages.length !== nextProps.messages.length
    ) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    this.scrollRef?.current?.scrollIntoView({ behaviour: "smooth" });

    //   const scrollHeight = this.scrollRef.scrollHeight;
    // const height = this.scrollRef.clientHeight;
    // const maxScrollTop = scrollHeight - height;
    // this.scrollRef.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  handleChatPrompt = (message) => {
    this.handleInputMessage(message);
  };

  handleLeftSlide = () => {
    const slider = document.getElementById("chat_prompts");
    console.log(slider.offsetWidth);
    slider.scrollLeft -= slider.offsetWidth;
    if (this.state.leftClick !== 0) {
      this.setState((prevState) => ({
        leftClick: prevState.leftClick - 1,
      }));
    }
  };

  handleRightSlide = () => {
    const slider = document.getElementById("chat_prompts");
    console.log(slider.offsetWidth);
    slider.scrollLeft += slider.offsetWidth;
    this.setState((prevState) => ({
      leftClick: prevState.leftClick + 1,
    }));
  };

  handleInputMessage = (value) => {
    this.setState((prev) => {
      return { ...prev, inputMessage: value };
    });
  };

  render() {
    return (
      <div className="flex w-full z-20">
        <div
          className={`fixed flex-col py-1 rounded-t-xl 
                  right-2 bottom-0 items-center border border-[#B8FB3C] bg-white ${this.props.messageBox
              ? "w-[25%] min-w-[280px]"
              : "w-[20%] min-w-[200px]"
            }`}
        >
          <div
            className="flex items-center justify-between sm:py-2 py-1 hover:cursor-pointer  px-2"
            onClick={this.props.handleChatReset}
          >
            <div className="justify-start flex cursor-pointer items-center">
              <p className="w-4 h-4 mx-2 bg-[#B8FB3C] rounded-full"></p>
              {/* <img
                src={chatcolor}
                alt="chat"
                title="Send message"
                className="sm:w-7 sm:h-7 w-6 h-6 mx-2 sm:mr-4 sm:mt-1"
              /> */}
              <div className="font-semibold font-['poppins'] text-lg text-[#333333]">
                {this.props.toPeerId
                  ? this.props.proctoredUsers.find(
                    (eachUser) => eachUser.peerid === this.props.toPeerId
                  )?.["name"]
                  : "Chat"}
              </div>
            </div>
            <div>
              <svg
                data-accordion-icon
                className={`w-6 h-6 shrink-0 ${!this.props.messageBox && "rotate-180"
                  }`}
                fill="#B8FB3C"
                title="open"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
          {this.props.messageBox ? (
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  className="bg-transparent w-[100%] h-[55vh] flex-col border-t border-b border-[#B8FB3C] scrollClass px-2"
                  style={{
                    overflowY: "scroll",
                  }}
                >
                  {this.props.messages &&
                    this.props.messages.map((eachMsg) => {
                      return (
                        <div
                          className="my-2 text-left font-['nunito']"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: `${eachMsg.from === this.props.uniqueUserId
                              ? "flex-end"
                              : "flex-start"
                              }`,
                          }}
                        >
                          <div
                            className={`rounded-lg max-w-[300px] px-1 ${eachMsg.from === this.props.uniqueUserId
                              ? "bg-[#BCFB46]"
                              : "bg-[#F2F7FB]"
                              } flex px-[8px] py-[5px]`}
                          >
                            <p className="break-all">
                              {eachMsg.message || eachMsg.msg.message}
                            </p>
                            <small
                              className={` ${eachMsg.from === this.props.uniqueUserId
                                ? "text-[#666668]"
                                : "tex-gray-50"
                                } text-[10px]   mt-[20px] ml-[8px]`}
                            >
                              {timeFormat(eachMsg.created_at)}
                            </small>
                          </div>
                        </div>
                      );
                    })}
                  <div
                    style={{ float: "left", clear: "both" }}
                    ref={this.scrollRef}
                  ></div>
                </div>
                <div
                  id="chat_prompts"
                  style={{ scrollBehavior: "smooth" }}
                  className="relative bg-white font-['poppins'] py-[8px]  border-t border-b border-[#B8FB3C]  flex overflow-x-scroll "
                >
                  <div
                    onClick={() => this.handleLeftSlide()}
                    className={`${this.state.leftClick === 0 ? "hidden" : "block"
                      } sticky left-0 bg-white px-1 z-10 cursor-pointer`}
                  >
                    <p className="font-black text-xl"> {`<`} </p>
                  </div>
                  {chat_prompts.map((chat_prompt) => (
                    <div
                      onClick={(e) => this.handleChatPrompt(chat_prompt)}
                      value="Chat Prompt"
                      className="bg-gray-300 flex justify-center items-center hover:bg-gray-400 transition px-[4px] py-[2px] mx-2 text-[10px] min-w-[160px] min-h-[30px] rounded-md opacity-80 font-semibold cursor-pointer"
                    >
                      {chat_prompt}
                    </div>
                  ))}
                  <div
                    onClick={() => this.handleRightSlide()}
                    className="sticky right-0 bg-white px-1 z-10 cursor-pointer"
                  >
                    <p className="font-black text-xl"> {`>`} </p>
                  </div>
                </div>
                <div className="flex sendMsg my-2">
                  <input
                    type="text"
                    placeholder="Type your message here..."
                    onKeyPress={(e) => {
                      if (e.key == "Enter") {
                        this.props.handleSendingMessage(
                          this.state.inputMessage
                        );
                        this.setState((prev) => {
                          return { ...prev, inputMessage: "" };
                        });
                      }
                    }}
                    className="bg-transparent w-[90%] outline-none placeholder:italic placeholder:text-sm
                    rounded placeholder-slate-500 placeholder-opacity-75 px-4 py-1"
                    onChange={(e) => this.handleInputMessage(e.target.value)}
                    autoFocus
                    value={this.state.inputMessage}
                  />
                  {/* <div className="flex"> */}
                  <button
                    onClick={() => {
                      this.props.handleSendingMessage(this.state.inputMessage);
                      this.setState((prev) => {
                        return { ...prev, inputMessage: "" };
                      });
                    }}
                    disabled={this.props.chatLoader}
                    className="flex justify-center items-center p-2 mr-1"
                  >
                    {!this.props.chatLoader ? (
                      <img
                        src={send}
                        title="Click to send"
                        className="h-4 w-4 items-center"
                        alt="send"
                      />
                    ) : (
                      <img
                        alt="loading"
                        src={load}
                        className="spinner w-4 h-4"
                      />
                    )}
                  </button>
                  {/* </div> */}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ proctor, chat }) => {
  return {
    proctoredUsers: proctor.proctoredUsers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProctoredUsersRequested: (data) =>
      dispatch(fetchProctoredUsersRequested(data)),
    fetchChatMsgsRequested: (data) => dispatch(fetchChatMsgsRequested(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Chat));
