export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const POSTGRES_URL = process.env.REACT_APP_POSTGRES_URL;
export const BASE_KA_URL = process.env.REACT_APP_BASE_KA_URL;
export const PEERJS_CONFIG = {
  host: process.env.REACT_APP_PEERJS_CONFIG_HOST,
  port: process.env.REACT_APP_PEERJS_CONFIG_PORT,
  secure: true,
  path: "/",
  debug: 3,
  config: {
    iceServers: [
      {
        urls: "stun:turn.kyron.in:80",
      },
      {
        urls: "turn:turn.kyron.in:80?transport=udp",
        username: "1649579289:kushal",
        credential: "bE9e3XKvoQ/l3wsIF34S+TuNgyQ=",
      },
      {
        urls: "turn:turn.kyron.in:80?transport=tcp",
        username: "1649579289:kushal",
        credential: "bE9e3XKvoQ/l3wsIF34S+TuNgyQ=",
      },
      {
        urls: "turn:turn.kyron.in:443?transport=tcp",
        username: "1649579289:kushal",
        credential: "bE9e3XKvoQ/l3wsIF34S+TuNgyQ=",
      },
    ],
  },
};
