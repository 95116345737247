import React, { Component } from "react";
import { connect } from "react-redux";
import AnywhereNavigate from "./accordian/AnywhereNavigate";
import ForwardOnly from "./accordian/ForwardOnly";
import { getType } from "../../common/utils";
import UniversalButton from "../../common/StandardButton/UniversalButton";
import { withRouter } from "../../common/WithRouter";

class SideNavBar extends Component {
  constructor() {
    super();
    this.state = {
      totalMarks: 0,
    };
    this.teacherRef = React.createRef(null);
    this.getTotalMarks = this.getTotalMarks.bind(this);
  }

  componentDidMount() {
    this.getTotalMarks(this.props.test.components);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.teacherStream !== this.props.teacherStream) {
      this.teacherRef.current.srcObject = this.props.teacherStream;
    }
  }

  getTotalMarks = (comp) => {
    var totalMark = 0;
    for (let i = 0; i < comp.length; i++) {
      for (let j = 0; j < comp[i].components.length; j++) {
        totalMark += parseFloat(comp[i].components[j].marks);
      }
    }
    this.setState((prev) => {
      return {
        ...prev,
        totalMarks: totalMark,
      };
    });
  };

  render() {
    return (
      <div
        className={`flex mt-[1rem] border-l px-1 flex-col w-full items-stretch block ${this.props.isRightSidebar
          ? "hidden"
          : "mt-3 xs:min-h-screen lg:fixed top-0 right-0 lg:w-1/5"
          } lg:top-12 `}
      >
        <div className="flex my-2 lg:flex-col gap-2">
          {/* <button
            className={`px-3 py-2 mt-2 mr-2 flex-1 rounded-lg text-[#001437] bg-[#BCFB46] 
          font-medium rounded-full text-md flex justify-center ${
            !this.props.AudioRecordingStatus?.startPreparationTime ||
            this.props.AudioRecordingStatus?.isAnswerTimeCompleted
              ? "cursor-pointer hover:bg-[#001437] hover:text-[#BCFB46]"
              : "cursor-not-allowed opacity-70"
          }
          font-['nunito']`}
          ></button> */}

          <UniversalButton
            classNames="flex-1 lg:w-full"
            onMouseOver={(e) =>
              this.props.onMouseOverEvent(e, `sidebar-submit-button`)
            }
            disabled={
              !(
                !this.props.AudioRecordingStatus?.startPreparationTime ||
                this.props.AudioRecordingStatus?.isAnswerTimeCompleted
              )
            }
            title="Submit Test"
            onClickFunction={() => this.props.handleSubmitModal()}
          />

          <p
            onMouseOver={(e) =>
              this.props.onMouseOverEvent(e, `sidebar-body-maxmarks`)
            }
            className="text-[#a0db32] text-center border border-[#a0db32] hover:bg-[#BCFB46]
              hover:text-[#001437] font-semibold uppercase text-xs font-bold
              px-4 py-2 rounded outline-none focus:outline-none flex-1
              ease-linear transition-all duration-150 font-['nunito']"
          >
            Max Marks : {this.state.totalMarks}
          </p>
        </div>

        {this.props.test.is_proctoring && (
          <button
            onClick={() => this.props.setSetttings()}
            className="text-[#a0db32] border border-[#a0db32] hover:bg-[#BCFB46]
              hover:text-[#001437] font-semibold uppercase text-xs font-bold
              px-4 py-2 mt-2 rounded outline-none focus:outline-none w-full
              ease-linear transition-all duration-150 font-['nunito']"
            onMouseOver={(e) =>
              this.props.onMouseOverEvent(
                e,
                `sidebar-body-change-setting-button`
              )
            }
          >
            Change Settings
          </button>
        )}
        {(getType() === "T" || getType() === "A") && (
          <button
            onClick={() => this.props.editTest()}
            className="px-3 mt-2 py-2 rounded-lg bg-slate-600 text-white font-bold"
          >
            Edit Test
          </button>
        )}
        <div
          className={`bg-white py-2 rounded-lg  ${this.props.isRightSidebar ? " lg:mb-[10px]" : " lg:mb-[70px]"
            }`}
          onMouseOver={(e) => this.props.onMouseOverEvent(e, `sidebar-body`)}
        >
          <div
            className="flex item-center justify-center mb-2"
            onMouseOver={(e) =>
              this.props.onMouseOverEvent(e, `sidebar-body-proctor-text`)
            }
          >
            {this.props.proctorConnected && this.props.test.is_proctoring && (
              <div className="dancingProctor w-full text-center px-5 py-1 bg-red-500 text-white text-md rounded-md font-bold">
                You are being proctored
              </div>
            )}
          </div>
          {this.props.test.is_proctoring && this.props.currentVideoDevice && (
            <video
              className="students own video mb-2"
              ref={this.props.videoRef}
              muted
              autoPlay
              style={{
                borderRadius: "10px",
              }}
              onMouseOver={(e) =>
                this.props.onMouseOverEvent(e, `sidebar-body-student-video`)
              }
            />
          )}
          {this.props.test.is_proctoring ? (
            <div className="flex gap-2">
              <p
                className="font-[nunito] mb-2"
                onMouseOver={(e) =>
                  this.props.onMouseOverEvent(
                    e,
                    `sidebar-body-proctor-audio-text`
                  )
                }
              >
                Proctor Audio:
              </p>
              <div>
                {this.props.isTeacherMuted ? (
                  <p
                    className="font-[nunito] mb-2 text-red-400"
                    onMouseOver={(e) =>
                      this.props.onMouseOverEvent(
                        e,
                        `sidebar-body-proctor-audio-text-muted`
                      )
                    }
                  >
                    {" "}
                    Muted
                  </p>
                ) : (
                  <div className="boxContainer1 h-[20px]">
                    <div className="box box1"></div>
                    <div className="box box2"></div>
                    <div className="box box3"></div>
                    <div className="box box4"></div>
                    <div className="box box5"></div>
                  </div>
                )}
              </div>
              <audio
                className="students own video mb-4"
                ref={this.teacherRef}
                muted={this.props.isTeacherMuted}
                autoPlay
                style={{
                  borderRadius: "10px",
                }}
                onMouseOver={(e) =>
                  this.props.onMouseOverEvent(e, `sidebar-body-proctor-audio`)
                }
              />
            </div>
          ) : null}
          {this.props.test.navigation_type === "A" ? (
            <AnywhereNavigate
              saveResponse={() => this.props.saveResponse()}
              onMouseOverEvent={this.props.onMouseOverEvent}
            />
          ) : (
            <ForwardOnly />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    test: test.test,
    AudioRecordingStatus: test,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SideNavBar));
