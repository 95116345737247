import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  updateStarterCardActive,
  setTestStatus,
  updateBandWidth,
} from "../../../store/actions/test";
import { toggleSidebar } from "../../../store/actions/sidebar";
import { ReactComponent as CameraIcon } from "../../../assets/img/svg/cam1.svg";
import { ReactComponent as CameraOffIcon } from "../../../assets/img/svg/cam1off.svg";
import { ReactComponent as MicIcon } from "../../../assets/img/svg/mic2on.svg";
import { ReactComponent as MicOffIcon } from "../../../assets/img/svg/mic2off.svg";
import { ReactComponent as DesktopIcon } from "../../../assets/img/svg/desktop.svg";
import { ReactComponent as DesktopOffIcon } from "../../../assets/img/svg/desktop-slash.svg";

import TestHeader from "../createTest/TestHeader";
import KyronSideLetter from "../../../assets/img/Kyron/kyron_logo.png";

// import NetworkSpeed from "network-speed";
import {
  fetchInterviewDetailsRequested,
  updateInterviewPage,
} from "../../../store/actions/interviewpanel";
import { fetchMyProfile } from "../../../store/actions/user";
import { fetchTurnServerCreds } from "../../../store/actions/turnserver";
import UniversalButton from "../../common/StandardButton/UniversalButton";
import { withRouter } from "../../common/WithRouter";

// const testNetworkSpeed = new NetworkSpeed();

class SystemMetrics extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef(null);
    this.state = {
      audioInputDevices: [],
      audioOutputDevices: [],
      videoInputDevices: [],
    };
  }

  componentDidMount() {
    if (this.props.sidebar.opened) {
      this.props.toggleSidebar();
    }
    this.props.setTestStatus(true);
    if (this.props.type === "I") {
      this.props.fetchInterviewDetailsRequested(this.props.testId);
      this.props.fetchMyProfile();
      this.props.fetchTurnServerCreds();
    }
  }

  setMediaDevices() {
    navigator.mediaDevices
      .enumerateDevices()
      .then((res) => this.gotDevices(res))
      .catch((err) => console.log("err", err));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.videoStream && this.state.videoInputDevices.length === 0) {
      navigator.permissions.query({ name: "camera" }).then((res) => {
        if (res.state === "granted") {
          this.setMediaDevices();
        }
      });
    }
    if (
      (prevProps.videoStream &&
        (prevProps?.videoStream?.id !== this.props.videoStream?.id)) ||
      (prevProps.currentAudioInputDevice &&
        (prevProps?.currentAudioInputDevice !==
          this.props.currentAudioInputDevice))
    ) {
      setTimeout(() => this.setVideoRef(), 0);
    }

    if (
      prevProps.currentAudioOutputDevice &&
      prevProps?.currentAudioOutputDevice !==
      this.props.currentAudioOutputDevice
    ) {
      this.attachSinkId(
        this.videoRef.current,
        this.props.currentAudioOutputDevice
      );
    }
  }

  setVideoRef = () => {
    if (this.props.videoStream && this.props.videoStream?.active && this.videoRef?.current) {
      console.log("checkin in", this.props.videoStream)
      this.videoRef.current.srcObject = this.props.videoStream;
    }
  }

  gotDevices(deviceInfos) {
    const audioInputDevices = deviceInfos.filter(
      (eachDevice) => eachDevice.kind === "audioinput"
    );
    const audioOutputDevices = deviceInfos.filter(
      (eachDevice) => eachDevice.kind === "audiooutput"
    );
    const videoInputDevices = deviceInfos.filter(
      (eachDevice) => eachDevice.kind === "videoinput"
    );

    this.setState({
      audioInputDevices,
      audioOutputDevices,
      videoInputDevices,
    });

    if (!this.props.isModal) {
      deviceInfos.map((eachDevice) => {
        if (eachDevice.kind === "audioinput") {
          if (eachDevice.deviceId === "default") {
            this.props.handleAudioInputChange(eachDevice.deviceId);
          }
        } else if (eachDevice.kind === "audiooutput") {
          if (eachDevice.deviceId === "default") {
            this.props.handleAudioOutputChange(eachDevice.deviceId);
          }
        } else {
          if (this.props.currentVideoDevice === null) {
            this.props.handleVideoChange(eachDevice.deviceId);
          }
        }

        return null;
      });
    }
    this.streamOn();
  }

  handleToast = (msg) => {
    toast.warning(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  onNextPage() {
    clearInterval(this.timer);
    if (this.props.isVideo && this.props.isAudio) {
      if (this.props.type === "I") {
        this.props.updateInterviewPage(2);
      }
      if (this.props.type === "T") {
        if (this.props.isScreen) {
          this.props.updateStarterCardActive(3);
        } else {
          this.handleToast("Please refresh and share your screen");
        }
      }
    } else {
      this.handleToast("Please enable your camera and refresh your page");
    }
  }

  attachSinkId(element, sinkId) {
    if (typeof element.sinkId !== "undefined") {
      element
        .setSinkId(sinkId)
        .then(() => {
          console.log(`Success, audio output device attached: ${sinkId}`);
        })
        .catch((error) => {
          let errorMessage = error;
          if (error.name === "SecurityError") {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          console.error(errorMessage);
          // Jump back to first output device in the list as it's the default.
          // audioOutputSelect.selectedIndex = 0;
        });
    } else {
      console.warn("Browser does not support output device selection.");
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  streamOn() {
    // console.log("checking", this.props.videoStream, !this.videoRef)
    if (
      this.props.videoStream &&
      this.props.videoStream.active &&
      !this.videoRef?.current?.srcObject
    ) {
      this.videoRef.current.srcObject = this.props.videoStream;
    }
  }
  allChecksPassed = () =>
    this.props.type === "T"
      ? this.props.isVideo &&
      this.props.isEntireScreenShared &&
      this.props.isScreen
      : this.props.isVideo;

  render() {
    return (
      <div
        className={`flex flex-col h-100 ${this.props.isModal ? `shadow-lg` : ""
          }`}
      >
        {!this.props.isEntireScreenShared && !this.props.isModal && (
          <div className="dancingProctor absolute h-12 w-96 bg-red-400 text-white rounded-md top-16 right-5 flex justify-center items-center">
            Please share you entire screen to continue{" "}
            <span
              className="font-xxl mx-2 cursor-pointer"
              onClick={() => window.location.reload()}
            >
              ↻
            </span>
          </div>
        )}
        {!this.props.isModal ? (
          <nav className="flex justify-between items-center px-4 py-2 border-b border-border fixed w-[100%] z-[49] bg-white drop-shadow-[2px_0px_2px_rgba(0,0,0,0.25)]">
            <img
              className="h-[40px] object-contain"
              src={KyronSideLetter}
              title="Logo"
              alt="kyron"
            />
            <TestHeader title={this.props.test.title} styles="text-start" />
          </nav>
        ) : null}
        <div className="flex rounded-md bg-white flex-col items-center">
          {!this.props.isModal && (
            <h4 className="text-center font-['nunito'] text-xl text-[#666666] mt-20">
              {this.allChecksPassed() ? (
                <span className="text-[#a0db32]">
                  All checks have been passed successfully. Your system is
                  compatible for the test.
                </span>
              ) : (
                " We are verifying your device! Please allow camera and microphone access"
              )}
            </h4>
          )}
          <div
            className="grid grid-rows bg-white  lg:grid-cols-2 gap-8
          md:grid-rows justify-center items-center md:mx-[10%] sm:mx-5 mx-2"
          >
            <div className="justify-center bg-white  items-center">
              <div className="flex justify-center items-center">
                {this.props.isVideo ? (
                  <>
                    <video
                      style={{ width: "400px", height: "300px" }}
                      ref={this.videoRef}
                      autoPlay
                      muted
                    ></video>
                  </>
                ) : (
                  <div className="flex w-[300px] h-[300px] gap-2 text-red-800 justify-center items-center">
                    {this.props.error === "" ? (
                      <p>...Retrieving Metrics</p>
                    ) : (
                      <p>
                        {this.props.error.toString().split(":")[1]}. Please
                        enable your camera and stop using your camera in other
                        applications
                      </p>
                    )}
                    <div className="mx-4">
                      <div className="dot-typing"></div>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex  justify-center items-center gap-5 mt-3">
                {/* Video */}
                <div
                  className={`flex-col flex justify-center items-center border-2 p-2 w-[85px] h-[85px] rounded-md ${this.props.isVideo ? "border-[#a0db32]" : "border-[#EB4335]"
                    }`}
                >
                  {this.props.isVideo ? (
                    <CameraIcon title="Student video on" alt="camera on" color={"#BCFB46"} />
                  ) : (
                    <CameraOffIcon title="Student video off" alt="camera off" />
                  )}
                  <p
                    className={`text-xs font-semibold mt-1 font-['nunito'] ${this.props.isVideo ? "text-[#a0db32]" : "text-[#EB4335]"
                      }`}
                  >
                    VIDEO
                  </p>
                </div>
                {/* Audio */}
                <div
                  className={`flex flex-col justify-center items-center border-2 w-[85px] h-[85px] p-2 rounded-md ${this.props.isVideo ? "border-[#a0db32]" : "border-[#EB4335]"
                    }`}
                >
                  {this.props.isVideo ? (
                    <MicIcon
                      title="Student audio on"
                      alt="Microphone on"
                      className="mx-auto"
                      color={"#BCFB46"}
                    />
                  ) : (
                    <MicOffIcon
                      title="Student audio off"
                      alt="Microphone off"
                      className="mx-auto"
                    />
                  )}
                  <p
                    className={`text-xs font-semibold mt-1 font-['nunito'] ${this.props.isVideo ? "text-[#a0db32]" : "text-[#EB4335]"
                      }`}
                  >
                    AUDIO
                  </p>
                </div>
                {/* Screen */}
                {this.props.type === "T" && (
                  <div
                    className={`flex-col flex justify-center items-center border-2 p-2 w-[85px] h-[85px] rounded-md ${this.props.isScreen
                      ? "border-[#a0db32]"
                      : "border-[#EB4335]"
                      }`}
                  >
                    {this.props.isScreen ? (
                      <DesktopIcon
                        title="Screen share on"
                        alt="Screen share on"
                        className="mx-auto"
                        color={"#BCFB46"}
                      />
                    ) : (
                      <DesktopOffIcon
                        title="Screen share off"
                        alt="Screen share off"
                        className="mx-auto"
                      />
                    )}
                    <p
                      className={`text-xs font-semibold mt-1 font-['nunito'] ${this.props.isScreen
                        ? "text-[#a0db32]"
                        : "text-[#EB4335]"
                        }`}
                    >
                      SCREEN
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="grid w-full sm:p-10 justify-center items-center gap-3 ">
              <div className=" h-35 w-96 p-2 rounded shadow-md">
                <p className="text-xl font-semibold py-1 font-['nunito']">
                  Microphone
                </p>
                <p>Select your microphone</p>
                <select
                  id="audioInputSelect"
                  className="w-full rounded border p-1 my-1 border-slate-300"
                  value={this.props.currentAudioInputDevice || null}
                  onChange={(e) =>
                    this.props.handleAudioInputChange(e.target.value)
                  }
                >
                  {this.state.audioInputDevices.length &&
                    this.state.audioInputDevices.map((eachDevice) => {
                      return (
                        <option
                          value={eachDevice.deviceId}
                          key={eachDevice.deviceId}
                        >
                          {eachDevice.label}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="h-35 w-96 p-2 rounded shadow-md">
                <p className="text-xl font-semibold py-1 font-['nunito']">
                  Camera
                </p>
                <p>Select your camera</p>
                {/* {console.log("checkig current", this.props)} */}
                <select
                  id="videoSelect"
                  className="w-full border  p-1 my-1 border-slate-300"
                  value={this.props.currentVideoDevice || null}
                  onChange={(e) => this.props.handleVideoChange(e.target.value)}
                >
                  {this.state.videoInputDevices.length &&
                    this.state.videoInputDevices.map((eachDevice) => {
                      return (
                        <option
                          value={eachDevice.deviceId}
                          key={eachDevice.deviceId}
                        >
                          {eachDevice.label}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="h-35 w-96 border p-2 rounded shadow-md">
                <p className="text-xl font-semibold py-1 font-['nunito']">
                  Speaker
                </p>
                <p>Select your speaker</p>
                <select
                  id="audioOutputSelect"
                  className="w-full border  p-1 my-1 border-slate-300"
                  value={this.props.currentAudioOutputDevice || null}
                  onChange={(e) =>
                    this.props.handleAudioOutputChange(e.target.value)
                  }
                >
                  {this.state.audioOutputDevices.length &&
                    this.state.audioOutputDevices.map((eachDevice) => {
                      return (
                        <>
                          <option
                            id={eachDevice.deviceId}
                            value={eachDevice.deviceId}
                            key={eachDevice.deviceId}
                          >
                            {eachDevice.label}
                          </option>
                        </>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>
          {this.props.isModal ? (
            <UniversalButton classNames="mb-6 mr-2" onClickFunction={() => this.props.setIsModal()} title="Save & Close" />

          ) : (
            <UniversalButton
              onClickFunction={() => this.onNextPage()}
              title="Next"
              classNames={`mb-6 mr-2 ${this.props.type === "I" && this.props.loader && "cursor-progress"}`}
              disabled={this.props.type === "I" && this.props.loader}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test, sidebar, interviewpanelReducer }) => {
  return {
    test: test.test,
    sidebar: sidebar,
    isVideo: test.isVideo,
    isAudio: test.isAudio,
    isScreen: test.isScreen,
    bandWidth: test.currentBandWidth,
    screenStream: test.screenStream,
    videoStream: test.videoStream,
    loader: interviewpanelReducer.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStarterCardActive: (data) => dispatch(updateStarterCardActive(data)),
    toggleSidebar: () => dispatch(toggleSidebar()),
    setTestStatus: (data) => dispatch(setTestStatus(data)),
    updateBandWidth: (data) => dispatch(updateBandWidth(data)),
    updateInterviewPage: (data) => dispatch(updateInterviewPage(data)),
    fetchInterviewDetailsRequested: (data) =>
      dispatch(fetchInterviewDetailsRequested(data)),
    fetchMyProfile: () => dispatch(fetchMyProfile()),
    fetchTurnServerCreds: () => dispatch(fetchTurnServerCreds()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SystemMetrics));
