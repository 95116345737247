import {
  FETCH_CHAT_MSGS_FAILURE,
  FETCH_CHAT_MSGS_REQUESTED,
  FETCH_CHAT_MSGS_SUCCESS,
  RECEIVE_MESSAGE,
  SEND_MESSAGE,
  FETCH_ALL_CHAT_MSGS_REQUESTED,
  FETCH_ALL_CHAT_MSGS_SUCCESS,
  FETCH_ALL_CHAT_MSGS_FAILURE,
  SET_NOTIFICATION_DATA,
} from "../actionTypes";

const initialState = {
  isLoading: false,
  messages: [],
  unreadsMapping: [],
};

const proctorId = null;

const ChatReducer = function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case FETCH_CHAT_MSGS_REQUESTED:
      return {
        ...state,
        isLoading: true,
        messages: [],
      };
    case FETCH_CHAT_MSGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        messages: action.payload,
      };
    case FETCH_CHAT_MSGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        messages: [],
      };
    case SEND_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case RECEIVE_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };

    case SET_NOTIFICATION_DATA:
      return {
        ...state,
        unreadsMapping: action.payload,
      };

    default:
      return state;
  }
};

export default ChatReducer;
