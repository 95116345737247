import {
  FETCH_HR_GRAPH_STATS_REQUESTED,
  FETCH_HR_GRAPH_STATS_SUCCESS,
  FETCH_HR_QUICK_STATS_REQUESTED,
  FETCH_HR_QUICK_STATS_SUCCESS,
  FETCH_HR_SUMMARY_STATS_REQUESTED,
  FETCH_HR_SUMMARY_STATS_SUCCESS,
} from "../actionTypes";
import { FETCH_HR_GRAPH_STATS_FAILURE } from "../actionTypes/hrdashboard";

export const fetchHrSummaryStatsRequested = (data) => {
  return {
    type: FETCH_HR_SUMMARY_STATS_REQUESTED,
    payload: data,
  };
};

export const fetchHrSummaryStatsSuccess = (data) => {
  return {
    type: FETCH_HR_SUMMARY_STATS_SUCCESS,
    payload: data,
  };
};

export const fetchHrQuickStatsRequested = (data) => {
  return {
    type: FETCH_HR_QUICK_STATS_REQUESTED,
    payload: data,
  };
};

export const fetchHrGraphStatsFailure = (data) => {
  return {
    type: FETCH_HR_GRAPH_STATS_FAILURE,
    payload: data,
  };
};

export const fetchHrQuickStatsSuccess = (data) => {
  return {
    type: FETCH_HR_QUICK_STATS_SUCCESS,
    payload: data,
  };
};

export const fetchHrGraphStatsRequested = (data) => {
  return {
    type: FETCH_HR_GRAPH_STATS_REQUESTED,
    payload: data,
  };
};

export const fetchHrGraphStatsSuccess = (data) => {
  return {
    type: FETCH_HR_GRAPH_STATS_SUCCESS,
    payload: data,
  };
};
