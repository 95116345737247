/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import authHeader from "./auth-header";
import { BASE_URL, POSTGRES_URL } from "../assets/js/config";

const getAllTests = () => {
  return axios.get(BASE_URL + "api/quiz", { headers: authHeader() });
};

export const fetchTests = (filter) => {
  let query_arr = [];
  for (let filter_key in filter) {
    const value = filter[filter_key];
    if (value !== null && value !== undefined) {
      query_arr.push(`${filter_key}=${value}`);
    }
  }
  const query_str = query_arr.join("&");
  return axios
    .get(BASE_URL + "api/quiz/?" + query_str, {
      headers: authHeader(),
    })
    .then((res) => {
      // console.log("res", res.data);
      return res.data;
    })
    .catch((err) => {
      throw err.response.data ?? "Error while fetching";
    });
};

export const addGroupDetails = (data) => {
  return axios
    .post(BASE_URL + "api/quizgroup", data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const updateGroupDetails = ({ data, id }) => {
  return axios
    .put(BASE_URL + `api/quizgroup/${id}`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const deleteGroupDetails = (id) => {
  return axios
    .delete(`${BASE_URL}api/quizgroup/${id}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Error(err.response.data);
    });
};

export const fetchAllGroupDetails = (type) => {
  return axios
    .get(BASE_URL + `api/quizgroup?type=${type}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const fetchTest = (id) => {
  return axios
    .get(BASE_URL + "api/quiz/" + id, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

export const sendEmails = (data) => {
  return axios
    .post(`${BASE_URL}api/quizassign/customemail`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const sendTestInviteEmails = (data) => {
  const { quiz_id, assign_ids } = data;
  return axios
    .put(
      `${BASE_URL}api/quiz/${quiz_id}/testinvite`,
      { assign_ids },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const sendReminderEmails = (data) => {
  const { quiz_id, assign_ids } = data;
  return axios
    .put(
      `${BASE_URL}api/quiz/${quiz_id}/reminder`,
      { assign_ids },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const fetchCompanies = () => {
  return axios
    .get(BASE_URL + "api/company", {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw Error(e.response.data);
    });
};

export const submitTest = ({ id, response, isSubmit }) => {
  return axios
    .put(
      `${BASE_URL}api/quizassign/updateresponse`,
      { response, quiz: id, isSubmit },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const submitFeedback = (data) => {
  const { testId, feedback } = data;
  return axios
    .put(`${BASE_URL}api/quizassign/${testId}/updatefeedback`, feedback, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

export const assignTest = ({ id, data }) => {
  return axios
    .put(BASE_URL + `api/quiz/${id}/assign`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw Error(e.response.data);
    });
};

export const assignTestProctor = ({ id, data }) => {
  return axios
    .put(BASE_URL + `api/quiz/${id}/assign-proctors`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const deleteTest = (id) => {
  return axios
    .delete(`${BASE_URL}api/quiz/${id}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
     console.log(err)
      throw err.response.data
    });
};

export const fetchQuizProctoring = (id) => {
  return axios
    .get(`${BASE_URL}api/quizproctoring/${id}/me`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data.msg);
    });
};

export const fetchTestAssign = (id) => {
  return axios
    .get(`${BASE_URL}api/quiz/${id}/assign`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const fetchTestProctorAssign = (id) => {
  return axios
    .get(`${BASE_URL}api/quiz/${id}/assign-proctors`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const fetchTestResponse = (id) => {
  return axios
    .get(`${BASE_URL}api/quizassign?quiz=${id}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const fetchTestResponseFromRedis = (id) => {
  return axios
    .get(`${BASE_URL}api/quizassign/${id}/getresponse`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const deleteResponse = (id) => {
  return axios
    .delete(`${BASE_URL}api/quizassign/${id}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const fetchTestScore = (data) => {
  const { testId, userId } = data;
  return axios
    .get(`${BASE_URL}api/quizassign/quiz/${testId}/user/${userId}/summary`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const fetchTestSubmissionsCSV = (data) => {
  return axios
    .post(
      `${BASE_URL}api/quizassign/downloadcsv`,
      {
        ...data,
      },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

export const updateQuizSet = ({ quiz_set, id }) => {
  return axios
    .put(
      `${BASE_URL}api/quizassign/${id}/updatequizset`,
      {
        quiz_set,
      },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const fetchTestsGroupSubmissionsCSV = (id) => {
  return axios
    .post(
      `${BASE_URL}api/quizgroup/downloadcsv`,
      {
        quiz_group: id,
      },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const updateOverallRank = ({ quiz_group }) => {
  return axios
    .post(
      `${BASE_URL}api/quizgroup/updateoverallrank`,
      {
        quiz_group,
      },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const updatePublicTestStudentDetailsService = (data) => {
  return axios
    .post(`${BASE_URL}api/quizassign`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const updateTestReportService = (data) => {
  return (
    axios
      .put(`${BASE_URL}api/quizassign/afterprocess`, data, {
        headers: authHeader(),
      })
      .then((res) =>
        axios.post(`${BASE_URL}api/quizassign/generatereport`, data, {
          headers: authHeader(),
        })
      )
      // .catch((err) => {
      //   console.log("checking1", err?.response?.data?.msg)
      //   throw Error(err?.response?.data?.msg);
      // })
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        throw Error(err?.response?.data?.msg);
      })
  );
};

const getOneTest = (id) => {
  return axios
    .get(BASE_URL + "api/quiz/" + id, {
      headers: authHeader(),
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

const createOneTest = () => {
  return axios.post(BASE_URL + "api/quiz", {}, { headers: authHeader() });
};

const saveOneTest = (data, id) => {
  return axios
    .put(BASE_URL + "api/quiz/" + id, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

const deleteOneTest = (id) => {
  return axios
    .delete(BASE_URL + "api/quiz/" + id, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

const assignEmail = (data, id) => {
  return axios
    .put(
      BASE_URL + "api/quizassign/assign/" + id,
      { emails: data },
      { headers: authHeader() }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};
const getAssignedUsers = (filter) => {
  let queryString = [];
  for (let key in filter) {
    if (filter[key] !== null && filter[key] !== "") {
      queryString.push(`${key}=${filter[key]}`);
    }
  }

  queryString = queryString.join("&");
  return axios.get(`${BASE_URL}api/quizassign?${queryString}`, {
    headers: authHeader(),
  });
};

const getOneResponse = (email) => {
  return axios.get(BASE_URL + "api/quizassign/?email=" + email, {
    headers: authHeader(),
  });
};

const loadStudentResponse = (id) => {
  return axios.get(BASE_URL + `api/quizassign/${id}`, {
    headers: authHeader(),
  });
};

const putUserResponse = (id, response) => {
  return axios.put(
    BASE_URL + `api/quizassign/updateresponse`,
    { response, quiz: id },
    {
      headers: authHeader(),
    }
  );
};

export const createStudentSchemaService = ({ testId, type }) => {
  return axios.post(
    `${POSTGRES_URL}api/psql/user-schema/${testId}`,
    { type },
    {
      headers: authHeader(),
    }
  );
};

export const submitSolution = ({
  testId,
  type,
  sectionIndex,
  questionIndex,
  query,
}) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${BASE_URL}api/psql/validate-answer/${testId}`,
        { sectionIndex, questionIndex, query, type },
        {
          headers: authHeader(),
        }
      )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
};

const uploadUserLogs = (data, id) => {
  return axios.put(BASE_URL + `api/quizassign/${id}/user-tracking`, data, {
    headers: authHeader(),
  });
};

const uploadUserCameraLogs = (data) => {
  return axios.put(BASE_URL + `api/quizassign/captureusercamera`, data, {
    headers: authHeader(),
  });
};

const uploadUserVideoStats = (data) => {
  return axios.put(BASE_URL + `api/quizassign/capturevideostate`, data, {
    headers: authHeader(),
  });
};

export const sendTestReportsservice = ({ quiz_id, assign_ids }) => {
  return axios.put(
    BASE_URL + `api/quiz/${quiz_id}/generate-email`,
    { assign_ids },
    {
      headers: authHeader(),
    }
  );
};

export const updateResponsequestionTime = ({ id, data }) => {
  return axios.put(
    `${BASE_URL}api/quizassign/${id}/update-response-question-time`,
    data,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};

export const updateTabChanges = ({ id }) => {
  return axios.put(
    `${BASE_URL}api/quizassign/${id}/update-tab-changes`,
    {},
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};

export const updateEndTimeDuration = ({ id, data }) => {
  return axios
    .put(
      `${BASE_URL}api/quizassign/${id}/update`,
      { ...data },
      {
        headers: {
          ...authHeader(),
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e.response.data;
    });
};

export const cloneTestApi = (id) => {
  return axios
    .post(
      `${BASE_URL}api/quiz/${id}/clone`,
      {},
      {
        headers: { ...authHeader() },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e.response.data;
    });
};

export const updatingCutoffApi = ({ id, data }) => {
  return axios
    .put(
      `${BASE_URL}api/quiz/${id}/update_cutoff_marks`,
      { ...data },
      {
        headers: { ...authHeader() },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e.response.data;
    });
};

export const cutOffPassedStudApi = (id) => {
  return axios
    .put(
      `${BASE_URL}api/quizassign/${id}/cutoff_students`,
      {},
      {
        headers: { ...authHeader() },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e.response.data;
    });
};

export const generatedetailedsummaryApi = (ids) => {
  return axios
    .post(
      `${BASE_URL}api/quiz/generatedetailedsummary`,
      { ids },
      {
        headers: { ...authHeader() },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e.response.data;
    });
};

export default {
  getAllTests,
  getOneTest,
  createOneTest,
  saveOneTest,
  deleteOneTest,
  assignEmail,
  getAssignedUsers,
  getOneResponse,
  putUserResponse,
  loadStudentResponse,
  uploadUserLogs,
  uploadUserCameraLogs,
  updateResponsequestionTime,
  updateTabChanges,
  uploadUserVideoStats,
};
