import React, { useRef, useState, useEffect } from "react";
import "../common/Common.css";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import {
  loginRequest,
  forgotPasswordRequest,
  loginSuccess,
  updateAlreadyLoggedinModel,
} from "../../store/actions/auth";
import { ReactComponent as EyeCrossIcon } from "../../assets/img/svg/cross-eye.svg";
import { ReactComponent as EyeIcon } from "../../assets/img/svg/heye.svg";
import { ReactComponent as ButtonSpinner } from "../../assets/img/svg/button-spinner.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../assets/js/config";
import { defaultToastSetting } from "../../assets/js/toast_config";
import Modal from "../common/Modal/Modal";
import UniversalButton from "../common/StandardButton/UniversalButton";

const required = (value) => {
  if (!value) {
    return (
      <div className=" text-red-500 text-sm font-['poppins']">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValid = emailRegex.test(value);
  if (!isValid) {
    return (
      <div className="text-red-500 text-sm font-['poppins']">
        This is not a valid email !
      </div>
    );
  }
};

const Login = (props) => {
  const form = useRef();
  const resetForm = useRef();
  const checkBtn = useRef();
  const history = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [popModal, setPopModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState("");

  const { isLoggedIn, isLoginLoading, isUserAlreadyLoggedIn } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const googleScript = document.getElementById("googleScript");

    if (window.google) {
      // All is good! Carry on
      googleButtonInitialize();
    }

    googleScript.addEventListener("load", () => {
      // Patiently waiting to do the thing
      googleButtonInitialize();
    });

    return () => {
      googleScript.removeEventListener("load");
    };
  }, []);

  const googleButtonInitialize = () => {
    window.google?.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCallbackResponse,
      context: "signup",
    });

    window.google?.accounts.id.renderButton(
      document.getElementById("signInGoogle"),
      {
        theme: "outline",
        size: "large",
        text: "signin_with",
        shape: "circle",
      }
    );
  };
  const handleCallbackResponse = (res) => {
    const encoded_credential = res.credential;
    //Sending encoded_credential as header to the backend to be processed further
    axios
      .post(
        `${BASE_URL}api/auth/googleLogin`,
        {},
        {
          //Send entire google JWT Token in headers and authenticate via auth in backend.
          headers: {
            "x-google-token": encoded_credential,
          },
        }
      )
      .then((res) => {
        //Setting data in localStorage for login (To be used as Redux state later)
        const auth = res.data;
        if (auth.token) {
          localStorage.setItem("priksha_name", auth.name);
          localStorage.setItem("priksha_token", auth.token);
          localStorage.setItem("priksha_type", auth.type);
          localStorage.setItem("priksha_email", auth.email);
        }

        dispatch(loginSuccess(auth));

        toast.success("Login successful", defaultToastSetting);
      })
      .catch((err) => {
        toast.error(err.response.data, defaultToastSetting);
      });
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangeResetEmail = (e) => {
    const resetemail = e.target.value;
    setResetEmail(resetemail);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLocalLogin = (e, logout) => {
    const type = props.type.toUpperCase();
    if (!logout) e.preventDefault();
    else {
      localStorage.clear();
    }
    if (!isLoggedIn) {
      dispatch(loginRequest({ email, password, type, logout }));
    }
  };

  const handlResetPassword = (e) => {
    e.preventDefault();
    dispatch(forgotPasswordRequest({ resetEmail }));
    handlePopModal();
  };

  const handlePopModal = () => {
    setPopModal(!popModal);
  };

  if (isLoggedIn) {
    <Navigate to="/dashboard" />;
  }

  const handleShowPassword = () => {
    setShowPassword((prevshowPassword) => !prevshowPassword);
  };

  return (
    <>
      <div className="login-input-div  md:px-10 md:py-10 my-5">
        {/* {props.type === "Candidate" && (
          <div className="text-xs mb-16 text-right">
            Don't have an account ?{" "}
            <span
              className="hover:text-kPrimaryDark hover:underline font-medium cursor-pointer"
              onClick={() => {
                history("/signup");
              }}
            >
              Sign Up
            </span>
          </div>
        )} */}
        <p className="text-[1.8rem] font-['Poppins'] font-bold pb-2">
          {/* {props.type === "Hr" ? "Hiring Team Sign in" : "Candidate Sign in"} */}
          Sign in
        </p>

        <Form
          onSubmit={(e) => handleLocalLogin(e, false)}
          ref={form}
          autoComplete="new-password"
          className="pb-2"
        >
          <div className="login-form-email mb-2">
            {/* <label
              htmlFor="email"
              className="font-['poppins'] text-neutral-400"
            >
              User name or email address
            </label> */}
            <Input
              type="email"
              className="login-form-emailInput inputPlaceholder"
              name="email"
              placeholder="Enter User name or Email"
              value={email}
              onChange={onChangeEmail}
              validations={[required, validEmail]}
            />
          </div>

          <div className="login-form-pass mb-4">
            {/* <label
              htmlFor="password"
              className="font-['poppins'] text-neutral-400"
            >
              Your password
            </label> */}
            <div className="grid items-center relative">
              <Input
                type={`${showPassword ? "text" : "password"}`}
                className="login-form-passInput inputPlaceholder"
                autoComplete="off"
                name="password"
                placeholder="Password"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
              />
              {showPassword ? (
                <EyeCrossIcon
                  onClick={handleShowPassword}
                  title="View"
                  alt="view"
                  className="absolute w-5 h-5 right-0  mr-4 cursor-pointer"
                />
              ) : (
                <EyeIcon
                  fill="#666666"
                  fill-opacity="0.8"
                  onClick={handleShowPassword}
                  title="View"
                  alt="view"
                  className="absolute w-5 h-5 right-0  mr-4 cursor-pointer"
                />
              )}
            </div>
          </div>

          <div className="flex flex-col">
            <p
              className="text-[#0337EF] self-end sm:text-xs md:text-sm cursor-pointer mb-4 font-['poppins']"
              data-modal-toggle="authentication-modal"
              onClick={() => handlePopModal()}
            >
              Forgot password ?
            </p>
            <button
              className="text-[#5B8B00] transition bg-[#BCFB46] hover:bg-gray-800 hover:text-white font-medium rounded-[0.3rem] text-md px-6 py-3 flex justify-center mr-2 mb-2 cursor-pointer font-['Poppins']"
              style={{ boxShadow: "0px 0px 80px 0px rgba(188, 251, 70, 0.56)" }}
            >
              {isLoginLoading ? (
                <ButtonSpinner />
              ) : (
                <span className="font-medium text-[1rem]">Sign in</span>
              )}
            </button>
          </div>

          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
        <div className="relative flex py-2 items-center justify-center">
          {/* <div className="flex-grow border-t border-gray-400"></div> */}
          <h1 className="flex-shrink mx-4 text-[1rem] mt-[1rem] font-[Poppins] text-[#B5B5B5] font-medium">
            or continue with
          </h1>
          {/* <div className="flex-grow border-t border-gray-400"></div> */}
        </div>
        <div className="flex flex-col items-center justify-center">
          <div
            id="signInGoogle"
            className="rounded-full flex gap-3 items-center justify-center py-2 font-['nunito'] text-[#333333] mb-2 "
          ></div>
          {/* <div className="border-2 w-[240px] text-sm rounded-full flex gap-3 items-center justify-center py-2 font-['nunito'] text-[#333333] mb-4 cursor-pointer">
            <img src={githubIcon} height="20px" />
            <a
              href={`https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GITHUB_REDIRECT_URL}githublogin&scope=read:user,user:email`}
            >
              Sign in with Github
            </a>
          </div> */}
        </div>
      </div>
      <Modal isModal={isUserAlreadyLoggedIn} className={"md:w-[40%]"}>
        <div className="p-3">
          <p className="font-['nunito'] text-md text-center">
            You are already logged into an active session. To log in again,
            please log out your previous session.
          </p>
          <div className="flex justify-center items-center gap-x-4 mt-4 pt-4">
            {/* <button
              type="submit"
              onClick={(e) => handleLocalLogin(e, true)}
              className="text-white transition bg-[#009999]  hover:bg-gray-800 hover:text-white font-medium rounded-full  
              text-md px-6 py-2 flex justify-center mr-2 mb-2 cursor-pointer font-['nunito']"
            >
              Logout
            </button> */}
            <UniversalButton
              onClickFunction={(e) => handleLocalLogin(e, true)}
              title="Logout"
              classNames="w-auto rounded-[2rem] px-6 py-3"
            />
            <UniversalButton
              onClickFunction={() =>
                dispatch(updateAlreadyLoggedinModel(false))
              }
              title="Close"
              classNames="w-auto rounded-[2rem] px-6 py-3"
            />
            {/* <button
              type="submit"
              onClick={() => dispatch(updateAlreadyLoggedinModel(false))}
              className="text-white transition bg-[#009999]  hover:bg-gray-800 hover:text-white font-medium rounded-full  
              text-md px-6 py-2 flex justify-center mr-2 mb-2 cursor-pointer font-['nunito']"
            >
              Close
            </button> */}
          </div>
        </div>
      </Modal>
      {popModal && (
        <div className=" flex justify-center w-full h-full items-center  m-3">
          <div
            className={`
           overflow-y-auto overflow-x-hidden fixed z-50 w-full inset-0 h-modal md:h-full`}
            id="authentication-modal"
            style={{ backgroundColor: "rgb(128 ,128 ,128,0.5)" }}
          >
            <div className="relative items-center justify-center mx-[15%] sm:mx-[25%] lg:mx-[35%] top-[10rem]">
              <div className="flex relative bg-slate-100 shadow-sm shadow-slate-200  rounded-xl ">
                <div className="flex-col items-center top-[50%] w-full p-5  text-start justify-center">
                  <div className="flex items-center justify-center">
                    <button
                      type="button"
                      className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                      data-modal-toggle="authentication-modal"
                      onClick={() => handlePopModal()}
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                    <Form
                      className="space-y-6 w-full"
                      onSubmit={handlResetPassword}
                      ref={resetForm}
                    >
                      <h3 className="mb-4 text-xl font-medium text-gray-900">
                        Reset password
                      </h3>
                      <div>
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          Email
                        </label>
                        <Input
                          type="email"
                          name="email"
                          value={resetEmail}
                          onChange={onChangeResetEmail}
                          id="email"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                          placeholder="name@company.com"
                          validations={[required, validEmail]}
                        />
                      </div>
                      <button
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                      >
                        Send Email
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
