import React from "react";
import {  useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Editor from "../tests/codeEditor/Components/Editor";
import Results from "../tests/codeEditor/Components/Results";


export default function InterviewCodeEditor(props) {
  const { currentSectionIndex, currentQuestionIndex, response } = useSelector(
    (state) => state.interviewpanelReducer
  );
  const interviewId = useParams()?.id;

  return (
    <div>
      <Editor
        sectionId={currentSectionIndex}
        questionId={currentQuestionIndex}
        testId={interviewId}
        type="I"
        socket={props.socket}
        response={response}
        is_disable_copy_paste={props.is_disable_copy_paste}
      />
      <Results
        sectionId={currentSectionIndex}
        response={response}
        questionId={currentQuestionIndex}
        testId={interviewId}
        toggleSocket={props.toggleSocket}
        EditorRunType={props.EditorRunType}
        type="I"
        socket={props.socket}
      />
    </div>
  );
}
