import React from "react";
import DragComponent from "./DragComponent";
import { ReactComponent as Plus } from "../../../assets/img/svg/plus4.svg";
import eye from "../../../assets/img/svg/eye.svg";
import AddQuestion from "./AddQuestion";
import ReactQuill from "react-quill";
import { modules } from "../utils/QuillModules";

// Store
import { connect } from "react-redux";
import {
  updateTestSectionDataWithKey,
  updateTestMainDataWithKey,
  deleteTestSection,
  duplicateTestSection,
  addTestSection,
  updateTestData,
  fetchAllTests,
  updateQuestionModal,
  updateTestDataOnExchange,
} from "../../../store/actions/test";
import { DropDown } from "../../common/ReactSelect/ReactSelect";
import { formateDate } from "../utils/TimeFormat";
import userService from "../../../services/user.service";
import { fetchUserTablesRequested } from "../../../store/actions/test";
import Modal from "../../common/Modal/Modal";
import QuestionBank from "../../../pages/QuestionBank/QuestionBank";
import Tooltip from "../../common/Tooltip/Tooltip";
import UniversalCheckbox from "../../common/UniversalCheckbox";

class NewAddSection extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedTest: null,
      allTestOptions: [],
      proctors: [],
      selectedProctors: null,
      currentQuestionIndex: null,
      currentSectionIndex: null,
    };
    this.addSection = this.addSection.bind(this);
    this.openQuestionBankModal = this.openQuestionBankModal.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount() {
    this.props.fetchAllTests({
      quiz_type: "all",
      status: true,
      is_a_set: true,
    });
    const Options =
      this.props.test.sets.length > 0 &&
      this.props.test?.sets.map((test) => {
        return {
          value: test._id,
          label: `${test.title}`,
        };
      });
    const ProctorOptions =
      this.props.test.alloted_proctors.length > 0 &&
      this.props.test?.alloted_proctors.map((test) => {
        return {
          value: test._id,
          label: `${test.name} (${test.email})`,
        };
      });
    this.setState((prev) => {
      return {
        ...prev,
        selectedTest: Options,
        selectedProctors: ProctorOptions,
      };
    });
    userService
      .getAllUsers({ type: "P" })
      .then((res) => {
        const Options = res?.data.map((proctor) => {
          // console.log("insidedropdown", test, formateDate(test.start_duration));
          return {
            value: proctor._id,
            label: `${proctor.name} (${proctor.email})`,
          };
        });
        this.setState((prev) => {
          return {
            ...prev,
            proctors: [...Options],
          };
        });
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.test &&
      !this.props.fetchTablesFailure &&
      this.props.tables.length === 0
    ) {
      const check = this.props.test?.components?.some(
        (e) => e.section_type === "PSQL"
      );
      if (check) this.props.fetchUserTablesRequested();
    }
    if (
      this.props.allSelectableTests?.all !== prevProps.allSelectableTests?.all
    ) {
      const Options = this.props.allSelectableTests.all.map((test) => {
        // console.log("insidedropdown", test, formateDate(test.start_duration));
        return {
          value: test._id,
          label: `${test.title} (${formateDate(test.start_duration)})`,
        };
      });
      this.setState((prev) => {
        return {
          ...prev,
          allTestOptions: Options,
        };
      });
    }
  }

  onDragStart = (event, sectionIndex) => {
    this.setState({
      dragData: JSON.stringify({ sectionIndex }),
    });
  };

  onDrop = (event, toSectionIndex) => {
    event.preventDefault();
    const draggedItem = JSON.parse(this.state.dragData);
    const { sectionIndex } = draggedItem;

    if (sectionIndex !== toSectionIndex) {
      const updatedSections = [...this.props.test.components];
      const [draggedSection] = updatedSections.splice(sectionIndex, 1);
      updatedSections.splice(toSectionIndex, 0, draggedSection);

      this.props.updateTestDataOnExchange({
        ...this.props.test,
        components: updatedSections,
      });
    }
  };

  moveSectionDown = ({ sectionIndex }) => {
    if (
      sectionIndex >= 0 &&
      sectionIndex < this.props.test.components.length - 1
    ) {
      const updatedSections = [...this.props.test.components];
      const temp = updatedSections[sectionIndex];
      updatedSections[sectionIndex] = updatedSections[sectionIndex + 1];
      updatedSections[sectionIndex + 1] = temp;

      this.props.updateTestDataOnExchange({
        ...this.props.test,
        components: updatedSections,
      });
    }
  };

  moveSectionUp = ({ sectionIndex }) => {
    if (sectionIndex > 0 && sectionIndex < this.props.test.components.length) {
      const updatedSections = [...this.props.test.components];
      const [movedSection] = updatedSections.splice(sectionIndex, 1);
      updatedSections.splice(sectionIndex - 1, 0, movedSection);

      this.props.updateTestDataOnExchange({
        ...this.props.test,
        components: updatedSections,
      });
    }
  };

  handleChange = (value) => {
    this.setState({ selectedTest: value });
    let selectTestIds = value ? value?.map((test) => test.value) : [];
    this.props.updateTestMainDataWithKey({
      key: "sets",
      value: selectTestIds,
    });
  };

  handleChangeProctors = (value) => {
    this.setState({ selectedProctors: value });
    let selectedProctorIds = value ? value?.map((test) => test.value) : [];
    this.props.updateTestMainDataWithKey({
      key: "alloted_proctors",
      value: selectedProctorIds,
    });
  };

  addSection() {
    this.props.addTestSection();
    this.props.handleToast("Section Added Successfully");
  }

  handleTestPublic() {
    this.props.updateTestMainDataWithKey({
      key: "is_public",
      value: !this.props.test.is_public,
    });
    this.props.updateTestMainDataWithKey({
      key: "is_proctoring",
      value: false,
    });
  }

  handleSectionView = (index) => {
    this.props.updateTestData(index);
  };

  openQuestionBankModal = ({ questionIndex, sectionIndex }) => {
    this.setState(
      (prev) => {
        return {
          ...prev,
          currentQuestionIndex: questionIndex,
          currentSectionIndex: sectionIndex,
        };
      },
      () => this.props.updateQuestionModal(true)
    );
  };

  render() {
    const { updateQuestionOrder, deleteQuestion, duplicateQuestion } =
      this.props;
    return (
      <div className="my-2">
        <Modal
          onClose={() => this.props.updateQuestionModal(false)}
          isModal={this.props.questionbank_modal}
          showCloseButton
          className="w-[80%] pt-[35px]"
        >
          <QuestionBank
            modalType="T"
            isModal={true}
            sectionIndex={this.state.currentSectionIndex}
            questionIndex={this.state.currentQuestionIndex}
          />
        </Modal>
        <div className="flex gap-x-10 items-center flex-wrap mb-3">
          <div className="flex flex-col col-span-2 sm:col-span-1">
            <label
              htmlFor="navigation_type"
              className="font-bold text-[1.1rem] font-['nunito'] mb-1"
            >
              Navigation Type<span className="text-red-500 mx-0.5">*</span>
            </label>
            <select
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "navigation_type",
                  value: e.target.value,
                })
              }
              value={this.props.test.navigation_type}
              className="border w-48 outline-none border-[#E0E0E0] p-1"
            >
              <option value="A">Navigate Anywhere</option>
              <option value="F">Forward_only</option>
            </select>
          </div>
        </div>
        <h6 className="font-bold text-[1.1rem] font-['nunito']">
          Features
        </h6>
        <div className="flex  px-2 py-4 border border-gray-400 rounded gap-5 flex-wrap">
          <div className="flex items-center md:basis-1/6 basis-[100%]">
            <UniversalCheckbox
              title="Randomize Section"
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "is_sections_random",
                  value: !this.props.test.is_sections_random,
                })
              }
              name="is_sections_random"
              value={this.props.test.is_sections_random}
              hovertext="Randomize section order for all students."
            />
            {/* <input
              className="h-4 w-4 mx-3"
              type="checkbox"
              name="is_sections_random"
              role="switch"
              checked={this.props.test.is_sections_random}
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "is_sections_random",
                  value: !this.props.test.is_sections_random,
                })
              }
            />
            <label
              htmlFor="is_sections_random"
              className="font-bold text-[1rem] font-['nunito']"
            >
              Randomize Section
            </label>
            <img
              src={eye}
              alt="info"
              title="Randomize section order for all students."
              className="ml-2 cursor-pointer"
            /> */}
          </div>
          <div className="flex items-center md:basis-1/6 basis-[100%]">
            <UniversalCheckbox
              title="Allow Flexible Exam Time"
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "allow_flexible_exam_time",
                  value: !this.props.test.allow_flexible_exam_time,
                })
              }
              name="allow_flexible_exam_time"
              value={this.props.test.allow_flexible_exam_time}
              hovertext="Flexible test scheduling: Take tests anytime within specified window for set duration."
            />
            {/* <input
              className="h-4 w-4 mx-3"
              type="checkbox"
              name="allow_flexible_exam_time"
              role="switch"
              checked={this.props.test?.allow_flexible_exam_time}
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "allow_flexible_exam_time",
                  value: !this.props.test.allow_flexible_exam_time,
                })
              }
            />
            <label
              htmlFor="is_sections_random"
              className="font-bold text-[1rem] font-['nunito']"
            >
              Allow Flexible Exam Time
            </label>
            <img
              src={eye}
              alt="info"
              title="Flexible test scheduling: Take tests anytime within specified window for set duration."
              className="ml-2 cursor-pointer"
            /> */}
          </div>

          {!this.props.test.is_public && (
            <div className="flex items-center md:basis-1/6 basis-[100%]">
              <UniversalCheckbox
                title="Proctor"
                onChange={(e) =>
                  this.props.updateTestMainDataWithKey({
                    key: "is_proctoring",
                    value: !this.props.test.is_proctoring,
                  })
                }
                name="is_proctoring"
                value={this.props.test.is_proctoring}
                hovertext="Enable proctoring for this test by checking this box."
              />
              {/* <input
                className="h-4 w-4 mx-3"
                type="checkbox"
                name="is_proctoring"
                role="switch"
                checked={this.props.test.is_proctoring}
                onChange={(e) => {
                  this.props.updateTestMainDataWithKey({
                    key: "is_proctoring",
                    value: !this.props.test.is_proctoring,
                  });
                }}
              />
              <label
                htmlFor="is_proctoring"
                className="font-bold text-[1rem] font-['nunito']"
              >
                Proctor
              </label>

              <img
                src={eye}
                alt="info"
                title="Enable proctoring for this test by checking this box."
                className="ml-2 cursor-pointer"
              /> */}
            </div>
          )}
          <div className="flex items-center md:basis-1/6 basis-[100%]">
            <UniversalCheckbox
              title="Public"
              onChange={(e) => this.handleTestPublic()}
              name="is_public"
              value={this.props.test.is_public}
              hovertext="Checking this box makes the current test public, accessible to anyone with the test link."
            />
            {/* <input
              className="h-4 w-4 mx-3"
              type="checkbox"
              name="is_public"
              role="switch"
              checked={this.props.test.is_public}
              onChange={(e) => this.handleTestPublic()}
            />
            <label
              htmlFor="is_public"
              className="font-bold text-[1rem] font-['nunito']"
            >
              Public
            </label>
            <Tooltip
              text=
            >
              <img
                src={eye}
                alt="info"
                className="ml-2 cursor-pointer"
              />
            </Tooltip> */}
          </div>
          {/* <div className="flex items-center md:basis-1/6 basis-[100%]">
            <input
              className="h-4 w-4 mx-3"
              type="checkbox"
              name="is_recording"
              role="switch"
              checked={this.props.test.is_recording}
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "is_recording",
                  value: !this.props.test.is_recording,
                })
              }
            />
            <label
              htmlFor="is_recording"
              className="font-bold text-[1rem] font-['nunito']"
            >
              Record
            </label>
          </div> */}

          <div className="flex items-center md:basis-1/6 basis-[100%]">
            <UniversalCheckbox
              title="Aits"
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "is_aits",
                  value: !this.props.test.is_aits,
                })
              }
              name="is_aits"
              value={this.props.test.is_aits}
              hovertext="Selecting this option designates the current test as an AITS (All India Test Series), exclusive to subscribed students."
            />
            {/* <input
              className="h-4 w-4 mx-3"
              type="checkbox"
              name="is_aits"
              role="switch"
              checked={this.props.test.is_aits}
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "is_aits",
                  value: !this.props.test.is_aits,
                })
              }
            />
            <label
              htmlFor="is_aits"
              className="font-bold text-[1rem] font-['nunito']"
            >
              Aits
            </label>
            <img
              src={eye}
              alt="info"
              title="Selecting this option designates the current test as an AITS (All India Test Series), exclusive to subscribed students."
              className="ml-2 cursor-pointer"
            /> */}
          </div>

          {/* <div className="flex items-center md:basis-1/6 basis-[100%]">
            <input
              className="h-4 w-4 mx-3"
              type="checkbox"
              name="is_preparation_time"
              role="switch"
              checked={this.props.test.is_preparation_time}
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "is_preparation_time",
                  value: !this.props.test.is_preparation_time,
                })
              }
            />
            <label
              htmlFor="is_preparation_time"
              className="font-bold text-[1rem] font-['nunito']"
            >
              Preparation Time
            </label>
          </div> */}
          <div className="flex items-center md:basis-1/6 basis-[100%]">
            <UniversalCheckbox
              title="Reveal Scorecard"
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "reveal_scorecard_questions",
                  value: !this.props.test.reveal_scorecard_questions,
                })
              }
              name="reveal_scorecard_questions"
              value={this.props.test.reveal_scorecard_questions}
              hovertext="Shows the student's response on the student report."
            />
            {/* <input
              className="h-4 w-4 mx-3"
              type="checkbox"
              name="reveal_scorecard_questions"
              role="switch"
              checked={this.props.test.reveal_scorecard_questions}
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "reveal_scorecard_questions",
                  value: !this.props.test.reveal_scorecard_questions,
                })
              }
            />
            <label
              htmlFor="reveal_scorecard_questions"
              className="font-bold text-[1rem] font-['nunito']"
            >
              Reveal Scorecard
            </label>
            <img
              src={eye}
              alt="info"
              title="Selecting this option displays both the student's response and submission details on the student report. Otherwise, only submission details are shown without the student's response."
              className="ml-2 cursor-pointer"
            /> */}
          </div>
          {/* {this.props.test.is_preparation_time&& (
            <div className="flex items-center">
              <input
                type="number"
                placeholder="Preparation Time"
                className="border border-gray-400 my-1 w-40 rounded-md outline-gray-300 p-2 h-8"
              />
            </div>
          )} */}
          <div className="flex items-center md:basis-1/6 basis-[100%]">
            <UniversalCheckbox
              title="Disable Copy Paste"
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "is_disable_copy_paste",
                  value: !this.props.test.is_disable_copy_paste,
                })
              }
              name="is_disable_copy_paste"
              value={this.props.test.is_disable_copy_paste}
              hovertext="Selecting this option disables copy/paste functionality during the test session."
            />
            {/* <input
              className="h-4 w-4 mx-3"
              type="checkbox"
              name="is_disable_copy_paste"
              role="switch"
              checked={this.props.test.is_disable_copy_paste}
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "is_disable_copy_paste",
                  value: !this.props.test.is_disable_copy_paste,
                })
              }
            />
            <label
              htmlFor="is_disable_copy_paste"
              className="font-bold text-[1rem] font-['nunito']"
            >
              Disable Copy Paste
            </label>
            <img
              src={eye}
              alt="info"
              title="Selecting this option disables copy/paste functionality during the test session."
              className="ml-2 cursor-pointer"
            /> */}
          </div>
          <div className="flex items-center md:basis-1/6 basis-[100%]">
            <UniversalCheckbox
              title="Fullscreen mode"
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "is_disable_fullscreen",
                  value: !this.props.test.is_disable_fullscreen,
                })
              }
              name="is_disable_fullscreen"
              value={this.props.test.is_disable_fullscreen}
              hovertext="Selecting this option displays the test in full-screen mode."
            />
            {/* <input
              className="h-4 w-4 mx-3"
              type="checkbox"
              name="is_disable_fullscreen"
              role="switch"
              checked={this.props.test.is_disable_fullscreen}
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "is_disable_fullscreen",
                  value: !this.props.test.is_disable_fullscreen,
                })
              }
            />
            <label
              htmlFor="is_disable_fullscreen"
              className="font-bold text-[1rem] font-['nunito']"
            >
              Fullscreen mode
            </label>
            <img
              src={eye}
              alt="info"
              title="Selecting this option displays the test in full-screen mode."
              className="ml-2 cursor-pointer"
            /> */}
          </div>

          <div className="flex items-center md:basis-1/6 basis-[100%]">
            <UniversalCheckbox
              title="Advanced Plagiarism"
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "is_disable_keystrokes",
                  value: !this.props.test.is_disable_keystrokes,
                })
              }
              name="is_disable_keystrokes"
              value={this.props.test.is_disable_keystrokes}
              hovertext="Selecting this option initiates plagiarism checks on student responses."
            />
            {/* <input
              className="h-4 w-4 mx-3"
              type="checkbox"
              name="is_disable_keystrokes"
              role="switch"
              checked={this.props.test.is_disable_keystrokes}
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "is_disable_keystrokes",
                  value: !this.props.test.is_disable_keystrokes,
                })
              }
            />
            <label
              htmlFor="is_disable_keystrokes"
              className="font-bold text-[1rem] font-['nunito']"
            >
              Advanced Plagiarism
            </label>
            <img
              src={eye}
              alt="info"
              title="Selecting this option initiates plagiarism checks on student responses."
              className="ml-2 cursor-pointer"
            /> */}
          </div>

          <div className="flex items-center md:basis-1/6 basis-[100%]">
            <UniversalCheckbox
              title="Advertisement"
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "is_show_advertisement",
                  value: !this.props.test.is_show_advertisement,
                })
              }
              name="is_show_advertisement"
              value={this.props.test.is_show_advertisement}
              hovertext="Selecting this option displays an advertisement once the test is completed."
            />
            {/* <input
              className="h-4 w-4 mx-3"
              type="checkbox"
              name="is_show_advertisement"
              role="switch"
              checked={this.props.test.is_show_advertisement}
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "is_show_advertisement",
                  value: !this.props.test.is_show_advertisement,
                })
              }
            />
            <label
              htmlFor="is_show_advertisement"
              className="font-bold text-[1rem] font-['nunito']"
            >
              Advertisement
            </label>
            <img
              src={eye}
              alt="info"
              title="Selecting this option displays an advertisement once the test is completed."
              className="ml-2 cursor-pointer"
            /> */}
          </div>

          <div className="flex items-center md:basis-1/6 basis-[100%]">

            <input
              className="h-4 w-4 mx-3"
              type="checkbox"
              name="is_disable_tab_switch"
              role="switch"
              checked={this.props.test?.is_disable_tab_switch}
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "is_disable_tab_switch",
                  value: !this.props.test.is_disable_tab_switch,
                })
              }
            />
            <div className="flex flex-col">
              <div className="flex">

                <label
                  htmlFor="is_disable_tab_switch"
                  className="font-bold text-[1rem] font-['nunito']"
                >
                  Disable Tab Switch
                </label>
                <Tooltip
                  theme="RIGHT"
                  text="Selecting this option disables tab switching and allows setting a limit on the number of times students can switch tabs."
                >
                  <img
                    src={eye}
                    alt="info"

                    className="ml-2 cursor-pointer"
                  />
                </Tooltip>

              </div>

              {this.props.test?.is_disable_tab_switch && (
                <input
                  type="number"
                  placeholder="Tab Switch Count"
                  value={this.props.test.tab_switch_count}
                  className="border-2 border-gray-300 block px-2.5 py-1 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 focus:border-[#28A3BC] peer"
                  onChange={(e) =>
                    this.props.updateTestMainDataWithKey({
                      key: "tab_switch_count",
                      value: e.target.value,
                    })
                  }
                />
              )}
            </div>

          </div>

          <div className="flex items-center  md:basis-1/6 basis-[100%]">
            <input
              className="h-4 w-4 mx-3"
              type="checkbox"
              name="is_user_capture_camera"
              role="switch"
              checked={this.props.test?.is_user_capture_camera}
              onChange={(e) =>
                this.props.updateTestMainDataWithKey({
                  key: "is_user_capture_camera",
                  value: !this.props.test.is_user_capture_camera,
                })
              }
            />
            <div className="flex flex-col">
              <div className="flex ">

                <label
                  htmlFor="is_user_capture_camera"
                  className="font-bold text-[1rem] font-['nunito']"
                >
                  Is user capture camera
                </label>
                <Tooltip
                  text="Selecting this option captures the student's image at regular intervals."
                >
                  <img
                    src={eye}
                    alt="info"
                    className="ml-2 cursor-pointer"
                  />
                </Tooltip>
              </div>
              {this.props.test?.is_user_capture_camera && (
                <input
                  type="number"
                  placeholder="Set Capture interval"
                  value={this.props.test?.set_camera_capture_interval}
                  className="border-2 border-gray-300 block px-2.5 py-1 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 focus:border-[#28A3BC] peer"
                  onChange={(e) =>
                    this.props.updateTestMainDataWithKey({
                      key: "set_camera_capture_interval",
                      value: e.target.value,
                    })
                  }
                />)}
            </div>

          </div>
        </div>
        <div className="my-2">
          <div className="flex ">
            <h1 className="font-bold text-[1.1rem] font-['nunito']">
              Select Test Type
            </h1>
            <Tooltip
              theme="RIGHT"
              text="This feature is designed for tests where students can freely select tests based on their domain."
            >
              <img
                src={eye}
                alt="info"
                className="ml-2 cursor-pointer"
              /></Tooltip>
          </div>
          <div className="flex gap-3 font-['nunito'] pt-2">
            <div className="flex items-center">
              <input
                className="h-4 w-4 mr-3"
                type="checkbox"
                name="is_a_set"
                role="switch"
                checked={this.props.test.is_a_set}
                onChange={(e) => {
                  this.props.updateTestMainDataWithKey({
                    key: "is_a_set",
                    value: !this.props.test.is_a_set,
                  });
                  this.props.updateTestMainDataWithKey({
                    key: "allow_set_choose",
                    value: !this.props.test.is_a_set && false,
                  });
                }}
              />
              <label
                htmlFor="is_a_set"
                className="font-bold text-[1rem] font-['nunito']"
              >
                Is a Set
              </label>
            </div>
            <div className="flex items-center">
              <input
                className="h-4 w-4 mr-3"
                type="checkbox"
                name="allow_set_choose"
                role="switch"
                checked={this.props.test.allow_set_choose}
                onChange={(e) => {
                  this.props.updateTestMainDataWithKey({
                    key: "allow_set_choose",
                    value: !this.props.test.allow_set_choose,
                  });
                  this.props.updateTestMainDataWithKey({
                    key: "is_a_set",
                    value: !this.props.test.allow_set_choose && false,
                  });
                }}
              />
              <label
                htmlFor="allow_set_choose"
                className="font-bold text-[1rem] font-['nunito']"
              >
                Allow to choose a set
              </label>
            </div>
          </div>
        </div>
        <div className="  max-w-[300px]  mt-2">
          {this.props.test?.is_a_set && (
            <>
              <h1 className="font-bold text-[1.1rem] font-['nunito']">
                Select proctors
              </h1>
              <DropDown
                placeholder="Select proctors"
                value={this.state.selectedProctors}
                options={this.state.proctors}
                handleChange={this.handleChangeProctors}
                multi={true}
              />
            </>
          )}
          {this.props.test?.allow_set_choose && (
            <>
              <h1 className="font-bold text-[1.1rem] font-['nunito']">
                Select sets
              </h1>
              <DropDown
                placeholder="Select sets"
                value={this.state.selectedTest}
                options={this.state.allTestOptions}
                handleChange={this.handleChange}
                multi={true}
              />
            </>
          )}
        </div>
        <div className="my-2">
          <p className="font-bold text-[1.1rem] font-['nunito'] mt-4">
            Test Structure
          </p>
          <p className="" style={{ opacity: "0.5" }}>
            Add your own questions with fields for the test.
          </p>
        </div>
        {
          !this.props.test?.allow_set_choose && (
            <div className="my-5 rounded-ld">
              {this.props.test.components.map((section, sectionIndex) => (
                <div
                  key={sectionIndex}
                  draggable
                  droppable
                  onDragStart={(e) => this.onDragStart(e, sectionIndex)}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => this.onDrop(e, sectionIndex)}
                >
                  <DragComponent
                    moveQuestionDown={(data) => this.moveSectionDown(data)}
                    moveQuestionUp={(data) => this.moveSectionUp(data)}
                    type="s"
                    test={this.props.test}
                    cardState={section?.isOpen}
                    content={
                      <div className="pl-2 md:px-0 px-2">
                        {section.isOpen && (
                          <h3 className="md:text-xl sm:text-lg text-md font-semibold font-['nunito']">
                            {`Section ${sectionIndex + 1}`}
                          </h3>
                        )}
                        <div className="flex md:flex-row justify-between flex-col pr-0 m:pr-0">
                          <div className="flex gap-2 md:flex-row md:items-center flex-col md:mx-2">
                            <select
                              onChange={(e) => {
                                // if (e.target.value === "PSQL")
                                //   // this.props.fetchUserTablesRequested();

                                this.props.updateTestSectionDataWithKey({
                                  sectionIndex,
                                  key: "section_type",
                                  value: e.target.value,
                                });
                              }}
                              value={section?.section_type}
                              className="border border-gray-300 outline-gray-300 p-1"
                              disabled={
                                this.props.test?.components[sectionIndex]
                                  ?.components?.length
                              }
                            >
                              <option value="G">General Section</option>
                              <option value="C">Coding Section</option>
                              <option value="PSQL">PostgreSQL Section</option>
                            </select>
                            <label
                              className="flex  gap-2"
                              htmlFor="is_section_random"
                            >
                              <input
                                className="h-4 w-4"
                                type="checkbox"
                                name="is_question_random"
                                role="switch"
                                checked={
                                  this.props.test.components[sectionIndex]
                                    .is_questions_random
                                }
                                onChange={(e) =>
                                  this.props.updateTestSectionDataWithKey({
                                    sectionIndex,
                                    key: "is_questions_random",
                                    value:
                                      !this.props.test.components[sectionIndex]
                                        .is_questions_random,
                                  })
                                }
                              />
                              <span className="text-[0.9rem] font-['nunito'] font-bold">
                                Randomize Question
                              </span>
                            </label>
                          </div>
                          {this.props.test.navigation_type === "A" &&
                            section.section_type === "G" ? (
                            <div
                              className={`flex items-center gap-3 mx-2 sm:mx-0`}
                            >
                              <label
                                htmlFor="questions_per_page"
                                className="text-[0.9rem] font-['nunito'] font-bold"
                              >
                                Questions Per Page
                              </label>
                              <select
                                onChange={(e) =>
                                  this.props.updateTestSectionDataWithKey({
                                    sectionIndex,
                                    key: "questions_per_page",
                                    value: e.target.value,
                                  })
                                }
                                value={
                                  this.props.test.components[sectionIndex]
                                    .questions_per_page
                                }
                                className="border border-gray-300 outline-gray-300 p-1"
                              >
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        {section.isOpen ? (
                          <div
                            className={`mx-1 ${section.section_type === "C" ? "pr-2 md:pr-0" : ""
                              }`}
                          >
                            <div className="flex flex-col my-3 ">
                              <label className="text-[1.1rem] font-['nunito'] font-semibold">
                                Section Title
                              </label>
                              <input
                                type="text"
                                name="title"
                                required
                                value={section.title}
                                onChange={(e) =>
                                  this.props.updateTestSectionDataWithKey({
                                    sectionIndex,
                                    key: "title",
                                    value: e.target.value,
                                  })
                                }
                                className="border border-gray-300 p-2 my-1 outline-gray-300"
                                placeholder="Section Title"
                              />
                            </div>
                            <div className="flex flex-col  md:h-[180px]">
                              <label className="text-[1.1rem] font-['nunito'] font-semibold">
                                Section Description
                              </label>
                              <ReactQuill
                                modules={modules}
                                name="testDescription"
                                value={section.description || ""}
                                onChange={(value) =>
                                  this.props.updateTestSectionDataWithKey({
                                    sectionIndex,
                                    key: "description",
                                    value,
                                  })
                                }
                                className="my-1"
                                placeholder="Test Description"
                              />
                            </div>
                            {section.isOpen && (
                              <AddQuestion
                                sectionIndex={sectionIndex}
                                openQuestionBankModal={(data) =>
                                  this.openQuestionBankModal(data)
                                }
                                updateQuestionOrder={updateQuestionOrder}
                                deleteQuestion={deleteQuestion}
                                duplicateQuestion={duplicateQuestion}
                              />
                            )}
                          </div>
                        ) : null}
                      </div>
                    }
                    handleSectionView={(data) => this.handleSectionView(data)}
                    sectionIndex={sectionIndex}
                    deleteAction={() =>
                      this.props.deleteTestSection({ sectionIndex })
                    }
                    duplicateAction={() =>
                      this.props.duplicateTestSection({ sectionIndex })
                    }
                    viewQuestionAction={() =>
                      this.props.viewQuestionAction({ sectionIndex })
                    }
                  />
                </div>
              ))}

              <div className="flex justify-center items-center gap-5">
                <button
                  onClick={() => this.addSection()}
                  className="flex gap-[0.4rem] items-center justify-center text-[#a0db32] border border-[#a0db32] hover:bg-[#001437] hover:text-[#a0db32] font-semibold uppercase text-base px-4 py-2 mb-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150 mt-5 mb-[5rem]"
                >
                  <Plus className="h-4 w-4 mx-2 [&>*]:!fill-[#a0db32] " />
                  Add Section
                </button>
              </div>
            </div>
          )
        }
      </div >
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    test: test.test,
    allSelectableTests: test.allTests,
    tables: test.tables,
    fetchTablesFailure: test.fetchTablesFailure,
    questionbank_modal: test.questionbank_modal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTestSectionDataWithKey: (data) =>
      dispatch(updateTestSectionDataWithKey(data)),
    fetchAllTests: (data) => dispatch(fetchAllTests(data)),
    updateTestMainDataWithKey: (data) =>
      dispatch(updateTestMainDataWithKey(data)),
    deleteTestSection: (data) => dispatch(deleteTestSection(data)),
    duplicateTestSection: (data) => dispatch(duplicateTestSection(data)),
    addTestSection: () => dispatch(addTestSection()),
    updateTestData: (data) => dispatch(updateTestData(data)),
    fetchUserTablesRequested: () => dispatch(fetchUserTablesRequested()),
    updateQuestionModal: (data) => dispatch(updateQuestionModal(data)),
    updateTestDataOnExchange: (data) =>
      dispatch(updateTestDataOnExchange(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAddSection);
