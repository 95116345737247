import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addGroupDetailsRequested,
  updateGroupDetailsRequested,
} from "../../store/actions/test";
import { toast } from "react-toastify";
import load from "../../assets/img/svg/load.svg";
import { defaultToastSetting } from "../../assets/js/toast_config";
import { uploadFile, viewFile } from "../../services/upload.service";
import {
  addEmailTemplateRequested,
  updateEmailTemplateRequested,
} from "../../store/actions/emailtemplates";
import UniversalButton from "../../components/common/StandardButton/UniversalButton";
import { withRouter } from "../../components/common/WithRouter";

class EmailTemplateModal extends Component {
  constructor() {
    super();
    this.state = {
      template: {
        title: "",
        remarks: "",
        file: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { currentTemplate, type } = this.props;
    console.log(currentTemplate);
    if (type === "E") {
      this.setState(
        (prev) => {
          return {
            ...prev,
            template: {
              title: currentTemplate.title,
              remarks: currentTemplate.remarks,
              file: currentTemplate.file,
            },
          };
        }
        // () => this.getFileUrl(currentTemplate.file)
      );
    }
  }

  getUrl(filename) {
    if (filename && filename.length) {
      viewFile(filename, "EMAIL-TEMPLATES").then((res) => {
        window.open(res);
      });
    }
  }

  handleChange = (e) => {
    const { value, title } = e.target;
    let new_form_data = { ...this.state.template };
    new_form_data[title] = value;
    this.setState({ template: new_form_data });
  };

  validate = () => {
    const { title, file } = this.state.template;
    if (title === "" || file === "") {
      return false;
    }
    return true;
  };

  changeTemplateFile(event) {
    uploadFile(event.target.files[0], {
      type: "EMAIL-TEMPLATES",
      id: "html-template",
    })
      .then((filename) => {
        this.handleChange({ target: { title: "file", value: filename } });
      })
      .catch((e) => console.log("error", e));
  }

  handleSubmit() {
    if (this.validate()) {
      if (this.props.type === "A") {
        this.props.addEmailTemplatesRequested(this.state.template);
      } else {
        this.props.updateEmailTemplatesRequested({
          id: this.props.currentTemplate._id,
          updated_value: { ...this.state.template },
        });
      }
    } else {
      toast.error("All Fields are mandatory", defaultToastSetting);
    }
  }

  render() {
    return (
      <div className=" relative w-full rounded-lg bg-white shadop-2">
        <div className="w-full">
          <div className="rounded pt-0 mb-4">
            <h1 className="text-left font-['nunito'] tracking-[0.18px] font-semibold text-lg">
              {this.props.type === "E"
                ? "Update Email Template"
                : "Create Email Template"}
            </h1>
            <div className="my-3">
              <div className="relative">
                <input
                  className="border-2 border-gray-300 block px-2.5 pb-2 pt-3 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 focus:border-[#009696] peer"
                  id="Name"
                  type="text"
                  title="title"
                  value={this.state.template.title}
                  onChange={(e) => this.handleChange(e)}
                  placeholder=" "
                />
                <label
                  htmlFor="otp"
                  className="font-['poppins'] absolute text-sm text-[#2D333A] duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[#009696] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2"
                >
                  Title
                </label>
              </div>
            </div>
            <div className="mb-2">
              <div className="relative">
                <textarea
                  className="border-2 border-gray-300 block px-2.5 pb-2 pt-3 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 focus:border-[#009696] peer"
                  placeholder=" "
                  id="remarks"
                  title="remarks"
                  value={this.state.template.remarks}
                  onChange={(e) => this.handleChange(e)}
                  rows={5}
                />
                <label
                  htmlFor="summary"
                  className="font-['poppins'] absolute text-sm text-[#2D333A] duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[#009696] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-0 peer-placeholder-shown:top-3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2"
                >
                  Remarks
                </label>
              </div>
            </div>
            <div className="mb-2">
              <div className="relative">
                <label
                  className="font-['poppins'] block text-gray-700 text-sm font-medium mb-2"
                  htmlFor="Email"
                >
                  Upload Template
                </label>
                <input
                  type="file"
                  name="file"
                  accept=".html"
                  // value={this.state.template?.file}
                  onChange={(e) => this.changeTemplateFile(e)}
                />
                <div className="py-2">
                  <button
                    className="border px-2 py-[1px] border-gray-600 bg-gray-200 hover:bg-gray-300"
                    onClick={() => this.getUrl(this.state.template.file)}
                  >
                    View File
                  </button>
                  <span className="text-xs">
                    {this.state.template.file.slice(0, 20)}
                  </span>
                </div>
              </div>
            </div>
            <UniversalButton
              classNames="w-full py-3"
              title={`${this.props.type === "E" ? "Update Group" : "Add Template"
                }`}
              onClickFunction={() => this.handleSubmit()}
              loading={this.props.loading}
            />
            {/* <button
              className="flex justify-center items-center w-full bg-[#009696] text-white font-bold py-2 rounded-[3px]"
              type="button"
              onClick={}
            >
              {this.props.type === "E" ? (
                <div className="flex">
                  {this.props.loading && (
                    <img
                      alt="loading"
                      src={load}
                      className="spinner mr-2 w-6 h-6"
                    />
                  )}
                  <div>Update Group</div>
                </div>
              ) : (
                <div className="flex">
                  {this.props.loading && (
                    <img
                      alt="loading"
                      src={load}
                      className="spinner mr-2 w-6 h-6"
                    />
                  )}
                  <p className="font-['nunito']">Add Template</p>
                </div>
              )}
            </button> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    companies: test.companies,
    templates: test.allTestGroups,
    loading: test.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addEmailTemplatesRequested: (data) =>
      dispatch(addEmailTemplateRequested(data)),
    updateEmailTemplatesRequested: (data) =>
      dispatch(updateEmailTemplateRequested(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmailTemplateModal));
