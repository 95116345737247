import { ASSIGN_TEST_SUCCESS } from "../actionTypes";
import {
  ASKING_QUESTION_TYPE,
  ASK_QUESTION_FAILED,
  ASK_QUESTION_REQUEST,
  ASK_QUESTION_SUCCESS,
  ASSIGN_INTERVIEW_FAILED,
  ASSIGN_INTERVIEW_REQUEST,
  ASSIGN_INTERVIEW_SUCCESS,
  ASSIGN_TEST_FAILED,
  ASSIGN_TEST_REQUEST,
  CREATE_CHAT_FAILED,
  CREATE_CHAT_REQUEST,
  CREATE_CHAT_SUCCESS,
  EXPORT_CSV_FAILED,
  EXPORT_CSV_REQUEST,
  EXPORT_CSV_SUCCESS,
  GET_CHAT_FAILED,
  GET_CHAT_REQUEST,
  GET_CHAT_SUCCESS,
  GET_DEPARTMENT_FAILED,
  GET_DEPARTMENT_REQUEST,
  GET_DEPARTMENT_SUCCESS,
  GET_PROFILE_FAILED,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_RESUME_DETAILS_FAILED,
  GET_RESUME_DETAILS_REQUEST,
  GET_RESUME_DETAILS_SUCCESS,
  PARSE_RESUME_FAILED,
  PARSE_RESUME_REQUEST,
  PARSE_RESUME_SUCCESS,
  REPARSE_RESUME_FAILED,
  REPARSE_RESUME_REQUEST,
  REPARSE_RESUME_SUCCESS,
  SELECTED_MAIL,
  SEND_EMAIL_FAILED,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  UPDATE_CHAT_FAILED,
  UPDATE_CHAT_REQUEST,
  UPDATE_CHAT_SUCCESS,
  UPDATE_STATUS_OF_RESUME,
  UPLOAD_FILE_FAILED,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
} from "../actionTypes/parseResume";

export const uploadFileRequest = (data) => {
  return { type: UPLOAD_FILE_REQUEST, payload: data };
};

export const uploadFileSuccess = (data) => {
  return { type: UPLOAD_FILE_SUCCESS, payload: data };
};

export const uploadFileFailed = (data) => {
  return { type: UPLOAD_FILE_FAILED, payload: data };
};

export const askQuestionRequest = (data) => {
  return { type: ASK_QUESTION_REQUEST, payload: data };
};

export const askQuestionSuccess = (data) => {
  return { type: ASK_QUESTION_SUCCESS, payload: data };
};

export const askQuestionFailed = (data) => {
  return { type: ASK_QUESTION_FAILED, payload: data };
};

// export const getDepartmentRequest = (data) => {
//   return { type: GET_DEPARTMENT_REQUEST, payload: data };
// };

// export const getDepartmentSuccess = (data) => {
//   return { type: GET_DEPARTMENT_SUCCESS, payload: data };
// };

// export const getDepartmentFailed = (data) => {
//   return { type: GET_DEPARTMENT_FAILED, payload: data };
// };

// export const getProfileRequest = (data) => {
//   return { type: GET_PROFILE_REQUEST, payload: data };
// };

// export const getProfileSuccess = (data) => {
//   return { type: GET_PROFILE_SUCCESS, payload: data };
// };

// export const getProfileFailed = (data) => {
//   return { type: GET_PROFILE_FAILED, payload: data };
// };

export const parseResumeRequest = (data) => {
  return { type: PARSE_RESUME_REQUEST, payload: data };
};

export const parseResumeSuccess = (data) => {
  return { type: PARSE_RESUME_SUCCESS, payload: data };
};

export const parseResumeFailed = (data) => {
  return { type: PARSE_RESUME_FAILED, payload: data };
};

export const reparseResumeRequest = (data) => {
  return { type: REPARSE_RESUME_REQUEST, payload: data };
};

export const reparseResumeSuccess = (data) => {
  return { type: REPARSE_RESUME_SUCCESS, payload: data };
};

export const reparseResumeFailed = (data) => {
  return { type: REPARSE_RESUME_FAILED, payload: data };
};

export const getResumeDetailsRequest = (data) => {
  return { type: GET_RESUME_DETAILS_REQUEST, payload: data };
};

export const getResumeDetailsSuccess = (data) => {
  return { type: GET_RESUME_DETAILS_SUCCESS, payload: data };
};

export const getResumeDetailsFailed = (data) => {
  return { type: GET_RESUME_DETAILS_FAILED, payload: data };
};

export const sendEmailRequest = (data) => {
  return { type: SEND_EMAIL_REQUEST, payload: data };
};

export const sendEmailSuccess = (data) => {
  return { type: SEND_EMAIL_SUCCESS, payload: data };
};

export const sendEmailFailed = (data) => {
  return { type: SEND_EMAIL_FAILED, payload: data };
};

export const exportCSVRequest = (data) => {
  return { type: EXPORT_CSV_REQUEST, payload: data };
};

export const exportCSVSuccess = (data) => {
  return { type: EXPORT_CSV_SUCCESS, payload: data };
};

export const exportCSVFailed = (data) => {
  return { type: EXPORT_CSV_FAILED, payload: data };
};

export const assignTestRequest = (data) => {
  return { type: ASSIGN_TEST_REQUEST, payload: data };
};

export const assignTestSuccess = (data) => {
  return { type: ASSIGN_TEST_SUCCESS, payload: data };
};

export const assignTestFailed = (data) => {
  return { type: ASSIGN_TEST_FAILED, payload: data };
};

export const assignInterviewRequest = (data) => {
  return { type: ASSIGN_INTERVIEW_REQUEST, payload: data };
};

export const assignInterviewSuccess = (data) => {
  return { type: ASSIGN_INTERVIEW_SUCCESS, payload: data };
};

export const assignInterviewFailed = (data) => {
  return { type: ASSIGN_INTERVIEW_FAILED, payload: data };
};

export const updateStatusOfResume = (data) => {
  return { type: UPDATE_STATUS_OF_RESUME, payload: data };
};

export const getChatRequest = (data) => {
  return { type: GET_CHAT_REQUEST, payload: data };
};

export const getChatSuccess = (data) => {
  return { type: GET_CHAT_SUCCESS, payload: data };
};

export const getChatFailed = (data) => {
  return { type: GET_CHAT_FAILED, payload: data };
};

export const createChatRequest = (data) => {
  return { type: CREATE_CHAT_REQUEST, payload: data };
};

export const createChatSuccess = (data) => {
  return { type: CREATE_CHAT_SUCCESS, payload: data };
};

export const createChatFailed = (data) => {
  return { type: CREATE_CHAT_FAILED, payload: data };
};

export const updateChatRequest = (data) => {
  return { type: UPDATE_CHAT_REQUEST, payload: data };
};

export const updateChatSuccess = (data) => {
  return { type: UPDATE_CHAT_SUCCESS, payload: data };
};

export const updateChatFailed = (data) => {
  return { type: UPDATE_CHAT_FAILED, payload: data };
};

export const selectedMail = (data) => {
  return { type: SELECTED_MAIL, payload: data };
};

export const askingQuestionType = (data) => {
  return { type: ASKING_QUESTION_TYPE, payload: data };
};
