import axios from "axios";
import { BASE_URL } from "../assets/js/config";
import authHeader from "./auth-header";

export const assignEvaluatorService = (data) => {
  return axios
    .put(BASE_URL + `api/evaluatorassign/`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

export const fetchAllAssignedReports = ({ evaluated }) => {
  return axios
    .get(BASE_URL + `api/evaluatorassign?evaluated=${evaluated}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

export const fetchAllEvaluatorsStatus = ({ id }) => {
  return axios
    .get(BASE_URL + `api/evaluatorassign/allEvaluatorStatus/${id}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

export const fetchReportService = (id) => {
  return axios
    .get(BASE_URL + `api/evaluatorassign/${id}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

export const updateScoreService = ({ id, marking }) => {
  return axios
    .put(
      BASE_URL +
        `api/quizassign/${id}/updatemarks
        `,
      { marking },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

export const submitEvaluation = ({ id }) => {
  return axios
    .put(
      BASE_URL +
        `api/evaluatorassign/update/${id}
        `,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

export const sendReminderEvaluatorApi = (data) => {
  return axios
    .post(BASE_URL + `api/evaluatorassign/reminder`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};
