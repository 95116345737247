import {
  FETCH_TEST_ANALYTICS_SCORE,
  FETCH_TEST_ANALYTICS_DURATION,
  FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION,
  FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION,
  FETCH_TEST_ANALYTICS_SCORE_FAILURE,
  FETCH_TEST_ANALYTICS_SCORE_SUCCESS,
  FETCH_TEST_ANALYTICS_DURATION_FAILURE,
  FETCH_TEST_ANALYTICS_DURATION_SUCCESS,
  FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION_FAILURE,
  FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION_SUCCESS,
  FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION_FAILURE,
  FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION_SUCCESS,
} from "../actionTypes";

const initialState = {
  analytics: {
    duration_mean: 0,
    duration_median: 0,
    score_median: 0,
    score_mean: 0,
    score_max: 0,
    score_min: 0,
    score_chart: [],
    duration_chart: [],
  },
};

export default function AnalyticsReducer(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case FETCH_TEST_ANALYTICS_SCORE:
      return {
        ...state,
      };

    case FETCH_TEST_ANALYTICS_SCORE_FAILURE:
      return {
        ...state,
      };

    case FETCH_TEST_ANALYTICS_SCORE_SUCCESS:
      return {
        ...state,
        score_mean: action.payload.data.score_mean,
        score_median: action.payload.data.score_median,
      };

    case FETCH_TEST_ANALYTICS_DURATION:
      return {
        ...state,
      };

    case FETCH_TEST_ANALYTICS_DURATION_FAILURE:
      return {
        ...state,
      };

    case FETCH_TEST_ANALYTICS_DURATION_SUCCESS:
      return {
        ...state,
        duration_mean: action.payload.data.duration_mean,
        duration_median: action.payload.data.duration_median,
      };

    case FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION:
      return {
        ...state,
      };

    case FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION_FAILURE:
      return {
        ...state,
      };

    case FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        score_chart: action.payload.data,
      };

    case FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION:
      return {
        ...state,
      };

    case FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION_FAILURE:
      return {
        ...state,
      };

    case FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        duration_chart: action.payload.data,
      };

    default:
      return state;
  }
}
