/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import authHeader from "./auth-header";
import { BASE_URL } from "../assets/js/config";
import { mimetypes } from "../components/tests/utils";

export const uploadFile = async function (file, data) {
  if (!file) return null;
  const ext = `.${mimetypes[file.type]}`;
  const res = await axios.post(
    `${BASE_URL}api/upload/put-signed-url`,
    { ...data, ext },
    {
      headers: { ...authHeader() },
    }
  );

  const img_upload_res = await axios.put(res.data.url, file, {
    headers: {
      "Content-Type": file.type,
    },
  });

  if (img_upload_res.status === 200) {
    return res.data.file_name;
  }
  return null;
};

export const UploadFileSingle = async function (url, file) {
  console.log("checking reduc", url, file)
  await axios.put(url, file, {
    headers: {
      "Content-Type": file.type,
    },
  })
    .then(res => {
      if (res.status === 200) {
        return res.data.file_name;
      }
    }).catch((err) => {
      throw Error(err.response.data);
    });


}

export const viewFile = async function (file_name, type) {
  // Sending a request to generate a signed url
  const res = await axios.post(
    `${BASE_URL}api/upload/get-signed-url`,
    { file_name, type },
    {
      headers: { ...authHeader() },
    }
  );

  return res.data.url;
};

export const uploadFormFile = async function (file) {
  let data = new FormData();
  data.append("file", file);

  return await axios.post(BASE_URL + "api/upload", data, {
    headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
  });
};
