import { initialTestQuestionData } from "../../components/tests/utils";
import {
  ADD_EMAIL_TEMPLATE_SUCCESS,
  FETCH_ALL_EMAIL_TEMPLATES_FAILURE,
  FETCH_ALL_EMAIL_TEMPLATES_REQUESTED,
  FETCH_ALL_EMAIL_TEMPLATES_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_MODAL,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_WITH_KEY,
} from "../actionTypes";

const initialState = {
  emailtemplateModal: false,
  emailSendModal: false,
  emailtemplates: null,
  loader: false,
};

const emailtemplates = function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_EMAIL_TEMPLATE_MODAL:
      return { ...state, [payload.key]: payload.value };

    case FETCH_ALL_EMAIL_TEMPLATES_SUCCESS:
      return { ...state, emailtemplates: [...payload], loader: false };

    case FETCH_ALL_EMAIL_TEMPLATES_FAILURE:
      return { ...state, loader: false };

    case FETCH_ALL_EMAIL_TEMPLATES_REQUESTED:
      return { ...state, loader: true };

    case ADD_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        emailtemplateModal: false,
        emailtemplates: [...state.emailtemplates, payload],
      };

    case UPDATE_EMAIL_TEMPLATE_SUCCESS:
      const { emailtemplates } = state;
      const updatedQuestionId = emailtemplates.findIndex(
        (emailtemplate) => emailtemplate._id === payload._id
      );
      emailtemplates[updatedQuestionId] = payload;
      return {
        ...state,
        emailtemplateModal: false,
        emailtemplates: [...emailtemplates],
      };

    default:
      return state;
  }
};

export default emailtemplates;
