import React, { Component } from "react";

// Store
import { connect } from "react-redux";
import load from "../../../assets/img/svg/load.svg";
import {
  updateTestDeleteModalActive,
  deleteTestRequest,
  DeleteResponseRequested,
  deleteGroupDetailsRequested,
} from "../../../store/actions/test";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../../assets/js/toast_config";
import { deleteQuestionRequested } from "../../../store/actions/questionbank";
import { deleteEmailTemplateRequested } from "../../../store/actions/emailtemplates";
import { deleteInterviewRequested } from "../../../store/actions/interviewpanel";
import { withRouter } from "../../common/WithRouter";

class DeleteTest extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.loadingDelete && !this.props.loadingDelete) {
      window.location.reload();
    }
    if (prevProps.error && !this.props.error) {
      toast.error(this.props.error, defaultToastSetting);
    }
  }

  deleteTest(testId) {
    if (this.props.title === "test") {
      this.props.deleteTestRequest(testId);
    } else if (this.props.title === "group") {
      this.props.deleteGroupDetailsRequested(testId);
    } else if (this.props.title === "question") {
      this.props.deleteQuestionRequested({ id: testId });
    } else if (this.props.title === "email-template") {
      this.props.deleteEmailTemplateRequested({ id: testId });
    } else if (this.props.title === "I") {
      this.props.deleteInterviewRequested({ id: testId });
    } else {
      this.props.deleteResponseRequested(testId);
    }
  }

  render() {
    const testId = this.props.testId;
    return (
      <>
        {this.props.deleteTestModalActive ? (
          <div
            className={`flex justify-center items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[100000000] w-full md:inset-0 h-full md:h-full`}
            id="popup-modal"
            style={{ backgroundColor: "rgb(0 ,0 ,0,0.1)" }}
          >
            <div className="flex justify-center items-center p-4 w-full max-w-2xl h-full md:h-auto">
              <div className="flex justify-center items-center relative px-4 w-full max-w-md h-full md:h-auto">
                <div className=" relative rounded-lg shadow bg-gray-700">
                  <div className="flex justify-end p-2">
                    <button
                      type="button"
                      onClick={() =>
                        this.props.updateTestDeleteModalActive(false)
                      }
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                      data-modal-toggle="popup-modal"
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div className="p-6 pt-0 text-center">
                    <svg
                      className="mx-auto mb-4 w-14 h-14 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <h3 className="mb-5 text-lg font-normal text-white ">
                      Are you sure you want to delete this {this.props.title}?
                    </h3>
                    <button
                      onClick={() => this.deleteTest(testId)}
                      data-modal-toggle="popup-modal"
                      type="button"
                      className="text-white bg-red-600 hover:bg-red-600 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                    >
                      {this.props.loadingDelete || this.props.deleteTestLoading && (
                        <img
                          alt="loading"
                          src={load}
                          className="spinner mr-2 w-6 h-6"
                        />
                      )}
                      Yes, I'm sure
                    </button>
                    <button
                      onClick={() =>
                        this.props.updateTestDeleteModalActive(false)
                      }
                      data-modal-toggle="popup-modal"
                      type="button"
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                    >
                      No, cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    deleteTestModalActive: test.deleteTestModalActive,
    loadingDelete: test.loadingDelete,
    deleteTestLoading: test.deleteTestLoading,
    error: test.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTestDeleteModalActive: (data) =>
      dispatch(updateTestDeleteModalActive(data)),
    deleteTestRequest: (data) => dispatch(deleteTestRequest(data)),
    deleteResponseRequested: (data) => dispatch(DeleteResponseRequested(data)),
    deleteGroupDetailsRequested: (data) =>
      dispatch(deleteGroupDetailsRequested(data)),
    deleteQuestionRequested: (data) => dispatch(deleteQuestionRequested(data)),
    deleteEmailTemplateRequested: (data) =>
      dispatch(deleteEmailTemplateRequested(data)),
    deleteInterviewRequested: (data) =>
      dispatch(deleteInterviewRequested(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DeleteTest));
