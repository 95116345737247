import React from "react";
import UniversalButton from "../common/StandardButton/UniversalButton";
import ReactHowler from "react-howler/lib/ReactHowler";
import raf from "raf";
import Play from "../../assets/img/play.png";
import Volume from "../../assets/img/volume.png";
import Pause from "../../assets/img/pause.png";
import styles from "./CustomAudio.module.css";

class CustomAudio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false,
      loaded: false,
      loop: false,
      mute: false,
      volume: 1.0,
      seek: 0.0,
      rate: 1,
      isSeeking: false,
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleOnLoad = this.handleOnLoad.bind(this);
    this.handleOnEnd = this.handleOnEnd.bind(this);
    this.handleOnPlay = this.handleOnPlay.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.renderSeekPos = this.renderSeekPos.bind(this);
    this.handleLoopToggle = this.handleLoopToggle.bind(this);
    this.handleMuteToggle = this.handleMuteToggle.bind(this);
    this.handleMouseDownSeek = this.handleMouseDownSeek.bind(this);
    this.handleMouseUpSeek = this.handleMouseUpSeek.bind(this);
    this.handleSeekingChange = this.handleSeekingChange.bind(this);
    this.handleRate = this.handleRate.bind(this);
  }

  componentWillUnmount() {
    this.clearRAF();
  }

  handleToggle(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      playing: !this.state.playing,
    });
  }

  handleOnLoad() {
    this.setState({
      loaded: true,
      duration: this.player.duration(),
    });
  }

  handleOnPlay() {
    this.setState({
      playing: true,
    });
    this.renderSeekPos();
  }

  handleOnEnd() {
    this.setState({
      playing: false,
    });
    this.clearRAF();
  }

  handleStop(e) {
    e.stopPropagation();
    e.preventDefault();
    this.player.stop();
    this.setState({
      playing: false,
    });
    this.renderSeekPos();
  }

  handleLoopToggle() {
    this.setState({
      loop: !this.state.loop,
    });
  }

  handleMuteToggle() {
    this.setState({
      mute: !this.state.mute,
    });
  }

  handleMouseDownSeek() {
    this.setState({
      isSeeking: true,
    });
  }

  handleMouseUpSeek(e) {
    this.setState({
      isSeeking: false,
    });

    this.player.seek(e.target.value);
  }

  handleSeekingChange(e) {
    this.setState({
      seek: parseFloat(e.target.value),
    });
  }

  renderSeekPos() {
    if (!this.state.isSeeking) {
      this.setState({
        seek: this.player.seek(),
      });
    }
    if (this.state.playing) {
      this._raf = raf(this.renderSeekPos);
    }
  }

  handleRate(e) {
    const rate = parseFloat(e.target.value);
    this.player.rate(rate);
    this.setState({ rate });
  }

  clearRAF() {
    raf.cancel(this._raf);
  }

  render() {
    return (
      <div className="flex w-[26rem] justify-between flex-col items-center gap-[0.4rem] border-[3px] border-[#BCFB46] px-5 py-1 rounded-[2rem]">
        {/* <div className="w-full flex justify-between items-center">
          <label className="flex justify-between items-center gap-[0.6rem]">
            Loop:
            <input
              type="checkbox"
              className="cursor-pointer"
              checked={this.state.loop}
              onChange={this.handleLoopToggle}
            />
          </label>
          <label className="flex justify-between items-center gap-[0.6rem]">
            Mute:
            <input
              type="checkbox"
              className="cursor-pointer"
              checked={this.state.mute}
              onChange={this.handleMuteToggle}
            />
          </label>
        </div>

        <div className="flex flex-col items-start ">
          <p>
            {"Status:         "}
            {this.state.seek.toFixed(2)}
            {" / "}
            {this.state.duration ? this.state.duration.toFixed(2) : "NaN"}
          </p>
          <label className="flex justify-between items-center gap-[0.6rem]">
            Volume:
            <input
              type="range"
              min="0"
              max="1"
              step=".05"
              className="cursor-pointer"
              value={this.state.volume}
              onChange={(e) =>
                this.setState({ volume: parseFloat(e.target.value) })
              }
            />
            {this.state.volume.toFixed(2)}
          </label>

          <label className="flex justify-between items-center gap-[0.6rem]">
            Seek:
            <input
              type="range"
              min="0"
              max={this.state.duration ? this.state.duration.toFixed(2) : 0}
              className="cursor-pointer"
              step=".01"
              value={this.state.seek}
              onChange={this.handleSeekingChange}
              onMouseDown={this.handleMouseDownSeek}
              onMouseUp={this.handleMouseUpSeek}
            />
          </label>

          <label className="flex justify-between items-center gap-[0.6rem]">
            Rate:
            <input
              type="range"
              min="0.1"
              max="3"
              step=".01"
              className="cursor-pointer"
              value={this.state.rate}
              onChange={this.handleRate}
            />
            {this.state.rate.toFixed(2)}
          </label>
        </div> */}

        <div className="flex justify-center items-center gap-[0.6rem]">
          <ReactHowler
            src="https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/theme_01.mp3"
            playing={this.state.playing}
            onLoad={this.handleOnLoad}
            onPlay={this.handleOnPlay}
            onEnd={this.handleOnEnd}
            loop={this.state.loop}
            mute={this.state.mute}
            volume={this.state.volume}
            ref={(ref) => (this.player = ref)}
          />
          <div className="w-[7rem] h-[2.3rem] flex items-center justify-center rounded-[50%] bg-[#BCFB46]">
            <img
              src={this.state.playing ? Pause : Play}
              onClick={this.handleToggle}
              className="cursor-pointer w-[1rem] "
            />
          </div>
          <input
            type="range"
            min="0"
            max={this.state.duration ? this.state.duration.toFixed(2) : 0}
            className={`${styles.rangeInput} cursor-pointer`}
            step=".01"
            value={this.state.seek}
            onChange={this.handleSeekingChange}
            onMouseDown={this.handleMouseDownSeek}
            onMouseUp={this.handleMouseUpSeek}
          />

          <p className="text-[0.9rem] w-[4rem] font-['poppins']">
            {this.state.seek.toFixed(1)}/
            {this.state.duration ? this.state.duration.toFixed(1) : "NaN"}
          </p>

          <img src={Volume} className="w-[1.4rem] " />
          <input
            type="range"
            min="0"
            max="1"
            step=".05"
            className={`${styles.rangeInput} cursor-pointer !w-[10rem]`}
            value={this.state.volume}
            onChange={(e) =>
              this.setState({ volume: parseFloat(e.target.value) })
            }
          />
        </div>

        {/* <div className="flex justify-between mt-3 w-full gap-[0.6rem] items-center">
          <UniversalButton
            classNames="w-[5.5rem]"
            onClickFunction={this.handleToggle}
            title={this.state.playing ? "Pause" : "Play"}
          />
          <UniversalButton
            classNames="w-[5.5rem]"
            onClickFunction={this.handleStop}
            title="Stop"
          />
        </div> */}
      </div>
    );
  }
  0;
}

// const CustomAudio = ({ audioSrc }) => {
//   const player = useRef();
//   const [audioSetting, setAudioSetting] = useState({
//     playing: false,
//     loaded: false,
//     loop: false,
//     mute: false,
//     volume: 1.0,
//     seek: 0.0,
//     rate: 1,
//     isSeeking: false,
//     duration: NaN,
//   });

//   const handleToggle = () => {
//     setAudioSetting((prev) => ({
//       ...prev,
//       playing: !audioSetting.playing,
//     }));
//   };

//   const handleOnLoad = () => {
//     setAudioSetting((prev) => ({
//       ...prev,
//       loaded: true,
//       duration: player.current.duration(),
//     }));
//   };

//   const handleOnPlay = () => {
//     setAudioSetting((prev) => ({
//       ...prev,
//       playing: true,
//     }));
//     renderSeekPos();
//   };

//   const handleOnEnd = () => {
//     setAudioSetting((prev) => ({
//       ...prev,
//       playing: false,
//     }));
//     clearRAF();
//   };

//   const handleStop = () => {
//     player.current.stop();
//     setAudioSetting((prev) => ({
//       ...prev,
//       playing: false,
//     }));
//     renderSeekPos();
//   };

//   const handleLoopToggle = () => {
//     setAudioSetting((prev) => ({
//       ...prev,
//       loop: !audioSetting.loop,
//     }));
//   };

//   const handleMuteToggle = () => {
//     setAudioSetting((prev) => ({
//       ...prev,
//       mute: !audioSetting.mute,
//     }));
//   };

//   const handleMouseDownSeek = () => {
//     setAudioSetting((prev) => ({
//       ...prev,
//       isSeeking: true,
//     }));
//   };

//   const handleMouseUpSeek = (e) => {
//     setAudioSetting((prev) => ({
//       ...prev,
//       isSeeking: false,
//     }));

//     player.current.seek(e.target.value);
//   };

//   const handleSeekingChange = (e) => {
//     setAudioSetting((prev) => ({
//       ...prev,
//       seek: parseFloat(e.target.value),
//     }));
//   };

//   const renderSeekPos = () => {
//     if (!audioSetting.isSeeking) {
//       setAudioSetting((prev) => ({
//         ...prev,
//         seek: player.current.seek(),
//       }));
//     }
//     if (audioSetting.playing) {
//       audioSetting._raf = raf(renderSeekPos);
//     }
//   };

//   const handleRate = (e) => {
//     const rate = parseFloat(e.target.value);
//     player.current.rate(rate);
//     setAudioSetting({
//       ...audioSetting,
//       rate: rate,
//     });
//   };

//   const clearRAF = () => {
//     raf.cancel(audioSetting._raf);
//   };

//   useEffect(() => {
//     return () => {
//       clearRAF();
//     };
//   }, []);

//   return (
//     <div className="full-control">
//       <ReactHowler
//         src={audioSrc}
//         playing={audioSetting.playing}
//         onLoad={handleOnLoad}
//         onPlay={handleOnPlay}
//         onEnd={handleOnEnd}
//         loop={audioSetting.loop}
//         mute={audioSetting.mute}
//         volume={audioSetting.volume}
//         ref={player}
//       />
//       <p>{audioSetting.loaded ? "Loaded" : "Loading"}</p>
//       <div className="toggles">
//         <label>
//           Loop:
//           <input
//             type="checkbox"
//             checked={audioSetting.loop}
//             onChange={handleLoopToggle}
//           />
//         </label>
//         <label>
//           Mute:
//           <input
//             type="checkbox"
//             checked={audioSetting.mute}
//             onChange={handleMuteToggle}
//           />
//         </label>
//       </div>
//       <p>
//         {"Status: "}
//         {audioSetting.seek.toFixed(2)}
//         {" / "}
//         {isNaN(audioSetting.duration)
//           ? "NaN"
//           : audioSetting.duration.toFixed(2)}
//       </p>
//       <div className="volume">
//         <label>
//           Volume:
//           <span className="slider-container">
//             <input
//               type="range"
//               min="0"
//               max="1"
//               step=".05"
//               value={audioSetting.volume}
//               onChange={(e) => {
//                 setAudioSetting((prev) => ({
//                   ...prev,
//                   volume: parseFloat(e.target.value),
//                 }));
//               }}
//             />
//           </span>
//           {audioSetting.volume.toFixed(2)}
//         </label>
//       </div>
//       <div className="seek">
//         <label>
//           Seek:
//           <span className="slider-container">
//             <input
//               type="range"
//               min="0"
//               max={
//                 isNaN(audioSetting.duration)
//                   ? 0
//                   : audioSetting.duration.toFixed(2)
//               }
//               step=".01"
//               value={audioSetting.seek}
//               onChange={handleSeekingChange}
//               onMouseDown={handleMouseDownSeek}
//               onMouseUp={handleMouseUpSeek}
//             />
//           </span>
//         </label>
//       </div>
//       <div className="rate">
//         <label>
//           Rate:
//           <span className="slider-container">
//             <input
//               type="range"
//               min="0.1"
//               max="3"
//               step=".01"
//               value={audioSetting.rate}
//               onChange={handleRate}
//             />
//           </span>
//           {audioSetting.rate.toFixed(2)}
//         </label>
//       </div>
//       <button onClick={handleToggle}>
//         {audioSetting.playing ? "Pause" : "Play"}
//       </button>
//       <button onClick={handleStop}>Stop</button>
//     </div>
//   );
// };

export default CustomAudio;
