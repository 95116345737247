import axios from "axios";
import { BASE_URL } from "../assets/js/config";
import authHeader from "./auth-header";

export const sendInterviewInvite = (data) => {
  return axios
    .put(BASE_URL + `api/interview-assign`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const fetchAllInterviews = (filter) => {
  let query_arr = [];
  for (let filter_key in filter) {
    const value = filter[filter_key];
    if (value !== null && value !== undefined) {
      query_arr.push(`${filter_key}=${value}`);
    }
  }
  const query_str = query_arr.join("&");
  return axios
    .get(BASE_URL + `api/interview-assign?` + query_str, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const fetchInterviewDetails = (id) => {
  return axios
    .get(BASE_URL + `api/interview-assign/${id}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const getInterviewerPeer = (id) => {
  return axios
    .get(
      BASE_URL + `api/interview-assign/interviewersettings?interviewId=${id}`,
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const updateInterviewQuestion = ({ data, id }) => {
  return axios
    .put(BASE_URL + `api/interview-assign/question/${id}`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const updateCurrentInterviewQuestion = ({ data, id }) => {
  return axios
    .put(BASE_URL + `api/interview-assign/score/${id}`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const deleteInterviewService = ({ id }) => {
  return axios
    .delete(`${BASE_URL}api/interview-assign/delete/${id}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

export const submitInterviewResponseService = ({ response, id, isSubmit }) => {
  return axios
    .put(
      BASE_URL + `api/interview-assign/updateresponse/${id}`,
      { response, isSubmit },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const updateSectionLock = ({ id, data }) => {
  return axios
    .put(
      BASE_URL + `api/interview-assign/edit-question-details/${id}`,
      { ...data },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const fetchInterviewSubmissionsCSV = (data) => {
  return axios
    .post(
      `${BASE_URL}api/interviewassign/downloadcsv`,
      {
        ...data,
      },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

// export const getInterviewResponseService = (id) => {
//   return axios
//     .get(BASE_URL + `api/interview-assign/${id}/getresponse`, {
//       headers: authHeader(),
//     })
//     .then((res) => {
//       return res.data;
//     })
//     .catch((err) => {
//
// throw Error(err.response.data);
//     });
// };
