import {
  FETCH_PROCTORED_USER_DETAILS_REQUESTED,
  FETCH_PROCTORED_USER_DETAILS_FAILURE,
  FETCH_PROCTORED_USER_DETAILS_SUCCESS,
} from "../actionTypes/userdetails";

export const fetchProctoredUserDetailsRequested = (data) => {
  return {
    type: FETCH_PROCTORED_USER_DETAILS_REQUESTED,
    payload: data,
  };
};

export const fetchProctoredUserDetailsSuccess = (data) => {
  return {
    type: FETCH_PROCTORED_USER_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchProctoredUserDetailsFailure = () => {
  return {
    type: FETCH_PROCTORED_USER_DETAILS_FAILURE,
  };
};
