import {
  UPDATE_INTERVIEW_CODE_EDITOR_CODE,
  UPDATE_INTERVIEW_CODE_EDITOR_LANGUAGE,
  FETCH_ALL_INTERVIEWS_REQUESTED,
  FETCH_ALL_INTERVIEWS_SUCCESS,
  UPDATE_CURRENT_SECTIONINDEX,
  UPDATE_CURRENT_QUESTIONINDEX,
  FETCH_INTERVIEW_DETAILS_REQUESTED,
  FETCH_INTERVIEW_DETAILS_SUCCESS,
  SET_CURRENT_QUESTION,
  GET_CURRENT_SOCKET_PEER_REQUESTED,
  GET_CURRENT_SOCKET_PEER_SUCCESS,
  SET_INTERVIEW_PEER,
  UPDATE_INTERVIEW_QUESTION_REQUESTED,
  UPDATE_INTERVIEW_QUESTION_SUCCESS,
  SET_CURRENT_SECTION,
  SET_CURRENT_QUESTION_SCORE_REQUESTED,
  SET_CURRENT_QUESTION_SCORE_SUCCESS,
  SET_CURRENT_QUESTION_SCORE,
  DELETE_INTERVIEW_REQUESTED,
  DELETE_INTERVIEW_SUCCESS,
  UPDATE_INTERVIEW_RESPONSE_REQUESTED,
  UPDATE_INTERVIEW_RESPONSE_SUCCESS,
  SUBMIT_INTERVIEW_RESPONSE_REQUESTED,
  SUBMIT_INTERVIEW_RESPONSE_SUCCESS,
  UPDATE_INTERVIEW_PAGE,
  // GET_INTERVIEW_RESPONSE_REQUESTED,
  // GET_INTERVIEW_RESPONSE_SUCCESS,
  SET_QUESTION_VIEW_STATUS,
  UPDATE_CUSTOM_QUESTION_MODAL,
  SUBMIT_OVERALL_FEEDBACK_REQUESTED,
  SUBMIT_OVERALL_FEEDBACK_SUCCESS,
  SUBMIT_OVERALL_FEEDBACK_FAILURE,
  UPDATE_OVERALL_FEEDBACK,
} from "../actionTypes/interviewpanel";

export const updateInterviewCodeEditorLanguage = (data) => {
  return {
    type: UPDATE_INTERVIEW_CODE_EDITOR_LANGUAGE,
    payload: data,
  };
};

export const updateInterviewCodeEditorCode = (data) => {
  return {
    type: UPDATE_INTERVIEW_CODE_EDITOR_CODE,
    payload: data,
  };
};

export const fetchAllInterviewsRequested = (data) => {
  return {
    type: FETCH_ALL_INTERVIEWS_REQUESTED,
    payload: data,
  };
};

export const fetchAllInterviewsSuccess = (data) => {
  return {
    type: FETCH_ALL_INTERVIEWS_SUCCESS,
    payload: data,
  };
};

export const updateCurrentSectionIndex = (data) => {
  return {
    type: UPDATE_CURRENT_SECTIONINDEX,
    payload: data,
  };
};

export const updateCurrentQuestionIndex = (data) => {
  return {
    type: UPDATE_CURRENT_QUESTIONINDEX,
    payload: data,
  };
};

export const fetchInterviewDetailsRequested = (data) => {
  return {
    type: FETCH_INTERVIEW_DETAILS_REQUESTED,
    payload: data,
  };
};

export const fetchInterviewDetailsSuccess = (data) => {
  return {
    type: FETCH_INTERVIEW_DETAILS_SUCCESS,
    payload: data,
  };
};

export const setCurrentSection = () => {
  return {
    type: SET_CURRENT_SECTION,
  };
};

// export const getCurrentSocketPeerRequested = (data) => {
//   return {
//     type: GET_CURRENT_SOCKET_PEER_REQUESTED,
//     payload: data,
//   };
// };

export const getCurrentSocketPeerSuccess = (data) => {
  return {
    type: GET_CURRENT_SOCKET_PEER_SUCCESS,
    payload: data,
  };
};

export const updateInterviewQuestionRequested = (data) => {
  return {
    type: UPDATE_INTERVIEW_QUESTION_REQUESTED,
    payload: data,
  };
};

export const updateInterviewQuestionSuccess = (data) => {
  return {
    type: UPDATE_INTERVIEW_QUESTION_SUCCESS,
    payload: data,
  };
};

export const updateCurrentQuestionScoreSuccess = (data) => {
  return {
    type: SET_CURRENT_QUESTION_SCORE_SUCCESS,
    payload: data,
  };
};

export const updateCurrentQuestionScoreRequested = (data) => {
  return {
    type: SET_CURRENT_QUESTION_SCORE_REQUESTED,
    payload: data,
  };
};

export const updateCurrentScore = (data) => {
  return {
    type: SET_CURRENT_QUESTION_SCORE,
    payload: data,
  };
};

export const deleteInterviewRequested = (data) => {
  return {
    type: DELETE_INTERVIEW_REQUESTED,
    payload: data,
  };
};

export const deleteInterviewSuccess = (data) => {
  return {
    type: DELETE_INTERVIEW_SUCCESS,
    payload: data,
  };
};

export const updateInterviewResponseRequested = (data) => {
  return {
    type: UPDATE_INTERVIEW_RESPONSE_REQUESTED,
    payload: data,
  };
};

export const updateInterviewResponseSuccess = (data) => {
  return {
    type: UPDATE_INTERVIEW_RESPONSE_SUCCESS,
    payload: data,
  };
};

export const submitInterviewRequested = (data) => {
  return {
    type: SUBMIT_INTERVIEW_RESPONSE_REQUESTED,
    payload: data,
  };
};

export const submitInterviewSuccess = (data) => {
  return {
    type: SUBMIT_INTERVIEW_RESPONSE_SUCCESS,
    payload: data,
  };
};

export const updateInterviewPage = (data) => {
  return {
    type: UPDATE_INTERVIEW_PAGE,
    payload: data,
  };
};

export const updateCustomQuestionModal = (data) => {
  return {
    type: UPDATE_CUSTOM_QUESTION_MODAL,
    payload: data,
  };
};

export const submitOverallFeedbackRequested = (data) => {
  return {
    type: SUBMIT_OVERALL_FEEDBACK_REQUESTED,
    payload: data,
  }
}

export const submitOverallFeedbackSuccess = (data) => {
  return {
    type: SUBMIT_OVERALL_FEEDBACK_SUCCESS,
    payload: data,
  }
}

export const submitOverallFeedbackFailure = () => {
  return {
    type: SUBMIT_OVERALL_FEEDBACK_FAILURE,
  }
}

export const updateOverallFeedback = (data) => {
  return {
    type: UPDATE_OVERALL_FEEDBACK,
    payload: data
  }
}

// export const getInterviewResponseRequested = (data) => {
//   return {
//     type: GET_INTERVIEW_RESPONSE_REQUESTED,
//     payload: data,
//   };
// };

// export const getInterviewResponseSuccess = (data) => {
//   return {
//     type: GET_INTERVIEW_RESPONSE_SUCCESS,
//     payload: data,
//   };
// };
