import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import { ReactComponent as Proctor } from "../../assets/img/svg/proctor.svg";
import { ReactComponent as InterviewCall } from "../../assets/img/svg/interview_call.svg";
import deletec from "../../assets/img/svg/deletecolor.svg";
import { ReactComponent as EditIcon } from "../../assets/img/svg/edit.svg";
// import deletec from "../../assets/img/svg/deletecolor.svg";
import DeleteTest from "../tests/createTest/DeleteTest";
import { updateTestDeleteModalActive } from "../../store/actions/test";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useState } from "react";
import Modal from "./Modal/Modal";
import InterviewModal from "../modals/InterviewModal";
import {
  selectedMail,
  updateChatRequest,
  updateStatusOfResume,
} from "../../store/actions/parseResume";
import { withRouter } from "./WithRouter";
import Tooltip from "../common/Tooltip/Tooltip";
import { ConvertTimeToCurrentTimezone } from "./utils";

class DashboardTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responseId: "",
      interviewModal: false,
      interviewEmail: "",

      columns: [
        {
          id: "checkbox",
          accessor: "_id",
          Cell: ({ cell: { row } }) => {
            return (
              <input
                type="checkbox"
                className="checkbox"
                checked={this.props.selectedStudentMail?.includes(
                  row.original._id
                )}
                onChange={(e) => this.toggleRow(row.original, e)}
              />
            );
          },
          Header: (x) => {
            return (
              <input
                type="checkbox"
                className="checkbox"
                checked={this.props.selectAll === 1}
                ref={(input) => {
                  if (input) {
                    input.indeterminate = this.props.selectAll === 2;
                  }
                }}
                onChange={() => this.toggleSelectAll()}
              />
            );
          },
          disableFilters: true,
          disableSortBy: true,
          width: 20,
          type: ["R"],
        },
        {
          Header: "S.No",
          accessor: "",
          Cell: ({ cell: { row } }) => <p>{row.index + 1}</p>,
          disableFilters: true,
          maxWidth: 20,
          width: 20,
          type: ["P", "H", "R", "S"],
        },
        {
          Header: "Test",
          accessor: "quiz.title",
          disableSortBy: true,
          filter: "fuzzyText",
          type: ["H"],
        },
        {
          Header: "Date",
          accessor: "",
          Cell: ({ cell: { row } }) => (
            <p>{this.getQuizDate(row?.original?.quiz?.start_duration)}</p>
          ),
          filter: "fuzzyText",
          maxWidth: 30,
          width: 20,
          type: ["H"],
        },
        {
          Header: "Name",
          accessor: "name",
          disableSortBy: true,
          filter: "fuzzyText",
          type: ["H", "P"],
        },
        {
          Header: "Name",
          accessor: "resume_name",
          disableSortBy: true,
          Cell: ({ cell: { row } }) => (
            <p
              onClick={() => {
                // window.open(row.original.resume, "_blank")
                // console.log(row.original);
                this.props?.setresumeSeen(row.original.resume);
              }}
              className="underline cursor-pointer text-sky-600"
            >
              {row.original?.name ? row.original?.name : "Not Parsed"}
            </p>
          ),
          filter: "fuzzyText",
          type: ["R"],
        },
        {
          Header: "Email",
          accessor: "email",
          filter: "fuzzyText",
          maxWidth: 10,
          disableSortBy: true,
          width: 10,
          type: ["P", "H", "R"],
        },
        {
          Header: "Mobile",
          accessor: this.props.type === "H" ? "user.mobile" : "mobile",
          filter: "fuzzyText",
          disableSortBy: true,
          maxWidth: 20,
          width: 20,
          type: ["P", "H"],
        },
        {
          Header: "Gender",
          accessor: "user.gender",
          filter: "fuzzyText",
          disableSortBy: true,
          maxWidth: 20,
          width: 20,
          type: ["H"],
        },
        {
          Header: "College",
          accessor: "college",
          filter: "fuzzyText",
          disableSortBy: true,
          maxWidth: 30,
          width: 30,
          type: ["H"],
        },
        {
          Header: "Score(Normalized)",
          accessor: (row) =>
            row.normalized_score ? row.normalized_score.toFixed(2) : 0,
          filter: "fuzzyText",
          maxWidth: 140,
          width: 140,
          type: ["H"],
        },
        {
          Header: "Score (%)",
          accessor: (row) =>
            row.llm_confidence_score
              ? `${row.llm_confidence_score.toFixed(2)}%`
              : 0,
          minWidth: 60,
          type: ["R"],
        },
        {
          Header: "Story",
          accessor: "story",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ cell: { row } }) => (
            <>
              {this.getShortStory(row?.original?.story)}
              {row?.original?.story ? (
                <span
                  className="text-blue-500 text-xs sm:text-sm  cursor-pointer"
                  onClick={() => this.props.setShowStoryModal(row?.original)}
                >
                  more
                </span>
              ) : null}
            </>
          ),
          minWidth: 280,
          type: ["R"],
        },
        {
          Header: "Actions",
          accessor: "action",
          Cell: (props) => (
            <CustomActions
              type={this.props.type}
              response={props?.row?.original}
              props={this.props}
              deleteResponse={this.handleDeleteResponse}
              updateInterviewModal={this.updateInterviewModal}
            />
          ),
          disableSortBy: true,
          disableFilters: true,
          maxWidth: 20,
          type: ["H"],
        },
        {
          Header: "Actions",
          accessor: "resume_action",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ cell: { row } }) => (
            <>
              <select
                className="w-full cursor-pointer font-['nunito'] p-2 rounded-[0.3rem] outline-none border border-[#BCFB46]"
                value={row.original?.status}
                onChange={(e) => {
                  const updatedList = this.props.studentList.map((res) => {
                    if (res._id === row.original._id)
                      return { ...res, status: e.target.value };
                    return res;
                  });

                  this.props.updateChat({
                    message: updatedList,
                    chat_id: this.props.chat_id,
                  });
                  this.props.updateStatusOfResume({
                    id: row.original._id,
                    status: e.target.value,
                  });
                }}
              >
                <option value="">Choose Status </option>
                <option value="T">Select for test</option>
                <option value="I">Select for Interview</option>
                <option value="R">Reject</option>
              </select>
            </>
          ),
          type: ["R"],
        },

        {
          Header: "Name",
          accessor: "user_name",
          filter: "fuzzyText",
          disableSortBy: true,
          accessor: (row) => row?.userId ? row.userId?.name : "raman",
          type: ["S"],
        },
        {
          Header: "Email",
          accessor: "user_email",
          filter: "fuzzyText",
          disableSortBy: true,
          accessor: (row) => row?.userId ? row.userId?.email : "raman",
          type: ["S"],
        },
        {
          Header: "Test Allowed",
          accessor: "test_allowed",
          disableFilters: true,
          disableSortBy: true,
          Cell: ({ cell: { row } }) => (
            <div className="relative">
              <Tooltip
                theme="BOTTOM"
                text={`${this.getValidityDate(row.original?.test_validity_date_from, row.original?.test_validity_date_to)}`}
              >{row.original?.test_allowed - row.original?.test_remaining}/{row.original?.test_allowed}
              </Tooltip>
            </div>
          ),
          maxWidth: 140,
          width: 140,
          type: ["S"],
        },
        {
          Header: "Students Allowed",
          accessor: "students_allowed",
          disableFilters: true,
          disableSortBy: true,
          Cell: ({ cell: { row } }) => (
            <div >
             {row.original?.students_allowed - row.original?.students_remaining}/{row.original?.students_allowed}
            </div>
          ),
          maxWidth: 140,
          width: 140,
          type: ["S"],
        },
        {
          Header: "Interview Allowed",
          accessor: "interview_allowed",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ cell: { row } }) => (
            <div className="relative">
              <Tooltip
                theme="BOTTOM"
                text={`${this.getValidityDate(row.original?.interview_validity_date_from, row.original?.interview_validity_date_to)}`}
              >
                {row.original?.interview_allowed - row.original?.interview_remaining}/{row.original?.interview_allowed}
              </Tooltip>
            </div>
          ),
          type: ["S"],
        },
        {
          Header: "Resume Allowed",
          accessor: "resume_allowed",
          disableFilters: true,
          disableSortBy: true,
          accessor: (row) =>
            row.resume_allowed,
          Cell: ({ cell: { row } }) => (
            <div className="relative">
              <Tooltip
                theme="BOTTOM"
                text={`${this.getValidityDate(row.original?.resume_validity_date_from, row.original?.resume_validity_date_to)}`}
              >
                {row.original?.resume_allowed - row.original?.resume_remaining}/{row.original?.resume_allowed}
              </Tooltip>
            </div>
          ),
          maxWidth: 140,
          width: 140,
          type: ["S"],
        },

        {
          Header: "Actions",
          accessor: "subscription_action",
          disableFilters: true,
          Cell: (props) => (
            <div className="flex gap-2 items-center">
              <EditIcon
                className="cursor-pointer w-5 h-5"
                alt="svg"
                color="#a0db32"
                title="Update Subscription"
                onClick={() => this.props.updateSubscriptionModal(props.row?.original)}
              />
              <img
                alt="delete"
                src={deletec}
                title="Delete Subscription"
                className="cursor-pointer sm:w-5 sm:h-5 w-4 h-4 hoverPointer"
                onClick={() => this.props.deleteSubscriptionModal(props.row?.original)}
              />

            </div>
          ),
          disableSortBy: true,
          type: ["S"],
        },
      ],
    };
  }

  getShortStory(Story) {
    if (Story) {
      let stripedStory = Story.replace(/(<([^>]+)>)/gi, "");
      return stripedStory.length > 80
        ? stripedStory.slice(0, 70) + "..."
        : stripedStory;
    }
  }

  toggleRow(value, e) {
    let newSelected = [];
    if (e.target.checked)
      newSelected = [...this.props.selectedStudentMail, value._id];
    else
      newSelected = this.props.selectedStudentMail.filter(
        (id) => id !== value._id
      );

    // setCheckboxClicked(true);
    this.props.selectedMail(newSelected);
    this.props.setSelectedStudentMail(newSelected);
  }

  toggleSelectAll() {
    let newSelected = [];

    if (this.props.selectAll === 0)
      this.props.studentList.forEach((student) => {
        newSelected.push(student["_id"]);
      });

    // setCheckboxClicked(true);
    this.props.selectedMail(newSelected);
    this.props.setSelectedStudentMail(newSelected);
    this.props.setSelectAll(this.props.selectAll === 0 ? 1 : 0);
  }

  handleDeleteResponse = (responseId) => {
    this.setState({ responseId: responseId });
    this.props.updateTestDeleteModalActive(true);
  };

  getQuizDate(data) {
    return dayjs(data).format("DD/MM/YYYY");
  }

  getValidityDate(from, to) {
    if (from && to)
      return `(${ConvertTimeToCurrentTimezone(from).format(
        "D MMM YYYY"
      )} - ${ConvertTimeToCurrentTimezone(to).format(
        "D MMM YYYY"
      )})`;

    if (from && !to)
      return `(${ConvertTimeToCurrentTimezone(from).format(
        "D MMM YYYY"
      )} - NA)`;

    if (from && !to)
      return `(NA - ${ConvertTimeToCurrentTimezone(to).format(
        "D MMM YYYY"
      )})`;

    return `(NA - NA)`;
  }

  handleToastWarning = (msg) => {
    toast.warning(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  render() {
    return (
      <>
        <DeleteTest
          testId={this.state.responseId}
          title="response"
          handleToast={this.handleToastWarning}
        />
        {this.props?.studentList?.length !== 0 && (
          <div className="flex flex-col w-full overflow-x-auto relative  font-['poppins']">
            <Table
              columns={this.state.columns}
              data={this.props.studentList || []}
              type={this.props.type}
            />
          </div>
        )}
      </>
    );
  }
}

function DefaultFilterForColumn({
  column: {
    filterValue,
    preFilteredRows: { length },
    setFilter,
  },
}) {
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        // Set undefined to remove the filter entirely
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${length} records..`}
      className="mt-2 shadow appearance-none border rounded w-full p-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    />
  );
}

function Table({ columns, data, updateMyData, skipPageReset, type }) {
  // const [showColumns, setShowColumns] = useState({});

  // const columnsWithVisibility = useMemo(() => {
  //   console.log("colummns", columns[0].type.includes(type))
  //   return columns.map((column) => ({
  //     ...column,
  //     show: column?.type.includes(type)
  //   }));
  // }, [columns, showColumns]);
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headers,
    rows,
    visibleColumns,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultFilterForColumn },
      autoResetPage: !skipPageReset,
      initialState: {
        pageSize: 5,
        // Set the default page size to 5
      },
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if(type !== 'P'){query.get("page") && gotoPage(query.get("page"));
    query.get("pageSize") && setPageSize(Number(query.get("pageSize")));}
  }, []);

  const setParamsInProps = ({ key, value }) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    if (currentUrlParams.has(key)) {
      currentUrlParams.set(key, value);
    } else {
      currentUrlParams.append(key, value);
    }
    let baseUrl = window.location.href.split("?")[0];
    let newUrl = `${baseUrl}?${currentUrlParams.toString()}`;
    window.history.replaceState({}, "", newUrl);
  };
  
  // Render the UI for your table
  return (
    <>
      <table className="sm:text-sm text-left w-full" {...getTableProps()}>
        <thead className="sm:text-sm text-[9px] shadow-sm uppercase border border-gray-300 align-top bg-[#E5E5E5]">
          <tr>
            {headers?.map((header, index) => (
              <th
                {...header?.getHeaderProps({
                  style: {
                    minWidth: header?.minWidth,
                    width: header?.width,
                    display: header?.type.includes(type)
                      ? "table-cell"
                      : "none",
                  },
                })}
                className="sm:p-3 p-1"
                key={`students-head-${index}`}
              >
                <p className="flex gap-1">
                  <span
                    {...header?.getHeaderProps(header?.getSortByToggleProps())}
                  >
                    {header?.render("Header")}
                  </span>
                  <span>
                    {header?.isSorted
                      ? header?.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </p>

                {/* Rendering Default Column Filter */}
                <div>{header?.canFilter ? header?.render("Filter") : null}</div>
              </th>
            ))}
          </tr>
        </thead>

        <tbody
          {...getTableBodyProps()}
          className={`sm:text-sm text-[10px] ${type === "R" && "bg-[#fff]"}`}
        >
          {page?.map((row, i) => {
            prepareRow(row);

            return (
              <tr {...row?.getRowProps()} className="border-b-2" key={i}>
                {row?.cells?.map((cell) => {
                  return (
                    <td
                      {...cell?.getCellProps({
                        style: {
                          minWidth: cell?.column.minWidth,
                          width: cell?.column.width,
                          display: cell?.column?.type?.includes(type)
                            ? "table-cell"
                            : "none",
                        },
                      })}
                      {...cell?.getCellProps()}
                      className="sm:p-3 px-1 border-x"
                    >
                      {cell?.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex p-2 justify-between items-center flex-col sm:flex-row gap-2">
        <div className="flex w-full sm:w-auto justify-between">
          <span>
            Page{" "}
            <input
              type="number"
              value={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                if(type !== 'P')setParamsInProps({ key: "page", value: page });
                gotoPage(page);
              }}
              className="border-2 border-gray-400 px-1 rounded text-center"
              style={{ width: "45px" }}
            />{" "}
            Of {pageOptions.length}
          </span>
          <select
            value={pageSize}
            className="border-2 sm:hidden w-[8rem] border-gray-400 px-2 rounded"
            onChange={(e) => {
              setParamsInProps({
                key: "pageSize",
                value: Number(e.target.value),
              });
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>

        <div>
          <button
            className="text-[#001437] border  bg-[#B8FB3C]  hover:text-white
             hover:bg-[#001437] font-semibold uppercase text-sm
            px-4 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150
            disabled:opacity-25 disabled:cursor-not-allowed disabled:bg-gray-500"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>{" "}
          <button
            className="text-[#001437] border  bg-[#B8FB3C] hover:text-white
             hover:bg-[#001437] font-semibold uppercase text-sm
            px-4 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150
            disabled:opacity-25 disabled:cursor-not-allowed disabled:bg-gray-500"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Prev
          </button>{" "}
          <button
            className="text-[#001437] border  bg-[#B8FB3C] hover:text-white 
             hover:bg-[#001437] font-semibold uppercase text-sm
            px-4 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150
            disabled:opacity-25 disabled:cursor-not-allowed disabled:bg-gray-500"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>{" "}
          <button
            className="text-[#001437] border  bg-[#B8FB3C] hover:text-white 
             hover:bg-[#001437] font-semibold uppercase text-sm
            px-4 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150
            disabled:opacity-25 disabled:cursor-not-allowed disabled:bg-gray-500"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
        </div>
        <select
          value={pageSize}
          className="hidden sm:block border-2 w-[8rem] border-gray-400 px-2 rounded"
          onChange={(e) => {
            if(type !== 'P')setParamsInProps({
              key: "pageSize",
              value: Number(e.target.value),
            });
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

const CustomActions = ({
  response,
  type,
  props,
  deleteResponse,
  updateInterviewModal,
}) => {
  const [interviewModal, setInterviewModal] = useState(false);
  const [interviewEmail, setInterviewEmail] = useState("");
  // const [resumeModal, setResumeModal] = useState("");

  const handleInterviewModal = ({ value, email }) => {
    setInterviewModal(value);
    setInterviewEmail(email);
  };

  const handleReviewTest = () => {
    if (response.user) {
      window.open(`/tests/candidatereport/${response._id}`, "_blank");
      // props.history({
      //   pathname: `/tests/reports/${response?.quiz}/user/${response.user._id}`,
      //   state: response._id,
      // });
    } else {
      window.open(`/tests/candidatereport/${response?._id}`, "_blank");
      // props.history({
      //   pathname: `/tests/reports/${response?.quiz}/user/${response.email}`,
      //   state: response.email,
      // });
    }
  };

  return (
    <>
      {/* {console.log("checking", response)} */}
      <div className="flex sm:gap-x-3 gap-x-1 items-center">
        {type === "R" ? (
          <>
            <InterviewCall
              className="ml-2 sm:w-6 sm:h-6 w-4 h-4 hoverPointer"
              color="#a0db32"
              onClick={() =>
                handleInterviewModal({ value: true, email: response.email })
              }
            />
            {/* <Proctor
              className="ml-2 sm:w-6 sm:h-6 w-4 h-4 hoverPointer"
              color="#a0db32"
              onClick={() =>
                setResumeModal()
              }
            /> */}
          </>
        ) : (
          <Proctor
            className="w-5 h-5 hoverPointer"
            alt="svg"
            color="#a0db32"
            title="View Report"
            onClick={() => handleReviewTest()}
          />
        )}
      </div>

      <Modal
        onClose={() => handleInterviewModal({ value: false })}
        isModal={interviewModal}
        showCloseButton
        className={"lg:w-[55%]"}
      >
        <InterviewModal
          updateInterviewModal={(data) => handleInterviewModal(data)}
          email={interviewEmail}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = ({ test }) => {
  return {
    companies: test.companies,
    allTests: test.allTests,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTestDeleteModalActive: (data) =>
      dispatch(updateTestDeleteModalActive(data)),
    updateStatusOfResume: (data) => dispatch(updateStatusOfResume(data)),
    updateChat: (data) => dispatch(updateChatRequest(data)),
    selectedMail: (data) => dispatch(selectedMail(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardTable));
