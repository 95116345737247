import { call, put, takeEvery } from "redux-saga/effects";
import { FETCH_TURNSERVER_CREDENTIALS } from "../actionTypes";
import { fetchTurnServerCredsSuccess } from "../actions/turnserver";
import { getTurnServerCreds } from "../../services/turnserver.service";

function* fetchTurnserver() {
  try {
    const creds = yield call(getTurnServerCreds);
    yield put(fetchTurnServerCredsSuccess(creds));
  } catch (e) {
    console.log(e);
    // yield put(setMessage("Email or Password is Incorrect!"))
    // yield put(loginFailure(e));
  }
}

function* turnServerSaga() {
  yield takeEvery(FETCH_TURNSERVER_CREDENTIALS, fetchTurnserver);
}

export default turnServerSaga;
