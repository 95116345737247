import React, { Component } from "react";
import load from "../assets/img/svg/load.svg";
import kyron from "../assets/img/kyron.png";
import { Link } from "react-router-dom";
import TestService from "../services/test.service";
import "../assets/css/Dashboard.css";
import DeleteTest from "../components/tests/createTest/DeleteTest";
import {
  fetchAllTests,
  fetchCompaniesRequested,
  updateTestDeleteModalActive,
} from "../store/actions/test";
import OngoingTestCards from "./OngoingTestCards";
import UpcomingTestCards from "./UpcomingTestCards";
import EndedTestCards from "./EndedTestCards";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import UniversalButton from "../components/common/StandardButton/UniversalButton";
import { withRouter } from "../components/common/WithRouter";
dayjs.extend(utc);
dayjs.extend(timezone);

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testId: " ",
      testList: [],
      teachersList: [],
      pageLoading: true,
      value: "default",
      companies: [],
    };
  }

  componentDidMount() {
    // Calling here for 1st time getting all tests
    this.props.fetchAllTests({
      quiz_type: "ongoing",
      is_a_set: this.isStudent() ? false : "",
      is_aits: this.props.is_aits,
    });
    this.props.fetchAllTests({
      quiz_type: "upcoming",
      is_a_set: this.isStudent() ? false : "",
      is_aits: this.props.is_aits,
    });
    this.props.fetchAllTests({
      quiz_type: "ended",
      is_a_set: this.isStudent() ? false : "",
      is_aits: this.props.is_aits,
    });
    // this.props.fetchCompaniesRequested();
    // this.setState({ pageLoading: true });
    // TestService.getAllTests().then(
    //   (response) => {
    //     this.setState({ testList: response.data });
    //     this.setState({ pageLoading: false });
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );

    // UserService.getAllUsers()
    //   .then((response) => {
    //     const teachers = response.data.filter((each) => each.type === "T");
    //     this.setState({ teachersList: teachers });
    //   })
    //   .catch((err) => console.log(err));
  }

  componentDidUpdate(prevProps) {
    if (this.props.allTests && this.state.pageLoading)
      this.setState({ pageLoading: false });

    // console.log("test", this.props);
    if (prevProps.error !== this.props.error) {
      this.handleToastWarning(this.props.error);
    }
  }

  isAdmin = () => {
    var type = localStorage["priksha_type"];
    return type === "A";
  };

  handleToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  handleToastWarning = (msg) => {
    toast.warning(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  isTeacher = () => {
    var type = localStorage["priksha_type"];
    return type === "T";
  };
  isKAInstructor = () => {
    var type = localStorage["priksha_type"];
    return type === "KA-I";
  };
  isStudent = () => {
    var type = localStorage["priksha_type"];
    return type === "S";
  };

  isAssistant = () => {
    var type = localStorage["priksha_type"];
    return type === "TA";
  };

  isProctor = () => {
    var type = localStorage["priksha_type"];
    return type === "P";
  };

  createNewTest = (e) => {
    e.preventDefault();
    TestService.createOneTest().then(
      (response) => {
        this.props.history(`/tests/edit/${response.data._id}`);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  displaySpecificTests = async (e) => {
    e.preventDefault();
    this.setState({ pageLoading: true });
    let totalData;
    await TestService.getAllTests().then(
      (response) => {
        totalData = response.data;
      },
      (error) => {
        console.log(error);
      }
    );
    if (e.target.value === "default") {
      this.setState({ testList: totalData });
    } else {
      const teacherTests = totalData.filter(
        (eachTest) => eachTest.created_By === e.target.value
      );
      this.setState({ testList: teacherTests });
    }
    this.setState({ pageLoading: false });
  };

  handleDeleteTest = (testId) => {
    this.setState({ testId: testId });
    this.props.updateTestDeleteModalActive(true);
  };

  getRecentTestData = () => {
    let testData = this.state.testList;
    return testData.slice(0, 3);
  };

  checkGiveTestEligibility = (data) => {
    return new Date(data.end_duration).getTime() > new Date().getTime();
  };

  openKyronAcademy = (e) => {
    e.preventDefault();
    window.open("https://kyron.in/academy");
  };

  render() {
    // console.log(dayjs.tz.guess());
    // console.log(dayjs("2013-11-18T11:55:20").tz("America/Toronto", true));
    // console.log(new Date(dayjs.tz("2022-10-18 12:00", "America/Toronto")));
    return (
      <>
        <DeleteTest
          testId={this.state.testId}
          title="test"
          handleToast={this.handleToastWarning}
        />
        <div className="flex flex-col p-3">
          <div className="flex mt-2">
            <div
              className="searchCreateTest rounded px-4 py-3 w-full flex flex-col lg:flex-row justify-between"
              style={{
                boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
                background:
                  "linear-gradient(91deg, #C6FB63 2.1%, #94E005 98.82%)",
              }}
            >
              <p className="text-[1.2rem] sm:text-[1.5rem] font-semibold font-['poppins']">
                Warmest Welcome to Test
              </p>
              <div className="flex gap-[2rem] items-center mt-2 lg:mt-0">
                {this.isAdmin() || this.isTeacher() || this.isAssistant() ? (
                  <>
                    {this.isAdmin() ? (
                      <div className="mx-2">
                        <div>Filter by teacher:</div>
                        <select
                          name="teacherList"
                          id="teachers"
                          className="form-control"
                          onChange={this.displaySpecificTests}
                          style={{ width: "100%" }}
                          value={this.state.value}
                        >
                          <option value="default">All</option>
                          {this.state.teachersList.map((teacher) => {
                            return (
                              <option
                                value={teacher._id}
                                key={teacher._id}
                                style={{ padding: "2px 0" }}
                              >{`${teacher.name} (${teacher.email})`}</option>
                            );
                          })}
                        </select>
                      </div>
                    ) : (
                      <></>
                    )}
                    <Link
                      to="/tests/edit/"
                      onClick={(e) => this.createNewTest(e)}
                      className="py-3 outline-none focus:outline-none text-[#001437] w-auto sm:bg-[#BCFB46] bg-[#94E005] hover:bg-[#001437] hover:text-[#a0db32]  rounded-lg md:text-[16px] font-bold md:px-6 text-sm  text-md px-6 py-2 text-center font-['nunito'] cursor-pointer"
                    >
                      Create Test
                    </Link>
                  </>
                ) : (
                  <></>
                )}

                <UniversalButton
                  onClickFunction={() => {
                    this.props.fetchAllTests({
                      quiz_type: "upcoming",
                      is_a_set: this.isStudent() ? false : "",
                    });
                    this.props.fetchAllTests({
                      quiz_type: "ongoing",
                      is_a_set: this.isStudent() ? false : "",
                    });
                  }}
                  classNames="py-3 w-auto"
                  title="Refresh Tests"
                />
              </div>
            </div>
          </div>

          {this.state.pageLoading ? (
            <div
              className="w-full h-full flex justify-center items-center "
              style={{ minHeight: "200px" }}
            >
              <img
                alt="loading"
                src={load}
                className="spinner mr-2 w-6 h-6 text-primary"
              />
            </div>
          ) : (
            <>
              <div className="overflow-hidden w-full h-full flex flex-col flex-start items-start mt-[1.5rem] pb-20">
                <OngoingTestCards
                  isAdmin={this.isAdmin}
                  handleToast={this.handleToastWarning}
                  isTeacher={this.isTeacher}
                  isKAInstructor={this.isKAInstructor}
                  isAssistant={this.isAssistant}
                  isProctor={this.isProctor}
                  showDropDown={this.showDropDown}
                  handleDropDown={this.handleDropDown}
                  handleDeleteTest={this.handleDeleteTest}
                  ongoing={this.props.allTests.ongoing}
                />
                <UpcomingTestCards
                  isAdmin={this.isAdmin}
                  isTeacher={this.isTeacher}
                  isKAInstructor={this.isKAInstructor}
                  handleToast={this.handleToastWarning}
                  isAssistant={this.isAssistant}
                  showDropDown={this.showDropDown}
                  handleDropDown={this.handleDropDown}
                  handleDeleteTest={this.handleDeleteTest}
                  upcoming={this.props.allTests.upcoming}
                  errorMessage={this.props.error}
                />
                <EndedTestCards
                  isAdmin={this.isAdmin}
                  isTeacher={this.isTeacher}
                  isKAInstructor={this.isKAInstructor}
                  handleToast={this.handleToastWarning}
                  isAssistant={this.isAssistant}
                  showDropDown={this.showDropDown}
                  handleDropDown={this.handleDropDown}
                  handleDeleteTest={this.handleDeleteTest}
                  ended={this.props.allTests.ended}
                />
              </div>
            </>
          )}
        </div>
        {Boolean(this.props.location.state) && (
          <div
            className={`flex justify-center items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full md:h-full`}
            id="popup-modal"
            style={{ backgroundColor: "rgb(0 ,0 ,0,0.1)" }}
          >
            <div className="flex justify-center items-center p-1 w-full h-full md:h-auto">
              <div className="flex justify-center items-center relative w-full min-w-xs max-w-xl h-full md:h-auto">
                <div className=" relative w-full rounded-lg bg-white shadow-2">
                  <div className="flex justify-end p-2">
                    <button
                      type="button"
                      onClick={() => this.props.history({ state: false })}
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                      data-modal-toggle="popup-modal"
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div className="flex-col w-full">
                    <img src={kyron} alt="kyron" />
                    <p className="p-5 text-md font-medium font-serif">
                      "You performed good in this one. Want to perform even
                      better in the next test? Want to prepare yourself for
                      software engineering or machine learning interviews? Visit
                      Kyron Academy{" "}
                      <a
                        href="https://kyron.in/academy"
                        target="blank"
                        className="text-[#009999]"
                      >
                        kyron.in/academy
                      </a>{" "}
                      to know more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* {((!this.isAdmin() || !this.isTeacher() || !this.isAssistant())  && Boolean(this.props.location.state)) && */}
        {/* <div className="fixed w-full bottom-0 bg-transparent text-center py-3 lg:px-4">
            <div
              onClick={this.openKyronAcademy}
              className="cursor-pointer p-2 bg-[#001437] items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
              role="alert"
            >
              <span className="flex rounded-full bg-[#b8fb3c] uppercase px-2 py-1 text-xs font-bold mr-3 text-[#001437]">
                New
              </span>
              <span className="font-semibold mr-2 text-left flex-auto">
                Want to prepare yourself for software engineering or machine
                learning interviews? Visit Kyron Academy
              </span>
              <svg
                className="fill-current opacity-75 h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
              </svg>
            </div>
          </div> */}
        {/* )} */}
      </>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    companies: test.companies,
    deleteModalActive: test.deleteModalActive,
    allTests: test.allTests,
    error: test.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTestDeleteModalActive: (data) =>
      dispatch(updateTestDeleteModalActive(data)),
    fetchAllTests: (data) => dispatch(fetchAllTests(data)),
    fetchCompaniesRequested: () => dispatch(fetchCompaniesRequested()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Test));
