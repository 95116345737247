import React from "react";
import { toast } from "react-toastify";
import mime from "mime";
import { ReactMediaRecorder } from "react-media-recorder";
import Main from "../codeEditor/Components/Main";
import "../../common/Common.css";
import { ReactComponent as PlayIcon } from "../../../assets/img/svg/play.svg";
import { ReactComponent as StopIcon } from "../../../assets/img/svg/stop.svg";
import { ReactComponent as ButtonSpinner } from "../../../assets/img/svg/button-spinner.svg";
import Speech from "speak-tts";
import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css";
// Store
import { connect } from "react-redux";
import {
  updateTestResponse,
  updateTestQuestionDataWithKey,
  updateQuestionIndex,
  updateSectionIndex,
  updateTestAccordianIndex,
  updateTestAccordianPageIndex,
  toggleRightSidebar,
  updateAudioRecordingStatus,
} from "../../../store/actions/test";
import { uploadFile, viewFile } from "../../../services/upload.service";

import CountDownAnswer from "./CountDownAnswer";
import PostgresQuestion from "../../../components/tests/PostgresQuestion";
import { updateInterviewResponseRequested } from "../../../store/actions/interviewpanel";
import { getType, getUserIdFromPeerId } from "../../common/utils";
import MyQuillEditor from "../../common/ReactQuill/ReactQuill";
import CountDownResponse from "./CountDownResponse";
import Modal from "../../common/Modal/Modal";
import PreviousMap from "postcss/lib/previous-map";
import { VideoPreview } from "./VideoRecorder";
import YouTubePlayer from "../../common/YoutubePlayer";
import CustomAudio from "../../audio/CustomAudio";
import load from "../../../assets/img/svg/load.svg";
import ViewFileModal from "../../modals/ViewFileModal";

const speech = new Speech();
const acceptFileType = {
  Any: "",
  Image: "image/*",
  Video: "video/*",
  Audio: "audio/*",
  PDFs: ".pdf",
  DOCs: ".doc, .docx",
};

const recordStatusMapping = {
  media_aborted: "Error",
  permission_denied: "Check Permission",
  no_specified_media_found: "Error",
  media_in_use: "Error",
  invalid_media_constraints: "Error",
  no_constraints: "Error",
  recorder_error: "Error",
  idle: "Start Recording",
  acquiring_media: "Initializing",
  recording: "Recording",
  stopping: "Stopping",
  stopped: "Stopped Recording",
};

let questionBox = null;

class RenderQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.startRecordingRef = React.createRef();
    this.stopRecordingRef = React.createRef();
    this.audioRef = React.createRef();
    this.onResponseChange = this.onResponseChange.bind(this);
    this.onFilesChange = this.onFilesChange.bind(this);
    this.UploadToServer = this.UploadToServer.bind(this);
    this.handleVoice = this.handleVoice.bind(this);
    this.onResponseChangeDescriptive =
      this.onResponseChangeDescriptive.bind(this);
    this.state = {
      questionFilesURL: [],
      mcqoptionsFiles: [],
      checkValues: [],
      // completedPreparationTime: null,
      // startPreparationTime: null,
      // isAnswerTimeCompleted: false,
      isHandleVoice: true,
      answerFilesUrl: null,
      timerColor: "green",
      isAudioLoaded: false,
      currentLanguage: "E",
      uploadFileLoading: false,
      fileModal: false,
    };
    this.audioEle = null;
  }

  setAudioRecordingStatus = () => {
    this.props.updateAudioRecordingStatus({
      key: "completedPreparationTime",
      value: false,
    });
    this.props.updateAudioRecordingStatus({
      key: "startPreparationTime",
      value: false,
    });
    this.props.updateAudioRecordingStatus({
      key: "isAnswerTimeCompleted",
      value: false,
    });
  };
  componentDidMount() {
    this.setAudioRecordingStatus();
    window.onbeforeunload = (e) => {
      if (speech.speaking()) {
        speech.cancel();
      }
    };

    this.props.updateQuestionIndex(this.props.question.questionIndex);
    this.props.updateSectionIndex(this.props.section.sectionIndex);
    const { response, section, question, isRightSidebar } = this.props;
    if (question?.type === "record") {
      this.setAudioRecordingStatus();
    }
    if (isRightSidebar) {
      this.props.toggleRightSidebar();
    }
    if (question?.type === "description") {
      if (
        response &&
        response[section?.sectionIndex] &&
        response[section?.sectionIndex][question?.questionIndex]
      ) {
        this.setState((prev) => {
          return {
            ...prev,
            currentLanguage:
              response[section?.sectionIndex][question?.questionIndex]?.lan,
          };
        });
      } else {
        this.setState((prev) => {
          return {
            ...prev,
            currentLanguage: this.props.question.ansLanguageType,
          };
        });
      }
    }
    if (this.props.question.type === "checkbox") {
      if (typeof response !== "undefined" && response) {
        if (Object.entries(response).length) {
          if (typeof response[section.sectionIndex] !== "undefined") {
            if (
              typeof response[section.sectionIndex][question.questionIndex] !==
              "undefined"
            ) {
              if (response[section.sectionIndex][question.questionIndex]) {
                this.setState({
                  checkValues:
                    response[section.sectionIndex][question.questionIndex],
                });
              }
            }
          }
        }
      }
    }

    if (
      question?.type === "record" &&
      response &&
      response[section?.sectionIndex] &&
      response[section?.sectionIndex][question?.questionIndex]
    ) {
      this.getUrl(response[section?.sectionIndex][question?.questionIndex][0]);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        answerFilesUrl: false,
      }));
    }
    if (this.props.question.questionFiles) {
      Promise.all(
        this.props.question.questionFiles.map((filename) => {
          return viewFile(filename, "TEST");
        })
      ).then((data) => {
        this.setState((prev) => {
          return {
            ...prev,
            questionFilesURL: data,
          };
        });
      });
    }

    if (question?.type === "checkbox" && this.props.question.options) {
      Promise.all(
        this.props.question.options.map((option) => {
          return option.option_file && viewFile(option.option_file, "TEST");
        })
      ).then((data) => {
        this.setState((prev) => {
          return {
            ...prev,
            mcqoptionsFiles: data,
          };
        });
      });
    }
    if (question?.type === "record") {
      speech
        .init()
        .then((data) => {
          // The "data" object contains the list of available voices and the voice synthesis params
          console.log("Speech is ready, voices are available", data);
        })
        .catch((e) => {
          console.error("An error occured while initializing : ", e);
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { response, section, question } = this.props;

    if (
      (this.props.type === "I" || this.props.type === "T") &&
      response &&
      response[section.sectionIndex] &&
      response[section.sectionIndex][question.questionIndex] &&
      question?.type === "file" &&
      !this.state.answerFilesUrl
    ) {
      this.getUrl(response[section.sectionIndex][question.questionIndex]);
    }
  }

  debounce(func, timeout = 3000) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  sendSocketEvent = this.debounce((value) => {
    this.props.socket.emit("send-response-event", {
      state: value,
      userId: getUserIdFromPeerId(this.props.interviewerPeerId),
    });
  });

  onResponseChangeDescriptive(value) {
    const name = `${this.props.section.sectionIndex},${this.props.question.questionIndex}`;
    const l = name.split(",");
    setTimeout(() => {
      if (this.props.type === "I") {
        if (getType() === "S") {
          this.sendSocketEvent({
            sectionIndex: l[0],
            questionIndex: l[1],
            value: {
              lan: this.state.currentLanguage,
              text: value,
            },
          });
        }
        this.props.updateInterviewResponseRequested({
          sectionIndex: l[0],
          questionIndex: l[1],
          value: {
            lan: this.state.currentLanguage,
            text: value,
          },
        });
      } else {
        this.props.updateTestResponse({
          sectionIndex: l[0],
          questionIndex: l[1],
          value: {
            lan: this.state.currentLanguage,
            text: value,
          },
        });
      }
    }, 0);
  }

  onResponseChangeInteger(e) {
    const name = `${this.props.section.sectionIndex},${this.props.question.questionIndex}`;
    const l = name.split(",");
    if (this.props.type === "I") {
      this.props.socket.emit("send-response-event", {
        state: {
          sectionIndex: l[0],
          questionIndex: l[1],
          value: e.target.value,
        },
        userId: getUserIdFromPeerId(this.props.interviewerPeerId),
      });

      this.props.updateInterviewResponseRequested({
        sectionIndex: l[0],
        questionIndex: l[1],
        value: e.target.value,
      });
    } else {
      this.props.updateTestResponse({
        sectionIndex: l[0],
        questionIndex: l[1],
        value: e.target.value,
      });
    }
  }

  onResponseChange(e) {
    const name = e.target.name;
    const l = name.split(",");
    if (this.props.question.optionType === "checkbox") {
      const { checkValues } = this.state;
      const { options } = this.props.question;
      let tempvalues = new Array(options.length).fill(false);
      for (let i = 0; i < checkValues.length; i++) {
        if (i < options.length && checkValues[i]) {
          tempvalues[i] = checkValues[i];
        }
      }
      tempvalues[e.target.value] = e.target.checked;
      this.setState({ checkValues: tempvalues });
      if (this.props.type === "I") {
        this.props.socket.emit("send-response-event", {
          state: {
            sectionIndex: l[0],
            questionIndex: l[1],
            value: tempvalues,
          },
          userId: getUserIdFromPeerId(this.props.interviewerPeerId),
        });

        this.props.updateInterviewResponseRequested({
          sectionIndex: l[0],
          questionIndex: l[1],
          value: tempvalues,
        });
      } else {
        this.props.updateTestResponse({
          sectionIndex: l[0],
          questionIndex: l[1],
          value: tempvalues,
        });
      }
    } else {
      const tempvalues = new Array(this.props.question.options.length).fill(
        false
      );
      tempvalues[e.target.value] = true;
      this.setState({ checkValues: tempvalues });
      if (this.props.type === "I") {
        this.props.socket.emit("send-response-event", {
          state: {
            sectionIndex: l[0],
            questionIndex: l[1],
            value: tempvalues,
          },
          userId: getUserIdFromPeerId(this.props.interviewerPeerId),
        });

        this.props.updateInterviewResponseRequested({
          sectionIndex: l[0],
          questionIndex: l[1],
          value: tempvalues,
        });
      } else {
        this.props.updateTestResponse({
          sectionIndex: l[0],
          questionIndex: l[1],
          value: tempvalues,
        });
      }
    }
  }

  getUrl(filename) {
    if (filename && filename.length) {
      viewFile(filename, "RESPONSE").then((res) => {
        this.setState(
          (prevState) => ({
            ...prevState,
            answerFilesUrl: res,
          }),
          function () {
            if (this.audioRef.current) {
              this.audioRef.current.load();
              this.audioRef.current.play();
              this.audioRef.current.muted = true;
              this.audioRef.current.playbackRate = 16;
              // this.audioRef.current.pause();
              // this.audioRef.current.pause();
              this.audioRef.current.addEventListener("ended", (event) => {
                this.audioRef.current.playbackRate = 1;
                this.audioRef.current.currentTime = 0;
                this.audioRef.current.muted = false;
                this.setState((prev) => {
                  return {
                    ...prev,
                    isAudioLoaded: true,
                  };
                });
              });
            }
          }
        );
      });
    }
  }

  onFilesChange(e) {
    const l = e.target.name.split(",");
    this.setState((prev) => {
      return {
        ...prev,
        uploadFileLoading: true,
      };
    });
    uploadFile(e.target.files[0], {
      type: "RESPONSE",
      id: this.props.testId,
    })
      .then((filename) => {
        const value = filename;
        if (this.props.type === "I") {
          this.props.socket.emit("send-response-event", {
            state: {
              sectionIndex: l[0],
              questionIndex: l[1],
              value,
            },
            userId: getUserIdFromPeerId(this.props.interviewerPeerId),
          });
          this.props.updateInterviewResponseRequested({
            sectionIndex: l[0],
            questionIndex: l[1],
            value,
          });
        } else {
          this.props.updateTestResponse({
            sectionIndex: l[0],
            questionIndex: l[1],
            value,
          });
          this.getUrl(filename);
        }
      })
      .catch((e) => console.log("File uploading", e))
      .finally(() =>
        this.setState((prev) => {
          return {
            ...prev,
            uploadFileLoading: false,
          };
        })
      );
  }

  UploadToServer = (blobUrl, blob) => {
    uploadFile(blob, {
      type: "RESPONSE",
      id: this.props.testId,
    }).then((filename) => {
      if (this.props.type === "I") {
        this.props.socket.emit("send-response-event", {
          state: {
            sectionIndex: this.props.section.sectionIndex,
            questionIndex: this.props.question.questionIndex,
            value: [filename],
          },
          userId: getUserIdFromPeerId(this.props.interviewerPeerId),
        });
        this.props.updateInterviewResponseRequested({
          sectionIndex: this.props.section.sectionIndex,
          questionIndex: this.props.question.questionIndex,
          value: [filename],
        });
      } else {
        this.props.updateTestResponse({
          sectionIndex: this.props.section.sectionIndex,
          questionIndex: this.props.question.questionIndex,
          value: [filename],
        });
      }
    });
  };

  // checkAndForward = () => {
  //   const {
  //     section,
  //     sections,
  //     currentAccordianIndex,
  //     currentAccordianPageIndex,
  //   } = this.props;
  //   if (section.components.length > currentAccordianPageIndex + 1) {
  //     this.props.handleNextQuestion();
  //   } else if (sections.length > currentAccordianIndex + 1) {
  //     this.props.handleNextSection();
  //   } else {
  //     this.props.handleSubmitModal();
  //   }
  // };

  countDownAnsCompleted = () => {
    this.props.updateAudioRecordingStatus({
      key: "completedPreparationTime",
      value: true,
    });
    // this.setState((prev) => {
    //   return {
    //     ...prev,
    //     completedPreparationTime: true,
    //   };
    // });
    this.startRecordingRef.current.click();
  };

  renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      this.props.updateAudioRecordingStatus({
        key: "completedPreparationTime",
        value: true,
      });
      // this.setState((prev) => {
      //   return {
      //     ...prev,
      //     completedPreparationTime: true,
      //   };
      // });
      this.startRecordingRef.current.click();
      return <div className="text-md">Recording Started</div>;
    } else {
      // Change color of timer

      // const totalTime = Number(this.props.question.preparation_time) / 1000;
      // const remainingTime = minutes * 60 + seconds;
      // console.log(remainingTime, totalTime);
      // console.log(this.state.timerColor);
      // if (
      //   totalTime &&
      //   remainingTime > totalTime / 3 &&
      //   remainingTime < (totalTime * 2) / 3 &&
      //   this.state.timerColor !== "yellow"
      // )
      //   this.setState((prev) => {
      //     return {
      //       ...prev,
      //       timerColor: "yellow",
      //     };
      //   });
      // else if (
      //   totalTime &&
      //   remainingTime < totalTime / 3 &&
      //   remainingTime > 0 &&
      //   this.state.timerColor !== "red"
      // )
      //   this.setState((prev) => {
      //     return {
      //       ...prev,
      //       timerColor: "red",
      //     };
      //   });

      return (
        <span>
          {("0" + minutes).slice(-2)}M {("0" + seconds).slice(-2)}S
        </span>
      );
    }
  };

  handleLanguage(e) {
    this.setState((prev) => {
      return {
        ...prev,
        currentLanguage: e,
      };
    });
  }

  getWordCount() {
    let answer =
      this.props.response?.[this.props.section.sectionIndex]?.[
        this.props.question.questionIndex
      ]?.text || "";

    // console.log(
    //   "wcheck",
    //   answer.length,
    //   answer.split(" ").filter(function (n) {
    //     return n !== "";
    //   }).length
    // );
    return answer
      .replace(/<(.|\n)*?>/g, "")
      .trim()
      .split(" ")
      .filter(function (n) {
        return n !== "";
      }).length;
  }

  getCharacterCount() {
    let answer =
      this.props.response?.[this.props.section.sectionIndex]?.[
        this.props.question.questionIndex
      ]?.text || "";
    // console.log("ccheck", answer);
    return answer.replace(/<(.|\n)*?>/g, "").trim().length;
  }

  // rendererStopRecording = ({ minutes, seconds, completed }) => {
  //   console.log(completed, "ijini");
  //   if (completed) {
  //     this.stopRecordingRef.current.click();
  //     this.setState((prev) => {
  //       return {
  //         isAnswerTimeCompleted: true,
  //       };
  //     });
  //     return <div className="text-md">00:00</div>;
  //   } else {
  //     return (
  //       <span>
  //         {("0" + minutes).slice(-2)}M {("0" + seconds).slice(-2)}S
  //       </span>
  //     );
  //   }
  // };

  handleToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  handleVoice(text) {
    // const _text =
    //   "     text hello world hello hello hello hello text       ".trim();
    // console.log(this.state.answerFilesUrl);
    // console.log(this.state.completedPreparationTime);
    // console.log(this.state.startPreparationTime, "start preparation time");
    // if (speech.speaking()) {
    //   speech.cancel();
    //   console.log("cancel ");
    // }
    var span = document.createElement("span");
    span.innerHTML = text;
    const _text = (span.textContent || span.innerText).trim();
    if (
      // Boolean(this.state.answerFilesUrl) &&
      this.state.answerFilesUrl !== null &&
      !this.state.answerFilesUrl &&
      !this.props.test?.completedPreparationTime &&
      !this.props.test?.startPreparationTime &&
      this.state.isHandleVoice
      // && !speech.speaking()
    ) {
      // console.log("speak start");
      // if (speech.speaking()) {
      //   speech.cancel();
      //   console.log("cancel ");
      //   return;
      // }
      let count = 0;
      speech
        .speak({
          text: _text,
          queue: false,
          listeners: {
            onstart: () => {
              // console.log("Start utterance");
              this.setState((prev) => {
                return {
                  ...prev,
                  isHandleVoice: false,
                };
              });
            },
            onend: () => {
              console.log("End utterance");
            },
            onresume: () => {
              console.log("Resume utterance");
            },
            onboundary: (event) => { },
          },
        })
        .then(() => {
          if (!speech.speaking()) {
            this.audioEle = new Audio(
              "https://soundjay.com/buttons/sounds/beep-01a.mp3"
            );
            this.audioEle.play();
            setTimeout(() => {
              this.props.updateAudioRecordingStatus({
                key: "startPreparationTime",
                value: true,
              });
              // this.setState((prev) => {
              //   return {
              //     ...prev,
              //     startPreparationTime: true,
              //   };
              // });
            }, 1700);
            // }
            return;
          }
        })
        .catch((e) => console.error("An error occurred :", e))
        .finally(() => { });
    }
  }

  componentWillUnmount = () => {
    if (speech.speaking()) {
      speech.cancel();
    }
  };

  render() {
    const { testId, question, index, section, response, onMouseOverEvent } =
      this.props;
    // console.log("checking", question);
    const {
      type,
      options,
      isRequired,
      questionFiles,
      questionLink,
      ansFileType,
      ansLanguageType,
      fileOptionsSize,
      optionsValueType,
      preparation_time,
      answer_time,
      optionType,
      marks,
      coding_description,
      question: questionText,
    } = question;
    const identifier = section.sectionIndex + "," + question.questionIndex;
    let questionFilesBox =
      this.state.questionFilesURL &&
      this.state.questionFilesURL.map((fileurl, index) => {
        const filename = fileurl.split("?")[0];
        const fileType = mime.getType(filename);
        const fileExtn = fileType.split("/")[0];
        if (fileExtn === "image") {
          return (
            <div
              className="flex justify-center items-center w-full"
              key={index}
            >
              <img
                alt="fileUrl"
                className="mb-3"
                style={{ maxWidth: "100%" }}
                title="Question File"
                src={fileurl}
              />
            </div>
          );
        } else if (fileExtn === "video") {
          return (
            <div className="flex w-1/3" key={index}>
              <video controls className="mb-3" style={{ maxWidth: "100%" }}>
                <source src={fileurl} />
              </video>
            </div>
          );
        } else if (fileExtn === "audio") {
          return (
            <div className="p-5 w-full sm:w-2/3 md:w-1/3" key={index}>
              <audio controls className="mb-3" style={{ maxWidth: "100%" }}>
                <source src={fileurl} />
              </audio>
              {/* <CustomAudio audioSrc={fileurl} /> */}
            </div>
          );
        } else return <span key={index}></span>;
      });

    const mcqFilesbox = (index, size) => {
      const { mcqoptionsFiles } = this.state;
      if (mcqoptionsFiles && mcqoptionsFiles[index]) {
        const fileurl = mcqoptionsFiles[index];
        const filename = fileurl.split("?")[0];
        const fileType = mime.getType(filename);
        const fileExtn = fileType.split("/")[0];
        if (fileExtn === "image") {
          return (
            <div className="flex" key={index}>
              <img
                alt="fileUrl"
                className={`h-[${size}px] object-contain`}
                title="Question File"
                src={fileurl}
              />
            </div>
          );
        } else if (fileExtn === "video") {
          return (
            <div className="flex w-1/3" key={index}>
              <video controls style={{ maxWidth: "100%" }}>
                <source src={fileurl} />
              </video>
            </div>
          );
        } else if (fileExtn === "audio") {
          return (
            <div className="p-5 w-1/3" key={index}>
              <audio controls style={{ maxWidth: "100%" }}>
                <source src={fileurl} />
              </audio>

              {/* <CustomAudio audioSrc={fileurl} /> */}
            </div>
          );
        } else return <span key={index}></span>;
      }
    };
    // console.log("type render", response);
    switch (type) {
      case "description":
        questionBox = (
          <>
            {this.props.type === "I" || section.section_type === "G" ? (
              <>
                <div className="flex justify-between py-2">
                  <p
                    className="text-muted mb-1 small"
                    onMouseOver={(e) =>
                      this.props.type === "T" &&
                      onMouseOverEvent(
                        e,
                        `main-white-body-question-default-text-${section.section_type}`
                      )
                    }
                  >
                    Descriptive type question.
                  </p>
                  <div className="flex">
                    <select
                      className="font-['nunito'] block  w-full  text-base  font-normal  text-gray-700  bg-white bg-clip-padding bg-no-repeat  border border-solid border-gray-300  rounded  transition  ease-in-out  m-0  focus:text-gray-700 focus:bg-white focus:border-[#a0db32] focus:outline-none"
                      id="grid-state"
                      onChange={(e) => this.handleLanguage(e.target.value)}
                      value={this.state.currentLanguage}
                      onMouseOver={(e) =>
                        this.props.type === "T" &&
                        onMouseOverEvent(
                          e,
                          `main-white-body-question-language-option`
                        )
                      }
                    >
                      <option value="E">Eng</option>
                      <option value="H">Hin</option>
                    </select>
                  </div>
                </div>
                {/* {console.log("checkingaaaa", questionLink)} */}
                {Boolean(questionLink) && questionLink !== "" ? (
                  <div className="flex flex-col w-full items-center justify-center">
                    <YouTubePlayer
                      key={`YT_${this.props.currentAccordianIndex}_${this.props.currentAccordianPageIndex}`}
                      YTVideoId={questionLink}
                      question={this.props.currentAccordianPageIndex}
                      section={this.props.currentAccordianIndex}
                    />
                  </div>
                ) : null}

                {this.state.questionFilesURL.length ? (
                  <p className="font-medium">Question Files </p>
                ) : null}
                {questionFilesBox}

                {this.state.currentLanguage === "E" ? (
                  <div
                    onMouseOver={(e) =>
                      this.props.type === "T" &&
                      onMouseOverEvent(e, `main-white-body-answer-eng-editor`)
                    }
                  >
                    <MyQuillEditor
                      type={this.props.type}
                      readOnly={false}
                      value={
                        response?.[this.props.section.sectionIndex]?.[
                          this.props.question.questionIndex
                        ]?.text || ""
                      }
                      onResponseChangeDescriptive={(data) =>
                        this.onResponseChangeDescriptive(data)
                      }
                    />
                  </div>
                ) : (
                  <div
                    onMouseOver={(e) =>
                      onMouseOverEvent(e, `main-white-body-answer-hin-editor`)
                    }
                  >
                    <ReactTransliterate
                      renderComponent={(props) => (
                        <textarea
                          className="border-2 w-full rounded-lg p-2"
                          rows="4"
                          {...props}
                        />
                      )}
                      value={
                        response?.[this.props.section.sectionIndex]?.[
                          this.props.question.questionIndex
                        ]?.text
                      }
                      onChangeText={(value) =>
                        this.onResponseChangeDescriptive(value)
                      }
                      lang="hi"
                    />
                  </div>
                )}
                <div className="flex gap-5 float-right">
                  <p
                    className="font-medium text-gray text-sm  font-['poppins']"
                    onMouseOver={(e) =>
                      this.props.type === "T" &&
                      onMouseOverEvent(
                        e,
                        `main-white-body-answer-words-${section.section_type}`
                      )
                    }
                  >
                    Words:{" "}
                    <span className="text-slate-900 text-xs">
                      {this.getWordCount()}
                    </span>
                  </p>
                  <p
                    className="font-medium text-gray text-sm font-['poppins']"
                    onMouseOver={(e) =>
                      this.props.type === "T" &&
                      onMouseOverEvent(
                        e,
                        `main-white-body-answer-char-${section.section_type}`
                      )
                    }
                  >
                    Characters:{" "}
                    <span className="text-slate-900 text-xs">
                      {this.getCharacterCount()}
                    </span>
                  </p>
                </div>
              </>
            ) : (
              <PostgresQuestion type="T" />
            )}
          </>
        );
        break;

      case "sql":
        questionBox = <PostgresQuestion type="T" />;
        break;

      case "coding":
        questionBox = (
          <Main
            testId={testId}
            section={section}
            title={question.question}
            response={this.props.response}
            question={question}
            questionId={question.questionIndex}
            description={coding_description}
            sectionId={section.sectionIndex}
            is_disable_copy_paste={this.props.is_disable_copy_paste}
          />
        );
        break;

      case "integer":
        questionBox = (
          <>
            {this.props.type === "I" || section.section_type === "G" ? (
              <>
                <p className="text-muted mb-1 small">Answer the question.</p>

                <div className="row justify-content-center d-flex">
                  {questionFilesBox}
                </div>
                <div className="flex">
                  <input
                    type="text"
                    placeholder="Your answer here"
                    name="integerAnswer"
                    value={
                      response?.[this.props.section.sectionIndex]?.[
                      this.props.question.questionIndex
                      ]
                    }
                    onChange={(e) => this.onResponseChangeInteger(e)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded focus:ring-blue-500 focus:border-blue-500 block p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                {/* <ReactQuill
                    defaultValue={
                      response?.[this.props.section.sectionIndex]?.[
                        this.props.question.questionIndex
                      ]
                    }
                    onChange={(value) => this.onResponseChangeDescriptive(value)}
                  /> */}
              </>
            ) : (
              <Main
                testId={testId}
                section={section}
                title={question.question}
                questionId={question.questionIndex}
                question={coding_description}
                sectionId={section.sectionIndex}
              />
            )}
          </>
        );
        break;

      case "checkbox":
        questionBox = (
          <>
            {/* <p className="text-muted mb-1 small">Pick the correct option.</p> */}
            <div className="justify-center flex">{questionFilesBox}</div>
            {options.map((data, index) => (
              <div key={index} className="flex items-center mb-3">
                <input
                  className={` ${optionType === "radio"
                    ? "w-4 h-4 mr-2 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    : "w-4 h-4 m-2 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
                    } `}
                  type={optionType}
                  name={identifier}
                  id="checker"
                  checked={
                    response &&
                      response[section.sectionIndex] &&
                      response[section.sectionIndex]?.[question.questionIndex]
                      ? response[section.sectionIndex]?.[
                      question.questionIndex
                      ]?.[data.optionIndex]
                      : this.state.checkValues[data.optionIndex]
                  }
                  value={data.optionIndex}
                  onChange={(e) => this.onResponseChange(e)}
                  onMouseOver={(e) =>
                    this.props.type === "T" &&
                    onMouseOverEvent(
                      e,
                      `main-white-body-answer-${optionType}-${index + 1}-${section.section_type
                      }`
                    )
                  }
                />
                {optionsValueType === "file" ? (
                  <>
                    <div className="flex justify-start">
                      {mcqFilesbox(index, fileOptionsSize)}
                    </div>
                  </>
                ) : (
                  <label
                    className="block text-sm font-medium text-gray-900"
                    dangerouslySetInnerHTML={{
                      __html: data.name,
                    }}
                    onMouseOver={(e) =>
                      this.props.type === "T" &&
                      onMouseOverEvent(
                        e,
                        `main-white-body-answer-text-${index}-${section.section_type}`
                      )
                    }
                  ></label>
                )}
              </div>
            ))}
          </>
        );
        break;

      case "record":
        questionBox = (
          <>
            {/* {this.state.timeLeft <= 5 && (
              <div className="flex w-full items-center justify-center animate-ping absolute ">
                <span className="text-center text-[40px]">
                  {this.state.timeLeft}
                </span>
              </div>
            )} */}
            <div className="flex-col my-2 mb-10">
              <div className="flex justify-center items-center  flex-col">
                {/* Handle voice function called for automatic read out */}
                {this.handleVoice(questionText)}
                {Boolean(questionLink) && questionLink !== "" ? (
                  <div className="flex flex-col items-center justify-center">
                    <YouTubePlayer
                      key={`YT_${this.props.currentAccordianIndex}_${this.props.currentAccordianPageIndex}`}
                      YTVideoId={questionLink}
                      question={this.props.currentAccordianPageIndex}
                      section={this.props.currentAccordianIndex}
                    />
                  </div>
                ) : null}
                <div
                  className={`justify-center flex ${this.props.test?.completedPreparationTime &&
                    "pointer-events-none"
                    } `}
                >
                  {questionFilesBox}
                </div>

                {!Boolean(this.state.answerFilesUrl) &&
                  !this.props.test?.completedPreparationTime &&
                  this.props.test?.startPreparationTime && (
                    <div className="flex-col justify-center items-center text-center">
                      <span className="text-center flex text-md font-bold py-[2px]">
                        Preparation Time
                      </span>
                      <CountDownAnswer
                        totalTime={parseInt(preparation_time)}
                        isCompleted={this.countDownAnsCompleted}
                      />
                    </div>
                  )}
              </div>
              {!Boolean(this.state.answerFilesUrl) ? (
                <div
                  style={{
                    display: this.props.test?.completedPreparationTime
                      ? "block"
                      : "none",
                    pointerEvents: this.props.test?.completedPreparationTime
                      ? "auto"
                      : "none",
                  }}
                >
                  <ReactMediaRecorder
                    video={ansFileType === "Video"}
                    audio={ansFileType === "Video" || ansFileType === "Audio"}
                    askPermissionOnMount
                    onStop={(blobUrl, blob) => {
                      const newBlob = new Blob([blob], {
                        type: "audio/mp3; codecs=0",
                      });
                      const newBlobURL = URL.createObjectURL(newBlob);
                      this.UploadToServer(newBlobURL, newBlob);
                      this.audioRef.current.src = newBlobURL;
                      this.audioRef.current.load();
                      this.audioRef.current.play();
                      this.audioRef.current.muted = true;
                      this.audioRef.current.playbackRate = 16;
                      this.audioRef.current.addEventListener(
                        "ended",
                        (event) => {
                          this.audioRef.current.playbackRate = 1;
                          this.audioRef.current.currentTime = 0;
                          this.audioRef.current.muted = false;
                          this.setState((prev) => {
                            return {
                              ...prev,
                              isAudioLoaded: true,
                            };
                          });
                        }
                      );
                    }}
                    render={({
                      previewStream,
                      status,
                      startRecording,
                      stopRecording,
                      mediaBlobUrl,
                      error,
                      status: errorStatus,
                    }) => {
                      return (
                        <div>
                          <div className="flex flex-col items-center justify-center my-5 gap-3  flex-wrap">
                            {recordStatusMapping[status] === "Recording" && (
                              <>
                                {ansFileType === "Audio" ? (
                                  <div className="boxContainer1 h-[30px]">
                                    <div className="box box1"></div>
                                    <div className="box box2"></div>
                                    <div className="box box3"></div>
                                    <div className="box box4"></div>
                                    <div className="box box5"></div>
                                  </div>
                                ) : (
                                  <VideoPreview stream={previewStream} />
                                )}
                              </>
                            )}
                            <div className="flex items-center gap-3  flex-wrap justify-center">
                              {!this.props.test?.isAnswerTimeCompleted && (
                                <div
                                  className="px-2 py-1 sm:text-[1em] md:text-[1.1em] lg:text-[1.2em] text-cyan-900 shadow-sm shadow-cyan-100 
                              font-semibold border border-[#BCFB46] rounded bg-[#EEFECF] flex gap-2 items-center"
                                >
                                  {recordStatusMapping[status] ===
                                    "Recording" ? null : (
                                    // <PauseIcon
                                    // className="cursor-pointer"
                                    //   color="#ffb242"
                                    // />
                                    <p
                                      onClick={
                                        !this.props.test?.isAnswerTimeCompleted
                                          ? startRecording
                                          : null
                                      }
                                      ref={this.startRecordingRef}
                                    >
                                      <PlayIcon
                                        // className="opacity-50 cursor-pointer"
                                        color="grey"
                                      />
                                    </p>
                                  )}

                                  <p
                                    onClick={() => {
                                      stopRecording();
                                      this.props.updateAudioRecordingStatus({
                                        key: "isAnswerTimeCompleted",
                                        value: true,
                                      });
                                      // this.setState((prev) => {
                                      //   return {
                                      //     ...prev,
                                      //     isAnswerTimeCompleted: true,
                                      //   };
                                      // });
                                    }}
                                    className="cursor-pointer"
                                    ref={this.stopRecordingRef}
                                  >
                                    <StopIcon
                                      color="#ef4444"
                                    // className="cursor-pointer"
                                    />
                                  </p>
                                  {/* Stop Ref can be added */}
                                  {this.props.test
                                    ?.completedPreparationTime && (
                                      <div
                                        className=" text-cyan-900 shadow-sm shadow-cyan-100 px-3
                              font-semibold border border-[#BCFB46] rounded bg-[#EEFECF] flex gap-1"
                                      >
                                        <CountDownResponse
                                          date={parseInt(answer_time)}
                                          stopRecording={() => {
                                            this.stopRecordingRef.current.click();
                                          }}
                                          setIsAnswerTimeCompleted={
                                            () =>
                                              this.props.updateAudioRecordingStatus(
                                                {
                                                  key: "isAnswerTimeCompleted",
                                                  value: true,
                                                }
                                              )
                                            //   this.setState(() => {
                                            //   return {
                                            //     isAnswerTimeCompleted: true,
                                            //   };
                                            // })
                                          }
                                        />
                                      </div>
                                    )}
                                </div>
                              )}
                              {/* <div className="flex gap-6  mr-2 hover:cursor-pointer">
                                  {recordStatusMapping[status] !==
                                  "Recording" ? (
                                    <img
                                      src={record}
                                      className="w-8 h-8 hidden"
                                      alt="Start Recording"
                                      title="Start Recording"
                                      ref={this.startRecordingRef}
                                      onClick={startRecording}
                                    />
                                  ) : (
                                    <img
                                      src={stopRecord}
                                      className="w-8 h-8"
                                      alt="Stop Recording"
                                      title="Stop Recording"
                                      onClick={() => {
                                        stopRecording();
                                        this.handleToast(
                                          "Audio successfully recorded"
                                        );
                                        //setTimeOut for handle next question
                                        setTimeout(() => {
                                          if (
                                            this.props.section.components
                                              .length >
                                            this.props
                                              .currentAccordianPageIndex +
                                              1
                                          ) {
                                            this.props.handleNextQuestion();
                                          } else if (
                                            this.props.sections.length >
                                            this.props.currentAccordianIndex + 1
                                          ) {
                                            this.props.handleNextSection();
                                          } else {
                                            this.props.handleSubmitModal();
                                          }
                                        }, 3000);
                                      }}
                                    />
                                  )}
                                </div> */}
                            </div>
                          </div>
                          <div className=" flex w-full justify-center items-center ">
                            {ansFileType === "Video" && mediaBlobUrl && (
                              <div className="flex flex-col w-full justify-center items-center">
                                <video
                                  width={270}
                                  height={320}
                                  src={
                                    mediaBlobUrl || this.state.answerFilesUrl
                                  }
                                  controls
                                />
                                <div className="bg-orange-400 py-1 px-2 mt-2 rounded text-white text-md font-semibold font-[poppins]">
                                  Your response is recorded
                                </div>
                              </div>
                            )}
                            {ansFileType === "Audio" &&
                              (this.state.answerFilesUrl || mediaBlobUrl) && (
                                <div className="flex flex-col justify-center items-center w-full">
                                  <audio
                                    className={`${this.state.isAudioLoaded
                                      ? "block"
                                      : "hidden"
                                      }`}
                                    style={{ width: "80%" }}
                                    preload="none"
                                    ref={this.audioRef}
                                    src={
                                      this.state.answerFilesUrl || mediaBlobUrl
                                    }
                                    controls
                                    autoPlay={false}
                                  />
                                  <div className="bg-orange-400 py-1 px-2 mt-2 rounded text-white text-md font-semibold font-[poppins]">
                                    Your response is recorded
                                  </div>
                                </div>
                              )}
                            {ansFileType === "audio" &&
                              !this.state.isAudioLoaded &&
                              this.props.test?.isAnswerTimeCompleted && (
                                <div className="animate-spin h-5 w-5 bg-black-400">
                                  <ButtonSpinner />
                                </div>
                              )}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              ) : (
                <div className="flex flex-col w-full items-center justify-center">
                  <div className="w-[50%]">
                    <ReactMediaRecorder
                      video={ansFileType === "Video"}
                      audio={ansFileType === "Audio"}
                      onStop={(blobUrl, blob) => {
                        this.UploadToServer(blobUrl, blob);
                      }}
                      render={({
                        status,
                        startRecording,
                        stopRecording,
                        mediaBlobUrl,
                      }) => (
                        <div>
                          <div className="flex w-full justify-center items-center gap-5 mt-4">
                            <div className="flex w-full justify-center items-center">
                              <div>
                                {ansFileType === "Video" && (
                                  <video
                                    width={270}
                                    height={320}
                                    src={this.state.answerFilesUrl}
                                    controls
                                  />
                                )}
                              </div>
                              {ansFileType === "Audio" &&
                                (mediaBlobUrl || this.state.answerFilesUrl) && (
                                  <div className="flex w-full justify-center items-center pb-2">
                                    <audio
                                      className={`${this.state.isAudioLoaded
                                        ? "block"
                                        : "hidden"
                                        }`}
                                      style={{ width: "80%" }}
                                      preload="none"
                                      ref={this.audioRef}
                                      src={
                                        this.state.answerFilesUrl ||
                                        mediaBlobUrl
                                      }
                                      controls
                                      autoPlay={false}
                                    />
                                  </div>
                                )}
                              {ansFileType === "audio" &&
                                !this.state.isAudioLoaded && (
                                  <div className="animate-spin w-4 h-4 bg-black-400">
                                    <ButtonSpinner />
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                  <div className="bg-orange-400 py-1 px-2 mt-2 rounded text-white text-md font-semibold font-[poppins]">
                    Your response is already recorded
                  </div>
                </div>
              )}
            </div>
          </>
        );
        break;

      case "file":
        questionBox = (
          <>
            <p className="text-muted">
              Upload the required file type. Allowed: {ansFileType}
            </p>
            <div className="justify-center flex py-1">{questionFilesBox}</div>
            <input
              type="file"
              id="img"
              name={identifier}
              accept={acceptFileType[ansFileType]}
              className="hidden"
              onChange={(e) => this.onFilesChange(e)}
            />
            <label
              for="img"
              className="font-normal border-2 py-1 px-3 rounded-lg bg-white text-slate-800 hover:bg-slate-800 hover:text-white cursor-pointer"
            >
              {response &&
                response[section.sectionIndex] &&
                response[section.sectionIndex][question.questionIndex]
                ? "Reupload File"
                : "Upload File"}
            </label>
            <>
              {!this.state.uploadFileLoading ? (
                response &&
                  response[section.sectionIndex] &&
                  response[section.sectionIndex][question.questionIndex] ? (
                  <button
                    className="mx-3 py-1 px-3 rounded bg-cyan-500 text-white font-bold cursor-pointer"
                    onClick={() =>
                      this.setState((prev) => {
                        return {
                          ...prev,
                          fileModal: true,
                        };
                      })
                    }
                  >
                    View File
                  </button>
                ) : (
                  <span className="font-semibold"> No File uploaded</span>
                )
              ) : (
                <img src={load} className="ml-5 inline spinner" />
              )}
            </>
            {/* )} */}
          </>
        );
        break;

      default:
        questionBox = <></>;
    }
    return (
      <>
        {this.props.type === "T" && (
          <div className=" bg-gray-100 px-5 py-4">
            {/* <Modal
            onClose={() =>
              {}
            }
            isModal={this.props.audioResponseModal}
            showCloseButton
            className={"md:w-[50%]"}
          >
            <div className="m-3">
              <p className="p-2 text-center">
                Remember, no more screenshots allowed! If you take one now, your
                test will be submitted automatically. Please honor the rules of
                the test. Good luck, and do your best!
              </p>
            </div>
          </Modal> */}
            <div className="flex font-['nunito'] w-full justify-between">
              <h5
                onMouseOver={(e) =>
                  this.props.type === "T" &&
                  onMouseOverEvent(e, `main-white-body-question-index-${index}`)
                }
              >
                <b>Question {index}.</b>
                {isRequired && <span className="text-danger ml-1">*</span>}
              </h5>
              <span
                className="bg-[#BCFB46] text-md font-['nunito'] text-[#001437] font-semibold py-1 px-2 "
                onMouseOver={(e) =>
                  this.props.type === "T" &&
                  onMouseOverEvent(e, `main-white-body-question-${index}-marks`)
                }
              >
                {marks} Marks
              </span>
            </div>
            {section.section_type === "G" || section.section_type === "PSQL" ? (
              <h5
                dangerouslySetInnerHTML={{
                  __html: question.question,
                }}
                className="font-['nunito']"
                onMouseOver={(e) =>
                  this.props.type === "T" &&
                  onMouseOverEvent(
                    e,
                    `main-white-body-question-text-${index}-${section.section_type}`
                  )
                }
              ></h5>
            ) : null}
          </div>
        )}
        <div className="px-1 lg:px-5 pt-3 pb-10">{questionBox}</div>

        <Modal
          onClose={() =>
            this.setState((prev) => {
              return {
                ...prev,
                fileModal: false,
              };
            })
          }
          isModal={this.state.fileModal}
          showCloseButton
          className="md:h-[80%] h-[45%] w-[92%]   xl:w-[60%]"
        >
          <ViewFileModal file={this.state.answerFilesUrl} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    currentAccordianIndex: test.currentAccordianIndex,
    currentAccordianPageIndex: test.currentAccordianPageIndex,
    isRightSidebar: test.isRightSidebar,
    test: test,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTestResponse: (data) => {
      dispatch(updateTestResponse(data));
    },
    updateInterviewResponseRequested: (data) =>
      dispatch(updateInterviewResponseRequested(data)),
    toggleRightSidebar: (data) => dispatch(toggleRightSidebar(data)),
    updateTestQuestionDataWithKey: (data) =>
      dispatch(updateTestQuestionDataWithKey(data)),
    updateQuestionIndex: (data) => dispatch(updateQuestionIndex(data)),
    updateSectionIndex: (data) => dispatch(updateSectionIndex(data)),
    updateTestAccordianIndex: (data) =>
      dispatch(updateTestAccordianIndex(data)),
    updateTestAccordianPageIndex: (data) =>
      dispatch(updateTestAccordianPageIndex(data)),

    updateAudioRecordingStatus: (data) =>
      dispatch(updateAudioRecordingStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderQuestion);
