import React, { Component } from "react";
import { ReactComponent as Proctor } from "../../assets/img/svg/proctor.svg";
import deletec from "../../assets/img/svg/deletecolor.svg";
import { ReactComponent as InterviewCall } from "../../assets/img/svg/interview_call.svg";
import { connect } from "react-redux";
import TestService, {
  cutOffPassedStudApi,
  generatedetailedsummaryApi,
  sendTestReportsservice,
  updateTestReportService,
} from "../../services/test.service";
import TestHeader from "./createTest/TestHeader";
import { fetchTestSubmissionsCSV } from "../../services/test.service";
import { uploadFile, viewFile } from "../../services/upload.service";
import {
  DeleteResponseRequested,
  fetchTestRequest,
  sendEmailsRequested,
  sendReminderEmailsRequested,
  sendTestInviteEmailsRequested,
  updateTestDeleteModalActive,
  updatingCutoffRequest,
} from "../../store/actions/test";
import DeleteTest from "./createTest/DeleteTest";
import { toast } from "react-toastify";
import { useTable, useSortBy, useFilters } from "react-table";

import BackButton from "../common/BackButton/BackButton";
import Modal from "../common/Modal/Modal";

import InterviewModal from "../modals/InterviewModal";
import { fetchInterviewSubmissionsCSV } from "../../services/interviewpanel.service";
import { defaultToastSetting } from "../../assets/js/toast_config";
import { toggleSidebar } from "../../store/actions/sidebar";
import userService from "../../services/user.service";
import {
  assignEvaluatorService,
  fetchAllEvaluatorsStatus,
} from "../../services/evaluator.service";
import EvaluatorsStatsSlider from "./testReport/EvaluatorSlider/EvaluatorsStatsSlider";
import UniversalButton from "../common/StandardButton/UniversalButton";
import { withRouter } from "../common/WithRouter";
import { ReactComponent as HamburgerIcon } from "../../assets/img/svg/hamburger.svg";
import { ReactComponent as NavBarCloseIcon } from "../../assets/img/svg/navbar_close.svg";
import { ReactComponent as Analytics } from "../../assets/img/svg/Analytics.svg";
import { ReactComponent as Test_Reports } from "../../assets/img/svg/Test_report.svg";
import { ReactComponent as Email } from "../../assets/img/svg/Mail.svg";
import { ReactComponent as Evaluation } from "../../assets/img/svg/Evaluation.svg";
import { Line } from "react-chartjs-2";
import { ConvertTimeToCurrentTimezone } from "../common/utils";
import ResumeLoader from "../../pages/parseResume/ResumeLoader";

import { modules } from "./utils/QuillModules";
import ReactQuill from "react-quill";


class NewAttemptedStudents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cutoffModal: false,
      cutoff: [],
      cutoffFilter: false,
      page: "Mail",
      currentsection: "",
      analytics: {},
      responseId: "",
      responseEmail: "",
      email: {
        subject: "",
        message: "",
      },
      template: {
        title: "",
        remarks: "",
        file: "",
      },
      templateOrMessage: false,
      submission_sidebar: true,

      pageLoading: true,
      messageModal: false,
      sendEmailModal: false,
      interviewModal: false,
      blindEvaluationModal: false,
      evaluatorsList: [],
      setEvaluatorsStats: null,
      evaluators: null,
      isRandom: false,
      isAssignEqually: false,
      isTestInvites: false,
      isTestReports: false,
      studentList: [],
      loading: false,
      formData: {
        college: "",
        cgpa: "",
        prev_employment: "",
        branch: "",
        location: "",
        gender: "",
        is_pwd: "",
      },
      selectedStudent: [],
      selectAll: 0,
      customFiltering: false,
      filter_options: {
        score_from: "",
        score_to: "",
      },
      searchResults: "",
      finalColumns: [
        {
          id: "checkbox",
          accessor: "_id",
          Cell: ({ cell: { value } }) => {
            return (
              <input
                type="checkbox"
                className="checkbox"
                checked={this.state.selectedStudent.includes(value)}
                onChange={(e) => this.toggleRow(value, e)}
              />
            );
          },
          Header: (x) => {
            return (
              <input
                type="checkbox"
                className="checkbox"
                checked={this.state.selectAll === 1}
                ref={(input) => {
                  if (input) {
                    input.indeterminate = this.state.selectAll === 2;
                  }
                }}
                onChange={() => this.toggleSelectAll()}
              />
            );
          },
          disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: "S.No",
          accessor: "",
          Cell: ({ cell: { row } }) => <p>{row.index + 1}</p>,
          disableFilters: true,
        },
        {
          Header: "Name",
          accessor: "",
          Cell: ({ cell: { row } }) => (
            <p>
              {row.original?.name
                ? row.original?.name
                : row.original?.user?.name}
            </p>
          ),
          disableSortBy: true,
          filter: "fuzzyText",
        },
        {
          Header: "Email",
          accessor: "email",
          Cell: ({ cell: { row } }) => (
            <p>
              {row.original?.email
                ? row?.original?.email
                : row?.original?.user?.email}
            </p>
          ),
          disableSortBy: true,
        },

        // {
        //   Header: "Rating",
        //   accessor: "",
        //   disableFilters: true,
        //   Cell: (props) => (
        //     <div>
        //       {props?.row?.original.rating ? (
        //         <span
        //           className="inline-flex items-center justify-center sm:px-2 px-1 py-1
        //              sm:text-xs text-[7px] font-bold leading-none text-yellow-100
        //              bg-yellow-700 rounded mr-1"
        //         >
        //           {props?.row?.original.rating}
        //         </span>
        //       ) : null}
        //       {" "}
        //       -
        //       {props?.row?.original.proctor_rating ? (
        //         <span
        //           className="inline-flex items-center justify-center sm:px-2 px-1 py-1
        //              sm:text-xs text-[7px] font-bold leading-none text-yellow-100
        //              bg-yellow-700 rounded mr-1"
        //         >
        //           {props?.row?.original.proctor_rating}
        //         </span>
        //       ) : null}
        //     </div>
        //   ),
        // },
      ],
      columns: [],
    };
    this.toggleRow = this.toggleRow.bind(this);
    this.editorRef = React.createRef(null);
    this.handleChangeScoreFrom = this.handleChangeScoreFrom.bind(this);
    this.handleChangeScoreTo = this.handleChangeScoreTo.bind(this);
  }

  componentDidMount() {
    if (this.props.sidebar.opened) {
      this.props.toggleSidebar();
    }
    const testId = this.props.params.id;
    this.setState({ pageLoading: true });
    fetchAllEvaluatorsStatus({ id: testId })
      .then((res) => {
        if (res.success) {
          this.setState((prev) => {
            return {
              ...prev,
              setEvaluatorsStats: res.data,
            };
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    TestService.getAssignedUsers({ quiz: testId }).then(
      (response) => {
        this.setState({ studentList: response.data });
        this.setState({ pageLoading: false });
      },
      (error) => {
        console.log(error);
      }
    );

    this.props.fetchTestRequest(this.props.params.id);

    this.setState((prev) => {
      return {
        ...prev,
        columns: [
          ...this.state.finalColumns,
          {
            Header: "Score",
            // accessor: "",
            accessor: (row) =>
              row.marks >= 0 ? row.marks.toFixed(2) : "Not Attempted",
          },
          {
            Header: "Grade",
            // accessor: "",
            accessor: (row) => (row.grade ? row.grade : "-"),
            disableSortBy: true,
          },
          {
            Header: "Actions",
            accessor: "action",
            disableFilters: true,
            Cell: (props) => (
              <CustomActions
                response={props?.row?.original}
                props={this.props}
                deleteResponse={this.handleDeleteResponse}
                updateInterviewModal={this.updateInterviewModal}
              />
            ),
            disableSortBy: true,
          },
        ],
      };
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.blindEvaluationModal && this.state.evaluators === null) {
      userService
        .getAllUsers({ type: "E" })
        .then((res) => {
          this.setState((prev) => {
            return {
              ...prev,
              evaluators: res.data,
            };
          });
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.successMessage !== this.props.successMessage) {
  //     this.handleToast(this.props.successMessage);
  //     // const timer = () =>
  //     //   setTimeout(() => {
  //     //     this.props.history("/tests");
  //     //   }, 3000);
  //     // timer();
  //     // return () => clearTimeout(timer);
  //   } else if (prevProps.errorMessage !== this.props.errorMessage) {
  //     this.handleToastWarning(this.props.errorMessage);
  //     // const timer = () =>
  //     //   setTimeout(() => {
  //     //     this.props.history("/tests");
  //     //   }, 3000);
  //     // timer();
  //     // return () => clearTimeout(timer);
  //   }
  // }

  handleToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  handleToastWarning = (msg) => {
    toast.warning(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  handleChangeScoreFrom = (e) => {
    this.setState((prev) => {
      return {
        filter_options: {
          score_to: prev.filter_options.score_to,
          score_from: e.target.value,
        },
      };
    });
  };
  handleChangeScoreTo = (e) => {
    this.setState((prev) => {
      return {
        filter_options: {
          score_from: prev.filter_options.score_from,
          score_to: e.target.value,
        },
      };
    });
  };

  toggleRow(value, e) {
    let newSelected = [];
    if (e.target.checked) {
      newSelected = [...this.state.selectedStudent, value];
    } else {
      newSelected = this.state.selectedStudent.filter(
        (studentId) => studentId !== value
      );
    }
    this.setState({
      selectedStudent: newSelected,
    });
  }

  toggleSelectAll() {
    let newSelected = [];

    if (this.state.selectAll === 0) {
      this.state.studentList.forEach((student) => {
        newSelected.push(student["_id"]);
      });
    }
    this.setState({
      selectedStudent: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
    });
  }

  downloadCSVReport = () => {
    const testId = this.props.params.id;
    fetchTestSubmissionsCSV({ ids: [testId] })
      .then(async (data) => {
        const fileURL = await viewFile(data.csv_file, "TEMPORARY");
        window.open(fileURL);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.msg, defaultToastSetting);
      });
  };

  downloadInterviewCSVReport = () => {
    const testId = this.props.params.id;
    fetchInterviewSubmissionsCSV({ ids: [testId] }).then(async (data) => {
      const fileURL = await viewFile(data.csv_file, "TEMPORARY");
      window.open(fileURL);
    });
  };

  handleMessageModal = () => {
    const { selectedStudent } = this.state;
    if (selectedStudent.length !== 0) {
      this.setState((prev) => {
        return {
          messageModal: true,
        };
      });
    } else {
      this.handleToastWarning("Students not selected");
    }
  };

  handleSendEmails = (e) => {
    const { selectedStudent, email, template, templateOrMessage } = this.state;
    const { id } = this.props.params;
    if (this.checkQuizIDs()) {
      if (email.message !== "" || template.file !== "") {
        this.props.sendEmailsRequested({
          assign_ids: selectedStudent,
          subject: email.subject,
          quiz: id,
          ...(templateOrMessage ? { template_url: template.file } : { message: email.message }),
        });
        this.setState((prev) => {
          return {
            messageModal: false,
          };
        });
      } else {
        this.handleToastWarning("Message should not be empty");
      }
    }
  };

  handleDeleteResponse = (responseId) => {
    this.setState({ responseId: responseId });
    this.props.updateTestDeleteModalActive(true);
  };

  handleSearch = () => {
    const filter = this.state.filter_options;
    const testId = this.props.params.id;
    this.setState((prevState) => {
      return { ...prevState, pageLoading: true };
    });

    TestService.getAssignedUsers({ quiz: testId, ...filter }).then(
      (res) => {
        this.setState({ studentList: res.data });
        this.setState({ pageLoading: false });
        if (res.data.length)
          this.setState({
            searchResults: "",
          });
        else
          this.setState({
            searchResults: "None of the students according to your search",
          });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  handleSendTestInviteEmails = () => {
    if (this.checkQuizIDs()) {
      this.props.sendTestInviteEmailsRequested({
        quiz_id: this.props.params.id,
        assign_ids: [...this.state.selectedStudent],
      });
      this.setState((prev) => {
        return {
          ...prev,
          isTestInvites: false,
          isTestReports: false,
          sendEmailModal: false,
        };
      });
    }
  };

  handleSendTestReminderEmails = () => {
    if (this.checkQuizIDs()) {
      this.props.sendReminderEmailsRequested({
        quiz_id: this.props.params.id,
        assign_ids: [...this.state.selectedStudent],
      });
      this.setState((prev) => {
        return {
          ...prev,
          isTestInvites: false,
          sendEmailModal: false,
        };
      });
    }
  };

  generateTestReport = () => {
    const quiz = this.props.params.id;
    updateTestReportService({ quiz })
      .then((res) => {
        if (res.success) {
          toast.success("Report Generated Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  handleSendEmailModal = (type) => {
    const { selectedStudent } = this.state;
    if (selectedStudent.length !== 0) {
      this.setState({ sendEmailModal: true });
      if (type === "invites") this.setState({ isTestInvites: true });
      if (type === "reports") this.setState({ isTestReports: true });
    } else {
      this.handleToastWarning("Students not selected");
    }
  };

  blindEvaluation = () => {
    const { selectedStudent } = this.state;
    if (selectedStudent.length !== 0) {
      this.setState((prev) => {
        return {
          ...prev,
          blindEvaluationModal: !prev.blindEvaluationModal,
        };
      });
    } else {
      toast.warning("Students not selected", defaultToastSetting);
    }
  };

  addEvaluator = () => {
    this.setState(
      (prev) => {
        return {
          ...prev,
          evaluatorsList: [
            ...prev.evaluatorsList,
            { evaluatorId: "", NumberOfReports: 0 },
          ],
        };
      },
      () =>
        this.state.isAssignEqually
          ? this.divideEvaluatorsEqually(this.state.evaluatorsList)
          : {}
    );
  };

  isValidate = () => {
    const { evaluatorsList } = this.state;
    var sum = 0;
    for (let i = 0; i < evaluatorsList.length; i++) {
      sum += parseInt(evaluatorsList[i].NumberOfReports);
      if (
        evaluatorsList[i].evaluatorId === "" ||
        evaluatorsList[i].NumberOfReports <= 0
      ) {
        toast.warning("No field should be empty !", defaultToastSetting);
        return false;
      }
    }
    if (sum !== this.state.selectedStudent.length) {
      toast.warning(
        "Selected students didn't match with the assigns.",
        defaultToastSetting
      );
      return false;
    }
    return true;
  };

  divideEvaluatorsEqually = (evaluators) => {
    const result = [];

    let totalStudents = this.state.selectedStudent.length;

    // Initialize the result array
    const studentsPerEvaluator = Math.floor(totalStudents / evaluators?.length);

    // Distribute students equally among evaluators
    for (const evaluator of evaluators) {
      result.push({
        evaluatorId: evaluator._id || evaluator.evaluatorId,
        NumberOfReports: studentsPerEvaluator,
      });
    }

    // If there are any remaining students, distribute them one by one among evaluators
    const remainingStudents = totalStudents % evaluators.length;
    for (let i = 0; i < remainingStudents; i++) {
      result[i].NumberOfReports++;
    }

    console.log("res", result);
    this.setState((prev) => {
      return {
        ...prev,
        evaluatorsList: [...result],
      };
    });
  };

  handleEqualAssignment = async () => {
    const { isAssignEqually } = this.state;
    if (!isAssignEqually) {
      const evaluators =
        this.state.evaluatorsList?.length > 0
          ? [...this.state.evaluatorsList]
          : [...this.state.evaluators];
      this.divideEvaluatorsEqually(evaluators);
    }
    this.setState((prev) => {
      return {
        ...prev,
        isAssignEqually: !prev.isAssignEqually,
      };
    });
  };

  assignEvaluators = () => {
    if (this.isValidate()) {
      this.setState((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      assignEvaluatorService({
        quiz_id: this.props.params.id,
        isRandom: this.state.isRandom,
        reportassigns: this.state.selectedStudent,
        evaluatorsList: this.state.evaluatorsList,
      })
        .then((res) => {
          if (res.success) {
            this.setState((prev) => {
              return {
                ...prev,
                loading: false,
              };
            });
            toast.success(res.message, defaultToastSetting);
            setTimeout(() => window.location.reload(), 3000);
          }
        })
        .catch((err) => {
          this.setState((prev) => {
            return {
              ...prev,
              loading: false,
            };
          });
          toast.warning(err.message, defaultToastSetting);
        });
    }
  };

  onChangeEvaluator = (e, index, key) => {
    this.setState((prev) => {
      const temp = [...prev.evaluatorsList];
      temp[index] = { ...temp[index], [key]: e.target.value };
      return {
        ...prev,
        evaluatorsList: temp,
      };
    });
  };

  onDeleteEvaluator = (index) => {
    this.setState(
      (prev) => {
        const temp = [...prev.evaluatorsList];
        temp.splice(index, 1);
        return {
          ...prev,
          evaluatorsList: temp,
        };
      },
      () =>
        this.state.isAssignEqually
          ? this.divideEvaluatorsEqually(this.state.evaluatorsList)
          : {}
    );
  };

  toggleCustomFilter = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        customFiltering: !prevState.customFiltering,
      };
    });
  };

  checkQuizIDs = () => {
    if (this.state.selectedStudent.length <= 0) {
      toast.warning("No student selected", defaultToastSetting);
      return false;
    }
    return true;
  };

  sendTestReports = () => {
    if (this.checkQuizIDs()) {
      sendTestReportsservice({
        quiz_id: this.props.params.id,
        assign_ids: [...this.state.selectedStudent],
      })
        .then((res) => {
          toast.success("Reports sent Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((e) =>
          toast.error("Failed to send reports.", {
            position: toast.POSITION.TOP_RIGHT,
          })
        );
      this.setState((prev) => {
        return {
          ...prev,
          isTestInvites: false,
          isTestReports: false,
          sendEmailModal: false,
        };
      });
    }
  };

  updateInterviewModal = ({ value, response, email }) => {
    this.setState(
      (prev) => {
        return {
          ...prev,
          responseId: response,
          responseEmail: email,
        };
      },
      () => {
        this.setState((prev) => {
          return {
            ...prev,
            interviewModal: value,
          };
        });
      }
    );
  };

  showTime = (tm) => {
    return ConvertTimeToCurrentTimezone(tm).format("dddd, MMMM D YYYY, h:mm a");
  };

  renderComponent = () => {
    switch (this.state.page) {
      case "Analytics":
        return (
          <>
            {this.state.searchResults ? (
              <p className="w-full font-['nunito'] text-center h-[70vh] flex items-center justify-center text-[1.4rem] font-bold">
                {this.state.searchResults}
              </p>
            ) : (
              <div className="w-full overflow-hidden">
                <h1 className="font-['nunito'] font-bold text-[1.6rem] pt-2">
                  Analytics
                </h1>
                <div className="flex gap-4 mb-4 mt-0.5 w-full justify-stretch">
                  <div className="border-[2px] flex-1 rounded-md p-3">
                    <h1 className="font-['nunito'] font-semibold text-[1.2rem] mb-3">
                      Test Details
                    </h1>
                    <h1 className="font-['nunito'] flex font-semibold text-[1rem]">
                      <span className="w-[8rem] block">Test Name </span> :{" "}
                      {this.props.test?.title}
                    </h1>
                    <h1 className="font-['nunito'] flex font-semibold text-[1rem]">
                      <span className="w-[8rem] block">Company </span> :{" "}
                      {this.props.test?.company?.name}
                    </h1>
                    <h1 className="font-['nunito'] flex font-semibold text-[1rem]">
                      <span className="w-[8rem] block">Section </span> :{" "}
                      {this.props.test?.components?.length}
                    </h1>

                    <h1 className="font-['nunito'] flex font-semibold text-[1rem]">
                      <span className="w-[8rem] block">Start Duration </span> :{" "}
                      {this.showTime(
                        this.props.test?.start_duration?.toLocaleString()
                      )}
                    </h1>
                    <h1 className="font-['nunito'] flex font-semibold text-[1rem]">
                      <span className="w-[8rem] block">End Duration </span> :{" "}
                      {this.showTime(
                        this.props.test?.end_duration?.toLocaleString()
                      )}
                    </h1>
                  </div>

                  <div className="grid flex-1 grid-cols-2 gap-2">
                    <div className="relative rounded-md bg-[#CF7A64]/[.26] w-[12.5rem] h-[5rem] font-['nunito']">
                      <p className="absolute top-2 left-2 text-[1rem] font-semibold text-[#CF7A64]">
                        Total Students
                      </p>
                      <p className="absolute bottom-2 font-bold right-2 text-[1.4rem] text-[#CF7A64]">
                        {this.state.analytics.students_assigned}
                      </p>
                    </div>
                    <div className="relative rounded-md bg-[#94E700]/[.24] w-[12.5rem] h-[5rem] font-['nunito']">
                      <p className="absolute top-2 left-2 text-[1rem] font-semibold text-[#94E700]">
                        Attempted Students
                      </p>
                      <p className="absolute bottom-2 font-bold right-2 text-[1.4rem] text-[#94E700]">
                        {this.state.analytics.students_attempted}
                      </p>
                    </div>
                    <div className="relative rounded-md bg-[#FF0000]/[.23] w-[12.5rem] h-[5rem] font-['nunito']">
                      <p className="absolute top-2 left-2 text-[1rem] font-semibold text-[#FF0000]">
                        Absent Students
                      </p>
                      <p className="absolute bottom-2 font-bold right-2 text-[1.4rem] text-[#FF0000]">
                        {this.state.analytics.not_attempted}
                      </p>
                    </div>
                    <div className="relative rounded-md bg-[#EB996E]/[.39] w-[12.5rem] h-[5rem] font-['nunito']">
                      <p className="absolute top-2 left-2 text-[1rem] font-semibold text-[#EB996E]">
                        Total Score
                      </p>
                      <p className="absolute bottom-2 font-bold right-2 text-[1.4rem] text-[#EB996E]">
                        {this.state.analytics.total_score}
                      </p>
                    </div>
                  </div>

                  <div className="border-[2px] w-[20rem] rounded-md px-3 pt-2">
                    <h1 className="font-['nunito'] font-semibold text-[1.2rem] mb-2">
                      Score
                    </h1>
                    <div className="flex font-['nunito'] justify-between items-center mb-0.5">
                      <p>min score:</p>
                      <p className="text-[#FF0000]">
                        {this.state.analytics.min_score}
                      </p>
                      <svg
                        viewBox="-20 -20 60 60"
                        height="35"
                        width="40"
                        style={{ transform: "scale(1.5)" }}
                      >
                        <path
                          className="fill-none stroke-[#eee] stroke-[8px]"
                          d="M12.5 1.7366
      a 10.7634 10.7634 0 0 1 0 21.5268
      a 10.7634 10.7634 0 0 1 0 -21.5268
      Z"
                        />
                        <path
                          style={{ strokeLinecap: "round" }}
                          className="fill-none stroke-[#FF0000] stroke-[8px]"
                          strokeDasharray={`${this.state.analytics.min_score}, 100`}
                          d="M12.5 1.7366
                          a 10.7634 10.7634 0 0 1 0 21.5268
                          a 10.7634 10.7634 0 0 1 0 -21.5268
                          Z"
                        />
                      </svg>
                    </div>
                    <div className="flex font-['nunito'] justify-between items-center mb-0.5">
                      <p>mid score:</p>
                      <p className="text-[#FFA800]">
                        {this.state.analytics.mean_score}
                      </p>
                      <svg
                        viewBox="-20 -20 60 60"
                        height="35"
                        width="40"
                        style={{ transform: "scale(1.5)" }}
                      >
                        <path
                          className="fill-none stroke-[#eee] stroke-[8px]"
                          d="M12.5 1.7366
                          a 10.7634 10.7634 0 0 1 0 21.5268
                          a 10.7634 10.7634 0 0 1 0 -21.5268
                          Z"
                        />
                        <path
                          style={{ strokeLinecap: "round" }}
                          className="fill-none stroke-[#FFA800] stroke-[8px]"
                          strokeDasharray={`${this.state.analytics.mean_score}, 100`}
                          d="M12.5 1.7366
                          a 10.7634 10.7634 0 0 1 0 21.5268
                          a 10.7634 10.7634 0 0 1 0 -21.5268
                          Z"
                        />
                      </svg>
                    </div>
                    <div className="flex font-['nunito'] justify-between items-center mb-0.5">
                      <p>max score:</p>
                      <p className="text-[#6CC200]">
                        {this.state.analytics.max_score}
                      </p>
                      <svg
                        viewBox="-20 -20 60 60"
                        height="35"
                        width="40"
                        style={{ transform: "scale(1.5)" }}
                      >
                        <path
                          className="fill-none stroke-[#eee] stroke-[8px]"
                          d="M12.5 1.7366
                          a 10.7634 10.7634 0 0 1 0 21.5268
                          a 10.7634 10.7634 0 0 1 0 -21.5268
                          Z"
                        />
                        <path
                          style={{ strokeLinecap: "round" }}
                          className="fill-none stroke-[#6CC200] stroke-[8px]"
                          strokeDasharray={`${this.state.analytics.max_score}, 100`}
                          d="M12.5 1.7366
                          a 10.7634 10.7634 0 0 1 0 21.5268
                          a 10.7634 10.7634 0 0 1 0 -21.5268
                          Z"
                        />
                      </svg>
                    </div>
                    <div className="flex font-['nunito'] justify-between items-center mb-0.5">
                      <p>Avg score:</p>
                      <p className="text-[#FFE500]">
                        {this.state.analytics.mean_score}
                      </p>
                      <svg
                        viewBox="-20 -20 60 60"
                        height="35"
                        width="40"
                        style={{ transform: "scale(1.5)" }}
                      >
                        <path
                          className="fill-none stroke-[#eee] stroke-[8px]"
                          d="M12.5 1.7366
                      a 10.7634 10.7634 0 0 1 0 21.5268
                      a 10.7634 10.7634 0 0 1 0 -21.5268
                      Z"
                        />
                        <path
                          style={{ strokeLinecap: "round" }}
                          className="fill-none stroke-[#FFE500] stroke-[8px]"
                          strokeDasharray={`${this.state.analytics.mean_score}, 100`}
                          d="M12.5 1.7366
                      a 10.7634 10.7634 0 0 1 0 21.5268
                      a 10.7634 10.7634 0 0 1 0 -21.5268
                      Z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                {/* <div className="flex  justify-between items-start"> */}
                <div className="flex flex-col">
                  <p className="text-md font-['poppins'] font-medium pb-2">
                    Choose Section :
                  </p>
                  <select
                    onChange={(e) =>
                      this.setState({ currentsection: e.target.value })
                    }
                    value={this.state.currentsection}
                    className=" rounded font-['poppins'] border w-[21rem] border-gray-300 outline-gray-300 p-1"
                  >
                    <option value="">Select Section</option>
                    {this.props.test.components?.map(
                      (section, sectionIndex) => (
                        <option
                          value={sectionIndex}
                          key={`section-${sectionIndex}`}
                        >
                          {section.title}
                        </option>
                      )
                    )}
                  </select>
                </div>

                <div className="flex gap-4 justify-between items-start h-full mt-5">
                  <div className="h-[16rem] w-[28rem]">
                    <Line
                      data={{
                        labels: [
                          ...Object.keys(this.state.analytics?.bins ?? {}),
                        ],
                        datasets: [
                          {
                            // label: "Dataset 1",
                            data: [
                              ...Object.values(
                                this.state.analytics?.bins ?? {}
                              ),
                            ],
                            borderColor: "#C6FB63",
                            backgroundColor: "rgba(255, 99, 132, 0.5)",
                          },
                        ],
                      }}
                      options={{
                        scales: {
                          y: {
                            title: {
                              display: true,
                              text: "Number of students",
                            },
                          },
                          x: {
                            title: {
                              display: true,
                              text: "Normalised score",
                            },
                          },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          // title: {
                          //   display: true,
                          //   text: "Users Gained between 2016-2020",
                          // },
                          legend: {
                            display: false,
                          },
                        },
                      }}
                    />
                  </div>

                  <div className="border-[2px] max-h-[16rem] scrollbar w-[35rem] p-2 mt-2 overflow-y-auto">
                    <h1 className="font-['nunito'] font-bold text-[1.6rem] mb-3">
                      {this.state.currentsection ? "Question" : "Section"}{" "}
                      Analytics
                    </h1>
                    <div className="flex justify-between items-center gap-2 mb-2">
                      <p className="font-normal text-center py-0.5 rounded px-1 bg-[#A1F800]/[0.26] text-[#648529] text-[1rem]">
                        Correctly Answered
                      </p>
                      <p className="font-normal text-center py-0.5 rounded px-1 text-[1rem] bg-[#FF0000]/[0.26] text-[#FF0000]">
                        Incorrectly Answered
                      </p>
                      <p className="font-normal text-center py-0.5 rounded px-1 text-[1rem] bg-[#EB996E]/[.39] text-[#94512d]">
                        Unattempted Answered
                      </p>
                    </div>
                    {this.state.currentsection
                      ? this.props.test.components[
                        this.state.currentsection
                      ].components.map((q, ind) => (
                        <div className="flex justify-between gap-5 mb-1 bg-[#F8F8F8] rounded p-2 font-['nunito']">
                          <p className="font-normal w-[20rem] text-[1.2rem]">
                            Question {ind + 1}.
                          </p>
                          <div className="flex gap-2 items-center">
                            <p className="font-normal text-center pt-0.5 rounded px-0.5 bg-[#A1F800]/[0.26] text-[#648529] text-[1rem]">
                              {this.state.analytics?.statistics?.[
                                this.props.test.components[
                                  this.state.currentsection
                                ].title
                              ]?.Questions?.[ind][
                                "Correctly Answered Students"
                              ].toFixed(1)}
                              %
                            </p>
                            <p className="font-normal text-center pt-0.5 rounded px-0.5 text-[1rem] bg-[#FF0000]/[0.26] text-[#FF0000]">
                              {this.state.analytics?.statistics?.[
                                this.props.test.components[
                                  this.state.currentsection
                                ].title
                              ]?.Questions?.[ind][
                                "Incorrectly Answered Students"
                              ].toFixed(1)}
                              %
                            </p>
                            <p className="font-normal text-center pt-0.5 rounded px-0.5 text-[1rem] bg-[#EB996E]/[.39] text-[#94512d]">
                              {this.state.analytics?.statistics?.[
                                this.props.test.components[
                                  this.state.currentsection
                                ].title
                              ]?.Questions?.[ind][
                                "Not Attempted Students"
                              ].toFixed(1)}
                              %
                            </p>
                          </div>
                        </div>
                      ))
                      : this.props.test.components.map((sc, ind) => (
                        <div className="flex justify-between gap-5 mb-1 bg-[#F8F8F8] rounded p-2 font-['nunito']">
                          <p className="font-normal w-[20rem] text-[1.2rem]">
                            {sc.title}
                          </p>
                          <div className="flex gap-2 items-center">
                            <p className="font-normal text-center pt-0.5 rounded px-0.5 bg-[#A1F800]/[0.26] text-[#648529] text-[1rem]">
                              {this.state.analytics?.statistics?.[sc.title][
                                "Correctly Answered Students"
                              ].toFixed(1)}
                              %
                            </p>
                            <p className="font-normal text-center pt-0.5 rounded px-0.5 text-[1rem] bg-[#FF0000]/[0.26] text-[#FF0000]">
                              {this.state.analytics?.statistics?.[sc.title][
                                "Incorrectly Answered Students"
                              ].toFixed(1)}
                              %
                            </p>
                            <p className="font-normal text-center pt-0.5 rounded px-0.5 text-[1rem] bg-[#EB996E]/[.39] text-[#94512d]">
                              {this.state.analytics?.statistics?.[sc.title][
                                "Not Attempted Students"
                              ].toFixed(1)}
                              %
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                  {/* </div> */}
                </div>
              </div>
            )}
          </>
        );

      default:
        return (
          <>
            {this.state.studentList.length ? (
              <div className="grid lg:grid-cols-4 gap-5 h-full scrollbar overflow-y-auto overflow-x-hidden ">
                <div
                  className={`w-full font-['nunito'] mb-4 ${this.state.customFiltering ? "col-span-3" : " col-span-4"
                    }`}
                >
                  <Table
                    columns={this.state.columns}
                    data={this.state.studentList}
                  />
                </div>
              </div>
            ) : !this.state.searchResults ? (
              <p className="w-full font-['nunito'] text-center h-[70vh] flex items-center justify-center text-[1.4rem] font-bold">
                As of now, none of the students have been assigned.
              </p>
            ) : (
              <p className="w-full font-['nunito'] text-center h-[70vh] flex items-center justify-center text-[1.4rem] font-bold">
                {this.state.searchResults}
              </p>
            )}
          </>
        );
    }
  };

  cutOffFiltering = () => {
    this.setState({ pageLoading: true });
    cutOffPassedStudApi(this.props.params.id)
      .then((res) => {
        if (res.data.length)
          this.setState({
            searchResults: "",
            studentList: res.data,
          });
        else
          this.setState({
            studentList: [],
            searchResults: "None of the students passed the cutoff",
          });
      })
      .catch((e) => toast.error(e.message, defaultToastSetting))
      .finally(() => this.setState({ pageLoading: false }));
  };

  changingPage = (pg) => {
    this.setState({ pageLoading: true, searchResults: "" });
    if (pg !== "Analytics") {
      TestService.getAssignedUsers({ quiz: this.props.params.id })
        .then((response) => this.setState({ studentList: response.data }))
        .catch((error) => console.log(error))
        .finally(() => this.setState({ pageLoading: false }));
    } else {
      generatedetailedsummaryApi([this.props.params.id])
        .then((res) => {
          if (Object.keys(res).length) this.setState({ analytics: res });
          else this.setState({ searchResults: "No Analytics present" });
        })
        .catch((err) => {
          console.log(err.message);
          toast.error(err.message, defaultToastSetting);
          this.setState({ searchResults: err.message });
        })
        .finally(() => this.setState({ pageLoading: false }));
    }

    this.setState({ page: pg });
  };

  getUrl(filename) {
    if (filename && filename.length) {
      viewFile(filename, "EMAIL-TEMPLATES").then((res) => {
        window.open(res);
      });
    }
  }

  changeTemplateFile(event) {
    uploadFile(event.target.files[0], {
      type: "EMAIL-TEMPLATES",
      id: "html-template",
    })
      .then((filename) => {
        let new_form_data = { ...this.state.template };
        new_form_data["file"] = filename;
        this.setState({ template: new_form_data });
      })
      .catch((e) => console.log("error", e));
  }

  imageHandler = (a) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];

      // file type is only image.
      if (/^image\//.test(file.type)) {
        this.saveToServer(file);
      } else {
        console.warn("You could only upload images.");
      }
    };
  };

  saveToServer(file) {
    uploadFile(file, {
      type: "TEMPORARY",
      id: this.props.params.id,
    })
      .then(async (filename) => {
        const value = filename;
        const fileURL = await viewFile(value, "TEMPORARY");
        console.log(fileURL)
        this.editorRef.current.getEditor().insertEmbed(null, "image", fileURL);
      })
      .catch((e) => console.log("File uploading", e))

  }

  render() {
    const { college, prev_employment, location, branch, cgpa, gender, is_pwd } =
      this.state.formData;
    console.log(this.state.email);

    return (
      <div className="overflow-hidden w-full sm:p-5">
        <Modal
          onClose={this.blindEvaluation}
          isModal={this.state.blindEvaluationModal}
          showCloseButton
          className={"font-['nunito'] md:w-[43%]"}
        >
          <div className="flex items-center justify-between pt-10 pb-2">
            <h1>Assign Evaluators</h1>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={this.addEvaluator}
            >
              Add Evaluator
            </button>
          </div>
          <div className="flex min-h-[5rem] gap-2 flex-col w-full justify-center items-center">
            {this.state.evaluatorsList.length ? (
              <>
                {this.state.evaluatorsList.map((evaluator, index) => (
                  <div className="flex items-center justify-start  w-full">
                    <select
                      onChange={(e) =>
                        this.onChangeEvaluator(e, index, "evaluatorId")
                      }
                      value={evaluator.evaluatorId}
                      id={`evaluator-${index}`}
                      className="block mr-2 w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-teal-500 focus:border-blur-500"
                    >
                      <option>Choose evaluator</option>
                      {this.state.evaluators !== null &&
                        this.state.evaluators.map((evaluator, index) => (
                          <option key={evaluator._id} value={evaluator._id}>
                            {evaluator.name}
                          </option>
                        ))}
                    </select>
                    <input
                      type="number"
                      placeholder="Number of reports"
                      min="1"
                      value={evaluator.NumberOfReports}
                      onChange={(e) =>
                        this.onChangeEvaluator(e, index, "NumberOfReports")
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                    />
                    <img
                      alt="delete"
                      src={deletec}
                      title="Delete Response"
                      className="ml-2 sm:w-5 sm:h-5 w-4 h-4 hoverPointer"
                      onClick={() => {
                        this.onDeleteEvaluator(index);
                      }}
                    />
                  </div>
                ))}
              </>
            ) : (
              <>No evaluators found</>
            )}
          </div>
          <div className="flex justify-between">
            {this.state.evaluatorsList.length > 0 && (
              <button
                className="my-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={this.assignEvaluators}
                disabled={this.state.loading}
              >
                Assign
              </button>
            )}
            <div className="flex ml-auto gap-x-3">
              <div className="flex gap-2 justify-center items-center">
                <input
                  type="checkbox"
                  id="isRandom"
                  name="isRandom"
                  className="outline-none bg-none"
                  checked={this.state.isRandom}
                  onChange={() =>
                    this.setState((prev) => {
                      return {
                        ...prev,
                        isRandom: !prev.isRandom,
                      };
                    })
                  }
                />
                <label className="">Assign Randomly</label>
              </div>
              <div className="flex gap-2 justify-center items-center">
                <input
                  type="checkbox"
                  id="assignEqually"
                  name="assignEqually"
                  className="outline-none bg-none"
                  checked={this.state.isAssignEqually}
                  onChange={() => {
                    this.handleEqualAssignment();
                  }}
                />
                <label className="">Assign Equally</label>
              </div>
            </div>
          </div>
        </Modal>

        <DeleteTest
          testId={this.state.responseId}
          title="response"
          handleToast={this.handleToastWarning}
        />

        {/* <pre>{JSON.stringify(this.state.formData, 0, null)}</pre> */}
        <Modal
          onClose={() => this.setState({ messageModal: false })}
          isModal={this.state.messageModal}
          showCloseButton
          className={"font-['nunito'] w-[92%] sm:w-[80%] md:w-[65%] lg:w-[52%]"}>
          <div className="w-full mt-5">
            <div className="flex w-full mt-[-1.2rem] items-center pr-4 justify-end">
              <label className="inline-flex relative items-center mr-5 cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={this.state.templateOrMessage}
                  readOnly
                />
                <div
                  onClick={() => {
                    this.setState((prev) => {
                      return {
                        ...prev,
                        templateOrMessage: !prev.templateOrMessage,
                      };
                    });
                  }}
                  className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#a0db32]"
                ></div>
                <span className="ml-2 text-md  font-['poppins'] font-medium text-gray-900">
                  {this.state.templateOrMessage ? "Template" : "Message"}
                </span>
              </label>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="Email"
              >
                Subject
              </label>
              <input
                type="text"
                className="border-2 p-2 outline-none border-gray-100 w-full rounded"
                placeholder="Type your subject here..."
                value={this.state.email.subject}
                onChange={(e) =>
                  this.setState((prev) => {
                    return {
                      ...prev,
                      email: {
                        ...prev.email,
                        subject: e.target.value,
                      },
                    };
                  })
                }
              />
            </div>
            {
              !this.state.templateOrMessage ? <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="Email"
                >
                  Message
                </label>
                <ReactQuill
                  ref={
                    this.editorRef
                  }
                  required
                  modules={{
                    toolbar: {
                      container: [
                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                        { 'indent': '-1' }, { 'indent': '+1' }],
                        ['link', 'image', 'video'],
                        ['clean']
                      ],
                      handlers: {
                        image: this.imageHandler,
                      }
                    },
                    clipboard: {
                      // toggle to add extra line breaks when pasting HTML:
                      matchVisual: false,
                    }
                  }}
                  value={this.state.email.message || ""}
                  onChange={(value) =>
                    this.setState((prev) => {
                      return {
                        ...prev,
                        email: {
                          ...prev.email,
                          message: value,
                        },
                      };
                    })
                  }
                  style={{ height: "270px" }}
                  placeholder="Type your message here..."
                />
              </div> : <div className="mt-2">
                <div className="relative">
                  <label
                    className="font-['poppins'] block text-gray-700 text-sm font-medium mb-2"
                    htmlFor="Email"
                  >
                    Upload Template
                  </label>
                  <input
                    type="file"
                    name="file"
                    accept=".html"
                    // value={this.state.template?.file}
                    onChange={(e) => this.changeTemplateFile(e)}
                  />
                  {
                    this.state.template.file ?  <div className="py-2">
                    <button
                      className="border px-2 py-[1px] border-gray-600 bg-gray-200 hover:bg-gray-300"
                      onClick={() => this.getUrl(this.state.template.file)}
                    >
                      View File
                    </button>
                    <span className="text-xs">
                      {this.state.template.file.slice(0, 20)}
                    </span>
                  </div> : null
                  }
                 
                </div>
              </div>
            }


            <div className="flex items-center justify-center mt-[6rem]">
              <UniversalButton
                title=" Send Emails"
                onClickFunction={this.handleSendEmails}
              />
            </div>
          </div>
        </Modal>

        {this.state.sendEmailModal && (
          <div
            className={`flex justify-center items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full md:h-full`}
            id="popup-modal"
            style={{ backgroundColor: "rgb(0 ,0 ,0,0.1)" }}
          >
            <div className="flex justify-center items-center p-4 w-full h-full md:h-auto">
              <div className="flex justify-center items-center relative w-full min-w-xs max-w-sm h-full md:h-auto">
                <div className=" relative w-full rounded-lg bg-white shadop-2">
                  <div className="w-full">
                    <form className="rounded p-2 pt-0">
                      <div className="flex items-center justify-between">
                        <div className="p-6 text-center">
                          <svg
                            className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                          </svg>

                          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{`Are you sure you want to send ${this.state.isTestInvites
                            ? "test invites?"
                            : this.state.isTestReports
                              ? "test reports?"
                              : "test reminder?"
                            }`}</h3>

                          <button
                            data-modal-toggle="popup-modal"
                            onClick={() => {
                              if (this.state.isTestInvites)
                                this.handleSendTestInviteEmails();
                              else if (this.state.isTestReports)
                                this.sendTestReports();
                              else this.handleSendTestReminderEmails();
                            }}
                            type="button"
                            className="text-white bg-red-600 hover:bg-red-800  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                          >
                            Yes, I'm sure
                          </button>
                          <button
                            data-modal-toggle="popup-modal"
                            onClick={() =>
                              this.setState({
                                sendEmailModal: false,
                                isTestInvites: false,
                                isTestReports: false,
                              })
                            }
                            type="button"
                            className="text-gray-500 bg-white hover:bg-gray-100 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 "
                          >
                            No, cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <Modal
          onClose={() => this.updateInterviewModal({ value: false })}
          isModal={this.state.interviewModal}
          showCloseButton
          className={"lg:w-[55%]"}
        >
          <InterviewModal
            updateInterviewModal={(data) => this.updateInterviewModal(data)}
            responseId={this.state.responseId}
            student_email={this.state.responseEmail}
          />
        </Modal>
        <Modal
          onClose={() => this.setState({ cutoffModal: false })}
          isModal={this.state.cutoffModal}
          showCloseButton
          className={"w-[92%] sm:w-[80%] md:w-[65%] lg:w-[50%] xl:w-[35%]"}
        >
          <div className="w-full font-['nunito']">
            <h1 className="w-full text-[1.7rem] mb-3 font-['nunito'] font-semibold text-center">
              Cut Off
            </h1>
            <h1 className="w-full text-[1.4rem] mb-3 font-normal font-['nunito'] text-left">
              {this.props.test.title}
            </h1>
            {this.state.cutoff.map((sc, sc_ind) => (
              <>
                {sc_ind === 1 ? (
                  <h1 className="w-full font-['nunito'] font-semibold mt-3 text-[1.2rem]">
                    Section Wise Cutoff
                  </h1>
                ) : null}
                <div className="w-full flex items-center justify-between gap-4 mb-2">
                  <h1 className="bg-[#fbfbfb] p-2 rounded-lg flex-1">
                    {sc.section}
                  </h1>
                  <input
                    className="flex-1 bg-[#fbfbfb] p-2 rounded-lg outline-none border-[1px] border-[#C6FB63]"
                    type="number"
                    value={sc.cutoff}
                    onChange={(e) => {
                      this.setState((prev) => {
                        const arr = this.state.cutoff.map((t, ind) => {
                          if (sc_ind === ind)
                            return {
                              ...t,
                              cutoff: e.target.value,
                            };
                          return t;
                        });
                        return { ...prev, cutoff: arr };
                      });
                    }}
                  />
                </div>
              </>
            ))}

            <div className="w-full grid place-items-center mt-4">
              <UniversalButton
                title="submit"
                classNames=""
                onClickFunction={() => {
                  this.props.updatingCutoffRequest({
                    id: this.props.params.id,
                    data: {
                      test_cutoff: +this.state.cutoff[0].cutoff,
                      section_cutoff: this.state.cutoff
                        .filter((sc, ind) => ind != 0)
                        .map((sc) => +sc.cutoff),
                    },
                  });
                  this.setState({ cutoffFilter: true, cutoffModal: false });
                  setTimeout(() => this.cutOffFiltering(), 1000);
                }}
              />
            </div>
          </div>
        </Modal>
        <div className="flex mt-3 mb-2 md:mt-0 px-2 md:px-0 justify-between items-start md:flex-row flex-col md:items-center">
          <div className="flex items-center justify-between w-full md:w-auto">
            <TestHeader title="Candidate Submissions" />
            <BackButton
              iconClass="block md:hidden"
              className="text-[#001437] flex md:hidden"
              iconColor="#001437"
            />
          </div>
          <div className="flex md:w-auto w-full gap-2 items-center justify-between md:justify-center">
            {this.state.page !== "Analytics" ? (
              <div className="flex items-center justify-end">
                <div className="flex items-end gap-2 justify-end w-auto bg-[#fbfbfb] px-3 py-1 rounded-[1rem]">
                  <div className="flex gap-2 items-center sm:flex-row flex-col">
                    <label htmlFor="score_from">Score (from) :</label>
                    <input
                      id="score_from"
                      type="number"
                      value={this.state.filter_options.score_from}
                      onChange={this.handleChangeScoreFrom}
                      className="border border-gray-300 outline-gray-300 px-2 py-1 rounded w-[6rem]"
                    ></input>
                  </div>
                  <div className="flex gap-2 items-center sm:flex-row flex-col">
                    <label htmlFor="score_to">Score (to) :</label>
                    <input
                      id="score_to"
                      type="number"
                      value={this.state.filter_options.score_to}
                      onChange={this.handleChangeScoreTo}
                      className="border border-gray-300 outline-gray-300 px-2 py-1 rounded w-[6rem]"
                    ></input>
                  </div>
                  <UniversalButton
                    title="Search"
                    onClickFunction={this.handleSearch}
                    classNames="!w-auto"
                  />
                </div>
              </div>
            ) : null}
            <BackButton
              iconClass="hidden md:block"
              className="text-[#001437] hidden md:flex"
              iconColor="#001437"
            />
          </div>
        </div>
        {/* <hr /> */}
        {/* back button */}

        {/* <div className="border-b-[1px] my-5 flex items-center justify-evenly w-full md:w-[70%] lg:w-[60%] xl:w-[50%] rounded-lg bg-[#fbfbfb]">
          <h1
            className={`flex items-center justify-center flex-1 gap-2 rounded-lg font-['nunito'] py-3 text-[1rem] cursor-pointer font-bold ${
              this.state.page === "Mail" && "bg-[#C6FB63]"
            }`}
            onClick={() => this.setState({ page: "Mail" })}
          >
            <Email className="flex items-center justify-center" />
            Mail
          </h1>
          <h1
            className={`flex items-center justify-center flex-1 gap-2 rounded-lg font-['nunito'] py-3 text-[1rem] cursor-pointer font-bold ${
              this.state.page === "Analitics" && "bg-[#C6FB63]"
            }`}
            onClick={() => this.setState({ page: "Analitics" })}
          >
            <Analytics className="flex items-center justify-center" />
            Analitics
          </h1>
          <h1
            className={`flex items-center justify-center flex-1 gap-2 rounded-lg font-['nunito'] py-3 text-[1rem] cursor-pointer font-bold ${
              this.state.page === "Test Report" && "bg-[#C6FB63]"
            }`}
            onClick={() => this.setState({ page: "Test Report" })}
          >
            <Test_Reports className="flex items-center justify-center" />
            Test Report
          </h1>
          <h1
            className={`flex items-center justify-center flex-1 gap-2 rounded-lg font-['nunito'] py-3 text-[1rem] cursor-pointer font-bold ${
              this.state.page === "Evaluation" && "bg-[#C6FB63]"
            }`}
            onClick={() => this.setState({ page: "Evaluation" })}
          >
            Evaluation
          </h1>
        </div> */}

        {this.state.setEvaluatorsStats !== null &&
          this.state.setEvaluatorsStats.length > 0 ? (
          <EvaluatorsStatsSlider
            setEvaluatorsStats={this.state.setEvaluatorsStats}
          />
        ) : null}

        {/* <div className="flex w-[100%] p-2  gap-x-3 pt-3 font-['nunito']  overflow-x-auto scroll-smooth scrollbar pb-3"> */}

        {/* </div> */}
        <div className="flex w-full h-[calc(100vh-10.5rem)] items-start gap-3 my-4">
          {
            this.state.submission_sidebar ? <div className="border-[1px] h-full flex-[20%] rounded-lg bg-[#fbfbfb]">
              <h1 className="flex items-center justify-start pl-5 gap-5 font-['nunito'] p-2 text-[1.4rem] border-b-[#9e9e9e] text-center border-b-[1px] font-bold">

                <NavBarCloseIcon className="cursor-pointer" onClick={() => this.setState({ submission_sidebar: false })} />
                Features

              </h1>
              <h1
                className={`flex items-center justify-start pl-10 gap-2 font-['nunito'] py-3 text-[1rem] cursor-pointer border-b-[1px] border-b-[#9e9e9e] font-bold ${this.state.page === "Mail" && "bg-[#C6FB63]"
                  }`}
                onClick={() => {
                  this.setState((prev) => {
                    return {
                      ...prev,
                      columns: [
                        ...this.state.finalColumns,
                        {
                          Header: "Score",
                          // accessor: "",
                          accessor: (row) =>
                            row.marks >= 0
                              ? row.marks.toFixed(2)
                              : "Not Attempted",
                        },
                        {
                          Header: "Grade",
                          // accessor: "",
                          accessor: (row) => (row.grade ? row.grade : "-"),
                          disableSortBy: true,
                        },
                        {
                          Header: "Actions",
                          accessor: "action",
                          disableFilters: true,
                          Cell: (props) => (
                            <CustomActions
                              response={props?.row?.original}
                              props={this.props}
                              deleteResponse={this.handleDeleteResponse}
                              updateInterviewModal={this.updateInterviewModal}
                            />
                          ),
                          disableSortBy: true,
                        },
                      ],
                    };
                  });

                  this.changingPage("Mail");
                }}
              >
                <Email />
                Mail
              </h1>
              <h1
                className={`flex items-center justify-start pl-10 gap-2 font-['nunito'] py-3 text-[1rem] cursor-pointer border-b-[1px] border-b-[#9e9e9e] font-bold ${this.state.page === "Analytics" && "bg-[#C6FB63]"
                  }`}
                onClick={() => this.changingPage("Analytics")}
              >
                <Analytics />
                Analytics
              </h1>
              <h1
                className={`flex items-center justify-start pl-10 gap-2 font-['nunito'] border-b-[1px] border-b-[#9e9e9e] py-3 text-[1rem] cursor-pointer font-bold ${this.state.page === "Test Report" && "bg-[#C6FB63]"
                  }`}
                onClick={() => {
                  this.setState((prev) => {
                    return {
                      ...prev,
                      columns: [
                        ...this.state.finalColumns,
                        {
                          Header: "Score",
                          // accessor: "",
                          accessor: (row) =>
                            row.marks >= 0
                              ? row.marks.toFixed(2)
                              : "Not Attempted",
                        },
                        {
                          Header: "Grade",
                          // accessor: "",
                          accessor: (row) => (row.grade ? row.grade : "-"),
                          disableSortBy: true,
                        },
                        {
                          Header: "Actions",
                          accessor: "action",
                          disableFilters: true,
                          Cell: (props) => (
                            <CustomActions
                              response={props?.row?.original}
                              props={this.props}
                              deleteResponse={this.handleDeleteResponse}
                              updateInterviewModal={this.updateInterviewModal}
                            />
                          ),
                          disableSortBy: true,
                        },
                      ],
                    };
                  });
                  this.changingPage("Test Report");
                }}
              >
                <Test_Reports />
                Test Report
              </h1>
              <h1
                className={`flex items-center justify-start gap-2 pl-10 border-b-[1px] border-b-[#9e9e9e] font-['nunito'] py-3 text-[1rem] cursor-pointer font-bold ${this.state.page === "Evaluation" && "bg-[#C6FB63]"
                  }`}
                onClick={() => {
                  this.setState((prev) => {
                    return {
                      ...prev,
                      columns: [
                        ...this.state.finalColumns,
                        {
                          Header: "Evaluator name",
                          // accessor: "marks",
                          accessor: (row) =>
                            row.evaluatorName
                              ? row.evaluatorName
                              : "Not Assigned",
                          disableSortBy: true,
                        },
                        {
                          Header: "Evaluation Status",
                          accessor: "",
                          disableFilters: true,
                          Cell: ({ cell: { row } }) => (
                            <CustomStatus
                              values={row?.original?.isReportEvaluated}
                            />
                          ),
                          disableSortBy: true,
                        },
                        {
                          Header: "Actions",
                          accessor: "action",
                          disableFilters: true,
                          Cell: (props) => (
                            <CustomActions
                              response={props?.row?.original}
                              props={this.props}
                              deleteResponse={this.handleDeleteResponse}
                              updateInterviewModal={this.updateInterviewModal}
                            />
                          ),
                          disableSortBy: true,
                        },
                      ],
                    };
                  });

                  this.changingPage("Evaluation");
                }}
              >
                <Evaluation />
                Evaluation
              </h1>
            </div> : <HamburgerIcon onClick={() => this.setState({ submission_sidebar: true })} className="cursor-pointer mt-4 mr-5 ml-2" />
          }


          {this.state.pageLoading ? (
            <div className="w-full flex items-center justify-center h-full">
              <ResumeLoader />
            </div>
          ) : (
            <div className="w-full h-full">
              {this.state.page === "Analytics" ? null : (
                <div className="grid mb-3 justify-end pl-2 font-['nunito'] gap-2 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 pt-2">
                  {/* <button
            type="button"
            className="text-[#001437] bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32] font-medium rounded-lg 
        sm:text-sm sm:px-4 sm:py-2 text-[10px] px-[7px] py-[5px] text-center mr-2"
            onClick={this.generateTestReport}
          >
            Generate Report
          </button> */}
                  {this.state.page === "Test Report" ? (
                    <>
                      <button
                        onClick={() => this.downloadCSVReport()}
                        type="button"
                        className="text-[#001437] bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32] font-medium rounded-lg 
        sm:text-sm sm:px-4 sm:py-2 text-[10px] px-[7px] py-[5px] text-center mr-2"
                      >
                        Download Reports
                      </button>

                      <button
                        onClick={() => this.downloadInterviewCSVReport()}
                        type="button"
                        className="text-[#001437] bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32] font-medium rounded-lg 
        sm:text-sm sm:px-4 sm:py-2 text-[10px] px-[7px] py-[5px] text-center mr-2"
                      >
                        Interview Reports
                      </button>
                      <button
                        onClick={() => {
                          this.setState({ cutoffModal: true });
                          this.setState((prev) => {
                            const arr = this.props.test.components.map(
                              (sc, ind) => {
                                return {
                                  section: sc.title,
                                  cutoff:
                                    this.props.test?.section_wise_cutoff?.[
                                    ind
                                    ] ?? 0,
                                };
                              }
                            );

                            return {
                              ...prev,
                              cutoff: [
                                {
                                  section: "Overall",
                                  cutoff: this.props.test?.test_cutoff ?? 0,
                                },
                                ...arr,
                              ],
                            };
                          });
                        }}
                        type="button"
                        className="text-[#001437] bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32] font-medium rounded-lg 
        sm:text-sm sm:px-4 sm:py-2 text-[10px] px-[7px] py-[5px] text-center mr-2"
                      >
                        Cut off
                      </button>

                      <div className="flex gap-2 ml-[-4rem] items-center justify-center font-medium rounded-lg sm:text-sm sm:px-4 sm:py-2 text-[10px] px-[7px] py-[5px]">
                        <input
                          type="checkbox"
                          className="cursor-pointer"
                          onChange={() => {
                            if (!this.state.cutoffFilter)
                              this.cutOffFiltering();
                            else this.changingPage("Test Report");

                            this.setState({
                              cutoffFilter: !this.state.cutoffFilter,
                            });
                          }}
                          checked={this.state.cutoffFilter}
                        />
                        <h1 className="font-['nunito'] text-[1.1rem] font-semibold">
                          Cut Off
                        </h1>
                      </div>
                    </>
                  ) : null}

                  {this.state.page === "Evaluation" ? (
                    <button
                      onClick={this.blindEvaluation}
                      type="button"
                      className="text-[#001437] bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32] font-medium rounded-lg 
          sm:text-sm sm:px-4 sm:py-2 text-[10px] px-[7px] py-[5px] text-center mr-2"
                    >
                      Blind evaluation
                    </button>
                  ) : null}
                  {this.state.page === "Mail" ? (
                    <>
                      <button
                        type="button"
                        className="text-[#001437] bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32] font-medium rounded-lg 
        sm:text-sm sm:px-4 sm:py-2 text-[10px] px-[7px] py-[5px] text-center mr-2"
                        onClick={this.handleMessageModal}
                      >
                        Send Email
                      </button>
                      <button
                        onClick={() => this.handleSendEmailModal("reports")}
                        type="button"
                        className="text-[#001437] bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32] font-medium rounded-lg 
        sm:text-sm sm:px-4 sm:py-2 text-[10px] px-[7px] py-[5px] text-center mr-2"
                      >
                        Send Test Reports
                      </button>
                      <button
                        type="button"
                        className="text-[#001437] bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32] font-medium rounded-lg 
        sm:text-sm sm:px-4 sm:py-2 text-[10px] px-[7px] py-[5px] text-center mr-2"
                        onClick={() => this.handleSendEmailModal("invites")}
                      >
                        Send Test Invite
                      </button>
                      <button
                        type="button"
                        className="text-[#001437] bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32] font-medium rounded-lg 
        sm:text-sm sm:px-4 sm:py-2 text-[10px] px-[7px] py-[5px] text-center mr-2"
                        onClick={() => this.handleSendEmailModal("reminder")}
                      >
                        Send Reminder Email
                      </button>
                    </>
                  ) : null}
                </div>
              )}

              <div className="h-[calc(100%-3rem)] w-full">
                {this.renderComponent()}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const CustomStatus = ({ values }) => {
  console.log("stats", values);
  // Loop through the array and create a badge-like component instead of a comma-separated string
  return (
    <span
      className={`inline-flex items-center justify-center sm:px-2 px-1 py-1 
                 sm:text-xs text-[7px] font-bold leading-none text-white
                 ${values ? "bg-green-600" : "bg-red-600"} rounded`}
    >
      {values ? "Evaluated" : "Pending"}
    </span>
  );
};

const CustomActions = ({
  response,
  props,
  deleteResponse,
  updateInterviewModal,
}) => {
  const handleReviewTest = () => {
    if (response.user) {
      props.history({
        pathname: `/tests/candidatereport/${response._id}`,
        state: response._id,
      });
    } else {
      props.history({
        pathname: `/tests/candidatereport/${response._id}`,
        state: response.email,
      });
    }
  };
  // Loop through the array and create a badge-like component instead of a comma-separated string

  return response.response || true ? (
    <>
      <div className="flex lg:gap-3 items-center">
        <Proctor
          className="cursor-pointer sm:w-6 sm:h-6 w-5 h-5"
          alt="svg"
          color="#a0db32"
          title="View Report"
          onClick={() => handleReviewTest()}
        />
        <InterviewCall
          className="cursor-pointer sm:w-6 sm:h-6 w-5 h-5"
          alt="svg"
          title="Call for an interview"
          onClick={() => {
            updateInterviewModal({
              value: true,
              response: response._id,
              email: response.email,
            });
          }}
          color="#a0db32"
        />

        <img
          alt="delete"
          src={deletec}
          title="Delete Response"
          className="cursor-pointer sm:w-5 sm:h-5 w-4 h-4 hoverPointer"
          onClick={() => {
            deleteResponse(response._id);
          }}
        />
      </div>
    </>
  ) : (
    <div className="flex">
      No Responses yet
      <img
        alt="delete"
        src={deletec}
        title="Delete Response"
        className="ml-2 sm:w-5 sm:h-5 w-4 h-4 hoverPointer"
        onClick={() => {
          deleteResponse(response._id);
        }}
      />
    </div>
  );
};

function DefaultFilterForColumn({
  column: {
    filterValue,
    preFilteredRows: { length },
    setFilter,
  },
}) {
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        // Set undefined to remove the filter entirely
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${length} records..`}
      className="mt-2 shadow appearance-none border rounded w-full p-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    />
  );
}

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headers,
    rows,
    visibleColumns,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultFilterForColumn },
    },
    useFilters,
    useSortBy
  );

  // Render the UI for your table
  return (
    <table className="sm:text-sm text-left w-full" {...getTableProps()}>
      <thead className="sm:text-sm text-[9px] shadow-sm uppercase border border-gray-300 align-top">
        <tr>
          {headers.map((header, index) => (
            <th className="sm:p-3 p-1" key={`students-head-${index}`}>
              <span {...header.getHeaderProps(header.getSortByToggleProps())}>
                {header.render("Header")}
              </span>
              <span>
                {header.isSorted ? (header.isSortedDesc ? " 🔽" : " 🔼") : ""}
              </span>
              {/* Rendering Default Column Filter */}
              <div>{header.canFilter ? header.render("Filter") : null}</div>
            </th>
          ))}
        </tr>
      </thead>

      <tbody {...getTableBodyProps()} className="sm:text-sm text-[10px]">
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="border-b-2" key={i}>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className="sm:p-3 px-1 border-x">
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const mapStateToProps = ({ test, sidebar, turnserver, userdetails }) => {
  return {
    isFetching: userdetails.isFetching,
    sidebar: sidebar,
    proctoredUserDetails: userdetails.proctoredUserDetails,
    testScoreDetails: test.testScoreDetails,
    successMessage: test.successMessage,
    test: test.test,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteResponseRequested: (data) => dispatch(DeleteResponseRequested(data)),
    sendEmailsRequested: (data) => dispatch(sendEmailsRequested(data)),
    updateTestDeleteModalActive: (data) =>
      dispatch(updateTestDeleteModalActive(data)),
    sendTestInviteEmailsRequested: (data) =>
      dispatch(sendTestInviteEmailsRequested(data)),
    sendReminderEmailsRequested: (data) =>
      dispatch(sendReminderEmailsRequested(data)),
    toggleSidebar: () => dispatch(toggleSidebar()),
    fetchTestRequest: (data) => dispatch(fetchTestRequest(data)),
    updatingCutoffRequest: (data) => dispatch(updatingCutoffRequest(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewAttemptedStudents));
