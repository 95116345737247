import {FETCH_TURNSERVER_CREDENTIALS_SUCCESS} from '../actionTypes';

const initialState = { 
  credentials: {
    username: null,
    password: null
  }
};

const turnserverReducer = function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case FETCH_TURNSERVER_CREDENTIALS_SUCCESS:
      return { 
        ...state,
        credentials:{
          username: action.payload.username,
          password: action.payload.password
        }
       };
    


    default:
      return state;
  }
}

export default turnserverReducer