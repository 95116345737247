import {FETCH_TURNSERVER_CREDENTIALS_FAILURE,FETCH_TURNSERVER_CREDENTIALS,FETCH_TURNSERVER_CREDENTIALS_SUCCESS} from '../actionTypes';

export const fetchTurnServerCredsFailure = (data) => {
    return {
      type: FETCH_TURNSERVER_CREDENTIALS_FAILURE,
      payload: data,
    };
  };

  export const fetchTurnServerCreds = () => {
    return {
      type: FETCH_TURNSERVER_CREDENTIALS
    };
  };

  export const fetchTurnServerCredsSuccess = (data) => {
    return {
      type: FETCH_TURNSERVER_CREDENTIALS_SUCCESS,
      payload: data,
    };
  };