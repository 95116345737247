// export const Languages = {
// 	cpp: {
// 		id: 54, // language id for judge0
// 		name: 'C++',
// 	},
// 	java: {
// 		id: 62,
// 		name: 'Java',
// 	},
// 	javascript: {
// 		id: 63,
// 		name: 'JavaScript',
// 	},
// 	python: {
// 		id: 71,
// 		name: 'Python',
// 	},
// 	c : {
// 		id: 50,
// 		name: "C (GCC 9.2.0)"
// 	}
// };

export const Languages = {
	c: {
		id: 49,
		name: "C (GCC 8.3.0)"
	},
	cpp: {
		id: 53,
		name: "C++ (GCC 8.3.0)"
	},
	c_9_2_0: {
		id: 50,
		name: "C (GCC 9.2.0)"
	},
	cpp_9_2_0: {
		id: 54,
		name: "C++ (GCC 9.2.0)"
	},
	c_sharp: {
		id: 51,
		name: "C# (Mono 6.6.0.161)"
	},

	go: {
		id: 60,
		name: "Go (1.13.5)"
	},
	java: {
		id: 62,
		name: "Java (OpenJDK 13.0.1)"
	},
	javascript: {
		id: 63,
		name: "JavaScript (Node.js 12.14.0)"
	},
	python_2_7_17: {
		id: 70,
		name: "Python (2.7.17)"
	},
	python_3_8_1: {
		id: 71,
		name: "Python (3.8.1)"
	},
	ruby: {
		id: 72,
		name: "Ruby (2.7.0)"
	},
	rust: {
		id: 73,
		name: "Rust (1.40.0)"
	},
	typescript: {
		id: 74,
		name: "TypeScript (3.7.4)"
	}
}
