import {
  FETCH_PROFILE_SUCCESS,
  GET_USER_SUBSCRIBED_PLANS_SUCCESS,
  UPDATE_USER_FAILED,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "../actionTypes/user";
const initialState = {
  newRegistration: {
    resume_file: "",
    college: "",
    prev_employment: "",
    tech_stack: [],
    branch: "",
    cgpa: null,
    address: "",
    location: "",
    hear_about_us: "",
    employed_before: null,
    legal_age: null,
    linkedin_url: "",
    consent: "",
    daily_basis: "",
    is_pwd: null,
    gender: null,
    photo_file: "",
  },
  profile: null,
  editProfileLoading: false,
  userSubscribedPlans: null,
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  let new_state;
  switch (type) {
    case "UPDATE_NEW_REGISTRATION":
      new_state = {
        ...state,
        newRegistration: {
          ...payload,
        },
      };
      break;

    case GET_USER_SUBSCRIBED_PLANS_SUCCESS:
      new_state = {
        ...state,
        userSubscribedPlans: [...payload],
      };
      break;

    case FETCH_PROFILE_SUCCESS:
      new_state = {
        ...state,
        profile: action.payload,
      };
      break;

    case UPDATE_USER_REQUEST:
      new_state = {
        ...state,
        editProfileLoading: true,
      };
      break;

    case UPDATE_USER_SUCCESS:
      new_state = {
        ...state,
        profile: action.payload,
        editProfileLoading: false,
      };
      break;

    case UPDATE_USER_FAILED:
      new_state = {
        ...state,
        editProfileLoading: false,
      };
      break;

    default:
      new_state = state;
  }
  return new_state;
};

export default userReducer;
