import React, { useRef, useState,useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { formats, modules } from '../../tests/utils/QuillModules';

const MyQuillEditor = (props) => {
  const [editorValue, setEditorValue] = useState('');

  const isInitialMount = useRef(true);

  useEffect(() => {
      isInitialMount.current = false;
  }, []);

  useEffect(() => {
    if (props.type === 'I' && editorValue !== props.value) {
      setEditorValue(props.value);
    }
  }, [props.value])

  useEffect(() => {
    if (editorValue !== props.value) {
      setEditorValue(props.value);
    }
  }, [])

  function debounce(f, interval) {
    let timer = null;

    return (...args) => {
      clearTimeout(timer);
      return new Promise((resolve) => {
        timer = setTimeout(() => resolve(f(...args)), interval);
      });
    };
  };

  const handleChange = debounce((value) => {
    setEditorValue(value);
    props.onResponseChangeDescriptive(value);
  }, 1000)


  const handleEditorChange = (value) => {
    // console.log("gdihbjcnoeasdlijkcznx soduzhxcjbn sdozcxulhjbnl sdocizxlhn sdilCZJKXH")
    if (isInitialMount.current) {
      isInitialMount.current = false; 
      return;
  }
    if (props.type === 'I') {
      handleChange(value);
    } else {
      setEditorValue(value);
      props.onResponseChangeDescriptive(value);
    }
  }


  return (
    <div>
      <ReactQuill placeholder={props.placeholder || ""} modules={modules} formats={formats} value={editorValue || ""} readOnly={props.readOnly} onChange={!props.readOnly ? handleEditorChange : null} preserveWhitespace />
    </div>
  );
};

export default MyQuillEditor;