import axios from "axios";
import { BASE_URL } from "../assets/js/config";
import authHeader from "./auth-header";

export const getAllSubscriptionApi = () => {
  return axios
    .get(`${BASE_URL}api/subscription`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      
      throw err.response.data;
    });
};

export const getSingleSubscriptionApi = () => {
  return axios
    .get(`${BASE_URL}api/subscription/single_sub`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

export const updateSubscriptionApi = (data) => {
  return axios
    .put(`${BASE_URL}api/subscription`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

export const deleteSubscriptionApi = (id) => {
  return axios
    .delete(`${BASE_URL}api/subscription/${id}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

