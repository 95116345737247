import React, { Component } from "react";
import TestHeader from "../tests/createTest/TestHeader";
import { connect } from "react-redux";
import { fetchProctoredUserDetailsRequested } from "../../store/actions/userdetails";
import { fetchTestScoreRequested } from "../../store/actions/test";
import { generatePeerId } from "./utils";
import { viewFile } from "../../services/upload.service";
import { fetchChatMsgsRequested } from "../../store/actions/chat";
import ReviewTest from "./ReviewTest";
import dummy from "../../assets/img/svg/dummypic.svg";
import { withRouter } from "../common/WithRouter";

class ProctoringReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ImageFilesUrl: "",
      IdFilesUrl: "",
    };
  }
  componentDidMount() {
    const search = this.props.location.search;
    const queryParams = new URLSearchParams(search);
    const term = queryParams.get("public");
    if (term !== "true") {
      const studentPeerId = generatePeerId("student", {
        testId: this.props.params.testId,
        userId: this.props.params.id,
      });
      this.props.fetchProctoredUserDetailsRequested({
        testId: this.props.params.testId,
        userId: studentPeerId,
      });
    }
    this.props.fetchTestScoreRequested({
      testId: this.props.params.testId,
      userId: this.props.params.id,
    });
  }

  getImageUrl(filename) {
    if (filename && filename.length) {
      viewFile(filename, "PROCTORING").then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          ImageFilesUrl: res,
        }));
      });
    }
  }

  getIdUrl(filename) {
    if (filename && filename.length) {
      viewFile(filename, "PROCTORING").then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          IdFilesUrl: res,
        }));
      });
    }
  }

  MinsToHrs(minutes) {
    var h = Math.floor(minutes / 60);
    var m = minutes % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return h + " Hrs : " + m + " Mins ";
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.proctoredUserDetails !== prevProps.proctoredUserDetails) {
      this.getIdUrl(this.props.proctoredUserDetails.idCardImg);
      this.getImageUrl(this.props.proctoredUserDetails.studentPhotoImg);
    }
    // if (
    //   this.props?.testScoreDetails?.submission?._id &&
    //   !this.state.custom_rank &&
    //   !this.state.custom_total_students
    // ) {
    //   console.log("check");
    //   testService
    //     .loadStudentResponse(this.props?.testScoreDetails?.submission?._id)
    //     .then(async (response) => {
    //       console.log("response", response.data);
    //       // this.setState({
    //       //   custom_rank: response.data.custom_rank,
    //       //   custom_total_students: response.data.custom_total_students,
    //       // });
    //     })
    //     .catch((err) => console.log(err));
    // }
  }

  render() {
    const student = this.props.proctoredUserDetails;
    const score = this.props.testScoreDetails;
    // console.log("response", this.props.response);
    return (
      <div className="sm:p-5 p-2 sm:px-8">
        {/* <button
          type="button"
          className="text-white bg-gradient-to-r from-purple-500 via-purple-600 
        to-purple-700 hover:bg-gradient-to-br font-medium rounded-lg 
        text-sm px-4 py-2 text-center mr-2 mb-2"
          onClick={() =>
            this.props.history(
              `/tests/students/${this.props.params.testId}`
            )
          }
        >
          Go Back
        </button> */}
        {/* <div className="grid lg:grid-cols-4 sm:grid-cols-2 sm:gap-y-3">
          <div className="shadow-sm rounded p-1 sm:p-3 sm:px-2 mr-10 ">
            <p className="text-slate-400 font-thin font-serif">Conclusion</p>
            <p className="font-bold sm:text-3xl text-ld text-md text-green-500">
              Positive
            </p>
            <hr className="rounded py-1 my-1 bg-green-500" />
          </div>
          <div className="shadow-sm rounded p-1 sm:p-3 sm:px-2 mr-10 ">
            <p className="text-slate-400 font-thin font-serif">
              Credibility Score
            </p>
            <p className="font-bold sm:text-3xl text-ld text-md text-indigo-500">
              80%
            </p>
            <hr className="rounded py-1 my-1 bg-indigo-500" />
          </div>
          <div className="shadow-sm rounded p-1 sm:p-3 sm:px-2 mr-10 ">
            <p className="text-slate-400 font-thin font-serif">Comment</p>
            <p className="font-bold sm:text-3xl text-ld text-md text-yellow-300">
              02
            </p>
            <hr className="rounded py-1 my-1 bg-yellow-300" />
          </div>
          <div className="shadow-sm rounded p-1 sm:p-3 sm:px-2 mr-10 ">
            <p className="text-slate-400 font-thin font-serif">Alert</p>
            <p className="font-bold sm:text-3xl text-ld text-md text-red-500">
              04
            </p>
            <hr className="rounded py-1 my-1 bg-red-500" />
          </div>
        </div> */}
        <TestHeader title="Candidate Details" />
        <hr className="h-4" />
        <div className="grid lg:grid-cols-2 sm:py-2 py-1 justify-start items-start">
          <div className="w-full">
            <div className="grid sm:grid-cols-2 gap-3">
              <div className="shadow-md rounded sm:p-3 p-1 px-2 bg-slate-50">
                <p className="text-slate-500 font-thin font-serif">Name</p>
                <p className="font-medium text-md sm:text-lg ">
                  {/* {student.user && student.user.name} */}
                  {this.props.response?.user?.name || this.props.response?.name}
                </p>
              </div>
              {/* <div className="shadow-md rounded sm:p-3 p-1 px-2 bg-slate-50">
                <p className="text-slate-500 font-thin font-serif">Login</p>
                <p className="font-medium text-md sm:text-lg ">
                  Pediredla_Ajay
                </p>
              </div> */}
              {/* <div className="shadow-md rounded sm:p-3 p-1 px-2 bg-slate-50">
                <p className="text-slate-500 font-thin font-serif">Mobile</p>
                <p className="font-medium text-md sm:text-lg ">
                  xxxx
                </p>
              </div> */}
              <div className="shadow-md rounded sm:p-3  p-1 bg-slate-50">
                <p className="text-slate-500 font-thin font-serif">Email</p>
                <p className="font-medium text-md sm:text-lg w-[100px]">
                  {/* {student.user && student.user.email} */}
                  {this.props.response?.user?.email ||
                    this.props.response?.email}
                </p>
              </div>
              {this.props.testScoreDetails?.submission?.college && (
                <div className="shadow-md rounded sm:p-3 p-1 px-2 bg-slate-50">
                  <p className="text-slate-500 font-thin font-serif">
                    Institution Name
                  </p>
                  <p className="font-medium text-md sm:text-lg ">
                    {this.props.testScoreDetails?.submission?.college}
                  </p>
                </div>
              )}
              {this.props.testScoreDetails?.submission?.mobile && (
                <div className="shadow-md rounded sm:p-3 p-1 px-2 bg-slate-50">
                  <p className="text-slate-500 font-thin font-serif">Mobile</p>
                  <p className="font-medium text-md sm:text-lg ">
                    {this.props.testScoreDetails?.submission?.mobile}
                  </p>
                </div>
              )}
              <div className="shadow-md rounded sm:p-3 p-1 px-2 bg-slate-50">
                <p className="text-slate-500 font-thin font-serif">Time Zone</p>
                <p className="font-medium text-md sm:text-lg ">
                  India Standard Time(GMT+5:30)
                </p>
              </div>
            </div>
          </div>
          {this.props.response?.quiz?.is_proctoring && (
            <div className="grid sm:grid-cols-2 sm:py-3 p-1 sm:px-2 lg:gap-x-1">
              <img
                src={this.state.ImageFilesUrl || dummy}
                alt="studentImage"
                className="border-2 border-slate-300"
                title="User_Photo"
              />
              <img
                src={this.state.IdFilesUrl || dummy}
                alt="studentImage"
                className="border-2 border-slate-300"
                title="User Id"
              />
            </div>
          )}
        </div>
        <TestHeader title="Session Details" />
        <hr className="h-2" />
        <div className="flex w-full sm:pb-5 pb-2">
          <div className="w-full">
            <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-y-3">
              <div className="shadow-md rounded md:p-3 sm:p-2 p-1 px-2 mr-10 bg-slate-50">
                <p className="text-slate-500 font-thin font-serif">Test Name</p>
                <p className="font-medium sm:text-lg md:text-xl text-md">
                  {/* {student.quiz && student.quiz.title} */}
                  {this.props.response?.quiz?.title}
                </p>
              </div>
              <div className="shadow-md rounded md:p-3 sm:p-2 p-1 px-2 mr-10 bg-slate-50">
                <p className="text-slate-500 font-thin font-serif">
                  Test Start Time
                </p>
                <p className="font-medium sm:text-lg md:text-xl text-md">
                  {new Date(
                    this.props.response?.quiz?.start_duration
                  ).toLocaleDateString() +
                    " " +
                    new Date(
                      this.props.response?.quiz?.start_duration
                    ).toLocaleTimeString()}
                </p>
              </div>
              <div className="shadow-md rounded md:p-3 sm:p-2 p-1 px-2 mr-10 bg-slate-50">
                <p className="text-slate-500 font-thin font-serif">
                  Test End Time
                </p>
                <p className="font-medium sm:text-lg md:text-xl text-md">
                  {new Date(
                    this.props.response?.quiz?.end_duration
                  ).toLocaleDateString() +
                    " " +
                    new Date(
                      this.props.response?.quiz?.end_duration
                    ).toLocaleTimeString()}{" "}
                </p>
              </div>
              <div className="shadow-md rounded md:p-3 sm:p-2 p-1 px-2 mr-10 bg-slate-50">
                <p className="text-slate-500 font-thin font-serif">
                  Test Duration
                </p>
                <p className="font-medium sm:text-lg md:text-xl text-md">
                  {this.props.response &&
                    this.MinsToHrs(
                      (new Date(this.props.response?.quiz?.end_duration) -
                        new Date(this.props.response?.quiz?.start_duration)) /
                      60000
                    )}
                </p>
              </div>
              <div className="shadow-md rounded md:p-3 sm:p-2 p-1 px-2 mr-10 bg-slate-50">
                <p className="text-slate-500 font-thin font-serif">
                  Time Taken
                </p>
                <p className="font-medium sm:text-lg md:text-xl text-md">
                  {this.MinsToHrs(parseInt(score.time_taken))}
                </p>
              </div>

              {/* <div className="shadow-md rounded md:p-3 sm:p-2 p-1 px-2 mr-10 bg-slate-50">
                <p className="text-slate-500 font-thin font-serif">
                  Pronunciation Score
                </p>
                <p className="font-medium sm:text-lg md:text-xl text-md">-</p>
              </div>

              <div className="shadow-md rounded md:p-3 sm:p-2 p-1 px-2 mr-10 bg-slate-50">
                <p className="text-slate-500 font-thin font-serif">
                  Fluency Score
                </p>
                <p className="font-medium sm:text-lg md:text-xl text-md">-</p>
              </div>

              <div className="shadow-md rounded md:p-3 sm:p-2 p-1 px-2 mr-10 bg-slate-50">
                <p className="text-slate-500 font-thin font-serif">
                  Overall Language Proficiency
                </p>
                <p className="font-medium sm:text-lg md:text-xl text-md">-</p>
              </div> */}

              <div className="shadow-md rounded md:p-3 sm:p-2 p-1 px-2 mr-10 bg-slate-50">
                <p className="text-slate-500 font-thin font-serif">Score</p>
                <p className="font-medium sm:text-lg md:text-xl text-md">
                  {(score.submission && score.submission.marks) || 0}/
                  {score.total_marks}
                </p>
              </div>
              {/* {console.log("score", score)} */}
              {score?.normalized_score !== "undefined" && (
                <div className="shadow-md rounded md:p-3 sm:p-2 p-1 px-2 mr-10 bg-slate-50">
                  <p className="text-slate-500 font-thin font-serif">
                    Normalized Score
                  </p>
                  <p className="font-medium sm:text-lg md:text-xl text-md">
                    {score?.normalized_score?.toFixed(3, 0)}
                  </p>
                </div>
              )}
              <div className="shadow-md rounded md:p-3 sm:p-2 p-1 px-2 mr-10 bg-slate-50">
                <p className="text-slate-500 font-thin font-serif">Rank</p>
                <p className="font-medium sm:text-lg md:text-xl text-md">
                  {score.current_rank}/{score.num_participants}
                </p>
              </div>
              {this.props.testScoreDetails.submission?.custom_total_students !==
                undefined && (
                  <div className="shadow-md rounded md:p-3 sm:p-2 p-1 px-2 mr-10 bg-slate-50">
                    <p className="text-slate-500 font-thin font-serif">
                      Overall Rank
                    </p>
                    {/* {console.log(
                    "check",
                    this.props.testScoreDetails.submission
                      ?.custom_total_students
                  )} */}
                    <p className="font-medium sm:text-lg md:text-xl text-md">
                      {this.props.testScoreDetails?.submission?.custom_rank}/
                      {
                        this.props.testScoreDetails?.submission
                          ?.custom_total_students
                      }
                    </p>
                  </div>
                )}
              {this.props.response?.quiz?.is_proctoring && (
                <>
                  {this.props.testScoreDetails?.submission?.proctor_name && (
                    <div className="shadow-md rounded md:p-3 sm:p-2 p-1 px-2 mr-10 bg-slate-50">
                      <p className="text-slate-500 font-thin font-serif">
                        Proctor Name
                      </p>
                      <p
                        className={`flex items-center font-medium sm:text-lg md:text-xl text-md`}
                      >
                        {this.props.testScoreDetails.submission.proctor_name}
                      </p>
                    </div>
                  )}
                  {this.props.testScoreDetails?.submission?.proctor_email && (
                    <div className="shadow-md rounded md:p-3 sm:p-2 p-1 px-2 mr-10 bg-slate-50">
                      <p className="text-slate-500 font-thin font-serif">
                        Proctor Email
                      </p>
                      <p
                        className={`flex items-center font-medium sm:text-lg md:text-xl text-md`}
                      >
                        {this.props.testScoreDetails.submission.proctor_email}
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {this.props.response?.quiz?.is_proctoring && (
          <>
            <TestHeader title="Proctor Chat" />
            <hr className="h-2" />
            <div className="flex w-full">
              <div className="w-full py-2 rounded ">
                <ul className=" text-slate-500 italic">
                  {this.props.testScoreDetails?.chats?.length
                    ? this.props.testScoreDetails?.chats?.map((each) => {
                      return (
                        <li>
                          <strong>{each.from.name}</strong>: {each.message}
                        </li>
                      );
                    })
                    : "No Chats available"}
                </ul>
              </div>
            </div>

            <TestHeader title="Proctor Comments" />
            <hr className="h-2" />
            <div className="flex w-full">
              <div className="w-full py-2 rounded ">
                <ul className=" text-slate-500 italic">
                  {Boolean(
                    this.props.testScoreDetails?.submission?.proctor_comments
                      ?.length
                  )
                    ? this.props.testScoreDetails?.submission?.proctor_comments?.map(
                      (each, index) => {
                        return (
                          <li>
                            <strong>{index + 1}</strong>: {each}
                          </li>
                        );
                      }
                    )
                    : "No Proctor Comments available"}
                </ul>
              </div>
            </div>
          </>
        )}

        <hr />
        <ReviewTest id={this.props?.testScoreDetails?.submission?._id} />
      </div>
    );
  }
}

const mapStateToProps = ({ test, sidebar, turnserver, userdetails, chat }) => {
  return {
    isFetching: userdetails.isFetching,
    proctoredUserDetails: userdetails.proctoredUserDetails,
    testScoreDetails: test.testScoreDetails,
    chat,
    response: test.submission_response,
    chatMsgs: chat.messages,
    isMsgsLoading: chat.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProctoredUserDetailsRequested: (data) =>
      dispatch(fetchProctoredUserDetailsRequested(data)),
    fetchTestScoreRequested: (data) => dispatch(fetchTestScoreRequested(data)),
    fetchChatMsgsRequested: (data) => dispatch(fetchChatMsgsRequested(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProctoringReport));
