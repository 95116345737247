import {
  FETCH_PROFILE_REQUESTED,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
} from "../actionTypes/user";

export const setNewRegistration = (new_values) => {
  return {
    type: "UPDATE_NEW_REGISTRATION",
    payload: new_values,
  };
};

export const getUserSubscribedPlans = () => {
  return {
    type: "GET_USER_SUBSCRIBED_PLANS",
  };
};

export const getUserSubscribedPlansSuccess = (data) => {
  return {
    type: "GET_USER_SUBSCRIBED_PLANS_SUCCESS",
    payload: data,
  };
};

export const fetchMyProfile = () => {
  return {
    type: FETCH_PROFILE_REQUESTED,
  };
};

export const fetchMyProfileSuccess = (data) => {
  return {
    type: FETCH_PROFILE_SUCCESS,
    payload: data,
  };
};

export const fetchMyProfileFailure = () => {
  return {
    type: FETCH_PROFILE_FAILURE,
  };
};

export const updateUserRequest = (data) => {
  return {
    type: UPDATE_USER_REQUEST,
    payload: data,
  };
};
export const updateUserSuccess = (data) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: data,
  };
};
export const updateUserFailed = (data) => {
  return {
    type: UPDATE_USER_FAILED,
    payload: data,
  };
};
