import axios from "axios";
import { BASE_URL } from "../assets/js/config";
import authHeader from "./auth-header";

export const getAllEmailTemplatesService = async (filter) => {
  let query_arr = [];
  for (let filter_key in filter) {
    const value = filter[filter_key];
    if (value !== null && value !== undefined && value !== "") {
      query_arr.push(`${filter_key}=${value}`);
    }
  }
  const query_str = query_arr.join("&");
  return await axios
    .get(`${BASE_URL}api/email-template?${query_str}`, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const addEmailTemplateService = async (data) => {
  return await axios
    .post(`${BASE_URL}api/email-template`, data, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const sendEmailTemplateService = async (data) => {
  return await axios
    .post(`${BASE_URL}api/email-template/send-email`, data, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const updateEmailTemplateService = (data) => {
  const { updated_value, id } = data;
  return axios
    .put(BASE_URL + `api/email-template/${id}`, updated_value, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const deleteEmailTemplateService = ({ id }) => {
  return axios
    .delete(`${BASE_URL}api/email-template/${id}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};
