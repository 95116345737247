import React, { useState } from "react";
import CreatableMulti from "../../../components/common/ReactSelect/SelectAndCreatable";
import { addQuestionRequested } from "../../../store/actions/questionbank";
import UniversalButton from "../../../components/common/StandardButton/UniversalButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTests } from "../../../store/actions/test";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../../assets/js/toast_config";
import {
  getQuizDetails,
  insertQuestions,
} from "../../../store/actions/extractQuestion";

const AddQuestionModal = ({
  selectedQuestion,
  questionTags,
  totalQuestion,
  handleTagChange,
  setAddAllModal,
  selectedTags,
  setSelectedQuestion,
  setTotalQuestion,
}) => {
  const dispatch = useDispatch();
  const [addToTest, setAddToTest] = useState(false);
  const [questionOrTest, setQuestionOrTest] = useState(false);
  const [selectedTest, setSelectedTest] = useState("");
  const [selectedSection, setSelectedSection] = useState("");

  const {
    allTests: { upcoming },
  } = useSelector((state) => state.test);

  const { addToTestQuestions, section } = useSelector(
    (state) => state.extractQuestionReducer
  );

  const addToQuestionBank = () => {
    if (addToTest)
      dispatch(
        fetchAllTests({
          quiz_type: "upcoming",
          is_a_set: "",
          is_aits: false,
        })
      );

    if (questionTags.length) {
      const newQuestion = totalQuestion.map((q) => {
        return { ...q, tags: questionTags };
      });
      dispatch(
        addQuestionRequested({
          questions: newQuestion,
          isExtractQuestion: true,
          index: selectedQuestion,
          ...(addToTest && { setQuestionOrTest }),
        })
      );
    } else
      dispatch(
        addQuestionRequested({
          questions: totalQuestion,
          isExtractQuestion: true,
          index: selectedQuestion,
          ...(addToTest && { setQuestionOrTest }),
        })
      );

    setSelectedQuestion([]);
    setTotalQuestion([]);

    if (!addToTest) setAddAllModal(false);
  };

  const handleAddToTest = () => {
    if (selectedTest === "")
      toast.warning("please select any test", defaultToastSetting);
    else
      dispatch(
        insertQuestions({
          sectionIndex: selectedSection,
          quiz_id: selectedTest,
          questions: addToTestQuestions,
        })
      );
    setAddAllModal(false);
  };

  // console.log(addToTestQuestions);

  return (
    <>
      {!questionOrTest ? (
        <div className="p-6 pt-0 text-center flex flex-col items-center justify-center">
          <svg
            className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>

          <h3 className="mb-5 text-lg font-normal font-['poppins'] font-semibold">
            Are you sure you want to add question number
            {selectedQuestion.map((num) => num + 1).join(", ")} to QuestionBank?
          </h3>
          <div className="mb-3 flex gap-[1.5rem] items-center">
            <CreatableMulti
              temp_college_data={selectedTags}
              handleTagChange={handleTagChange}
            />
            <label className="inline-flex relative items-center mr-5 cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={addToTest}
                readOnly
              />
              <div
                onClick={() => setAddToTest((prev) => !prev)}
                className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#a0db32]"
              ></div>
              <span className="ml-2 text-md  font-['poppins'] font-medium text-gray-900">
                Add to test
              </span>
            </label>
          </div>
          <div className="flex gap-[1rem] mt-[1.5rem]">
            <UniversalButton
              title="Yes, I'm sure"
              onClickFunction={() => addToQuestionBank()}
              classNames="w-auto mr-5"
            />

            <button
              onClick={() => setAddAllModal(false)}
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
            >
              No, cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="p-6 pt-0 text-center flex flex-col items-center justify-center">
          <p className="my-[1rem] font-semibold text-[1.4rem]">
            Choose test and upload question
          </p>
          <div className="flex-1 w-full md:w-[65%] lg:w-[50%] mb-[1rem]">
            <p className="mb-1 text-[1rem] font-['nunito] pl-1 font-semibold text-left">
              Choose Test
            </p>
            <select
              className="w-full font-['nunito] p-2 rounded-[0.3rem] outline-none border border-[#BCFB46]"
              onChange={(e) => {
                console.log("main", e.target.value);
                dispatch(getQuizDetails(e.target.value));
                setSelectedTest(e.target.value);
              }}
            >
              <option value="">Choose Tests...</option>

              {upcoming.length
                ? upcoming.map((t, ind) => (
                    <option key={`${t._id}-${ind}`} value={`${t._id}`}>
                      {t.title}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="flex-1 w-full md:w-[65%] lg:w-[50%] mb-[1rem]">
            <p className="mb-1 text-[1rem] font-['nunito] pl-1 font-semibold text-left">
              Choose Section
            </p>
            <select
              className="w-full font-['nunito] p-2 rounded-[0.3rem] mt-1 outline-none border border-[#BCFB46]"
              onChange={(e) => setSelectedSection(e.target.value)}
            >
              <option value="">Choose Section...</option>

              {section.length
                ? section.map((t, ind) => (
                    <option
                      key={`${t.sectionIndex}-${ind}`}
                      value={`${t.sectionIndex}`}
                    >
                      {t.sectionTitle}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <UniversalButton
            title="Add Questions"
            onClickFunction={() => handleAddToTest()}
          />
        </div>
      )}
    </>
  );
};

export default AddQuestionModal;
