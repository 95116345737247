import React, { Suspense, useEffect } from "react";
import {
  unstable_HistoryRouter as HistoryRouter,
  Route,
  Routes
} from "react-router-dom";
import { createBrowserHistory } from "history";
import Navbar from "./components/common/Navbar";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ResetPassword from "./pages/ResetPassword";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Test from "./pages/Test";
import Sidebar from "./components/common/Sidebar";
import CreateTest from "./components/tests/CreateTest";
import Courses from "./pages/teacher/Courses";
import RegisterUser from "./components/user/registeruser";
import Authentication from "./components/router/Authentication";
import PublicRoute from "./components/router/PublicRoute";
// import AllPage from "./components/router/AllPage";

import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "./store/actions/message";
import AttemptedStudents from "./components/tests/AttemptedStudents";
import ProctoringReport from "./components/tests/ProctoringReport";
import SeeScore from "./components/tests/GetScore";
import GiveTest from "./components/tests/GiveTest";
import ReviewTest from "./components/tests/ReviewTest";
import CreateCourse from "./pages/admin/CreateCourse";
import AllCourses from "./pages/admin/AllCourses";
import ViewCourse from "./pages/admin/ViewCourse"; ////
import DeleteCourse from "./pages/admin/DeleteCourse"; ////
import VideoMonitor from "./components/tests/VideoMonitor";
import SuperProctorVideoMonitoring from "./components/tests/SuperProctorVideoMonitoring";
import WelcomePage from "./components/tests/beforeTest/WelcomePage";
import CheckYourMetrics from "./components/tests/beforeTest/CheckYourMetrics";
import Proctoring from "./components/tests/beforeTest/Proctoring";
import AssignTest from "./components/tests/createTest/AssignTest";
import FeedBack from "./components/tests/afterTest/FeedBack";
import Analytics from "./pages/student/Analytics";
import Analysis from "./pages/Analysis";
import * as Sentry from "@sentry/react";
import { ToastContainer } from "react-toastify";
import TestGroups from "./pages/TestGroups";
import googleSignup from "./components/signup/googleSignup";
import githubSignup from "./components/signup/githubSignup";
import GithubLogin from "./components/login/githubLogin";
import PublicTestRoute from "./components/router/PublicTestRoute";
import Error_page from "./components/common/Error_page";
import CandidateReport from "./components/tests/CandidateReport";

import QuestionBank from "./pages/QuestionBank/QuestionBank";
import EmailTemplate from "./pages/EmailTemplate/EmailTemplate";
import PaymentSuccess from "./pages/PaymentSuccess";
import Profile from "./pages/Profile";
import SignupAdditionalDetails from "./pages/SignupAdditionalDetails";
import PostgresQuestion from "./components/tests/PostgresQuestion";
import InterviewPanel from "./pages/InterviewPanel/InterviewPanel";
import Interviews from "./pages/InterviewPanel/Interviews";
import InterviewReport from "./pages/InterviewPanel/InterviewReport";
import ParseResume from "./pages/parseResume/ParseResume";
import ExtractQuestion from "./pages/ExtractQuestion/ExtractQuestion";
import EvaluatorsAssign from "./pages/Evaluators/EvaluatorsAssign";
import BlindReportEvaluation from "./pages/Evaluators/BlindReportEvaluation";
import Subscription from "./pages/subscription/Subscription";

localStorage.removeItem("midas-code");
const history = createBrowserHistory();
const App = () => {
  const { isTestStarted: testStatus } = useSelector((state) => state.test);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { opened: sideBarOpened } = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();
  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage());
    });
  }, [dispatch]);
  return (
    <HistoryRouter history={history}>
      <ToastContainer
        theme="dark"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>{!testStatus && <Navbar user={currentUser} />}</div>
      <div className="flex h-full flex-row">
        {currentUser && <Sidebar user={currentUser} />}
        <div
          className={`${sideBarOpened && currentUser ? "content" : "content-full"
            } rounded`}
          style={{ background: "white", position: "relative" }}
        // style={{ background: "white", position: "relative", marginLeft: !sideBarOpened && '70px'}}
        >
          <Routes>

            <Route
              path="/"
              element={
                <PublicRoute fallback={<>Loading...</>}>
                  <Home />
                </PublicRoute>
              }
            />
            <Route
              path="/signup"
              element={
                <PublicRoute restricted={true} fallback={<>Loading...</>}>
                  <SignUp />
                </PublicRoute>
              }
            />
            <Route
              path="/signup/details"
              element={
                <PublicRoute restricted={true} fallback={<>Loading...</>}>
                  <SignupAdditionalDetails />
                </PublicRoute>
              }
            />
            <Route
              path="/aicts-payment-success"
              element={
                <PublicRoute fallback={<>Loading...</>}>
                  <PaymentSuccess />
                </PublicRoute>
              }
            />
            <Route
              path="/githublogin"
              element={
                <PublicRoute restricted={true} fallback={<>Loading...</>}>
                  <GithubLogin />
                </PublicRoute>
              }
            />
            <Route
              path="/resetpassword/:resetToken"
              element={
                <PublicRoute restricted={true} fallback={<>Loading...</>}>
                  <ResetPassword />
                </PublicRoute>
              }
            />
            <Route
              path="/interviews/candidatereport/:id"
              element={
                <PublicRoute fallback={<>Loading...</>}>
                  <InterviewReport />
                </PublicRoute>
              }
            />
            <Route
              path="/login"
              element={
                <PublicRoute restricted={true} fallback={<>Loading...</>}>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path="/tests/reports/:testId/user/:id"
              element={
                <PublicRoute fallback={<>Loading...</>}>
                  <ProctoringReport />
                </PublicRoute>
              }
            />
            <Route
              path="/tests/candidatereport/:id"
              element={
                <PublicRoute fallback={<>Loading...</>}>
                  <CandidateReport />
                </PublicRoute>
              }
            />
            <Route
              path="/systemcompatibility"
              element={
                <PublicRoute fallback={<>Loading...</>}>
                  <CheckYourMetrics />
                </PublicRoute>
              }
            />
            <Route
              path="/tests/review/:id"
              element={
                <PublicRoute fallback={<>Loading...</>}>
                  <ReviewTest />
                </PublicRoute>
              }
            />



            <Route
              path="/dashboard"
              exact
              element={
                <Authentication fallback={<>Loading...</>}>
                  <Dashboard />
                </Authentication>
              }
            />
            <Route
              path="/profile"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <Profile />
                </Authentication>
              }
            />
            <Route
              path="/aits"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <Test is_aits={true} />
                </Authentication>
              }
            />
            <Route
              path="/tests"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <Test is_aits={false} />
                </Authentication>
              }
            />
            <Route
              path="/testgroups"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <TestGroups />
                </Authentication>
              }
            />
            <Route
              path="/tests/edit/:id"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <CreateTest />
                </Authentication>
              }
            />
            <Route
              path="/tests/preview/:id"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <GiveTest />
                </Authentication>
              }
            />
            <Route
              path="/tests/response/:id"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <SeeScore />
                </Authentication>
              }
            />
            <Route
              path="/tests/students/:id"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <AttemptedStudents />
                </Authentication>
              }
            />
            <Route
              path="/interviews/interviewpanel/:id"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <InterviewPanel />
                </Authentication>
              }
            />

            <Route
              path="/askyourresume"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <ParseResume />
                </Authentication>
              }
            />
            <Route
              path="/extractQuestion"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <ExtractQuestion />
                </Authentication>
              }
            />
            <Route
              path="/questionbank"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <QuestionBank modalType="P" isModal={false} />
                </Authentication>
              }
            />
            <Route
              path="/email-template"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <EmailTemplate />
                </Authentication>
              }
            />
            <Route
              path="/subscription"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <Subscription />
                </Authentication>
              }
            />
            <Route
              path="/tests/videomonitor/:id"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <VideoMonitor type="p" />
                </Authentication>
              }
            />
            <Route
              path="/tests/superproctor/:id"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <SuperProctorVideoMonitoring />
                </Authentication>
              }
            />
            <Route
              path="/tests/superproctor/:id/:proctor"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <SuperProctorVideoMonitoring />
                </Authentication>
              }
            />
            <Route
              path="/createcourse"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <CreateCourse />
                </Authentication>
              }
            />
            <Route
              path="/blind-assessment"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <EvaluatorsAssign />
                </Authentication>
              }
            />
            <Route
              path="/blind-assessment/review/:id"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <BlindReportEvaluation />
                </Authentication>
              }
            />
            <Route
              path="/allcourses/:id"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <ViewCourse />
                </Authentication>
              }
            />
            <Route
              path="/register"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <Register />
                </Authentication>
              }
            />
            <Route
              path="/allcourses/delete/:id"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <DeleteCourse />
                </Authentication>
              }
            />
            <Route
              path="/allcourses"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <AllCourses />
                </Authentication>
              }
            />
            <Route
              path="/courses"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <Courses />
                </Authentication>
              }
            />

            <Route
              path="/form"
              element={
                <Authentication restricted={true} fallback={<>Loading...</>}>
                  <RegisterUser />
                </Authentication>
              }
            />
            <Route
              path="/tests/feedback/:id"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <FeedBack />
                </Authentication>
              }
            />
            <Route
              path="/tests/proctoring/:id"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <Proctoring isBack={true} />
                </Authentication>
              }
            />
            <Route
              path="/assign-test/:id"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <AssignTest />
                </Authentication>
              }
            />
            <Route
              path="/analytics/:id"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <Analytics />
                </Authentication>
              }
            />
            <Route
              path="/analysis"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <Analysis />
                </Authentication>
              }
            />
            <Route
              path="/postgres-question"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <PostgresQuestion type="T" />
                </Authentication>
              }
            />
            <Route
              path="/interviews"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <Interviews />
                </Authentication>
              }
            />
            <Route
              path="/error-page"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <Error_page />
                </Authentication>
              }
            />

            <Route
              path="/tests/giveTest/:id"
              element={
                <PublicTestRoute fallback={<>Loading...</>}>
                  <GiveTest />
                </PublicTestRoute>
              }
            />

            <Route
              path="/tests/welcome-page/:id"
              element={
                <Authentication fallback={<>Loading...</>}>
                  <WelcomePage />
                </Authentication>
              }
            />

            {/* <Authentication exact path="/dashboard" component={<Dashboard />} />
            <Authentication exact path="/profile" component={<Profile />} />
            <Authentication
              exact
              path="/aits"
              component={<Test is_aits={true} />}
            />
            <Authentication
              exact
              path="/tests"
              component={<Test is_aits={false} />}
            />
            <Authentication
              exact
              path="/testgroups"
              component={<TestGroups />}
            />
            <Authentication
              exact
              path="/tests/edit/:id"
              component={<CreateTest />}
            />
            <Authentication
              exact
              path="/tests/preview/:id"
              component={<GiveTest />}
            />
            <Authentication
              exact
              path="/tests/response/:id"
              component={<SeeScore />}
            />
            <Authentication
              exact
              path="/tests/students/:id"
              component={<AttemptedStudents />}
            />
            <Authentication
              exact
              path="/interviews/interviewpanel/:id"
              component={<InterviewPanel />}
            />
            <PublicRoute
              exact
              path="/tests/reports/:testId/user/:id"
              component={ProctoringReport}
            />
            <PublicRoute
              exact
              path="/tests/candidatereport/:id"
              component={CandidateReport}
            />
            <PublicRoute
              exact
              path="/systemcompatibility"
              component={CheckYourMetrics}
            />
            <PublicRoute
              exact
              path="/tests/review/:id"
              component={ReviewTest}
            />
            <PublicTestRoute
              exact
              path="/tests/giveTest/:id"
              component={<GiveTest />}
            />
            <Authentication
              exact
              path="/askyourresume"
              component={<ParseResume />}
            />
            <Authentication
              exact
              path="/extractQuestion"
              component={<ExtractQuestion />}
            />
            <Authentication
              exact
              path="/questionbank"
              component={<QuestionBank modalType="P" isModal={false} />}
            />
            <Authentication
              exact
              path="/email-template"
              component={<EmailTemplate />}
            />
            <Authentication
              exact
              path="/tests/videomonitor/:id"
              component={<VideoMonitor type="p" />}
            />
            <Authentication
              exact
              path="/tests/superproctor/:id"
              component={<SuperProctorVideoMonitoring />}
            />
            <Authentication
              exact
              path="/tests/superproctor/:id/:proctor"
              component={<SuperProctorVideoMonitoring />}
            />
            <Authentication
              exact
              path="/createcourse"
              component={<CreateCourse />}
            />
            <Authentication
              exact
              path="/blind-assessment"
              component={<EvaluatorsAssign />}
            />
            <Authentication
              exact
              path="/blind-assessment/review/:id"
              component={<BlindReportEvaluation />}
            />

            <Authentication
              exact
              path="/allcourses/:id"
              component={<ViewCourse />}
            />
            <Authentication exact path="/register" component={<Register />} />
            <Authentication
              exact
              path="/allcourses/delete/:id"
              component={<DeleteCourse />}
            />
            <Authentication
              exact
              path="/allcourses"
              component={<AllCourses />}
            />
            <Authentication exact path="/courses" component={<Courses />} />
            
            
            <PublicRoute
              restricted={true}
              exact
              path="/signup"
              component={SignUp}
            />
            <PublicRoute
              exact
              restricted={true}
              path="/signup/details"
              component={SignupAdditionalDetails}
            />
            <PublicRoute
              path="/aicts-payment-success"
              component={PaymentSuccess}
            />
 
            <PublicRoute
              restricted={true}
              path="/githublogin"
              component={GithubLogin}
            />
            <PublicRoute
              restricted={true}
              path="/resetpassword/:resetToken"
              component={ResetPassword}
            />
            <PublicRoute
            exact
            path="/interviews/candidatereport/:id"
            component={InterviewReport}
            />
            <PublicTestRoute
              exact
              path="/tests/welcome-page/:id"
              component={<WelcomePage />}
            />
            <Authentication
              restricted={true}
              exact
              path="/form"
              component={<RegisterUser />}
            />
           
            <Authentication
              exact
              path="/tests/feedback/:id"
              component={<FeedBack />}
            />
           
            <Authentication
              exact
              path="/tests/proctoring/:id"
              component={<Proctoring isBack={true} />}
            />
            <Authentication
              exact
              path="/assign-test/:id"
              component={<AssignTest />}
            />
            <Authentication
              exact
              path="/analytics/:id"
              component={<Analytics />}
            />
            <Authentication exact path="/analysis" component={<Analysis />} />
            <Authentication
              exact
              path="/postgres-question"
              component={<PostgresQuestion type="T" />}
            />
            <Authentication
              exact
              path="/interviews"
              component={<Interviews />}
            />
            
            <Authentication component={<Error_page />} /> */}
          </Routes>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </HistoryRouter>
  );
};

export default Sentry.withProfiler(App);
