import React, { Component } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { updateStarterCardActive } from "../../../store/actions/test";
import { uploadFile } from "../../../services/upload.service";
import TestHeader from "../createTest/TestHeader";
import { ReactComponent as ButtonSpinner } from "../../../assets/img/svg/button-spinner.svg";
import card from "../../../assets/img/card.png";
import KyronSideLetter from "../../../assets/img/Kyron/kyron_logo.png";
import { updateInterviewPage } from "../../../store/actions/interviewpanel";
import { getUserIdFromPeerId } from "../../common/utils";
import { defaultToastSetting } from "../../../assets/js/toast_config";
import UniversalButton from "../../common/StandardButton/UniversalButton";

class FullFillments extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef(null);
    this.videoRef2 = React.createRef(null);
    this.state = {
      is_verifying_photo: false,
      is_verifying_id: false,
      is_retrieving: true,
      idDataURL: null,
      imageDataURL: null,
      localStream: null,
      idBlob: null,
      photoBlob: null,
      recentIdObj: null,
      recentPhotoObj: null,
    };
    this.streamOn = this.streamOn.bind(this);
    this.capturePicture = this.capturePicture.bind(this);
    this.reCaptureImage = this.reCaptureImage.bind(this);
  }

  onNextPage() {
    if (this.props.type === "T" && this.props.test.is_proctoring) {
      if (this.props.isVideo && this.props.isAudio) {
        if (this.props.isScreen) {
          if (this.state.idDataURL && this.state.imageDataURL) {
            this.props.socket.emit("checks-before-test", {
              data: {
                isPhotoAndCard: true,
                idCardImg: this.state.recentIdObj,
                studentPhotoImg: this.state.recentPhotoObj,
              },
              userId: this.props.userId,
              testId: this.props.testId,
              type: "fullfillments",
            });
            this.props.updateStarterCardActive(4);
          } else {
            toast.warning("Please verify Your Photo and ID", defaultToastSetting)
          }
        } else {
          toast.warning("Please refresh and share your screen", defaultToastSetting)
        }
      } else {
        toast.warning("Please enable your camera and refresh your page", defaultToastSetting)
      }
    } else if (this.props.type === "I") {
      if (this.props.isVideo && this.props.isAudio) {
        if (this.state.idDataURL && this.state.imageDataURL) {
          this.props.socket.emit("checks-before-interview", {
            data: {
              isPhotoAndCard: true,
              studentIdCardImg: this.state.recentIdObj,
              studentImg: this.state.recentPhotoObj,
            },
            userId: this.props.userId,
            OtherUserId: getUserIdFromPeerId(this.props.interviewerPeerId),
            interviewId: this.props.testId,
            type: "fullfillments",
          });
          this.props.updateInterviewPage(3);
        } else {
          toast.warning("Please verify Your Photo and ID", defaultToastSetting);
        }
      } else {
        toast.warning("Please enable your camera and refresh your page", defaultToastSetting);
      }
    }
  }

  componentDidMount() {
    let flag = false;
    if (
      this.props.type === "T" &&
      this.props.proctoringDetails.isPhotoAndCard
    ) {
      this.props.updateStarterCardActive(5);
      flag = true;
    } else if (
      this.props.type === "I" &&
      this.props.interviewDetails?.isPhotoAndCard
    ) {
      flag = true;
      if (this.props.interviewerPeerId !== "") {
        this.props.socket.emit("send-status-notification", {
          msg: "Identity card and picture are already captured!",
          type: "s",
          userId: getUserIdFromPeerId(this.props.interviewerPeerId),
        });
      }
      this.props.updateInterviewPage(3);
    } else if (
      this.props.type === "I" &&
      !this.props.interviewDetails?.isPhotoAndCard &&
      this.props.interviewerPeerId !== ""
    ) {
      this.props.socket.emit("send-status-notification", {
        msg: "Capturing identity card and picture!",
        type: "w",
        userId: getUserIdFromPeerId(this.props.interviewerPeerId),
      });
    }
    if (!flag) {
      this.streamOn(this.videoRef, "photo");
      this.streamOn(this.videoRef2, "card");
    }
  }

  streamOn(path) {
    navigator.mediaDevices
      .getUserMedia({
        video: { deviceId: this.props.currentVideoDevice || undefined },
      })
      .then((stream) => {
        this.setState({ localStream: stream });
        path.current.srcObject = stream;
        this.setState({ is_retrieving: false });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  capturePicture(path) {
    if (this.state.localStream.active) {
      var canvas = document.createElement("canvas");
      canvas.width = path.current.videoWidth;
      canvas.height = path.current.videoHeight;
      var context = canvas.getContext("2d");
      context.drawImage(path.current, 0, 0, canvas.width, canvas.height);
      path.current.srcObject = null;
      canvas.toBlob(async (context) => {
        // const sizeInBytes = context.size;
        // const sizeInMB = sizeInBytes / (1024 * 1024);

        // const downloadLink = document.createElement("a");
        // downloadLink.href = URL.createObjectURL(context);
        // downloadLink.download = new Date() + "image.png";
        // console.log(context, downloadLink.href);
        if (path === this.videoRef) {
          this.setState({ is_verifying_photo: true });
          this.props.type === "I"
            ? await uploadFile(context, {
              type: "INTERVIEW",
              id: this.props.testId,
            }).then((filename) => {
              this.setState({ recentPhotoObj: filename });
            })
            : await uploadFile(context, {
              type: "PROCTORING",
              id: this.props.testId,
            }).then((filename) => {
              this.setState({ recentPhotoObj: filename });
            });
          this.setState(
            {
              photoBlob: context,
              imageDataURL: URL.createObjectURL(context),
              is_verifying_photo: false,
            },
            () => this.streamOff()
          );
        } else {
          this.setState({ is_verifying_id: true });
          this.props.type === "I"
            ? await uploadFile(context, {
              type: "INTERVIEW",
              id: this.props.testId,
            }).then((filename) => {
              this.setState({ recentIdObj: filename });
            })
            : await uploadFile(context, {
              type: "PROCTORING",
              id: this.props.testId,
            }).then((filename) => {
              this.setState({ recentIdObj: filename });
            });
          this.setState(
            {
              idBlob: context,
              idDataURL: URL.createObjectURL(context),
              is_verifying_id: false,
            },
            () => this.streamOff()
          );
        }
      });
    } else {
      toast.success("Please enable your camera and refresh the page", defaultToastSetting);
    }
  }

  componentWillUnmount() {
    if (this.state.localStream) {
      this.state.localStream.getTracks().forEach((track) => {
        track.stop();
      });
      this.setState({ localStream: null });
    }
  }

  streamOff() {
    if (this.state.idDataURL && this.state.imageDataURL) {
      this.state.localStream.getTracks().forEach((track) => {
        track.stop();
      });
      this.setState({ localStream: null });
    }
  }

  reCaptureImage(path) {
    if (path === this.videoRef) {
      this.setState({ imageDataURL: null, is_verifying_photo: false });
    } else {
      this.setState({ idDataURL: null, is_verifying_id: false });
    }
    this.streamOn(path);
  }

  render() {
    return (
      <div>
        {/* Header */}
        <nav className="flex justify-between items-center px-4 py-2 border-b border-border w-[100%] bg-white drop-shadow-[2px_0px_2px_rgba(0,0,0,0.25)]">
          <img
            className="h-[40px] object-contain"
            src={KyronSideLetter}
            title="Logo"
            alt="kyron"
          />
        </nav>

        <div className="flex justify-start px-4 pt-5">
          <TestHeader title="Student Verification" />
        </div>
        <div className="grid md:mt-14 mt-5 md:grid-cols-2 justify-center font-['poppins']">
          <div className="flex flex-col items-center ">
            <h1 className="text-xl font-semibold pb-3">Take your photo</h1>
            <p className="mb-8">
              Please take a photo of yours by clicking on{" "}
              <span className="font-semibold">Take Photo</span>.
            </p>
            {this.state.is_retrieving && (
              <div className="flex gap-2">
                <p>...retrieving metrics</p>
                {/* <img
                  alt="loading"
                  src={load}
                  className="spinner mr-2 w-6 h-6 text-primary "
                /> */}
                <div className="mx-4">
                  <div className="dot-typing"></div>
                </div>
              </div>
            )}

            {this.state.imageDataURL ? (
              <div className="text-center">
                <div className="flex justify-center items-center gap-4">
                  <img
                    style={{ width: "350px", height: "250px" }}
                    className="border-4 p-1 border-gray-300"
                    alt="userImage"
                    title="Face Id"
                    src={this.state.imageDataURL}
                  />
                </div>
                <p className="pt-2 text-[#a0db32] ">
                  You photo was successfully taken
                </p>
                <button
                  className="text-[#a0db32] border border-[#a0db32] hover:bg-[#BCFB46]
              hover:text-white font-semibold uppercase text-xs
              px-4 py-2 mb-1 rounded outline-none focus:outline-none
              ease-linear transition-all duration-150 mt-5"
                  onClick={(e) => {
                    e.preventDefault();
                    this.reCaptureImage(this.videoRef);
                  }}
                >
                  Retake
                </button>
              </div>
            ) : (
              <>
                {this.state.localStream && this.state.localStream.active ? (
                  <div className="text-center">
                    <div className="flex justify-center w-full items-center gap-4">
                      <div>
                        <video
                          className="border-4 p-1  border-gray-300"
                          style={{ width: "330px", height: "250px" }}
                          ref={this.videoRef}
                          autoPlay
                          muted
                        ></video>
                      </div>
                    </div>

                    <button
                      className="text-[#a0db32] border border-[#a0db32] hover:bg-[#BCFB46]
                        hover:text-white font-semibold uppercase text-xs
                        px-4 py-2 mb-1 rounded outline-none focus:outline-none
                        ease-linear transition-all duration-150 mt-5"
                      onClick={(e) => {
                        e.preventDefault();
                        this.capturePicture(this.videoRef);
                      }}
                    >
                      {this.state.is_verifying_photo ? (
                        <ButtonSpinner />
                      ) : (
                        "Take Photo "
                      )}
                    </button>
                  </div>
                ) : (
                  <p className="text-red-600 h-[300px] w-[400px] flex justify-center items-center">
                    Please Enable Your Camera and refresh the page. . .
                  </p>
                )}
              </>
            )}
          </div>
          <div className="flex flex-col items-center">
            <h1 className="text-xl font-semibold pb-3">Verify Your ID</h1>
            <p className="mb-8">
              Please place your ID within the frame and click on{" "}
              <span className="font-semibold">Take Photo</span>
            </p>
            {this.state.is_retrieving && (
              <div className="flex gap-2">
                <p>...Retrieving Metrics</p>
                {/* <img
                  alt="loading"
                  src={load}
                  className="spinner mr-2 w-6 h-6 text-primary "
                /> */}
                <div className="mx-4">
                  <div className="dot-typing"></div>
                </div>
              </div>
            )}

            {Boolean(this.state.idDataURL) ? (
              <div className="text-center">
                <div className="flex justify-center items-center gap-4 position-relative">
                  <div>
                    <img
                      src={card}
                      style={{
                        position: "absolute",
                        transform: "translate(15px, 25px)",
                        width: "300px",
                        height: "200px",
                      }}
                      alt=""
                    />
                    <img
                      style={{ width: "330px", height: "250px" }}
                      className="border-4 p-1 border-gray-300"
                      alt="Id"
                      title="Id"
                      src={this.state.idDataURL}
                    />
                  </div>
                </div>

                <p className="pt-2 text-[#a0db32]">
                  Your ID-photo was successfully taken
                </p>
                <button
                  className="text-[#a0db32] border border-[#a0db32] hover:bg-[#BCFB46]
              hover:text-white font-semibold uppercase text-xs
              px-4 py-2 mb-1 rounded outline-none focus:outline-none
              ease-linear transition-all duration-150 mt-5"
                  onClick={(e) => {
                    e.preventDefault();
                    this.reCaptureImage(this.videoRef2);
                  }}
                >
                  Retake
                </button>
              </div>
            ) : (
              <>
                {this.state.localStream && this.state.localStream.active ? (
                  <div className="text-center">
                    <div className="flex justify-center items-center gap-4">
                      <div>
                        <img
                          src={card}
                          style={{
                            position: "absolute",
                            transform: "translate(15px, 25px)",
                            width: "300px",
                            height: "200px",
                          }}
                          alt=""
                        />
                        <video
                          className="border-4 p-1 border-gray-300"
                          style={{ width: "330px", height: "250px" }}
                          ref={this.videoRef2}
                          autoPlay
                          muted
                        ></video>
                      </div>
                    </div>

                    <button
                      className=" text-[#a0db32] border border-[#a0db32] hover:bg-[#BCFB46]
              hover:text-white font-semibold uppercase text-xs
              px-4 py-2 mb-1 rounded outline-none focus:outline-none
              ease-linear transition-all duration-150 mt-5"
                      onClick={(e) => {
                        e.preventDefault();
                        this.capturePicture(this.videoRef2);
                      }}
                    >
                      {this.state.is_verifying_id ? (
                        <ButtonSpinner />
                      ) : (
                        "Take Photo "
                      )}
                    </button>
                  </div>
                ) : (
                  <p className="text-red-600 h-[300px] w-[400px] flex justify-center items-center">
                    Please Enable Your Camera and refresh the page. . .
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-center items-center my-5">
          <UniversalButton
            onClickFunction={() => this.onNextPage()}
            title="Next"
            classNames="mb-6 mr-2"
          />
        </div>
        <div></div>
      </div>
    );
  }
}

const mapStateToProps = ({ test, interviewpanelReducer }) => {
  return {
    test: test.test,
    proctoringDetails: test.proctoringDetails,
    interviewDetails: interviewpanelReducer.interviewDetails,
    screenStream: test.screenStream,
    isVideo: test.isVideo,
    isAudio: test.isAudio,
    isScreen: test.isScreen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStarterCardActive: (data) => dispatch(updateStarterCardActive(data)),
    updateInterviewPage: (data) => dispatch(updateInterviewPage(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FullFillments);
