import {
  FETCH_CHAT_MSGS_FAILURE,
  FETCH_CHAT_MSGS_SUCCESS,
  FETCH_CHAT_MSGS_REQUESTED,
  SEND_MESSAGE,
  RECEIVE_MESSAGE,
  FETCH_ALL_CHAT_MSGS_FAILURE,
  FETCH_ALL_CHAT_MSGS_REQUESTED,
  FETCH_ALL_CHAT_MSGS_SUCCESS,
  SET_NOTIFICATION_DATA,
} from "../actionTypes";

export const fetchAllChatMsgsFailure = (data) => {
  return {
    type: FETCH_ALL_CHAT_MSGS_FAILURE,
    payload: data,
  };
};

export const fetchAllChatMsgsRequested = (data) => {
  return {
    type: FETCH_ALL_CHAT_MSGS_REQUESTED,
    payload: data,
  };
};

export const fetchAllChatMsgsSuccess = (data) => {
  return {
    type: FETCH_ALL_CHAT_MSGS_SUCCESS,
    payload: data,
  };
};
export const fetchChatMsgsFailure = (data) => {
  return {
    type: FETCH_CHAT_MSGS_FAILURE,
    payload: data,
  };
};

export const fetchChatMsgsRequested = (data) => {
  return {
    type: FETCH_CHAT_MSGS_REQUESTED,
    payload: data,
  };
};

export const fetchChatMsgsSuccess = (data) => {
  return {
    type: FETCH_CHAT_MSGS_SUCCESS,
    payload: data,
  };
};

export const sendMessage = (data) => {
  return {
    type: SEND_MESSAGE,
    payload: data,
  };
};
export const receiveMessage = (data) => {
  return {
    type: RECEIVE_MESSAGE,
    payload: data,
  };
};

export const setNewNotification = (data) => {
  return {
    type: SET_NOTIFICATION_DATA,
    payload: data,
  };
};
