/* eslint-disable import/no-anonymous-default-export */
import { TOGGLE_SIDEBAR } from "../actionTypes";

const initialState = { opened: true };

const sidebarReducer = function (state = initialState, action) {
  const { type } = action;

  switch (type) {
    case TOGGLE_SIDEBAR:
      return { opened: !state.opened };

    default:
      return state;
  }
}

export default sidebarReducer