import { resolve } from "@sentry/utils";
import axios from "axios";
import { BASE_URL } from "../assets/js/config";
import authHeader from "./auth-header";

export const getProctoredUsers = ({ testId }) => {
  return axios
    .get(`${BASE_URL}api/quizproctoring/${testId}/proctoredusers`, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error.response.data);
    });
};
export const getProctoredUsersOfAnyProctor = ({ testId, proctorId }) => {
  return axios
    .get(
      `${BASE_URL}api/quizproctoring/${testId}/${proctorId}/proctoredusers`,
      {
        headers: { ...authHeader() },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error.response.data);
    });
};

export const updateUniqueTestTimeService = ({
  submission_id,
  extra_time,
  proctor_comments,
  proctoring_flags,
  cheating_flags,
}) => {
  return axios
    .put(
      `${BASE_URL}api/quizassign/${submission_id}/updatebyproctor`,
      { extra_time, proctor_comments, proctoring_flags, cheating_flags },
      {
        headers: { ...authHeader() },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error.response.data);
    });
};

export const getTestTimeService = ({ email, quiz }) => {
  return axios
    .get(`${BASE_URL}api/quizassign?email=${email}&quiz=${quiz}`, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error.response.data);
    });
};

export const getProctoredUserDetails = ({ testId, userId }) => {
  return axios
    .get(`${BASE_URL}api/quizproctoring/${testId}/user/${userId}`, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const getProctoringFlags = async () => {
  return await axios
    .get(`${BASE_URL}api/proctoringflag`, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const getCheatingFlags = async () => {
  return await axios
    .get(`${BASE_URL}api/cheatingflag`, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const getAttendingUsers = async ({ testId }) => {
  return await axios
    .get(`${BASE_URL}api/quizproctoring/${testId}/summary`, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error.response.data);
    });
};
