import axios from "axios";
import React, { Component } from "react";

import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../assets/js/config";
import Modal from "../Modal/Modal";
import { ReactComponent as ButtonSpinner } from "../../../assets/img/svg/button-spinner.svg";
import {
  createTag,
  fetchAllTagsService,
} from "../../../services/questionbank.service";
// import { ColourOption, colourOptions } from "../data";
// import { ActionMeta, OnChangeValue } from "react-select";

export default class CreatableMulti extends Component {
  constructor(props) {
    super();
    this.state = {
      showAddTagModal: false,
      n_tag_name: "",
      isNewTagAddLoading: false,
    };
  }

  handleNewCollegeSave = async () => {
    this.setState({
      isNewTagAddLoading: true,
    });
    createTag({
      label: this.state.n_tag_name?.toUpperCase(),
      value: this.state.n_tag_name?.toUpperCase(),
    })
      .then((response) => {
        this.setState({
          isNewTagAddLoading: false,
          showAddTagModal: false,
          n_tag_name: "",
        });
        this.props.handleTagChange([
          ...this.props.temp_college_data,
          {
            label: response?.data.label,
            value: response?.data.value,
            tag_id: response?.data._id,
          },
        ]);
        toast.success("Your tag successfully added", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        this.setState({
          isNewTagAddLoading: false,
          showAddTagModal: false,
        });
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  promiseOptions = async (inputValue) => {
    return fetchAllTagsService(inputValue)
      .then((response) => {
        const arrayMap = response.slice(0, 20).map((data) => {
          return { value: data.value, label: data.label, tag_id: data._id };
        });
        return arrayMap;
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  debounce = (f, interval) => {
    let timer = null;

    return (...args) => {
      clearTimeout(timer);
      return new Promise((resolve) => {
        timer = setTimeout(() => resolve(f(...args)), interval);
      });
    };
  };
  render() {
    return (
      <>
        <Modal
          onClose={() =>
            this.setState({
              showAddTagModal: false,
            })
          }
          isModal={this.state.showAddTagModal}
          showCloseButton
          className={"w-[90%] md:w-[40%]"}
        >
          <h2 className="text-xl font-['poppins'] font-medium pb-4 ">
            Add your tag
          </h2>
          <div className="mb-10">
            <label className="font-['poppins'] text-neutral-400 ">
              {" "}
              Your tag name<span>*</span>{" "}
            </label>
            <br />
            <small>Make sure your tag is not available in our list</small>
            <input
              name="college_name"
              placeholder="Enter your text here"
              className="login-form-emailInput uppercase"
              value={this.state.n_tag_name}
              onChange={(e) =>
                this.setState({
                  n_tag_name: e.target.value,
                })
              }
              oninput="this.value = this.value.toUpperCase()"
            />
          </div>

          <button
            onClick={this.handleNewCollegeSave}
            className="text-white w-[150px] transition bg-[#009999]  hover:bg-gray-800 hover:text-white font-medium rounded-full  
          text-md px-3 py-1 flex justify-center mr-2 my-2 cursor-pointer font-['nunito'] ml-auto"
            type="submit"
          >
            {this.state.isNewTagAddLoading ? <ButtonSpinner /> : "Add"}
          </button>
        </Modal>
        <AsyncSelect
          loadOptions={this.debounce(this.promiseOptions, 500)}
          value={this.props.temp_college_data}
          isMulti
          onChange={(e) => this.props.handleTagChange(e)}
          className="min-w-[300px] login-form-selectInput"
          placeholder="Select your tag"
          noOptionsMessage={({ inputValue }) =>
            !inputValue ? (
              "Please enter keyword to search your tag"
            ) : (
              <button
                onClick={() =>
                  this.setState({
                    showAddTagModal: true,
                    n_tag_name: inputValue,
                  })
                }
              >
                No results found!{" "}
                <span className="login-form-selectInput-button">
                  Click here
                </span>{" "}
                to add your tag
              </button>
            )
          }
        />
      </>
    );
  }
}
