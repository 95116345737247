export const UPDATE_INTERVIEW_CODE_EDITOR_LANGUAGE =
  "UPDATE_INTERVIEW_CODE_EDITOR_LANGUAGE";

export const UPDATE_INTERVIEW_CODE_EDITOR_CODE =
  "UPDATE_INTERVIEW_CODE_EDITOR_CODE";

export const FETCH_ALL_INTERVIEWS_REQUESTED = "FETCH_ALL_INTERVIEWS_REQUESTED";

export const FETCH_ALL_INTERVIEWS_SUCCESS = "FETCH_ALL_INTERVIEWS_SUCCESS";

export const UPDATE_CURRENT_SECTIONINDEX = "UPDATE_CURRENT_SECTIONINDEX";

export const UPDATE_CURRENT_QUESTIONINDEX = "UPDATE_CURRENT_QUESTIONINDEX";

export const FETCH_INTERVIEW_DETAILS_REQUESTED =
  "FETCH_INTERVIEW_DETAILS_REQUESTED";

export const FETCH_INTERVIEW_DETAILS_SUCCESS =
  "FETCH_INTERVIEW_DETAILS_SUCCESS";

export const SET_CURRENT_SECTION = "SET_CURRENT_SECTION";

// export const GET_CURRENT_SOCKET_PEER_REQUESTED =
//   "GET_CURRENT_SOCKET_PEER_REQUESTED";

export const GET_CURRENT_SOCKET_PEER_SUCCESS =
  "GET_CURRENT_SOCKET_PEER_SUCCESS";

export const UPDATE_INTERVIEW_QUESTION_REQUESTED =
  "UPDATE_INTERVIEW_QUESTION_REQUESTED";

export const UPDATE_INTERVIEW_QUESTION_SUCCESS =
  "UPDATE_INTERVIEW_QUESTION_SUCCESS";

export const SET_CURRENT_QUESTION_SCORE_SUCCESS =
  "SET_CURRENT_QUESTION_SCORE_SUCCESS";

export const SET_CURRENT_QUESTION_SCORE_REQUESTED =
  "SET_CURRENT_QUESTION_SCORE_REQUESTED";

export const SET_CURRENT_QUESTION_SCORE = "SET_CURRENT_QUESTION_SCORE";

export const DELETE_INTERVIEW_REQUESTED = "DELETE_INTERVIEW_REQUESTED";

export const DELETE_INTERVIEW_SUCCESS = "DELETE_INTERVIEW_SUCCESS";

export const UPDATE_INTERVIEW_RESPONSE_REQUESTED =
  "UPDATE_INTERVIEW_RESPONSE_REQUESTED";

export const UPDATE_INTERVIEW_RESPONSE_SUCCESS =
  "UPDATE_INTERVIEW_RESPONSE_SUCCESS";

export const SUBMIT_INTERVIEW_RESPONSE_REQUESTED =
  "SUBMIT_INTERVIEW_RESPONSE_REQUESTED";

export const SUBMIT_INTERVIEW_RESPONSE_SUCCESS =
  "SUBMIT_INTERVIEW_RESPONSE_SUCCESS";

export const UPDATE_INTERVIEW_PAGE = "UPDATE_INTERVIEW_PAGE";

export const UPDATE_CUSTOM_QUESTION_MODAL = "UPDATE_CUSTOM_QUESTION_MODAL";

export const UPDATE_OVERALL_FEEDBACK = "UPDATE_OVERALL_FEEDBACK";

export const SUBMIT_OVERALL_FEEDBACK_REQUESTED = "SUBMIT_OVERALL_FEEDBACK_REQUESTED";

export const SUBMIT_OVERALL_FEEDBACK_SUCCESS = "SUBMIT_OVERALL_FEEDBACK_SUCCESS";

export const SUBMIT_OVERALL_FEEDBACK_FAILURE = "SUBMIT_OVERALL_FEEDBACK_FAILURE";

// export const GET_INTERVIEW_RESPONSE_REQUESTED =
//   "GET_INTERVIEW_RESPONSE_REQUESTED";

// export const GET_INTERVIEW_RESPONSE_SUCCESS = "GET_INTERVIEW_RESPONSE_SUCCESS";
