import React from "react";
import { useSelector } from "react-redux";
import { Route, Navigate, useLocation } from "react-router-dom";


const PublicTestRoute = ({ children, ...rest }) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const search = useLocation().search;
  const query = new URLSearchParams(search).get("public");
  return currentUser || query ? children : <Navigate to="/dashboard" />

};

export default PublicTestRoute;



// const PublicTestRoute = ({ component: Component, ...rest }) => {
//   const { user: currentUser } = useSelector((state) => state.auth);
//   const search = useLocation().search;
//   const query = new URLSearchParams(search).get("public");
//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         currentUser || query ? Component : <Navigate to="/login" />
//       }
//     />
//   );
// };

// export default PublicTestRoute;
