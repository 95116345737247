import React, { useRef, useState } from "react";
import InterviewAccordian from "../../components/modals/InterviewAccordian";
import { ReactComponent as Interviewmicon } from "../../assets/img/svg/interviewmicon.svg";
import { ReactComponent as Interviewvideoon } from "../../assets/img/svg/interviewvideoon.svg";
import { ReactComponent as InterviewfullScreen } from "../../assets/img/svg/fullscreen.svg";
import { ReactComponent as InterviewSwitchScreen } from "../../assets/img/svg/switch.svg";
import { ReactComponent as InterviewScreenshare } from "../../assets/img/svg/screenshare.svg";
import { ReactComponent as Interviewexit } from "../../assets/img/svg/interviewexit.svg";
import { ReactComponent as Settings } from "../../assets/img/svg/settings.svg";
import { ReactComponent as Cross } from "../../assets/img/svg/cross.svg";
import { ReactComponent as EditIcon } from "../../assets/img/svg/edit.svg";
import interview_waiting from "../../assets/img/svg/interview_waiting.svg";
import InterviewCodeEditor from "../../components/modals/InterviewCodeEditor";
import { toggleSidebar } from "../../store/actions/sidebar";
import screenfull from "screenfull";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import io from "socket.io-client";
import StudentChat from "../../components/tests/giveTest/StudentChat";
import {
  setIsAudio,
  setIsVideo,
  setUserScreen,
  setUserVideo,
} from "../../store/actions/test";
import SystemMetrics from "../../components/tests/beforeTest/SystemMetrics";
import { useParams } from "react-router-dom";
import { fetchMyProfile } from "../../store/actions/user";
import {
  generatePeerId,
  generateStudentProctorPeerId,
  peerConfig,
} from "../../components/tests/utils";
import {
  fetchInterviewDetailsRequested,
  getCurrentSocketPeerSuccess,
  setCurrentSection,
  submitInterviewRequested,
  updateCurrentQuestionIndex,
  updateCustomQuestionModal,
  updateInterviewQuestionSuccess,
  updateInterviewResponseRequested,
  updateOverallFeedback,
  submitOverallFeedbackRequested,
} from "../../store/actions/interviewpanel";
import { fetchTurnServerCreds } from "../../store/actions/turnserver";
import Peer from "peerjs";
import { userTypeMapping } from "../../components/tests/constants";
import Modal from "../../components/common/Modal/Modal";
import QuestionBank from "../QuestionBank/QuestionBank";
import { updateQuestionbankModal } from "../../store/actions/questionbank";
import { getType, getUserIdFromPeerId } from "../../components/common/utils";
import {
  copyInsideCodeEditor,
  updateCodeEditor,
  updateCodeEditorInput,
  updateCodeEditorLanguage,
} from "../../store/actions/codeeditor";
import { fetchChatMsgsRequested, sendMessage } from "../../store/actions/chat";
import { updateCurrentSectionIndex } from "../../store/actions/interviewpanel";
import {
  getInterviewerPeer,
  updateInterviewQuestion,
} from "../../services/interviewpanel.service";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../assets/js/toast_config";
import load from "../../assets/img/svg/load.svg";
import { ReactComponent as HamburgerIcon } from "../../assets/img/svg/hamburger.svg";
import plus from "../../assets/img/svg/plus4.svg";
import SubmitModal from "../../components/tests/giveTest/SubmitModal";
import PostgresQuestion from "../../components/tests/PostgresQuestion";
import FullFillments from "../../components/tests/beforeTest/FullFillments";
import RenderQuestion from "../../components/tests/giveTest/RenderQuestion";

import {
  setPostgresError,
  setPostgresTable,
  updateLoadingResponse,
  updatePostgresQuery,
  updatePostgresResult,
} from "../../store/actions/postgres";
import AddQuestion from "../QuestionBank/AddQuestion";
import TabSwitchModal from "../../components/tests/extras/TabSwitchModal";
import CountDownBar from "../../components/tests/giveTest/CountDownBar";
import axios from "axios";
import { BASE_URL } from "../../assets/js/config";
import authHeader from "../../services/auth-header";
import UniversalButton from "../../components/common/StandardButton/UniversalButton";
import WhiteBoard from "./Whiteboard/WhiteBoard";
import { createTLStore, defaultShapeUtils } from "@tldraw/tldraw";

export default function InterviewPanel() {
  const params = useParams();
  const [chatMessageLoader, setchatMessageLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [currentVideoDevice, setcurrentVideoDevice] = useState(null);
  const [currentAudioInputDevice, setcurrentAudioInputDevice] = useState(null);
  const [currentAudioOutputDevice, setcurrentAudioOutputDevice] =
    useState(null);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const interviewId = useParams()?.id;
  const scrollRef = useRef(null);
  const peerRef = useRef(true);
  const dispatch = useDispatch();
  const { opened: sideBarOpened } = useSelector((state) => state.sidebar);
  const { videoStream, isVideo, screenStream } = useSelector(
    (state) => state.test
  );
  const { questionModal } = useSelector((state) => state.questionbank);
  const { credentials } = useSelector((state) => state.turnserver);
  const [whiteBoard, setWhiteBoard] = useState(false);
  const {
    currentSectionIndex,
    currentQuestionIndex,
    interviewerPeerId,
    interviewDetails,
    response,
    pageNumber,
    customQuestionModal,
    loader,
  } = useSelector((state) => state.interviewpanelReducer);

  const videoRef = useRef(null);
  const remoteRef = useRef(null);
  const screenRef = useRef(null);
  const interviewerPeerIdRef = useRef(null);
  const socketRef = useRef(null);
  const uniqueUserIdRef = useRef(null);
  const isOurVideoRef = useRef(null);
  const isOurMicMutedRef = useRef(null);
  const { profile } = useSelector((state) => state.user);
  const uniqueuserId = profile ? profile._id : null;

  const [peerId, setPeerId] = useState("");
  const [videoPeer, setVideoPeer] = useState(null);
  const [socket, setSocket] = useState(null);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const isSocketConnectedRef = useRef(null);
  const [isOurMicMuted, setIsOurMicMuted] = useState(true);
  const [ourVideo, setIsOurVideo] = useState(false);
  const [isOthersMicMuted, setIsOthersMicMuted] = useState(true);

  //chat
  const [chatInput, setchatInput] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { messages } = useSelector((state) => state.chat);

  const [showleftbar, setShowLeftBar] = useState(true);
  const [showRightbar, setShowRightBar] = useState(true);
  const [viewStyle, setViewStyle] = useState(true);
  const [isFullScreen, setisFullScreen] = useState(false);
  const [isRemoteVideo, setIsRemoteVideo] = useState(false);
  const [toggleSocket, setToggleSocket] = useState(false);
  const [EditorRunType, setEditorRunType] = useState("");
  const [isInterviewerConnected, setisInterviewerConnected] = useState(false);
  const [interviewSubmitModal, setSubmitModal] = useState(false);
  const [isScreenShared, setIsScreenShared] = useState(false);
  const [displayPeerId, setDisplayPeerId] = useState("");
  const [displayPeer, setDisplayPeer] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(true);

  const tabSwitchCount = 5;
  const [tabChangecounter, setTabChangecounter] = useState(0);
  const [tabSwitchModal, setTabSwitchModal] = useState(false);

  const [store] = useState(() => createTLStore({ shapeUtils: defaultShapeUtils }))

  //function to close and save the test when user closes the tab switch modal
  const handleCloseTabSwitchModal = () => {
    setTabSwitchModal(false);

    if (tabChangecounter > tabSwitchCount) {
      saveInterviewResponse({ isSubmit: false });
      localStorage.removeItem(`${params.id}-tabChangecounter`);
      window.open(`${window.location.origin}/interviews`, "_self");
    }
  };

  useEffect(() => {
    interviewerPeerIdRef.current = interviewerPeerId;
  }, [interviewerPeerId]);

  useEffect(() => {
    if (interviewerPeerId)
      socketRef.current.emit("send-tldraw-state", {
        userId: getUserIdFromPeerId(interviewerPeerId),
        state: whiteBoard,
        type: 'M'
      });
  }, [whiteBoard]);

  useEffect(() => {
    isSocketConnectedRef.current = isSocketConnected;
  }, [isSocketConnected]);

  useEffect(() => {
    isOurVideoRef.current = ourVideo;
  }, [ourVideo]);

  useEffect(() => {
    isOurMicMutedRef.current = isOurMicMuted;
  }, [isOurMicMuted]);

  useEffect(() => {
    socketRef.current = socket;
  }, [socket]);

  useEffect(() => {
    uniqueUserIdRef.current = profile;
  }, [profile]);

  //triggering function for visibility eventlistener
  const handleTabSwitch = () => {
    if (document.visibilityState === "hidden") {
      axios.put(
        `${BASE_URL}api/interview-assign/update-tab-changes/${params.id}`,
        {},
        {
          headers: {
            ...authHeader(),
          },
        }
      );
      dispatch(copyInsideCodeEditor(false));
      const prevCount = JSON.parse(
        localStorage.getItem(`${params.id}-tabChangecounter`)
      );
      localStorage.setItem(`${params.id}-tabChangecounter`, prevCount + 1);
      setTabChangecounter(prevCount + 1);
      setTabSwitchModal(true);
    }
  };

  //useeffect for creating the peerids for video and display stream
  useEffect(() => {
    if (uniqueuserId !== null && interviewId) {
      let peerId = generatePeerId(userTypeMapping[getType()], {
        testId: interviewId,
        userId: uniqueuserId,
      });
      setPeerId(peerId);

      //student/teacher-display-interviewId-uniqueuserid
      let displayPeerId = generateStudentProctorPeerId(
        userTypeMapping[getType()],
        {
          peerType: "display",
          testId: interviewId,
          userId: uniqueuserId,
        }
      );
      setDisplayPeerId(displayPeerId);
    }
  }, [uniqueuserId]);

  //custom interval for updating the response for every 10 seconds from student side
  const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }

      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }, [delay]);
  };

  //updating response for every 10 seconds
  useInterval(() => {
    if (getType() === "S") {
      saveInterviewResponse({ isSubmit: false });
    }
  }, 10000);

  //function for saving interview response based on arugement which decides whether submitted by student({isSubmit: true}) or automatic submission({isSubmit:false})
  const saveInterviewResponse = ({ isSubmit }) => {
    const submitInterview = () => {
      dispatch(
        submitInterviewRequested({
          id: interviewId,
          response,
          isSubmit,
        })
      );
    };

    getType() === "S" && submitInterview();
  };

  //eventlistener when user clicked on stopsharing button of screen sharing
  useEffect(() => {
    if (screenStream) {
      screenStream.getVideoTracks()[0].addEventListener("ended", () => {
        stopSharing();
      });
    }
  }, [screenStream]);

  //for fetching messages in the chat box
  useEffect(() => {
    if (interviewerPeerId) {
      dispatch(
        fetchChatMsgsRequested({
          testId: interviewId,
          userId: getUserIdFromPeerId(interviewerPeerId),
        })
      );
    }
  }, [interviewId, interviewerPeerId]);

  //inititializing different peer connectiions for video and display
  useEffect(() => {
    if (
      credentials.username &&
      credentials.password &&
      uniqueuserId &&
      displayPeerId &&
      peerId &&
      peerRef.current
    ) {
      peerRef.current = false;
      const peerconfig = peerConfig(credentials);
      const peer = new Peer(peerId, peerconfig);
      const displayPeer = new Peer(displayPeerId, peerConfig);

      peer.on("open", () => {
        setVideoPeer(peer);
      });

      displayPeer.on("open", () => {
        setDisplayPeer(displayPeer);
      });

      peer.on("close", () => {
        console.log("Video peer disconneted 338", peer);
      });

      displayPeer.on("close", () => {
        console.log("Display peer disconneted 342", peer);
      });
    }
  }, [credentials, uniqueuserId, videoStream]);

  //function for naming user browser
  const getUserBrowser = () => {
    let userAgent = navigator.userAgent;
    let browserName;
    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "Chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "Firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "Safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "Opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "Edge";
    } else {
      browserName = "No browser detection";
    }
    return browserName;
  };

  // for different socket connections (incoming calls)
  useEffect(() => {
    if (videoStream && peerId && uniqueuserId && !isSocketConnected) {
      const sockets = io(process.env.REACT_APP_SOCKET_URL, {
        secure: true,
        withCredentials: true,
        transports: ["websocket"], // Explicitly set the transport to WebSocket
      });

      sockets.on("connect", () => {
        if (!isSocketConnectedRef.current) {
          setIsSocketConnected(true);
          // console.log("checkasdasdas");
          sockets.emit("new-socket-interview", {
            userId: uniqueuserId,
            socketId: sockets.id,
            interviewId: interviewId,
            peerId: peerId,
            userType: userTypeMapping[getType()],
            startTime: Date.now(),
            userAgent: getUserBrowser(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          });
        }
      });

      sockets.on("recieve-mic-state", (data) => {
        if (data.type === "I") {
          setIsOurMicMuted(data.state);
        } else {
          setIsOthersMicMuted(data.state);
        }
      });

      sockets.on("recieve-editor-language-state", (data) => {
        dispatch(updateCodeEditorLanguage(data));
      });

      sockets.on("recieve-editor-code-state", (data) => {
        dispatch(updateCodeEditor(data));
      });

      sockets.on("recieve-selected-question", () => {
        dispatch(fetchInterviewDetailsRequested(interviewId));
      });

      sockets.on("receiving-msg", (data) => {
        dispatch(sendMessage(data));
        setShowModal(true);
      });

      sockets.on("recieve-current-section-state", (data) => {
        dispatch(updateCurrentQuestionIndex(null));
        dispatch(updateCurrentSectionIndex(data));
      });

      sockets.on("recieve-current-question-state", (data) => {
        dispatch(updateCurrentQuestionIndex(data));
      });

      sockets.on("recieve-our-stream-state", (data) => {
        if (data.type === "I") {
          setOnClickStream(data.state);
        } else if (data.type === "C") {
          setIsRemoteVideo(data.state);
          // if (getType() === "S" && data.state)
          //   sendStreamToInterviewer();
        } else if (data.type === "S") {
          setIsScreenShared(data.state);
        }
      });

      sockets.on("recieve-code-editor-input-state", (data) => {
        dispatch(updateCodeEditorInput(data.state));
      });

      sockets.on("recieve-add-section", () => {
        dispatch(setCurrentSection());
      });

      sockets.on("recieve-run-code-editor", async (data) => {
        setEditorRunType(data);
        setToggleSocket((prev) => !prev);
      });

      sockets.on("recieve-screen-event", (data) => {
        if (data) {
          toast.success("Student is on full screen mode!", defaultToastSetting);
          setisFullScreen(true);
        } else {
          setisFullScreen(false);
          toast.warning(
            "Student has exited the full screen.",
            defaultToastSetting
          );
        }
      });

      sockets.on("interviewer-connected", (data) => {
        dispatch(getCurrentSocketPeerSuccess(data?.peerId));
        setisInterviewerConnected(true);
        setIsOthersMicMuted(true);
        if (getType() === "S") {
          // sendStreamToInterviewer();
          // console.log("socket", socketRef.current,socket)
          if (!isFullScreen) {
            socketRef.current.emit("send-screen-event", {
              userId: getUserIdFromPeerId(interviewerPeerId),
              state: false,
            });
          } else {
            socketRef.current.emit("send-screen-event", {
              userId: getUserIdFromPeerId(interviewerPeerId),
              state: true,
            });
          }
        }
        // socketRef.current.emit("send-current-state",{
        //   userId: getUserIdFromPeerId(interviewerPeerId),
        //   data: {
        //     isOurMicMuted,
        //     isVideo,
        //     currentSectionIndex:{index:currentSectionIndex,isReleased: interviewDetails?.components[currentSectionIndex]?.isReleased},
        //     currentQuestionIndex,
        //   }
        // })
        toast.success(data.msg, defaultToastSetting);
      });

      sockets.on("recieve-current-state", async (data) => {
        // console.log("check",data)
        setIsOthersMicMuted(data.isOurMicMuted);
        setIsRemoteVideo(data.ourVideo);
        // dispatch(updateCurrentSectionIndex(data.currentSectionIndex))
        // dispatch(updateCurrentQuestionIndex(data.currentQuestionIndex));
      });

      sockets.on("recieve-response-event", (data) => {
        console.log("recieved response", data)
        dispatch(updateInterviewResponseRequested(data));
      });

      sockets.on("recieve-status-notification", (data) => {
        const { msg, type } = data;
        if (type === "w") {
          toast.warning(msg, defaultToastSetting);
        } else if (type === "s") {
          toast.success(msg, defaultToastSetting);
        } else {
          toast.error(msg, defaultToastSetting);
        }
      });

      sockets.on("recieve-postgres-events", (data) => {
        const { state, type } = data;
        if (type === "query") {
          dispatch(updatePostgresQuery(state));
        } else if (type === "result") {
          dispatch(updatePostgresResult(state));
        } else if (type === "error") {
          dispatch(setPostgresError(state));
        } else if (type === "loading") {
          dispatch(updateLoadingResponse(state));
        } else if (type === "tables") {
          dispatch(setPostgresTable(state));
        }
      });

      sockets.on("recieve-tldraw-state", (data) => {
        console.log("stata recieved", data)
        if (data?.type === 'M') { setWhiteBoard(data?.state) }
        else {
          const snapshot = JSON.parse(data?.state)
          store.loadSnapshot(snapshot)
        }
      });

      sockets.on("disconnection-event", (data) => {
        if (getType() === "S") saveInterviewResponse({ isSubmit: false });
        toast.warning(data.msg, defaultToastSetting);
        setIsRemoteVideo(false);
        setIsOthersMicMuted(true);
        setIsScreenShared(false);
        setisFullScreen(false);
        setisInterviewerConnected(false);
      });

      setSocket(sockets);
    }
  }, [videoStream, videoPeer, uniqueuserId]);

  // for incoming display peer call from interviewer to student for display stream
  useEffect(() => {
    if (!displayPeer) return;
    displayPeer.on("call", (call) => {
      call.answer();
      call.on("stream", (remoteStream) => {
        setIsScreenShared(true);
        toast.success(
          "Interviewer sharing screen now. Please pay attention.",
          defaultToastSetting
        );
        screenRef.current.srcObject = remoteStream;
        dispatch(setUserScreen(remoteStream));
      });
    });
  }, [displayPeer]);

  //for incoming video peer call for video streams
  useEffect(() => {
    if (!videoPeer || videoStream === null) return;
    videoPeer.on("call", (call) => {
      call.answer(videoStream);

      socketRef.current.emit("send-current-state", {
        userId: getUserIdFromPeerId(interviewerPeerIdRef.current),
        data: {
          isOurMicMuted: isOurMicMutedRef.current,
          ourVideo: isOurVideoRef.current,
        },
      });
      call.on("stream", (remoteStream) => {
        // console.log("remote",remoteStream)
        // setIsRemoteVideo(true);
        if (remoteRef && remoteRef.current)
          remoteRef.current.srcObject = remoteStream;
      });
    });
  }, [videoPeer, videoStream, socketRef]);

  //for sending stream to interviewer from student
  useEffect(() => {
    // console.log("checking",/((getType()==='S' && pageNumber === 3)||(getType()==='P')) ,
    // isVideo ,
    // videoStream !== null ,
    // interviewerPeerId ,
    // // isCalled.current === false ,
    // // getType() === "S" ,
    // videoPeer)
    if (
      ((getType() === "S" && pageNumber === 3) || getType() === "P") &&
      isVideo &&
      videoStream !== null &&
      interviewerPeerId &&
      // isCalled.current === false &&
      videoPeer
    ) {
      // console.log("checking",socketRef,ourVideo)
      socketRef.current.emit("send-current-state", {
        userId: getUserIdFromPeerId(interviewerPeerId),
        data: {
          isOurMicMuted,
          ourVideo,
        },
      });
      sendStreamToInterviewer();
      // isCalled.current = true;
    }

    if (
      pageNumber === 3 &&
      videoStream !== null &&
      videoRef &&
      videoRef.current
    ) {
      videoRef.current.srcObject = videoStream;
    }
  }, [
    ourVideo,
    isVideo,
    videoStream,
    interviewerPeerId,
    videoPeer,
    pageNumber,
  ]);

  // useEffect(()=>{
  //   if(videoStream!==null && isVideo && interviewerPeerId && getType()==='P' && videoPeer){
  //     sendStreamToInterviewer();
  //   }
  // },[videoStream,interviewerPeerId,isVideo,videoPeer]);

  //function for sending  video stream
  const sendStreamToInterviewer = () => {
    // console.log("interver",interviewerPeerIdRef.current,videoStream,videoPeer)
    try {
      const call = videoPeer.call(interviewerPeerIdRef.current, videoStream);

      call.on("stream", (remoteStream) => {
        // console.log("remote",remoteRef.current.srcObject)
        // setIsRemoteVideo(true);
        if (remoteRef.current.srcObject === null)
          remoteRef.current.srcObject = remoteStream;
      });
    } catch (err) {
      console.log("peer error", err);
    }
  };

  // for setting tab change counter and event listener
  useEffect(() => {
    if (getType() === "S" && interviewDetails.is_disable_tab_switch) {
      if (localStorage.getItem(`${params.id}-tabChangecounter`) === null) {
        localStorage.setItem(`${params.id}-tabChangecounter`, 0);
      } else {
        const count = JSON.parse(
          localStorage.getItem(`${params.id}-tabChangecounter`)
        );
        setTabChangecounter(count);
        setTabSwitchModal(count > tabSwitchCount ? true : false);
      }
      document.addEventListener("visibilitychange", handleTabSwitch);
    }
  }, [JSON.stringify(interviewDetails)]);

  // fetching interviewer peer if someone already joined
  useEffect(() => {
    if (getType() === "P" && sideBarOpened) {
      dispatch(toggleSidebar());
    }
    getInterviewerPeer(interviewId)
      .then((res) => {
        if (
          (res.success && Object.keys(res.data["I"]).length !== 0) ||
          (getType() === "P" && Object.keys(res.data["S"]).length !== 0)
        ) {
          setisInterviewerConnected(true);
          let peerId =
            getType() === "S"
              ? Object.keys(res.data["I"])
              : Object.keys(res.data["S"]);
          dispatch(getCurrentSocketPeerSuccess(peerId[0]));
        } else {
          setisInterviewerConnected(false);
        }
      })
      .catch((err) => console.log("peer,err", err));

    if (getType() === "P") {
      dispatch(fetchInterviewDetailsRequested(interviewId));
      dispatch(fetchMyProfile());
      dispatch(fetchTurnServerCreds());
    }

    setUserStream(false);
    return () => {
      disConnectPeer();
    };
  }, []);

  useEffect(() => {
    //full screen logic
    if (document.body && isSocketConnected) {
      document.body.addEventListener("fullscreenerror", function () {
        console.log("error in fullscreening");
      });

      if (screenfull.isEnabled) {
        screenfull.on("change", () => {
          if (screenfull.isFullscreen) {
            socketRef.current &&
              socketRef.current.emit("send-screen-event", {
                userId: getUserIdFromPeerId(interviewerPeerIdRef.current),
                state: true,
              });
            setisFullScreen(true);
          }
          if (!screenfull.isFullscreen) {
            socketRef.current &&
              socketRef.current.emit("send-screen-event", {
                userId: getUserIdFromPeerId(interviewerPeerIdRef.current),
                state: false,
              });
            setisFullScreen(false);
          }
        });
      }
    }
  }, [isSocketConnected]);

  useEffect(() => {
    setUserStream(ourVideo);
    console.log("check");
    // sendStreamToInterviewer();
  }, [currentVideoDevice, currentAudioInputDevice, currentAudioOutputDevice]);

  const setUserStream = async (state) => {
    await navigator.mediaDevices
      .getUserMedia({
        video: {
          height: { exact: 144 },
          deviceId: currentVideoDevice,
        },
        audio: {
          deviceId: currentAudioInputDevice,
          noiseSuppression: true,
          echoCancellation: true,
        },
      })
      .then((stream) => {
        dispatch(setUserVideo(stream));
        dispatch(setIsVideo(true));
        dispatch(setIsAudio(true));
        // console.log("checkin is iurcideo",state)
        setIsOurVideo(state);
        // setIsOurMicMuted(false);
      })
      .catch((err) => {
        console.log(err);
        seterrorMessage(err);
      });
  };

  useEffect(() => {
    if (showModal) {
      scrollToBottom();
    }
  }, [showModal]);

  const handleChatModal = () => {
    // if (!showModal)
    //   dispatch(
    //     fetchChatMsgsRequested({
    //       testId: interviewId,
    //       userId: getUserIdFromPeerId(interviewerPeerId),
    //     })
    //     // () => setShowModal(!showModal)
    //   );
    // setUnseenmessages(0);
    // setNotification(false);
    // dispatch(
    //   fetchChatMsgsRequested({
    //     testId: interviewId,
    //     userId: getUserIdFromPeerId(interviewerPeerId),
    //   })
    // );
    setShowModal(!showModal);
    scrollToBottom();
  };

  //for sending messages
  const handleSendingMessage = () => {
    setchatMessageLoader(true);
    if (isSocketConnected && interviewerPeerId && interviewId && uniqueuserId) {
      socketRef.current.emit(
        "sending-msg",
        {
          msg: chatInput,
          userId: getUserIdFromPeerId(interviewerPeerId),
          interviewId,
          uniqueuserId,
        },
        (message) => {
          dispatch(sendMessage(message));
          setchatInput("");
          setchatMessageLoader(false);
          scrollToBottom();
        }
      );
    }
  };

  //automatic scroll to bottom when new message sent
  const scrollToBottom = () => {
    if (scrollRef.current)
      scrollRef.current.scrollIntoView({ behaviour: "smooth" });
  };

  const handleAudioInputChange = (id) => {
    setcurrentAudioInputDevice(id);
  };

  const handleAudioOutputChange = (id) => {
    setcurrentAudioOutputDevice(id);
  };

  const handleVideoChange = (id) => {
    setcurrentVideoDevice(id);
  };

  //function for trimming the question
  const getShortQuestion = (question) => {
    let stripedQuestion = question.replace(/(<([^>]+)>)/gi, "");
    return stripedQuestion.length > 82
      ? stripedQuestion.slice(0, 82) + "..."
      : stripedQuestion;
  };

  const onClickQuestion = async (index) => {
    if (getType() === "S") {
      saveInterviewResponse({ isSubmit: false });
    }
    if (interviewDetails?.components[currentSectionIndex]?.isReleased) {
      socketRef.current.emit("update-current-question", {
        state: index,
        userId: getUserIdFromPeerId(interviewerPeerId),
      });
    }
    dispatch(updateCurrentQuestionIndex(index));
  };

  //function for activating our mic
  const onClickUserMic = async () => {
    setIsOurMicMuted(!isOurMicMuted);
    socketRef.current.emit(
      "send-mic-state",
      {
        state: !isOurMicMuted,
        userId: getUserIdFromPeerId(interviewerPeerId),
        type: "C",
      }
    );
  };

  //for activating other user mic
  const onClickOtherUserMic = async () => {
    setIsOthersMicMuted(!isOthersMicMuted);
    socketRef.current.emit(
      "send-mic-state",
      {
        state: !isOthersMicMuted,
        userId: getUserIdFromPeerId(interviewerPeerId),
        type: "I",
      });
  };

  //socket call for sending our stream state
  const onActionOthersVideoStream = async () => {
    socketRef.current.emit(
      "send-our-stream-state",
      {
        state: isRemoteVideo,
        userId: getUserIdFromPeerId(interviewerPeerId),
        type: "I",
      },
      (res) => {
        if (res.success) {
          setIsRemoteVideo(!isRemoteVideo);
        } else {
          toast.warning(res.msg, defaultToastSetting);
        }
      }
    );
  };

  const onActionVideoStream = async () => {
    socketRef.current.emit("send-our-stream-state", {
      state: !ourVideo,
      userId: getUserIdFromPeerId(interviewerPeerId),
      type: "C",
    });
    setOnClickStream(ourVideo);
  };

  //stream on/off
  const setOnClickStream = async (state) => {
    // console.log("func",state)
    if (state) {
      videoStream.getVideoTracks().forEach(function (track) {
        if (track.readyState === "live" && track.kind === "video") {
          track.stop();
          videoRef.current.srcObject = null;
          track.enabled = false;
          dispatch(setIsVideo(false));
          setIsOurVideo(false);
        }
      });
    } else {
      setUserStream(true);
    }
  };

  //function for opening question modal if section is selected
  const handleQuestionbankModal = () => {
    if (currentSectionIndex !== null && currentSectionIndex !== "null") {
      dispatch(updateQuestionbankModal({ value: true }));
    } else {
      toast.warning("Please select the section!", defaultToastSetting);
    }
  };

  //function for updating section index based on lock/unlock
  const updateSectionIndex = ({ index, isReleased }) => {
    dispatch(updateCurrentSectionIndex({ index, isReleased }));
    dispatch(updateCurrentQuestionIndex(null));
    if (getType() !== "S") {
      socketRef.current.emit("update-current-section", {
        state: { index, isReleased },
        userId: getUserIdFromPeerId(interviewerPeerId),
      });
    }
  };

  //function for exiting interviews
  const exitInterview = () => {
    saveInterviewResponse({ isSubmit: false });
    let keysToRemove = [
      "currentLanguage",
      "code",
      "currentSectionIndex",
      "currentQuestionIndex",
    ];
    for (let key of keysToRemove) {
      localStorage.removeItem(key);
    }
    disConnectPeer();
  };

  //function for disconnecting the peer
  const disConnectPeer = () => {
    if (interviewerPeerIdRef && interviewerPeerIdRef.current !== "") {
      socketRef.current.emit(
        "disconnect-me",
        {
          userId: uniqueUserIdRef.current._id,
          userType: userTypeMapping[getType()],
          othersId: getUserIdFromPeerId(interviewerPeerIdRef.current),
          user:
            getType() === "S"
              ? uniqueUserIdRef.current?.name || "Student"
              : "Interviewer",
        },
        (response) => {
          // console.log("res",response)
          if (response.status) {
            socketRef.current.close();
            if (displayPeer) {
              displayPeer.disconnect();
              displayPeer.destroy();
            }
            if (videoPeer) {
              videoPeer.disconnect();
              videoPeer.destroy();
            }

            window.location.href = "/interviews";
          } else {
            toast.warning(response.message, defaultToastSetting);
          }
        }
      );
    } else {
      window.location.href = "/interviews";
    }
  };

  //handling view style for code editor
  const onClicksetViewStyle = () => {
    if (viewStyle) {
      setShowLeftBar(false);
      setViewStyle(false);
    } else {
      setViewStyle(true);
    }
  };

  //toggle function for sharing screen
  const shareScreen = async () => {
    if (isScreenShared) {
      stopSharing();
    } else {
      await navigator.mediaDevices
        .getDisplayMedia({
          video: {
            height: 480,
          },
        })
        .then(async (screenStream) => {
          const student_peer = generateStudentProctorPeerId("student", {
            peerType: "display",
            testId: interviewId,
            userId: getUserIdFromPeerId(interviewerPeerId),
          });
          // console.log(
          //   "checking start peer",
          //   displayPeer,
          //   screenStream,
          //   student_peer
          // );
          dispatch(setUserScreen(screenStream));
          displayPeer.call(student_peer, screenStream);
          // call.on("stream", (data) => {
          //   console.log("stream response0", data);
          // });
          setIsScreenShared(true);
        })
        .catch((err) => console.log(err));
    }
  };

  const stopSharing = () => {
    screenStream.getTracks().forEach(function (track) {
      // console.log("screen track", track);
      if (track.kind === "video") {
        // console.log("screen stopped");
        track.stop();
        track.enabled = false;
        socketRef.current.emit("send-our-stream-state", {
          state: false,
          userId: getUserIdFromPeerId(interviewerPeerId),
          type: "S",
        });
        setIsScreenShared(false);
      }
    });
  };

  //deleting the question and sending socket calls for student
  const handleDeleteQuestion = (index) => {
    let _components = [...interviewDetails?.components];
    let _questions = [..._components[currentSectionIndex].components];
    _questions.splice(index, 1);
    _components[currentSectionIndex].components = _questions;

    updateInterviewQuestion({
      data: {
        components: _components,
        isReleased: _components[currentSectionIndex]?.isReleased,
      },
      id: interviewId,
    })
      .then((res) => {
        if (res.success) {
          dispatch(updateCurrentQuestionIndex(null));
          dispatch(updateInterviewQuestionSuccess(res.data.components));
          if (interviewerPeerId) {
            socketRef.current.emit("send-selected-question", {
              userId: getUserIdFromPeerId(interviewerPeerId),
            });
          }
        }
      })
      .catch((err) => {
        toast.error("Question not Deleted", defaultToastSetting);
      });
  };

  const handleAddSection = () => {
    let _components = [
      ...interviewDetails?.components,
      { components: [], isReleased: false },
    ];
    updateInterviewQuestion({
      data: { components: _components },
      id: interviewId,
    })
      .then((res) => {
        if (res.success) {
          dispatch(updateCurrentSectionIndex(null));
          dispatch(updateInterviewQuestionSuccess(res.data.components));
          if (interviewerPeerId) {
            socketRef.current.emit("send-selected-question", {
              userId: getUserIdFromPeerId(interviewerPeerId),
            });
          }
        }
      })
      .catch((err) => {
        console.log("error", err);
        toast.error("Failed to update section!", defaultToastSetting);
      });
  };

  const handleDeleteSection = (index) => {
    let _components = [...interviewDetails?.components];
    _components.splice(index, 1);
    updateInterviewQuestion({
      data: {
        components: _components,
        isReleased: _components[currentSectionIndex]?.isReleased,
      },
      id: interviewId,
    })
      .then((res) => {
        if (res.success) {
          dispatch(updateInterviewQuestionSuccess(res.data.components));
          if (interviewerPeerId) {
            socketRef.current.emit("send-selected-question", {
              userId: getUserIdFromPeerId(interviewerPeerId),
            });
          }
        }
      })
      .catch((err) => {
        toast.error("Section not Deleted", defaultToastSetting);
      });
  };

  const onCopyHandler = (e) => {
    e.preventDefault();
    toast.error("Coping is restricted");
  };

  const onPasteHandler = (e) => {
    e.preventDefault();
    toast.error("Pasting is restricted");
  };

  const handleSubmitTest = () => {
    saveInterviewResponse({ isSubmit: true });
    toast.success("Interview submitted succesfully.", defaultToastSetting);
    disConnectPeer();
  };

  const handleCustomQuestionModal = () => {
    if (currentSectionIndex !== null && currentSectionIndex !== "null") {
      dispatch(updateCustomQuestionModal({ value: true }));
    } else {
      toast.warning("Please select the section!", defaultToastSetting);
    }
  };

  const setFeedback = (value) => {
    dispatch(updateOverallFeedback({ key: "overall_feedback", value }));
  };

  const submitFeedback = () => {
    dispatch(
      submitOverallFeedbackRequested({
        id: interviewId,
        data: {
          key: "overall_feedback",
          value: interviewDetails?.overall_feedback,
        },
      })
    );
  };

  const renderQuestion = (type) => {
    switch (type) {
      case "coding":
        return (
          <>
            <button
              onClick={() => onClicksetViewStyle()}
              className="absolute top-2 right-6 border border-[#B8FB3C] rounded-md bg-white text-xs text-[#B8FB3C] sm:px-2 py-1.5"
            >
              Change view
            </button>
            <InterviewCodeEditor
              socket={socket}
              toggleSocket={toggleSocket}
              EditorRunType={EditorRunType}
              is_disable_copy_paste={interviewDetails?.is_disable_copy_paste}
            />
          </>
        );
      case "sql":
        return (
          <>
            <PostgresQuestion
              type="I"
              socket={socket}
              interviewerPeerId={interviewerPeerId}
            />
          </>
        );

      default:
        return (
          <div className="p-2 pl-3">
            <RenderQuestion
              type="I"
              interviewerPeerId={interviewerPeerId}
              testId={interviewId}
              socket={socket}
              question={{
                ...interviewDetails?.components[currentSectionIndex]
                  ?.components[currentQuestionIndex],
                questionIndex: currentQuestionIndex,
              }}
              index={currentQuestionIndex + 1}
              section={{
                ...interviewDetails?.components[currentSectionIndex],
                sectionIndex: currentSectionIndex,
              }}
              sections={interviewDetails?.components}
              response={response}
            />
          </div>
        );
    }
  };

  const allPages = () => {
    switch (pageNumber) {
      case 1:
        return (
          <>
            <SystemMetrics
              socket={socket}
              userId={uniqueuserId}
              testId={interviewId}
              error={errorMessage}
              interviewerPeerId={interviewerPeerId}
              deviceInfos={[]}
              isEntireScreenShared={true}
              currentVideoDevice={currentVideoDevice}
              currentAudioOutputDevice={currentAudioOutputDevice}
              currentAudioInputDevice={currentAudioInputDevice}
              handleAudioInputChange={(data) => handleAudioInputChange(data)}
              handleAudioOutputChange={(data) => handleAudioOutputChange(data)}
              handleVideoChange={(data) => handleVideoChange(data)}
              isModal={false}
              type="I"
            />
          </>
        );
      case 2:
        return (
          <>
            <FullFillments
              type="I"
              socket={socketRef.current}
              userId={uniqueuserId}
              interviewerPeerId={interviewerPeerId}
              testId={interviewId}
              currentVideoDevice={currentVideoDevice}
            />
          </>
        );
      case 3:
        return (
          <>
            {tabSwitchModal &&
              getType() === "S" &&
              interviewDetails?.is_disable_tab_switch && (
                <TabSwitchModal
                  counter={tabChangecounter}
                  type="I"
                  tabSwitchCount={tabSwitchCount}
                  handleCloseTabSwitchModal={() => handleCloseTabSwitchModal()}
                />
              )}
            {interviewSubmitModal && (
              <SubmitModal
                type="I"
                submitTest={() => handleSubmitTest()}
                handleSubmitModal={() => setSubmitModal(false)}
              />
            )}
            <Modal
              onClose={() =>
                dispatch(updateCustomQuestionModal({ value: false }))
              }
              isModal={customQuestionModal}
              showCloseButton
              className={"md:w-[70%]"}
            >
              <AddQuestion type="I" socket={socket} interviewId={interviewId} />
            </Modal>

            <Modal
              onClose={() =>
                dispatch(updateQuestionbankModal({ value: false }))
              }
              isModal={questionModal}
              showCloseButton
              className="w-[80%] pt-[35px]"
            >
              <QuestionBank
                isModal={true}
                modalType="I"
                sectionIndex={0}
                questionIndex={0}
                socket={socket}
              />
            </Modal>
            <Modal
              onClose={() => setWhiteBoard((prev) => !prev)}
              isModal={whiteBoard}
              showCloseButton
              className="w-[80%] pt-[35px]"
            >
              <WhiteBoard socket={socket} store={store} />
            </Modal>
            {getType() === "S" && !isInterviewerConnected && (
              <Modal isModal={true} className="w-[50%]">
                <div className="flex flex-col gap-y-5 items-center justify-center pt-4">
                  <img
                    src={interview_waiting}
                    title="wait"
                    alt="wait"
                    className="h-[40vh]"
                  />
                  <p className="font-['poppins'] text-center px-10">
                    Hello, Thank you for joining the interview session. Please
                    be patient and wait for the interviewer to join the session.
                    They will be with you shortly. Thank you for your
                    cooperation, and we appreciate your patience. All the best !
                  </p>
                  <button
                    onClick={() => exitInterview()}
                    className="hover:text-[#dd0000] rounded border border-[#dd0000] hover:bg-white bg-[#dd0000] text-sm text-white py-2 px-3"
                  >
                    Exit Interview
                  </button>
                </div>
              </Modal>
            )}
            {getType() === "S" && !isFullScreen && (
              <Modal isModal={true} className="flex flex-col items-center justify-center gap-5">
                You can continue with the test only while you are on fullscreen.
                Click below to go Fullscreen again
                <UniversalButton onClickFunction={() => {
                  if (screenfull.isEnabled) {
                    screenfull.toggle();
                  }
                }} title="Go Full Screen" />

              </Modal>
            )}
            {showSettingsModal && (
              <div
                className={`overflow-y-auto overflow-x-hidden  fixed flex flex-col z-50 justify-center 
                  items-center h-full w-full top-0 left-0 `}
                id="popup-modal"
                style={{ backgroundColor: "rgb(128 ,128 ,128,0.7)" }}
              >
                <SystemMetrics
                  socket={socket}
                  userId={uniqueuserId}
                  testId={interviewId}
                  error={errorMessage}
                  deviceInfos={[]}
                  isEntireScreenShared={true}
                  currentAudioOutputDevice={currentAudioOutputDevice}
                  currentVideoDevice={currentVideoDevice}
                  currentAudioInputDevice={currentAudioInputDevice}
                  handleAudioInputChange={(data) =>
                    handleAudioInputChange(data)
                  }
                  handleAudioOutputChange={(data) =>
                    handleAudioOutputChange(data)
                  }
                  handleVideoChange={(data) => handleVideoChange(data)}
                  isModal={true}
                  setIsModal={() => setShowSettingsModal(false)}
                  type="I"
                />
              </div>
            )}

            <div
              className="relative"
              onCopy={(e) =>
                interviewDetails?.is_disable_copy_paste
                  ? onCopyHandler(e)
                  : null
              }
              onPaste={(e) =>
                interviewDetails?.is_disable_copy_paste
                  ? onPasteHandler(e)
                  : null
              }
            >
              <div className="flex divide-x w-full pb-1  bg-[#F5F5F5] font-['poppins']">
                <div className={` ${showleftbar ? "w-[18%]" : "w-[3%]"}`}>
                  <div className="flex p-2 items-center justify-center" >
                    {showleftbar && (
                      <h1 className="text-[0.9rem] font-semibold px-1">
                        {interviewDetails?.interview_title}
                      </h1>
                    )}
                    <div
                      className={`ml-auto ${showleftbar
                        ? "flex"
                        : "flex flex-col-reverse justify-center items-center"
                        } flex gap-x-3`}
                    >
                      <Settings
                        fill={"#047575"}
                        className="hover:cursor-pointer"
                        alt="icon"
                        onClick={() => setShowSettingsModal(true)}
                      />
                      <HamburgerIcon
                        className="w-[20px] cursor-pointer"
                        onClick={() => setShowLeftBar(!showleftbar)}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="flex flex-col items-center">
                    {showleftbar && (
                      <p className="p-1 bg-[#EEFECF] w-full justify-center flex ">
                        Your Video
                      </p>
                    )}
                    {!ourVideo ? (
                      <div className={`flex items-center justify-center text-white bg-black w-full ${showleftbar && "h-[144px]"} `}>
                        Camera off
                      </div>
                    ) : (
                      <video
                        ref={videoRef}
                        className="user-stream"
                        muted={true}
                        autoPlay
                        controls
                      ></video>
                    )}
                  </div>
                  {showleftbar && (
                    <div className="flex flex-col">
                      <button
                        onClick={() => setWhiteBoard((prev) => !prev)}

                        className=" inline-flex cursor-pointer items-center justify-center text-[#001437] text mx-auto w-[70%] hover:w-[72%] bg-[#BCFB46] border text-xs   py-1.5 gap-3 mt-2"
                      >
                        <EditIcon
                          className="w-4 h-4"
                          alt="svg"
                          color="#000"
                          title="Edit Test"
                        />
                        Start a new whiteboard
                      </button>
                      {getType() === "P" && (
                        <>
                          <h1 className="text-md font-semibold p-3 px-4">
                            Question Description
                          </h1>
                          <hr />
                          <div className="flex flex-col justify-center items-center sm:pb-3">
                            <p className="text-xs px-5 py-1">
                              Start your interviews by choosing one of the tasks
                              from a pre-built library.
                            </p>
                            <div className="flex gap-x-2 p-2">
                              <button
                                onClick={() => handleQuestionbankModal()}
                                className="text-[#001437] bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32] text-xs  py-1 px-3"
                              >
                                Import Question
                              </button>
                              <button
                                onClick={() => handleCustomQuestionModal()}
                                className="text-[#001437] bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32] text-xs   py-1 px-3"
                              >
                                Custom Question
                              </button>
                            </div>
                          </div>
                          <hr />
                        </>
                      )}
                      {interviewDetails.components.length ? (
                        <>
                          <h1 className="text-md font-semibold p-3 px-4">
                            Questions in Section-{currentSectionIndex + 1}
                          </h1>
                          <div className="flex flex-col gap-y-1 px-3">
                            {(getType() === "S" &&
                              interviewDetails?.components[currentSectionIndex]
                                ?.isReleased) ||
                              getType() !== "S" ? (
                              <>
                                {interviewDetails?.components[
                                  currentSectionIndex
                                ]?.components?.length > 0 ? (
                                  <>
                                    {interviewDetails?.components[
                                      currentSectionIndex
                                    ]?.components?.map((question, index) => (
                                      <div
                                        key={question._id}
                                        className="relative"
                                      >
                                        {!(getType() === "S") && (
                                          <Cross
                                            className="absolute bg-white -top-0.5 rounded-full -right-1 cursor-pointer h-[10px] w-[10px]"
                                            color="#dd0000"
                                            onClick={() =>
                                              handleDeleteQuestion(index)
                                            }
                                          />
                                        )}
                                        <button
                                          onClick={() => onClickQuestion(index)}
                                          className={`${parseInt(currentQuestionIndex) ===
                                            index
                                            ? "text-white bg-[#a0db32]"
                                            : "bg-white text-[#a0db32]"
                                            } border border-[#a0db32] w-full  rounded  text-xs  sm:px-3 sm:py-2 py-1 px-3`}
                                        >
                                          {getShortQuestion(question?.question)}
                                        </button>
                                      </div>
                                    ))}
                                  </>
                                ) : (
                                  <button
                                    disabled
                                    className="border border-[#a0db32] rounded bg-white text-sm text-[#a0db32] sm:px-5 sm:py-2 py-1 px-3"
                                  >
                                    No questions selected
                                  </button>
                                )}
                              </>
                            ) : (
                              <button
                                disabled
                                className="border border-[#a0db32] rounded bg-white text-sm text-[#a0db32] sm:px-5 sm:py-2 py-1 px-3"
                              >
                                Section Locked!
                              </button>
                            )}
                          </div>
                        </>
                      ) : null}
                    </div>
                  )}
                </div>
                <div
                  className={`p-1  m-1 pb-16 ${showRightbar && showleftbar
                    ? "w-[64%]"
                    : showRightbar || showleftbar
                      ? "w-[82%]"
                      : "w-full"
                    }`}
                >
                  <div className="flex text-md py-1 justify-center items-center bg-[#EEFECF]">
                    <CountDownBar
                      saveAutomatic={() =>
                        getType() === "S" &&
                        saveInterviewResponse({ isSubmit: false })
                      }
                      endDate={interviewDetails?.endTimeInterview}
                    />
                  </div>
                  <div
                    className={`flex h-full
             ${!viewStyle ? " flex-row" : " flex-col"}`}
                  >
                    {(getType() === "S" &&
                      interviewDetails?.components[currentSectionIndex]
                        ?.isReleased) ||
                      getType() !== "S" ? (
                      <>
                        {currentQuestionIndex !== null &&
                          interviewDetails?.components[currentSectionIndex]
                            ?.components[currentQuestionIndex] ? (
                          <>
                            <div className={`pr-2 ${!viewStyle && "flex-1"}`}>
                              <p className="m-2">
                                Question No :{" "}
                                {parseInt(currentQuestionIndex) + 1}
                              </p>
                              <div
                                className={`min-w-[25vw] max-w-[90vw] ${!viewStyle ? "max-h-[80vh]" : "max-h-[30vh] "
                                  } scrollbar px-2 overflow-y-auto`}
                              >
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      interviewDetails?.components[
                                        currentSectionIndex
                                      ]?.components[currentQuestionIndex]
                                        ?.question,
                                  }}
                                  className=" bg-white rounded  text-xs  sm:px-3 sm:py-2 py-1 px-3"
                                ></p>
                              </div>
                            </div>

                            {interviewDetails?.components[currentSectionIndex]
                              ?.components[currentQuestionIndex] && (
                                <div
                                  className="relative flex-1"
                                  key={`section -
                            ${currentSectionIndex} -
                            Question -
                            ${currentQuestionIndex}`}
                                >
                                  {renderQuestion(
                                    interviewDetails?.components[
                                      currentSectionIndex
                                    ]?.components[currentQuestionIndex]?.type
                                  )}
                                </div>
                              )}
                          </>
                        ) : (
                          <div className="flex justify-center items-center h-screen">
                            <p>No Question Selected</p>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="flex justify-center items-center h-full">
                        Section is locked
                      </div>
                    )}
                  </div>
                </div>
                <div className={` ${showRightbar ? "w-[18%]" : "w-[3%]"}`}>
                  <div
                    className={`${showRightbar
                      ? "flex-row"
                      : "flex-col justify-center gap-y-3"
                      } flex  items-center p-3`}
                  >
                    <HamburgerIcon
                      className="w-[20px] cursor-pointer"
                      onClick={() => setShowRightBar(!showRightbar)}
                    />
                    {showRightbar && (
                      <h1 className="px-3 text-md font-semibold">Scorecard</h1>
                    )}
                    <span className="relative flex h-3 w-3 ml-auto">
                      <span
                        className={`animate-ping absolute inline-flex h-full w-full rounded-full bg-${isInterviewerConnected ? "green" : "red"
                          }-500 opacity-75`}
                      ></span>
                      <span
                        className={`relative inline-flex rounded-full h-3 w-3 bg-${isInterviewerConnected ? "green" : "red"
                          }-500`}
                      ></span>
                    </span>
                  </div>
                  <hr />

                  <div className="flex flex-col justify-center items-center p-1">
                    {showRightbar && (
                      <p className="p-1 bg-[#EEFECF] w-full justify-center flex ">
                        {getType() === "S"
                          ? "Interviewer Video"
                          : "Student Video"}
                      </p>
                    )}
                    <>
                      {/* {console.log("remoteref",isRemoteVideo)} */}
                      {/* { isRemoteVideo ? ( */}
                      <video
                        ref={remoteRef}
                        className={`remote-stream ${!currentScreen || !isRemoteVideo
                          ? "w-[0px] h-[0px]"
                          : ""
                          }`}
                        autoPlay
                        muted={isOthersMicMuted}
                        controls
                      ></video>
                      {/* ) : (  */}
                      <>
                        {/* {currentScreen && isInterviewerConnected ? ( */}
                        <div
                          className={`flex items-center justify-center text-white bg-black 
                            ${!isRemoteVideo &&
                              currentScreen &&
                              isInterviewerConnected && showRightbar
                              ? "w-full h-[144px]"
                              : "w-[0px] h-[0px] hidden "
                            } 
                            
                            `}
                        >
                          Camera off
                        </div>
                        {/* ) : (  */}
                        <div
                          className={`flex items-center justify-center text-white bg-black   
                            ${!isRemoteVideo &&
                              currentScreen &&
                              !isInterviewerConnected && showRightbar
                              ? "w-full h-[144px]"
                              : "w-[0px] h-[0px] hidden"
                            }
                            
                            `}
                        >
                          {getType() === "S"
                            ? "Interviewer camera"
                            : "Student camera"}
                        </div>
                        {/* )}  */}
                      </>
                      {/* )}  */}
                      {getType() === "S" && (
                        <>
                          {isScreenShared ? (
                            <video
                              ref={screenRef}
                              className={`remote-stream ${currentScreen ? "w-[0px] h-[0px]" : ""
                                }`}
                              autoPlay
                              muted={true}
                              controls
                            ></video>
                          ) : (
                            <>
                              {!currentScreen && isInterviewerConnected && (
                                <>
                                  <div className={`flex items-center justify-center text-white bg-black w-full ${showleftbar && "h-[144px]"} `}>
                                    screen off
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  </div>
                  {showRightbar && (
                    <>
                      <>
                        {isInterviewerConnected ? (
                          <div className="bg-[#FAFAFA] w-full flex items-center justify-center gap-x-2 pb-2">
                            <div
                              className={`flex justify-center ${!isOthersMicMuted
                                ? "bg-[#B8FB3C]"
                                : "bg-[#EB4335]"
                                } items-center w-8 h-8  ${!(getType() === "S") && "cursor-pointer"
                                } rounded-full`}
                              onClick={() =>
                                !(getType() === "S") && onClickOtherUserMic()
                              }
                            >
                              <Interviewmicon
                                color="white"
                                alt="icon"
                                title="Mic"
                              />
                            </div>
                            <div
                              className={`flex justify-center ${isRemoteVideo ? "bg-[#B8FB3C]" : "bg-[#EB4335]"
                                } items-center w-8 h-8 ${!(getType() === "S") && "cursor-pointer"
                                } rounded-full`}
                              onClick={() =>
                                !(getType() === "S") &&
                                onActionOthersVideoStream()
                              }
                            >
                              <Interviewvideoon color="white" title="Video" />
                            </div>
                            {!(getType() === "S") && (
                              <div
                                className={`flex justify-center ${isFullScreen ? "bg-[#B8FB3C]" : "bg-[#EB4335]"
                                  } items-center w-8 h-8  rounded-full cursor-pointer`}
                              >
                                <InterviewfullScreen
                                  color="white"
                                  className="w-5 h-5"
                                  title="Full Screen"
                                />
                              </div>
                            )}
                            {getType() === "S" && (
                              <div
                                className={`flex justify-center ${!currentScreen
                                  ? "bg-[#B8FB3C]"
                                  : "bg-[#EB4335]"
                                  } items-center w-8 h-8  rounded-full cursor-pointer`}
                                onClick={() => setCurrentScreen(!currentScreen)}
                              >
                                <InterviewSwitchScreen
                                  color="white"
                                  className="w-5 h-5"
                                  title="Switch screen"
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="flex items-center sm:text-md text-sm justify-center text-red-800 py-1">
                            {getType() === "S"
                              ? "Interviewer has not joined yet!"
                              : "Student has not connected"}
                          </div>
                        )}
                      </>
                      <hr />

                      {getType() === "P" ||
                        interviewDetails.components.length > 0 ? (
                        <div className="flex justify-between items-center mr-2">
                          <h1 className="text-md font-semibold pt-3 px-2">
                            Sections
                          </h1>

                          {getType() === "P" && (
                            <img
                              src={plus}
                              title="Add Section"
                              loading="lazy"
                              className="h-4 w-4 mx-2"
                              px-2
                              alt="plus"
                              onClick={() => handleAddSection()}
                            />
                          )}
                        </div>
                      ) : null}
                      <InterviewAccordian
                        saveInterviewResponse={(data) =>
                          getType() === "S" && saveInterviewResponse(data)
                        }
                        updateSectionIndex={(data) => updateSectionIndex(data)}
                        handleDeleteSection={(data) =>
                          handleDeleteSection(data)
                        }
                      />
                      {getType() !== "S" && (
                        <div
                          id="accordion-flush-body-1"
                          className="display bg-gray-100 p-2 px-3"
                          aria-labelledby="accordion-flush-heading-1"
                        >
                          <h1 className="text-sm">Overall Feedback </h1>
                          <textarea
                            name="query"
                            id="query"
                            cols="2"
                            rows="3"
                            value={interviewDetails?.overall_feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            placeholder="Enter your feedback here"
                            className="p-2 mt-2 mb-1 w-full bg-white text-xs text-black placeholder-[#666666] placeholder-opacity-50 placeholder:text-xs focus:outline-none"
                          ></textarea>
                          {loader ? (
                            <div className="flex w-[70%]  items-center justify-center border border-[#a0db32] py-1">
                              <img
                                alt="loading"
                                src={load}
                                className="spinner mr-2 w-4 h-4 text-teal-400 "
                              />
                            </div>
                          ) : (
                            <button
                              onClick={() => submitFeedback()}
                              className="text-[#001437] bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32] text-xs  py-2 px-3"
                            >
                              Submit feedback
                            </button>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="fixed bg-[#FAFAFA] w-full bottom-0 flex items-center justify-center gap-x-2 p-2">
                <div
                  className={`flex justify-center ${!isOurMicMuted ? "bg-[#B8FB3C]" : "bg-[#EB4335]"
                    } items-center w-10 h-10 cursor-pointer rounded-full`}
                  onClick={() => onClickUserMic()}
                >
                  <Interviewmicon
                    // color={isOurMicMuted ? "#B8FB3C" : "white"}
                    color="white"
                    alt="icon"
                  />
                </div>
                <div
                  className={`flex justify-center ${ourVideo ? "bg-[#B8FB3C]" : "bg-[#EB4335]"
                    } items-center w-10 h-10 cursor-pointer rounded-full`}
                  onClick={() => onActionVideoStream()}
                >
                  <Interviewvideoon
                    // color={isVideo ? "white" : "#B8FB3C"}
                    color="white"
                    alt="icon"
                  />
                </div>
                {!(getType() === "S") && (
                  <div
                    className={`flex justify-center ${isScreenShared ? "bg-[#B8FB3C]" : "bg-[#EB4335]"
                      } items-center w-10 h-10 cursor-pointer rounded-full`}
                    onClick={() => shareScreen()}
                  >
                    <InterviewScreenshare
                      // color={isScreenShared ? "white" : "#B8FB3C"}
                      color="white"
                      className="w-5 h-5"
                      title="Full Screen"
                    />
                  </div>
                )}
                <div
                  className="flex justify-center cursor-pointer  items-center w-10 h-10 border-2 border-[#EB4335] rounded-full"
                  onClick={() => setSubmitModal(true)}
                >
                  <Interviewexit color="#EB4335" alt="icon" />
                </div>
              </div>
              <StudentChat
                isInterviewChat={true}
                showModal={showModal}
                handleChatModal={() => handleChatModal()}
                messages={messages}
                unseenMessageCount={0}
                notify={false}
                uniqueUserId={uniqueuserId}
                proctorConnected={isInterviewerConnected}
                scrollRef={scrollRef}
                handleChatPrompt={(data) => setchatInput(data)}
                handleSendingMessage={() => handleSendingMessage()}
                inputMessage={chatInput}
                chatMessageLoader={chatMessageLoader}
              />
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  return <>{allPages()}</>;
}
