import React, { useRef, useState } from "react";
import "./Test.css";
import io from "socket.io-client";
import Peer from "peerjs";
import { BASE_URL } from "../../assets/js/config";
import micoff from "../../assets/img/svg/mic2off.svg";
import micon from "../../assets/img/svg/mic2on.svg";
import speakeron from "../../assets/img/svg/speakeron.svg";
import speakeroff from "../../assets/img/svg/speakeroff.svg";
import { ReactComponent as Cross } from "../../assets/img/svg/cross.svg";
import { ReactComponent as Time } from "../../assets/img/svg/Time.svg";
import { ReactComponent as ButtonSpinner } from "../../assets/img/svg/button-spinner.svg";
import { toast } from "react-toastify";
import { generatePeerId, peerConfig } from "./utils";
import Chat from "./Chat";
import VideosMap from "./VideosMap";

// Redux
import { fetchTestRequest } from "../../store/actions/test";
import { toggleSidebar } from "../../store/actions/sidebar";
import { fetchTurnServerCreds } from "../../store/actions/turnserver";
import { fetchMyProfile } from "../../store/actions/user";
import {
  fetchProctoredUsersOfAnyProctorRequested,
  fetchProctoredUsersRequested,
} from "../../store/actions/proctor";
import {
  fetchChatMsgsRequested,
  receiveMessage,
  sendMessage,
} from "../../store/actions/chat";
import Proctoring from "./beforeTest/Proctoring";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import BroadcastModal from "./videoMonitoring/BroadcastModal";
import { ITEMS_IN_PAGE, userTypeMapping } from "./constants";
import ProctorDashboard from "./videoMonitoring/ProctorDashboard";
import BackButton from "../common/BackButton/BackButton";
import axios from "axios";
import authHeader from "../../services/auth-header";
import Notifications from "./videoMonitoring/Notifications";
import CountDownBar from "./giveTest/CountDownBar";
import { useParams } from "react-router-dom";

let calls = [];
export const VideoMonitor = (props) => {
  const socket = io(process.env.REACT_APP_SOCKET_URL, {
    secure: true,
    withCredentials: true,
    transports: ["websocket"],
  });
  const dispatch = useDispatch();
  const { id: testId, proctor: proctorId } = useParams();
  const [allLoaded, setAllLoaded] = useState(false);
  const [chatLoader, setChatLoader] = useState(false);
  const [uniqueUserId, setUniqueUserId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [AllVideos, setAllVideos] = useState([]);
  const [toPeerId, setToPeerId] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [messageBox, setMessageBox] = useState(false);
  const [mute, setMute] = useState(true);
  const [currentPage, setCurrentPage] = useState(
    Number(new URLSearchParams(window.location.search).get("page")) || 1
  );
  const [peer, setPeer] = useState(null);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [videosLoading, setVideosLoading] = useState(false);
  const [searchedStudentName, setSearchedStudentName] = useState("");
  const [broadcastModal, setBroadcastModal] = useState(false);
  const [broadcastInputMsg, setBroadcastInputMsg] = useState("");
  const [showProctoringReportModal, setShowProctoringReportModal] =
    useState(null);
  const [teacherStream, setTeacherStream] = useState(null);
  const [currentChangeInput, setCurrentChangeInput] = useState(
    Number(new URLSearchParams(window.location.search).get("page")) || 1
  );
  const [selectedStudentVideoStream, setSelectedStudentVideoStream] =
    useState(null);

  const { opened: sideBarOpened } = useSelector((state) => state.sidebar);
  const test = useSelector((state) => state.test.test);
  const credentials = useSelector((state) => state.turnserver.credentials);
  const profile = useSelector((state) => state.user.profile);
  const { proctoredUsers, isFetching: isProctoredUsersLoading } = useSelector(
    (state) => state.proctor
  );
  const { messages: chatMsgs } = useSelector((state) => state.chat);

  const StreamsRef = useRef([]);
  const VideosRef = useRef([]);
  const leaguesRef = useRef(messageBox);
  const toPeerIdRef = useRef(toPeerId);

  useEffect(() => {
    leaguesRef.current = messageBox;
  });

  useEffect(() => {
    toPeerIdRef.current = toPeerId;
  });

  useEffect(() => {
    if (props.type === "p" && sideBarOpened) dispatch(toggleSidebar());
    dispatch(fetchMyProfile());
    dispatch(fetchTurnServerCreds());
    dispatch(fetchTestRequest(testId));
    if (testId && proctorId)
      dispatch(fetchProctoredUsersOfAnyProctorRequested({ testId, proctorId }));
    if (testId && !proctorId)
      dispatch(fetchProctoredUsersRequested({ testId }));

    // setVideosLoading(true);

    try {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then((stream) => {
          setTeacherStream(stream);
        })
        .catch((err) => {
          console.log("Stream error: ", err);
        });
    } catch (err) {
      console.log("Navigator error: ", err);
    }

    setTimeout(() => {
      const videoBoxes = document.querySelectorAll("video");
      if (videoBoxes.length) console.log("Greater than 0");
      else {
        // console.log("into else");
        callTheFilteredStudents();
      }
    }, 3000);

    return () => {
      socket.close();
      if (peer) peer.destroy();
    };
  }, []);

  useEffect(() => {
    if (credentials && profile && testId && !peer && teacherStream) {
      const peerconfig = peerConfig(credentials);

      const userType = userTypeMapping[getType()];
      console.log({ userType });
      const peer = new Peer(
        generatePeerId(userType, {
          testId: testId,
          userId: profile._id,
        }),
        peerconfig
      );
      // console.log("generated peer", generatePeerId, peer);
      peer.on("open", function (id) {
        // console.log("My peer ID is: " + id);
        setPeer(peer);
      });

      // peer.on("call", (call) => {
      //   console.log("Call initiated", call);
      //   calls.push(call);
      //   if (calls.length === ITEMS_IN_PAGE) setVideosLoading(false);
      //   call.answer(teacherStream);
      //   call.on("stream", (remoteStream) => {
      //     console.log({ peer, call, remoteStream });
      //     let isDifferent = true;
      //     StreamsRef.current.forEach((each) => {
      //       if (each.id === call.peer) {
      //         isDifferent = false;
      //       }
      //     });
      //     if (isDifferent) {
      //       StreamsRef.current.push({
      //         id: call.peer,
      //         studentStream: remoteStream,
      //       });
      //       // handleToast("Student Joined");

      //       setAllVideos((prev) => {
      //         return [...prev, 1];
      //       });

      //       VideosRef.current.forEach((eachVideo, i) => {
      //         if (StreamsRef.current[i]) {
      //           console.log("here in Streams if");
      //           eachVideo.id = StreamsRef.current[i].id;
      //           eachVideo.srcObject = StreamsRef.current[i].studentStream;
      //         }
      //       });
      //     }
      //   });
      // });

      peer.on("close", () => {
        console.log("Peer disconneted 169");
        // peer.destroy();
      });
    }
  }, [credentials, profile, testId, teacherStream]);

  useEffect(() => {
    if (
      peer &&
      peer._id &&
      profile &&
      !isProctoredUsersLoading &&
      testId &&
      teacherStream
    ) {
      if (allLoaded) return;

      // if(proctoredUsers.length === 0) setVideosLoading(false)
      // Check what is the use
      setAllLoaded(true);
      setUniqueUserId(profile._id);

      setTimeout(() => {
        callTheFilteredStudents(peer);
      }, 1000);
      // const currentPageUsers = getCurrentPageUsers()

      socket.on("connect", () => {
        console.log({
          "type": "socket establishment from proctor",
          "socket details": {
            userId: profile._id,
            socketId: socket.id,
            testId: testId,
            peerId: peer.id,
            userType: "teacher",
            // usersToCall: currentPageUsers,
          }
        })
        socket.emit("new-socket", {
          userId: profile._id,
          socketId: socket.id,
          testId: testId,
          peerId: peer.id,
          userType: "teacher",
          // usersToCall: currentPageUsers,
        });
      });

      socket.on("peer-connected", ({ name }) => {
        handleToast(`${name} joined the meeting`);
      });

      // TODO: Later
      socket.on("peer-disconnected", ({ name, peerid }) => {
        let node = document.querySelector(`.div_${peerid}`);
        if (node) {
          node.remove();
          handleToast(`${name} left the meeting`);
          let newStreamRef = StreamsRef.current.filter(
            (each) => each.id !== peerid
          );
          StreamsRef.current = newStreamRef;

          let newVideosRef = VideosRef.current.filter(
            (each) => each.id !== peerid
          );
          VideosRef.current = newVideosRef;
        }
      });
    }
  }, [peer, profile, proctoredUsers, isProctoredUsersLoading, teacherStream]);

  const getCurrentPageUsers = () => {
    const pageOffsetStart = (currentPage - 1) * ITEMS_IN_PAGE;
    const pageOffset = currentPage * ITEMS_IN_PAGE;
    return proctoredUsers.slice(pageOffsetStart, pageOffset);
  };

  const callTheFilteredStudents = (peer) => {
    const proctoredUserPaginated = getCurrentPageUsers();
    setAllVideos([]);

    VideosRef.current = [];
    StreamsRef.current = [];
    for (let proctoredUserIndex in proctoredUserPaginated) {
      const proctoredUser = proctoredUserPaginated[proctoredUserIndex];
      try {
        const call = peer.call(proctoredUser.peerid, teacherStream);
        console.log({ call });
        calls.push(call);
        call.on("stream", (studentStream) => {
          // Storing objects of objects in streamRef
          let isDifferent = true;
          StreamsRef.current.forEach((each) => {
            if (each.id === proctoredUser.peerid) {
              isDifferent = false;
            }
          });
          if (isDifferent) {
            StreamsRef.current.push({
              id: proctoredUser.peerid,
              studentStream,
            });

            setAllVideos((prev) => {
              return [...prev, 1];
            });

            VideosRef.current.forEach((eachVideo, i) => {
              if (StreamsRef.current[i]) {
                eachVideo.id = StreamsRef.current[i].id;
                eachVideo.srcObject = StreamsRef.current[i].studentStream;
              }
            });
          }
        });
      } catch (err) {
        // alert("You are already proctoring.");
      }
    }
  };

  const handleToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleMute = (uniqueid) => {
    if (document.getElementById(uniqueid).muted) {
      document.getElementById(uniqueid).muted = false;
      document.getElementById(`muteImg_${uniqueid}`).src = speakeron;
    } else {
      document.getElementById(uniqueid).muted = true;
      document.getElementById(`muteImg_${uniqueid}`).src = speakeroff;
    }
    const videoBoxes = document.querySelectorAll(".videoBox");
    videoBoxes.forEach((eachBox) => {
      if (eachBox.id !== uniqueid) {
        eachBox.muted = true;
        document.getElementById(`muteImg_${eachBox.id}`).src = speakeroff;
      }
    });

    setMute(!mute);
  };

  const handleTeacherMute = (uniqueid) => {
    // 1. We need to change the mute unmute img here on teachers end
    let verdict;
    if (document.getElementById(`mic_${uniqueid}`)) {
      if (document.getElementById(`mic_${uniqueid}`).src.includes("mic2off")) {
        document.getElementById(`mic_${uniqueid}`).src = micon;
        verdict = true;
      } else if (
        document.getElementById(`mic_${uniqueid}`).src.includes("mic2on")
      ) {
        document.getElementById(`mic_${uniqueid}`).src = micoff;
        verdict = false;
      }
      const videoBoxes = document.querySelectorAll(".videoBox");
      videoBoxes.forEach((eachBox) => {
        if (eachBox.id !== uniqueid)
          document.getElementById(`mic_${eachBox.id}`).src = micoff;
      });
    }

    // 2. We can send socket event to everyone with clicked socket Id and we can
    // filter that on receving end of student
    const studentDetails = proctoredUsers.find(
      (student) => student.peerid === uniqueid
    );
    if (studentDetails)
      socket.emit("unmute-teacher-from-teacher", {
        studentDetails,
        testId: test._id,
        userId: profile._id,
        verdict,
      });
  };

  const handleChatReset = () => {
    setMessageBox(false);
    setToPeerId("");
    document.querySelector(`.notify_${toPeerId}`)?.classList.remove("show");
  };

  const isMessageBoxOpen = () => leaguesRef.current;

  useEffect(() => {
    socket.on("private-message", (msgData) => {
      if (isMessageBoxOpen && toPeerIdRef.current === msgData.studentPeer) {
        axios
          .get(BASE_URL + `api/quizchat/${msgData.id}/readmessage`, {
            headers: { ...authHeader() },
          })
          .then((res) => {
            console.log({ res });
          })
          .catch((err) =>
            console.log("error in sending flag unread msgs: ", err)
          );
        // .finally(() => setIsNotificationsLoading(false));
        dispatch(receiveMessage(msgData));
      } else {
        // Array format of notifications
        document
          .querySelector(`.notify_${msgData.studentPeer}`)
          ?.classList.add("show");
        handleToast(`New Message from ${msgData.msg.from.name}`);
      }
    });
  });

  const handleChat = (peerClicked) => {
    setMessageBox(true);
    setToPeerId(peerClicked);
    // alert(peerClicked);
    const userId = peerClicked.split("-")[2];
    dispatch(
      fetchChatMsgsRequested({
        testId,
        userId,
      })
    );

    document.querySelector(`.notify_${peerClicked}`)?.classList.remove("show");
  };

  const handleSendingMessage = (inputMessage) => {
    if (inputMessage) {
      setChatLoader(true);
      socket.emit(
        "msg-from-teacher",
        {
          testId: testId,
          msg: inputMessage,
          toPeer: toPeerId,
          userType: "teacher",
          userId: uniqueUserId,
          timestamp: Date.now(),
        },
        (msg) => {
          dispatch(sendMessage(msg));
          setInputMessage("");
          setChatLoader(false);
          dispatch(
            fetchChatMsgsRequested({
              testId,
              userId: toPeerId.split("-")[2],
            })
          );
        }
      );
    } else {
      console.log("No message typed");
    }
  };

  // Modularising calls
  const handleSearchStudent = () => {
    const studentFound = proctoredUsers.filter((student) =>
      student.name.toLowerCase().includes(searchedStudentName.toLowerCase())
    );
    if (!studentFound.length) {
      toast.warning("No Student Found", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setAllVideos([]);
    VideosRef.current = [];
    StreamsRef.current = [];
    for (let student of studentFound) {
      const call = peer.call(student?.peerid, teacherStream);
      calls.push(call);
      call.on("stream", (studentStream) => {
        let isDifferent = true;
        StreamsRef.current.forEach((each) => {
          if (each.id === student?.peerid) {
            isDifferent = false;
          }
        });
        if (isDifferent) {
          StreamsRef.current.push({
            id: student?.peerid,
            studentStream,
          });

          setAllVideos((prev) => [...prev, 1]);
          VideosRef.current.forEach((eachVideo, i) => {
            if (StreamsRef.current[i]) {
              eachVideo.id = StreamsRef.current[i].id;
              eachVideo.srcObject = StreamsRef.current[i].studentStream;
            }
          });
        }
      });
    }
  };

  const getFilteredStudents = () => {
    return proctoredUsers.filter((student) =>
      student.name.toLowerCase().includes(searchedStudentName.toLowerCase())
    );
  };

  const handleProctoringReportModal = (streamId) => {
    setShowProctoringReportModal(false);
    setTimeout(() => {
      setShowProctoringReportModal(true);
      const stream = StreamsRef.current.find(
        (stream) => stream.id === streamId
      );
      if (stream) setSelectedStudentVideoStream(stream.studentStream);
    });
  };

  const resetCalls = () => {
    calls.forEach((call) => call.close());
    callTheFilteredStudents(peer, currentPage);
  };

  const checkNextDisabled = () => {
    return currentPage * ITEMS_IN_PAGE >= proctoredUsers.length;
  };

  const getType = () => {
    return localStorage.getItem("priksha_type");
  };

  // const captureScreenShots=()=>{
  //   socket.emit("capturecameras-msg-from-proctor", {
  //     testId: test._id,
  //     userId: profile._id,
  //     msg: broadcastInputMsg,
  //   });
  // }


  return (
    <div className="flex h-[90vh] lg:items-start flex-col relative items-center gap-3 w-full">
      {/* Broadcast modal for broadcasting voice and chat message */}
      <BroadcastModal
        socket={socket}
        handleBroadcastModal={(v) => setBroadcastModal(v)}
        broadcastModal={broadcastModal}
      />

      <div className="w-full px-2 md:pl-5 py-4 font-['poppins']">
        <div className="hidden sm:flex flex-col justify-between items-center sm:flex-row sm:gap-4 ">
          <div className="flex">
            {getType() === "T" || getType() === "P" ? <BackButton text="" /> : null}
            <h2 className=" md:text-md lg:text-xl font-semibold ">

              Monitoring - Live feed
              {/* <span className="text-gray-500">{test.title}</span> */}
            </h2>
          </div>

          <h2 className="text-gray-500 md:text-md lg:text-xl font-semibold ">
            {test.title}
          </h2>

          {/* {console.log("ss", test)} */}
          <div className="flex gap-1 justify-center items-center">
            <Time className="w-8 h-8" />
            <CountDownBar
              saveAutomatic={() => { }}

              endDate={test?.end_duration}
            />

          </div>

        </div>
        <div className="sm:hidden flex flex-col justify-between items-center sm:flex-row sm:gap-4 ">
        <h2 className="text-gray-500 md:text-md lg:text-xl font-semibold ">
            {test.title}
          </h2>
         
         <div className="flex items-center justify-between">

         
          <div className="flex">
            {getType() === "T" || getType() === "P" ? <BackButton text="" /> : null}
            <h2 className=" md:text-md lg:text-xl font-semibold ">

              Monitoring - Live feed
              {/* <span className="text-gray-500">{test.title}</span> */}
            </h2>
          </div>

         

          {/* {console.log("ss", test)} */}
          <div className="flex gap-1 justify-center items-center">
            <Time className="w-8 h-8" />
            <CountDownBar
              saveAutomatic={() => { }}

              endDate={test?.end_duration}
            />

          </div>
          </div>
        </div>

        {/* Proctor Dashboard */}
        <ProctorDashboard />

        {/* Navbar */}
        <div className="bg-[#F5F5F5]  w-full rounded-lg mb-4 flex flex-col gap-2 lg:gap-0 lg:flex-row justify-between items-center px-2 py-2">
          {/* <VideoMonitoringNavbar
            setBroadcastModal={setBroadcastModal}
            callTheFilteredStudents={callTheFilteredStudents}
            peer={peer}
            handleSearchStudent={handleSearchStudent}
            calls={calls}
          /> */}
          {/* Prev button */}
          <div className="flex w-full lg:w-[50%] lg:justify-start lg:gap-2 justify-between items-center">
            <div>
              <button
                type="button"
                className="text-white border  bg-[#B8FB3C] hover:border-[#B8FB3C]
                      hover:text-[#B8FB3C] hover:bg-white font-semibold uppercase text-xs
                      px-4 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150
                      disabled:opacity-25 disabled:cursor-not-allowed disabled:bg-gray-500"
                disabled={currentPage <= 1}
                onClick={() => {
                  if (getType() === "T" || getType() === "P") {
                    window.open(
                      `${window.location.protocol}//${window.location.host
                      }/tests/videomonitor/${testId}?page=${currentPage - 1}`,
                      "_self"
                    );
                  } else if (getType() === "SP") {
                    window.open(
                      `${window.location.protocol}//${window.location.host
                      }/tests/superproctor/${testId}/${proctorId}?page=${currentPage - 1
                      }`,
                      "_self"
                    );
                  }
                }}
              >
                Prev
              </button>
              {/* Next button */}
              <button
                type="button"
                className="text-white border bg-[#B8FB3C] hover:border-[#B8FB3C] ml-2
                      hover:text-[#B8FB3C] hover:bg-white font-semibold uppercase text-xs
                      px-4 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150
                      disabled:opacity-25 disabled:cursor-not-allowed disabled:bg-gray-500"
                disabled={checkNextDisabled()}
                onClick={() => {
                  if (getType() === "T" || getType() === "P") {
                    window.open(
                      `${window.location.protocol}//${window.location.host
                      }/tests/videomonitor/${testId}?page=${currentPage + 1}`,
                      "_self"
                    );
                  } else if (getType() === "SP") {
                    window.open(
                      `${window.location.protocol}//${window.location.host
                      }/tests/superproctor/${testId}/${proctorId}?page=${currentPage + 1
                      }`,
                      "_self"
                    );
                  }
                }}
              >
                Next
              </button>
            </div>
            {/* Page nUmber */}
            <div className="flex text-center text-[#666666]">
              <h5>Page</h5>
              {/* Page {proctoredUsers.length ? ordinal(currentPage) : "0"} */}
              <input
                type="number"
                placeholder=""
                value={proctoredUsers.length ? currentChangeInput : 0}
                disabled={!proctoredUsers.length}
                className="h-[25px] w-[25px] rounded-md border border-1"
                style={{
                  padding: "2px 0 2px 6px",
                }}
                onChange={(e) => setCurrentChangeInput(e.target.value)}
              />
              of {Math.ceil(proctoredUsers.length / ITEMS_IN_PAGE)}
              <button
                type="button"
                className="text-[#a0db32] border border-[#a0db32] hover:bg-[#BCFB46]
                        hover:text-[#001437] font-bold uppercase text-xs
                        px-2  rounded outline-none focus:outline-none
                        ease-linear transition-all duration-150 mx-4"
                onClick={() =>
                  window.open(
                    `${window.location.protocol}//${window.location.host}/tests/videomonitor/${testId}?page=${currentChangeInput}`,
                    "_self"
                  )
                }
                disabled={
                  Number(currentChangeInput) === 0 ||
                  currentChangeInput >
                  Math.ceil(proctoredUsers.length / ITEMS_IN_PAGE)
                }
              >
                GO
              </button>
            </div>
          </div>
          {/* search div */}
          <div className="w-full flex flex-col sm:flex-row gap-3 lg:justify-end justify-between select-none">
            <div className="flex justify-evenly gap-2">
              <Notifications handleChat={handleChat} />
              {/* <button
                type="button"
                className="text-[#a0db32] border border-[#009696] hover:bg-[#009696]
                hover:text-white font-semibold uppercase text-xs
                px-2 rounded outline-none focus:outline-none
                ease-linear transition-all duration-150"
                onClick={() => captureScreenShots()}
              >
                Capture Screenshots
              </button> */}
              <button
                type="button"
                className="text-[#a0db32] border border-[#a0db32] hover:bg-[#BCFB46]
                hover:text-[#001437] font-semibold uppercase text-xs
                px-2 rounded outline-none focus:outline-none
                ease-linear transition-all duration-150"
                onClick={() => setBroadcastModal(true)}
              >
                Broadcast Message
              </button>
              <button
                type="button"
                className="text-[#a0db32] background-transparent font-semibold uppercase px-1 py-1 
                          text-[0.9rem] outline-none focus:outline-none ease-linear transition-all duration-150"
                onClick={handleSearchStudent}
              >
                Search
              </button>
              <button
                type="button"
                className="text-[#EB4335] background-transparent font-semibold uppercase px-1 py-1 
                          text-[0.9rem] outline-none focus:outline-none ease-linear transition-all duration-150 underline"
                onClick={() => {
                  calls.forEach((call) => call.close());

                  setSearchedStudentName("");
                  callTheFilteredStudents(peer, currentPage);
                }}
              >
                Reset
              </button>
            </div>
            <div className="flex gap-3">
              <div className="relative">
                <input
                  type="text"
                  className="pl-2 py-1 pr-10 rounded-md outline-none"
                  name="user"
                  id="user"
                  placeholder="Search for user..."
                  value={searchedStudentName}
                  onChange={(e) => setSearchedStudentName(e.target.value)}
                />

                <Cross
                  className="absolute cursor-pointer right-2 top-[8px]"
                  onClick={() => {
                    setSearchedStudentName("");
                    callTheFilteredStudents(peer, currentPage);
                  }}
                />
              </div>
             
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 md:gap-0 md:flex-row w-full min-h-[50vh]">
          <div className="w-full md:w-[60%] lg:w-[68%] xl:w-[72%]">
            {isProctoredUsersLoading || videosLoading ? (
              <div className="flex justify-center items-center h-[50vh] text-xl gap-3">
                <span>Loading Students </span>
                <ButtonSpinner />
              </div>
            ) : (
              <>
                <VideosMap
                  AllVideos={AllVideos}
                  StreamsRef={AllVideos.length ? StreamsRef : StreamsRef}
                  VideosRef={AllVideos.length ? VideosRef : VideosRef}
                  proctoredUsers={
                    searchedStudentName.length
                      ? getFilteredStudents()
                      : getCurrentPageUsers()
                  }
                  test_id={test._id} // Router
                  handleMute={handleMute} // Handle there
                  handleTeacherMute={handleTeacherMute} // Handle there
                  handleChat={handleChat} // Handle there
                  currentPage={currentPage} // Handle there
                  callTheFilteredStudents={callTheFilteredStudents}
                  handleProctoringReportModal={handleProctoringReportModal}
                />
              </>
            )}
          </div>
          <hr className="w-[1px] h-[auto] bg-gray-400" />
          {showProctoringReportModal === true ? (
            <div className={`w-full md:w-[40%] lg:w-[32%] xl:w-[28%]`}>
              <Proctoring
                isBack={false}
                setShowProctoringReportModal={setShowProctoringReportModal}
                studentVideoStream={selectedStudentVideoStream}
              />
            </div>
          ) : showProctoringReportModal === false ? (
            <div className="w-full md:w-[40%] lg:w-[32%] xl:w-[28%] flex justify-center items-center text-gray-500">
              <span>Loading </span> <ButtonSpinner />
            </div>
          ) : (
            <div
              className={`w-full md:w-[40%] lg:w-[32%] xl:w-[28%] flex justify-center items-center text-gray-500`}
            >
              Select any student for details
            </div>
          )}
        </div>
      </div>
      {getType() === "T" || getType() === "P" || getType() === "SP" ? (
        <Chat
          messageBox={messageBox}
          handleChatReset={handleChatReset}
          toPeerId={toPeerId}
          showModal={showModal}
          chatLoader={chatLoader}
          handleSendingMessage={handleSendingMessage}
          uniqueUserId={uniqueUserId}
          messages={chatMsgs}
        />
      ) : null}
      {/* ) : null} */}
    </div>
  );
};

export default VideoMonitor;
