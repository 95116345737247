import React from "react";
import { ReactComponent as DashboardLogo } from "../../assets/img/svg/dashout.svg";
import { ReactComponent as AitsLogo } from "../../assets/img/svg/spreadsheet.svg";
import { ReactComponent as EventLogo } from "../../assets/img/svg/events.svg";
import { ReactComponent as QuestionLogo } from "../../assets/img/svg/questions.svg";
import { ReactComponent as QuestionBank } from "../../assets/img/svg/questionbank.svg";
import { ReactComponent as SkillLogo } from "../../assets/img/svg/skills.svg";
import { ReactComponent as Students } from "../../assets/img/icons/students.svg";
import { ReactComponent as ProfileLogo } from "../../assets/img/svg/book.svg";
import { ReactComponent as GroupTestLogo } from "../../assets/img/svg/group_test.svg";

import { ReactComponent as EmailTemplate } from "../../assets/img/svg/email_template.svg";

// import { ReactComponent as TestLogo } from "../../assets/img/svg/test.svg";
import { ReactComponent as TestActiveLogo } from "../../assets/img/svg/test_active.svg";

import account from "../../assets/img/svg/account.svg";
import accountout from "../../assets/img/svg/accountout.svg";
import candidates from "../../assets/img/svg/candidates.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../common/Common.css";

const Sidebar = ({ user }) => {
  const url = window.location.href;
  const location = useLocation();
  const { opened: sideBarOpened } = useSelector((state) => state.sidebar);
  const sideNavLinks = [
    {
      to: "/dashboard",
      value: "dashboard",
      text: "Dashboard",
      showLink: ["S", "T", "KA-I", "A", "TA", "P", "HR", "SP", "E"],
    },
    {
      to: "/tests",
      value: "tests",
      text: "Tests",
      showLink: ["S", "T", "KA-I", "A", "TA", "P", "SP"],
    },
    {
      to: "/interviews",
      value: "interviews",
      text: "Interviews",
      showLink: ["S", "P", "T"],
    },
    {
      to: "/testgroups",
      value: "testgroups",
      text: "Test Groups",
      showLink: ["T", "KA-I", "A", "TA"],
    },
    {
      to: "/aits",
      value: "aits",
      text: "Aits Tests",
      showLink: ["S", "T", "KA-I", "P"],
    },
    {
      to: "/questionbank",
      value: "questionbank",
      text: "Question Bank",
      showLink: ["T", "KA-I"],
    },
    {
      to: "/email-template",
      value: "email-template",
      text: "Email Template",
      showLink: ["A"],
    },
    {
      to: "/subscription",
      value: "subscription",
      text: "Subscription",
      showLink: ["A"],
    },
    {
      to: "/postgres-question",
      value: "postgres-question",
      text: "Postgres",
      showLink: ["T", "KA-I"],
    },

    {
      to: "/askyourresume",
      value: "askyourresume",
      text: "Ask Resume",
      showLink: ["T", "HR"],
    },
    {
      to: "/extractQuestion",
      value: "extractQuestion",
      text: "Extract Question",
      showLink: ["T", "HR"],
    },
    // {
    //   to: "/candidates",
    //   value: "candidates",
    //   icon: candidates,
    //   activeIcon: candidates,
    //   text: "Candidates",
    //   showLink: ["T", "KA-I", "A", "TA"],
    // },
    // {
    //   to: "/events",
    //   value: "events",
    //   text: "Events",
    //   showLink: ["S", "T", "KA-I", "A", "TA"],
    // },
    // {
    //   to: "/questions",
    //   value: "questions",
    //   text: "Questions",
    //   showLink: ["S", "T", "KA-I", "A", "TA"],
    // },
    // {
    //   to: "/skills",
    //   value: "skills",
    //   text: "Skills",
    //   showLink: ["S", "T", "KA-I", "A", "TA"],
    // },
    // {
    //   to: "/register",
    //   value: "register",
    //   icon: accountout,
    //   activeIcon: account,
    //   text: "New Registration",
    //   showLink: ["A"],
    // },
    // // {
    // //   to: "/allcourses",
    // //   value: "allcourses",
    // //   icon: bookout,
    // //   activeIcon: book,
    // //   text: "All Courses",
    // //   showLink: ["A", "T", "KA-I"],
    // // },
    // {
    //   to: "/form",
    //   value: "form",
    //   text: "Profile",
    //   showLink: ["S"],
    // },
    {
      to: "/blind-assessment",
      value: "blind-assessment",
      text: "Assessment",
      showLink: ["E"],
    },
  ];

  const activeIndex = sideNavLinks
    .map(({ value }) => {
      return value.toLowerCase();
    })
    .indexOf(location.pathname.split("/")[1]);

  return (
    <div
      id={sideBarOpened ? "sidebar-open" : "sidebar"}
      className={`flex h-[96%] border-r border-b border-border  ${sideBarOpened ? "show" : ""
        }`}
    >
      {/* <Scrollbars style={{ width: 500, height: 600 }}> */}
      <ul className="ml-1 overflow-auto scrollbar pb-[1rem]" id="sidenav">
        {sideNavLinks.map((item, index) =>
          item.showLink.includes(user.type) ? (
            <li
              key={index}
              className={`rounded-md hover:bg-[#EEFECF]   hover:border-gray-700   ${url.split("/")[3].split("?")[0] === item.value
                ? "bg-[#EEFECF] font-medium"
                : ""
                }`}
            >
              <a
                href={item.to}
                className="no-underline flex text-text-light items-center flex-col gap-1 relative"
              >
                {CustomLogo(activeIndex === index, index)}

                <h5
                  className={`font-['poppins'] text-center text-xs  ${url.split("/")[3].split("?")[0] === item.value
                    ? "text-[#001437] font-medium"
                    : "text-[#566474]"
                    }`}
                >
                  {item.text}
                </h5>

                {/* <li
              key={index}
              className={`rounded-md hover:bg-[#DCF5F5] hover:border-gray-700   ${
                activeIndex === index ? "active" : "not-active"
              }`}
            >
              <Link
                to={item.to}
                className="no-underline flex text-text-light items-center flex-col gap-1 relative link-item"
              >
                {CustomLogo(activeIndex === index, index)}

                {sideBarOpened ? (
                  <h5 className="font-['poppins'] duration-300 ">
                    {" "}
                    {item.text}
                  </h5>
                ) : (
                  <h5
                    className="font-['poppins'] text-xs absolute left-[70px] top-[50%] translate-y-[-50%] bg-[#DCF5F5] w-max px-2 py-1
                             link-item__text rounded-md duration-300 shadow-lg"
                  >
                    {item.text}
                  </h5>
                )} */}
              </a>
            </li>
          ) : (
            <span key={index}></span>
          )
        )}
      </ul>
    </div>
  );
};

function CustomLogo(isActive, index) {
  const customLogos = [
    <DashboardLogo color={isActive ? "#001437" : "#566474"} alt="icon" />,
    <TestActiveLogo color={isActive ? "#001437" : "#566474"} alt="icon" />,
    <Students
      color={isActive ? "#001437" : "#566474"}
      className="h-8 w-8"
      alt="icon"
    />,
    <GroupTestLogo color={isActive ? "#001437" : "#566474"} alt="icon" />,
    <AitsLogo color={isActive ? "#001437" : "#566474"} alt="icon" />,

    <QuestionBank color={isActive ? "#001437" : "#566474"} alt="icon" />,
    <EmailTemplate color={isActive ? "#001437" : "#566474"} alt="icon" />,

    // Not visible
    <EventLogo color={isActive ? "#001437" : "#566474"} alt="icon" />,
    <QuestionLogo color={isActive ? "#001437" : "#566474"} alt="icon" />,
    <TestActiveLogo color={isActive ? "#001437" : "#566474"} alt="icon" />,

    // not visible
    <TestActiveLogo color={isActive ? "#001437" : "#566474"} alt="icon" />,
    // <ProfileLogo color={isActive ? "#001437" : "#566474"} alt="icon" />,
    // <ProfileLogo color={isActive ? "#001437" : "#566474"} alt="icon" />,
  ];
  return customLogos[index];
}

export default Sidebar;
