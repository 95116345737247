const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{
        ...style,
        borderRadius: "50%",
        background: "#94E005",
        zIndex: 100,
      }}
      onClick={onClick}
    />
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      className={className}
      style={{
        ...style,
        borderRadius: "50%",
        background: "#94E005",
        zIndex: 100,
      }}
      onClick={onClick}
    />
  );
};

const slickSetting = {
  dots: false,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 2,
  initialSlide: 0,
  arrows: true,
  responsive: [
    {
      breakpoint: 1268,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export { slickSetting };
