import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Datetime from "react-datetime";
import { sendInterviewInvite } from "../../services/interviewpanel.service";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../assets/js/toast_config";
import UniversalButton from "../common/StandardButton/UniversalButton";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllGroupDetailsRequested,
  fetchCompaniesRequested,
} from "../../store/actions/test";

const InterviewModal = (props) => {
  const dispatch = useDispatch();

  const [interview_title, setInterview_title] = useState(
    props?.interview_title ?? ""
  );
  const [company, setCompany] = useState(props?.company?._id ?? "");
  const [group, setGroup] = useState(props?.group?._id ?? "");
  const [scheduledStartDate, setscheduledStartDate] = useState(
    props?.startTimeInterview ?? ""
  );
  const [scheduledEndDate, setscheduledEndDate] = useState(
    props?.endTimeInterview ?? ""
  );
  const [interviewerEmail, setInterviewerEmail] = useState(
    props?.interviewer_email ?? ""
  );
  const [studentEmail, setStudentEmail] = useState(props?.student_email ?? "");
  const [remarks, setRemarks] = useState(props?.remark ?? "");
  const [copyPaste, setCopyPaste] = useState(
    props?.is_disable_copy_paste ?? false
  );
  const [tabSwitch, setTabSwitch] = useState(
    props?.is_disable_tab_switch ?? false
  );
  const params = useParams();
  const { companies, allTestGroups } = useSelector((state) => state.test);
  const [status, setStatus] = useState(props?.status ?? false);

  const isValidated = () => {
    if (
      scheduledStartDate !== "" &&
      scheduledEndDate !== "" &&
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(interviewerEmail)
    )
      return true;
    else {
      toast.warning("Invalid Email or time", defaultToastSetting);
    }
  };

  console.log(props);

  // "startTimeInterview": 1696098600000,
  // "endTimeInterview": 1698604200000,
  // "interviewer_email": "proctor@proctor.com",
  // "student_email": "www.amitkewot@gmail.com",
  // "remark": "aaa",
  // "is_disable_copy_paste": false,
  // "is_disable_tab_switch": false,
  // "company":"62fd112d81405c8350c7577d",
  // "interview_title":"testing changes update.......",
  // "interview_id":"65350e8d5c57bd9c02093412"

  const handleSendInterviewCalls = () => {
    if (isValidated()) {
      sendInterviewInvite({
        // quiz_assign: props.responseId,
        // quiz: params?.id,
        interview_title,
        company,
        // interview_group: group,
        student_email: studentEmail,
        interviewer_email: interviewerEmail,
        interview_id: props?.interview_id,
        startTimeInterview: scheduledStartDate,
        remark: remarks,
        endTimeInterview: scheduledEndDate,
        is_disable_copy_paste: copyPaste,
        is_disable_tab_switch: tabSwitch,

        // status,
      })
        .then((res) => {
          if (res.success === true)
            toast.success(
              "Interview email sent successfully",
              defaultToastSetting
            );
        })
        .catch((err) => toast.error(err.message, defaultToastSetting))
        .finally(() => props.updateInterviewModal(true));
    }
  };

  useEffect(() => {
    dispatch(fetchCompaniesRequested());
    dispatch(fetchAllGroupDetailsRequested({ type: "I" }));
  }, []);

  return (
    <div className="flex flex-col max-h-[72vh] overflow-y-auto scrollbar pr-1">
      <h1 className="font-['poppins'] text-[#1A1A25] font-medium text-center pb-1 text-[20px]">
        Set Interview Timing
      </h1>
      <h2 className="font-['poppins'] text-[#1A1A25] font-medium text-center text-[16px]">
        Please select a date and time to schedule an interview
      </h2>
      <div className="w-full flex justify-end pt-2">
        <div className="w-auto">
          <label className="inline-flex relative items-center mr-5 cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={status}
              readOnly
            />
            <div
              onClick={() => {
                setStatus((prev) => !prev);
              }}
              className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#a0db32]"
            ></div>
            <span className="ml-2 text-md  font-['poppins'] font-medium text-gray-900">
              {status ? "Active" : "InActive"}
            </span>
          </label>
        </div>
      </div>

      <div className="flex flex-col gap-y-2 pb-2">
        <div className="mb-1 flex justify-center items-center flex-col md:flex-row w-full gap-3">
          <div className="w-full md:w-1/2">
            <label
              className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
              htmlFor="interviewerEmail"
            >
              Interview Title
            </label>
            <div className="relative">
              <input
                className="border-2 border-gray-300 block px-2.5 pb-2 pt-2 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 peer"
                id="interview_title"
                name="interview_title"
                value={interview_title}
                onChange={(e) => setInterview_title(e.target.value)}
                type="text"
                placeholder="Interview Title"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <label
              className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
              htmlFor="company"
            >
              Company Name
            </label>
            <div className="relative">
              <select
                className="border-2 w-full focus:outline-none focus:ring-0  placeholder:font-light text-sm outline-gray-300 border-gray-300 p-2 rounded font-['nunito']"
                id="company"
                onChange={(e) => setCompany(e.target.value)}
                value={company}
              >
                <option>Select</option>
                {companies?.map((company, idx) => (
                  <option value={company._id} key={idx}>
                    {company.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="mb-1 flex justify-center items-center flex-col md:flex-row w-full gap-3">
          <div className="w-full md:w-1/2">
            <label
              className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
              htmlFor="interviewerEmail"
            >
              Student Email
            </label>
            <div className="relative">
              <input
                className="border-2 border-gray-300 block px-2.5 pb-2 pt-2 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 peer"
                id="interviewerEmail"
                name="interviewerEmail"
                value={studentEmail}
                onChange={(e) => setStudentEmail(e.target.value)}
                type="email"
                placeholder="Interviewer Email"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <label
              className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
              htmlFor="interviewerEmail"
            >
              Interviewer Email
            </label>
            <div className="relative">
              <input
                className="border-2 border-gray-300 block px-2.5 pb-2 pt-2 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 peer"
                id="interviewerEmail"
                name="interviewerEmail"
                value={interviewerEmail}
                onChange={(e) => setInterviewerEmail(e.target.value)}
                type="email"
                placeholder="Interviewer Email"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center flex-col md:flex-row w-full gap-3">
          <div className="w-full md:w-1/2">
            <label
              className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
              htmlFor="Mobile"
            >
              Interview Start Time
            </label>
            <Datetime
              className="font-normal w-full"
              inputProps={{
                className:
                  "border-2 w-full focus:outline-none focus:ring-0  placeholder:font-light  text-sm outline-gray-300 border-gray-300 p-2 rounded font-['nunito']",
                placeholder: "Start Date & Time",
                name: "start_duration",
              }}
              autoComplete="off"
              value={new Date(scheduledStartDate)}
              onChange={(e) => setscheduledStartDate(e?._d?.getTime())}
            />
          </div>
          <div className="w-full md:w-1/2">
            <label
              className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
              htmlFor="Mobile"
            >
              Interview End Time
            </label>
            <Datetime
              className="font-normal w-full"
              autoComplete="off"
              inputProps={{
                className:
                  "border-2 w-full focus:outline-none focus:ring-0  placeholder:font-light  text-sm outline-gray-300 border-gray-300 p-2 rounded font-['nunito']",
                placeholder: "End Date & Time",
                name: "end_duration",
              }}
              value={new Date(scheduledEndDate)}
              onChange={(e) => setscheduledEndDate(e?._d?.getTime())}
            />
          </div>
        </div>

        <div className="flex justify-between items-center gap-[2rem] pl-1 my-1 flex-col md:flex-row">
          <div className="w-full md:flex-1">
            <label
              className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
              htmlFor="company"
            >
              Group Name
            </label>
            <div className="relative">
              <select
                className="border-2 w-full focus:outline-none focus:ring-0  placeholder:font-light text-sm outline-gray-300 border-gray-300 p-2 rounded font-['nunito']"
                id="company"
                onChange={(e) => setGroup(e.target.value)}
                value={group}
              >
                <option>Select</option>
                {allTestGroups?.map((company, idx) => (
                  <option value={company._id} key={idx}>
                    {company.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex-1 flex justify-end md:pt-5 items-center gap-[1rem]">
            <div className="flex gap-2">
              <input
                checked={copyPaste}
                type="checkbox"
                onChange={(e) => setCopyPaste(!copyPaste)}
              />
              <label className="">Disable Copy/Paste</label>
            </div>
            <div className="flex gap-2">
              <input
                checked={tabSwitch}
                type="checkbox"
                onChange={(e) => setTabSwitch(!tabSwitch)}
              />
              <label className="">Disable Tab Switch</label>
            </div>
          </div>
        </div>

        <div>
          <label
            className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
            htmlFor="Mobile"
          >
            Remarks
          </label>
          <div className="relative">
            <textarea
              className="border-2 border-gray-300 block px-2.5 pb-2 pt-3 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 peer"
              placeholder=" "
              id="remarks"
              name="remarks"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              rows={3}
            />
            <label
              htmlFor="summary"
              className="font-['nunito'] absolute text-sm  duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-0 peer-placeholder-shown:top-3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2"
            >
              Remarks
            </label>
          </div>
        </div>
        <div className={`flex justify-center items-center gap-x-3`}>
          <UniversalButton
            title="send"
            onClickFunction={() => handleSendInterviewCalls()}
          />
        </div>
      </div>
    </div>
  );
};

export default InterviewModal;
