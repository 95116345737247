export const FETCH_ALL_QUESTIONS_REQUESTED = "FETCH_ALL_QUESTIONS_REQUESTED";

export const FETCH_ALL_QUESTIONS_SUCCESS = "FETCH_ALL_QUESTIONS_SUCCESS";

export const FETCH_ALL_QUESTIONS_FAILURE = "FETCH_ALL_QUESTIONS_FAILURE";

export const ADD_QUESTION_REQUESTED = "ADD_QUESTION_REQUESTED";

export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS";

export const ADD_QUESTION_FAILURE = "ADD_QUESTION_FAILURE";

export const UPDATE_QUESTION_REQUESTED = "UPDATE_QUESTION_REQUESTED";

export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS";

export const UPDATE_QUESTION_FAILURE = "UPDATE_QUESTION_FAILURE";

export const DELETE_QUESTION_REQUESTED = "DELETE_QUESTION_REQUESTED";

export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";

export const DELETE_QUESTION_FAILURE = "DELETE_QUESTION_FAILURE";

export const UPDATE_QUESTION_MODAL = "UPDATE_QUESTION_MODAL";

export const UPDATE_QUESTION_WITH_KEY = "UPDATE_QUESTION_WITH_KEY";

export const FETCH_USER_TABLES_REQUESTED = "FETCH_USER_TABLES_REQUESTED";

export const FETCH_USER_TABLES_FAILURE = "FETCH_USER_TABLES_FAILURE";

export const FETCH_USER_TABLES_SUCCESS = "FETCH_USER_TABLES_SUCCESS";

export const FETCH_ALL_TAGS_REQUESTED = "FETCH_ALL_TAGS_REQUESTED";

export const FETCH_ALL_TAGS_SUCCESS = "FETCH_ALL_TAGS_SUCCESS";

export const SET_QUESTION_VIEW_STATUS = "SET_QUESTION_VIEW_STATUS";

export const UPDATE_QUESTION_FILTER = "UPDATE_QUESTION_FILTER";
export const UPDATE_RECENT_QUESTION = "UPDATE_RECENT_QUESTION";
