import { all } from "redux-saga/effects";
import userSaga from "./userSaga";
import codeeditorSaga from "./codeeditorSaga";
import testSaga from "./testSaga";
import turnServerSaga from "./turnserverSaga";
import chatSaga from "./chatSaga";
import proctorSaga from "./proctorSaga";
import userdetailsSaga from "./userdetailsSaga";
import hrdashboardSaga from "./hrdashboardSaga";
import questionsSaga from "./questionbankSaga";
import emailtemplatesSaga from "./emailtemplatesSaga";
import interviewpanelSaga from "./interviewpanel";
import parseResumeSaga from "./parseResume";
import extractQuestionSaga from "./extractQuestion";
import evaluatorsSaga from "./evaluators";
import subscriptionSaga from "./subscription";

export default function* rootSaga() {
  yield all([
    userSaga(),
    codeeditorSaga(),
    testSaga(),
    turnServerSaga(),
    chatSaga(),
    proctorSaga(),
    userdetailsSaga(),
    hrdashboardSaga(),
    questionsSaga(),
    emailtemplatesSaga(),
    interviewpanelSaga(),
    parseResumeSaga(),
    extractQuestionSaga(),
    evaluatorsSaga(),
    subscriptionSaga()
  ]);
}
