import {
  LOGIN_REQUESTED,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SIGNUP_REQUESTED,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGOUT,
  SET_MESSAGE,
  SET_SUCCESS_MESSAGE,
  FORGOT_PASSWORD_REQUESTED,
  RESET_PASSWORD_REQUESTED,
  SET_SIGNUP_LOADING,
  REGISTER_PUBLIC_TEST_TOKEN,
  UPDATE_ALREADY_LOGIN_MODEL,
  LOGOUT_SUCCESSFUL,
} from "../actionTypes";

import AuthService from "../../services/auth.service";

export const register = (name, type, email, mobile, password) => (dispatch) => {
  return AuthService.register(name, type, email, mobile, password).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: "Registered Successfully! Login to continue.",
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        "Error! Enter a valid email/mobile or user already exists!";

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const registerPublicTestToken = (data) => {
  return {
    type: REGISTER_PUBLIC_TEST_TOKEN,
    payload: data,
  };
};

export const signupRequested = (data) => {
  return {
    type: SIGNUP_REQUESTED,
    payload: data,
  };
};

export const signupSuccess = (data) => {
  return {
    type: REGISTER_SUCCESS,
    payload: data,
  };
};

export const signupFailure = (data) => {
  return {
    type: REGISTER_FAIL,
    payload: data,
  };
};

export const loginRequest = (data) => {
  return {
    type: LOGIN_REQUESTED,
    payload: data,
  };
};

export const forgotPasswordRequest = (data) => {
  return {
    type: FORGOT_PASSWORD_REQUESTED,
    payload: data,
  };
};

export const resetPasswordRequest = (data) => {
  return {
    type: RESET_PASSWORD_REQUESTED,
    payload: data,
  };
};

export const loginSuccess = (data) => {
  return {
    type: LOGIN_SUCCESS,
    payload: { user: data },
  };
};

export const setMessage = (data) => {
  return {
    type: SET_MESSAGE,
    payload: data,
  };
};

export const setSuccessMessage = (data) => {
  return {
    type: SET_SUCCESS_MESSAGE,
    payload: data,
  };
};

export const loginFailure = (data) => {
  return {
    type: LOGIN_FAILURE,
    payload: data,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const logoutSuccessful = () => {
  return {
    type: LOGOUT_SUCCESSFUL,
  };
};

export const updateAlreadyLoggedinModel = (data) => {
  return {
    type: UPDATE_ALREADY_LOGIN_MODEL,
    payload: data,
  };
};

export const setSignupLoading = (data) => {
  return {
    type: SET_SIGNUP_LOADING,
    payload: data,
  };
};
