import React, { useRef, useState, useEffect } from "react";
import "../common/Common.css";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  setMessage,
  setSuccessMessage,
  resetPasswordRequest,
} from "../../store/actions/auth";
import { toast } from "react-toastify";
import eye from "../../assets/img/svg/heye.svg";
import crossEye from "../../assets/img/svg/cross-eye.svg";
import { useParams } from "react-router-dom";

const required = (value) => {
  if (!value) {
    return (
      <div
        className="alert alert-danger text-red-600 text-sm font-semibold mt-2"
        role="alert"
      >
        This field is required!
      </div>
    );
  }
};

const ResetPassword = (props) => {
  const form = useRef();
  const params = useParams();
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [alert, setAlert] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.auth);
  const { successmessage } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    handleToast(message);
    dispatch(setMessage(null));
  }, [message]);

  const handleToastSuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  if (successmessage !== null) {
    handleToastSuccess(successmessage);
    dispatch(setSuccessMessage(null));
    setTimeout(function () {
      window.location.replace("/login");
    }, 3000);
  }

  const handleToast = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const onChangePassword = (e) => {
    setAlert(false);
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeConfirmPassword = (e) => {
    setAlert(false);
    const confirmpassword = e.target.value;
    setConfirmPassword(confirmpassword);
  };

  const handleResetPAssword = (e) => {
    e.preventDefault();
    form.current.validateAll();
    if (password === confirmpassword) {
      dispatch(
        resetPasswordRequest({ password, resetToken: params.resetToken })
      );
      // setPassword("");
      // setConfirmPassword("");
    } else {
      setAlert(true);
    }
  };

  if (isLoggedIn) {
    <Navigate to="/dashboard" />;
  }

  const handleShowPassword = () => {
    setShowPassword((prevshowPassword) => !prevshowPassword);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((prevshowPassword) => !prevshowPassword);
  };

  return (
    <div className="flex md:px-10 shadow-2xl md:py-10 my-5 bg-white rounded-lg">
      <div className="login-input-div">
        <p className="login-heading pb-7">Reset Password</p>
        <Form
          onSubmit={handleResetPAssword}
          ref={form}
          autoComplete="new-password"
          className="pb-2"
        >
          <div className="login-form-pass">
            <label htmlFor="password">New Password</label>
            <div className="grid items-center relative">
              <Input
                type={`${showPassword ? "text" : "password"}`}
                className="login-form-passInput"
                autoComplete="off"
                name="password"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
              />
              <img
                onClick={handleShowPassword}
                src={showPassword ? crossEye : eye}
                className="absolute w-5 h-5 right-0 top-[22px] mr-4 cursor-pointer"
                title="View"
                alt="view"
              />
            </div>
          </div>

          <div className="login-form-pass">
            <label htmlFor="password">Confirm Password</label>
            <div className="grid items-center relative">
              <Input
                type={`${showConfirmPassword ? "text" : "password"}`}
                className="login-form-passInput"
                autoComplete="off"
                name="password"
                value={confirmpassword}
                onChange={onChangeConfirmPassword}
                validations={[required]}
              />
              <img
                onClick={handleShowConfirmPassword}
                src={showConfirmPassword ? crossEye : eye}
                className="absolute w-5 h-5 right-0 top-[22px] mr-4 cursor-pointer"
                title="View"
                alt="view"
              />
            </div>
            {alert && (
              <span className="text-red-600 text-sm font-semibold">
                Passwords not matched !
              </span>
            )}
          </div>

          <button
            className="text-white w-[200px] transition bg-[#009999]  hover:bg-gray-800 hover:text-white font-medium rounded-lg 
                  text-md px-6 py-2 text-center mr-2 cursor-pointer"
          >
            <span className="font-semibold">Reset Password</span>
          </button>
        </Form>
      </div>

    </div>
  );
};

export default ResetPassword;
