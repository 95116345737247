import React from "react";
import RenderQuestion from "../../components/tests/createTest/RenderQuestion";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchInterviewDetailsRequested } from "../../store/actions/interviewpanel";
import TestHeader from "../../components/tests/createTest/TestHeader";
import dummy from "../../assets/img/svg/dummypic.svg";
import { viewFile } from "../../services/upload.service";
import { MinsToHrs, calculateTestDuration, isFloat } from "../../components/common/utils";

const InterviewReport = () => {
  const dispatch = useDispatch();
  const [currentsection, setCurrentSection] = useState("");
  const [currentquestion, setCurrentQuestion] = useState("");
  const [imageFileUrl, setImageFileUrl] = useState("");
  const [idFileUrl, setIdFileUrl] = useState("");
  const interviewId = useParams()?.id;
  const { interviewDetails } = useSelector(
    (state) => state.interviewpanelReducer
  );

  useEffect(() => {
    if (interviewId !== "")
      dispatch(fetchInterviewDetailsRequested(interviewId));
  }, []);

  useEffect(() => {
    getIdUrl(interviewDetails.studentIdCardImg);
    getImageUrl(interviewDetails.studentImg);
  }, [interviewDetails]);

  function getImageUrl(filename) {
    if (filename && filename.length) {
      viewFile(filename, "PROCTORING").then((res) => {
        setImageFileUrl(res);
      });
    }
  }

  function getIdUrl(filename) {
    if (filename && filename.length) {
      viewFile(filename, "PROCTORING").then((res) => {
        setIdFileUrl(res);
      });
    }
  }

  return (
    <>
      <div className="p-5">
        <TestHeader
          styles="text-2xl text-[#000000] font-medium"
          title="Candidate Details"
        />

        <div className="mt-[1.5rem] mb-[2.5rem] flex flex-col sm:flex-row items-center justify-evenly gap-[1rem]">
          <img
            src={imageFileUrl || dummy}
            alt="studentImage"
            className="border-2 max-w-[300px] border-slate-300"
            title="User_Photo"
          />
          <img
            src={idFileUrl || dummy}
            alt="studentImage"
            className="border-2 max-w-[300px] border-slate-300"
            title="User Id"
          />
        </div>
        <div className="grid w-full px-3 md:px-0 lg:grid-cols-3 sm:grid-cols-2 gap-y-3 items-center md:items-start mb-[1rem] gap-2 px-3 md:px-0">
          <div className="sm:p-3 p-1 px-2">
            <p className="pl-2 text-lg font-medium font-['Inter']">Name</p>
            <p
              className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
              style={{
                border: "1.5px solid #E0E0E0",
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              {interviewDetails?.user?.name || interviewDetails?.student_email?.split("@")?.[0]}
            </p>
          </div>
          <div className="sm:p-3 p-1 px-2">
            <p className="pl-2 text-lg font-medium font-['Inter']">Email</p>
            <p
              className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
              style={{
                border: "1.5px solid #E0E0E0",
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              {interviewDetails?.student_email}
            </p>
          </div>
          <div className="sm:p-3 p-1 px-2">
            <p className="pl-2 text-lg font-medium font-['Inter']">
              Interviewer Email
            </p>
            <p
              className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
              style={{
                border: "1.5px solid #E0E0E0",
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              {interviewDetails?.interviewer_email}
            </p>
          </div>
        </div>

        <hr className="h-2" />

        <TestHeader
          styles="text-2xl text-[#000000] font-medium"
          title="Session Details"
        />
        <div className="w-full grid px-3 md:px-0 lg:grid-cols-3 sm:grid-cols-2 gap-y-3 items-center md:items-start">
          <div className="md:p-3 sm:p-2 p-1 px-2">
            <p className="pl-2 text-lg font-medium font-['Inter']">
              Interview Name
            </p>
            <p
              className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
              style={{
                border: "1.5px solid #E0E0E0",
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              {interviewDetails?.interview_title}
            </p>
          </div>
          <div className="md:p-3 sm:p-2 p-1 px-2">
            <p className="pl-2 text-lg font-medium font-['Inter']">
              Interview Start Time
            </p>
            <p
              className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
              style={{
                border: "1.5px solid #E0E0E0",
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              {new Date(
                interviewDetails?.startTimeInterview
              ).toLocaleDateString() +
                " " +
                new Date(
                  interviewDetails?.startTimeInterview
                ).toLocaleTimeString()}
            </p>
          </div>
          <div className="md:p-3 sm:p-2 p-1 px-2">
            <p className="pl-2 text-lg font-medium font-['Inter']">
              Interview End Time
            </p>
            <p
              className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
              style={{
                border: "1.5px solid #E0E0E0",
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              {new Date(
                interviewDetails?.endTimeInterview
              ).toLocaleDateString() +
                " " +
                new Date(
                  interviewDetails?.endTimeInterview
                ).toLocaleTimeString()}{" "}
            </p>
          </div>
          <div className="md:p-3 sm:p-2 p-1 px-2">
            <p className="pl-2 text-lg font-medium font-['Inter']">
              Interview Duration
            </p>
            <p
              className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
              style={{
                border: "1.5px solid #E0E0E0",
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              {MinsToHrs(calculateTestDuration(interviewDetails?.startTimeInterview, interviewDetails?.endTimeInterview))}
            </p>
          </div>
        </div>

        <hr className="h-2" />
        <TestHeader
          styles="text-3xl text-[#000000] font-medium"
          title="Interview Details"
        />

        <div className="flex flex-col items-start gap-2 lg:flex-row lg:items-center justify-between mb-4 ">
          <div className="flex items-start flex-col ">
            <h2 className="text-2xl font-semibold font-['nunito']  mb-0">
              {interviewDetails.interview_title}
            </h2>
          </div>
          <div className="w-auto flex bg-[#BCFB464A] px-1 md:px-3 py-1 items-center gap-x-2 py-1 rounded">
            <span className="text-[1.25rem] md:text-[1.875rem] font-medium">{`${isFloat(interviewDetails?.marks)
              ? interviewDetails?.marks.toFixed(2)
              : interviewDetails?.marks || 0
              }`}</span>
            <span className="text-[0.875rem] md:text-[1.25rem] font-semibold">
              Marks out of
            </span>
            <span className="text-[0.875rem] md:text-[1.25rem] font-semibold">
              {`100 Marks`}
            </span>
          </div>
          <div className="flex flex-col gap-x-3 items-start  ">
            <div className="flex flex-col items-end py-1">
              {
                interviewDetails?.user ? <div className="flex flex-col items-start">
                  <h4 className="text-[16px] font-medium font-['Inter']">
                    Name:
                    <span className=" pl-3 text-[16px] font-semibold">
                      {interviewDetails?.user?.name}
                    </span>
                  </h4>
                  <h4 className="text-[16px] font-medium font-['Inter']">
                    Email:
                    <span className=" pl-3 text-[16px] font-semibold">
                      {interviewDetails?.user?.email}
                    </span>
                  </h4>
                </div> : null
              }

            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-x-2">
          <div className="flex flex-col">
            <p className="text-md font-['poppins'] font-medium pb-2">
              Choose Section :
            </p>
            <select
              onChange={(e) => setCurrentSection(e.target.value)}
              value={currentsection}
              className=" rounded font-['poppins'] border w-48 border-gray-300 outline-gray-300 p-1"
            >
              <option value="">Select Section</option>
              {interviewDetails.components?.map((section, sectionIndex) => (
                <option value={sectionIndex} key={`section-${sectionIndex}`}>
                  {`section-${sectionIndex + 1}`}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <p className="text-md font-['poppins'] font-medium pb-2">
              Choose Question :
            </p>
            <select
              onChange={(e) => setCurrentQuestion(e.target.value)}
              value={currentquestion}
              className=" rounded font-['poppins'] border w-48 border-gray-300 outline-gray-300 p-1"
            >
              <option value="">Select Question</option>
              {currentsection !== "" &&
                interviewDetails.components[currentsection]?.components?.map(
                  (question, questionIndex) => (
                    <option
                      value={questionIndex}
                      key={`question-${questionIndex}`}
                    >
                      {questionIndex + 1}
                    </option>
                  )
                )}
            </select>
          </div>
        </div>

        {interviewDetails.components.map((section, sectionIndex) => (
          <>
            {currentsection !== "" ? (
              <>
                {parseInt(currentsection) === sectionIndex && (
                  <div key={sectionIndex} className="bg-white p-lg-5 mt-4">
                    <h4 className="text-left text-2xl font-['nunito'] font-bold mb-0">
                      {section.title}
                    </h4>
                    {section?.description ? (
                      <>
                        <p className="text-muted text-lg font-medium mt-4 mb-1 pl-1 font-[poppins]">
                          Section Description
                        </p>

                        <p
                          dangerouslySetInnerHTML={{
                            __html: section.description,
                          }}
                          className="text-muted bg-[#EEFECF] shadow-[#BCFB46] rounded-lg shadow p-5 text-base mb-4 font-[poppins] text-slate-600"
                        ></p>
                      </>
                    ) : null}
                    {section.components.map((question, questionIndex) => (
                      <div
                        key={`renderquestion-${sectionIndex}-${questionIndex}`}
                      >
                        {currentquestion !== "" ? (
                          <>
                            {parseInt(currentquestion) === questionIndex && (
                              <div className="mt-3 mb-4 p-3">
                                <RenderQuestion
                                  sectionIndex={sectionIndex}
                                  question_details={question}
                                  index={questionIndex}
                                  response={interviewDetails?.response}
                                  type="I"
                                  // marking={marking}
                                  isTestCreation={false}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="mt-3 mb-4 p-3">
                            <RenderQuestion
                              sectionIndex={sectionIndex}
                              question_details={question}
                              index={questionIndex}
                              type="I"
                              response={interviewDetails?.response}
                              // marking={marking}
                              isTestCreation={false}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <div key={sectionIndex} className="bg-white p-lg-5 mt-4">
                <h4 className="text-left text-2xl font-['nunito'] font-bold mb-0">
                  {section.title}
                </h4>
                {section?.description ? (
                  <>
                    <p className="text-muted text-lg font-medium mt-4 mb-1 pl-1 font-[poppins]">
                      Section Description
                    </p>

                    <p
                      dangerouslySetInnerHTML={{
                        __html: section.description,
                      }}
                      className="text-muted bg-[#EEFECF] shadow-[#BCFB46] rounded-lg shadow p-5 text-base mb-4 font-[poppins] text-slate-600"
                    ></p>
                  </>
                ) : null}

                {section.components.map((question, questionIndex) => (
                  <div key={`renderquestion2-${sectionIndex}-${questionIndex}`}>
                    <div className="mt-3 mb-4 p-3">
                      <RenderQuestion
                        sectionIndex={sectionIndex}
                        question_details={question}
                        index={questionIndex}
                        type="I"
                        response={interviewDetails?.response}
                        //   marking={marking}
                        isTestCreation={false}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        ))}
      </div>
    </>
  );
};

export default InterviewReport;
