import React from "react";

const FirstStep = ({
  addTags,
  removeTags,
  handleFile,
  handleChange,
  values,
}) => {
  return (
    <div className="flex flex-col space-y-4 p-2">
      <div className="mt-2">
        <label className="bg-[#008bf8] rounded text-white py-2 px-3 hover:cursor-pointer">
          <input
            hidden
            type="file"
            accept=".pdf"
            onChange={handleFile}
            name="resume_file"
            value={values.resume_file}
          ></input>
          Upload Resume
        </label>
      </div>
      <div>
        <div className="">
          <label className="block">
            <span className="block my-2 text-neutral-650 font-[540]">
              College
            </span>
            <input
              name="college"
              value={values.college}
              onChange={handleChange}
              type="text"
              className="border border-gray-300 outline-gray-200 p-2 w-full"
            />
          </label>
        </div>
      </div>
      <div>
        <div className="">
          <label className="block">
            <span className="block my-2 text-neutral-650 font-[540]">
              Previous Employment
            </span>
            <textarea
              onChange={handleChange}
              name="prev_employment"
              value={values.prev_employment}
              className="border border-gray-300 outline-gray-200 p-2 w-full"
              cols="20"
              divs="3"
            ></textarea>
          </label>
        </div>
      </div>
      <div>
        <div className="">
          <label className="block">
            <span className="block my-2 text-neutral-650 font-[540]">
              Tech Stack
            </span>
          </label>
          <div className="tags-input">
            <ul id="tags">
              {values.tech_stack.map((tag, index) => (
                <li key={index} className="tag">
                  <span className="tag-title">{tag}</span>
                  <span
                    className="tag-close-icon"
                    onClick={(e) => removeTags(e, index)}
                  >
                    x
                  </span>
                </li>
              ))}
            </ul>
            <input
              type="text"
              onKeyUp={(e) => (e.key === "Enter" ? addTags(e) : null)}
              placeholder="Press enter to add tags"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 w-full">
        <label className="block">
          <span className="block my-2 text-neutral-650 font-[540]">Branch</span>
          <input
            onChange={handleChange}
            name="branch"
            value={values.branch}
            type="text"
            className="border border-gray-300 outline-gray-200 p-2 w-2/3"
          />
        </label>

        <label className="block">
          <span className="block my-2 text-neutral-650 font-[540]">CGPA</span>
          <input
            onChange={handleChange}
            name="cgpa"
            value={values.cgpa}
            type="number"
            className="border border-gray-300 outline-gray-200 p-2 w-2/3"
          />
        </label>
      </div>
      <div>
        <div className="">
          <label className="block">
            <span className="block my-2 text-neutral-650 font-[540]">
              Address
            </span>
            <textarea
              onChange={handleChange}
              name="address"
              value={values.address}
              className="border border-gray-300 outline-gray-200 p-2 w-full"
              cols="30"
              divs="3"
            ></textarea>
          </label>
        </div>
      </div>
      <div>
        <div className="">
          <label className="block">
            <span className="block my-2 text-neutral-650 font-[540]">
              Location
            </span>
            <input
              onChange={handleChange}
              name="location"
              value={values.location}
              type="text"
              className="border border-gray-300 outline-gray-200 p-2 w-full"
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default FirstStep;
