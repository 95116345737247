import { call, put, takeEvery } from "redux-saga/effects";
import {
  ASK_QUESTION_REQUEST,
  ASSIGN_INTERVIEW_REQUEST,
  ASSIGN_TEST_REQUEST,
  CREATE_CHAT_REQUEST,
  EXPORT_CSV_REQUEST,
  GET_CHAT_REQUEST,
  GET_DEPARTMENT_REQUEST,
  GET_PROFILE_REQUEST,
  GET_RESUME_DETAILS_REQUEST,
  PARSE_RESUME_REQUEST,
  REPARSE_RESUME_REQUEST,
  SEND_EMAIL_REQUEST,
  UPDATE_CHAT_REQUEST,
  UPDATE_STATUS_OF_RESUME,
  UPLOAD_FILE_REQUEST,
} from "../actionTypes/parseResume";
import {
  uploadFileSuccess,
  uploadFileFailed,
  askQuestionSuccess,
  askQuestionFailed,
  getDepartmentSuccess,
  getDepartmentFailed,
  getProfileSuccess,
  getProfileFailed,
  parseResumeSuccess,
  parseResumeFailed,
  sendEmailSuccess,
  sendEmailFailed,
  exportCSVSuccess,
  exportCSVFailed,
  assignTestSuccess,
  assignTestFailed,
  assignInterviewSuccess,
  assignInterviewFailed,
  getResumeDetailsSuccess,
  getResumeDetailsFailed,
  reparseResumeSuccess,
  reparseResumeFailed,
  getChatSuccess,
  getChatFailed,
  createChatSuccess,
  createChatFailed,
  updateChatSuccess,
  updateChatFailed,
  selectedMail,
} from "../actions/parseResume";
import {
  askQuestionRequestApi,
  assignInterviewApi,
  createChatApi,
  exportCSVApi,
  getChatApi,
  getDepartmentApi,
  getDetailsFromSessionId,
  getHeaderFromSessionId,
  getProfileApi,
  parseResumeApi,
  reparseResumeApi,
  sendEmailApi,
  updateChatApi,
  updateStatusOfResumeApi,
  uploadFileRequestApi,
} from "../../services/parseResume.service";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../assets/js/toast_config";
import { assignTest } from "../../services/test.service";

function* uploadFileRequest(action) {
  const { link, files, setPage, setLoadingPopup } = action.payload;
  try {
    const res = yield call(uploadFileRequestApi, { link, files });
    yield put(uploadFileSuccess(res));

    // yield getDepartment();
    // setPage("filter");
  } catch (e) {
    yield put(uploadFileFailed(e));
  } finally {
    setLoadingPopup(true);
  }
}

function* askQuestionRequest(action) {
  try {
    const { prompt, session_id, type, time, resumes } = action.payload;
    yield put(askQuestionSuccess({ prompt, type, time }));
    const res = yield call(askQuestionRequestApi, {
      prompt,
      session_id,
      resumes,
    });
    yield put(askQuestionSuccess(res));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(askQuestionFailed(e));
  }
}

// function* getDepartment(action) {
//   try {
//     const res = yield call(getDepartmentApi);
//     yield put(getDepartmentSuccess(res));
//   } catch (e) {
//     toast.error(e.message, defaultToastSetting);
//     yield put(getDepartmentFailed(e));
//   }
// }

// function* getProfile(action) {
//   try {
//     const res = yield call(getProfileApi, action.payload);
//     yield put(getProfileSuccess(res));
//   } catch (e) {
//     toast.error(e.message, defaultToastSetting);
//     yield put(getProfileFailed(e));
//   }
// }

function* parseResume(action) {
  try {
    // const { setPage, count, query } = action.payload;
    const headerResponse = yield call(getHeaderFromSessionId, action.payload);
    yield put(parseResumeSuccess({ header: headerResponse }));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(parseResumeFailed(e));
  }
}

function* reparseResume(action) {
  try {
    const { sessionId, chat_id, retry } = action.payload;
    const res = yield call(reparseResumeApi, { sessionId, retry });
    yield put(reparseResumeSuccess({ chat_id, res }));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(reparseResumeFailed(e));
  }
}

function* getResumeDetails(action) {
  try {
    const res = yield call(getDetailsFromSessionId, action.payload);

    const res2 = yield call(createChatApi, {
      from: `user_${action.payload}`,
      to: `user_${localStorage.getItem("priksha_email")}`,
      message: { type: "table", result: res },
      session_id: action.payload,
    });
    yield put(createChatSuccess({ data: res2.data, loader: false }));
    // yield put(getResumeDetailsSuccess(res));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(getResumeDetailsFailed(e));
  }
}

function* sendEmail(action) {
  try {
    const { message, setSendEmail, subject, emails } = action.payload;
    const res = yield call(sendEmailApi, { emails, message, subject });
    yield put(sendEmailSuccess(res));
    setSendEmail({ message: "", subject: "" });
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(sendEmailFailed(e));
  }
}

function* updateStatusOfResume(action) {
  try {
    yield call(updateStatusOfResumeApi, action.payload);
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
  }
}

function* exportCSV(action) {
  try {
    const { setExportModal, token, status, parameters, resumes } =
      action.payload;
    const res = yield call(exportCSVApi, {
      token,
      status,
      parameters,
      resumes,
    });
    yield put(exportCSVSuccess(res));
    setExportModal("");
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(exportCSVFailed(e));
  }
}

function* asssignTest(action) {
  try {
    const { id, assign, setTestModal } = action.payload;
    const res = yield call(assignTest, { id, data: { assign } });
    yield put(assignTestSuccess(res));
    setTestModal(false);
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(assignTestFailed(e));
  }
}

function* asssignInterview(action) {
  try {
    const { interview_details, students, setinterviewModal } = action.payload;
    const res = yield call(assignInterviewApi, {
      interview_details,
      students,
    });

    yield put(assignInterviewSuccess(res));
    setinterviewModal(false);
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(assignInterviewFailed(e));
  }
}

function* getChat(action) {
  try {
    const res = yield call(getChatApi, action.payload);
    // console.log(res);
    if (!res.data.length) yield* getResumeDetails({ ...action });
    else yield put(getChatSuccess(res.data));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(getChatFailed(e));
  }
}

function* createChat(action) {
  try {
    const { prompt, session_id, resumes } = action.payload;

    const res1 = yield call(createChatApi, {
      to: `user_${session_id}`,
      from: `user_${localStorage.getItem("priksha_email")}`,
      message: { type: "text", result: prompt },
      session_id: session_id,
    });
    yield put(createChatSuccess({ data: res1.data, loader: true }));

    const res = yield call(askQuestionRequestApi, {
      prompt,
      session_id,
      resumes,
    });

    const res2 = yield call(createChatApi, {
      from: `user_${session_id}`,
      to: `user_${localStorage.getItem("priksha_email")}`,
      session_id: session_id,
      ...(res.trueResults.length
        ? { message: { type: "table", result: res.trueResults } }
        : { message: { type: "text", result: "No results found" } }),
    });
    yield put(selectedMail([]));
    yield put(createChatSuccess({ data: res2.data, loader: false }));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(createChatFailed(e));
  }
}

function* updateChat(action) {
  try {
    const { message, chat_id } = action.payload;
    const res = yield call(updateChatApi, {
      message: { type: "table", result: message },
      chat_id,
    });

    yield put(updateChatSuccess(res));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(updateChatFailed(e));
  }
}

function* parseResumeSaga() {
  yield takeEvery(UPLOAD_FILE_REQUEST, uploadFileRequest);
  yield takeEvery(ASK_QUESTION_REQUEST, askQuestionRequest);
  // yield takeEvery(GET_DEPARTMENT_REQUEST, getDepartment);
  // yield takeEvery(GET_PROFILE_REQUEST, getProfile);
  yield takeEvery(PARSE_RESUME_REQUEST, parseResume);
  yield takeEvery(REPARSE_RESUME_REQUEST, reparseResume);
  yield takeEvery(GET_RESUME_DETAILS_REQUEST, getResumeDetails);
  yield takeEvery(SEND_EMAIL_REQUEST, sendEmail);
  yield takeEvery(UPDATE_STATUS_OF_RESUME, updateStatusOfResume);
  yield takeEvery(EXPORT_CSV_REQUEST, exportCSV);
  yield takeEvery(ASSIGN_TEST_REQUEST, asssignTest);
  yield takeEvery(ASSIGN_INTERVIEW_REQUEST, asssignInterview);
  yield takeEvery(GET_CHAT_REQUEST, getChat);
  yield takeEvery(CREATE_CHAT_REQUEST, createChat);
  yield takeEvery(UPDATE_CHAT_REQUEST, updateChat);
}

export default parseResumeSaga;
