import { DELETE_SUBSCRIPTION, DELETE_SUBSCRIPTION_FAILURE, DELETE_SUBSCRIPTION_SUCCESS, GET_ALL_SUBSCRIPTION, GET_ALL_SUBSCRIPTION_FAILURE, GET_ALL_SUBSCRIPTION_SUCCESS, GET_SINGLE_SUBSCRIPTION, GET_SINGLE_SUBSCRIPTION_FAILURE, GET_SINGLE_SUBSCRIPTION_SUCCESS, UPDATE_SUBSCRIPTION, UPDATE_SUBSCRIPTION_FAILURE, UPDATE_SUBSCRIPTION_SUCCESS } from "../actionTypes/subscription";

const initialState = {
    subscription: [],
    personalSubscription:{},
    personalSubscriptionLoading:false,
    subscriptionLoading: false,
    updateSubscriptionLoading: false,
    deleteSubscriptionLoading: false,
};

const SubscriptionReducer = function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case GET_ALL_SUBSCRIPTION:
            return {
                ...state,
                subscriptionLoading: true,
            };

        case GET_ALL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscription: payload,
                subscriptionLoading: false,
            };

        case GET_ALL_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                subscriptionLoading: false,
            };

        case GET_SINGLE_SUBSCRIPTION:
            return {
                ...state,
                personalSubscriptionLoading: true,
            };

        case GET_SINGLE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                personalSubscription: payload,
                personalSubscriptionLoading: false,
            };

        case GET_SINGLE_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                personalSubscriptionLoading: false,
            };

        case UPDATE_SUBSCRIPTION:
            return {
                ...state,
                updateSubscriptionLoading: true,
            };

        case UPDATE_SUBSCRIPTION_SUCCESS: {
            const updatedSubscription = state.subscription.map(sb => {
                if (sb.userId._id === payload.userId) return {...payload, userId:sb.userId};
                return sb;
            })
            return {
                ...state,
                subscription: updatedSubscription,
                updateSubscriptionLoading: false,
            };
        }

        case UPDATE_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                updateSubscriptionLoading: false,
            };

        case DELETE_SUBSCRIPTION:
            return {
                ...state,
                deleteSubscriptionLoading: true,
            };

        case DELETE_SUBSCRIPTION_SUCCESS: {
            const updatedSubscription = state.subscription.filter(sb => sb._id !== payload.id)
            return {
                ...state,
                subscription: updatedSubscription,
                deleteSubscriptionLoading: false,
            };
        }
        
        case DELETE_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                deleteSubscriptionLoading: false,
            };

        default:
            return state;
    }
};

export default SubscriptionReducer;
