import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../assets/js/toast_config";
import { deleteSubscriptionFailure, deleteSubscriptionSuccess, getAllSubscriptionFailure, getAllSubscriptionSuccess, getSingleSubscriptionFailure, getSingleSubscriptionSuccess, updateSubscriptionFailure, updateSubscriptionSuccess } from "../actions/subscription";
import { DELETE_SUBSCRIPTION, GET_ALL_SUBSCRIPTION, GET_SINGLE_SUBSCRIPTION, UPDATE_SUBSCRIPTION } from "../actionTypes/subscription";
import { deleteSubscriptionApi, getAllSubscriptionApi, getSingleSubscriptionApi, updateSubscriptionApi } from "../../services/subscription.service";


function* getAllSubscription() {
    try {
        const res = yield call(getAllSubscriptionApi);
        yield put(getAllSubscriptionSuccess(res));
    } catch (e) {
        toast.error(e, defaultToastSetting);
        yield put(getAllSubscriptionFailure(e));
    }
}

function* getSingleSubscription() {
    try {
        const res = yield call(getSingleSubscriptionApi);
        yield put(getSingleSubscriptionSuccess(res));
    } catch (e) {
        yield put(getSingleSubscriptionFailure(e));
        toast.error(e, defaultToastSetting);
    }
}

function* updateSubscription(action) {
    const { data, setSubscriptionModal } = action.payload
    try {
        const res = yield call(updateSubscriptionApi, data);
        yield put(updateSubscriptionSuccess(res));
    } catch (e) {
        yield put(updateSubscriptionFailure(e));
        toast.error(e, defaultToastSetting);
    } finally {
        setSubscriptionModal(false)
    }
}

function* deleteSubscription(action) {
    const {setDeleteModal, id} = action.payload
    try {
        const res = yield call(deleteSubscriptionApi, id);
        yield put(deleteSubscriptionSuccess({id}));
    } catch (e) {
        yield put(deleteSubscriptionFailure(e));
        toast.error(e, defaultToastSetting);
    }finally{
        setDeleteModal(null)
    }
}

function* subscriptionSaga() {
    yield takeEvery(GET_ALL_SUBSCRIPTION, getAllSubscription);
    yield takeEvery(GET_SINGLE_SUBSCRIPTION, getSingleSubscription);
    yield takeEvery(UPDATE_SUBSCRIPTION, updateSubscription);
    yield takeEvery(DELETE_SUBSCRIPTION, deleteSubscription);
}

export default subscriptionSaga;
