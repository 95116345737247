import React from "react";
import CurrentTestCard from "../../pages/CurrentTestCard";

const TestSeeAllModal = ({
  data,
  isAdmin,
  isTeacher,
  isKAInstructor,
  isAssistant,
  showDropDown,
  handleDropDown,
  handleDeleteTest,
}) => {
  return (
    <>
      <h1 className="w-full text-center font-semibold font-['nunito'] text-[1.8rem]">
        All Tests
      </h1>
      <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 w-full max-h-[72vh] overflow-y-auto scrollbar px-2">
        {data?.map((data, data_index) => (
          <CurrentTestCard
            key={`CURRENT${data._id}`}
            data={data}
            data_index={data_index}
            showDropDown={showDropDown}
            handleDropDown={handleDropDown}
            handleDeleteTest={handleDeleteTest}
            isAdmin={isAdmin}
            isTeacher={isTeacher}
            isKAInstructor={isKAInstructor}
            isAssistant={isAssistant}
          />
        ))}
      </div>
    </>
  );
};

export default TestSeeAllModal;
