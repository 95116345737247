import React, { Component } from "react";
import smile from "../../../assets/img/emojis/smile.svg";
import natural from "../../../assets/img/emojis/natural.svg";
import disappointed from "../../../assets/img/emojis/disappointed.svg";
import { connect } from "react-redux";
import { submitFeedbackRequested } from "../../../store/actions/test";
import { toast } from "react-toastify";
import { toggleSidebar } from "../../../store/actions/sidebar";
import JSZip from "jszip";
import { withRouter } from "../../common/WithRouter";
class FeedBack extends Component {
  constructor() {
    super();
    this.state = {
      feedback: "",
      feedback_type: "appreciation",
      rating: 5,
      // proctor_feedback: "",
      // proctor_feedback_type: "appreciation",
      proctor_rating: 5,
    };
    this.handleChangeFeedback = this.handleChangeFeedback.bind(this);
    this.handleChangeFeedbackType = this.handleChangeFeedbackType.bind(this);
    this.handleChangeFeedback = this.handleChangeFeedback.bind(this);
  }

  componentDidMount() {
    if (this.props.sidebar.opened) {
      this.props.toggleSidebar();
    }
    // this.downloadDataset();

  }

  downloadDataset = async () => {
    const dataURLs = JSON.parse(localStorage.getItem('storedImages')) || [];
    const blobs = dataURLs.map((dataURL) => {
      const byteString = atob(dataURL.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: 'image/jpeg' });
    });

    const zip = new JSZip();

    blobs.forEach((blob, index) => {
      zip.file(`image${index}.jpg`, blob);
    });

    zip.generateAsync({ type: 'blob' }).then(function (content) {
      const zipBlob = new Blob([content], { type: 'application/zip' });
      const zipUrl = URL.createObjectURL(zipBlob);
      const a = document.createElement('a');
      a.href = zipUrl;
      a.download = 'images.zip';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
    localStorage.removeItem("storedImages");
  }


  componentDidUpdate(prevProps) {
    if (prevProps.feedbackMessage !== this.props.feedbackMessage) {
      this.handleToast(this.props.feedbackMessage, this.props.error);
      const timer = setTimeout(() => {
        const { history, location } = this.props;
        history('/tests',
          { state: location.state }
        );
      }, 3000);
      return () => clearTimeout(timer);
    }
  }

  handleToast = (msg, error) => {
    if (error) {
      return toast.error(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return toast.success(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  handleChangeFeedback = (e) => {
    this.setState((prev) => {
      return {
        feedback: e.target.value,
      };
    });
  };
  // handleChangeProctorFeedback = (e) => {
  //   this.setState((prev) => {
  //     return {
  //       proctor_feedback: e.target.value,
  //     };
  //   });
  // };

  handleChangeFeedbackType = (e) => {
    this.setState((prev) => {
      return {
        feedback_type: e.target.value,
      };
    });
  };

  // handleChangeProctorFeedbackType = (e) => {
  //   this.setState((prev) => {
  //     return {
  //       proctor_feedback_type: e.target.value,
  //     };
  //   });
  // };

  handleSubmitFeedback = (e) => {
    const {
      feedback,
      rating,
      feedback_type,
      // proctor_feedback,
      // proctor_feedback_type,
      proctor_rating,
    } = this.state;
    let sendObject = {
      rating,
      feedback_type,
      // proctor_feedback_type,
      proctor_rating,
      ...(feedback !== "" && { feedback }),
      // ...(proctor_feedback !== "" && { proctor_feedback }),
    };

    this.props.submitFeedbackRequested(this.props.params.id, sendObject);
  };

  render() {
    return (
      <div className="flex font-['nunito'] bg-white my-5  w-full justify-center  my-2">
        <div className="rounded-lg max-w-md w-full space-y-4 bg-white">
          <div className="rounded-lg text-[#001437] max-w-md bg-[#BCFB46] overflow-hidden  p-5">
            <h2 className="my-1 text-start text-2xl font-bold">
              Please, Send us your feedback !
            </h2>
            <h5> Your feedback is valuable for us.</h5>
          </div>
          <div className="rounded shadow-2xl max-w-md overflow-hidden shadow-xl px-5">
            <div className="flex-col gap-6 ">
              <div className="col-span-12 bg-[#F2FEDC] px-3 py-2">
                <label className="block text-md font-medium text-gray-700 pb-2">
                  How was your overall test experience?
                </label>
                <div className="flex gap-5 mb-2">
                  <img
                    src={smile}
                    className={`w-7 h-7 cursor-pointer border-[#8EDA00] ${this.state.rating === 5 && "border-2"
                      } rounded-3xl`}
                    alt="Smile"
                    title="Happy"
                    onClick={() => this.setState({ rating: 5 })}
                  />
                  <img
                    src={natural}
                    className={`w-7 h-7 cursor-pointer border-[#8EDA00] ${this.state.rating === 3 && "border-2"
                      } rounded-3xl`}
                    alt="Natural"
                    title="Natural"
                    onClick={() => this.setState({ rating: 3 })}
                  />
                  <img
                    src={disappointed}
                    className={`w-7 h-7 cursor-pointer border-[#8EDA00] ${this.state.rating === 1 && "border-2"
                      } rounded-3xl`}
                    alt="Disappointed"
                    title="Sad"
                    onClick={() => this.setState({ rating: 1 })}
                  />
                </div>
                {/* <div className="flex justify-between items-center py-2">
                  <div className="flex">
                    <input
                      className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-500 bg-white checked:bg-[#BCFB46] checked:border-[#87CE01] focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      onChange={this.handleChangeFeedbackType}
                      name="flexRadioDefault"
                      id="appreciation_feedback"
                      value="appreciation"
                      checked={this.state.feedback_type === "appreciation"}
                    />
                    <label
                      className="form-check-label inline-block text-gray-800"
                      htmlFor="appreciation_feedback"
                    >
                      Appreciation
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-500 bg-white checked:bg-[#BCFB46] checked:border-[#87CE01] focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      onChange={this.handleChangeFeedbackType}
                      name="flexRadioDefault"
                      id="bug_feedback"
                      value="bug"
                      checked={this.state.feedback_type === "bug"}
                    />
                    <label
                      className="form-check-label inline-block text-gray-800"
                      htmlFor="bug_feedback"
                    >
                      Bug
                    </label>
                  </div>
                  <div className="flex">
                    <input
                      className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-500 bg-white checked:bg-[#BCFB46] checked:border-[#87CE01] focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      onChange={this.handleChangeFeedbackType}
                      name="flexRadioDefault"
                      id="suggestion_feedback"
                      value="suggestion"
                      checked={this.state.feedback_type === "suggestion"}
                    />
                    <label
                      className="form-check-label inline-block text-gray-800"
                      htmlFor="suggestion_feedback"
                    >
                      Suggestion
                    </label>
                  </div>
                </div>
                <textarea
                  className="border-2 p-2  mt-1 border-gray-100 w-full rounded-md bg-gray-100"
                  placeholder="Describe Your Experience Here ..."
                  value={this.state.feedback}
                  onChange={this.handleChangeFeedback}
                  rows={5}
                /> */}
              </div>

              <div className="col-span-12 mt-4 bg-[#F2FEDC] px-3 py-2">
                <label className="block text-md font-medium text-gray-700 pb-2">
                  How was your proctoring experience?
                </label>
                <div className="flex gap-5 mb-2">
                  <img
                    src={smile}
                    className={`w-7 h-7 cursor-pointer border-[#8EDA00] ${this.state.proctor_rating === 5 && "border-2"
                      } rounded-3xl`}
                    alt="Smile"
                    title="Happy"
                    onClick={() => this.setState({ proctor_rating: 5 })}
                  />
                  <img
                    src={natural}
                    className={`w-7 h-7 cursor-pointer border-[#8EDA00] ${this.state.proctor_rating === 3 && "border-2"
                      } rounded-3xl`}
                    alt="Natural"
                    title="Natural"
                    onClick={() => this.setState({ proctor_rating: 3 })}
                  />
                  <img
                    src={disappointed}
                    className={`w-7 h-7 cursor-pointer border-[#8EDA00] ${this.state.proctor_rating === 1 && "border-2"
                      } rounded-3xl`}
                    alt="Disappointed"
                    title="Sad"
                    onClick={() => this.setState({ proctor_rating: 1 })}
                  />
                </div>
              </div>
              <div className="col-span-12 mt-4 bg-[#F2FEDC] px-3 py-2">
                <div>
                  <p className="block text-md font-medium text-gray-700 pb-2">Do you have any other comments?</p>
                </div>
                <div className="flex justify-between items-center py-2">
                  <div className="flex">
                    <input
                      className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-500 bg-white checked:bg-[#BCFB46] checked:border-[#87CE01] focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      onChange={this.handleChangeFeedbackType}
                      name="flexRadioDefault2"
                      id="proctor_appreciation_feedback"
                      value="appreciation"
                      checked={
                        this.state.feedback_type === "appreciation"
                      }
                    />
                    <label
                      className="form-check-label inline-block text-gray-800"
                      htmlFor="proctor_appreciation_feedback"
                    >
                      Appreciation
                    </label>
                  </div>

                  <div className="flex">
                    <input
                      className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-500 bg-white checked:bg-[#BCFB46] checked:border-[#87CE01] focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      onChange={this.handleChangeFeedbackType}
                      name="flexRadioDefault2"
                      id="proctor_bug_feedback"
                      value="bug"
                      checked={this.state.feedback_type === "bug"}
                    />
                    <label
                      className="form-check-label inline-block text-gray-800"
                      htmlFor="proctor_bug_feedback"
                    >
                      Bug
                    </label>
                  </div>

                  <div className="flex">
                    <input
                      className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-500 bg-white checked:bg-[#BCFB46] checked:border-[#87CE01] focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      onChange={this.handleChangeFeedbackType}
                      name="flexRadioDefault2"
                      id="proctor_suggestion_feedback"
                      value="suggestion"
                      checked={
                        this.state.feedback_type === "suggestion"
                      }
                    />
                    <label
                      className="form-check-label inline-block text-gray-800"
                      htmlFor="proctor_suggestion_feedback"
                    >
                      Suggestion
                    </label>
                  </div>
                </div>
                <textarea
                  className="border-2 p-2  mt-1 border-gray-100 w-full rounded-md bg-white focus:none"
                  placeholder="Describe Your Experience Here ..."
                  value={this.state.feedback}
                  onChange={this.handleChangeFeedback}
                  rows={5}
                />
              </div>

              <button
                className="bg-[#BCFB46] text-[#001437] hover:opacity-80 w-full  font-bold py-2 mb-2 mt-4  rounded"
                onClick={this.handleSubmitFeedback}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test, sidebar }) => {
  return {
    feedbackMessage: test.feedbackMessage,
    error: test.error,
    sidebar: sidebar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitFeedbackRequested: (testId, data) =>
      dispatch(submitFeedbackRequested(testId, data)),
    toggleSidebar: () => dispatch(toggleSidebar()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeedBack));
