import React, { Component, createRef } from "react";
import "../common/Common.css";
import { connect } from "react-redux";
import { signupRequested } from "../../store/actions/auth";
import { toast } from "react-toastify";
import { ReactComponent as ButtonSpinner } from "../../assets/img/svg/button-spinner.svg";
import KyronSideLetter from "../../assets/img/Kyron/KyronLetterSide.png";
import axios from "axios";
import { BASE_URL } from "../../assets/js/config";
import { defaultToastSetting } from "../../assets/js/toast_config";
import googleIcon from "../../assets/img/svg/google.svg";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { withRouter } from "../common/WithRouter";

class googleSignUp extends Component {
  constructor(props) {
    super(props);

    this.form = createRef();
    this.form.current = null;
    this.state = {
      formdata: {
        mobile: "",
        gender: "",
        college: "",
      },
      temp_college_data: null,
      temp_gender_data: null,
      mobileError: "",
      genderError: "",
      collegeError: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isSignedUp &&
      !this.props.isSignupLoading &&
      !prevProps.isSignedUp
    ) {
      this.props.history("/login");
    }
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    let new_form_data = { ...this.state.formdata };
    new_form_data[name] = value;

    this.setState({ formdata: new_form_data, [`${name}Error`]: "" });
  };
  handleCollegeChange = (e) => {
    let new_form_data = { ...this.state.formdata };
    new_form_data["college"] = e.college_id;
    this.setState({
      formdata: new_form_data,
      collegeError: "",
      temp_college_data: e,
    });
  };
  handleGenderChange = (e) => {
    let new_form_data = { ...this.state.formdata };
    new_form_data["gender"] = e.value;
    this.setState({
      formdata: new_form_data,
      genderError: "",
      temp_gender_data: e,
    });
  };

  validate = () => {
    let mobileError = "";
    let genderError = "";
    let collegeError = "";

    if (!this.state.formdata.gender) {
      genderError = "Please select the gender.";
    }

    if (!this.state.formdata.college) {
      collegeError = "Please select your college";
    }

    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (
      re.test(this.state.formdata.mobile) === false ||
      this.state.formdata.mobile.length !== 10
    ) {
      mobileError = "Invalid Mobile Number";
    }

    if (mobileError || genderError || collegeError) {
      this.setState({ mobileError, genderError, collegeError });
      return false;
    }

    return true;
  };

  handleGoogleSignUp = (e) => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      //Making an axios call to googleSignUp API in backend(we send name, email, mobile, gmail userID, type, signuptype)
      axios
        .post(
          `${BASE_URL}api/user/addmobile`,
          {
            mobile: this.state.formdata.mobile,
            gender: this.state.formdata.gender,
            college: this.state.formdata.college,
          },
          {
            headers: {
              //Send entire google JWT Token in headers and authenticate via googleauth middleware in backend.
              "x-google-token": this.props.userCredentials,
            },
          }
        )
        .then((res) => {
          this.props.history(`/login`);
          toast.success("Account Created", defaultToastSetting);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  promiseOptions = async (inputValue) => {
    return await axios
      .get(`${BASE_URL}api/college?name=${inputValue}`)
      .then((response) => {
        const arrayMap = response.data.slice(0, 20).map((data) => {
          return { value: data.name, label: data.name, college_id: data._id };
        });
        return arrayMap;
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  debounce = (f, interval) => {
    let timer = null;

    return (...args) => {
      clearTimeout(timer);
      return new Promise((resolve) => {
        timer = setTimeout(() => resolve(f(...args)), interval);
      });
    };
  };

  render() {
    return (
      <div className=" flex flex-col items-center justify-center login-input-div md:px-12 px-12 my-5">
        <img src={KyronSideLetter} alt="LOGO" className="w-[220px] mb-4" />
        <img src={googleIcon} height="40px" width="40px" />
        <div className="font-['poppins'] text-neutral-400 text-lg mb-4">
          Enter Additional Details
        </div>
        <form onSubmit={this.handleGoogleSignUp} className="w-full">
          <div className="mb-2">
            <label className="font-['poppins'] text-neutral-400">
              Mobile Number
            </label>
            <input
              type="text"
              name="mobile"
              placeholder="Mobile"
              maxLength={10}
              className="login-form-passInput"
              value={this.state.formdata.mobile}
              onChange={this.handleChange}
            />
            <div className="text-red-500 text-sm font-['poppins']">
              {this.state.mobileError}
            </div>
          </div>

          <div className="mb-2">
            <label className="font-['poppins'] text-neutral-400">Gender</label>
            <Select
              className="login-form-selectInput"
              value={this.state.temp_gender_data}
              onChange={this.handleGenderChange}
              placeholder="Select your gender"
              options={[
                { value: "M", label: "Male" },
                { value: "F", label: "Female" },
                { value: "T", label: "Transgender" },
                { value: "NB", label: "Non Binary" },
                { value: "NC", label: "Non Confirming" },
                { value: "O", label: "Others" },
              ]}
            />
            <div className="text-red-500 text-sm font-['poppins']">
              {this.state.genderError}
            </div>
          </div>

          <div className="mb-8">
            <label className="font-['poppins'] text-neutral-400">College</label>
            <AsyncSelect
              menuPlacement="top"
              loadOptions={this.debounce(this.promiseOptions, 500)}
              value={this.state.temp_college_data}
              onChange={this.handleCollegeChange}
              className="login-form-selectInput"
              placeholder="Select your college"
              noOptionsMessage={({ inputValue }) =>
                !inputValue ? (
                  "Please enter keyword to search your college"
                ) : (
                  <button
                    onClick={() => this.props.setShowAddCollegeModal(true)}
                  >
                    No results found!{" "}
                    <span className="login-form-selectInput-button">
                      Click here
                    </span>{" "}
                    to add your college
                  </button>
                )
              }
            />

            <div className="text-red-500 text-sm font-['poppins']">
              {this.state.collegeError}
            </div>
          </div>

          <div className="flex justify-center">
            <button
              className="text-white w-[200px] transition bg-[#009999]  hover:bg-gray-800 hover:text-white font-medium rounded-full  
            text-md px-6 py-2 justify-center cursor-pointer font-['nunito']"
              type="submit"
            >
              {this.props.isSignupLoading ? (
                <ButtonSpinner />
              ) : (
                "Create Account"
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    isSignupLoading: auth.isSignupLoading,
    isSignedUp: auth.isSignedUp,
    // message: auth.message,
    // successMessage: auth.successmessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signupRequested: (data) => dispatch(signupRequested(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(googleSignUp));
