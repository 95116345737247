import React, { Component } from "react";
import { ReactComponent as Cross } from "../../assets/img/svg/cross.svg";
import deletec from "../../assets/img/svg/deletecolor.svg";
import { ReactComponent as EditIcon } from "../../assets/img/svg/edit.svg";
import Modal from "../../components/common/Modal/Modal";
import AddQuestion from "./AddQuestion";
import { connect } from "react-redux";
import {
  fetchAllQuestionsRequested,
  fetchAllTagsRequested,
  setQuestionStatus,
  updateQuestionFilter,
  updateQuestionbankModal,
  updateRecentQuestion,
} from "../../store/actions/questionbank";
import load from "../../assets/img/svg/load.svg";
import {
  updateQuestionFromQuestionBank,
  updateTestDeleteModalActive,
} from "../../store/actions/test";
import DeleteTest from "../../components/tests/createTest/DeleteTest";
import CreatableMulti from "../../components/common/ReactSelect/SelectAndCreatable";
import {
  updateInterviewQuestionRequested,
  updateInterviewQuestionSuccess,
} from "../../store/actions/interviewpanel";
import { getUserIdFromPeerId } from "../../components/common/utils";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../assets/js/toast_config";
import { updateInterviewQuestion } from "../../services/interviewpanel.service";
import UniversalButton from "../../components/common/StandardButton/UniversalButton";
import { withRouter } from "../../components/common/WithRouter";
import { BASE_URL } from "../../assets/js/config";
import authHeader from "../../services/auth-header";
import axios from "axios"

class QuestionBank extends Component {
  constructor() {
    super();

    this.state = {
      currentQuestion: {},
      type: "A",
      currentQuestionId: "",
      searchingQuestText:"",
      enterPressed:false,
      searchedQuestion:[],
      questionColors: {
        checkbox: "#45B8AC",
        description: "#F7CAC9",
        integer: " #92A8D1",
        record: "#D65076",
        file: "#DFCFBE",
        coding: "#98B4D4",
      },
    };
    this.handleEditQuestion = this.handleEditQuestion.bind(this);
    this.handleFilterTags = this.handleFilterTags.bind(this);
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    let filter = {
      ...this.props.filter,
      type: query.get("type") || "",
      difficulty: query.get("difficulty") || "",
      recent_interview_question:
        query.get("recent_interview_question") || false,
      recent_quiz_question: query.get("recent_quiz_question") || false,
      tags: query.get("tags") || "",
      page: query.get("page") || 0,
      pageSize: query.get("pageSize") || 5,
    };
    let recentQuestion = filter.recent_interview_question
      ? "r_i_q"
      : filter.recent_quiz_question
        ? "r_q_q"
        : "";
    this.props.updateRecentQuestion(recentQuestion);
    this.props.updateQuestionFilter(filter);
    this.props.fetchAllQuestionsRequested(filter);
  }

  handleEditQuestion = (index) => {
    const { questions } = this.props;
    this.props.updateQuestionbankModal({ value: true });
    this.setState((prev) => {
      return {
        ...prev,
        currentQuestion: { ...questions[index] },
        type: "E",
      };
    });
  };

  handleAddQuestion = () => {
    this.setState(
      (prev) => {
        return {
          ...prev,
          type: "A",
        };
      },
      () => this.props.updateQuestionbankModal({ value: true })
    );
  };

  deleteQuestion = (index) => {
    const { questions } = this.props;
    this.props.updateTestDeleteModalActive(true);
    this.setState((prev) => {
      return {
        ...prev,
        currentQuestionId: questions[index]._id,
      };
    });
    // this.props.deleteQuestionRequested({ id: questions[index]._id });
  };

  getShortQuestion(question) {
    let stripedQuestion = question.replace(/(<([^>]+)>)/gi, "");
    return question.length > 250
      ? stripedQuestion.slice(0, 200) + "..."
      : stripedQuestion;
  }

  trimHtml(question) {
    return question.replace(/(<([^>]+)>)/gi, "");
  }

  onChangePageSize(e) {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("pageSize", e.target.value);
    let filter = {
      ...this.props.filter,
      pageSize: e.target.value,
    };
    this.props.fetchAllQuestionsRequested(filter);
    this.props.updateQuestionFilter(filter);

    this.props.history(
      window.location.pathname + "?" + currentUrlParams.toString()
    );
  }

  previousPage() {
    const { page } = this.props.filter;
    let filter = {
      ...this.props.filter,
      page: page > 0 ? page - 1 : page,
    };
    this.props.updateQuestionFilter(filter);
    this.setPageToParams(filter);
  }

  setPageToParams(page) {
    this.props.fetchAllQuestionsRequested(page);
    let currentUrlParams = new URLSearchParams(window.location.search);
    // console.log("page", page.page);
    currentUrlParams.set("page", page.page);
    this.props.history(
      window.location.pathname + "?" + currentUrlParams.toString()
    );
  }

  handleFilterType(e) {
    const { name, value } = e.target;
    let currentUrlParams = new URLSearchParams(window.location.search);
    if (name === "recentQuestions") {
      if (value === "r_i_q") {
        if (currentUrlParams.has("recent_quiz_question"))
          currentUrlParams.set("recent_quiz_question", false);
        currentUrlParams.set("recent_interview_question", true);
      }
      if (value === "r_q_q") {
        if (currentUrlParams.has("recent_interview_question"))
          currentUrlParams.set("recent_interview_question", false);
        currentUrlParams.set("recent_quiz_question", true);
      }
      if (value === "") {
        if (currentUrlParams.has("recent_quiz_question"))
          currentUrlParams.delete("recent_quiz_question");
        if (currentUrlParams.has("recent_interview_question"))
          currentUrlParams.delete("recent_interview_question");
      }
      let recentQuestion = value;
      this.props.updateRecentQuestion(recentQuestion);
    } else currentUrlParams.set(name, value);

    // console.log(path, currentUrlParams.toString());

    let filter = {
      ...this.props.filter,
      page: 0,
      ...(name !== "recentQuestions" && { [name]: value }),
      ...(name === "recentQuestions" &&
        value === "r_q_q" && {
        recent_quiz_question: true,
        recent_interview_question: false,
      }),
      ...(name === "recentQuestions" &&
        value === "r_i_q" && {
        recent_interview_question: true,
        recent_quiz_question: false,
      }),
      ...(name === "recentQuestions" &&
        value === "" && {
        recent_interview_question: false,
        recent_quiz_question: false,
      }),
    };

    this.props.updateQuestionFilter(filter);
    this.props.fetchAllQuestionsRequested(filter);
    this.props.history(
      window.location.pathname + "?" + currentUrlParams.toString()
    );
  }

  handleFilterTags(data) {
    const tags_data = data.map((tag) => tag.tag_id);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("tags", tags_data);
    let filter = {
      ...this.props.filter,
      tags: [...tags_data],
    };
    this.props.fetchAllQuestionsRequested(filter);
    this.props.updateQuestionFilter(filter);
    this.props.history(
      window.location.pathname + "?" + currentUrlParams.toString()
    );
  }

  nextPage() {
    const { page } = this.props.filter;
    let filter = {
      ...this.props.filter,
      page: page + 1,
    };
    this.props.updateQuestionFilter(filter);
    this.setPageToParams(filter);
  }

  handleChangePage(val) {
    let filter = {
      ...this.props.filter,
      page: parseInt(val) + 1,
    };
    this.props.updateQuestionFilter(filter);
    this.setPageToParams(filter);
  }

  onClickQuestion(question) {
    const { isModal, questionIndex, sectionIndex, modalType } = this.props;
    if (isModal) {
      if (modalType === "T") {
        this.props.updateQuestionFromQuestionBank({
          question,
          sectionIndex,
          questionIndex,
        });
      } else if (modalType === "I") {
        const { components, currentSectionIndex } = this.props;
        let _components = [...components];
        _components[currentSectionIndex].components = [
          ...components[currentSectionIndex].components,
          { ...question },
        ];
        updateInterviewQuestion({
          data: {
            components: _components,
            isReleased: components[currentSectionIndex]?.isReleased,
          },
          id: this.props.params.id,
        })
          .then((res) => {
            if (res.success) {
              this.props.updateInterviewQuestionSuccess(res.data.components);
              if (this.props.interviewerPeerId) {
                this.props.socket.emit("send-selected-question", {
                  userId: getUserIdFromPeerId(this.props.interviewerPeerId),
                });
              }
            }
          })
          .catch((err) => {
            toast.error("Question not updated", defaultToastSetting);
          });
        this.props.updateQuestionbankModal({ value: false });
      }
    }
  }

  handleSearchQuestion(e) {
     this.setState((prevState) => {
      return {
        ...prevState,
        searchingQuestText:e.target.value,
      };
    });
  }

  renderQuestionMap(arr){
    console.log(arr)
    return  <div className={`mt-2 ${this.props.isModal &&
      `${this.props.modalType === "I" ? "h-[57vh]" : "h-[63vh]"
      } scrollbar pr-2 overflow-y-auto h-[45%] lg:h-[66%] xl:h-[73%]`
      }`}
  >
    {arr.map((question, index) => (
      <div
        key={`${index}_question`}
        className={`grid ${!this.props.isModal
            ? "grid-cols-[4fr_1fr_1fr_2fr_1fr]"
            : "grid-cols-[5fr_1fr_1fr_2fr]"
          } mt-1 p-1 px-3 border-b-2 ${this.props.isModal &&
          `${this.props.questions_Used.includes(question._id) &&
          `bg-[#EEFECF]`
          } hover:bg-gray-100`
          }`}
      >
        {/* {console.log("questions", question)} */}
        {/* <p className="font-['poppins'] min-w-[100px] text-md">
          {question?.type.toUpperCase()}
        </p> */}
        <div className="font-['poppins'] text-sm pb-2 flex justify-start items-satrt gap-x-4 font-normal text-[0.9rem]">
          <div
            className="w-[1rem] h-[1rem] rounded bg-[#fff] shadow-[inset_0px_1.1px_1.1px_1px_rgba(0,0,0,0.5)] cursor-pointer"
            onClick={() => this.onClickQuestion(question)}
          ></div>
          <div className="w-fit">
            <p className="font-['poppins'] text-sm pb-2">
              {question?.isOpen ? (
                <>
                  {this.trimHtml(question?.question)}
                  {question?.question.length > 250 && (
                    <span
                      className="text-blue-500 text-sm cursor-pointer"
                      onClick={() =>
                        this.props.setQuestionStatus({
                          index,
                          status: !question?.isOpen,
                        })
                      }
                    >
                      less
                    </span>
                  )}
                </>
              ) : (
                <>
                  {this.getShortQuestion(question?.question)}
                  <span
                    className="text-blue-500 text-sm cursor-pointer"
                    onClick={() =>
                      this.props.setQuestionStatus({
                        index,
                        status: !question?.isOpen,
                      })
                    }
                  >
                    more
                  </span>
                </>
              )}
            </p>
          </div>
        </div>
        <div className="flex justify-center font-normal text-[0.9rem]">
          {question?.type}
        </div>
        <div className="flex justify-center font-normal text-[0.9rem]">
          {question?.difficulty === "M"
            ? "Medium"
            : question?.difficulty === "E"
              ? "Easy"
              : question?.difficulty === "H"
                ? "Hard"
                : "-"}
        </div>
        <div className="flex flex-wrap justify-center gap-1 font-normal text-[0.9rem]">
          {question.tags &&
            question?.tags.map((tag) => (
              <div
                key={`${tag?._id}_tag`}
                className=" mr-1 h-fit  text-[#009696] border border-[#009696] font-['poppins'] px-2 rounded-full"
              >
                {tag?.label}
              </div>
            ))}
        </div>
        <div className="flex justify-end items-start font-normal text-[0.9rem]">
          {!this.props.isModal && (
            <div className="flex min-w-[70px] sm:gap-x-3 gap-x-1 items-center ml-auto">
              <EditIcon
                className="w-5 h-5 cursor-pointer"
                alt="svg"
                color="#a0db32"
                title="Edit Question"
                onClick={() => this.handleEditQuestion(index)}
              />
              <img
                alt="delete"
                src={deletec}
                title="Delete Question"
                className="ml-2 w-[1rem] sm:w-5 sm:h-5 hoverPointer"
                onClick={() => {
                  this.deleteQuestion(index);
                }}
              />
            </div>
          )}
        </div>
      </div>
    ))
    }
  </div>
  }

  showQuestion(){
    if(this.state.searchedQuestion.length)return this.renderQuestionMap(this.state.searchedQuestion)
    else if(this.state.searchingQuestText && this.state.enterPressed)return <p className="flex justify-center items-center h-[70vh] font-['poppins'] font-medium">
    No Questions Found
  </p>
    else if(this.props.questions?.length) return this.renderQuestionMap(this.props.questions)
    else return  <p className="flex justify-center items-center h-[70vh] font-['poppins'] font-medium">
    No Questions Found
  </p>
  }

  render() {
    const { questions, loading, tags, recentQuestions } = this.props;
    const { page, pageSize, type, difficulty, totalPages } = this.props.filter;
    console.log(
      "checkQuestion",
      this.props.questionIndex,
      this.props.sectionIndex
    );
    return (
      <>
        <DeleteTest
          testId={this.state.currentQuestionId}
          title="question"
        // handleToast={this.handleToastWarning}
        />
        <Modal
          onClose={() => this.props.updateQuestionbankModal({ value: false })}
          isModal={!this.props.isModal && this.props.modal}
          showCloseButton
          className={"md:w-[70%]"}
        >
          <AddQuestion
            currentQuestion={this.state.currentQuestion}
            type={this.state.type}
          />
        </Modal>
        <div
          className={`${!this.props.isModal ? "m-6" : "w-[100%] h-[70vh]"} `}
        >
          <div
            className={`${this.props.isModal && "mr-10"} flex  justify-between`}
          >
            <h1 className="font-['poppins'] font-medium text-[#37474F] text-xl">
              Question Bank
            </h1>
            {!this.props.isModal ? (
              <UniversalButton
                onClickFunction={() => this.handleAddQuestion()}
                title="Create New Question"
                classNames="w-auto"
              />
            ) : null}
          </div>
          <div className="w-[100%] xl:justify-between flex gap-3 flex-col xl:flex-row py-2 rounded-lg mt-4 md:mt-2">
            <div className="flex w-full xl:w-fit justify-between flex-col lg:flex-row gap-3">
              <div className="w-full flex gap-3 mb-1">
                <select
                  id="small"
                  name="type"
                  onChange={(e) => this.handleFilterType(e)}
                  value={type}
                  className="block px-3 shadow-md font-['poppins'] font-normal h-[2.1rem] text-sm text-gray-900 bg-gray-50 rounded-lg flex-1 xl:w-fit"
                >
                  <option value="">Type</option>
                  <option value="description">Descriptive</option>
                  <option value="integer">Integer</option>
                  <option value="checkbox">Multiple Choice</option>
                  <option value="record">Record</option>
                  <option value="file">File Upload</option>
                  <option value="coding">Coding</option>
                  <option value="sql">Sql</option>
                </select>
                <select
                  id="small"
                  name="difficulty"
                  value={difficulty}
                  onChange={(e) => this.handleFilterType(e)}
                  className="block px-3 flex-1 xl:w-fit shadow-md font-['poppins'] font-normal h-[2.1rem] text-sm text-gray-900 bg-gray-50 rounded-lg"
                >
                  <option value="">Difficulty</option>
                  <option value="E">Easy</option>
                  <option value="M">Medium</option>
                  <option value="H">Hard</option>
                </select>
              </div>
              <div className="w-full flex flex-col sm:flex-row gap-3 mb-1">
                <div className="sm:flex-1">
                  <CreatableMulti
                    value={tags}
                    handleTagChange={this.handleFilterTags}
                  />
                </div>

                <select
                  id="small"
                  name="recentQuestions"
                  value={recentQuestions}
                  onChange={(e) => this.handleFilterType(e)}
                  className="block px-3 sm:flex-1 xl:w-fit shadow-md font-['poppins'] font-normal h-[2.1rem] text-sm text-gray-900 bg-gray-50 rounded-lg"
                >
                  <option value="">Select Filter</option>
                  <option value="r_i_q">Recent interview question</option>
                  <option value="r_q_q">Recent quiz question</option>
                </select>
              </div>
            </div>
            <div className="w-full relative ml-auto">
              <input
                type="text"
                className="w-full pl-2 py-1 pr-10 rounded-md outline-none border-2"
                name="user"
                id="user"
                placeholder="Search Question"
              value={this.state.searchingQuestText}
              onChange={(e) => this.handleSearchQuestion(e)}
              onKeyDown={(e) =>{{
                if (e && e.key === "Enter")
                  axios.put(
                  // `http://localhost:5678/api/question/advance-search`,
                  `${BASE_URL}api/question/advance-search`,
                  { searchText :e.target.value },
                  {
                    headers: { ...authHeader() },
                  }
                ).then((res) => {
                  console.log(res)
                  this.setState(prev=>{
                    return {
                      ...prev,
                      searchedQuestion:res.data
                    }
                  })
                }).catch((err) => {
                  console.log(err);
                }).finally(()=>{
                  if(this.state.searchingQuestText)this.setState(prev=>{
                    return {
                      ...prev,
                      enterPressed:true
                    }
                  })
                });
              
              }
              }}
              />

              <Cross
                className="absolute cursor-pointer right-2 top-[8px]"
              onClick={() => 
                this.setState(prev=>{
                  return {
                    ...prev,
                    enterPressed:false,
                    searchingQuestText:"",
                    searchedQuestion:[]
                  }
                })
              }
              />
            </div>
          </div>
          {loading ? (
            <div
              className="flex justify-center items-end "
              style={{ minHeight: "200px" }}
            >
              <img
                alt="loading"
                src={load}
                className="spinner mr-2 w-6 h-6 text-primary"
              />
            </div>
          ) : (
            <>
              
              {
                // this.state.searchedQuestion.length ? <></> : this.state.searchingQuestText ? 
                this.showQuestion()
              }
            </>
          )}
        </div>
        <div className="flex  flex-col gap-2 sm:flex-row pt-2 px-6 sm:px-10 font-['nunito'] justify-between">
          <div className="flex-1">
            <span>
              Page
              <input
                type="number"
                value={page + 1}
                disabled
                // onChange={(e) => {
                //   this.handleChangePage(e.target.value);
                // }}
                // className="border-2 border-gray-400 px-2 rounded"
                style={{ width: "30px" }}
              />
              Of {totalPages}
            </span>
          </div>

          <div className="flex flex-1 pb-8 justify-between ">
            <div className="flex gap-3 justify-between sm:justify-start">
              {/* <button
              className="text-[#001437] border  bg-[#B8FB3C]  hover:text-white
             hover:bg-[#001437] font-semibold uppercase text-sm
            px-4 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150
            disabled:opacity-25 disabled:cursor-not-allowed disabled:bg-gray-500"
              // onClick={() => gotoPage(0)}
              // disabled={!canPreviousPage}
            >
              {"<<"}
            </button>{" "} */}
              <button
                className="text-[#001437] border  bg-[#B8FB3C] hover:text-white
             hover:bg-[#001437] font-semibold uppercase text-sm
            px-4 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150
            disabled:opacity-25 disabled:cursor-not-allowed disabled:bg-gray-500"
                onClick={() => this.previousPage()}
                disabled={page === 0}
              >
                Prev
              </button>
              <button
                className="text-[#001437] border  bg-[#B8FB3C] hover:text-white 
             hover:bg-[#001437] font-semibold uppercase text-sm
            px-4 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150
            disabled:opacity-25 disabled:cursor-not-allowed disabled:bg-gray-500"
                onClick={() => this.nextPage()}
                disabled={page + 1 >= totalPages}
              >
                Next
              </button>
              {/* <button
              className="text-[#001437] border  bg-[#B8FB3C] hover:text-white 
             hover:bg-[#001437] font-semibold uppercase text-sm
            px-4 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150
            disabled:opacity-25 disabled:cursor-not-allowed disabled:bg-gray-500"
              // onClick={() => gotoPage(pageCount - 1)}
              // disabled={!canNextPage}
            >
              {">>"}
            </button>{" "} */}
            </div>
            <select
              value={pageSize}
              className="border-2 border-gray-400 px-2 rounded"
              onChange={(e) => {
                this.onChangePageSize(e);
              }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ questionbank, test, interviewpanelReducer }) => {
  return {
    modal: questionbank.questionModal,
    recentQuestions: questionbank.recentQuestions,
    filter: questionbank.filter,
    questions: questionbank.questions,
    loading: questionbank.loader,
    questions_Used: test.questions_used,
    questionTags: questionbank.questionTags,
    interviewerPeerId: interviewpanelReducer.interviewerPeerId,
    currentSectionIndex: interviewpanelReducer.currentSectionIndex,
    components: interviewpanelReducer.interviewDetails?.components,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateQuestionbankModal: (data) => dispatch(updateQuestionbankModal(data)),
    fetchAllQuestionsRequested: (data) =>
      dispatch(fetchAllQuestionsRequested(data)),
    updateTestDeleteModalActive: (data) =>
      dispatch(updateTestDeleteModalActive(data)),
    fetchAllTagsRequested: () => dispatch(fetchAllTagsRequested()),
    updateQuestionFromQuestionBank: (data) =>
      dispatch(updateQuestionFromQuestionBank(data)),
    updateInterviewQuestionRequested: (data) =>
      dispatch(updateInterviewQuestionRequested(data)),
    updateInterviewQuestionSuccess: (data) =>
      dispatch(updateInterviewQuestionSuccess(data)),
    setQuestionStatus: (data) => dispatch(setQuestionStatus(data)),
    updateQuestionFilter: (data) => dispatch(updateQuestionFilter(data)),
    updateRecentQuestion: (data) => dispatch(updateRecentQuestion(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(QuestionBank));

