export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILED = "UPLOAD_FILE_FAILED";

export const ASK_QUESTION_REQUEST = "ASK_QUESTION_REQUEST";
export const ASK_QUESTION_SUCCESS = "ASK_QUESTION_SUCCESS";
export const ASK_QUESTION_FAILED = "ASK_QUESTION_FAILED";

export const GET_DEPARTMENT_REQUEST = "GET_DEPARTMENT_REQUEST";
export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS";
export const GET_DEPARTMENT_FAILED = "GET_DEPARTMENT_FAILED";

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILED = "GET_PROFILE_FAILED";

export const PARSE_RESUME_REQUEST = "PARSE_RESUME_REQUEST";
export const PARSE_RESUME_SUCCESS = "PARSE_RESUME_SUCCESS";
export const PARSE_RESUME_FAILED = "PARSE_RESUME_FAILED";

export const REPARSE_RESUME_REQUEST = "REPARSE_RESUME_REQUEST";
export const REPARSE_RESUME_SUCCESS = "REPARSE_RESUME_SUCCESS";
export const REPARSE_RESUME_FAILED = "REPARSE_RESUME_FAILED";

export const GET_RESUME_DETAILS_REQUEST = "GET_RESUME_DETAILS_REQUEST";
export const GET_RESUME_DETAILS_SUCCESS = "GET_RESUME_DETAILS_SUCCESS";
export const GET_RESUME_DETAILS_FAILED = "GET_RESUME_DETAILS_FAILED";

export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILED = "SEND_EMAIL_FAILED";

export const EXPORT_CSV_REQUEST = "EXPORT_CSV_REQUEST";
export const EXPORT_CSV_SUCCESS = "EXPORT_CSV_SUCCESS";
export const EXPORT_CSV_FAILED = "EXPORT_CSV_FAILED";

export const ASSIGN_TEST_REQUEST = "ASSIGN_TEST_REQUEST";
export const ASSIGN_TEST_SUCCESS = "ASSIGN_TEST_SUCCESS";
export const ASSIGN_TEST_FAILED = "ASSIGN_TEST_FAILED";

export const ASSIGN_INTERVIEW_REQUEST = "ASSIGN_INTERVIEW_REQUEST";
export const ASSIGN_INTERVIEW_SUCCESS = "ASSIGN_INTERVIEW_SUCCESS";
export const ASSIGN_INTERVIEW_FAILED = "ASSIGN_INTERVIEW_FAILED";

export const UPDATE_STATUS_OF_RESUME = "UPDATE_STATUS_OF_RESUME";

export const GET_CHAT_REQUEST = "GET_CHAT_REQUEST";
export const GET_CHAT_SUCCESS = "GET_CHAT_SUCCESS";
export const GET_CHAT_FAILED = "GET_CHAT_FAILED";

export const CREATE_CHAT_REQUEST = "CREATE_CHAT_REQUEST";
export const CREATE_CHAT_SUCCESS = "CREATE_CHAT_SUCCESS";
export const CREATE_CHAT_FAILED = "CREATE_CHAT_FAILED";

export const UPDATE_CHAT_REQUEST = "UPDATE_CHAT_REQUEST";
export const UPDATE_CHAT_SUCCESS = "UPDATE_CHAT_SUCCESS";
export const UPDATE_CHAT_FAILED = "UPDATE_CHAT_FAILED";

export const ASKING_QUESTION_TYPE = "ASKING_QUESTION_TYPE";

export const SELECTED_MAIL = "SELECTED_MAIL";
