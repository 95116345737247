import React, { Component } from "react";
import up from "../../../assets/img/svg/up.svg";
import down from "../../../assets/img/svg/down.svg";
import copy from "../../../assets/img/svg/copy.svg";
import delete1 from "../../../assets/img/svg/delete.svg";
import mid from "../../../assets/img/svg/mid.svg";
import imports from "../../../assets/img/svg/import.svg";

export default class NewDragComponent extends Component {
  render() {
    const {
      content,
      deleteAction,
      duplicateAction,
      sectionIndex,
      questionIndex,
      type,
      cardState,
    } = this.props;

    return (
      <div
        className={`rounded border border-gray  py-2 bg-white my-2`}
      // style={{ border: "1px solid rgb(0, 0, 0, 0.1)" }}
      >
        <div
          className={`flex flex-row md:ml-0 ml-3  ${!cardState ? "items-center" : ""
            }  justify-center`}
        >
          <div className="flex flex-col  border border-[#001437] rounded-2xl mx-2">
            <img
              src={up}
              title="Move Up"
              onClick={() => ((type === 's' && sectionIndex >= 1) || (type === 'q' && questionIndex >= 1)) && this.props.moveQuestionUp({ sectionIndex, questionIndex })}
              className={`w-[1.8rem] ${((type === 's' && sectionIndex >= 1) || (type === 'q' && questionIndex >= 1)) ? " cursor-pointer hoverPointer hover:bg-[#EEFECF]" : ""} rounded-[0.7rem]  transition duration-150 ease-in-out p-1 py-1`}
              alt="svg"
            />
            <img
              src={down}
              title="Move down"
              className={`w-[1.8rem] rounded-[0.7rem] ${((type === 's' && sectionIndex + 1 < this.props.test?.components?.length) || (type === 'q' && questionIndex + 1 < this.props.test?.components[sectionIndex].components?.length)) ? "hoverPointer hover:bg-[#EEFECF] cursor-pointer" : ""}  transition duration-150 ease-in-out  p-1 py-1`}
              alt="svg"
              onClick={() => ((type === 's' && sectionIndex + 1 < this.props.test?.components?.length) || (type === 'q' && questionIndex + 1 < this.props.test?.components[sectionIndex].components?.length)) && this.props.moveQuestionDown({ sectionIndex, questionIndex })}
            // style={{ transform: "translateY(-50%)" }}
            />
          </div>
          <p className="h-[10%] md:block hidden rounded-r-lg font-medium font-['nunito'] p-3 bg-[#A0DB34]">
            {this.props.type === "s"
              ? "S" + (this.props.sectionIndex + 1)
              : "Q" + (this.props.questionIndex + 1)}
          </p>
          {/* <div
              className={`flex flex-col  px-2 py-1 rounded-full`}
              title="Drag Order"
            >
              <img
                src={up}
                title="View Section"
                className="w-6 d-none d-md-block"
                alt="svg"
              />
              <img
                src={mid}
                title="View Section"
                className="w-6 d-none d-md-block"
                alt="svg"
              // style={{ transform: "translateY(-50%)" }}
              />
              <img
                src={down}
                title="View Section"
                className="w-6 d-none d-md-block"
                alt="svg"
              // style={{ transform: "translateY(-50%)" }}
              />
            </div> */}
          <div className="w-full lg:mx-5 sm:mx-2">{content}</div>
          <div
            className={`flex pr-5 md:pr-2 ${cardState
              ? "flex-col-reverse justify-end"
              : "flex-row justify-start items-center"
              } `}
          >

            <img
              src={delete1}
              title="Delete"
              className="w-[2.6rem] rounded-[0.7rem] hoverPointer transition duration-150 ease-in-out hover:bg-[#EEFECF] cursor-pointer p-2 py-2.5"
              alt="svg"
              onClick={deleteAction}
            />
            <img
              src={copy}
              title="Copy"
              className="w-[2.5rem] rounded-[0.7rem] hoverPointer transition duration-150 ease-in-out hover:bg-[#EEFECF] cursor-pointer p-2 py-2.5"
              alt="svg"
              onClick={duplicateAction}
            />
            {sectionIndex !== undefined && questionIndex !== undefined && (
              <>
                <img
                  src={imports}
                  title="Import"
                  className="w-[2.5rem] rounded-[0.7rem] hoverPointer transition duration-150 ease-in-out hover:bg-[#EEFECF] cursor-pointer p-2 py-2.5"
                  alt="svg"
                  onClick={() =>
                    this.props.openQuestionBankModal({
                      sectionIndex,
                      questionIndex,
                    })
                  }
                />
              </>
            )}
            {this.props.type === "s" && (
              <img
                src={down}
                title="Toggle"
                className={`w-[2.5rem] rounded-[0.7rem] hoverPointer hover:bg-[#EEFECF] cursor-pointer ${!cardState ? "" : "rotate-180"
                  }  transition duration-150 ease-in-out  p-2 py-2.5`}
                alt="svg"
                onClick={() =>
                  this.props.handleSectionView(this.props.sectionIndex)
                }
              />
            )}
          </div>

        </div>
      </div>
    );
  }
}
