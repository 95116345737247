import React, { Component } from "react";
import { ReactComponent as Cross } from "../../assets/img/svg/cross.svg";
import { ReactComponent as Edit } from "../../assets/img/svg/edit.svg";
import { ReactComponent as Delete } from "../../assets/img/svg/deletecolor.svg";
import { ReactComponent as Update } from "../../assets/img/svg/updateRank.svg";
import EmailTemplateModal from "./CreateEmailTemplate";
import Modal from "../../components/common/Modal/Modal";
import { connect } from "react-redux";
import load from "../../assets/img/svg/load.svg";
import { updateTestDeleteModalActive } from "../../store/actions/test";
import DeleteTest from "../../components/tests/createTest/DeleteTest";
import {
  fetchAllEmailTemplatesRequested,
  updateEmailTemplateModal,
} from "../../store/actions/emailtemplates";
import Papa from "papaparse";
import { sendEmailTemplateService } from "../../services/emailtemplate.service";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../assets/js/toast_config";
import UniversalButton from "../../components/common/StandardButton/UniversalButton";
import { withRouter } from "../../components/common/WithRouter";

class EmailTemplate extends Component {
  constructor() {
    super();
    this.state = {
      currentTemplate: {},
      type: "A",
      currentTemplateId: "",
      emailsData: [],
      emailSubject: "",
      emailSendModal: false,
    };
  }

  componentDidMount() {
    this.props.fetchAllEmailTemplatesRequested();
  }

  handleEditTemplate = (index) => {
    const { emailtemplates } = this.props;
    this.props.updateEmailTemplateModal({
      key: "emailtemplateModal",
      value: true,
    });
    this.setState((prev) => {
      return {
        ...prev,
        currentTemplate: { ...emailtemplates[index] },
        type: "E",
      };
    });
  };

  handleAddQuestion = () => {
    this.setState(
      (prev) => {
        return {
          ...prev,
          type: "A",
        };
      },
      () =>
        this.props.updateEmailTemplateModal({
          key: "emailtemplateModal",
          value: true,
        })
    );
  };

  handleSendEmailTemplate = (id) => {
    // console.log("checkid", id);
    this.setState((prev) => {
      return {
        ...prev,
        currentTemplateId: id,
        emailSendModal: true,
      };
    });
  };

  importCSV = (csvfile) => {
    Papa.parse(csvfile, {
      complete: this.updateData,
      header: true,
    });
  };

  handleChange = (event) => {
    this.importCSV(event.target.files[0]);
  };

  updateData = (result) => {
    const data = result.data;
    // Validating data
    data.map((object) =>
      Object.keys(object).forEach((key) => {
        const value = object[key].replace(/\s+/g, "");
        delete object[key];
        if (value !== "") {
          object[key.toLowerCase().replace(/\s+/g, "")] = value;
        }
      })
    );
    const filteredData = data.filter(
      (item) =>
        item.hasOwnProperty("email") &&
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(item["email"])
    );
    // console.log("result", filteredData);
    // Setting the data
    this.setState((prev) => {
      return {
        ...prev,
        emailsData: [...filteredData],
      };
    });
  };

  deleteQuestion = (id) => {
    this.props.updateTestDeleteModalActive(true);
    this.setState((prev) => {
      return {
        ...prev,
        currentTemplateId: id,
      };
    });
    // this.props.deleteQuestionRequested({ id: questions[index]._id });
  };

  handleSendEmail = () => {
    const { emailsData, currentTemplateId, emailSubject } = this.state;
    sendEmailTemplateService({
      data: emailsData,
      subject: emailSubject,
      id: currentTemplateId,
    })
      .then((res) => {
        toast.success("Emails sent successfully", defaultToastSetting);
      })
      .catch((e) => toast.warning(e.msg, defaultToastSetting));
  };

  render() {
    const { emailtemplates, loading } = this.props;
    return (
      <>
        <DeleteTest
          testId={this.state.currentTemplateId}
          title="email-template"
        />
        <Modal
          onClose={() =>
            this.props.updateEmailTemplateModal({
              key: "emailtemplateModal",
              value: false,
            })
          }
          isModal={this.props.modal}
          showCloseButton
          className={"md:w-[40%]"}
        >
          <EmailTemplateModal
            currentTemplate={this.state.currentTemplate}
            type={this.state.type}
          />
        </Modal>
        <Modal
          onClose={() =>
            this.setState((prev) => {
              return {
                ...prev,
                emailSendModal: false,
              };
            })
          }
          isModal={this.state.emailSendModal}
          showCloseButton
          className={"md:w-[40%]"}
        >
          <h1 className="font-[poppins'] text-lg font-bold">
            Upload emails csv
          </h1>
          <div className="h-[17vh] justify-center font-['poppins']">
            <div className="my-3">
              <div className="relative">
                <input
                  className="border-2 border-gray-300 block px-2.5 pb-2 pt-3 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 focus:border-[#009696] peer"
                  id="Name"
                  type="text"
                  title="title"
                  value={this.state.emailSubject}
                  onChange={(e) =>
                    this.setState((prev) => {
                      return {
                        ...prev.state,
                        emailSubject: e.target.value,
                      };
                    })
                  }
                  placeholder=" "
                />
                <label
                  htmlFor="otp"
                  className="font-['poppins'] absolute text-sm text-[#2D333A] duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[#009696] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2"
                >
                  Subject
                </label>
              </div>
            </div>
            <input
              className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
              type="file"
              ref={(input) => {
                this.filesInput = input;
              }}
              name="file"
              placeholder={null}
              onChange={this.handleChange}
            />
            <div className="py-2 text-sm text-red-800">
              {this.state.emailsData.length > 0 ? (
                <p>Emails attached : {this.state.emailsData.length} </p>
              ) : (
                <p>No email attached</p>
              )}
            </div>
          </div>
          <div className="w-full flex items-center justify-center">

          <UniversalButton onClickFunction={() => this.handleSendEmail()} title="Send Emails"/>
          </div>
        </Modal>
        <div className="m-6">
          <div className="flex justify-between">
            <h1 className="font-['poppins'] font-medium text-[#37474F] text-xl">
              Email Templates
            </h1>
            {/* <button
              className="text-[#009696] border border-[#009696] font-['nunito'] hover:text-white 
             hover:bg-[#001437] font-semibold uppercase text-sm
            px-4 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150
            disabled:opacity-25 disabled:cursor-not-allowed disabled:bg-gray-500"
              onClick={}
            >
              
            </button> */}
            <UniversalButton
              classNames="w-auto py-3"
              title="Create Email Template"
              onClickFunction={() => this.handleAddQuestion()}
            />
          </div>
          {loading ? (
            <div
              className="flex justify-center items-end "
              style={{ minHeight: "200px" }}
            >
              <img
                alt="loading"
                src={load}
                className="spinner mr-2 w-6 h-6 text-primary"
              />
            </div>
          ) : (
            <>
              {emailtemplates?.length !== 0 ? (
                <div className="flex w-full flex-wrap gap-x-3 ">
                  {emailtemplates?.map((template, index) => (
                    <div
                      key={`${index}_email_template`}
                      className={`w-[19%] mt-3 p-3 items-start rounded-2xl  shadow-md  gap-x-3`}
                    >
                      <p className="font-['poppins'] text-sm">
                        {template?.title}
                      </p>
                      <p className="font-['nunito'] text-xs pb-2">
                        {template?.remarks}
                      </p>
                      <div className="flex justify-end  sm:gap-x-3 gap-x-1">
                        <Update
                          title="Upload csv"
                          className="ml-2 sm:w-5 sm:h-5 w-4 h-4 hoverPointer"
                          color="#a0db32"
                          onClick={() =>
                            this.handleSendEmailTemplate(template._id)
                          }
                        />
                        <Edit
                          title="Edit Template"
                          className="ml-2 sm:w-5 sm:h-5 w-4 h-4 hoverPointer"
                          onClick={() => this.handleEditTemplate(index)}
                          color="#a0db32"
                        />
                        <Delete
                          title="Delete Template"
                          className="ml-2 sm:w-5 sm:h-5 w-4 h-4 hoverPointer"
                          color="#a0db32"
                          onClick={() => {
                            this.deleteQuestion(template._id);
                          }}
                        />
                        {/* <img
                          alt="top-arrow"
                          src={update}
                          title="Upload csv"
                          className="ml-2 sm:w-5 sm:h-5 w-4 h-4 hoverPointer"
                        />
                        <img
                          alt="top-arrow"
                          src={proctor}
                          title="Edit Template"
                          className="ml-2 sm:w-5 sm:h-5 w-4 h-4 hoverPointer"
                        />
                        <img
                          alt="delete"
                          src={deletec}
                          title="Delete Template"
                          className="ml-2 sm:w-5 sm:h-5 w-4 h-4 hoverPointer"
                        /> */}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="flex justify-center items-center h-[70vh] font-['poppins'] font-medium">
                  No email templates Found
                </p>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ emailtemplates }) => {
  return {
    modal: emailtemplates.emailtemplateModal,
    emailmodal: emailtemplates.emailSendModal,
    emailtemplates: emailtemplates.emailtemplates,
    loading: emailtemplates.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateEmailTemplateModal: (data) =>
      dispatch(updateEmailTemplateModal(data)),
    fetchAllEmailTemplatesRequested: (data) =>
      dispatch(fetchAllEmailTemplatesRequested(data)),

    updateTestDeleteModalActive: (data) =>
      dispatch(updateTestDeleteModalActive(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmailTemplate));
