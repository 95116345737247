import React from "react";
import load from "../../../assets/img/svg/load.svg";

export default function UniversalButton({
  onClickFunction,
  title,
  classNames = "",
  disabled = false,
  loading = false,
  ...rest
}) {
  return (
    <button
      onClick={onClickFunction}
      className={`text-[#001437] w-[200px] bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32]  rounded-lg 
              md:text-[16px] font-bold md:px-6 text-sm  text-md px-6 py-2 text-center font-['nunito'] cursor-pointer ${classNames} ${
        loading ? "hover:bg-[#a0db32]" : ""
      } ${
        disabled
          ? "bg-[#fff] hover:bg-[#fff] hover:text-[#001437] cursor-not-allowed"
          : ""
      }`}
      disabled={disabled}
      {...rest}
    >
      {loading ? (
        <img alt="loading" src={load} className="mx-auto spinner w-6 h-6" />
      ) : (
        title
      )}
    </button>
  );
}
