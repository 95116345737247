import { initialTestQuestionData } from "../../components/tests/utils";
import {
  ADD_QUESTION_SUCCESS,
  FETCH_ALL_QUESTIONS_FAILURE,
  FETCH_ALL_QUESTIONS_REQUESTED,
  FETCH_ALL_QUESTIONS_SUCCESS,
  FETCH_ALL_TAGS_SUCCESS,
  SET_QUESTION_VIEW_STATUS,
  UPDATE_QUESTION_FILTER,
  UPDATE_QUESTION_MODAL,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_WITH_KEY,
  UPDATE_RECENT_QUESTION,
} from "../actionTypes";

const initialState = {
  questionModal: false,
  questionData: initialTestQuestionData(),
  questionTags: [],
  questions: null,
  loader: false,
  recentQuestions: "",
  filter: {
    pageSize: 5,
    page: 0,
    type: "",
    difficulty: "",
    tags: "",
    totalPages: 0,
    recent_interview_question: false,
    recent_quiz_question: false,
  },
};

const questionbank = function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_QUESTION_MODAL:
      return { ...state, questionModal: payload.value };

    case UPDATE_QUESTION_FILTER:
      return {
        ...state,
        filter: { ...payload },
      };
    case UPDATE_RECENT_QUESTION:
      return {
        ...state,
        recentQuestions: payload,
      };

    case FETCH_ALL_QUESTIONS_SUCCESS:
      // console.log("reducer", payload);
      const new_questions = payload.questions.map((question) => {
        return {
          ...question,
          isOpen: question?.question?.length > 250 ? false : true,
        };
      });

      return {
        ...state,
        questions: [...new_questions],
        loader: false,
        filter: {
          ...state.filter,
          page: payload.currentPage,
          totalPages: payload.totalPages,
        },
      };

    case FETCH_ALL_QUESTIONS_FAILURE:
      return { ...state, loader: false };

    case FETCH_ALL_QUESTIONS_REQUESTED:
      return { ...state, loader: true };

    case FETCH_ALL_TAGS_SUCCESS:
      payload.map((el) => {
        el.value = el._id;
      });
      return { ...state, questionTags: [...payload] };

    case UPDATE_QUESTION_WITH_KEY:
      return {
        ...state,
        questionData: {
          ...state.questionData,
          [payload.key]: payload.value,
        },
      };

    case ADD_QUESTION_SUCCESS:
      console.log("reducer", state.questions, payload);
      return {
        ...state,
        questionModal: false,
        ...(state.questions === null
          ? { questions: [...payload] }
          : { questions: [...state.questions, ...payload] }),
      };

    case UPDATE_QUESTION_SUCCESS:
      const { questions } = state;
      const updatedQuestionId = questions.findIndex(
        (question) => question._id === payload._id
      );
      questions[updatedQuestionId] = payload;
      return {
        ...state,
        questionModal: false,
        questions: [...questions],
      };

    case SET_QUESTION_VIEW_STATUS:
      const question = [...state.questions];
      // console.log("reducer", question);
      question[payload.index].isOpen = payload.status;
      return { ...state, questions: [...question] };

    default:
      return state;
  }
};

export default questionbank;
