import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ReactComponent as NotificationBell } from "../../../assets/img/svg/notificationBell.svg";
import { BASE_URL } from "../../../assets/js/config";
import authHeader from "../../../services/auth-header";
import { setNewNotification } from "../../../store/actions/chat";
import { generatePeerId } from "../utils";
import { useParams } from "react-router-dom";

const Notifications = ({ handleChat }) => {
  const dispatch = useDispatch();
  const { id: testId } = useParams();
  const notificationRef = useRef();
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isNotificationsLoading, setIsNotificationsLoading] = useState(false);
  const { unreadsMapping } = useSelector((state) => state.chat);

  const clickOutside = (e) => {
    if (notificationRef.current.contains(e.target)) return;
    setIsNotificationsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);
    return () => document.removeEventListener("mousedown", clickOutside);
  }, [isNotificationsOpen]);

  const notifications = [
    {
      name: "Mayank Virmani",
      unreadCount: 3,
    },
    {
      name: "Ajay Pediredla",
      unreadCount: 4,
    },
    {
      name: "Kushal Poddar",
      unreadCount: 0,
    },
    {
      name: "Kushal Poddar",
      unreadCount: 0,
    },
    {
      name: "Kushal Poddar",
      unreadCount: 0,
    },
    {
      name: "Kushal Poddar",
      unreadCount: 0,
    },
    {
      name: "Kushal Poddar",
      unreadCount: 0,
    },
    {
      name: "Kushal Poddar",
      unreadCount: 0,
    },
  ];

  const handleNotificationClick = () => {
    if (!testId) return;
    setIsNotificationsOpen((prev) => !prev);
    setIsNotificationsLoading(true);
    axios
      .get(BASE_URL + `api/quizchat/quiz/${testId}/unreadcount`, {
        headers: { ...authHeader() },
      })
      .then((res) => {
        dispatch(setNewNotification(res.data));
      })
      .catch((err) => console.log("error in fetching unread msgs: ", err))
      .finally(() => setIsNotificationsLoading(false));
  };
  return (
    <div
      className="flex justify-center items-center relative "
      ref={notificationRef}
    >
      <NotificationBell
        onClick={() => handleNotificationClick()}
        className="cursor-pointer mx-1 hover:bg-gray-200 p-[7px] rounded-full"
        width={"35px"}
        height={"35px"}
      />
      {isNotificationsOpen ? (
        <div className="absolute z-40 bg-white rounded-lg top-[30px] right-[0] w-[300px] h-[350px] shadow-lg border overflow-y-scroll">
          {isNotificationsLoading ? (
            <div className="flex justify-center items-center h-full w-full">
              {" "}
              Loading <div className="dotsLoading w-[25px]"></div>{" "}
            </div>
          ) : unreadsMapping.length ? (
            unreadsMapping.map((notif) => {
              // alert(JSON.stringify(notif));
              console.log(testId, notif._id);
              return (
                <div
                  id={notif._id}
                  className={`border-b border-gray-300 flex flex-col justify-center items-start px-4 py-2  ${notif.count
                    ? "hover:bg-[#e3f7be] cursor-pointer"
                    : "opacity-50"
                    } `}
                  onClick={() => {
                    if (notif.count === 0) return;
                    handleChat(
                      generatePeerId("student", { testId, userId: notif._id })
                    );
                    setIsNotificationsOpen((prev) => !prev);
                  }}
                >
                  <strong>{notif.name}</strong>
                  <div>{notif.count} unread Messages</div>
                </div>
              );
            })
          ) : (
            <div className="flex justify-center items-center h-full opacity-50">
              No new notifications
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Notifications;
