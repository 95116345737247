export const GET_ALL_SUBSCRIPTION = "GET_ALL_SUBSCRIPTION";
export const GET_ALL_SUBSCRIPTION_SUCCESS = "GET_ALL_SUBSCRIPTION_SUCCESS";
export const GET_ALL_SUBSCRIPTION_FAILURE = "GET_ALL_SUBSCRIPTION_FAILURE";

export const GET_SINGLE_SUBSCRIPTION = "GET_SINGLE_SUBSCRIPTION";
export const GET_SINGLE_SUBSCRIPTION_SUCCESS = "GET_SINGLE_SUBSCRIPTION_SUCCESS";
export const GET_SINGLE_SUBSCRIPTION_FAILURE = "GET_SINGLE_SUBSCRIPTION_FAILURE";

export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION_SUCCESS";
export const DELETE_SUBSCRIPTION_FAILURE = "DELETE_SUBSCRIPTION_FAILURE";

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_FAILURE = "UPDATE_SUBSCRIPTION_FAILURE";
