import React, { Component } from "react";
import TestService from "../../../services/test.service";

// Store
import { connect } from "react-redux";
import { updateTestDeleteModalActive } from "../../../store/actions/test";
import UniversalButton from "../../common/StandardButton/UniversalButton";
import { withRouter } from "../../common/WithRouter";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../../assets/js/toast_config";

class createTestFooter extends Component {
  constructor() {
    super();
    this.saveTest = this.saveTest.bind(this);
  }

  checkAnswers(isAnswered) {
    for (var i = 0; i < isAnswered.length; i++) {
      if (isAnswered[i].includes(false)) {
        return false;
      }
    }
    return true;
  }

  checkingResponses(question, sindex, qindex) {
    if (question.type === "checkbox") {
      if (question.ans_mcq.length !== question.options.length) {
        toast.warning(
          `Please verify the correct answer for the following multiple-choice question in Section ${sindex}, Question ${qindex}.`
        );
        return false;
      } else if (!question?.ans_mcq.toString().includes("true")) {
        toast.warning(
          `Please answer the following multiple-choice question in Section ${sindex}, Question ${qindex}.`
        );
        return false;
      }
    } else if (question.type === "integer") {
      if (!question.ans_integer) {
        toast.warning(
          `Please provide the answer for the following question in Section ${sindex}, Question ${qindex}.`
        );
        return false;
      }
    } else if (question.type === "record") {
      if (
        parseInt(question.preparation_time) < 5000 ||
        parseInt(question.answer_time) < 5000
      ) {
        toast.warning(
          `Please provide the preparation/answer time for the following question in Section ${sindex}, Question ${qindex}.`
        );
        return false;
      }
    }
    return true;
  }

  isObject(variable) {
    return typeof variable === 'object' && variable !== null;
  }

  validateTest() {
    console.log("checkin", this.props.test);
    if (this.props.test?.title.trim() === "") {
      this.props.handleToastWarning(
        "Test title should not be an empty string!"
      );
      return false;
    } else if (
      this.props.test?.is_a_set &&
      this.props.test?.alloted_proctors.length === 0
    ) {
      this.props.handleToastWarning("Please select at least one proctor");
      return false;
    } else if (!this.isObject(this.props.test?.company) && this.props.test?.company?.trim() === "") {
      this.props.handleToastWarning("Please choose a company!");
      return false;
    } else if (
      new Date(this.props.test?.end_duration) <=
      new Date(this.props.test?.start_duration)
    ) {
      this.props.handleToastWarning(
        "Test end time should not be less than the start time!"
      );
      return false;
    } else if (
      this.props.test?.allow_flexible_exam_time &&
      parseInt(this.props.test?.exam_duration) <= 0
    ) {
      this.props.handleToastWarning(
        "When 'Allow Flexible Time' is enabled, the exam duration cannot be zero."
      );
      return false;
    } else if (
      this.props.test?.is_disable_tab_switch &&
      this.props.test?.tab_switch_count < 1
    ) {
      this.props.handleToastWarning("Tab switch count cannot be less than 1 !");
      return false;
    } else if (
      this.props.test?.is_user_capture_camera &&
      this.props.test?.set_camera_capture_interval < 1
    ) {
      this.props.handleToastWarning(
        "Capture camera interval cannot be less than 1 !"
      );
      return false;
    }

    for (
      let sindex = 0;
      sindex < this.props.test?.components.length;
      sindex++
    ) {
      const section = this.props.test?.components[sindex];
      for (let qindex = 0; qindex < section?.components?.length; qindex++) {
        const question = section?.components[qindex];
        if (!question.type) {
          toast.warning(
            `Please select the answer type for the following question in Section ${sindex + 1}, Question ${qindex + 1}.`
            , defaultToastSetting);
          return false;
        }
        if (question?.question.trim().replace(/<[^>]+>/g, '') === "") {
          toast.warning(
            `Please verify the question in Section ${sindex + 1}, Question ${qindex + 1}.`
            , defaultToastSetting);
          return false;
        }
        if (question.marks <= 0) {
          toast.warning(
            `Please verify the marks for the following question in Section ${sindex + 1}, Question ${qindex + 1}.`
            , defaultToastSetting);
          return false;
        }
        if (section.section_type === "C") {
          if (!question.std_input_file || !question.std_output_file) {
            this.props.handleToastWarning(
              `Input or Output test files not selected in section: ${sindex + 1} and question: ${qindex + 1} of Coding Section !`
            );
            return false;
          }
        } else if (section.section_type === "G") {
          if (!this.checkingResponses(question, sindex + 1, qindex + 1)) {
            return false;
          }
        }
      }
    }
    return true;
  }

  saveTest() {
    if (this.validateTest()) {
      const tmpTest = this.props.test;
      if (this.props.isKAInstructor && !this.props.test.is_kyron_academy)
        tmpTest.is_kyron_academy = true;
      TestService.saveOneTest(tmpTest, this.props.params.id).then(res => {
        toast.success("Test saved successfully!", defaultToastSetting)
        const timer = () =>
          setTimeout(() => {
            if (this.props.test.is_aits) {
              this.props.history("/aits");
            } else {
              this.props.history("/tests");
            }
          }, 3000);
        timer();
        return () => clearTimeout(timer);
      }).catch(e => {
        toast.error(e.message, defaultToastSetting)
      })
    }
  }

  render() {
    return (
      <div className=" w-full fixed bottom-[0.1rem] py-2.5 px-3 bg-gray-200 shadow-[#BCFB46] shadow flex justify-center items-center">
        <div className="w-full flex gap-3 justify-between">
          <UniversalButton
            onClickFunction={() => this.props.updateTestDeleteModalActive(true)}
            classNames="m-0 py-2 px-3 w-auto bg-[#e63946] text-white"
            title="Delete Test"
          />
          <UniversalButton
            onClickFunction={() => this.saveTest()}
            classNames="m-0 py-2 px-5 w-auto"
            title="Save Test"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    loadingDelete: test.loadingDelete,
    test: test.test,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTestDeleteModalActive: (data) =>
      dispatch(updateTestDeleteModalActive(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(createTestFooter));
