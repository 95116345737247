import React, { Component } from "react";
import TestHeader from "../components/tests/createTest/TestHeader";
import hamburger from "../assets/img/svg/hamburger.svg";
import tablec from "../assets/img/svg/tablec.svg";

import { Bar } from "react-chartjs-2";
import { withRouter } from "../components/common/WithRouter";

class Analysis extends Component {
  constructor() {
    super();
    this.state = {
      showAnlysis: true,
    };
  }

  render() {
    const data = {
      labels: [
        "A1",
        "LA2",
        "Unrateable",
        "HA2",
        "LB1",
        "HB1",
        "LA2",
        "Unrateable",
      ],
      datasets: [
        {
          label: "Percentage(%)",
          data: [0.1, 0.2, 0.3, 0.4, 0.5, 0.43, 0.3, 0.1],
          backgroundColor: "#4C93DB",
          barPercentage: 0.5,
        },
      ],
    };
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: {
          beginAtZero: true,
          ticks: {
            stepSize: 0.1,
          },
        },
      },
    };
    return (
      <div className="sm:p-5 p-2 px-3 sm:px-7">
        <button
          type="button"
          className="text-white bg-gradient-to-r from-purple-500 via-purple-600 
        to-purple-700 hover:bg-gradient-to-br font-medium rounded-lg 
        text-sm px-4 py-2 text-center mr-2 mb-2"
          onClick={() => this.props.history(`/tests`)}
        >
          Go Back
        </button>
        <div className="flex justify-between">
          <TestHeader title="Analysis" />
          <div
            onClick={() =>
              this.setState((prevState) => ({
                showAnlysis: !prevState.showAnlysis,
              }))
            }
          >
            <img
              src={this.state.showAnlysis ? hamburger : tablec}
              alt="close"
              title="close"
              className="w-8 h-8 cursor-pointer"
            />
          </div>
        </div>
        {this.state.showAnlysis && (
          <div>
            <div className="grid sm:grid-cols-3 gap-x-20 py-2">
              <p className="text-lg font-semibold text-gray-600">
                Academic IS SOPI Rating Rubric
              </p>
              <p className="text-lg font-semibold text-gray-600">α:0.90</p>
              <p className="text-lg font-semibold text-gray-600">
                α of selected items:0.90
              </p>
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg py-2">
              <table className="w-full text-sm text-left">
                <thead className="text-lg text-gray-500 font-normal">
                  <tr>
                    <th scope="col" className="px-6 py-2">
                      Question
                    </th>
                    <th scope="col" className="px-6 py-2">
                      Item Discrimination Index
                    </th>
                    <th scope="col" className="px-6 py-2">
                      Item Facility
                    </th>
                    <th scope="col" className="px-6 py-2">
                      Cronbach alpha if omitted
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-2 text-[17px] font-medium text-gray-600 whitespace-nowrap"
                    >
                      <input className="mr-3" type="checkbox" id="checkbox" />
                      Task 1 - Response Time-Q#2
                    </th>
                    <td className="px-6 py-2 text-[17px] text-gray-500 font-medium">
                      0.18
                    </td>
                    <td className="px-6 py-2 text-[17px] text-gray-500 font-medium">
                      0.93
                    </td>
                    <td className="px-6 py-2 text-[17px] text-gray-500 font-medium">
                      0.93
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-2 text-[17px] font-medium text-gray-600 whitespace-nowrap"
                    >
                      <input className="mr-3" type="checkbox" id="checkbox" />
                      Task 1 - Response Time-Q#2
                    </th>
                    <td className="px-6 py-2 text-[17px] text-gray-500 font-medium">
                      0.18
                    </td>
                    <td className="px-6 py-2 text-[17px] text-gray-500 font-medium">
                      0.93
                    </td>
                    <td className="px-6 py-2 text-[17px] text-gray-500 font-medium">
                      0.93
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-2 text-[17px] font-medium text-gray-600 whitespace-nowrap"
                    >
                      <input className="mr-3" type="checkbox" id="checkbox" />
                      Task 1 - Response Time-Q#2
                    </th>
                    <td className="px-6 py-2 text-[17px] text-gray-500 font-medium">
                      0.18
                    </td>
                    <td className="px-6 py-2 text-[17px] text-gray-500 font-medium">
                      0.93
                    </td>
                    <td className="px-6 py-2 text-[17px] text-gray-500 font-medium">
                      0.93
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-2 text-[17px] font-medium text-gray-600 whitespace-nowrap"
                    >
                      <input className="mr-3" type="checkbox" id="checkbox" />
                      Task 1 - Response Time-Q#2
                    </th>
                    <td className="px-6 py-2 text-[17px] text-gray-500 font-medium">
                      0.18
                    </td>
                    <td className="px-6 py-2 text-[17px] text-gray-500 font-medium">
                      0.93
                    </td>
                    <td className="px-6 py-2 text-[17px] text-gray-500 font-medium">
                      0.93
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-2 text-[17px] font-medium text-gray-600 whitespace-nowrap"
                    >
                      <input className="mr-3" type="checkbox" id="checkbox" />
                      Task 1 - Response Time-Q#2
                    </th>
                    <td className="px-6 py-2 text-[17px] text-gray-500 font-medium">
                      0.18
                    </td>
                    <td className="px-6 py-2 text-[17px] text-gray-500 font-medium">
                      0.93
                    </td>
                    <td className="px-6 py-2 text-[17px] text-gray-500 font-medium">
                      0.93
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className="flex py-2 h-[55vh] w-full">
          <Bar options={options} data={data}></Bar>
        </div>
      </div>
    );
  }
}

export default withRouter(Analysis);
