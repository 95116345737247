import React from 'react'
import { useEffect } from 'react'
import { fetchAllAssignedReportsRequested } from '../../store/actions/evaluators'
import { useDispatch, useSelector } from 'react-redux'
import tick from "../../assets/img/svg/tick.png";
import cross from "../../assets/img/svg/cross.png";
import load from "../../assets/img/svg/load.svg";
import { useState } from 'react';

export default function EvaluatorsAssign() {
    const dispatch = useDispatch();
    const [currentQuiz, setCurrentQuiz] = useState(null);
    const [evaluationFilter, setEvaluationFilter] = useState("")
    const { reportsAssigned, loading } = useSelector((state) => state.evaluatorsReducer);

    useEffect(() => {
        dispatch(fetchAllAssignedReportsRequested({ evaluated: evaluationFilter }))
    }, [])



    const onClickReport = (id) => {
        window.location.href = `/blind-assessment/review/${id}`;
    }

    const handleFilterType = (e) => {
        setEvaluationFilter(e.target.value);
        dispatch(fetchAllAssignedReportsRequested({ evaluated: e.target.value }))
    }


    return (
        <div className="m-5 p-3 md:my-10 md:mx-20 shadow-xl">
            <div className="flex justify-between  items-center">
                <h1 className="font-medium mr-2 text-lg font-['poppins']">Kyron Blind Assessment</h1>
                <div className="flex justify-center items-center gap-2">
                    <select
                        id="small"
                        name="evaluation_filter"
                        value={evaluationFilter}
                        onChange={(e) => handleFilterType(e)}
                        className="block px-3 border  shadow-md font-['poppins'] font-normal h-[2.1rem] text-sm text-gray-900 bg-gray-50 rounded-lg"
                    >
                        <option value="">All Reports</option>
                        <option value="E">Evaluated reports</option>
                        <option value="P">Pending reports</option>
                    </select>
                    <button onClick={() => dispatch(fetchAllAssignedReportsRequested({ evaluated: evaluationFilter }))} className=" font-['nunito'] my-2 hover:bg-[#BBE74B] hover:text-[#011538]  text-[#BBE74B] bg-[#011538] font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                    >Refresh</button>
                </div>
            </div>
            <div className="font-['nunito']">
                {reportsAssigned != null && !loading ?
                    <>

                        {reportsAssigned?.length > 0 ?
                            <>
                                {reportsAssigned.map((report, index) =>
                                    <>
                                        <div
                                            id="accordion-flush"
                                            data-accordion="collapse"
                                            data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white"
                                            data-inactive-classes="text-gray-500 dark:text-gray-400"
                                            key={"section_" + index}
                                            className={`hover:cursor-pointer mb-1 transition-all hover:bg-[#BBE74B]  shadow-md ${currentQuiz === index ? " bg-[#BBE74B] text-[#011538]" : " bg-[#ecf7cd] text-[#BBE74B] "}`}
                                            onClick={() => currentQuiz === index ? setCurrentQuiz(null) : setCurrentQuiz(index)}
                                        >
                                            <h2 id="accordion-flush-heading-1 transition-all">
                                                <div
                                                    className="flex items-center  transition-all  w-full text-sm px-3 py-1.5 text-left text-gray-800 border-b border-gray-200"
                                                    data-accordion-target="#accordion-flush-body-1"
                                                    aria-expanded="true"
                                                    aria-controls="accordion-flush-body-1"
                                                >
                                                    <div className="flex flex-col">
                                                        <p className="font-semibold text-lg">
                                                            {report?.quiz_name}
                                                        </p>
                                                        {evaluationFilter === "" ?
                                                            <div className="flex flex-col">

                                                                <div>
                                                                    <span className="text-[9px] font-semibold inline-block px-2 uppercase rounded-full text-white bg-[#001437] text-[#a0db32]">
                                                                        {report?.noOfReportsEvaluated === report?.totalNoOfReports ? "Completed" : "Progress"}
                                                                    </span>
                                                                </div>
                                                                <div className="flex  items-center justify-between">
                                                                    <div className="overflow-hidden  min-w-[200px] h-2 text-[10px] flex rounded bg-[#BCFB46] ">
                                                                        <div style={{ width: (report?.noOfReportsEvaluated / report?.totalNoOfReports) * 100 + '%' }} className="shadow-none flex flex-col min-w-[1%] text-center whitespace-nowrap text-white justify-center bg-[#001437]"></div>
                                                                    </div>
                                                                    <div className="mr-auto mx-2">
                                                                        <span className="text-[12px] px-1 bg-[#001437] rounded font-semibold inline-block text-[#a0db32]">
                                                                            {report?.noOfReportsEvaluated}/{report?.totalNoOfReports}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div> : null}
                                                    </div>


                                                    <div className="flex ml-auto gap-x-1 items-center justify-center">
                                                        <svg
                                                            data-accordion-icon
                                                            className={`w-6 h-6 shrink-0 ${index === currentQuiz && "rotate-180"
                                                                }`}
                                                            fill="currentColor"

                                                            viewBox="0 0 20 20"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                                clipRule="evenodd"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </h2>

                                        </div>
                                        {(currentQuiz === index) ?

                                            <>
                                                {report?.reports.length > 0 ?
                                                    <>

                                                        {report?.reports.map(report =>
                                                            <div key={report.evaluated_report_id} onClick={() => onClickReport(report.evaluated_report_id)} className="grid border border-lg hover:border-[#BBE74B] hover:bg-white hover:cursor-pointer text-sm my-1 bg-gray-300 px-3 py-2 grid-cols-4 gap-4">
                                                                <p>Report-{report.evaluated_report_id.slice(-6)}</p>
                                                                <p>Quiz-{report.quiz_id.slice(-6)}</p>
                                                                <div className="flex gap-4 items-center justify-center">
                                                                    <img
                                                                        src={report.isEvaluated ? tick : cross}
                                                                        alt=""
                                                                        className="w-5 h-5"
                                                                    />

                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                    :
                                                    <div className="flex justify-center p-2 ">
                                                        No Reports Found!
                                                    </div>
                                                }</>
                                            : null
                                        }
                                    </>
                                )}
                            </>
                            :
                            <div className="flex justify-center p-2">
                                No Reports Found!
                            </div>
                        }
                    </>
                    :
                    <img
                        alt="loading"
                        src={load}
                        className="spinner my-1 mx-auto w-5 h-5 text-white "
                    />
                }

            </div>
        </div>
    )
}
