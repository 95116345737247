import axios from "axios";
import { BASE_URL } from "../assets/js/config";
import authHeader from "./auth-header";

export const fetchChatOfUser = ({ testId, userId }) => {
  let queryString = "";
  if (userId) {
    queryString = `user=${userId}`;
  }
  return axios
    .get(`${BASE_URL}api/quizchat/${testId}/me?${queryString}`, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};
export const fetchChatOfQuiz = ({ testId }) => {
  return axios
    .get(`${BASE_URL}api/quizchat/quiz/${testId}`, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};
