import React, { useState, useEffect } from "react";
import "../common/Common.css";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { loginSuccess } from "../../store/actions/auth";
import githubIcon from "../../assets/img/svg/github.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../assets/js/config";
import { defaultToastSetting } from "../../assets/js/toast_config";
import qs from "qs";
import { getUser } from "../../services/user.service";

const GithubLogin = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    //Redirection during Github signup/login
    const code = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    }).code;

    let userData = null;
    let email = "";
    //Getting All USer details using the github code passed as params
    getUser(code)
      .then((res) => {
        userData = JSON.parse(res.userData.data.text);
        email = JSON.parse(res.userEmails.data.text)[0].email;
        axios
          .post(`${BASE_URL}api/auth/githublogin`, {
            email: email,
            userId: userData.node_id,
          })
          .then((res) => {
            const auth = res.data;
            if (auth.token) {
              localStorage.setItem("priksha_name", auth.name);
              localStorage.setItem("priksha_token", auth.token);
              localStorage.setItem("priksha_type", auth.type);
              localStorage.setItem("priksha_email", auth.email);
            }

            dispatch(loginSuccess(auth));

            toast.success("Login successful", defaultToastSetting);
          })
          .catch((err) => {
            console.log(err);
            history("/login");
            toast.error("Cannot Login with Github", defaultToastSetting);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <div className="flex items-center justify-center h-[100vh]">
        <div className=" flex flex-col items-center justify-center font-['poppins'] text-neutral-400 text-lg mb-4">
          {/* <img src={KyronSideLetter} alt="LOGO" className="w-[220px] mb-4" /> */}
          <img
            src={githubIcon}
            height="40px"
            width="40px"
            className="mb-2 mr-2"
          />
          <div>Logging into Kyron via Github...</div>
        </div>
      </div>
    </>
  );
};

export default GithubLogin;
