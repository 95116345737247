import React, { useState, useEffect, useRef } from "react";
import { rearrangeAllTests } from "../store/actions/test";
import TestHeader from "../components/tests/createTest/TestHeader";
import CurrentTestCard from "./CurrentTestCard";
import { useDispatch } from "react-redux";
import { ReactComponent as EmptyIcon } from "../assets/img/svg/empty.svg";
import Slider from "react-slick";
import "../components/common/Common.css";
import { slickSetting } from "../components/tests/Arrow";
import Modal from "../components/common/Modal/Modal";
import TestSeeAllModal from "../components/modals/TestSeeAllModal";

const UpcomingTestCards = ({
  isAdmin,
  isTeacher,
  isKAInstructor,
  handleToast,
  isAssistant,
  showDropDown,
  handleDropDown,
  handleDeleteTest,
  upcoming,
  errorMessage,
}) => {
  const dispatch = useDispatch();

  const interval = useRef();
  const [seeAllModal, setSeeAllModal] = useState(false);

  useEffect(() => {
    if (upcoming.length) {
      let lowestTime = 9999999999999;
      let shifting_test_index = 0;
      upcoming.forEach((test, index) => {
        if (new Date(test.start_duration).getTime() < lowestTime) {
          lowestTime = new Date(test.start_duration).getTime();
          shifting_test_index = index;
        }
      });
      if (
        (lowestTime - new Date().getTime()) / (1000 * 60) <= 30 &&
        (lowestTime - new Date().getTime()) / (1000 * 60) > 0
      ) {
        clearInterval(interval.current);
        interval.current = setTimeout(() => {
          dispatch(rearrangeAllTests({ index: shifting_test_index }));
        }, lowestTime - new Date().getTime() + 5);
      }
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [upcoming.length]);

  useEffect(() => {
    // handleToast(errorMessage);
  }, [errorMessage]);

  return (
    <>
      <div className="w-full m-auto">
        <div className="flex items-center justify-between w-full mt-8">
          <TestHeader title="Upcoming Tests" />
          {upcoming.length > 0 && (
            <h1
              className="text-[1rem] text-[#87CF00] font-medium mr-3 cursor-pointer"
              onClick={() => setSeeAllModal(true)}
            >
              See All
            </h1>
          )}
        </div>
        <div className="test mx-[1.5rem] mt-[0.8rem] w-[96%]">
          {upcoming.length > 0 ? (
            <Slider {...slickSetting}>
              {upcoming.map((data, data_index) => (
                <CurrentTestCard
                  key={`CURRENT${data._id}`}
                  data={data}
                  data_index={data_index}
                  showDropDown={showDropDown}
                  handleDropDown={handleDropDown}
                  handleDeleteTest={handleDeleteTest}
                  isAdmin={isAdmin}
                  isTeacher={isTeacher}
                  isKAInstructor={isKAInstructor}
                  isAssistant={isAssistant}
                  type="upcoming"
                />
              ))}
            </Slider>
          ) : (
            <div className="w-full h-[23rem] flex flex-col justify-center items-center p-5">
              <EmptyIcon style={{ height: "100%" }} />
              <h3>No Tests to show</h3>
            </div>
          )}
        </div>
      </div>
      <Modal
        onClose={() => setSeeAllModal(false)}
        showCloseButton
        isModal={seeAllModal}
        className="w-[80%]"
      >
        <TestSeeAllModal
          data={upcoming}
          showDropDown={showDropDown}
          handleDropDown={handleDropDown}
          handleDeleteTest={handleDeleteTest}
          isAdmin={isAdmin}
          isTeacher={isTeacher}
          isKAInstructor={isKAInstructor}
          isAssistant={isAssistant}
        />
      </Modal>
    </>
  );
};

export default UpcomingTestCards;
