import axios from "axios";
import { BASE_URL } from "../assets/js/config";
import authHeader from "./auth-header";

export const uploadFileRequestApi = (data) => {
  return axios
    .post(`${BASE_URL}api/chatpdf/add-url`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const extractQuestionRequestApi = (data) => {
  return axios
    .post(`${BASE_URL}api/chatpdf/message`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const getQuizDetailsApi = (id) => {
  console.log("service", id);
  return axios
    .get(`${BASE_URL}api/quiz/${id}/getQuizDetails`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const insertQuestionsApi = (data) => {
  return axios
    .post(`${BASE_URL}api/quiz/insertQuestions`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};
