import {
  SET_POSTGRES_ERROR,
  SET_POSTGRES_TABLE,
  UPDATE_LOADING_RESPONSE,
  UPDATE_POSTGRES_QUERY,
  UPDATE_POSTGRES_RESULT,
} from "../actionTypes/postgres";

export const updatePostgresQuery = (data) => {
  return {
    type: UPDATE_POSTGRES_QUERY,
    payload: data,
  };
};

export const updateLoadingResponse = (data) => {
  return {
    type: UPDATE_LOADING_RESPONSE,
    payload: data,
  };
};

export const updatePostgresResult = (data) => {
  return {
    type: UPDATE_POSTGRES_RESULT,
    payload: data,
  };
};

export const setPostgresError = (data) => {
  return {
    type: SET_POSTGRES_ERROR,
    payload: data,
  };
};

export const setPostgresTable = (data) => {
  return {
    type: SET_POSTGRES_TABLE,
    payload: data,
  };
};