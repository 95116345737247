import React, { Component } from "react";
import circle from "../../../assets/img/circle.svg";
import greenCircle from "../../../assets/img/greencircle.svg";
import eye from "../../../assets/img/svg/eye.svg";
import Peer from "peerjs";
import io from "socket.io-client";
import { getUserToken } from "../../common/utils";
import { connect } from "react-redux";
import { fetchTestRequest } from "../../../store/actions/test";
import { fetchProctoredUserDetailsRequested } from "../../../store/actions/userdetails";
import { findDuration, formateTime } from "../utils/TimeFormat";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../../assets/js/toast_config";
import { ReactComponent as Cross } from "../../../assets/img/svg/cross.svg";
import {
  generateProctorPeerId,
  generateStudentProctorPeerId,
  peerConfig,
} from "../utils";
import { toggleSidebar } from "../../../store/actions/sidebar";
import { viewFile } from "../../../services/upload.service";
import {
  getCheatingFlags,
  getProctoringFlags,
  getTestTimeService,
  updateUniqueTestTimeService,
} from "../../../services/proctor.service";
import ProctoringViewImageModal from "../videoMonitoring/ProctoringViewImageModal";
import axios from "axios";
import ProctoringUserDetails from "../videoMonitoring/Proctoring/ProctoringUserDetails";
import ProctoringUserFlags from "../videoMonitoring/Proctoring/ProctoringUserFlags";
import UniversalButton from "../../common/StandardButton/UniversalButton";
import { withRouter } from "../../common/WithRouter";

class Proctoring extends Component {
  constructor(props) {
    super(props);
    this.socket = io(process.env.REACT_APP_SOCKET_URL, {
      secure: true,
      withCredentials: true,
      transports: ["websocket"],
    });
    const tokenArr = getUserToken().split(".");
    const uniqueUserId = tokenArr[tokenArr.length - 1];

    this.testId = this.props.params.id;
    this.state = {
      name: "",
      email: "",
      mobile: "",
      isPhotoAndCard: false,
      isTerms: false,
      isMetrics: false,
      idCardImg: null,
      studentPhotoImg: null,
      startTimeTest: null,
      college: "",
      branch: "",
      showModal: false,
      displayPeerId: generateProctorPeerId("teacher", {
        peerType: "display",
        userId: uniqueUserId,
      }),
      userAgent: null,
      timezone: null,
      submission_id: "",
      extra_time: 0,
      proctor_comments: [],
      newCommentInput: "",
      proctoringViewImageModal: false,
      screenState: 1,
      proctoring_flags: [],
      cheating_flags: [],
    };

    this.studentVideo = React.createRef(null);
    this.userDisplay = React.createRef(null);
  }

  componentDidMount = () => {
    if (this.props.sidebar.opened) {
      this.props.toggleSidebar();
    }
    const studentPeer = localStorage.getItem("student-peer");
    const peerconfig = peerConfig(this.props.credentials);
    this.displayPeer = new Peer(this.state.displayPeerId, peerconfig);
    this.displayPeer.on("open", () => {
      this.socket.on("connect", () => {
        this.socket.emit(
          "give-user-details-to-proctor",
          {
            testId: this.testId,
            studentPeer,
            displayPeer: this.state.displayPeerId,
          },
          (response) => {
            if (response) {
              this.setState((prev) => {
                return {
                  ...prev,
                  name: response.name || "Not Provided",
                  email: response.email || "Not Provided",
                  mobile: response.mobile || "Not Provided",
                  isPhotoAndCard: response.isPhotoAndCard || "Not Provided",
                  // isTerms: response.isTerms || "Not Provided",
                  // isMetrics: response.isMetrics || "Not Provided",
                  startTimeTest: response.startTimeTest || "Not Provided",
                  college: response.college || "Not Provided",
                  branch: response.branch || "Not Provided",
                  isStarted: response.isStarted || "Not Provided",
                };
              });
            }
            getTestTimeService({ email: response.email, quiz: this.testId })
              .then((res) => {
                const procFlags = res[0]?.proctoring_flags?.map(
                  (flag) => flag._id
                );
                const cheatFlags = res[0]?.cheating_flags?.map(
                  (flag) => flag._id
                );
                console.log({ res: res[0] });
                this.setState((prev) => {
                  return {
                    ...prev,
                    submission_id: res[0]._id,
                    extra_time: res[0]?.extra_time || 0,
                    proctor_comments: res[0]?.proctor_comments || [],
                    proctoring_flags: procFlags || [],
                    cheating_flags: cheatFlags || [],
                  };
                });
              })
              .catch((err) => console.log({ err }));
            this.props.fetchProctoredUserDetailsRequested({
              testId: this.testId,
              userId: studentPeer,
            });
          }
        );
      });

      // console.log("peer:", this.displayPeer);
      this.displayPeer.on("call", (call) => {
        call.answer();
        console.log({ displayCallProctoring: call });
        call.on("stream", (displayStream) => {
          this.userDisplay.current.srcObject = displayStream;
        });
      });

      this.props.fetchTestRequest(this.props.params.id);
    });
  };

  handleUpdateStudentTime(e) {
    this.setState((prev) => {
      return {
        ...prev,
        extra_time: e.target.value,
      };
    });
  }

  handleSendUpdateRequest(proctoring_flags, cheating_flags) {
    const { submission_id, extra_time, proctor_comments } = this.state;
    updateUniqueTestTimeService({
      submission_id,
      extra_time,
      proctor_comments: [...proctor_comments, this.state.newCommentInput],
      proctoring_flags,
      cheating_flags,
    })
      .then((res) => {
        if (res.success) {
          toast.success(res.msg, defaultToastSetting);
        }
      })
      .catch((err) => toast.error("Cannot Update time", defaultToastSetting));
  }

  componentDidUpdate(prev) {
    if (
      this.props.studentVideoStream &&
      this.studentVideo.current &&
      (!this.studentVideo.current.srcObject ||
        prev.studentVideoStream.id != this.props.studentVideoStream.id)
    ) {
      this.studentVideo.current.srcObject = this.props.studentVideoStream;
    }
    if (
      prev.isFetching &&
      !this.props.isFetching &&
      this.props.proctoredUserDetails
    ) {
      this.setState((prev) => {
        return {
          ...prev,
          isPhotoAndCard:
            this.props.proctoredUserDetails.isPhotoAndCard || false,
          isTerms: this.props.proctoredUserDetails.isTerms || false,
          isMetrics: this.props.proctoredUserDetails.isMetrics || false,
          userAgent: this.props.proctoredUserDetails.userAgent || false,
          timezone: this.props.proctoredUserDetails.timezone || "Not Provided",
        };
      });

      const studentDisplayPeer = generateStudentProctorPeerId("student", {
        peerType: "display",
        testId: this.props?.proctoredUserDetails?.quiz?._id,
        userId: this.props?.proctoredUserDetails?.user?._id,
      });
      let calls = [];
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then(async (stream) => {
          try {
            const displayCall = this.displayPeer.call(
              studentDisplayPeer,
              stream
            );
            calls.push(displayCall);
            // console.log("displayCall: ", displayCall);
            displayCall.on("stream", (remoteDisplayStream) => {
              // console.log("remoteDisplayStream: ", remoteDisplayStream);
              this.userDisplay.current.srcObject = remoteDisplayStream;
            });
          } catch (err) {
            console.log(err);
          }
        });

      viewFile(this.props.proctoredUserDetails.idCardImg, "PROCTORING").then(
        (data) => {
          this.setState((prev) => {
            return {
              ...prev,
              idCardImg: data,
            };
          });
        }
      );
      viewFile(
        this.props.proctoredUserDetails.studentPhotoImg,
        "PROCTORING"
      ).then((data) => {
        this.setState((prev) => {
          return {
            ...prev,
            studentPhotoImg: data,
          };
        });
      });
    }
  }

  componentWillUnmount() {
    // console.log("Yoouuu");
    this.displayPeer.destroy();
  }

  refershStudent = (type) => {
    switch (type) {
      case "display":
        const studentDisplayPeer = generateStudentProctorPeerId("student", {
          peerType: "display",
          testId: this.props?.proctoredUserDetails?.quiz?._id,
          userId: this.props?.proctoredUserDetails?.user?._id,
        });
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: true })
          .then(async (stream) => {
            try {
              const displayCall = this.displayPeer.call(
                studentDisplayPeer,
                stream
              );
              // calls.push(displayCall);
              // console.log("displayCall: ", displayCall);
              displayCall.on("stream", (remoteDisplayStream) => {
                // console.log("remoteDisplayStream: ", remoteDisplayStream);
                this.userDisplay.current.srcObject = remoteDisplayStream;
              });
            } catch (err) {
              console.log(err);
            }
          });
        return;
      default:
        return;
    }
  };

  handleFlagsUpdate(flag, id) {
    let flagArray = [];

    if (flag) flagArray = this.state.cheating_flags;
    else flagArray = this.state.proctoring_flags;

    const idx = flagArray.findIndex((flag) => flag === id);

    if (idx !== -1) {
      this.setState((prev) => {
        return {
          ...prev,
          [flag ? "cheating_flags" : "proctoring_flags"]: this.state[
            flag ? "cheating_flags" : "proctoring_flags"
          ].filter((flag) => flag !== id),
        };
      });
      const procFlags = this.state.proctoring_flags.filter(
        (flag) => flag !== id
      );

      const cheatFlags = this.state.cheating_flags.filter(
        (flag) => flag !== id
      );
      this.handleSendUpdateRequest(procFlags, cheatFlags);
    } else {
      let arr = [];
      if (flag) arr = this.state.cheating_flags;
      else arr = this.state.proctoring_flags;

      this.setState((prev) => {
        return {
          ...prev,
          [flag ? "cheating_flags" : "proctoring_flags"]: [...arr, id],
        };
      });
      let cheatFlags = [],
        procFlags = [];
      if (flag) {
        procFlags = this.state.proctoring_flags;
        cheatFlags = [...arr, id];
      } else {
        procFlags = [...arr, id];
        cheatFlags = this.state.cheating_flags;
      }
      this.handleSendUpdateRequest(procFlags, cheatFlags);
    }
  }

  render() {
    const getType = () => {
      return localStorage.getItem("priksha_type");
    };
    const userDetails = {
      name: this.state.name,
      mobile: this.state.mobile,
      email: this.state.email,
      college: this.state.college,
      timezone: this.state.timezone,
      title: this.props.test.title,
      start_duration: formateTime(this.props.test.start_duration),
      end_duration: formateTime(this.props.test.end_duration),
      test_duration: findDuration(
        this.props.test.start_duration,
        this.props.test.end_duration
      ),
      userAgent: this.state.userAgent,
    };
    return (
      <div className="flex relative w-full flex-col h-[65vh] md:mx-1 scrollbar overflow-y-auto">
        <ProctoringViewImageModal
          isModalOpen={this.state.proctoringViewImageModal}
          isPhotoAndCard={this.state.isPhotoAndCard}
          studentPhotoImg={this.state.studentPhotoImg}
          idCardImg={this.state.idCardImg}
          handleProctoringImageModal={(v) =>
            this.setState((prev) => {
              return {
                ...prev,
                proctoringViewImageModal: v,
              };
            })
          }
        />
        <div className="flex fixed z-10 justify-between w-full md:w-[39%] lg:w-[31%] xl:w-[27%] py-1  rounded-t-lg  bg-[#BCFB46]  gap-2  font-['poppins'] items-center">
          <h2 className="text-lg font-semibold px-2 text-[#001437]">User Details</h2>
          <Cross
            className="cursor-pointer mr-2"
            color="#001437"
            onClick={() => this.props.setShowProctoringReportModal(null)}
          />
        </div>
        <div className="px-2 mt-12 flex justify-center gap-4 items-center">
          <h3 className="text-md font-semibold">Screen</h3>
          <label
            for="small-toggle"
            className="inline-flex relative items-center cursor-pointer"
          >
            <input
              type="checkbox"
              value=""
              id="small-toggle"
              className="sr-only peer"
              onClick={() =>
                this.setState((prev) => {
                  return {
                    ...prev,
                    screenState: this.state.screenState ? 0 : 1,
                  };
                })
              }
            />
            <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
          <h3 className="text-md font-semibold">Video</h3>
        </div>

        <div className="p-2">
          {/* User Video Stream */}
          <div
            className={`${this.state.screenState ? "w-full h-auto" : "w-[0] h-[0]"
              } overflow-hidden`}
          >
            <div className="flex justify-between items-center ">
              <p className="text-d font-medium text-slate-500 py-2">
                Candidate's Screen
              </p>

              <div
                className="mr-2 rounded-full cursor-pointer hover:bg-gray-200 p-1"
                style={{
                  transition: "background 300ms linear",
                  cursor: "pointer",
                }}
                onClick={() => this.refershStudent("display")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                  <title>Reset Screen</title>
                </svg>
              </div>
            </div>
            <video
              className="bg-gray-300"
              width={"100%"}
              ref={this.userDisplay}
              autoPlay
              muted
              controls
              id="shared-screen"
            ></video>
          </div>

          {/* User Display Stream */}
          <div
            className={`${this.state.screenState === 0 ? "w-full h-auto" : "w-[0] h-[0]"
              } overflow-hidden`}
          >
            <div className="flex justify-between items-center">
              <p className="text-d font-medium text-slate-500 py-2">
                Candidate's Video
              </p>
              <div
                className="mr-2 rounded-full cursor-pointer hover:bg-gray-200 p-1"
                style={{
                  transition: "background 300ms linear",
                  cursor: "pointer",
                }}
                onClick={() => this.refershStudent("video")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                  <title>Reset Video</title>
                </svg>
              </div>
            </div>
            <video
              className="bg-gray-300"
              width={"100%"}
              ref={this.studentVideo}
              autoPlay
              muted
              controls
            ></video>
          </div>
        </div>
        {getType() === "T" || getType() === "P" ? (
          <>
            <div className="flex  flex-col my-2 p-2">
              <div className="flex items-center">
                <label>Increase Exam Duration(in Mins)</label>
                <img
                  src={eye}
                  alt="info"
                  title="Enter the number of minutes you want increase"
                  className="ml-2"
                />
              </div>
              <div className="flex mt-2">
                <input
                  type="number"
                  name="Minutes"
                  min="0"
                  value={this.state.extra_time}
                  placeholder="Enter number of minutes"
                  onChange={(e) => this.handleUpdateStudentTime(e)}
                  className="border-2 border-gray-300 outline-gray-300 px-2 rounded-l-md"
                />
                <button
                  onClick={() => this.handleSendUpdateRequest()}
                  className=" py-1 px-2 text-[#001437]  bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32] text-md font-semibold  rounded-r-md"
                >
                  Update
                </button>
              </div>
            </div>

            <div className="my-2">
              <ProctoringUserFlags
                proctoring_flags={this.state.proctoring_flags}
                cheating_flags={this.state.cheating_flags}
                handleFlagsUpdate={(flag, id) =>
                  this.handleFlagsUpdate(flag, id)
                }
              />
            </div>
          </>
        ) : null}

        <ProctoringUserDetails userDetails={userDetails} />

        <div className="flex flex-col justify-center gp-6 p-2 mb-8">
          {/* {exam details} */}

          {/* {Photo and Id card details} */}
          <div className="flex justify-center gap-x-5">
            <UniversalButton onClickFunction={() =>
              this.setState((prev) => {
                return {
                  ...prev,
                  proctoringViewImageModal: true,
                };
              })
            } title="Examine ID" className="w-[400px]" />

          </div>

          {/* Proctor Comments */}
          {console.log("sasda", this.state.proctor_comments)}
          {getType() === "T" || getType() === "P" ? (
            <div className="flex flex-col justify-start py-1">
              <h4 className="text-2xl font-semibold">Proctor Comments:</h4>
              <div className="flex flex-col col-gap-3">
                <div className="flex flex-col my-2 max-h-[200px] overflow-y-auto scrollbar pr-2">
                  {this.state.proctor_comments.map((comment) => {
                    return (
                      <>
                        {comment !== "" && <p
                          className="border my-1 rounded-md px-2"
                          style={{
                            boxShadow: "rgba(0, 0, 0, 0.1) 1.95px 1.95px 2.6px",
                          }}
                        >
                          {comment}
                        </p>
                        }
                      </>
                    );
                  })}
                </div>
                {this.state.isNewComment ? (
                  <div className="flex gap-2 justify-between w-full">
                    <input
                      type="text"
                      value={this.state.newCommentInput}
                      className="border-2 border-gray-300 outline-gray-300 px-2 text-sm rounded-sm w-[75%]"
                      onChange={(e) =>
                        this.setState((prev) => {
                          return {
                            ...prev,
                            newCommentInput: e.target.value,
                          };
                        })
                      }
                    />
                    <div className="">
                      <button
                        onClick={() => {
                          this.setState((prev) => {
                            return {
                              ...prev,
                              proctor_comments: [
                                ...prev.proctor_comments,
                                this.state.newCommentInput,
                              ],
                              newCommentInput: "",
                              isNewComment: false,
                            };
                          });
                          this.handleSendUpdateRequest();
                        }}
                        className="h-[40px] w-[35px] cursor-pointer text-center p-2 text-[#001437]  bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32] transition-all duration-100 rounded-md"
                        disabled={!this.state.newCommentInput.trim().length}
                      >
                        &#10003;
                      </button>{" "}
                      <button
                        className="h-[40px] w-[35px] cursor-pointer text-center p-2 bg-red-500 hover:bg-red-600 transition-all duration-100 text-white rounded-md"
                        onClick={() =>
                          this.setState((prev) => {
                            return {
                              ...prev,
                              newCommentInput: "",
                              isNewComment: false,
                            };
                          })
                        }
                      >
                        x
                      </button>{" "}
                    </div>
                  </div>
                ) : null}
                <div
                  onClick={() =>
                    this.setState((prev) => {
                      return {
                        ...prev,
                        isNewComment: true,
                      };
                    })
                  }
                  className="w-full cursor-pointer my-2 py-1 px-2 text-[#a0db32] border border-[#a0db32] hover:bg-[#BCFB46]
                  hover:text-white transition-all duration-200  text-md font-semibold text-center  rounded-md"
                >
                  + Add new comment
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test, sidebar, turnserver, userdetails }) => {
  return {
    test: test.test,
    sidebar: sidebar,
    credentials: turnserver.credentials,
    isFetching: userdetails.isFetching,
    proctoredUserDetails: userdetails.proctoredUserDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTestRequest: (data) => dispatch(fetchTestRequest(data)),
    toggleSidebar: () => dispatch(toggleSidebar()),
    fetchProctoredUserDetailsRequested: (data) =>
      dispatch(fetchProctoredUserDetailsRequested(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Proctoring));
