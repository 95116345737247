import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_PROFILE_REQUESTED,
  LOGIN_REQUESTED,
  FORGOT_PASSWORD_REQUESTED,
  RESET_PASSWORD_REQUESTED,
  SIGNUP_REQUESTED,
  REGISTER_PUBLIC_TEST_TOKEN,
  GET_USER_SUBSCRIBED_PLANS,
  LOGOUT,
  UPDATE_USER_REQUEST,
} from "../actionTypes";
import {
  loginSuccess,
  loginFailure,
  setMessage,
  setSuccessMessage,
  signupSuccess,
  signupFailure,
  logoutSuccessful,
} from "../actions/auth";
import {
  loginUser,
  forgotPassword,
  resetPassword,
  registerPublicUser,
  logoutUser,
} from "../../services/auth.service";
import {
  fetchProfile,
  getUserSubscribedPlansService,
  registerUser,
  updateUserApi,
} from "../../services/user.service";
import {
  fetchMyProfileFailure,
  fetchMyProfileSuccess,
  getUserSubscribedPlansSuccess,
  updateUserFailed,
  updateUserSuccess,
} from "../actions/user";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../assets/js/toast_config";

function* loginUserSaga(action) {
  try {
    const auth = yield call(loginUser, action.payload);
    if (auth.token) {
      localStorage.setItem("priksha_name", auth.name);
      localStorage.setItem("priksha_token", auth.token);
      localStorage.setItem("priksha_type", auth.type);
      localStorage.setItem("priksha_email", auth.email);
    }
    yield put(loginSuccess(auth));
  } catch (e) {
    toast.error(e.msg, defaultToastSetting);
    yield put(loginFailure(e));
  }
}

function* registerPublicTestToken(action) {
  try {
    const auth = yield call(registerPublicUser, action.payload);
    if (auth.token) {
      localStorage.setItem("priksha_token", auth.token);
      localStorage.setItem("priksha_type", auth.type);
      localStorage.setItem("priksha_email", auth.email);
    }
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
  }
}

function* fetchMyProfileSaga(action) {
  try {
    const profile = yield call(fetchProfile);
    yield put(fetchMyProfileSuccess(profile));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(fetchMyProfileFailure(e));
  }
}

function* forgotPasswordSaga(action) {
  try {
    const response = yield call(forgotPassword, action.payload);
    yield put(setSuccessMessage(response.data));
  } catch (e) {
    yield put(setMessage(e.message));
  }
}

function* resetPasswordSaga(action) {
  try {
    const response = yield call(resetPassword, action.payload);
    yield put(setSuccessMessage(response.data.data));
  } catch (e) {
    yield put(setMessage(e.message));
  }
}

function* signupUserSaga(action) {
  try {
    const response = yield call(registerUser, action.payload);
    yield put(signupSuccess(true));
    toast.success("User registered successfully", defaultToastSetting);
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(signupFailure(false));
  }
}

function* getUserSubscribedPlansSaga() {
  try {
    const res = yield call(getUserSubscribedPlansService);
    yield put(getUserSubscribedPlansSuccess(res));
  } catch (e) {
    toast.error(e, defaultToastSetting);
  }
}

function* logoutUserSaga() {
  try {
    const res = yield call(logoutUser);
    yield put(logoutSuccessful());
  } catch (e) {
    window.location.href = "/login";
    toast.error(e, defaultToastSetting);
  }
}

function* updateUser(action) {
  try {
    const { updatedData, closeModal } = action.payload;
    const response = yield call(updateUserApi, updatedData);
    yield put(updateUserSuccess(response));
    closeModal();
    toast.success("User registered successfully", defaultToastSetting);
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(updateUserFailed(e));
  }
}

function* userSaga() {
  yield takeEvery(LOGIN_REQUESTED, loginUserSaga);
  yield takeEvery(FETCH_PROFILE_REQUESTED, fetchMyProfileSaga);
  yield takeEvery(FORGOT_PASSWORD_REQUESTED, forgotPasswordSaga);
  yield takeEvery(RESET_PASSWORD_REQUESTED, resetPasswordSaga);
  yield takeEvery(SIGNUP_REQUESTED, signupUserSaga);
  yield takeEvery(REGISTER_PUBLIC_TEST_TOKEN, registerPublicTestToken);
  yield takeEvery(GET_USER_SUBSCRIBED_PLANS, getUserSubscribedPlansSaga);
  yield takeEvery(LOGOUT, logoutUserSaga);
  yield takeEvery(UPDATE_USER_REQUEST, updateUser);
}

export default userSaga;
