import React from "react";
import { Media } from "react-media-player";
import { viewFile } from "../../../services/upload.service";
import tick from "../../../assets/img/svg/tick.png";
import cross from "../../../assets/img/svg/cross.png";
import ordinal from "ordinal";
import { CodeBlock, dracula } from "react-code-blocks";

import MyQuillEditor from "../../common/ReactQuill/ReactQuill";
import { isFloat } from "../../common/utils";
import mime from "mime";
import Modal from "../../common/Modal/Modal";
import ViewFileModal from "../../modals/ViewFileModal";
import { Languages } from "../codeEditor/Utils/Languages";

export default class RenderQuestion extends React.Component {
  constructor() {
    super();
    this.state = {
      questionFilesURL: [],
      answerFilesUrl: null,
      fileModal: false,
    };
  }

  componentDidMount() {
    const { response, sectionIndex, index, question_details } = this.props;
    if (this.props.question_details.questionFiles) {
      Promise.all(
        this.props.question_details.questionFiles.map((filename) => {
          return viewFile(filename, "TEST");
        })
      ).then((data) => {
        this.setState((prev) => {
          return {
            ...prev,
            questionFilesURL: data,
          };
        });
      });
    }

    if (
      question_details["type"] === "record" &&
      !this.props.isTestCreation &&
      response &&
      response[sectionIndex] &&
      response[sectionIndex][index]
    ) {
      this.getUrl(response[sectionIndex][index][0]);
    }

    if (
      question_details["type"] === "checkbox" &&
      question_details["options"]
    ) {
      // console.log("checkdjb", question_details["options"]);
      Promise.all(
        question_details["options"].map((option) => {
          return option.option_file && viewFile(option.option_file, "TEST");
        })
      ).then((data) => {
        this.setState((prev) => {
          return {
            ...prev,
            mcqoptionsFiles: data,
          };
        });
      });
    }

    if (
      question_details["type"] === "file" &&
      !this.props.isTestCreation &&
      response &&
      response[sectionIndex] &&
      response[sectionIndex][index]
    ) {
      this.getUrl(response[sectionIndex][index]);
    }
  }

  arrayEquals(student, teacher) {
    return (
      Array.isArray(student) &&
      Array.isArray(teacher) &&
      student.length === teacher.length &&
      student.every((val, index) => val === teacher[index])
    );
  }

  getUrl(filename) {
    if (filename && filename.length) {
      viewFile(filename, "RESPONSE").then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          answerFilesUrl: res,
        }));
      });
    }
  }

  getCode = (obj) => {
    const sorted = Object.keys(obj).reduce((a, b) =>
      obj[a].length > obj[b].length ? a : b
    );
    return sorted;
  };

  isAudio(fileData) {
    const filename = fileData.split(" ")[0].split(".")[1];
    if (filename === "mp3;") {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const {
      question_details,
      index,
      sectionIndex,
      response,
      marking,
      // UserType,
    } = this.props;
    const acceptFileType = {
      Any: "",
      Image: "image/*",
      Video: "video/*",
      Audio: "audio/*",
      PDFs: ".pdf",
      DOCs: ".doc, .docx",
    };

    let type = question_details["type"];
    let question = question_details["question"];
    let options = question_details["options"];
    let isRequired = question_details["isRequired"];
    let questionFiles = question_details["questionFiles"];
    let fileOptionsSize = question_details["fileOptionsSize"];
    let optionsValueType = question_details["optionsValueType"];
    let ansFileType = question_details["ansFileType"];
    let ans_mcq = question_details["ans_mcq"];
    let optionType = question_details["optionType"];
    let identifier = sectionIndex + "," + index;
    let questionBox = null;

    const isMarking = () => {
      if (
        marking &&
        marking[sectionIndex] !== undefined &&
        marking[sectionIndex][index] !== undefined
      ) {
        return true;
      } else {
        return false;
      }
    };

    let questionFilesBox =
      this.state.questionFilesURL &&
      this.state.questionFilesURL.map((fileurl, index) => {
        const filename = fileurl.split("?")[0];
        const fileType = mime.getType(filename);
        const fileExtn = fileType.split("/")[0];
        if (fileExtn === "image") {
          return (
            <div className="flex" key={index}>
              <img
                alt="fileUrl"
                className="mb-3"
                style={{ maxWidth: "100%" }}
                title="Question File"
                src={fileurl}
              />
            </div>
          );
        } else if (fileExtn === "video") {
          return (
            <div className="flex w-1/3" key={index}>
              <video controls className="mb-3" style={{ maxWidth: "100%" }}>
                <source src={fileurl} />
              </video>
            </div>
          );
        } else if (fileExtn === "audio") {
          return (
            <div className="p-5 w-auto" key={index}>
              <audio controls className="mb-3" style={{ maxWidth: "100%" }}>
                <source src={fileurl} />
              </audio>

              {/* <CustomAudio audioSrc={fileurl} /> */}
            </div>
          );
        } else return <span key={index}></span>;
      });

    const mcqFilesbox = (index, size) => {
      const { mcqoptionsFiles } = this.state;
      if (mcqoptionsFiles && mcqoptionsFiles[index]) {
        const fileurl = mcqoptionsFiles[index];
        const filename = fileurl.split("?")[0];
        const fileType = mime.getType(filename);
        const fileExtn = fileType.split("/")[0];
        if (fileExtn === "image") {
          return (
            <div className="flex" key={index}>
              <img
                alt="fileUrl"
                className={`h-[${size}px] object-contain`}
                title="Question File"
                src={fileurl}
              />
            </div>
          );
        } else if (fileExtn === "video") {
          return (
            <div className="flex w-1/3" key={index}>
              <video controls style={{ maxWidth: "100%" }}>
                <source src={fileurl} />
              </video>
            </div>
          );
        } else if (fileExtn === "audio") {
          return (
            <div className="p-5 w-auto" key={index}>
              <audio controls style={{ maxWidth: "100%" }}>
                <source src={fileurl} />
              </audio>

              {/* <CustomAudio audioSrc={fileurl} /> */}
            </div>
          );
        } else return <span key={index}></span>;
      }
    };

    switch (type) {
      case "description":
        questionBox = (
          <>
            <div className="flex flex-row justify-center">
              {questionFilesBox}
            </div>
            {!this.props.isTestCreation && (
              <div className="form-control">
                {response &&
                  response[sectionIndex] &&
                  response[sectionIndex][index] ? (
                  response[sectionIndex][index].judge_response ? (
                    <>
                      <div>
                        <CodeBlock
                          text={
                            response[sectionIndex][index].judge_response.code
                          }
                          language={
                            response[sectionIndex][index].judge_response
                              .language
                          }
                          showLineNumbers={true}
                          theme={dracula}
                        />
                      </div>


                      <label className="block w-wrap my-2 text-md text-gray-700 border rounded border-gray-400 px-2 py-1">
                        <div className="flex gap-4 items-center">
                          {response[sectionIndex][index].is_correct ? (
                            <span className="text-green-800">
                              All test cases passed
                            </span>
                          ) : (
                            <span className="text-red-700">Wrong Answer</span>
                          )}
                          <img
                            src={
                              response[sectionIndex][index].is_correct
                                ? tick
                                : cross
                            }
                            alt=""
                            className="w-5 h-5"
                          />
                        </div>
                      </label>
                      <p className="text-black text-[1rem]">
                        <span className="font-semibold text-[1.1rem]"> Language:</span>
                        {" "}
                        {
                          Languages[response[sectionIndex][index]?.judge_response.language]?.name
                        }

                      </p>
                    </>
                  ) : (
                    <>
                      {response[sectionIndex][index].code ? (
                        <div>
                          <CodeBlock
                            text={
                              response[sectionIndex][index].code[
                              this.getCode(response[sectionIndex][index].code)
                              ]
                            }
                            language={this.getCode(
                              response[sectionIndex][index].code
                            )}
                            showLineNumbers={true}
                            theme={dracula}
                          />
                        </div>
                      ) : (
                        <>
                          {response[sectionIndex][index]?.text !== " " && (
                            <MyQuillEditor
                              readOnly={true}
                              type={this.props.type}
                              value={response[sectionIndex][index]?.text || ""}
                            />
                          )}
                        </>
                      )}
                    </>
                  )
                ) : (
                  <textarea
                    style={{ resize: "none" }}
                    rows={3}
                    cols={3}
                    className="p-2 border border-gray-300 w-full font-semibold"
                    placeholder="No Response From Student"
                    disabled
                  ></textarea>
                )}
              </div>
            )}
          </>
        );
        break;
      case "integer":
        questionBox = (
          <>
            <div className="flex flex-row justify-center">
              {questionFilesBox}
            </div>
            <div className="form-control my-2">
              {!this.props.isTestCreation &&
                response &&
                response[sectionIndex] &&
                response[sectionIndex][index] ? (
                <input
                  type="text"
                  placeholder="Your answer here"
                  value={response?.[sectionIndex]?.[index]}
                  disabled
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded focus:ring-blue-500 focus:border-blue-500 block px-2 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              ) : (
                <textarea
                  style={{ resize: "none" }}
                  rows={3}
                  cols={3}
                  className="p-2 border border-gray-300 w-full font-semibold"
                  placeholder="No Response From Student"
                  disabled
                ></textarea>
              )}
            </div>
          </>
        );
        break;

      case "coding":
        questionBox = (
          <>
            <div className="flex flex-row justify-center">
              {questionFilesBox}
            </div>
            {!this.props.isTestCreation && (
              <div className="form-control">
                {response &&
                  response[sectionIndex] &&
                  response[sectionIndex][index] ? (
                  response[sectionIndex][index].judge_response ? (
                    <>
                      <div>
                        <CodeBlock
                          text={
                            response[sectionIndex][index].judge_response.code
                          }
                          language={
                            response[sectionIndex][index].judge_response
                              .language
                          }
                          showLineNumbers={true}
                          theme={dracula}
                        />
                      </div>
                      <label className="block w-wrap my-2 text-md text-gray-700 border rounded border-gray-400 px-2 py-1">
                        <div className="flex gap-4 items-center">
                          {response[sectionIndex][index].is_correct ? (
                            <span className="text-green-800">
                              All test cases passed
                            </span>
                          ) : (
                            <span className="text-red-700">Wrong Answer</span>
                          )}

                          <img
                            src={
                              response[sectionIndex][index].is_correct
                                ? tick
                                : cross
                            }
                            alt=""
                            className="w-5 h-5"
                          />
                        </div>
                      </label>
                    </>
                  ) : (
                    <>
                      {response[sectionIndex][index].code && (
                        <div>
                          <CodeBlock
                            text={
                              response[sectionIndex][index].code[
                              this.getCode(response[sectionIndex][index].code)
                              ]
                            }
                            language={this.getCode(
                              response[sectionIndex][index].code
                            )}
                            showLineNumbers={true}
                            theme={dracula}
                          />

                        </div>
                      )}
                    </>
                  )
                ) : (
                  <textarea
                    style={{ resize: "none" }}
                    rows={3}
                    cols={3}
                    className="p-2 border border-gray-300 w-full font-semibold"
                    placeholder="No Response From Student"
                    disabled
                  ></textarea>
                )}
              </div>
            )}
          </>
        );
        break;

      case "sql":
        questionBox = (
          <>
            <div className="flex flex-row justify-center">
              {questionFilesBox}
            </div>
            {response &&
              response[sectionIndex] &&
              response[sectionIndex][index] ? (
              <>
                <div className="flex gap-x-4">
                  <span>Query:</span>
                  {response[sectionIndex][index]?.query}
                </div>
                <div className="flex gap-x-4">
                  <span>Verdict:</span>
                  <span
                    className={`font-bold rounded text-white bg-${response[sectionIndex][index]?.verdict ? "green" : "red"
                      }-700 px-2`}
                  >
                    {response[sectionIndex][index]?.verdict ? "True" : "False"}
                  </span>
                </div>
              </>
            ) : (
              <span className="font-semibold"> No Response From Student</span>
            )}
          </>
        );
        break;

      case "checkbox":
        questionBox = (
          <>
            {/* <p className="text-muted mb-2 small">Pick the correct option.</p> */}
            <div className="flex justify-center ">{questionFilesBox}</div>
            {options &&
              options.map((option, idx) => {
                return (
                  <div
                    className="w-full flex my-1 items-center gap-3 p-1"
                    key={`option-${sectionIndex}-${index}-${idx}`}
                  >
                    <div className="w-[1%] mr-2">
                      <input
                        className="w-4 h-4"
                        type={optionType}
                        name={`option-${sectionIndex}-${index}-${idx}`}
                        checked={response?.[sectionIndex]?.[index]?.[idx]}
                        disabled
                      />
                    </div>
                    <div
                      className={`border border-gray-400  ${ans_mcq[idx]
                          ? "bg-[#EEFECF]"
                          : response?.[sectionIndex]?.[index]?.[idx]
                            ? "bg-red-200"
                            : "bg-gray-200"
                        }  px-3 py-1.5 w-[40%]`}
                    >
                      {optionsValueType === "file" ? (
                        <>
                          <div className="flex justify-start">
                            {mcqFilesbox(idx, fileOptionsSize)}
                          </div>
                        </>
                      ) : (
                        <label
                          className="block text-sm font-medium text-gray-900"
                          dangerouslySetInnerHTML={{
                            __html: option.name,
                          }}
                        ></label>
                      )}
                    </div>
                    <div className="flex gap-4 items-center justify-center">
                      {
                        ans_mcq[idx] ? (
                          <span className="text-[#3DEA00] text-[0.9rem] font-['Inter'] font-light">
                            Correct Option
                          </span>
                        ) : null
                        // (
                        //   <span className="text-[#FF1300] text-[0.9rem] font-['Inter'] font-light">
                        //     Wrong Option
                        //   </span>
                        // )
                      }
                    </div>
                  </div>
                );
              })}
            {!this.props.isTestCreation && (
              <div className="">
                Options Selected by student:
                {response &&
                  response[sectionIndex] &&
                  response[sectionIndex][index] ? (
                  <>
                    <ul>
                      {response[sectionIndex] &&
                        response[sectionIndex][index].map(
                          (answer, index) =>
                            answer === true && (
                              <li key={index} className="list-disc ml-5">
                                {`${ordinal(index + 1)} option`}
                              </li>
                            )
                        )}
                    </ul>
                  </>
                ) : (
                  <span className="font-semibold">
                    {" "}
                    No Response From Student
                  </span>
                )}
              </div>
            )}
          </>
        );
        break;
      case "record":
        questionBox = (
          <>
            <div className="flex flex-row justify-center">
              {questionFilesBox}
            </div>
            {!this.props.isTestCreation && (
              <>
                {response &&
                  response[sectionIndex] &&
                  response[sectionIndex][index] ? (
                  <Media>
                    <div className="media">
                      <div className="media-player">
                        {!this.isAudio(response[sectionIndex][index][0]) ? (
                          <>
                            <video
                              controls
                              src={this.state.answerFilesUrl}
                              className="w-[350xpx] h-[250px]"
                            />
                          </>
                        ) : (
                          <>
                            <audio
                              controls
                              src={this.state.answerFilesUrl}
                              className="my-2"
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </Media>
                ) : (
                  <span
                    className="font-semibold"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    {" "}
                    No Response from Student
                  </span>
                )}
              </>
            )}
          </>
        );
        break;
      case "file":
        questionBox = (
          <>
            <p className="text-muted mb-1 small">
              Upload the required file type. Allowed: {ansFileType}
            </p>
            <div className="flex flex-row justify-center">
              {questionFilesBox}
            </div>
            {!this.props.isTestCreation && (
              <>
                {response &&
                  response[sectionIndex] &&
                  response[sectionIndex][index] ? (
                  <button
                    className="py-1 px-3 rounded bg-cyan-500 text-white font-bold"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState((prev) => {
                        return {
                          ...prev,
                          fileModal: true,
                        };
                      });
                    }}
                  >
                    View File
                  </button>
                ) : (
                  <span className="font-semibold">
                    {" "}
                    No response from student
                  </span>
                )}
              </>
            )}
          </>
        );
        break;
      default:
        questionBox = <></>;
    }

    return (
      <>
        <div>
          <div className="flex flex-col md:flex-row lg:gap-[1rem] items-start">
            {this.props.type !== "E" ? (
              <div className="flex gap-2 py-2 items-center w-[10rem]">
                <p className="text-[20px] font-bold">Question {index + 1}.</p>
                {!this.props.isTestCreation && (
                  <>
                    <img
                      src={question_details.is_correct ? tick : cross}
                      alt=""
                      className="w-4 h-4"
                    />
                    {/* {question_details.is_correct ? (
                  <span className="text-green-800">Correct Answer</span>
                ) : (
                  <span className="text-red-700">Wrong Answer</span>
                )} */}
                  </>
                )}
              </div>
            ) : null}
            <div className="flex-1 w-[100%]">
              <div className="flex py-2">
                <h5
                  className="font-medium font-['nunito]"
                  dangerouslySetInnerHTML={{
                    __html: question,
                  }}
                ></h5>
                {isRequired && <span className="text-danger ml-1">*</span>}
              </div>
              {questionBox}
            </div>
          </div>
          {/* {console.log("check", question_details)} */}
          {this.props.type !== "E" && !this.props.isTestCreation && (
            <div className="flex gap-x-4 font-['poppins'] md:pl-[11rem] mt-3 items-end">
              <div
                className="flex py-1  items-center justify-center gap-1 px-3"
                style={{
                  border: `1px solid ${question_details.is_correct ? "#3DEA00" : "#FF1300"
                    }`,
                }}
              >
                <h6>
                  Score :{" "}
                  <span>
                    {isFloat(question_details?.score)
                      ? parseFloat(question_details?.score).toFixed(2)
                      : question_details?.score}/{question_details?.marks}
                  </span>
                </h6>
              </div>
              {question_details?.type === "description" && (
                <div
                  className="flex py-1  items-center justify-center gap-1 px-2"
                  style={{
                    border: `1px solid ${question_details?.grade ? "#3DEA00" : "#FF1300"
                      }`,
                  }}
                >
                  {/* {console.log("ccc", question_details)} */}
                  <h6>
                    Grade :{" "}
                    <span>
                      {question_details?.grade
                        ? question_details?.grade
                        : "Not Assigned"}
                    </span>
                  </h6>
                </div>
              )}
              {/* <div className="flex flex-col items-center text-center mt-1 w-[120px]">
              <label>
                <h6>LLM used</h6>
              </label>
              <p className="bg-red-300 w-full py-1">90%</p>
            </div>
            <div className="flex flex-col items-center text-center mt-1 w-[120px]">
              <label>
                <h6>Human effort</h6>
              </label>
              <p className="bg-red-300 w-full py-1">10%</p>
            </div> */}
            </div>
          )}
        </div>

        <Modal
          onClose={(e) => {
            e.preventDefault();
            this.setState((prev) => {
              return {
                ...prev,
                fileModal: false,
              };
            });
          }}
          isModal={this.state.fileModal}
          showCloseButton
          className="md:h-[80%] h-[45%] w-[92%] xl:w-[60%]"
        >
          <ViewFileModal file={this.state.answerFilesUrl} />
        </Modal>
      </>
    );
  }
}
