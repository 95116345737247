import React from "react";
// Store
import { connect } from "react-redux";
import {
  updateCodeEditorInput,
  runCodeRequest,
  submitCodeSuccess,
  updateEditorState,
  fetchCodeRunStatusSuccess,
  clearCodeRunStatus,
} from "../../../../store/actions/codeeditor";
import load from "../../../../assets/img/svg/runload.svg";
import { TemplateCode } from "../../../../components/tests/codeEditor/Utils/Constants";
import { updateTestResponse } from "../../../../store/actions/test";
import {
  codeStatus,
  submitCode,
} from "../../../../services/codeeditor.service";
import { getType, getUserIdFromPeerId } from "../../../common/utils";
import {
  updateCurrentQuestionScoreRequested,
  updateInterviewResponseRequested,
} from "../../../../store/actions/interviewpanel";
import { ReactComponent as Passed } from "../../../../assets/img/svg/edit.svg";
import { ReactComponent as Failed } from "../../../../assets/img/svg/wrong.svg";
import "../../../../index.css";

class Results extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      operationtype: null,
      statusCount: 0,
      iscustomInput: false,
      selectedTestCases: 0,
    };
  }

  componentDidMount() {
    this.setDefaultTestCases();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.toggleSocket !== this.props.toggleSocket) {
      this.run(this.props.EditorRunType, false);
    }
    if (!prevProps.run_token && this.props.run_token) {
      this.checkSubmissionRequest();
    }

    if (this.state.statusCount > 6 || this.props.results) {
      if (this.state.statusCount > 4) {
        this.props.updateEditorState(false);
        this.props.clearCodeRunStatus();
      }
    }
  }

  handleCustomInput(e) {
    const { iscustomInput } = this.state;
    if (!iscustomInput) {
      this.props.updateCodeEditorInput("");
    } else {
      this.setDefaultTestCases();
    }
    this.setState(
      (prev) => {
        return {
          ...prev,
          iscustomInput: !prev.iscustomInput,
        };
      },
      () => this.setDefaultTestCases()
    );
  }

  setDefaultTestCases() {
    const { iscustomInput } = this.state;
    if (!iscustomInput) {
      const test_cases = this.props.question?.coding_testcases;
      this.props.updateCodeEditorInput(test_cases);
    } else {
      this.props.updateCodeEditorInput("");
    }
  }

  checkSubmissionRequest = async () => {
    for (let i = 0; i < 6; i++) {
      if (this.state.operationtype === "RUN") {
        const code_results = await this.checkJudgeSubmission();
        if (code_results.waiting) {
          // If still waiting, wait for 2 more seconds
          await new Promise((r) => setTimeout(r, 2000));
        } else if (
          code_results.judge_response.status &&
          ![1, 2].includes(code_results.judge_response.status.id)
        ) {
          this.props.fetchCodeRunStatusSuccess(code_results);
          break;
        }
      } else {
        const code_results = await this.submit();
        if (code_results.waiting) {
          // If still waiting, wait for 2 more seconds
          await new Promise((r) => setTimeout(r, 2000));
        } else {
          this.props.submitCodeSuccess(code_results);
          if (this.props.type === "I") {
            this.props.updateInterviewResponseRequested({
              sectionIndex: code_results.sectionIndex,
              questionIndex: code_results.questionIndex,
              value: code_results,
            });
            if (code_results.is_correct && getType() === "P") {
              this.props.updateCurrentQuestionScoreRequested({
                data: {
                  key: "score",
                  value:
                    this.props.interviewDetails?.components[
                      code_results.sectionIndex
                    ]?.components[code_results.questionIndex]?.marks,
                  section_index: code_results.sectionIndex,
                  question_index: code_results.questionIndex,
                },
                id: this.props.testId,
              });
            }
          } else {
            this.props.updateTestResponse({
              sectionIndex: code_results.sectionIndex,
              questionIndex: code_results.questionIndex,
              value: code_results,
            });
          }

          break;
        }
      }
    }
  };

  checkJudgeSubmission = () => {
    if (!this.props.run_token) {
      return;
    }

    return codeStatus({ token: this.props.run_token });
  };

  run = (type, isSelf) => {
    this.setState((prev) => {
      return {
        ...prev,
        operationtype: type,
        statusCount: 0,
      };
    });
    const { sectionId, questionId, codeResponse, language, testId, stdin } =
      this.props;
    const question_codeResponse =
      codeResponse &&
        codeResponse[sectionId] &&
        codeResponse[sectionId][questionId]
        ? codeResponse[sectionId][questionId]
        : null;
    const upload = Boolean(question_codeResponse)
      ? question_codeResponse
      : TemplateCode;
    this.props.updateEditorState(true);
    if (isSelf && this.props.type === "I" && this.props.interviewerPeerId) {
      this.props.socket.emit("run-code-editor", {
        type,
        userId: getUserIdFromPeerId(this.props.interviewerPeerId),
      });
    }
    this.props.runCodeRequest({
      sectionId: sectionId,
      questionId: questionId,
      testId: testId,
      code: upload,
      lang: language,
      inp: stdin,
      type,
      kind: this.props.type,
    });
  };

  submit = () => {
    const { sectionId, questionId, codeResponse, language, testId, stdin } =
      this.props;
    const question_codeResponse =
      codeResponse &&
        codeResponse[sectionId] &&
        codeResponse[sectionId][questionId]
        ? codeResponse[sectionId][questionId]
        : null;
    const upload = Boolean(question_codeResponse)
      ? question_codeResponse
      : TemplateCode;
    return submitCode({
      sectionId: sectionId,
      questionId: questionId,
      testId: testId,
      code: upload,
      lang: language,
      inp: stdin,
      token: this.props.run_token,
      kind: this.props.type,
    });
  };

  debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  sendSocketEvent = this.debounce((value) =>
    this.props.socket.emit("update-code-editor-input-state", {
      state: value,
      userId: getUserIdFromPeerId(this.props.interviewerPeerId),
    })
  );

  updateCodeEditorInputState = (e) => {
    this.props.updateCodeEditorInput(e.target.value);
    if (this.props.type === "I" && this.props.interviewerPeerId) {
      this.sendSocketEvent(e.target.value);
    }
  };

  render() {
    const { operationtype } = this.state;
    const { sectionId, questionId, type, response } = this.props;
    const question_response =
      response && response[sectionId] && response[sectionId][questionId]
        ? response[sectionId][questionId]
        : null;
    return (
      <div className="pr-3 my-1">
        {question_response && question_response.judge_response && (
          <h3 className="text-md font-bold">Verdict: </h3>
        )}
        {question_response && question_response.judge_response ? (
          <div
            id="alert-additional-content-1"
            className={`p-2 my-2 rounded-lg bg-${question_response.is_correct ? "green" : "red"
              }-300`}
            role="alert"
          >
            <div className="my-2 text-sm break-words rounded-lg">
              <div className="text-left ">
                <b className="inline-flex">
                  Code submitted: &nbsp;&nbsp;
                  {this.state.operationtype === "SUBMIT" &&
                    this.props.active &&
                    !this.props.results ? (
                    <img
                      alt="loading"
                      src={load}
                      className="spinner mx-1 w-4 h-4"
                    />
                  ) : (
                    question_response.judge_response.compile_status
                  )}
                </b>
              </div>
            </div>
          </div>
        ) : null}
        <div>
          <textarea
            rows="4"
            className="block p-2.5 w-full text-sm rounded-lg border  bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
            placeholder="Enter Your Input"
            value={this.props.stdin}
            onChange={(e) => this.updateCodeEditorInputState(e)}
          />
        </div>

        <div className="flex justify-start items-start">
          {this.state.operationtype === "SUBMIT" ? (
            this.props.active && !this.props.results ? (
              <div className="w-[100%] h-[9rem] mr-2 flex flex-col justify-center items-center gap-2">
                <h1>Processing</h1>
                <img
                  alt="loading"
                  src={load}
                  className="spinner mx-1 w-4 h-4"
                />
              </div>
            ) : question_response?.judge_responses?.length ? (
              <>
                <div className="mt-3 w-[25%] md:w-[17%] lg:w-[16%] h-[11rem] overflow-auto scrollbar border-none outline-none cursor-pointer bg-[#111827]">
                  {question_response?.judge_responses?.map((res, ind) => (
                    <div
                      className={`flex justify-center gap-4 py-3 items-center ${this.state.selectedTestCases === ind
                        ? "bg-[#1D2432]"
                        : null
                        } `}
                      onClick={() => {
                        this.setState((prev) => {
                          return { ...prev, selectedTestCases: ind };
                        });
                      }}
                    >
                      {res?.status?.description !== "Accepted" ? (
                        <Failed fill="#fc0303" style={{ color: "#fc0303" }} />
                      ) : (
                        <Passed fill="#1BA94C" style={{ color: "#1BA94C" }} />
                      )}
                      <h1
                        className={`text-[#1BA94C] ${res?.status?.description !== "Accepted"
                          ? "text-[#fc0303]"
                          : null
                          }`}
                      >
                        Test case {1 + ind}
                      </h1>
                    </div>
                  ))}
                </div>
                <div className="mt-3 h-[11rem] flex-1 border-none outline-none">
                  {question_response?.judge_responses?.length &&
                    this.state.operationtype === "SUBMIT" &&
                    this.state.selectedTestCases !== -1 ? (
                    <div className="block p-2.5 w-full h-[100%] bg-[#1D2432] border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
                      <h1 className="w-[97%] pt-2 pl-1 pb-1 m-auto text-[#fcf7f7]">
                        Compiler Message
                      </h1>
                      <h1 className="bg-[#080808] w-[97%] p-2 rounded m-auto text-[#fff]">
                        {
                          question_response?.judge_responses?.[
                            this.state.selectedTestCases
                          ].status?.description
                        }
                      </h1>
                    </div>
                  ) : null}
                </div>
              </>
            ) : null
          ) : null}
        </div>

        {this.props.active && operationtype === "RUN" ? (
          <div className="mt-2 h-[40%]">
            {!this.props.results ? (
              <div
                id="alert-additional-content-1"
                className="p-2 mb-2 bg-blue-100"
                role="alert"
              >
                <div className="mt-2 mb-4 text-sm break-words">
                  <div className="text-left">
                    Getting Results.. Your Output will appear here
                  </div>
                </div>
              </div>
            ) : null}
            {this.props.results && this.props.results.stdout ? (
              <div
                id="alert-additional-content-1"
                className="mb-4 "
                role="alert"
              >
                <textarea
                  rows="4"
                  className="block p-2.5 w-full text-sm border bg-green-100"
                  value={atob(this.props.results.stdout)}
                  readOnly
                />
              </div>
            ) : null}
            {this.props.results && this.props.results.stderr ? (
              <div
                id="alert-2"
                className="flex p-4 mb-4 bg-red-100"
                role="alert"
              >
                <pre className="ml-3 text-sm font-medium text-red-700 ">
                  Error: <br />
                  {atob(this.props.results.stderr)}
                </pre>
              </div>
            ) : null}
            {this.props.results && this.props.results.compile_output ? (
              <div
                id="alert-2"
                className="flex p-4 mb-4 bg-red-100 dark:bg-red-200"
                role="alert"
              >
                <pre className="ml-3 w-full text-sm font-medium text-red-700 max-h-[200px] overflow-y-auto">
                  Compilation Error: <br />
                  {atob(this.props.results.compile_output)}
                </pre>
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="mt-2">
          <div className="flex gap-2 justify-start">
            <button
              type="button"
              onClick={() => this.run("RUN", true)}
              className={`inline-flex items-center rounded-lg text-sm px-6 pt-2 pb-1 focus:ring-gray-600  font-semibold uppercase font-['nunito'] ${this.props.active && !this.props.results
                ? "bg-gray-300 text-gray-800"
                : "bg-gray-800 text-gray-100"
                }  text-white hover:bg-gray-700 `}
              disabled={this.props.active && !this.props.results}
            >
              {this.props.active &&
                !this.props.results &&
                this.state.operationtype === "RUN" ? (
                // <img
                //   alt="loading"
                //   src={load}
                //   className="spinner mx-1 w-4 h-4" />
                <div className="px-6">
                  <div className="dot-typing"></div>
                </div>
              ) : (
                <div>Run Code</div>
              )}
            </button>
            {/* <div className="dot-typing"></div> */}

            <button
              type="button"
              onClick={() => this.run("SUBMIT", true)}
              className="inline-flex bg-[#2dbb5d] text-white font-semibold uppercase pt-2 pb-1 px-4 rounded outline-none focus:outline-none text-sm font-['nunito']"
              disabled={this.props.active && !this.props.results}
            >
              {this.props.active &&
                !this.props.results &&
                this.state.operationtype === "SUBMIT" ? (
                // <img
                //   alt="loading"
                //   src={load}
                //   className="spinner mx-1 w-4 h-4" />
                <div className="px-6">
                  <div className="dot-typing"></div>
                </div>
              ) : (
                <div>Submit</div>
              )}
            </button>

            <div className="flex gap-x-1 m-2">
              <input
                type="checkbox"
                value={this.state.iscustomInput}
                onChange={(e) => this.handleCustomInput(e)}
              />
              <label className="text-sm font-['nunito']">Custom Input</label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ codeeditor, test, interviewpanelReducer }) => {
  return {
    stdin: codeeditor.stdin,
    code: codeeditor.code,
    language: codeeditor.language,
    results: codeeditor.results,
    test: test.test,
    codeResponse: test.codeResponse,
    active: codeeditor.editorState,
    run_token: codeeditor.run_token,
    interviewerPeerId: interviewpanelReducer.interviewerPeerId,
    interviewDetails: interviewpanelReducer.interviewDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCodeEditorInput: (data) => dispatch(updateCodeEditorInput(data)),
    runCodeRequest: (data) => dispatch(runCodeRequest(data)),
    submitCodeSuccess: (data) => dispatch(submitCodeSuccess(data)),
    updateTestResponse: (data) => dispatch(updateTestResponse(data)),
    updateEditorState: (data) => dispatch(updateEditorState(data)),
    fetchCodeRunStatusSuccess: (data) =>
      dispatch(fetchCodeRunStatusSuccess(data)),
    clearCodeRunStatus: () => dispatch(clearCodeRunStatus()),
    updateInterviewResponseRequested: (data) =>
      dispatch(updateInterviewResponseRequested(data)),
    updateCurrentQuestionScoreRequested: (data) =>
      dispatch(updateCurrentQuestionScoreRequested(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Results);
