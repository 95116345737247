import React, { Component } from "react";
import { connect } from "react-redux";
import { ReactComponent as Send } from "../../../assets/img/svg/send.svg";
import dayjs from "dayjs";
import { chat_prompts } from "../../../assets/js/chat_prompts";
import load from "../../../assets/img/svg/load.svg";
import { withRouter } from "../../common/WithRouter";

export class StudentChat extends Component {
  constructor() {
    super();
    this.state = {
      leftClick: 0,
      rightClick: 0,
    };
    this.messageFormat = this.messageFormat.bind(this);
  }

  messageFormat = (timestamp) => {
    return dayjs(timestamp).format("HH:mm");
  };

  handleLeftSlide = () => {
    const slider = document.getElementById("chat_prompts");
    console.log(slider.offsetWidth);
    slider.scrollLeft -= slider.offsetWidth;
    if (this.state.leftClick !== 0) {
      this.setState((prevState) => ({
        leftClick: prevState.leftClick - 1,
      }));
    }
  };

  handleRightSlide = () => {
    const slider = document.getElementById("chat_prompts");
    console.log(slider.offsetWidth);
    slider.scrollLeft += slider.offsetWidth;
    this.setState((prevState) => ({
      leftClick: prevState.leftClick + 1,
    }));
  };

  render() {
    return (
      <div className="flex font-['nunito']  w-full">
        {(this.props.test.is_proctoring || this.props.isInterviewChat) && (
          <div
            className={`fixed flex-col py-1 rounded-t-xl right-2 bottom-0 items-center border border-[#B8FB3C] bg-white ${this.props.showModal
              ? "w-[25%] min-w-[280px]"
              : "w-[20%] min-w-[200px]"
              }`}
          >
            <div
              className="flex items-center justify-between sm:py-2 py-1 hover:cursor-pointer  px-2"
              onClick={() => this.props.handleChatModal()}
            >
              <div className="justify-start flex cursor-pointer items-center">
                <p className="w-4 h-4 mx-2 bg-[#B8FB3C] rounded-full"></p>
                {/* <ChatColor className="w-6 h-6 mx-2" color={"#BCFB46"} /> */}
                <div className="pl-1 font-['poppins'] font-medium text-lg text-[#333333]">
                  Messaging
                  {this.props.notify ? (
                    <span className="animate-ping ml-2 w-3 text-sm bg-red-600 text-white px-2 rounded-2xl">
                      {this.props.unseenMessageCount}
                    </span>
                  ) : null}
                </div>
              </div>
              <div>
                <svg
                  data-accordion-icon
                  className={`w-6 h-6 shrink-0 ${!this.props.showModal && "rotate-180"
                    }`}
                  fill="#B8FB3C"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                {/* <img
                  src={this.props.showModal ? downarrow : uparrow}
                  alt="open"
                  title="open"
                  className="w-6 h-6 mx-2"
                /> */}
              </div>
            </div>
            {this.props.showModal ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="bg-transparent w-[100%] h-[55vh] flex-col border-t border-[#B8FB3C] scrollClass px-2"
                    style={{
                      overflowY: "scroll",
                    }}
                  >
                    {this.props.messages &&
                      this.props.messages.map((eachMsg) => {
                        return (
                          // chat box - conditions are repeating
                          <div
                            className="my-2"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: `${eachMsg.from === this.props.uniqueUserId ||
                                eachMsg.from._id === this.props.uniqueUserId
                                ? "flex-end"
                                : "flex-start"
                                }`,
                            }}
                          >
                            <div
                              className={`rounded-lg max-w-[300px]  px-[8px] py-[5px] ${eachMsg.from === this.props.uniqueUserId ||
                                eachMsg.from._id === this.props.uniqueUserId
                                ? "bg-[#B8FB3C]"
                                : "bg-[#F2F7FB]"
                                }`}
                            >
                              <p className="break-all">{eachMsg.message}</p>
                              <p
                                className={`text-right text-[10px] text-[#666668]`}
                              >
                                {this.messageFormat(eachMsg.created_at)}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    <div
                      style={{ float: "left", clear: "both" }}
                      ref={this.props.scrollRef}
                    ></div>
                  </div>
                  {this.props.proctorConnected ? (
                    <div
                      id="chat_prompts"
                      style={{ scrollBehavior: "smooth" }}
                      className="relative bg-white font-['poppins'] py-[8px]  border-t border-b border-[#B8FB3C]  flex overflow-x-scroll "
                    >
                      <div
                        onClick={() => this.handleLeftSlide()}
                        className={`${this.state.leftClick === 0 ? "hidden" : "block"
                          } sticky left-0 bg-white px-1 z-10 cursor-pointer`}
                      >
                        <p className="font-black text-xl"> {`<`} </p>
                      </div>
                      {chat_prompts.map((chat_prompt) => (
                        <div
                          onClick={(e) =>
                            this.props.handleChatPrompt(chat_prompt)
                          }
                          value="Chat Prompt"
                          className="bg-gray-300 flex justify-center items-center hover:bg-gray-400 transition px-[4px] py-[2px] mx-2 text-[10px] min-w-[160px] min-h-[30px] rounded-md opacity-80 font-semibold cursor-pointer"
                        >
                          {chat_prompt}
                        </div>
                      ))}
                      <div
                        onClick={() => this.handleRightSlide()}
                        className="sticky right-0 bg-white px-1 z-10 cursor-pointer"
                      >
                        <p className="font-black text-xl"> {`>`} </p>
                      </div>
                    </div>
                  ) : null}

                  <div className="flex sendMsg my-2 ">
                    {this.props.proctorConnected ? (
                      <>
                        <input
                          type="text"
                          placeholder="Type your message here..."
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              this.props.handleSendingMessage();
                            }
                          }}
                          className="bg-transparent w-[90%] outline-none placeholder:italic placeholder:text-sm
                                  rounded placeholder-slate-500 placeholder-opacity-75 px-4 py-1"
                          onChange={(e) =>
                            this.props.handleChatPrompt(e.target.value)
                          }
                          value={this.props.inputMessage}
                        />
                        <button
                          onClick={() => this.props.handleSendingMessage()}
                          disabled={this.props.chatMessageLoader}
                          className="flex justify-center items-center p-2 mr-1"
                        >
                          {!this.state.chatMessageLoader ? (
                            <Send color={"#B7B7B7"} className="cursor-pointer" />
                          ) : (
                            <img
                              alt="loading"
                              src={load}
                              className="spinner w-4 h-4"
                            />
                          )}
                        </button>
                      </>
                    ) : (
                      <div className="flex text-center justify-center items-center">
                        <span className="mx-auto text-center pl-4">
                          No Proctor Connected
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    test: test.test,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentChat));
