import React, { useState } from "react";
import { useEffect } from "react";
import {
  getCheatingFlags,
  getProctoringFlags,
} from "../../../../services/proctor.service";

const ProctoringUserFlags = ({
  proctoring_flags,
  cheating_flags,
  handleFlagsUpdate,
}) => {
  const [proctoringflag, setProctoringFlags] = useState([]);
  const [cheatingFlags, setCheatingFlags] = useState([]);
  const [proctoringFlagsActive, setProctoringFlagsActive] = useState(false);
  const [cheatingFlagsActive, setCheatingFlagsActive] = useState(false);

  useEffect(() => {
    getProctoringFlags()
      .then((res) => setProctoringFlags(res))
      .catch((err) => console.log(err));

    getCheatingFlags()
      .then((res) => setCheatingFlags(res))
      .catch((err) => console.log(err));

    document.addEventListener("click", function (e) {
      const div = document.getElementById("dropdownSearchButton");

      if (div) {
        if (!div.contains(e.target)) {
          setCheatingFlagsActive(false);
          setProctoringFlagsActive(false);
        }
      }
    });
  }, []);

  return (
    <div
      className="flex flex-wrap justify-between items-stretch gap-2"
      id="dropdownSearchButton"
    >
      <div className="relative " id="dropdownSearchButton">
        <FlagsTitle
          title={"Proctoring"}
          setProctoringFlagsActive={setProctoringFlagsActive}
          setCheatingFlagsActive={setCheatingFlagsActive}
        />

        {proctoringFlagsActive ? (
          <ul
            className="overflow-y-auto absolute bg-white h-auto text-sm text-gray-700 dark:text-gray-200"
            style={{
              boxShadow: "5px 5px 24px rgba(41,43,88,0.12)",
              borderRadius: "0 0 10px 10px",
            }}
          >
            {proctoringflag.map((flag) => {
              return (
                <>
                  <li>
                    <div className="flex items-center p-2 rounded hover:bg-gray-100 cursor-pointer">
                      <input
                        type="checkbox"
                        name={flag.name}
                        id={flag._id}
                        value={flag._id}
                        onClick={() => handleFlagsUpdate(0, flag._id)}
                        checked={
                          proctoring_flags.findIndex(
                            (eachFlag) => eachFlag === flag._id
                          ) !== -1
                        }
                        className=" w-4 h-4 border border-gray-500 bg-white checked:bg-[#BCFB46] checked:border-[#87CE01] rounded"
                      />
                      <label
                        htmlFor={flag._id}
                        className="ml-2 w-full text-sm font-medium cursor-pointer text-gray-500"
                      >
                        {flag.name}
                      </label>
                    </div>
                  </li>
                </>
              );
            })}
          </ul>
        ) : null}
      </div>
      <div className="relative " id="dropdownSearchButton">
        <FlagsTitle
          title={"Cheating"}
          setCheatingFlagsActive={setCheatingFlagsActive}
          setProctoringFlagsActive={setProctoringFlagsActive}
        />

        {cheatingFlagsActive ? (
          <ul
            className="overflow-y-auto absolute h-auto text-sm bg-white text-gray-700 dark:text-gray-200"
            style={{
              boxShadow: "5px 5px 24px rgba(41,43,88,0.12)",
              borderRadius: "0 0 10px 10px",
            }}
          >
            {cheatingFlags.map((flag) => {
              return (
                <>
                  <li>
                    <div className="flex items-center p-2 rounded  cursor-pointer hover:bg-gray-200">
                      <input
                        type="checkbox"
                        name={flag.name}
                        id={flag._id}
                        value={flag._id}
                        onClick={() => handleFlagsUpdate(1, flag._id)}
                        checked={
                          cheating_flags.findIndex(
                            (eachFlag) => eachFlag === flag._id
                          ) !== -1
                        }
                        className="w-4 h-4 text-blue-600 bg-gray-100  cursor-pointer rounded border-gray-300 "
                      />
                      <label
                        htmlFor={flag._id}
                        className="ml-2 w-full text-sm font-medium  cursor-pointer text-gray-500 rounded"
                      >
                        {flag.name}
                      </label>
                    </div>
                  </li>
                </>
              );
            })}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default ProctoringUserFlags;

export const FlagsTitle = ({
  title,
  setProctoringFlagsActive,
  setCheatingFlagsActive,
}) => {
  return (
    <button
      data-dropdown-toggle="dropdownSearch"
      className="inline-flex items-center py-2 px-4 text-sm font-medium text-center text-[#001437]  bg-[#BCFB46] rounded-lg hover:bg-[#b3fc28]"
      type="button"
      onClick={() => {
        if (title === "Proctoring") {
          setProctoringFlagsActive((prev) => !prev);
          setCheatingFlagsActive(false);
        } else {
          setProctoringFlagsActive(false);
          setCheatingFlagsActive((prev) => !prev);
        }
      }}
    >
      {`${title} Flags`}
      <svg
        className="ml-2 w-4 h-4"
        aria-hidden="true"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"
        ></path>
      </svg>
    </button>
  );
};
