// import { nanoid } from "nanoid";

export default function authHeader() {
  const name = localStorage.getItem("priksha_name");
  const token = localStorage.getItem("priksha_token");
  const type = localStorage.getItem("priksha_type");
  const user = { name, type, token };
  // const { id } = nanoid();
  if (user && user.token) {
    return {
      "x-auth-token": user.token,
      "x-referer-sec-bool": 1,
    };
  } else {
    return {};
  }
}

export function resumeHeader() {
  const Authorization = localStorage.getItem("resume_token");

  if (Authorization) return { Authorization };
  else return {};
}
