import React from "react";

const Dashboard = (props) => {
  return (
    <div className="flex-col md:p-8 p-3 sm:p-5 lg:p-10">
      <p className="text-2xl font-bold">Welcome {props.user.name}</p>
      {/* <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5 text-center pt-5">
        <div className="flex justify-between px-6 items-center shadow-md rounded p-3 mr-10 bg-purple-100">
          <p className="text-slate-600 text-xl px-2 font-medium">
            Applications Sent
          </p>
          <p className="text-[30px] font-medium">10</p>
        </div>
        <div className="flex justify-between px-6 items-center shadow-md rounded p-3  mr-10 bg-orange-100">
          <p className="text-slate-600 text-xl px-2 font-medium">Tests Given</p>
          <p className="text-[30px] font-medium">5</p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5 text-center pt-5">
        <div className="flex justify-between px-6 items-center shadow-md rounded p-3  mr-10 bg-cyan-100">
          <p className="text-slate-600 text-xl px-2 font-medium">
            Applications Selected
          </p>
          <p className="text-[30px] font-medium">7</p>
        </div>
        <div className="flex justify-between px-6 items-center shadow-md rounded p-3  mr-10 bg-yellow-100">
          <p className="text-slate-600 text-xl px-2 font-medium">
            Applications Rejected
          </p>
          <p className="text-[30px] font-medium">3</p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5 text-center pt-5">
        <div className="flex justify-between px-6 items-center shadow-md rounded p-3  mr-10 bg-rose-100">
          <p className="text-slate-600 text-xl px-2 font-medium">
            Interviews Given
          </p>
          <p className="text-[30px] font-medium">4</p>
        </div>
        <div className="flex justify-between px-6 items-center shadow-md rounded p-3 mr-10 bg-indigo-100">
          <p className="text-slate-600 text-xl px-2 font-medium">
            Interviews Selected
          </p>
          <p className="text-[30px] font-medium">1</p>
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
