import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_ATTENDING_USERS_REQUESTED,
  FETCH_PROCTORED_USERS_ANY_PROCTOR_REQUESTED,
  FETCH_PROCTORED_USERS_REQUESTED,
} from "../actionTypes";
import {
  fetchAttendingUsersFailure,
  fetchAttendingUsersSuccess,
  fetchProctoredUsersFailure,
  fetchProctoredUsersOfAnyProctorFailure,
  fetchProctoredUsersOfAnyProctorSuccess,
  fetchProctoredUsersSuccess,
} from "../actions/proctor";
import {
  getAttendingUsers,
  getProctoredUsers,
  getProctoredUsersOfAnyProctor,
} from "../../services/proctor.service";

function* fetchProctoredUsers(action) {
  try {
    const proctoredUsers = yield call(getProctoredUsers, action.payload);
    yield put(fetchProctoredUsersSuccess(proctoredUsers));
  } catch (e) {
    yield put(fetchProctoredUsersFailure(e));
  }
}
function* fetchProctoredUsersAnyProctor(action) {
  try {
    const proctoredUsers = yield call(
      getProctoredUsersOfAnyProctor,
      action.payload
    );
    yield put(fetchProctoredUsersOfAnyProctorSuccess(proctoredUsers));
  } catch (e) {
    yield put(fetchProctoredUsersOfAnyProctorFailure(e));
  }
}

function* fetchAttendingUsers(action) {
  try {
    const attendingUsers = yield call(getAttendingUsers, action.payload);
    yield put(fetchAttendingUsersSuccess(attendingUsers));
  } catch (e) {
    yield put(fetchAttendingUsersFailure(e));
  }
}

function* proctorSaga() {
  yield takeEvery(FETCH_PROCTORED_USERS_REQUESTED, fetchProctoredUsers);
  yield takeEvery(FETCH_ATTENDING_USERS_REQUESTED, fetchAttendingUsers);
  yield takeEvery(
    FETCH_PROCTORED_USERS_ANY_PROCTOR_REQUESTED,
    fetchProctoredUsersAnyProctor
  );
}

export default proctorSaga;
