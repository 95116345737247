export const FETCH_HR_QUICK_STATS_REQUESTED = "FETCH_HR_QUICK_STATS_REQUESTED";

export const FETCH_HR_QUICK_STATS_SUCCESS = "FETCH_HR_QUICK_STATS_SUCCESS";

export const FETCH_HR_GRAPH_STATS_REQUESTED = "FETCH_HR_GRAPH_STATS_REQUESTED";

export const FETCH_HR_GRAPH_STATS_SUCCESS = "FETCH_HR_GRAPH_STATS_SUCCESS";

export const FETCH_HR_GRAPH_STATS_FAILURE = "FETCH_HR_GRAPH_STATS_FAILURE";

export const FETCH_HR_SUMMARY_STATS_REQUESTED =
  "FETCH_HR_SUMMARY_STATS_REQUESTED";

export const FETCH_HR_SUMMARY_STATS_SUCCESS = "FETCH_HR_SUMMARY_STATS_SUCCESS";
