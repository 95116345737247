import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import { Navigate, useNavigate } from "react-router-dom";
import TestService from "../../services/test.service";
import RenderQuestion from "./createTest/RenderQuestion";
import TestHeader from "./createTest/TestHeader";
import { toggleSidebar } from "../../store/actions/sidebar";
import load from "../../assets/img/svg/load.svg";
import { setSubmissionResponse } from "../../store/actions/test";
import { isFloat } from "../common/utils";
// import { updateTestQuestionDataWithKey } from "../../store/actions/test";

const CandidateTestReview = ({ id, fetchProctorAndTestDetails }) => {
  const dispatch = useDispatch();
  // const { opened: sideBarOpened } = useSelector((state) => state.sidebar);
  // const { id } = useParams();
  const [components, setComponents] = useState([
    {
      title: "",
      description: "",
      components: [],
    },
  ]);
  const [saving, setSaving] = useState(false);
  const [totalMarks, setTotalMarks] = useState(0);
  const [finalMarks, setFinalMarks] = useState(0);
  const [testId, setTestId] = useState(null);
  const [currentsection, setCurrentSection] = useState("");
  const [currentquestion, setCurrentQuestion] = useState("");

  const [testData, setTestData] = useState({
    title: "",
    description: "",
    start_duration: null,
    end_duration: null,
    assign: [],
    reveal_scorecard_questions: false,
  });

  let [pageLoading, setPageLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
  });
  let [studentResponse, setStudentResponse] = useState({});
  const [marking, setMarking] = useState({});

  const getTotalMarks = (comp) => {
    var totalMark = 0;
    for (let i = 0; i < comp.length; i++) {
      for (let j = 0; j < comp[i].components.length; j++) {
        totalMark += parseInt(comp[i].components[j].marks);
      }
    }
    setTotalMarks(totalMark);
  };

  let history = useNavigate();

  useEffect(() => {
    if (!id) return;
    setPageLoading(true);
    TestService.loadStudentResponse(id).then(
      async (response) => {
        dispatch(setSubmissionResponse({ response: response.data }));

        if (response.data.user) {
          setUserDetails({
            name: response.data.user.name,
            email: response.data.user.email,
          });
          fetchProctorAndTestDetails(
            response?.data?.quiz?._id,
            response?.data?.user?._id
          );
        } else {
          setUserDetails({
            name: response.data.name,
            email: response.data.email,
          });
          fetchProctorAndTestDetails(
            response?.data?.quiz?._id,
            response?.data?.email
          );
        }
        setFinalMarks(response.data.marks);
        const quiz_response = response.data.quiz;
        // console.log("checking res",quiz_response.reveal_scorecard_questions)
        setTestId(quiz_response._id);
        setTestData({
          title: quiz_response.title ? quiz_response.title : "",
          assign: quiz_response.assign,
          description: quiz_response.description
            ? quiz_response.description
            : "",
          start_duration: quiz_response.start_duration,
          end_duration: quiz_response.end_duration,
          reveal_scorecard_questions: quiz_response.reveal_scorecard_questions,
        });
        getTotalMarks(quiz_response.components);

        setComponents(quiz_response.components);
        // console.log("checking tresponse", response.data);
        const student_response = response.data.response;
        setStudentResponse(student_response);
        if (response.data.marking) {
          setMarking(response.data.marking);
        }

        setPageLoading(false);
      },
      (error) => {
        return <Navigate to="/dashboard" />;
      }
    );
  }, [id]);

  const previewTest = (e) => {
    saveReview(e, () => {
      history(`/tests/preview/${testId}`);
    });
  };

  const saveReview = (e, callback) => {
    e.preventDefault();
    setSaving(true);
    TestService.getAssignedUsers({ quiz: testId }).then(
      (res) => {
        var users = res.data;
        users.forEach((user) => {
          if (user["_id"] === id) {
            UserService.putUserReviewScore(user["_id"], marking);
            history(`/dashboard`);
          }
        });
      },
      (error) => {
        return <Navigate to="/dashboard" />;
      }
    );
    setSaving(false);
  };

  if (pageLoading) {
    return (
      <div className="w-[100vw] h-[90vh] flex justify-center items-center">
        <img
          alt="loading"
          src={load}
          className="spinner mr-2 w-6 h-6 text-primary "
        />
      </div>
    );
  }
  return (
    <>
      {/* {console.log("chec",testData)} */}
      {testData?.reveal_scorecard_questions && (
        <div>
          <div>
            {pageLoading ? (
              <div
                className="justify-content-center align-items-end d-flex"
                style={{ minHeight: "250px" }}
              >
                <img
                  alt="loading"
                  src={load}
                  className="spinner mr-2 w-6 h-6 text-primary "
                />
              </div>
            ) : (
              <>
                <TestHeader
                  styles="text-3xl text-[#000000] font-medium"
                  title="Test Details"
                />
              </>
            )}
          </div>
          {!pageLoading && (
            <form onSubmit={(e) => saveReview(e)}>
              <div className="mt-4">
                <div className="flex flex-col items-start">
                  <h2 className="text-2xl font-['nunito'] font-semibold  pb-2">
                    Grading Scale
                  </h2>

                  <div className="flex w-full justify-between gap-2 items-center mb-2">
                    <table className="w-full text-sm text-left text-gray-500 ">
                      <thead className="text-xs text-[#001437] uppercase bg-[#BCFB46]  ">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Letter Range
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Numeric Grade (%)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th
                            scope="row"
                            className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap "
                          >
                            A
                          </th>
                          <td className="px-6 py-3">95-100</td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th
                            scope="row"
                            className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap "
                          >
                            A-
                          </th>
                          <td className="px-6 py-3">90-95</td>
                        </tr>

                        <tr className="bg-[#EEFECF] border-b ">
                          <th
                            scope="row"
                            className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap "
                          >
                            B+
                          </th>
                          <td className="px-6 py-3">87-90</td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th
                            scope="row"
                            className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap "
                          >
                            B
                          </th>
                          <td className="px-6 py-3">85-87</td>
                        </tr>
                        {/* <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            B-
                          </th>
                          <td className="px-6 py-3">
                            80-85
                          </td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            C+
                          </th>
                          <td className="px-6 py-3">
                            77-80
                          </td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            C
                          </th>
                          <td className="px-6 py-3">
                            75-77
                          </td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            C-
                          </th>
                          <td className="px-6 py-3">
                            70-75
                          </td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            D+
                          </th>
                          <td className="px-6 py-3">
                            67-70
                          </td>
                        </tr>

                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            D
                          </th>
                          <td className="px-6 py-3">
                            65-67
                          </td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            D-
                          </th>
                          <td className="px-6 py-3">
                            60-65
                          </td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            F
                          </th>
                          <td className="px-6 py-3">
                            Below 60
                          </td>
                        </tr> */}
                      </tbody>
                    </table>

                    <table className="w-full text-sm text-left text-gray-500 ">
                      <thead className="text-xs text-[#001437] uppercase bg-[#BCFB46]  ">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Letter Range
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Numeric Grade (%)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            A
                          </th>
                          <td className="px-6 py-3">
                            95-100
                          </td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            A-
                          </th>
                          <td className="px-6 py-3">
                            90-95
                          </td>
                        </tr>

                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            B+
                          </th>
                          <td className="px-6 py-3">
                            87-90
                          </td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            B
                          </th>
                          <td className="px-6 py-3">
                            85-87
                          </td>
                        </tr> */}
                        <tr className="bg-[#EEFECF] border-b ">
                          <th
                            scope="row"
                            className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap "
                          >
                            B-
                          </th>
                          <td className="px-6 py-3">80-85</td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th
                            scope="row"
                            className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap "
                          >
                            C+
                          </th>
                          <td className="px-6 py-3">77-80</td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th
                            scope="row"
                            className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap "
                          >
                            C
                          </th>
                          <td className="px-6 py-3">75-77</td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th
                            scope="row"
                            className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap "
                          >
                            C-
                          </th>
                          <td className="px-6 py-3">70-75</td>
                        </tr>
                        {/* <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            D+
                          </th>
                          <td className="px-6 py-3">
                            67-70
                          </td>
                        </tr>

                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            D
                          </th>
                          <td className="px-6 py-3">
                            65-67
                          </td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            D-
                          </th>
                          <td className="px-6 py-3">
                            60-65
                          </td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            F
                          </th>
                          <td className="px-6 py-3">
                            Below 60
                          </td>
                        </tr> */}
                      </tbody>
                    </table>

                    <table className="w-full text-sm text-left text-gray-500 ">
                      <thead className="text-xs text-[#001437] uppercase bg-[#BCFB46]  ">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Letter Range
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Numeric Grade (%)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            A
                          </th>
                          <td className="px-6 py-3">
                            95-100
                          </td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            A-
                          </th>
                          <td className="px-6 py-3">
                            90-95
                          </td>
                        </tr>

                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            B+
                          </th>
                          <td className="px-6 py-3">
                            87-90
                          </td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            B
                          </th>
                          <td className="px-6 py-3">
                            85-87
                          </td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            B-
                          </th>
                          <td className="px-6 py-3">
                            80-85
                          </td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            C+
                          </th>
                          <td className="px-6 py-3">
                            77-80
                          </td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            C
                          </th>
                          <td className="px-6 py-3">
                            75-77
                          </td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            C-
                          </th>
                          <td className="px-6 py-3">
                            70-75
                          </td>
                        </tr> */}
                        <tr className="bg-[#EEFECF] border-b ">
                          <th
                            scope="row"
                            className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap "
                          >
                            D+
                          </th>
                          <td className="px-6 py-3">67-70</td>
                        </tr>

                        <tr className="bg-[#EEFECF] border-b ">
                          <th
                            scope="row"
                            className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap "
                          >
                            D
                          </th>
                          <td className="px-6 py-3">65-67</td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th
                            scope="row"
                            className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap "
                          >
                            D-
                          </th>
                          <td className="px-6 py-3">60-65</td>
                        </tr>
                        <tr className="bg-[#EEFECF] border-b ">
                          <th
                            scope="row"
                            className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap "
                          >
                            F
                          </th>
                          <td className="px-6 py-3">Below 60</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex flex-col items-start gap-2 lg:flex-row lg:items-center justify-between mb-4 ">
                  <div className="flex items-start flex-col ">
                    <h2 className="text-2xl font-semibold font-['nunito']  mb-0">
                      {testData.title}
                    </h2>
                  </div>
                  <div className="w-auto flex bg-[#BCFB464A] px-1 md:px-3 py-1 items-center gap-x-2 py-1 rounded">
                    <span className="text-[1.25rem] md:text-[1.875rem] font-medium">{`${isFloat(finalMarks)
                      ? finalMarks.toFixed(2)
                      : finalMarks || 0
                      }`}</span>
                    <span className="text-[0.875rem] md:text-[1.25rem] font-semibold">
                      Marks out of
                    </span>
                    <span className="text-[0.875rem] md:text-[1.25rem] font-semibold">
                      {`${totalMarks} Marks`}
                    </span>
                  </div>
                  <div className="flex flex-col gap-x-3 items-start  ">
                    <div className="flex flex-col items-end py-1">
                      <div className="flex flex-col items-start">
                        <h4 className="text-[16px] font-medium font-['Inter']">
                          Name:
                          <span className=" pl-3 text-[16px] font-semibold">
                            {userDetails.name}
                          </span>
                        </h4>
                        <h4 className="text-[16px] font-medium font-['Inter']">
                          Email:
                          <span className=" pl-3 text-[16px] font-semibold">
                            {userDetails.email}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {testData?.description ? (
                    <>
                      <p className="text-muted text-lg font-medium mb-1 pl-1 font-[poppins]">
                        Test Description
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: testData.description,
                        }}
                        className="text-muted bg-[#EEFECF] shadow-[#BCFB46] rounded-lg shadow p-5 text-base mb-4 font-[poppins] text-slate-600"
                      ></p>
                    </>
                  ) : null}
                  <div className="flex flex-col sm:flex-row gap-x-2">
                    <div className="flex flex-col">
                      <p className="text-md font-['poppins'] font-medium pb-2">
                        Choose Section :
                      </p>
                      <select
                        onChange={(e) => setCurrentSection(e.target.value)}
                        value={currentsection}
                        className=" rounded font-['poppins'] border w-48 border-gray-300 outline-gray-300 p-1"
                      >
                        <option value="">Select Section</option>
                        {components?.map((section, sectionIndex) => (
                          <option
                            value={sectionIndex}
                            key={`section-${sectionIndex}`}
                          >
                            {section.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col">
                      <p className="text-md font-['poppins'] font-medium pb-2">
                        Choose Question :
                      </p>
                      <select
                        onChange={(e) => setCurrentQuestion(e.target.value)}
                        value={currentquestion}
                        className=" rounded font-['poppins'] border w-48 border-gray-300 outline-gray-300 p-1"
                      >
                        <option value="">Select Question</option>
                        {/* {console.log("questioninde", components[currentsection])} */}
                        {currentsection !== "" &&
                          components[currentsection]?.components?.map(
                            (question, questionIndex) => (
                              <option
                                value={questionIndex}
                                key={`question-${questionIndex}`}
                              >
                                {questionIndex + 1}
                              </option>
                            )
                          )}
                      </select>
                    </div>
                  </div>
                </div>

                {components.map((section, sectionIndex) => (
                  <>
                    {currentsection !== "" ? (
                      <>
                        {parseInt(currentsection) === sectionIndex && (
                          <div
                            key={sectionIndex}
                            className="bg-white p-lg-5 mt-4"
                          >
                            <h4 className="text-left text-2xl font-['nunito'] font-bold mb-0">
                              {section.title}
                            </h4>
                            {section?.description ? (
                              <>
                                <p className="text-muted text-lg font-medium mt-4 mb-1 pl-1 font-[poppins]">
                                  Section Description
                                </p>

                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: section.description,
                                  }}
                                  className="text-muted bg-[#EEFECF] shadow-[#BCFB46] rounded-lg shadow p-5 text-base mb-4 font-[poppins] text-slate-600"
                                ></p>
                              </>
                            ) : null}
                            {section.components.map(
                              (question, questionIndex) => (
                                <div
                                  key={`renderquestion-${sectionIndex}-${questionIndex}`}
                                >
                                  {currentquestion !== "" ? (
                                    <>
                                      {parseInt(currentquestion) ===
                                        questionIndex && (
                                          <div className="mt-3 mb-4 p-3">
                                            <RenderQuestion
                                              sectionIndex={sectionIndex}
                                              question_details={question}
                                              index={questionIndex}
                                              response={studentResponse}
                                              marking={marking}
                                              isTestCreation={false}
                                              type="T"
                                            />
                                          </div>
                                        )}
                                    </>
                                  ) : (
                                    <div className="mt-3 mb-4 p-3">
                                      <RenderQuestion
                                        sectionIndex={sectionIndex}
                                        question_details={question}
                                        index={questionIndex}
                                        response={studentResponse}
                                        marking={marking}
                                        isTestCreation={false}
                                        type="T"
                                      />
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <div key={sectionIndex} className="bg-white p-lg-5 mt-4">
                        <h4 className="text-left text-2xl font-['nunito'] font-bold mb-0">
                          {section.title}
                        </h4>
                        {section?.description ? (
                          <>
                            <p className="text-muted text-lg font-medium mt-4 mb-1 pl-1 font-[poppins]">
                              Section Description
                            </p>

                            <p
                              dangerouslySetInnerHTML={{
                                __html: section.description,
                              }}
                              className="text-muted bg-[#EEFECF] shadow-[#BCFB46] rounded-lg shadow p-5 text-base mb-4 font-[poppins] text-slate-600"
                            ></p>
                          </>
                        ) : null}

                        {section.components.map((question, questionIndex) => (
                          <div
                            key={`renderquestion2-${sectionIndex}-${questionIndex}`}
                          >
                            <div className="mt-3 mb-4 p-3">
                              <RenderQuestion
                                sectionIndex={sectionIndex}
                                question_details={question}
                                index={questionIndex}
                                response={studentResponse}
                                marking={marking}
                                isTestCreation={false}
                                type="T"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ))}
              </div>
            </form>
          )}
        </div>
      )}
    </>
  );
};

export default CandidateTestReview;
