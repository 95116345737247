export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_REQUESTED = "LOGIN_REQUESTED";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SIGNUP_REQUESTED = "SIGNUP_REQUESTED";

export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";

export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const SET_SIGNUP_LOADING = "SET_SIGNUP_LOADING";

export const FORGOT_PASSWORD_REQUESTED = "FORGOT_PASSWORD_REQUESTED";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const LOGOUT = "LOGOUT";

export const LOGOUT_SUCCESSFUL = "LOGOUT_SUCCESSFUL";

export const FETCH_PROFILE_REQUESTED = "FETCH_PROFILE_REQUESTED";

export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";

export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";

export const RESET_PASSWORD_REQUESTED = "RESET_PASSWORD_REQUESTED";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const REGISTER_PUBLIC_TEST_TOKEN = "REGISTER_PUBLIC_TEST_TOKEN";

export const GET_USER_SUBSCRIBED_PLANS = "GET_USER_SUBSCRIBED_PLANS";

export const GET_USER_SUBSCRIBED_PLANS_SUCCESS =
  "GET_USER_SUBSCRIBED_PLANS_SUCCESS";

export const UPDATE_ALREADY_LOGIN_MODEL = "UPDATE_ALREADY_LOGIN_MODEL";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
