import React from "react";
import { useSelector } from "react-redux";
import { Route, Navigate, useLocation } from "react-router-dom";

// const PublicRoute = ({ component: Component, restricted, ...rest }) => {
//   const { user: currentUser } = useSelector((state) => state.auth);
//   const search = useLocation().search;
//   const isAictsPlanId = new URLSearchParams(search).get("aicts_plan");

//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         currentUser && restricted && !isAictsPlanId ? (
//           <Navigate to="/dashboard" />
//         ) : (
//           <Component {...props} />
//         )
//       }
//     ></Route>
//   );
// };

// export default PublicRoute;

const PublicRoute = ({ children, restricted, ...rest }) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const search = useLocation().search;
  const isAictsPlanId = new URLSearchParams(search).get("aicts_plan");

  return currentUser && restricted && !isAictsPlanId ? <Navigate to="/dashboard" /> : children;
};

export default PublicRoute;
