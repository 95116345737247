import React from "react";
import ReactQuill from "react-quill";
import delete1 from "../../../assets/img/svg/delete.svg";
import { ReactComponent as Plus } from "../../../assets/img/svg/plus4.svg";
import eye from "../../../assets/img/svg/eye.svg";
import { uploadFile, viewFile } from "../../../services/upload.service";
import { modules } from "../utils/QuillModules";
import load from "../../../assets/img/svg/load.svg";

// Store
import { connect } from "react-redux";
import { updateTestQuestionDataWithKey } from "../../../store/actions/test";
import MyQuillEditor from "../../common/ReactQuill/ReactQuill";
import { withRouter } from "../../common/WithRouter";

class TestQuestionEditor extends React.Component {
  constructor() {
    super();
    this.state = {
      checkValues: [],
      memoryError: false,
      timeLimitError: false,
      uploadFileLoader: false,
    };
    this.changeOptionType = this.changeOptionType.bind(this);
    this.changeOptionValueTypes = this.changeOptionValueTypes.bind(this);
    this.changeQuestion = this.changeQuestion.bind(this);
    this.changeQuestionFiles = this.changeQuestionFiles.bind(this);
    this.changeMcqOptionFiles = this.changeMcqOptionFiles.bind(this);
    this.handleUploadFiles = this.handleUploadFiles.bind(this);
    this.deleteQuestionFile = this.deleteQuestionFile.bind(this);
    this.changeMarks = this.changeMarks.bind(this);
    this.changeType = this.changeType.bind(this);
    this.addOption = this.addOption.bind(this);
    this.changeOption = this.changeOption.bind(this);
    this.deleteOption = this.deleteOption.bind(this);
    this.changePostgreSQLAnswer = this.changePostgreSQLAnswer.bind(this);
  }

  componentDidMount() {
    this.setState((prev) => {
      const section = this.props.test.components[this.props.sectionIndex];
      const questionData = section.components[this.props.questionIndex];
      const { ans_mcq } = questionData;
      return {
        ...prev,
        checkValues: ans_mcq || [],
      };
    });
  }

  changeOptionType(type) {
    const value = type === "single" ? "radio" : "checkbox";
    this.props.updateTestQuestionDataWithKey({
      questionIndex: this.props.questionIndex,
      sectionIndex: this.props.sectionIndex,
      key: "optionType",
      value,
    });
  }

  changeOptionValueTypes(type) {
    this.props.updateTestQuestionDataWithKey({
      questionIndex: this.props.questionIndex,
      sectionIndex: this.props.sectionIndex,
      key: "optionsValueType",
      value: type,
    });
  }

  changefileOptionsSize(type) {
    this.props.updateTestQuestionDataWithKey({
      questionIndex: this.props.questionIndex,
      sectionIndex: this.props.sectionIndex,
      key: "fileOptionsSize",
      value: type,
    });
  }

  changeQuestion(value) {
    console.log(
      "cjecking",
      value,
      this.props.questionIndex,
      this.props.sectionIndex
    );
    this.props.updateTestQuestionDataWithKey({
      questionIndex: this.props.questionIndex,
      sectionIndex: this.props.sectionIndex,
      key: "question",
      value,
    });
  }

  changeQuestionFiles(event) {
    const questionFiles =
      this.props.test.components[this.props.sectionIndex].components[
        this.props.questionIndex
      ].questionFiles;
    this.setState((prev) => {
      return { ...prev, uploadFileLoader: true };
    });
    uploadFile(event.target.files[0], {
      type: "TEST",
      id: this.props.params.id,
    }).then((filename) => {
      this.props.updateTestQuestionDataWithKey({
        questionIndex: this.props.questionIndex,
        sectionIndex: this.props.sectionIndex,
        key: "questionFiles",
        value: questionFiles ? [...questionFiles, filename] : [filename],
      });
      this.setState((prev) => {
        return { ...prev, uploadFileLoader: false };
      });
    });
  }

  changeMcqOptionFiles(event) {
    const options =
      this.props.test.components[this.props.sectionIndex].components[
        this.props.questionIndex
      ].options;

    uploadFile(event.target.files[0], {
      type: "TEST",
      id: this.props.testId,
    }).then((filename) => {
      let bOptions = [...options];
      bOptions[event.target.name].option_file = filename;
      this.props.updateTestQuestionDataWithKey({
        questionIndex: this.props.questionIndex,
        sectionIndex: this.props.sectionIndex,
        key: "options",
        value: bOptions,
      });
    });

    var optionFileIndex = 0;
    let mcqoptionsFiles =
      this.props.test.components[this.props.sectionIndex].components[
        this.props.questionIndex
      ].mcqoptionsFiles;

    if (mcqoptionsFiles && mcqoptionsFiles.length) {
      optionFileIndex = mcqoptionsFiles.length;
    }

    uploadFile(event.target.files[0], {
      type: "TEST",
      id: this.props.testId,
    }).then((filename) => {
      if (optionFileIndex > event.target.name) {
        mcqoptionsFiles[event.target.name] = filename;
      } else {
        mcqoptionsFiles = mcqoptionsFiles
          ? [...mcqoptionsFiles, filename]
          : [filename];
      }
      this.props.updateTestQuestionDataWithKey({
        questionIndex: this.props.questionIndex,
        sectionIndex: this.props.sectionIndex,
        key: "mcqoptionsFiles",
        value: mcqoptionsFiles,
      });
    });
  }

  handleUploadFiles(e) {
    uploadFile(e.target.files[0], { type: "TEST", id: this.props.testId }).then(
      (filename) => {
        this.props.updateTestQuestionDataWithKey({
          questionIndex: this.props.questionIndex,
          sectionIndex: this.props.sectionIndex,
          key: e.target.name,
          value: [filename],
        });
      }
    );
  }

  deleteQuestionFile(index) {
    const question =
      this.props.test.components[this.props.sectionIndex].components[
      this.props.questionIndex
      ];
    const _questionFiles = [...question.questionFiles];
    _questionFiles.splice(index, 1);
    this.props.updateTestQuestionDataWithKey({
      questionIndex: this.props.questionIndex,
      sectionIndex: this.props.sectionIndex,
      key: "questionFiles",
      value: _questionFiles,
    });
  }

  deleteMcqOptionFile(index) {
    const options =
      this.props.test.components[this.props.sectionIndex].components[
        this.props.questionIndex
      ].options;
    let bOptions = [...options];
    bOptions[index].option_file = null;
    this.props.updateTestQuestionDataWithKey({
      questionIndex: this.props.questionIndex,
      sectionIndex: this.props.sectionIndex,
      key: "options",
      value: bOptions,
    });
  }

  changeMarks(e) {
    this.props.updateTestQuestionDataWithKey({
      questionIndex: this.props.questionIndex,
      sectionIndex: this.props.sectionIndex,
      key: "marks",
      value: e.target.value,
    });
  }

  changeType(e) {
    var changeDefaultSubType = "";
    if (e.target.value === "file") changeDefaultSubType = "Any";
    else if (e.target.value === "record") changeDefaultSubType = "Video";
    this.props.updateTestQuestionDataWithKey({
      questionIndex: this.props.questionIndex,
      sectionIndex: this.props.sectionIndex,
      key: "type",
      value: e.target.value,
    });
    this.props.updateTestQuestionDataWithKey({
      questionIndex: this.props.questionIndex,
      sectionIndex: this.props.sectionIndex,
      key: "ansFileType",
      value: changeDefaultSubType,
    });
  }

  addOption() {
    var optionIndex = 0;
    const options =
      this.props.test.components[this.props.sectionIndex].components[
        this.props.questionIndex
      ].options;
    const optionType =
      this.props.test.components[this.props.sectionIndex].components[
        this.props.questionIndex
      ].optionsValueType;
    if (options && options.length) {
      optionIndex = options.length;
    }
    if (optionType === "text") {
      this.props.updateTestQuestionDataWithKey({
        questionIndex: this.props.questionIndex,
        sectionIndex: this.props.sectionIndex,
        key: "options",
        value: options
          ? [...options, { name: "", optionIndex }]
          : [{ name: "", optionIndex }],
      });
    } else {
      this.props.updateTestQuestionDataWithKey({
        questionIndex: this.props.questionIndex,
        sectionIndex: this.props.sectionIndex,
        key: "options",
        value: options
          ? [...options, { name: "", optionIndex, option_file: "" }]
          : [{ name: "", optionIndex, option_file: "" }],
      });
    }
  }

  changeOption(value, name) {
    const options =
      this.props.test.components[this.props.sectionIndex].components[
        this.props.questionIndex
      ].options;
    let bOptions = [...options];
    bOptions[name].name = value;
    this.props.updateTestQuestionDataWithKey({
      questionIndex: this.props.questionIndex,
      sectionIndex: this.props.sectionIndex,
      key: "options",
      value: bOptions,
    });
  }

  optionAnswer(e, index) {
    const options =
      this.props.test.components[this.props.sectionIndex].components[
        this.props.questionIndex
      ].options;
    var tempvalues = [];
    if (e.target.type === "checkbox") {
      const { checkValues } = this.state;
      tempvalues = new Array(options.length).fill(false);
      for (let i = 0; i < checkValues.length; i++) {
        if (i < options.length && checkValues[i]) {
          tempvalues[i] = checkValues[i];
        }
      }
      tempvalues[index] = e.target.checked;
      this.setState({ checkValues: tempvalues });
    } else {
      tempvalues = new Array(options.length).fill(false);
      tempvalues[index] = true;
      this.setState({ checkValues: tempvalues });
    }
    this.props.updateTestQuestionDataWithKey({
      questionIndex: this.props.questionIndex,
      sectionIndex: this.props.sectionIndex,
      key: "ans_mcq",
      value: tempvalues,
    });
  }

  changeIntegerAnswer(e) {
    const { questionIndex, sectionIndex } = this.props;
    this.props.updateTestQuestionDataWithKey({
      questionIndex: questionIndex,
      sectionIndex: sectionIndex,
      key: "ans_integer",
      value: e.target.value,
    });
  }

  changePostgreSQLAnswer(value) {
    const { questionIndex, sectionIndex } = this.props;
    this.props.updateTestQuestionDataWithKey({
      questionIndex: questionIndex,
      sectionIndex: sectionIndex,
      key: "valid_answer_csv",
      value,
    });
  }

  handleMemoryLimit(e) {
    this.setState((prev) => {
      return {
        ...prev,
        memoryError: false,
      };
    });
    if (parseInt(e.target.value) > 512) {
      this.setState((prev) => {
        return {
          ...prev,
          memoryError: true,
        };
      });
    } else {
      this.props.updateTestQuestionDataWithKey({
        questionIndex: this.props.questionIndex,
        sectionIndex: this.props.sectionIndex,
        key: "coding_memory_limit",
        value: e.target.value,
      });
    }
  }

  handleTimeLimit(e) {
    this.setState((prev) => {
      return {
        ...prev,
        timeLimitError: false,
      };
    });
    if (parseInt(e.target.value) > 5 || parseInt(e.target.value) < 1) {
      this.setState((prev) => {
        return {
          ...prev,
          timeLimitError: true,
        };
      });
    } else {
      this.props.updateTestQuestionDataWithKey({
        questionIndex: this.props.questionIndex,
        sectionIndex: this.props.sectionIndex,
        key: "coding_time_limit",
        value: e.target.value,
      });
    }
  }

  deleteOption(toDel) {
    const options =
      this.props.test.components[this.props.sectionIndex].components[
        this.props.questionIndex
      ].options;
    let bOptions = [...options];
    bOptions.splice(toDel, 1);
    const result = bOptions.map((option, index) => ({
      ...option,
      optionIndex: index,
    }));
    this.props.updateTestQuestionDataWithKey({
      questionIndex: this.props.questionIndex,
      sectionIndex: this.props.sectionIndex,
      key: "options",
      value: result,
    });
  }

  async getUrl(filename) {
    if (filename && filename.length) {
      var fileUrl = await viewFile(filename, "TEST").then((res) => {
        return res;
      });
    }

    window.open(fileUrl);
  }

  render() {
    const section = this.props.test.components[this.props.sectionIndex];
    const questionData = section.components[this.props.questionIndex];
    let {
      marks,
      type,
      question,
      options,
      isRequired,
      questionFiles,
      mcqoptionsFiles,
      ansFileType,
      ansLanguageType,
      preparation_time,
      answer_time,
      ans_mcq,
      ans_integer,
      optionType,
      optionsValueType,
      fileOptionsSize,
      questionLink,
      coding_testcases,
      coding_memory_limit,
      coding_time_limit,
      std_input_file,
      std_output_file,
      select_postgresql_table,
      testcase_weightage,
    } = questionData;

    const renderOptions = () => {
      return (
        <div className="w-full">
          <label className="">
            Options (Add and select the correct option(s) for auto grading)
          </label>
          {options &&
            options.map((data, index) => (
              <div
                className="flex w-full gap-3 justify-center items-center my-2"
                key={`${questionData.id}-option-${index}`}
              >
                <div className="flex-none">
                  <input
                    type={optionType}
                    style={{ marginLeft: "10px" }}
                    name={`option${this.props.sectionIndex}-${this.props.questionIndex}`}
                    checked={ans_mcq[index] ? ans_mcq[index] : false}
                    className="h-4 w-4"
                    onChange={(e) => this.optionAnswer(e, index)}
                  />
                </div>
                <div className="grow w-full">
                  {optionsValueType === "file" ? (
                    <>
                      <div className="my-2 gap-3">
                        <label className="font-semibold">Option Files</label>
                        <input
                          className="md:ml-3"
                          type="file"
                          name={index}
                          accept="audio/*,video/*,image/*"
                          onChange={(e) => this.changeMcqOptionFiles(e)}
                        />
                        <p className="text-sm text-gray-500">
                          Upload multiple files pertaining to question. Files
                          allowed: Images(JPG, PNG), Videos(MP4), Audios(MP3).
                        </p>
                        {data && renderOptionFiles(data.option_file, index)}
                      </div>
                    </>
                  ) : (
                    <>
                      <ReactQuill
                        modules={modules}
                        value={data.name || ""}
                        placeholder="Your option here"
                        onChange={(value) => this.changeOption(value, index)}
                        className="my-1"
                      />
                    </>
                  )}
                </div>
                <div className="flex-none">
                  <img
                    src={delete1}
                    title="Delete"
                    loading="lazy"
                    className="w-6 h-6 cursor-pointer"
                    alt="svg"
                    onClick={() => {
                      this.deleteOption(index);
                    }}
                  />
                </div>
              </div>
            ))}
          <div className="small my-1">
            <button
              className="flex gap-[0.4rem] items-center justify-center text-[#a0db32] border border-[#a0db32] outline-none focus:outline-none ease-linear transition-all duration-150 py-2 px-4 rounded-md font-semibold hover:bg-[#001437] hover:text-[#a0db32] mt-2"
              onClick={() => this.addOption()}
            >
              <Plus className="h-4 w-4 mx-2 [&>*]:!fill-[#a0db32] " />
              Add Option
            </button>
          </div>
        </div>
      );
    };

    const renderQuestionFiles = () => {
      return (
        <>
          {questionFiles && questionFiles.length > 0 && (
            <label className="mb-0 small">Uploaded Files</label>
          )}
          {questionFiles.map((filename, index) => (
            <p className="mb-0" key={index}>
              {filename}{" "}
              <img
                src={delete1}
                title="delete"
                loading="lazy"
                className="w-6 h-6 hoverPointer"
                alt="svg"
                onClick={() => this.deleteQuestionFile(index)}
              />
            </p>
          ))}
        </>
      );
    };

    const renderOptionFiles = (file, index) => {
      return (
        <>
          {mcqoptionsFiles && mcqoptionsFiles.length > 0 && (
            <label className="mb-0 small">Uploaded File</label>
          )}
          {questionFiles.map((filename, index) => (
            <>
              {file && (
                <p className="flex gap-5 py-2" key={`questionFiles-${index}`}>
                  {file}{" "}
                  <img
                    src={delete1}
                    title="delete"
                    loading="lazy"
                    className="w-6 h-6 hoverPointer"
                    alt="svg"
                    onClick={() => this.deleteMcqOptionFile(index)}
                  />
                </p>
              )}
            </>
          ))}
        </>
      );
    };
    return (
      <div className="flex flex-col">
        <div
          className={`flex flex-col ${section.section_type === "C" ? "mr-4 md:mr-0" : ""
            } `}
        >
          <label className="text-[1.1rem] font-bold font-['nunito']">
            Question {this.props.questionIndex + 1}
          </label>
          <MyQuillEditor
            readOnly={false}
            value={question}
            type="I"
            onResponseChangeDescriptive={(data) => this.changeQuestion(data)}
            placeholder="Enter your question"
          />
          {/* <ReactQuill
            modules={modules}
            name="testQuestion"
            value={question}
            onChange={(value) => this.changeQuestion(value)}
            className="my-1 "
            placeholder="Enter your question"
          /> */}
        </div>
        {section.section_type === "G" ? (
          <>
            <div className="my-2 gap-3">
              <div className="flex items-center gap-4">
                <label className="font-semibold">Question Files</label>
                <input
                  className="md:ml-3"
                  type="file"
                  name="file"
                  accept="audio/*,video/*,image/*"
                  onChange={(e) => this.changeQuestionFiles(e)}
                />

                {this.state.uploadFileLoader ? (
                  <img
                    alt="loading"
                    src={load}
                    className="spinner my-1 mx-auto w-5 h-5 text-white "
                  />
                ) : null}
              </div>

              <p className="text-sm text-gray-500">
                Upload multiple files pertaining to question. Files allowed:
                Images(JPG, PNG), Videos(MP4), Audios(MP3).
              </p>
              {questionFiles && renderQuestionFiles()}
            </div>
            {(type === "description" || type === "record") && (
              <div className="flex md:flex-row flex-col items-center  jusitify-center my-2 gap-3">
                <label className="font-semibold font-['nunito'] ">
                  Youtube Video Url(if needed) :
                </label>
                <input
                  type="url"
                  onChange={(e) =>
                    this.props.updateTestQuestionDataWithKey({
                      questionIndex: this.props.questionIndex,
                      sectionIndex: this.props.sectionIndex,
                      key: "questionLink",
                      value: e.target.value,
                    })
                  }
                  value={questionLink}
                  placeholder="Paste your link here"
                  className="border border-gray-300 my-1 outline-gray-300 p-2 h-8 w-[70%]"
                />
              </div>
            )}
            <div
              className={`flex gap-1 md:gap-5  my-1 ${type === "integer" || type === "description"
                ? "flex-col items-start sm:flex-row"
                : ""
                }  ${type === "record"
                  ? "flex-col items-start sm:flex-row sm:items-center"
                  : type === "checkbox"
                    ? "flex-col items-start lg:flex-row"
                    : ""
                }`}
            >
              <div className="flex flex-col gap-[0.3rem]">
                <label className="text-[1rem] font-semibold font-['nunito']">
                  Answer Type<span className="text-red-500 mx-0.5">*</span>
                </label>
                <select
                  onChange={(e) => this.changeType(e)}
                  className="border border-gray-300 h-[2rem] outline-gray-300 px-1"
                  value={type}
                >
                  {/* <option value="">select type</option> */}
                  <option value="description">Descriptive</option>
                  <option value="integer">Integer</option>
                  <option value="checkbox">Multiple Choice</option>
                  <option value="record">Record</option>
                  <option value="file">File Upload</option>
                </select>
              </div>

              <div className="col-span-2">
                {type === "description" && (
                  <div className="grid md:grid-cols-2 gap-1 md:gap-5">
                    <div className="flex flex-col gap-1 justify-center items-start">
                      <label className="text-[1rem] font-semibold font-['nunito']">
                        Default Answer Type
                      </label>
                      <select
                        onChange={(e) =>
                          this.props.updateTestQuestionDataWithKey({
                            questionIndex: this.props.questionIndex,
                            sectionIndex: this.props.sectionIndex,
                            key: "ansLanguageType",
                            value: e.target.value,
                          })
                        }
                        className="border border-gray-300 outline-gray-300 p-1"
                        value={ansLanguageType}
                      >
                        <option value="E">English</option>
                        <option value="H">Hindi</option>
                      </select>
                    </div>
                  </div>
                )}
                {type === "integer" && (
                  <div className="grid md:grid-cols-2 gap-1 md:gap-5">
                    <div className="flex flex-col gap-1 justify-center items-start">
                      <label className="text-[1rem] font-semibold font-['nunito']">
                        Integer Answer
                      </label>
                      <input
                        type="text"
                        onChange={(e) => this.changeIntegerAnswer(e)}
                        value={ans_integer}
                        placeholder="Answer"
                        className="border border-gray-300 my-1 outline-gray-300 p-2 h-8"
                      />
                    </div>
                  </div>
                )}
                {type === "file" && (
                  <div className="flex flex-col gap-1 items-start">
                    <p className="text-[1rem] font-semibold font-['nunito']">
                      File Type
                    </p>
                    <select
                      onChange={(e) =>
                        this.props.updateTestQuestionDataWithKey({
                          questionIndex: this.props.questionIndex,
                          sectionIndex: this.props.sectionIndex,
                          key: "ansFileType",
                          value: e.target.value,
                        })
                      }
                      className="border border-gray-300 outline-gray-300 p-1"
                      value={ansFileType}
                    >
                      <option value="Any">All Files</option>
                      <option value="Image">Image</option>
                      <option value="Video">Video</option>
                      <option value="Audio">Audio</option>
                      <option value="PDFs">PDFs</option>
                      <option value="DOCs">DOCs</option>
                    </select>
                  </div>
                )}
                {type === "record" && (
                  <>
                    <div className="flex gap-x-5">
                      <div className="flex flex-col gap-1 items-start">
                        <p className="text-[1rem] font-semibold font-['nunito']">
                          File Type
                        </p>
                        <select
                          onChange={(e) =>
                            this.props.updateTestQuestionDataWithKey({
                              questionIndex: this.props.questionIndex,
                              sectionIndex: this.props.sectionIndex,
                              key: "ansFileType",
                              value: e.target.value,
                            })
                          }
                          className="border border-gray-300 outline-gray-300 p-1 
                    "
                          value={ansFileType}
                        >
                          <option value="Video">Video</option>
                          <option value="Audio">Audio</option>
                        </select>
                      </div>
                      <div className="flex flex-col gap-1 items-start">
                        <p className="text-[1rem] font-semibold font-['nunito']">
                          Preparation Time
                        </p>
                        <select
                          onChange={(e) =>
                            this.props.updateTestQuestionDataWithKey({
                              questionIndex: this.props.questionIndex,
                              sectionIndex: this.props.sectionIndex,
                              key: "preparation_time",
                              value: e.target.value,
                            })
                          }
                          className="border border-gray-300 outline-gray-300 p-1 
                    "
                          value={preparation_time}
                        >
                          {/* <option value="">Select</option> */}
                          <option value="5000">5 secs</option>
                          <option value="15000">15 secs</option>
                          <option value="30000">30 secs</option>
                          <option value="60000">1 min</option>
                          <option value="300000">5 mins</option>
                          <option value="600000">10 mins</option>
                        </select>
                      </div>
                      <div className="flex flex-col gap-1 items-start">
                        <p className="text-[1rem] font-semibold font-['nunito']">
                          Answer Time
                        </p>
                        <select
                          onChange={(e) =>
                            this.props.updateTestQuestionDataWithKey({
                              questionIndex: this.props.questionIndex,
                              sectionIndex: this.props.sectionIndex,
                              key: "answer_time",
                              value: e.target.value,
                            })
                          }
                          className="border border-gray-300 outline-gray-300 p-1 
                    "
                          value={answer_time}
                        >
                          {/* <option value="">Select</option> */}
                          <option value="5000">5 secs</option>
                          <option value="15000">15 secs</option>
                          <option value="30000">30 secs</option>
                          <option value="60000">1 min</option>
                          <option value="120000">2 min</option>
                          <option value="180000">3 min</option>
                          <option value="240000">4 min</option>
                          <option value="300000">5 mins</option>
                          <option value="600000">10 mins</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}
                {type === "checkbox" ? (
                  <div className="flex mb-2 flex-col md:flex-row items-start md:items-center justify-start gap-1 md:gap-10 md:gap-5">
                    <div className="flex flex-row md:flex-col gap-4 md:gap-1 justify-center items-start">
                      <label className="text-[1rem] font-semibold font-['nunito']">
                        Options Selection type
                      </label>
                      <select
                        onChange={(e) => this.changeOptionType(e.target.value)}
                        className="border border-gray-300 outline-gray-300 p-1"
                      >
                        <option value="single">Single Choice</option>
                        <option value="multi">Multiple Choice</option>
                      </select>
                    </div>
                    <div className="flex flex-row md:flex-col gap-4 md:gap-1 justify-center items-start">
                      <label className="text-[1rem] font-semibold font-['nunito']">
                        Options type
                      </label>
                      <select
                        onChange={(e) =>
                          this.changeOptionValueTypes(e.target.value)
                        }
                        value={optionsValueType}
                        className="border border-gray-300 outline-gray-300 p-1"
                      >
                        <option value="text">Text</option>
                        <option value="file">Files</option>
                      </select>
                    </div>

                    {optionsValueType === "file" && (
                      <div className="flex flex-col gap-1 justify-center items-start">
                        <label className="text-[1rem] font-semibold font-['nunito']">
                          File Size
                        </label>
                        <select
                          onChange={(e) =>
                            this.changefileOptionsSize(e.target.value)
                          }
                          value={fileOptionsSize}
                          className="border border-gray-300 outline-gray-300 p-1"
                        >
                          <option value="75">Small</option>
                          <option value="125">Medium</option>
                          <option value="175">Large</option>
                        </select>
                      </div>
                    )}
                    <div className="flex flex-row items-center">
                      <input
                        className="h-4 w-4 mr-3"
                        type="checkbox"
                        name="is_mcq_options_random"
                        role="switch"
                        checked={
                          this.props.test.components[this.props.sectionIndex]
                            .components[this.props.questionIndex]
                            .is_mcq_options_random
                        }
                        onChange={() =>
                          this.props.updateTestQuestionDataWithKey({
                            questionIndex: this.props.questionIndex,
                            sectionIndex: this.props.sectionIndex,
                            key: "is_mcq_options_random",
                            value:
                              !this.props.test.components[
                                this.props.sectionIndex
                              ].components[this.props.questionIndex]
                                .is_mcq_options_random,
                          })
                        }
                      />
                      <label
                        htmlFor="is_mcq_options_random"
                        className="text-[1rem] font-semibold font-['nunito']"
                      >
                        Randomize MCQ Options
                      </label>
                    </div>
                  </div>
                ) : (
                  <> </>
                )}
              </div>
            </div>
            {type === "checkbox" && renderOptions()}
          </>
        ) : section.section_type === "PSQL" ? (
          <div className="flex gap-1 md:gap-5 items-center my-4">
            <div className="flex flex-col">
              <label htmlFor="uploadAnsCSV">Upload valid ans CSV</label>
              <input
                type="file"
                name="uploadAnsCSV"
                id="uploadAnsCSV"
                accept=".csv"
                onChange={(e) => {
                  const input = e.target.files[0];
                  if (input) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      const text = e.target.result;
                      this.changePostgreSQLAnswer(text);
                    };
                    reader.readAsText(input);
                  }
                }}
                className="my-2"
              />
              {questionData?.valid_answer_csv ? (
                <p className="font-bold text-[1rem] text-[#006400] font-['nunito']">
                  File Uploaded
                </p>
              ) : null}
            </div>
            <div className="flex flex-col">
              <label htmlFor="selectTable">Select Table</label>
              <select
                onChange={(e) =>
                  this.props.updateTestQuestionDataWithKey({
                    questionIndex: this.props.questionIndex,
                    sectionIndex: this.props.sectionIndex,
                    key: "select_postgresql_table",
                    value: e.target.value,
                  })
                }
                className="border border-gray-300 my-2 outline-gray-300 p-1"
                value={select_postgresql_table}
              >
                <option>Select a table</option>
                {this.props.tables
                  .map((e) => e.table_name)
                  .map((table, i) => {
                    return (
                      <option value={table} key={i}>
                        {table}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        ) : (
          <div className="pr-4 md:pr-0">
            <div className="flex flex-col my-2">
              <label>Default Test Case</label>
              <textarea
                rows="4"
                className="block p-2.5 w-full text-sm rounded-lg border  border-gray-600"
                placeholder="Please provide default test case"
                value={coding_testcases || ""}
                onChange={(e) =>
                  this.props.updateTestQuestionDataWithKey({
                    questionIndex: this.props.questionIndex,
                    sectionIndex: this.props.sectionIndex,
                    key: "coding_testcases",
                    value: e.target.value,
                  })
                }
              />
            </div>

            <div className="flex gap-10 ">
              <div className="flex w-64 flex-col my-2">
                <div className="flex items-center">
                  <label>Memory Limit (in MB)</label>
                  <img
                    src={eye}
                    alt="info"
                    loading="lazy"
                    title="Memory Limit (2-512) mb"
                    className="ml-2"
                  />
                </div>
                <input
                  type="number"
                  name="coding_memory_limit"
                  min="2"
                  max="512"
                  value={coding_memory_limit}
                  placeholder="Enter memory limit for execution"
                  onChange={(e) => this.handleMemoryLimit(e)}
                  className="border border-gray-300 outline-gray-300 p-2"
                />
                {this.state.memoryError && (
                  <p className="text-red-600 text-xs p-[2px]">
                    Memory Limit must be 2-512 mb only
                  </p>
                )}
              </div>
              <div className="flex w-64 flex-col my-2">
                <div className="flex items-center">
                  <label>Time Limit (in Seconds)</label>
                  <img
                    src={eye}
                    alt="info"
                    loading="lazy"
                    title="Time Limit (1-5)"
                    className="ml-2"
                  />
                </div>{" "}
                <input
                  type="number"
                  min="1"
                  max="5"
                  name="coding_time_limit"
                  value={coding_time_limit}
                  placeholder="Enter time limit for execution"
                  onChange={(e) => this.handleTimeLimit(e)}
                  className="border border-gray-300 outline-gray-300 p-2"
                />
                {this.state.timeLimitError && (
                  <p className="text-red-600 text-xs p-[2px]">
                    Time Limit must be 1-5 secs only
                  </p>
                )}
              </div>
            </div>

            <div>
              {[...Array(std_input_file?.length).keys()].map((_, ind) => (
                <div
                  style={{ boxShadow: "0 2.5px 2.5px #d4d4d4" }}
                  className="flex flex-col relative flex-wrap gap-3 mt-2 mb-4 bg-[#fcfcfc]  p-2 rounded"
                  key={`codinginput_${ind}`}
                >
                  {testcase_weightage?.[ind] ? (
                    <div className="absolute bg-none outline-none border-none top-4 right-4 cursor-pointer z-50">
                      <h1>Weightage : {testcase_weightage?.[ind]}%</h1>
                    </div>
                  ) : null}

                  <div>
                    <label className="mb-0 mr-5 small">Standard Input</label>
                    <input
                      type="file"
                      name="std_input_file"
                      accept=".txt"
                      onChange={(e) => this.handleUploadFiles(e)}
                      style={{ margin: "0 0 0 5px" }}
                    />
                    {std_input_file && std_input_file[ind] ? (
                      <div>
                        <span>{std_input_file[ind]}</span>
                        <button
                          className="mx-4 border border-gray-600 px-2 py-[2px] bg-gray-200 hover:bg-gray-300"
                          onClick={() => this.getUrl(std_input_file[ind])}
                        >
                          View File
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <label className="mb-0 mr-5 small">Standard Output</label>
                    <input
                      type="file"
                      name="std_output_file"
                      accept=".txt"
                      onChange={(e) => this.handleUploadFiles(e)}
                      style={{ margin: "0 0 0 5px" }}
                    />
                    {std_output_file && std_output_file[ind] ? (
                      <div>
                        <span>{std_output_file[ind]}</span>
                        <button
                          className="mx-4 border border-gray-600 px-2 py-[2px] bg-gray-200 hover:bg-gray-300"
                          onClick={() => this.getUrl(std_output_file[ind])}
                        >
                          View File
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div
          className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 
         my-2 mt-4"
        >
          <div className="flex flex-col w-36">
            <label className="text-[1rem] font-semibold font-['nunito']">
              Max Marks<span className="text-red-500 mx-0.5">*</span>
            </label>
            <input
              min="0"
              type="number"
              onChange={(e) => this.changeMarks(e)}
              value={marks}
              placeholder="Max Marks"
              className="border border-gray-300 my-1 outline-gray-300 p-2 h-8"
            />
          </div>
          {/* <div className="my-2 items-center pt-5">
            <input
              type="checkbox"
              checked={isRequired}
              onChange={(e) =>
                this.props.updateTestQuestionDataWithKey({
                  questionIndex: this.props.questionIndex,
                  sectionIndex: this.props.sectionIndex,
                  key: "isRequired",
                  value: e.target.checked,
                })
              }
              className="h-4 w-4"
            />
            <label className="mx-2 text-[1rem] font-semibold font-['nunito']">
              Required
            </label>
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    test: test.test,
    testId: test.test._id,
    tables: test.tables,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTestQuestionDataWithKey: (data) =>
      dispatch(updateTestQuestionDataWithKey(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TestQuestionEditor));
