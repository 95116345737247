import { ASSIGN_TEST_SUCCESS } from "../actionTypes";
import {
  ASKING_QUESTION_TYPE,
  ASK_QUESTION_FAILED,
  ASK_QUESTION_REQUEST,
  ASK_QUESTION_SUCCESS,
  ASSIGN_INTERVIEW_FAILED,
  ASSIGN_INTERVIEW_REQUEST,
  ASSIGN_INTERVIEW_SUCCESS,
  ASSIGN_TEST_FAILED,
  ASSIGN_TEST_REQUEST,
  CREATE_CHAT_FAILED,
  CREATE_CHAT_REQUEST,
  CREATE_CHAT_SUCCESS,
  EXPORT_CSV_FAILED,
  EXPORT_CSV_REQUEST,
  EXPORT_CSV_SUCCESS,
  GET_CHAT_FAILED,
  GET_CHAT_REQUEST,
  GET_CHAT_SUCCESS,
  GET_DEPARTMENT_FAILED,
  GET_DEPARTMENT_REQUEST,
  GET_DEPARTMENT_SUCCESS,
  GET_PROFILE_FAILED,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_RESUME_DETAILS_FAILED,
  GET_RESUME_DETAILS_REQUEST,
  GET_RESUME_DETAILS_SUCCESS,
  PARSE_RESUME_FAILED,
  PARSE_RESUME_REQUEST,
  PARSE_RESUME_SUCCESS,
  REPARSE_RESUME_FAILED,
  REPARSE_RESUME_REQUEST,
  REPARSE_RESUME_SUCCESS,
  SELECTED_MAIL,
  SEND_EMAIL_FAILED,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  UPDATE_CHAT_FAILED,
  UPDATE_CHAT_REQUEST,
  UPDATE_CHAT_SUCCESS,
  UPDATE_STATUS_OF_RESUME,
  UPLOAD_FILE_FAILED,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
} from "../actionTypes/parseResume";

const initialState = {
  uploadFileLoading: false,
  askQuestionLoading: false,
  departmentLoading: false,
  profileLoading: false,
  parseResumeLoading: false,
  sendEmailLoading: false,
  exportCSVLoading: false,
  assignTestLoading: false,
  assignInterviewLoading: false,
  reparseLoading: false,
  getChatLoading: false,
  createChatLoading: false,

  departments: [],
  selectedMail: [],
  profiles: [],
  files: [],
  headers: {},
  questionAnswer: [],
  questioning_type:""
};

const parseResumeReducer = function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPLOAD_FILE_REQUEST:
      return { ...state, uploadFileLoading: true };

    case UPLOAD_FILE_SUCCESS:
      localStorage.setItem("resume_token", payload.token);
      return {
        ...state,
        uploadFileLoading: false,
      };

    case UPLOAD_FILE_FAILED:
      return { ...state, uploadFileLoading: false };

    case ASK_QUESTION_REQUEST:
      return { ...state, askQuestionLoading: true };

    case ASK_QUESTION_SUCCESS:
      console.log(payload);
      return {
        ...state,
        ...(!payload.type && { askQuestionLoading: false }),
        questionAnswer: [
          ...state.questionAnswer,
          { ...payload, time: new Date() },
        ],
      };

    case ASK_QUESTION_FAILED:
      return { ...state, askQuestionLoading: false };

    // case GET_DEPARTMENT_REQUEST:
    //   return { ...state, departmentLoading: true };

    // case GET_DEPARTMENT_SUCCESS:
    //   return {
    //     ...state,
    //     departments: payload.department_list,
    //     departmentLoading: false,
    //   };

    // case GET_DEPARTMENT_FAILED:
    //   return { ...state, departmentLoading: false };

    // case GET_PROFILE_REQUEST:
    //   return { ...state, profileLoading: true };

    // case GET_PROFILE_SUCCESS:
    //   return {
    //     ...state,
    //     profiles: payload.profile_list,
    //     profileLoading: false,
    //   };

    // case GET_PROFILE_FAILED:
    //   return { ...state, profileLoading: false };

    case PARSE_RESUME_REQUEST:
      return { ...state, parseResumeLoading: true };

    case PARSE_RESUME_SUCCESS:
      return {
        ...state,
        headers: payload.header,
        parseResumeLoading: false,
      };

    case PARSE_RESUME_FAILED:
      return { ...state, parseResumeLoading: false };

    case REPARSE_RESUME_REQUEST:
      return { ...state, reparseLoading: true };

    case REPARSE_RESUME_SUCCESS:
      const chatInd = state.questionAnswer.findIndex(
        (qa) => qa._id === payload.chat_id
      );
      // console.log(chatInd, state.questionAnswer[chatInd]);

      const updatedChat = state.questionAnswer[chatInd].message.result.map(
        (obj1) => {
          let matchingObject = payload.res.find(
            (obj2) => obj1._id === obj2._id
          );
          // console.log(matchingObject);
          return matchingObject ? matchingObject : obj1;
        }
      );

      const updatedQuestionAnswer = state.questionAnswer;
      updatedQuestionAnswer[chatInd].message = {
        ...updatedQuestionAnswer[chatInd].message,
        result: updatedChat,
      };
      return {
        ...state,
        questionAnswer: updatedQuestionAnswer,
        reparseLoading: false,
      };

    case REPARSE_RESUME_FAILED:
      return { ...state, reparseLoading: false };

    case GET_RESUME_DETAILS_REQUEST:
      return { ...state };

    case GET_RESUME_DETAILS_SUCCESS:
      return {
        ...state,
        questionAnswer: [payload],
      };

    case GET_RESUME_DETAILS_FAILED:
      return { ...state };

    case SEND_EMAIL_REQUEST:
      return { ...state, sendEmailLoading: true };

    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailLoading: false,
      };

    case SEND_EMAIL_FAILED:
      return { ...state, sendEmailLoading: false };

    // case UPDATE_STATUS_OF_RESUME:
    //   const updatedResume = state.questionAnswer[payload.ind].map((res) => {
    //     if (res._id === payload.id) return { ...res, status: payload.status };
    //     return res;
    //   });

    //   const updatetedQuestionAnswer = state.questionAnswer;
    //   updatetedQuestionAnswer[payload.ind] = updatedResume;

    //   return { ...state, questionAnswer: updatetedQuestionAnswer };

    case EXPORT_CSV_REQUEST:
      return { ...state, exportCSVLoading: true };

    case EXPORT_CSV_SUCCESS:
      return {
        ...state,
        exportCSVLoading: false,
      };

    case EXPORT_CSV_FAILED:
      return { ...state, exportCSVLoading: false };

    case ASSIGN_TEST_REQUEST:
      return { ...state, assignTestLoading: true };

    case ASSIGN_TEST_SUCCESS:
      return {
        ...state,
        assignTestLoading: false,
      };

    case ASSIGN_TEST_FAILED:
      return { ...state, assignTestLoading: false };

    case ASSIGN_INTERVIEW_REQUEST:
      return { ...state, assignInterviewLoading: true };

    case ASSIGN_INTERVIEW_SUCCESS:
      return {
        ...state,
        assignInterviewLoading: false,
      };

    case ASSIGN_INTERVIEW_FAILED:
      return { ...state, assignInterviewLoading: false };

    case GET_CHAT_REQUEST:
      return { ...state, getChatLoading: true };

    case GET_CHAT_SUCCESS:
      return {
        ...state,
        questionAnswer: payload,
        getChatLoading: false,
      };

    case GET_CHAT_FAILED:
      return { ...state, getChatLoading: false };

    case UPDATE_CHAT_REQUEST: {
      const ind = state.questionAnswer.findIndex(
        (c) => c._id === payload.chat_id
      );

      const updatetedQuestionAnswer = state.questionAnswer;
      updatetedQuestionAnswer[ind] = {
        ...state.questionAnswer[ind],
        message: {
          ...state.questionAnswer[ind].message,
          result: payload.message,
        },
      };

      return { ...state, questionAnswer: updatetedQuestionAnswer };
    }

    case UPDATE_CHAT_SUCCESS: {
      return { ...state };
    }
    // case UPDATE_CHAT_FAILED:
    //   return { ...state, UPDATEChatLoading: false };

    case CREATE_CHAT_REQUEST:
      return { ...state, createChatLoading: true };

    case CREATE_CHAT_SUCCESS:
      return {
        ...state,
        questionAnswer: [...state.questionAnswer, payload.data],
        createChatLoading: payload.loader,
      };

    case CREATE_CHAT_FAILED:
      return { ...state, createChatLoading: false };

    case SELECTED_MAIL:
      return { ...state, selectedMail: payload };

    case ASKING_QUESTION_TYPE:
      return { ...state, questioning_type: payload };

    default:
      return state;
  }
};

export default parseResumeReducer;
