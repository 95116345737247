import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/";
import "react-quill/dist/quill.snow.css";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

Sentry.init({
  dsn: "https://10dc5009cbe34b74b97a5d58800d4f1d@o1230365.ingest.sentry.io/6377015",
  integrations: [new BrowserTracing()],
  // beforeSend(event, hint) {
  //   alert(JSON.stringify(event));
  //   const error = hint.originalException;
  //   console.log(error);
  //   if (
  //     error &&
  //     error.message &&
  //     error.message.match(/database unavailable/i)
  //   ) {
  //     event.fingerprint = ["database-unavailable"];
  //   }
  //   return event;
  // },
  environment: "production",
  enabled: true,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => { };
  console.error = () => { };
  console.debug = () => { };
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
