import React from "react";
import ReactQuill from "react-quill";
// import proctor from "../../assets/img/svg/edit.svg";
import delete1 from "../../assets/img/svg/deletecolor.svg";
import { ReactComponent as Plus } from "../../assets/img/svg/plus4.svg";
import eye from "../../assets/img/svg/eye.svg";

// Store
import { connect } from "react-redux";

import { initialTestQuestionData2 } from "../../components/tests/utils";
import { modules } from "../../components/tests/utils/QuillModules";
import { uploadFile, viewFile } from "../../services/upload.service";
import {
  fetchUserTablesRequested,
  updateTestQuestionDataWithKey,
} from "../../store/actions/test";
import { DropDown } from "../../components/common/ReactSelect/ReactSelect";
import TagsInput from "../../components/common/TagInput";
import {
  addQuestionRequested,
  fetchAllTagsRequested,
  updateQuestionRequested,
  updateQuestionbankModal,
} from "../../store/actions/questionbank";
import CreatableMulti from "../../components/common/ReactSelect/SelectAndCreatable";
import { updateInterviewQuestion } from "../../services/interviewpanel.service";
import { getUserIdFromPeerId } from "../../components/common/utils";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../assets/js/toast_config";
import {
  updateCustomQuestionModal,
  updateInterviewQuestionSuccess,
} from "../../store/actions/interviewpanel";
import { updateExtractedQuestion } from "../../store/actions/extractQuestion";
import UniversalButton from "../../components/common/StandardButton/UniversalButton";

class AddQuestion extends React.Component {
  constructor() {
    super();
    this.state = {
      checkValues: [],
      selectedtags: [],
      section_type: "G",
      memoryError: false,
      timeLimitError: false,
      questionData: initialTestQuestionData2(),
      numberOfTestCases: 0,
      inputTestCases: [],
      outputTestCases: [],

      currInd: -1,
    };
    this.changeOptionType = this.changeOptionType.bind(this);
    this.changeOptionValueTypes = this.changeOptionValueTypes.bind(this);
    this.changeQuestion = this.changeQuestion.bind(this);
    this.changeQuestionFiles = this.changeQuestionFiles.bind(this);
    this.changeMcqOptionFiles = this.changeMcqOptionFiles.bind(this);
    this.handleUploadFiles = this.handleUploadFiles.bind(this);
    this.deleteQuestionFile = this.deleteQuestionFile.bind(this);
    this.changeMarks = this.changeMarks.bind(this);
    this.changeType = this.changeType.bind(this);
    this.addOption = this.addOption.bind(this);
    this.changeOption = this.changeOption.bind(this);
    this.deleteOption = this.deleteOption.bind(this);
    this.changePostgreSQLAnswer = this.changePostgreSQLAnswer.bind(this);
  }

  componentDidMount() {
    if (this.props.type === "E" || this.props.type === "EEQ") {
      const { currentQuestion, ind } = this.props;
      // const tags = currentQuestion.tags.map((tag) => {
      //   return { value: tag., label: tag };
      // });
      // console.log("tags", tags);

      if (ind !== undefined && ind !== null) {
        console.log(ind);
        this.setState((prev) => {
          return { ...prev, currInd: ind };
        });
      }
      this.setState((prev) => {
        return {
          ...prev,
          questionData: { ...this.state.questionData, ...currentQuestion },
          ...(currentQuestion.tags && {
            selectedtags: [...currentQuestion.tags],
          }),
        };
      });
      if (currentQuestion?.type === "sql" && this.props.tables.length === 0) {
        this.props.fetchUserTablesRequested();
      }
      if (
        currentQuestion?.type === "coding" &&
        currentQuestion?.std_input_file.length
      )
        this.setState((prev) => {
          return {
            ...prev,
            numberOfTestCases: currentQuestion?.std_input_file.length,
          };
        });
    }
  }

  // componentDidUpdate(prevState) {
  //   if (
  //     this.state.questionData?.type !== prevState.questionData?.type &&
  //     this.state.questionData?.type === "sql" &&
  //     this.props.tables.length === 0
  //   ) {
  //     this.props.fetchUserTablesRequested();
  //   }
  // }

  changeOptionType(type) {
    const value = type === "single" ? "radio" : "checkbox";
    this.changeQuestion("optionType", value);
  }

  changeOptionValueTypes(type) {
    this.changeQuestion("optionsValueType", type);
  }

  changefileOptionsSize(type) {
    this.changeQuestion("fileOptionsSize", type);
  }

  changeQuestion(key, value) {
    this.setState((prev) => {
      return {
        ...prev,
        questionData: {
          ...prev.questionData,
          [key]: value,
        },
      };
    });
  }

  changeQuestionFiles(event) {
    const question_files = this.state.questionData.question_files;

    ///issue
    uploadFile(event.target.files[0], {
      type: "TEST",
      id: "QUESTION-BANK",
    }).then((filename) => {
      this.changeQuestion(
        "question_files",
        question_files ? [...question_files, filename] : [filename]
      );
      //   this.props.updateTestQuestionDataWithKey({
      //     questionIndex: this.props.questionIndex,
      //     sectionIndex: this.props.sectionIndex,
      //     key: "question_files",
      //     value: question_files ? [...question_files, filename] : [filename],
      //   });
    });
  }

  changeMcqOptionFiles(event) {
    const options = this.state.questionData.options;

    //issue here need to resolve
    uploadFile(event.target.files[0], {
      type: "TEST",
      id: "QUESTION-BANK",
    }).then((filename) => {
      let bOptions = [...options];
      bOptions[event.target.name].option_file = filename;
      this.changeQuestion("options", bOptions);
      //   this.props.updateTestQuestionDataWithKey({
      //     questionIndex: this.props.questionIndex,
      //     sectionIndex: this.props.sectionIndex,
      //     key: "options",
      //     value: bOptions,
      //   });
    });
  }

  handleUploadFiles(e, ind) {
    uploadFile(e.target.files[0], { type: "TEST", id: "QUESTION-BANK" }).then(
      (filename) => {
        const testCases =
          e.target.name === "std_input_file"
            ? this.state.inputTestCases
            : this.state.outputTestCases;
        if (testCases[ind]) testCases[ind] = filename;
        else testCases.push(filename);
        this.setState((prev) => {
          return {
            ...prev,
            ...(e.target.name === "std_input_file"
              ? { inputTestCases: [...testCases] }
              : { outputTestCases: [...testCases] }),
          };
        });
        this.changeQuestion(e.target.name, [...testCases]);
      }
      // this.props.updateTestQuestionDataWithKey({
      //   questionIndex: this.props.questionIndex,
      //   sectionIndex: this.props.sectionIndex,
      //   key: e.target.name,
      //   value: [filename],
      // });
      //   }
    );
  }

  handleDeleteFile(ind) {
    const iTestCases = this.state.inputTestCases;
    const oTestCases = this.state.outputTestCases;
    const tmpWeightage = this.state.questionData.testcase_weightage;
    if (ind < tmpWeightage.length) tmpWeightage.splice(ind, 1);

    this.setState((prev) => {
      return {
        ...prev,
        numberOfTestCases: prev.numberOfTestCases - 1,
        inputTestCases: [...iTestCases.splice(ind, 1)],
        outputTestCases: [...oTestCases.splice(ind, 1)],
        ...(this.state.questionData.is_partial_marking && {
          questionData: {
            ...this.state.questionData,
            testcase_weightage: tmpWeightage,
          },
        }),
      };
    });
  }

  deleteQuestionFile(index) {
    const question = this.state.questionData;
    const _question_files = [...question.question_files];
    _question_files.splice(index, 1);
    this.changeQuestion("question_files", _question_files);
    // this.props.updateTestQuestionDataWithKey({
    //   questionIndex: this.props.questionIndex,
    //   sectionIndex: this.props.sectionIndex,
    //   key: "question_files",
    //   value: _question_files,
    // });
  }

  addTestCases() {
    this.setState((prev) => {
      const tmpWeightage = this.state.questionData.testcase_weightage;
      tmpWeightage.push("");
      const newState = {
        ...prev,
        numberOfTestCases: prev.numberOfTestCases + 1,
        questionData: {
          ...this.state.questionData,
          testcase_weightage: tmpWeightage,
        },
      };
      return newState;
    });
  }

  weightageOfTestCases(e, ind) {
    const tmpWeightage = this.state.questionData.testcase_weightage;
    if (ind > tmpWeightage.length) tmpWeightage.push(+e.target.value);
    else tmpWeightage[ind] = +e.target.value;
    this.setState((prev) => {
      return {
        ...prev,
        questionData: {
          ...this.state.questionData,
          testcase_weightage: tmpWeightage,
        },
      };
    });
  }

  deleteMcqOptionFile(index) {
    const options = this.state.questionData.options;
    let bOptions = [...options];
    bOptions[index].option_file = null;
    this.changeQuestion("options", bOptions);
  }

  changeMarks(e) {
    this.changeQuestion("marks", e.target.value);
  }

  changeType(e) {
    if (e.target.value === "sql") {
      this.props.fetchUserTablesRequested();
    }
    var changeDefaultSubType = "";
    if (e.target.value === "file") changeDefaultSubType = "Any";
    else if (e.target.value === "record") changeDefaultSubType = "Video";
    this.changeQuestion("type", e.target.value);
    this.changeQuestion("ansFileType", changeDefaultSubType);
    // this.props.updateTestQuestionDataWithKey({
    //   questionIndex: this.props.questionIndex,
    //   sectionIndex: this.props.sectionIndex,
    //   key: "type",
    //   value: e.target.value,
    // });
    // this.props.updateTestQuestionDataWithKey({
    //   questionIndex: this.props.questionIndex,
    //   sectionIndex: this.props.sectionIndex,
    //   key: "ansFileType",
    //   value: changeDefaultSubType,
    // });
  }

  addOption() {
    var optionIndex = 0;
    const options = this.state.questionData.options;
    const optionType = this.state.questionData.optionsValueType;
    if (options && options.length) {
      optionIndex = options.length;
    }
    let value =
      optionType === "text"
        ? options
          ? [...options, { name: "", optionIndex }]
          : [{ name: "", optionIndex }]
        : options
          ? [...options, { name: "", optionIndex, option_file: "" }]
          : [{ name: "", optionIndex, option_file: "" }];
    this.changeQuestion("options", value);
  }

  changeOption(value, name) {
    const options = this.state.questionData.options;
    let bOptions = [...options];
    bOptions[name].name = value;
    this.changeQuestion("options", bOptions);
    // this.props.updateTestQuestionDataWithKey({
    //   questionIndex: this.props.questionIndex,
    //   sectionIndex: this.props.sectionIndex,
    //   key: "options",
    //   value: bOptions,
    // });
  }

  optionAnswer(e, index) {
    const options = this.state.questionData.options;
    var tempvalues = [];
    if (e.target.type === "checkbox") {
      tempvalues = [...this.state.checkValues];
      tempvalues[index] = e.target.checked;
      this.setState({ checkValues: tempvalues });
    } else {
      tempvalues = new Array(options.length).fill(false);
      tempvalues[index] = true;
      this.setState({ checkValues: tempvalues });
    }
    this.changeQuestion("ans_mcq", tempvalues);
    // this.props.updateTestQuestionDataWithKey({
    //   questionIndex: this.props.questionIndex,
    //   sectionIndex: this.props.sectionIndex,
    //   key: "ans_mcq",
    //   value: tempvalues,
    // });
  }

  changeIntegerAnswer(e) {
    // const { test, questionIndex, sectionIndex } = this.props;4
    this.changeQuestion("ans_integer", e.target.value);
    // this.props.updateTestQuestionDataWithKey({
    //   questionIndex: questionIndex,
    //   sectionIndex: sectionIndex,
    //   key: "ans_integer",
    //   value: e.target.value,
    // });
  }

  handleMemoryLimit(e) {
    this.setState((prev) => {
      return {
        ...prev,
        memoryError: false,
      };
    });
    if (parseInt(e.target.value) > 512 || parseInt(e.target.value) < 2) {
      this.setState((prev) => {
        return {
          ...prev,
          memoryError: true,
        };
      });
    } else {
      this.changeQuestion("coding_memory_limit", e.target.value);
      //   this.props.updateTestQuestionDataWithKey({
      //     questionIndex: this.props.questionIndex,
      //     sectionIndex: this.props.sectionIndex,
      //     key: "coding_memory_limit",
      //     value: e.target.value,
      //   });
    }
  }

  handleTimeLimit(e) {
    this.setState((prev) => {
      return {
        ...prev,
        timeLimitError: false,
      };
    });
    if (parseInt(e.target.value) > 5 || parseInt(e.target.value) < 1) {
      this.setState((prev) => {
        return {
          ...prev,
          timeLimitError: true,
        };
      });
    } else {
      this.changeQuestion("coding_time_limit", e.target.value);
      //   this.props.updateTestQuestionDataWithKey({
      //     questionIndex: this.props.questionIndex,
      //     sectionIndex: this.props.sectionIndex,
      //     key: "coding_time_limit",
      //     value: e.target.value,
      //   });
    }
  }

  deleteOption(toDel) {
    const options = this.state.questionData.options;
    let bOptions = [...options];
    bOptions.splice(toDel, 1);
    const result = bOptions.map((option, index) => ({
      ...option,
      optionIndex: index,
    }));
    this.changeQuestion("options", result);
    // this.props.updateTestQuestionDataWithKey({
    //   questionIndex: this.props.questionIndex,
    //   sectionIndex: this.props.sectionIndex,
    //   key: "options",
    //   value: result,
    // });
  }

  // onChangeQuestionType(type) {
  //   this.setState((prev) => {
  //     return {
  //       ...prev,
  //       section_type: type,
  //       questionData: initialTestQuestionData2(),
  //     };
  //   });
  // }

  handleTagChange = (data) => {
    const tags = data.map((tag) => tag.tag_id);
    console.log("data", data);
    this.setState({
      selectedtags: [...data],
    });
    this.changeQuestion("tags", tags);
  };

  // handleChange = (data) => {
  //   this.setState({ selectedtags: data });
  //   const tags = data.map((tag) => tag.value);
  //   this.changeQuestion("tags", tags);
  // };

  async getUrl(filename) {
    if (filename && filename.length) {
      var fileUrl = await viewFile(filename, "TEST").then((res) => {
        // this.setState((prevState) => ({
        //   ...prevState,
        //   answerFilesUrl: res,
        // }));
        return res;
      });
    }

    window.open(fileUrl);
  }

  changePostgreSQLAnswer(value) {
    this.changeQuestion("valid_answer_csv", value);
  }

  onSubmitQuestionData() {
    const { questionData } = this.state;
    if (this.props.type === "EEQ") {
      this.props.updateExtractedQuestion({
        data: questionData,
        ind: this.state.currInd,
      });
      this.props.updateQuestionbankModal({ value: false });
      return;
    }

    const sumOfWeightage = questionData.testcase_weightage.reduce(
      (accum, sm) => accum + sm,
      0
    );

    if (
      (questionData.is_partial_marking && sumOfWeightage === 100) ||
      !questionData.is_partial_marking
    ) {
      if (this.props.type === "A" || this.props.type === "P")
        this.props.addQuestionRequested([questionData]);
      else if (this.props.type === "I") {
        const { components, currentSectionIndex } = this.props;
        let _components = [...components];
        _components[currentSectionIndex].components = [
          ...components[currentSectionIndex].components,
          { ...questionData },
        ];
        updateInterviewQuestion({
          data: { components: _components },
          id: this.props.interviewId,
        })
          .then((res) => {
            if (res.success) {
              this.props.updateInterviewQuestionSuccess(res.data.components);
              this.props.addQuestionRequested(questionData);
              if (this.props.interviewerPeerId) {
                this.props.socket.emit("send-selected-question", {
                  userId: getUserIdFromPeerId(this.props.interviewerPeerId),
                });
              }
            }
          })
          .catch((err) => {
            console.log("error", err);
            toast.error("Question not updated", defaultToastSetting);
          });
        this.props.updateCustomQuestionModal({ value: false });
      } else {
        this.props.updateQuestionRequested({
          updated_value: questionData,
          id: questionData._id,
        });
      }
    } else if (questionData.is_partial_marking && sumOfWeightage !== 100) {
      toast.warning(
        "sum of weightage of all the test cases should be 100",
        defaultToastSetting
      );
    }
  }

  render() {
    let {
      id,
      marks,
      type,
      question,
      options,
      isRequired,
      question_files,
      ansFileType,
      ansLanguageType,
      preparation_time,
      answer_time,
      ans_mcq,
      ans_integer,
      optionType,
      optionsValueType,
      is_mcq_options_random,
      fileOptionsSize,
      coding_description,
      difficulty,
      tags,
      coding_testcases,
      coding_memory_limit,
      coding_time_limit,
      std_input_file,
      std_output_file,
      select_postgresql_table,
    } = this.state.questionData;
    // console.log("QUES", this.state.questionData);
    const renderOptions = () => {
      return (
        <div className="w-full">
          <label className="">
            Options (Add and select the correct option(s) for auto grading)
          </label>
          {options &&
            options.map((data, index) => (
              <div
                className="flex w-full gap-3 justify-center items-center my-2"
                key={`${data.id}-option-${index}`}
              >
                <div className="flex-none">
                  <input
                    type={optionType}
                    style={{ marginLeft: "10px" }}
                    name={`option${index}`}
                    checked={ans_mcq[index] ? ans_mcq[index] : false}
                    className="h-4 w-4"
                    onChange={(e) => this.optionAnswer(e, index)}
                  />
                </div>
                <div className="grow w-full">
                  {/* {console.log("optionsva;ue", optionsValueType)} */}
                  {optionsValueType === "file" ? (
                    <>
                      <div className="my-2 gap-3">
                        <label className="font-semibold">Option Files</label>
                        <input
                          className="md:ml-3"
                          type="file"
                          name={index}
                          accept="audio/*,video/*,image/*"
                          onChange={(e) => this.changeMcqOptionFiles(e)}
                        />
                        <p className="text-sm text-gray-500">
                          Upload multiple files pertaining to question. Files
                          allowed: Images(JPG, PNG), Videos(MP4), Audios(MP3).
                        </p>
                        {data && renderOptionFiles(data.option_file, index)}
                      </div>
                    </>
                  ) : (
                    <>
                      <ReactQuill
                        modules={modules}
                        value={data.name || ""}
                        placeholder="Your option here"
                        onChange={(value) => this.changeOption(value, index)}
                        className="my-1"
                      />
                    </>
                  )}
                </div>
                <div className="flex-none">
                  <img
                    src={delete1}
                    title="Delete"
                    loading="lazy"
                    className="w-6 h-6 cursor-pointer"
                    alt="svg"
                    onClick={() => {
                      this.deleteOption(index);
                    }}
                  />
                </div>
              </div>
            ))}
          <div className="small my-1">
            <button
              className="flex gap-[0.4rem] items-center justify-center text-[#a0db32] border border-[#a0db32] outline-none focus:outline-none ease-linear transition-all duration-150 py-2 px-4 rounded-md font-semibold hover:bg-[#001437] hover:text-[#a0db32] mt-2"
              onClick={() => this.addOption()}
            >
              <Plus className="h-4 w-4 mx-2 [&>*]:!fill-[#a0db32] " />
              Add Option
            </button>
          </div>
        </div>
      );
    };

    const renderQuestionFiles = () => {
      return (
        <>
          {question_files && question_files.length > 0 && (
            <label className="mb-0 small">Uploaded Files</label>
          )}
          {question_files.map((filename, index) => (
            <p className="mb-0" key={index}>
              {filename}{" "}
              <img
                src={delete1}
                title="delete"
                loading="lazy"
                className="w-6 h-6 hoverPointer"
                alt="svg"
                onClick={() => this.deleteQuestionFile(index)}
              />
            </p>
          ))}
        </>
      );
    };

    const renderOptionFiles = (file, index) => {
      return (
        <>
          {/* {mcqoptionsFiles && mcqoptionsFiles.length > 0 && (
            <label className="mb-0 small">Uploaded File</label>
          )}
          {question_files.map((filename, index) => ( */}
          {file && (
            <p className="flex gap-5 py-2">
              {file}{" "}
              <img
                src={delete1}
                title="delete"
                loading="lazy"
                className="w-6 h-6 hoverPointer"
                alt="svg"
                onClick={() => this.deleteMcqOptionFile(index)}
              />
            </p>
          )}
          {/* ))} */}
        </>
      );
    };
    return (
      <div className="flex flex-col">
        {/* {this.props.type === "A" && ( */}
        {/* <div className="flex gap-x-3 items-center">
          <select
            id="small"
            value={this.state.section_type}
            onChange={(e) => this.onChangeQuestionType(e.target.value)}
            className="w-[30%] px-3 py-1.5 shadow-md font-['poppins'] font-normal  text-md text-gray-900 bg-gray-50 rounded-lg"
          >
            <option value="G">General</option>
            <option value="C">Coding</option>
          </select>
        </div> */}
        {/* )} */}
        <div className="flex gap-5 mt-2">
          <select
            id="small"
            value={difficulty}
            onChange={(e) => this.changeQuestion("difficulty", e.target.value)}
            className="px-3 py-1.5 shadow-md font-['poppins'] font-normal  text-md text-gray-900 bg-gray-50 rounded-lg"
          >
            <option>Select Difficulty</option>
            <option value="E">Easy</option>
            <option value="M">Medium</option>
            <option value="H">Hard</option>
          </select>
          <CreatableMulti
            temp_college_data={this.state.selectedtags}
            handleTagChange={this.handleTagChange}
          />
          {/* <DropDown
            value={this.state.selectedtags}
            options={this.props.questionTags}
            handleChange={this.handleChange}
            multi={true}
            isCreatable={true}
            width={300}
            placeholder="Select Tags"
          /> */}
        </div>
        <div className="shadow-md rounded-lg max-h-[60vh] overflow-y-auto p-2 my-2 scrollbar">
          <div className="flex flex-col">
            <label className="font-semibold">Question</label>
            <ReactQuill
              modules={modules}
              value={question || ""}
              placeholder="Enter your question"
              onChange={(value) => this.changeQuestion("question", value)}
              className="my-1"
            />
          </div>
          {this.state.section_type === "G" ? (
            <>
              <div className="my-2 gap-3">
                <label className="font-semibold">Question Files</label>
                <input
                  className="md:ml-3"
                  type="file"
                  name="file"
                  accept="audio/*,video/*,image/*"
                  onChange={(e) => this.changeQuestionFiles(e)}
                />
                <p className="text-sm text-gray-500">
                  Upload multiple files pertaining to question. Files allowed:
                  Images(JPG, PNG), Videos(MP4), Audios(MP3).
                </p>
                {question_files && renderQuestionFiles()}
              </div>

              <div className="flex gap-1 md:gap-5 items-center my-1">
                <div className="flex flex-col">
                  <label className="">Answer Type</label>
                  <div className="flex gap-[3rem] items-center">
                    <select
                      onChange={(e) => this.changeType(e)}
                      className="border border-gray-300 my-1 outline-gray-300 p-1"
                      value={type}
                    >
                      <option>Select Type</option>
                      <option value="description">Descriptive</option>
                      <option value="integer">Integer</option>
                      <option value="checkbox">Multiple Choice</option>
                      <option value="record">Record</option>
                      <option value="file">File Upload</option>
                      <option value="coding">Coding</option>
                      <option value="sql">Sql</option>
                    </select>
                    {type === "coding" ? (
                      <div className="flex gap-2 items-center">
                        <input
                          type="checkbox"
                          id="is_partial_marking"
                          name="is_partial_marking"
                          className="outline-none bg-none"
                          checked={this.state.questionData.is_partial_marking}
                          onChange={() =>
                            this.setState((prev) => {
                              return {
                                ...prev,
                                questionData: {
                                  ...this.state.questionData,
                                  is_partial_marking:
                                    !this.state.questionData.is_partial_marking,
                                },
                              };
                            })
                          }
                        />
                        <label className="">is_partial_marking</label>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-span-2">
                  {type === "description" && (
                    <div className="grid md:grid-cols-2 gap-1 md:gap-5">
                      <div className="flex flex-col gap-1 justify-center items-start">
                        <label>Default Answer Type</label>
                        <select
                          onChange={(e) =>
                            this.changeQuestion(
                              "ans_language_type",
                              e.target.value
                            )
                          }
                          className="border border-gray-300 outline-gray-300 p-1"
                          value={ansLanguageType}
                        >
                          <option>Select Language</option>
                          <option value="E">English</option>
                          <option value="H">Hindi</option>
                        </select>
                      </div>
                    </div>
                  )}
                  {type === "integer" && (
                    <div className="grid md:grid-cols-2 gap-1 md:gap-5">
                      <div className="flex flex-col gap-1 justify-center items-start">
                        <label>Integer Answer</label>
                        <input
                          type="text"
                          onChange={(e) => this.changeIntegerAnswer(e)}
                          value={ans_integer}
                          placeholder="Answer"
                          className="border border-gray-300 my-1 outline-gray-300 p-2 h-8"
                        />
                      </div>
                    </div>
                  )}
                  {type === "file" && (
                    <select
                      onChange={(e) =>
                        this.changeQuestion("ansFileType", e.target.value)
                      }
                      className="border border-gray-300 mt-6 outline-gray-300 p-1"
                      value={ansFileType}
                    >
                      <option>Select File Type</option>
                      <option value="Any">All Files</option>
                      <option value="Image">Image</option>
                      <option value="Video">Video</option>
                      <option value="Audio">Audio</option>
                      <option value="PDFs">PDFs</option>
                      <option value="DOCs">DOCs</option>
                    </select>
                  )}
                  {type === "record" && (
                    <>
                      <div className="flex gap-x-5">
                        <select
                          onChange={(e) =>
                            this.changeQuestion("ansFileType", e.target.value)
                          }
                          className="border border-gray-300 mt-6 outline-gray-300 p-1 
                    "
                          value={ansFileType}
                        >
                          <option>Select Record Type</option>
                          <option value="Video">Video</option>
                          <option value="Audio">Audio</option>
                        </select>
                        {/* {this.props.test.is_preparation_time && ( */}
                        <select
                          onChange={(e) =>
                            this.changeQuestion(
                              "preparation_time",
                              e.target.value
                            )
                          }
                          className="border border-gray-300 mt-6 outline-gray-300 p-1 
                    "
                          value={preparation_time}
                        >
                          <option>Select Preparation Time</option>
                          <option value="5000">5 secs</option>
                          <option value="15000">15 secs</option>
                          <option value="30000">30 secs</option>
                          <option value="60000">1 min</option>
                          <option value="300000">5 mins</option>
                          <option value="600000">10 mins</option>
                        </select>
                        <select
                          onChange={(e) =>
                            this.changeQuestion("answer_time", e.target.value)
                          }
                          className="border border-gray-300 mt-6 outline-gray-300 p-1 
                    "
                          value={answer_time}
                        >
                          <option>Select Answer Time</option>
                          <option value="5000">5 secs</option>
                          <option value="15000">15 secs</option>
                          <option value="30000">30 secs</option>
                          <option value="60000">1 min</option>
                          <option value="300000">5 mins</option>
                          <option value="600000">10 mins</option>
                        </select>
                        {/* )} */}
                      </div>
                    </>
                  )}

                  {type === "sql" && (
                    <div className="flex gap-1 md:gap-5 items-center my-4">
                      <div className="flex flex-col">
                        <label htmlFor="uploadAnsCSV">
                          Upload valid ans CSV
                        </label>
                        <input
                          type="file"
                          name="uploadAnsCSV"
                          id="uploadAnsCSV"
                          accept=".csv"
                          onChange={(e) => {
                            const input = e.target.files[0];
                            if (input) {
                              const reader = new FileReader();
                              reader.onload = (e) => {
                                const text = e.target.result;
                                this.changePostgreSQLAnswer(text);
                              };
                              reader.readAsText(input);
                            }
                          }}
                          className="my-2"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="selectTable">Select Table</label>
                        <select
                          onChange={(e) =>
                            this.changeQuestion(
                              "select_postgresql_table",
                              e.target.value
                            )
                          }
                          className="border border-gray-300 my-2 outline-gray-300 p-1"
                          value={select_postgresql_table}
                        >
                          <option>Select a table</option>
                          {this.props.tables
                            .map((e) => e.table_name)
                            .map((table, i) => {
                              return (
                                <option value={table} key={i}>
                                  {table}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  )}

                  {type === "checkbox" ? (
                    <div className="flex items-center justify-start  gap-10 md:gap-5">
                      <div className="flex flex-col gap-1 justify-center items-start">
                        <label>Options Selection type</label>
                        <select
                          onChange={(e) =>
                            this.changeOptionType(e.target.value)
                          }
                          className="border border-gray-300 outline-gray-300 p-1"
                          value={optionType}
                        >
                          <option>Select Options Type</option>
                          <option value="single">Single Choice</option>
                          <option value="multi">Multiple Choice</option>
                        </select>
                      </div>
                      <div className="flex flex-col gap-1 justify-center items-start">
                        <label>Options type</label>
                        <select
                          onChange={(e) =>
                            this.changeOptionValueTypes(e.target.value)
                          }
                          value={optionsValueType}
                          className="border border-gray-300 outline-gray-300 p-1"
                        >
                          <option>Select Option Type</option>
                          <option value="text">Text</option>
                          <option value="file">Files</option>
                        </select>
                      </div>

                      {optionsValueType === "file" && (
                        <div className="flex flex-col gap-1 justify-center items-start">
                          <label>File Size</label>
                          <select
                            onChange={(e) =>
                              this.changefileOptionsSize(e.target.value)
                            }
                            value={fileOptionsSize}
                            className="border border-gray-300 outline-gray-300 p-1"
                          >
                            <option>Select File Size</option>
                            <option value="75">Small</option>
                            <option value="125">Medium</option>
                            <option value="175">Large</option>
                          </select>
                        </div>
                      )}
                      <div className="mt-5">
                        <input
                          className="h-4 w-4 mr-3"
                          type="checkbox"
                          name="is_mcq_options_random"
                          role="switch"
                          checked={is_mcq_options_random}
                          onChange={() =>
                            this.changeQuestion(
                              "is_mcq_options_random",
                              !is_mcq_options_random
                            )
                          }
                        />
                        <label htmlFor="is_mcq_options_random">
                          Randomize MCQ Options
                        </label>
                      </div>
                    </div>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>
              {type === "checkbox" && renderOptions()}
              {type === "coding" && (
                <>
                  <div className="flex flex-col my-2">
                    <label>Default Test Case</label>
                    <textarea
                      rows="4"
                      className="block p-2.5 w-full text-sm rounded-lg border  border-gray-600"
                      placeholder="Please provide default test case"
                      value={coding_testcases || ""}
                      onChange={(e) =>
                        this.changeQuestion("coding_testcases", e.target.value)
                      }
                    />
                    {/* <input
                name="coding_description"
                value={coding_description}
                onChange={(e) =>
                  this.props.updateTestQuestionDataWithKey({
                    questionIndex: this.props.questionIndex,
                    sectionIndex: this.props.sectionIndex,
                    key: "coding_description",
                    value: e.target.value,
                  })
                }
                className="border border-gray-300 outline-gray-300 p-2"
                placeholder="Test Description"
              /> */}
                  </div>

                  <div className="flex gap-10">
                    <div className="flex w-64 flex-col my-2">
                      <div className="flex items-center">
                        <label>Memory Limit (in MB)</label>
                        <img
                          src={eye}
                          alt="info"
                          loading="lazy"
                          title="Memory Limit (2-512) mb"
                          className="ml-2"
                        />
                      </div>
                      <input
                        type="number"
                        name="coding_memory_limit"
                        min="2"
                        max="512"
                        value={coding_memory_limit}
                        placeholder="Enter memory limit for execution"
                        onChange={
                          (e) => this.handleMemoryLimit(e)
                          // this.props.updateTestQuestionDataWithKey({
                          //   questionIndex: this.props.questionIndex,
                          //   sectionIndex: this.props.sectionIndex,
                          //   key: "coding_memory_limit",
                          //   value: e.target.value,
                          // })
                        }
                        className="border border-gray-300 outline-gray-300 p-2"
                      />
                      {this.state.memoryError && (
                        <p className="text-red-600 text-xs p-[2px]">
                          Memory Limit must be 2-512 mb only
                        </p>
                      )}
                    </div>
                    <div className="flex w-64 flex-col my-2">
                      <div className="flex items-center">
                        <label>Time Limit (in Seconds)</label>
                        <img
                          src={eye}
                          alt="info"
                          loading="lazy"
                          title="Time Limit (1-5)"
                          className="ml-2"
                        />
                      </div>{" "}
                      <input
                        type="number"
                        min="1"
                        max="5"
                        name="coding_time_limit"
                        value={coding_time_limit}
                        placeholder="Enter time limit for execution"
                        onChange={
                          (e) => this.handleTimeLimit(e)
                          // this.props.updateTestQuestionDataWithKey({
                          //   questionIndex: this.props.questionIndex,
                          //   sectionIndex: this.props.sectionIndex,
                          //   key: "coding_time_limit",
                          //   value: e.target.value,
                          // })
                        }
                        className="border border-gray-300 outline-gray-300 p-2"
                      />
                      {this.state.timeLimitError && (
                        <p className="text-red-600 text-xs p-[2px]">
                          Time Limit must be 1-5 secs only
                        </p>
                      )}
                    </div>
                  </div>

                  <button
                    className="flex gap-[0.4rem] items-center justify-center text-[#a0db32] border border-[#a0db32] outline-none focus:outline-none ease-linear transition-all duration-150 py-2 px-4 rounded-md font-semibold hover:bg-[#001437] hover:text-[#a0db32] mt-2"
                    onClick={() => this.addTestCases()}
                  >
                    Add new Test Case
                  </button>
                  {this.state.numberOfTestCases > 0
                    ? [...Array(this.state.numberOfTestCases).keys()].map(
                      (_, ind) => (
                        <div
                          key={`testCases-${ind}`}
                          style={{ boxShadow: "0 2.5px 2.5px #d4d4d4" }}
                          className="flex flex-col relative flex-wrap gap-3 mt-2 mb-4 bg-[#fcfcfc]  p-2 rounded"
                        >
                          <button
                            className="absolute bg-none outline-none border-none top-4 right-4 cursor-pointer z-50"
                            onClick={() => this.handleDeleteFile(ind)}
                          >
                            <img
                              src={delete1}
                              title="Delete"
                              loading="lazy"
                              className="w-4 h-4"
                              alt="svg"
                            />
                          </button>
                          <div className="flex flex-col gap-2">
                            {this.state.questionData.is_partial_marking ? (
                              <div className="flex gap-2 items-center">
                                <label className="mb-0 mr-5 small">
                                  Weightage:
                                </label>
                                <input
                                  type="text"
                                  name="weightage"
                                  value={
                                    this.state.questionData
                                      .testcase_weightage[ind]
                                  }
                                  placeholder="In % out of 100"
                                  onChange={(e) =>
                                    this.weightageOfTestCases(e, ind)
                                  }
                                  className="outline-none rounded border-none px-2 py-1"
                                  style={{
                                    border: "1px solid gray",
                                    background: "#fcfcfc",
                                  }}
                                />
                              </div>
                            ) : null}

                            <div className="flex  relative flex-wrap gap-3 ">
                              <div>
                                <label className="mb-0 mr-5 small">
                                  Standard Input
                                </label>
                                <input
                                  type="file"
                                  name="std_input_file"
                                  accept=".txt"
                                  onChange={(e) =>
                                    this.handleUploadFiles(e, ind)
                                  }
                                // style={{ margin: "0 0 0 5px" }}
                                />
                                {std_input_file && std_input_file[ind] ? (
                                  <div>
                                    <span>{std_input_file[ind]}</span>
                                    <button
                                      className="mx-4 border border-gray-600 px-2 py-[2px] bg-gray-200 hover:bg-gray-300"
                                      onClick={() =>
                                        this.getUrl(std_input_file[ind])
                                      }
                                    >
                                      View File
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                <label className="mb-0 mr-5 small">
                                  Standard Output
                                </label>
                                <input
                                  type="file"
                                  name="std_output_file"
                                  accept=".txt"
                                  onChange={(e) => this.handleUploadFiles(e)}
                                // style={{ margin: "0 0 0 5px" }}
                                />
                                {std_output_file && std_output_file[ind] ? (
                                  <div>
                                    <span>{std_output_file[ind]}</span>
                                    <button
                                      className="mx-4 border border-gray-600 px-2 py-[2px] bg-gray-200 hover:bg-gray-300"
                                      onClick={() =>
                                        this.getUrl(std_output_file[ind])
                                      }
                                    >
                                      View File
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )
                    : null}
                </>
              )}
            </>
          ) : (
            <>
              <div className="flex flex-col my-2">
                <label>Default Test Case</label>
                <textarea
                  rows="4"
                  className="block p-2.5 w-full text-sm rounded-lg border  border-gray-600"
                  placeholder="Please provide default test case"
                  value={coding_testcases || ""}
                  onChange={(e) =>
                    this.changeQuestion("coding_testcases", e.target.value)
                  }
                />
                {/* <input
                name="coding_description"
                value={coding_description}
                onChange={(e) =>
                  this.props.updateTestQuestionDataWithKey({
                    questionIndex: this.props.questionIndex,
                    sectionIndex: this.props.sectionIndex,
                    key: "coding_description",
                    value: e.target.value,
                  })
                }
                className="border border-gray-300 outline-gray-300 p-2"
                placeholder="Test Description"
              /> */}
              </div>

              <div className="flex gap-10">
                <div className="flex w-64 flex-col my-2">
                  <div className="flex items-center">
                    <label>Memory Limit (in MB)</label>
                    <img
                      src={eye}
                      alt="info"
                      loading="lazy"
                      title="Memory Limit (2-512) mb"
                      className="ml-2"
                    />
                  </div>
                  <input
                    type="number"
                    name="coding_memory_limit"
                    min="2"
                    max="512"
                    value={coding_memory_limit}
                    placeholder="Enter memory limit for execution"
                    onChange={
                      (e) => this.handleMemoryLimit(e)
                      // this.props.updateTestQuestionDataWithKey({
                      //   questionIndex: this.props.questionIndex,
                      //   sectionIndex: this.props.sectionIndex,
                      //   key: "coding_memory_limit",
                      //   value: e.target.value,
                      // })
                    }
                    className="border border-gray-300 outline-gray-300 p-2"
                  />
                  {this.state.memoryError && (
                    <p className="text-red-600 text-xs p-[2px]">
                      Memory Limit must be 2-512 mb only
                    </p>
                  )}
                </div>
                <div className="flex w-64 flex-col my-2">
                  <div className="flex items-center">
                    <label>Time Limit (in Seconds)</label>
                    <img
                      src={eye}
                      alt="info"
                      loading="lazy"
                      title="Time Limit (1-5)"
                      className="ml-2"
                    />
                  </div>{" "}
                  <input
                    type="number"
                    min="1"
                    max="5"
                    name="coding_time_limit"
                    value={coding_time_limit}
                    placeholder="Enter time limit for execution"
                    onChange={
                      (e) => this.handleTimeLimit(e)
                      // this.props.updateTestQuestionDataWithKey({
                      //   questionIndex: this.props.questionIndex,
                      //   sectionIndex: this.props.sectionIndex,
                      //   key: "coding_time_limit",
                      //   value: e.target.value,
                      // })
                    }
                    className="border border-gray-300 outline-gray-300 p-2"
                  />
                  {this.state.timeLimitError && (
                    <p className="text-red-600 text-xs p-[2px]">
                      Time Limit must be 1-5 secs only
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-wrap gap-3 mt-2">
                <div>
                  <label className="mb-0 mr-5 small">Standard Input</label>
                  <input
                    type="file"
                    name="std_input_file"
                    accept=".txt"
                    onChange={(e) => this.handleUploadFiles(e)}
                    style={{ margin: "0 0 0 5px" }}
                  />
                  {std_input_file && std_input_file[0] ? (
                    <div>
                      <span>{std_input_file[0]}</span>
                      <button
                        className="mx-4 border border-gray-600 px-2 py-[2px] bg-gray-200 hover:bg-gray-300"
                        onClick={() => this.getUrl(std_input_file[0])}
                      >
                        View File
                      </button>
                    </div>
                  ) : null}
                </div>
                <div>
                  <label className="mb-0 mr-5 small">Standard Output</label>
                  <input
                    type="file"
                    name="std_output_file"
                    accept=".txt"
                    onChange={(e) => this.handleUploadFiles(e)}
                    style={{ margin: "0 0 0 5px" }}
                  />
                  {std_output_file && std_output_file[0] ? (
                    <div>
                      <span>{std_output_file[0]}</span>
                      <button
                        className="mx-4 border border-gray-600 px-2 py-[2px] bg-gray-200 hover:bg-gray-300"
                        onClick={() => this.getUrl(std_output_file[0])}
                      >
                        View File
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>
        {/* <button
          className="max-w-[30%] mx-auto text-[#009696] border border-[#009696] font-['nunito'] hover:text-white 
             hover:bg-[#001437] font-semibold uppercase text-sm
            px-4 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150
            disabled:opacity-25 disabled:cursor-not-allowed disabled:bg-gray-500"
          onClick={() => this.onSubmitQuestionData()}
        >
          {this.props.type === "A" ||
          this.props.type === "I" ||
          this.props.type === "P"
            ? "Add Question"
            : "Edit Question"}
        </button> */}
        <UniversalButton
          title={
            this.props.type === "A" ||
              this.props.type === "I" ||
              this.props.type === "P"
              ? "Add Question"
              : "Edit Question"
          }
          onClickFunction={() => this.onSubmitQuestionData()}
          classNames="m-auto py-3"
        />
      </div>
    );
  }
}

const mapStateToProps = ({ test, questionbank, interviewpanelReducer }) => {
  return {
    test: test.test,
    testId: test.test._id,
    questionTags: questionbank.questionTags,
    tables: test.tables,
    interviewerPeerId: interviewpanelReducer.interviewerPeerId,
    currentSectionIndex: interviewpanelReducer.currentSectionIndex,
    components: interviewpanelReducer.interviewDetails?.components,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTestQuestionDataWithKey: (data) =>
      dispatch(updateTestQuestionDataWithKey(data)),
    addQuestionRequested: (data) => dispatch(addQuestionRequested(data)),
    updateQuestionRequested: (data) => dispatch(updateQuestionRequested(data)),
    fetchUserTablesRequested: () => dispatch(fetchUserTablesRequested()),
    updateCustomQuestionModal: (data) =>
      dispatch(updateCustomQuestionModal(data)),
    updateInterviewQuestionSuccess: (data) =>
      dispatch(updateInterviewQuestionSuccess(data)),

    updateExtractedQuestion: (data) => dispatch(updateExtractedQuestion(data)),
    updateQuestionbankModal: (data) => dispatch(updateQuestionbankModal(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestion);
