import {
  FETCH_PROCTORED_USERS_FAILURE,
  FETCH_PROCTORED_USERS_REQUESTED,
  FETCH_PROCTORED_USERS_SUCCESS,
  FETCH_ATTENDING_USERS_REQUESTED,
  FETCH_ATTENDING_USERS_SUCCESS,
  FETCH_ATTENDING_USERS_FAILURE,
  FETCH_PROCTORED_USERS_ANY_PROCTOR_REQUESTED,
  FETCH_PROCTORED_USERS_ANY_PROCTOR_SUCCESS,
  FETCH_PROCTORED_USERS_ANY_PROCTOR_FAILURE,
} from "../actionTypes/proctor";

export const fetchProctoredUsersRequested = (data) => {
  return {
    type: FETCH_PROCTORED_USERS_REQUESTED,
    payload: data,
  };
};

export const fetchProctoredUsersFailure = () => {
  return {
    type: FETCH_PROCTORED_USERS_FAILURE,
  };
};

export const fetchProctoredUsersSuccess = (data) => {
  return {
    type: FETCH_PROCTORED_USERS_SUCCESS,
    payload: data,
  };
};

export const fetchAttendingUsersRequested = (data) => {
  return {
    type: FETCH_ATTENDING_USERS_REQUESTED,
    payload: data,
  };
};

export const fetchAttendingUsersFailure = () => {
  return {
    type: FETCH_ATTENDING_USERS_FAILURE,
  };
};

export const fetchAttendingUsersSuccess = (data) => {
  return {
    type: FETCH_ATTENDING_USERS_SUCCESS,
    payload: data,
  };
};

export const fetchProctoredUsersOfAnyProctorRequested = (data) => {
  return {
    type: FETCH_PROCTORED_USERS_ANY_PROCTOR_REQUESTED,
    payload: data,
  };
};
export const fetchProctoredUsersOfAnyProctorSuccess = (data) => {
  return {
    type: FETCH_PROCTORED_USERS_ANY_PROCTOR_SUCCESS,
    payload: data,
  };
};
export const fetchProctoredUsersOfAnyProctorFailure = (data) => {
  return {
    type: FETCH_PROCTORED_USERS_ANY_PROCTOR_FAILURE,
    payload: data,
  };
};
