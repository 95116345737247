import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GoogleSignup from "../components/signup/googleSignup";
import Modal from "../components/common/Modal/Modal";
import { ReactComponent as ButtonSpinner } from "../assets/img/svg/button-spinner.svg";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../assets/js/config";

const SignupAdditionalDetails = () => {
  const history = useNavigate();
  const newClgRef = useRef();
  const tokenRef = useRef();
  const [loading, setLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(null);
  const [showAddCollegeModal, setShowAddCollegeModal] = useState(false);
  const [isNewCollegeAddLoading, setIsNewCollegeAddLoading] = useState(false);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.state &&
      history.location.state.userCredential
    ) {
      setIsAuth(true);
      setLoading(false);
      const fetchTempToken = async () => {
        await axios
          .post(`${BASE_URL}api/auth/temp`)
          .then((response) => {
            tokenRef.current = response?.data.token;
          })
          .catch((err) => {
            toast.error(err.response.data, {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      };
      fetchTempToken();
    } else {
      setIsAuth(false);
      setLoading(false);
    }
  }, [history]);

  const handleNewCollegeSave = async () => {
    setIsNewCollegeAddLoading(true);

    await axios
      .post(
        `${BASE_URL}api/college`,
        { name: newClgRef.current.value },
        {
          headers: {
            "x-auth-token": tokenRef.current,
            "x-referer-sec-bool":
              "ZW50QHN0dWRlbnQuY29tIiwiX2lkIjoiNjI1ZWRhNmZkNTJmOTgwZjhmMGM2OTZjIiwidHlwZSI6IlMiLCJpYXQiOjE2NjI2MTYyMTcsImV4cCI6MTY2MjY1OTQxNywiYXVkIjoiaHR0cHM6Ly9hcHAua3lyb24uaW4vIiwiaXNzIjoiS1lST04i",
          },
        }
      )
      .then((response) => {
        newClgRef.current.value = "";
        toast.success("Your college successfully added", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .finally(() => {
        setIsNewCollegeAddLoading(false);
        setShowAddCollegeModal(false);
      });
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center ">
          <ButtonSpinner />
        </div>
      ) : isAuth ? (
        <>
          <Modal
            onClose={() => setShowAddCollegeModal(false)}
            isModal={showAddCollegeModal}
            showCloseButton
            className={"w-[90%] md:w-[40%]"}
          >
            <h2 className="text-xl font-['poppins'] font-medium pb-4 ">
              Add your college
            </h2>
            <div className="mb-10">
              <label className="font-['poppins'] text-neutral-400 ">
                {" "}
                Your college name<span>*</span>{" "}
              </label>
              <br />
              <small>Make sure your college is not available in our list</small>
              <input
                name="college_name"
                placeholder="Enter your text here"
                className="login-form-emailInput uppercase"
                ref={newClgRef}
                oninput="this.value = this.value.toUpperCase()"
              />
            </div>

            <button
              onClick={handleNewCollegeSave}
              className="text-white w-[150px] transition bg-[#009999]  hover:bg-gray-800 hover:text-white font-medium rounded-full  
          text-md px-3 py-1 flex justify-center mr-2 my-2 cursor-pointer font-['nunito'] ml-auto"
              type="submit"
            >
              {isNewCollegeAddLoading ? <ButtonSpinner /> : "Add"}
            </button>
          </Modal>
          <div
            // className={`w-[90%] md:w-[40%]`}
            style={{
              width: "100% !important",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "5% 25%",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
          >
            <GoogleSignup
              userCredentials={history.location.state.userCredential}
              setShowAddCollegeModal={setShowAddCollegeModal}
            />
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center ">
          YOU ARE NOT AUTHORIZED TO ACCESS THIS PAGE
        </div>
      )}
    </>
  );
};

export default SignupAdditionalDetails;
