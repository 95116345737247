import React, { Component } from "react";

export default class RoundedUser extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.roundref = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("click", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.roundref.current &&
      !this.roundref.current.contains(event.target)
    ) {
      if (!this.ref.current.classList.contains("hidden")) {
        this.handleDropDown();
      }
    }
  };

  handleDropDown = () => {
    this.ref.current.classList.toggle("hidden");
  };

  render() {
    const { name, email } = this.props.user;
    const { logOut } = this.props;
    return (
      <>
        <div
          className="flex items-center md:order-2"
          style={{ position: "relative" }}
        >
          <h5 className="mr-2 font-['poppins'] hidden md:block">{name}</h5>
          <button
            type="button"
            ref={this.roundref}
            className="flex mr-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 "
            id="user-menu-button"
            aria-expanded="false"
            data-dropdown-toggle="dropdown"
            onClick={this.handleDropDown}
          >
            <span className="sr-only">Open user menu</span>
            <img
              className="w-8 h-8 rounded-full"
              src={`https://ui-avatars.com/api/?name=${name}&bold=true&rounded=true&background=random`}
              title="Profile"
              alt=""
            />
          </button>
          <div
            ref={this.ref}
            className="hidden z-50 my-4 text-base list-none bg-white rounded divide-y divide-gray-100 shadow"
            id="dropdown"
            style={{ position: "absolute", right: "0px", top: "30%" }}
          >
            <div className="py-3 px-4">
              <span className="block text-sm text-gray-900 ">{name}</span>
              <span className="block text-sm  text-gray-400 truncate">
                {email}
              </span>
            </div>
            <ul style={{ margin: 0, padding: 0 }} aria-labelledby="dropdown">
              <li>
                <a
                  href="/dashboard"
                  className="block py-2 px-4 text-sm text-decoration-none text-gray-700 hover:bg-gray-100"
                >
                  Dashboard
                </a>
              </li>
              <li>
                <a
                  href="/profile"
                  className="block py-2 px-4 text-sm text-decoration-none text-gray-700 hover:bg-gray-100"
                >
                  Profile
                </a>
              </li>
              <li onClick={logOut}>
                <div className="block pt-2 pb-3  cursor-pointer px-4 text-sm text-gray-700 hover:bg-gray-100">
                  Sign out
                </div>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}
