import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateYTvideoRecordingStatus } from "../../store/actions/test";
import testService from "../../services/test.service";
import { useParams } from "react-router-dom";

const YouTubePlayer = ({ YTVideoId, section, question }) => {
  const playerRef = useRef(null);
  const dispatch = useDispatch();
  const quiz_id = useParams()?.id;
  const { quizAssignDetails, test } = useSelector((state) => state.test);
  console.log("ss", quizAssignDetails);

  const loadYouTubeAPI = () => {
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  };

  useEffect(() => {
    loadYouTubeAPI();

    function youtube_parser(url) {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : "";
    }

    playerRef.current = new window.YT.Player(
      `${section}_${question}_youtube-player`,
      {
        videoId: youtube_parser(YTVideoId),
        playerVars: {
          modestbranding: 1, // Hide YouTube logo
          rel: 0, // Disable related videos
          showinfo: 0, // Hide video information bar
          iv_load_policy: 3, // Disable video annotations
        },
        events: {
          onStateChange: onPlayerStateChange,
        },
      }
    );
    // };

    // // Load the YouTube IFrame API script.
    // const tag = document.createElement('script');
    // tag.src = 'https://www.youtube.com/iframe_api';
    // const firstScriptTag = document.getElementsByTagName('script')[0];
    // firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    return () => {
      if (playerRef.current) {
        console.log("desryoed");
        playerRef.current.destroy();
      }
    };
  }, []);

  const uploadVideoStatus = ({ type }) => {
    testService
      .uploadUserVideoStats({
        testId: quiz_id,
        studentId: quizAssignDetails._id,
        actiontype: type,
        time_interval: new Date(),
      })
      .then((res) => {
        console.log("response0", res);
      })
      .catch((err) => console.log("error", err));
  };

  const onPlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      dispatch(updateYTvideoRecordingStatus("PLAYING"));
      test?.is_user_capture_camera && uploadVideoStatus({ type: "PLAYING" });
    } else if (event.data === window.YT.PlayerState.PAUSED) {
      dispatch(updateYTvideoRecordingStatus("PAUSED"));
      test?.is_user_capture_camera && uploadVideoStatus({ type: "PAUSED" });
    } else if (event.data === window.YT.PlayerState.ENDED) {
      dispatch(updateYTvideoRecordingStatus("ENDED"));
      test?.is_user_capture_camera && uploadVideoStatus({ type: "ENDED" });
    } else if (event.data === window.YT.PlayerState.BUFFERING) {
      dispatch(updateYTvideoRecordingStatus("BUFFERING"));
      test?.is_user_capture_camera && uploadVideoStatus({ type: "BUFFERING" });
    } else if (event.data === window.YT.PlayerState.UNSTARTED) {
      dispatch(updateYTvideoRecordingStatus("UNSTARTED"));
      test?.is_user_capture_camera && uploadVideoStatus({ type: "UNSTARTED" });
    }
  };

  return (
    <div className="w-full">
      <div id={`${section}_${question}_youtube-player`} className="w-full" />
    </div>
  );
};

export default YouTubePlayer;
