import axios from "axios";
import { BASE_URL } from "../assets/js/config";
import authHeader from "./auth-header";

export const getTurnServerCreds = () => {
  return axios
    .get(`${BASE_URL}api/turnserver`, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw Error(e.response.data);
    });
};
