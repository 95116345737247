import React from "react";
import CourseService from "../../services/course.service";
import { withRouter } from "../../components/common/WithRouter";

class NewViewCourse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Course: {
        id: "",
        title: "",
        description: "",
        code: "",
        students: [],
        teachers: [],
        tas: [],
      },
    };
  }

  componentDidMount() {
    const id = "61863897908ebb0e4c643d61"

    CourseService.getOneCourse(id)
      .then(async (response) => {
        this.setState((prevState) => ({
          ...prevState,
          Course: {
            id: response.data._id,
            title: response.data.title,
            description: response.data.description,
            code: response.data.code,
            students: response.data.students,
            teachers: response.data.teachers,
            tas: response.data.tas,
          },
        }));

      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    return (
      <div style={{ padding: "15px 30px" }}>
        <div>
          <h2>Title: {this.state.Course.title}</h2>
          <h3>Description: {this.state.Course.description}</h3>
          <h4>Course Code: {this.state.Course.code}</h4>
          <hr />
          <div className="flex justify-around">
            <div
              className="border border-dark flex flex-col items-center"
              style={{ width: "30%" }}
            >
              <h5 style={{ margin: "10px 0" }}>STUDENTS</h5>
              <hr style={{ width: "80%", margin: "0" }} />
              <div style={{ margin: "8px 0" }}>
                {this.state.Course.students.map((student, idx) => {
                  return (
                    <p key={`student-${idx}`} style={{ margin: "2px 0" }}>
                      {student}
                    </p>
                  );
                })}
              </div>
            </div>
            <div
              className="border border-dark flex flex-col items-center"
              style={{ width: "30%" }}
            >
              <h5 style={{ margin: "10px 0" }}>TEACHERS</h5>
              <hr style={{ width: "80%", margin: "0" }} />
              <div style={{ margin: "8px 0" }}>
                {this.state.Course.teachers.map((teacher, idx) => {
                  return (
                    <p key={`teacher-${idx}`} style={{ margin: "2px 0" }}>
                      {teacher.email}
                    </p>
                  );
                })}
              </div>
            </div>
            <div
              className="border border-dark flex flex-col items-center"
              style={{ width: "30%" }}
            >
              <h5 style={{ margin: "10px 0" }}>TAs</h5>
              <hr style={{ width: "80%", margin: "0" }} />
              <div style={{ margin: "8px 0" }}>
                {this.state.Course.tas.map((ta, idx) => {
                  return (
                    <p key={`ta-${idx}`} style={{ margin: "2px 0" }}>
                      ta
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NewViewCourse);
