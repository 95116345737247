import React, { Component } from "react";
import { withRouter } from "../../common/WithRouter";
import { connect } from "react-redux";
import { ReactComponent as Plus } from "../../../assets/img/svg/plus4.svg";
import { fetchProctoredUserDetailsRequested } from "../../../store/actions/userdetails";
import load from "../../../assets/img/svg/load.svg";
import {
  assignTestRequest,
  assignTestProctorRequest,
  fetchTestAssignRequested,
  updateTestAssign,
  fetchTestRequest,
} from "../../../store/actions/test";
import Papa from "papaparse";
import { ToastContainer, toast } from "react-toastify";
import deletec from "../../../assets/img/svg/deletecolor.svg";

class AssignStudents extends Component {
  constructor(props) {
    super(props);
    this.importCSV = this.importCSV.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateData = this.updateData.bind(this);
    this.handleChangeRow = this.handleChangeRow.bind(this);
    this.addRow = this.addRow.bind(this);
    this.getAssignmentType = this.getAssignmentType.bind(this);
    this.handleDeleteResponse = this.handleDeleteResponse.bind(this);
  }

  componentDidMount() {
    const testId = this.props.params.id;
    this.props.fetchTestRequest(testId);
    this.props.fetchTestAssignRequested({ key: "assign", id: testId });
    this.props.fetchTestAssignRequested({ key: "proctor_assign", id: testId });
  }

  getAssignmentType = () => {
    return this.props.assignmentType === "S" ? "assign" : "proctor_assign";
  };

  importCSV = (csvfile) => {
    Papa.parse(csvfile, {
      complete: this.updateData,
      header: true,
    });
  };

  handleChange = (event) => {
    this.importCSV(event.target.files[0]);
  };

  updateData(result) {
    const data = result.data;
    // console.log("r1,", data)
    // Validating data
    data.map((object) =>
      Object.keys(object).forEach((key) => {
        console.log("checkiinside", object.key);
        const value = object[key].replace(/ +$/, "");
        delete object[key];
        if (value !== "") {
          object[key.toLowerCase().replace(/ +$/, "")] = value;
        }
      })
    );
    // console.log("result1", data);
    const filteredData = data.filter(
      (item) =>
        item.hasOwnProperty("email") &&
        item.hasOwnProperty("name") &&
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(item["email"])
    );
    console.log("result1", filteredData);
    // Setting the data
    this.props.updateTestAssign({
      key: this.getAssignmentType(),
      data: [...this.props[this.getAssignmentType()], ...filteredData],
    });
  }

  handleChangeRow = (index, e) => {
    const { name, value } = e.target;
    // if(value !== "")
    // {
    const assign = this.props[this.getAssignmentType()];
    const currentRows = [...assign];
    currentRows[index][name] = value;
    this.props.updateTestAssign({
      key: this.getAssignmentType(),
      data: currentRows,
    });
    // }
  };

  addRow = () => {
    const newRow = {
      name: "",
      email: "",
      college: "",
      mobile: "",
    };

    this.props.updateTestAssign({
      key: this.getAssignmentType(),
      data: [...this.props[this.getAssignmentType()], newRow],
    });
  };

  handleToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  updateAssign = () => {
    if (this.props.assignmentType === "S") {
      this.props.assignTestRequest(this.props.test._id, {
        assign: this.props.assign,
      });
    } else {
      this.props.assignTestProctorRequest(this.props.test._id, {
        proctor_assign: this.props.proctor_assign,
      });
    }
  };

  handleDeleteResponse(index) {
    const currentRows = this.props[this.getAssignmentType()];
    currentRows.splice(index, 1);
    this.props.updateTestAssign({
      key: this.getAssignmentType(),
      data: currentRows,
    });
  }

  render() {
    return (
      <div>
        <div className="flex flex-col justify-center items-center p-8">
          <label className="mb-0">
            <h6 className="mb-2 text-xl font-bold">
              Assign{" "}
              {this.props.assignmentType === "S" ? "Students" : "Proctors"}
            </h6>
          </label>
          <div className="flex pt-3 ml-10">
            <input
              className="block w-full text-sm file:outline-none file:border-none file:bg-[#BCFB46] file:mr-4 file:py-2 file:px-4 hover:file:bg-[#001437] hover:file:text-[#a0db32] font-semibold file:rounded-[0.3rem] ease-linear transition-all duration-150"
              type="file"
              ref={(input) => {
                this.filesInput = input;
              }}
              name="file"
              placeholder={null}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="flex-col">
          <div className="flex gap-[1.3rem]">
            <button
              onClick={() => this.addRow()}
              className="flex gap-[0.4rem] items-center justify-center text-[#a0db32] border border-[#a0db32] hover:bg-[#001437] hover:text-[#a0db32] font-semibold uppercase text-base p-2 px-5 mb-1 rounded-[0.7rem] outline-none focus:outline-none ease-linear transition-all duration-150"
            >
              <Plus className="h-4 w-4 [&>*]:!fill-[#a0db32]" />
              Add rows
            </button>
            {this.props[this.getAssignmentType()].length ? (
              <button
                onClick={this.updateAssign}
                className={`w-[140px] flex gap-[0.4rem] items-center justify-center text-[#a0db32] border border-[#a0db32] hover:bg-[#001437] hover:text-[#a0db32] font-semibold uppercase text-base p-2 px-5 mb-1 rounded-[0.7rem] outline-none focus:outline-none ease-linear transition-all duration-150  ${
                  this.props.loading &&
                  "bg-white border border-cyan-700 hover:bg-[#a0db32]"
                }`}
              >
                {this.props.assignTestLoading ? (
                  <img
                    alt="loading"
                    src={load}
                    className="spinner my-1 mx-auto w-5 h-5 text-white "
                  />
                ) : (
                  "Update"
                )}
              </button>
            ) : null}
          </div>
          <div className="flex py-2">
            <table className="sm:text-sm text-left w-full">
              <thead className="text-sm shadow-sm uppercas">
                <tr>
                  <th className="p-1">S.No</th>
                  <th className="p-1">Name</th>
                  <th className="p-1">Email</th>
                  <th className="p-1">College</th>
                  <th className="p-1">Mobile</th>

                  <th className="p-1">Gender</th>
                  <th className="p-1">Cgpa</th>
                  <th className="p-1">10th Percentile</th>
                  <th className="p-1">12th Percentile</th>
                  <th className="p-1">Backlogs</th>
                  <th className="p-1">Grad Degree</th>
                  <th className="p-1">Branch</th>
                  <th className="p-1">Resume</th>
                  <th className="p-1">Actions</th>
                </tr>
              </thead>
              {console.log("cheking", this.props[this.getAssignmentType()])}
              {this.props[this.getAssignmentType()].length && (
                <tbody>
                  {this.props[this.getAssignmentType()].map(
                    (student, index) => (
                      <tr key={`student-${index}`} className="w-full">
                        <td className="">
                          <input
                            className="w-[3.2rem] shadow appearance-none border rounded py-2 px-2
                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            readOnly
                            value={index + 1}
                            type="text"
                            placeholder="S.No"
                          />
                        </td>
                        <td className="p-1">
                          <input
                            name="name"
                            required
                            onChange={(e) => this.handleChangeRow(index, e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 
                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={student.name}
                            type="text"
                            placeholder="Name"
                          />
                        </td>
                        <td className="p-1">
                          <input
                            name="email"
                            required
                            onChange={(e) => this.handleChangeRow(index, e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 
                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={student.email}
                            type="text"
                            placeholder="Email"
                          />
                        </td>
                        <td className="p-1">
                          <input
                            name="college"
                            onChange={(e) => this.handleChangeRow(index, e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 
                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={student.college}
                            type="text"
                            placeholder="College"
                          />
                        </td>
                        <td className="p-1">
                          <input
                            name="mobile"
                            onChange={(e) => this.handleChangeRow(index, e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 
                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={student.mobile}
                            type="text"
                            placeholder="Mobile"
                          />
                        </td>

                        <td className="p-1">
                          <input
                            name="gender"
                            onChange={(e) => this.handleChangeRow(index, e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 
                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={student.gender}
                            type="text"
                            placeholder="Gender"
                          />
                        </td>

                        <td className="p-1">
                          <input
                            name="cgpa"
                            onChange={(e) => this.handleChangeRow(index, e)}
                            className="shadow appearance-none border rounded w-[3.2rem] py-2 px-2 
                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={student.cgpa}
                            type="text"
                            placeholder="cgpa"
                          />
                        </td>

                        <td className="p-1">
                          <input
                            name="class_ten_perc"
                            onChange={(e) => this.handleChangeRow(index, e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3
                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={student.class_ten_perc}
                            type="text"
                            placeholder="10th percentile"
                          />
                        </td>

                        <td className="p-1">
                          <input
                            name="class_twelve_perc"
                            onChange={(e) => this.handleChangeRow(index, e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 
                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={student.class_twelve_perc}
                            type="text"
                            placeholder="12th Percentile"
                          />
                        </td>

                        <td className="p-1">
                          <input
                            name="num_current_backlogs"
                            onChange={(e) => this.handleChangeRow(index, e)}
                            className="shadow appearance-none border rounded w-[3.2rem] py-2 px-2 
                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={student.num_current_backlogs}
                            type="text"
                            placeholder="Current Backlogs"
                          />
                        </td>

                        <td className="p-1">
                          <input
                            name="grad_degree"
                            onChange={(e) => this.handleChangeRow(index, e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 
                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={student.grad_degree}
                            type="text"
                            placeholder="Grad Degree"
                          />
                        </td>

                        <td className="p-1">
                          <input
                            name="branch"
                            onChange={(e) => this.handleChangeRow(index, e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 
                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={student.branch}
                            type="text"
                            placeholder="Branch"
                          />
                        </td>
                        <td className="p-1">
                          <input
                            name="resume_url"
                            onChange={(e) => this.handleChangeRow(index, e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 
                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={student.cv_link}
                            type="url"
                            placeholder="Resume Url"
                          />
                        </td>

                        <td className="p-1">
                          <div className="flex sm:gap-x-3 gap-x-1 items-center">
                            <img
                              alt="delete"
                              src={deletec}
                              title="Delete Response"
                              className="ml-2 sm:w-5 sm:h-5 w-4 h-4 hoverPointer"
                              onClick={() => {
                                this.handleDeleteResponse(index);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
        <ToastContainer theme="dark" rtl={false} draggable />
      </div>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    test: test.test,
    loading: test.loading,
    assign: test.assign,
    assignTestLoading: test.assignTestLoading,
    proctor_assign: test.proctor_assign,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProctoredUserDetailsRequested: (data) =>
      dispatch(fetchProctoredUserDetailsRequested(data)),
    assignTestRequest: (id, data) => dispatch(assignTestRequest(id, data)),
    assignTestProctorRequest: (id, data) =>
      dispatch(assignTestProctorRequest(id, data)),
    fetchTestAssignRequested: (id) => dispatch(fetchTestAssignRequested(id)),
    updateTestAssign: (data) => dispatch(updateTestAssign(data)),
    fetchTestRequest: (data) => dispatch(fetchTestRequest(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssignStudents));
