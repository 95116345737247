import { DELETE_SUBSCRIPTION, DELETE_SUBSCRIPTION_FAILURE, DELETE_SUBSCRIPTION_SUCCESS, GET_ALL_SUBSCRIPTION, GET_ALL_SUBSCRIPTION_FAILURE, GET_ALL_SUBSCRIPTION_SUCCESS, GET_SINGLE_SUBSCRIPTION, GET_SINGLE_SUBSCRIPTION_SUCCESS, UPDATE_SUBSCRIPTION, UPDATE_SUBSCRIPTION_FAILURE, UPDATE_SUBSCRIPTION_SUCCESS } from "../actionTypes/subscription";


export const getAllSubscription = (data) => {
    return { type: GET_ALL_SUBSCRIPTION, payload: data };
};
export const getAllSubscriptionSuccess = (data) => {
    return { type: GET_ALL_SUBSCRIPTION_SUCCESS, payload: data };
};
export const getAllSubscriptionFailure = (data) => {
    return { type: GET_ALL_SUBSCRIPTION_FAILURE, payload: data };
};


export const getSingleSubscription = (data) => {
    return { type: GET_SINGLE_SUBSCRIPTION, payload: data };
};
export const getSingleSubscriptionSuccess = (data) => {
    return { type: GET_SINGLE_SUBSCRIPTION_SUCCESS, payload: data };
};
export const getSingleSubscriptionFailure = (data) => {
    return { type: GET_SINGLE_SUBSCRIPTION_SUCCESS, payload: data };
};


export const deleteSubscription = (data) => {
    return { type: DELETE_SUBSCRIPTION, payload: data };
};
export const deleteSubscriptionSuccess = (data) => {
    return { type: DELETE_SUBSCRIPTION_SUCCESS, payload: data };

};
export const deleteSubscriptionFailure = (data) => {
    return { type: DELETE_SUBSCRIPTION_FAILURE, payload: data };
};


export const updateSubscription = (data) => {
    return { type: UPDATE_SUBSCRIPTION, payload: data };
};
export const updateSubscriptionSuccess = (data) => {
    return { type: UPDATE_SUBSCRIPTION_SUCCESS, payload: data };
};
export const updateSubscriptionFailure = (data) => {
    return { type: UPDATE_SUBSCRIPTION_FAILURE, payload: data };
};
