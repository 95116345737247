export const UPLOAD_FILE_REQUEST_QUESTION = "UPLOAD_FILE_REQUEST_QUESTION";
export const UPLOAD_FILE_SUCCESS_QUESTION = "UPLOAD_FILE_SUCCESS_QUESTION";
export const UPLOAD_FILE_FAILED_QUESTION = "UPLOAD_FILE_FAILED_QUESTION";

export const EXTRACT_QUESTION_REQUEST = "EXTRACT_QUESTION_REQUEST";
export const EXTRACT_QUESTION_SUCCESS = "EXTRACT_QUESTION_SUCCESS";
export const EXTRACT_QUESTION_FAILED = "EXTRACT_QUESTION_FAILED";

export const UPDATE_EXTRACTED_QUESTION = "UPDATE_EXTRACTED_QUESTION";

export const DELETE_EXTRACTED_QUESTION = "DELETE_EXTRACTED_QUESTION";
export const DISABLE_EXTRACTED_QUESTION = "DISABLE_EXTRACTED_QUESTION";

export const GET_QUIZ_DETAILS = "GET_QUIZ_DETAILS";
export const GET_QUIZ_DETAILS_SUCCESS = "GET_QUIZ_DETAILS_SUCCESS";
export const INSERT_QUESTIONS = "INSERT_QUESTIONS";
