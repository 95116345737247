import {
  ADD_EMAIL_TEMPLATE_FAILURE,
  ADD_EMAIL_TEMPLATE_REQUESTED,
  ADD_EMAIL_TEMPLATE_SUCCESS,
  DELETE_EMAIL_TEMPLATE_FAILURE,
  DELETE_EMAIL_TEMPLATE_REQUESTED,
  DELETE_EMAIL_TEMPLATE_SUCCESS,
  FETCH_ALL_EMAIL_TEMPLATES_FAILURE,
  FETCH_ALL_EMAIL_TEMPLATES_REQUESTED,
  FETCH_ALL_EMAIL_TEMPLATES_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_FAILURE,
  UPDATE_EMAIL_TEMPLATE_MODAL,
  UPDATE_EMAIL_TEMPLATE_REQUESTED,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_WITH_KEY,
} from "../actionTypes/emailtemplates";

export const updateEmailTemplateModal = (data) => {
  return {
    type: UPDATE_EMAIL_TEMPLATE_MODAL,
    payload: data,
  };
};

export const updateEmailTemplateWithKey = (data) => {
  return {
    type: UPDATE_EMAIL_TEMPLATE_WITH_KEY,
    payload: data,
  };
};

export const fetchAllEmailTemplatesRequested = (data) => {
  return {
    type: FETCH_ALL_EMAIL_TEMPLATES_REQUESTED,
    payload: data,
  };
};

export const fetchAllEmailTemplatesSuccess = (data) => {
  return {
    type: FETCH_ALL_EMAIL_TEMPLATES_SUCCESS,
    payload: data,
  };
};

export const fetchAllEmailTemplatesFailure = () => {
  return {
    type: FETCH_ALL_EMAIL_TEMPLATES_FAILURE,
  };
};

export const addEmailTemplateRequested = (data) => {
  return {
    type: ADD_EMAIL_TEMPLATE_REQUESTED,
    payload: data,
  };
};

export const addEmailTemplateSuccess = (data) => {
  return {
    type: ADD_EMAIL_TEMPLATE_SUCCESS,
    payload: data,
  };
};

export const addEmailTemplateFailure = () => {
  return {
    type: ADD_EMAIL_TEMPLATE_FAILURE,
  };
};

export const updateEmailTemplateRequested = (data) => {
  return {
    type: UPDATE_EMAIL_TEMPLATE_REQUESTED,
    payload: data,
  };
};

export const updateEmailTemplateSuccess = (data) => {
  return {
    type: UPDATE_EMAIL_TEMPLATE_SUCCESS,
    payload: data,
  };
};

export const updateEmailTemplateFailure = () => {
  return {
    type: UPDATE_EMAIL_TEMPLATE_FAILURE,
  };
};

export const deleteEmailTemplateRequested = (data) => {
  return {
    type: DELETE_EMAIL_TEMPLATE_REQUESTED,
    payload: data,
  };
};

export const deleteEmailTemplateSuccess = (data) => {
  return {
    type: DELETE_EMAIL_TEMPLATE_SUCCESS,
    payload: data,
  };
};

export const deleteEmailTemplateFailure = () => {
  return {
    type: DELETE_EMAIL_TEMPLATE_FAILURE,
  };
};
