import React, { useRef, useState } from "react";
import { useId } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkIfSchemaExists,
  createAdminSchema,
  createSchema,
  getAllTables,
  runQuery,
  fetchTableHeaders,
  insertData,
} from "../../services/questionbank.service";
import { submitSolution } from "../../services/test.service";
import {
  createSchemaForStudent,
  submitTestRequested,
  updateTestResponse,
} from "../../store/actions/test";
import Papa from "papaparse";
import Modal from "../common/Modal/Modal";

import {
  setPostgresError,
  setPostgresTable,
  updateLoadingResponse,
  updatePostgresQuery,
  updatePostgresResult,
} from "../../store/actions/postgres";
import { getUserIdFromPeerId } from "../common/utils";
import { updateInterviewResponseRequested } from "../../store/actions/interviewpanel";

const PostgresQuestion = (props) => {
  // const [tables, setTables] = useState(null);
  const dispatch = useDispatch();
  const { tables } = useSelector((state) => state.postgresReducer);
  const { id: testId } = useParams();
  useEffect(() => {
    checkIfSchemaExists({ testId }).then((res) => {
      if (res?.data?.exist) {
        getAllTables().then((res) => setTables(res.data));
      }
    });
  }, []);

  const setTables = (data) => {
    dispatch(setPostgresTable(data));
  };

  return (
    <div className="w-full  h-full py-[3%] flex">
      {tables !== null ? (
        <QueryComponent
          tables={tables}
          setTables={(data) => setTables(data)}
          socket={props.socket}
          interviewerPeerId={props.interviewerPeerId}
          type={props.type}
        />
      ) : (
        <NoSchema
          setTables={(data) => setTables(data)}
          type={props.type}
          interviewerPeerId={props.interviewerPeerId}
          socket={props.socket}
        />
      )}
    </div>
  );
};

export default PostgresQuestion;

const NoSchema = ({ setTables, type, interviewerPeerId, socket }) => {
  const { id: testId } = useParams();
  const [loading, setLoading] = useState(false);
  const isAdmin = () => {
    var type = localStorage["priksha_type"].toString();
    return type === "A";
  };

  // useEffect(() => {
  //   props.socket.on("recieve-postgresql-table-event", (data) => {
  //     console.log("check postdql event", data);
  //   });
  // }, []);

  const isTeacher = () => {
    var type = localStorage["priksha_type"].toString();
    return type === "T";
  };

  const handleCreateSchema = () => {
    setLoading(true);
    if (isAdmin() || isTeacher()) {
      createAdminSchema()
        .then((res) => getAllTables().then((res) => setTables(res.data)))
        .finally(() => setLoading(false));
    } else
      createSchema({ testId, type })
        .then((res) =>
          getAllTables().then((res) => {
            if (type === "I") {
              socket.emit("send-postgres-events", {
                type: "tables",
                state: res.data,
                userId: getUserIdFromPeerId(interviewerPeerId),
              });
            }
            setTables(res.data);
          })
        )
        .finally(() => setLoading(false));
  };

  return (
    <div className=" flex justify-center items-center flex-col w-full">
      {loading ? (
        <div>Loading Schema....</div>
      ) : (
        <div className="flex justify-center flex-col w-full items-center">
          <span className="text-2xl text-slate-600">No Schema Created </span>
          <div
            className="bg-red-200 px-[2%] py-[1%] my-[2%] text-xl  rounded-lg flex justify-center cursor-pointer items-center hover:shadow-md"
            onClick={handleCreateSchema}
          >
            Create Schema
          </div>
        </div>
      )}
    </div>
  );
};

const QueryComponent = ({
  tables,
  setTables,
  socket,
  interviewerPeerId,
  type,
}) => {
  const dispatch = useDispatch();
  const { id: testId } = useParams();
  // const [result, setResult] = useState(null);
  // const [loading, setLoading] = useState(false);
  const { query, loading, result, error } = useSelector(
    (state) => state.postgresReducer
  );
  // const [error, setError] = useState("");

  const { currentAccordianIndex, currentAccordianPageIndex, response } =
    useSelector((state) => state.test);

  const { currentSectionIndex, currentQuestionIndex } = useSelector(
    (state) => state.interviewpanelReducer
  );

  const setLoading = (val) => {
    type === "I" &&
      socket.emit("send-postgres-events", {
        type: "loading",
        state: val,
        userId: getUserIdFromPeerId(interviewerPeerId),
      });
    dispatch(updateLoadingResponse(val));
  };

  const setResult = (val) => {
    type === "I" &&
      socket.emit("send-postgres-events", {
        type: "result",
        state: val,
        userId: getUserIdFromPeerId(interviewerPeerId),
      });
    dispatch(updatePostgresResult(val));
  };

  const setError = (val) => {
    type === "I" &&
      socket.emit("send-postgres-events", {
        type: "error",
        state: val,
        userId: getUserIdFromPeerId(interviewerPeerId),
      });
    dispatch(setPostgresError(val));
  };

  const handleRunQuery = () => {
    setLoading(true);
    runQuery({ query, testId })
      .then((res) => {
        getAllTables().then((res) => setTables(res.data));
        if (Array.isArray(res.data))
          setResult({ data: res.data, type: "table" });
        else setResult({ data: res.data.toUpperCase(), type: "string" });
        setError("");
        if (type === "I") {
          dispatch(
            updateInterviewResponseRequested({
              sectionIndex: currentSectionIndex,
              questionIndex: currentQuestionIndex,
              value: {
                query,
              },
            })
          );
        } else {
          dispatch(
            updateTestResponse({
              sectionIndex: currentAccordianIndex,
              questionIndex: currentAccordianPageIndex,
              value: {
                query,
              },
            })
          );
        }
      })
      .catch((err) => {
        setError(JSON.stringify(err.data));
        setResult(null);
      })
      .finally(() => setLoading(false));
  };

  const showResult = () => {
    if (!result) return <></>;
    const type = result.type;
    // console.log("result", result);
    if (type === "string") return <div>{result.data}</div>;
    if (type === "table")
      return (
        <div className="w-full">
          <table className="sm:text-sm text-left w-3/4">
            {result.data.length ? (
              <>
                <thead className="text-sm border uppercase">
                  {Object.keys(result.data[0]).map((key, i) => (
                    <th className="p-2" key={i}>
                      {key}
                    </th>
                  ))}
                  <th></th>
                </thead>
                <tbody>
                  {result.data.map((row, i) => {
                    const values = Object.values(row);
                    return (
                      <tr className="w-full">
                        {values.map((value, i) => (
                          <td className="p-1 border pl-2" key={i}>
                            {value}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </>
            ) : (
              <div>No records found</div>
            )}
          </table>
        </div>
      );
  };

  const submitSQLSolution = () => {
    // console.log("onclick submit");
    setLoading(true);
    submitSolution({
      testId,
      type,
      sectionIndex: type === "I" ? currentSectionIndex : currentAccordianIndex,
      questionIndex:
        type === "I" ? currentQuestionIndex : currentAccordianPageIndex,
      query,
    })
      .then((res) => {
        // console.log("submit", res);
        if (res.verdict) {
          setResult({ data: "Query Accepted", type: "string" });
        } else {
          setResult({ data: "Wrong Answer", type: "string" });
        }
        if (type === "I") {
          dispatch(
            updateInterviewResponseRequested({
              sectionIndex: currentSectionIndex,
              questionIndex: currentSectionIndex,
              value: {
                query,
              },
            })
          );
        } else {
          dispatch(
            updateTestResponse({
              sectionIndex: currentAccordianIndex,
              questionIndex: currentAccordianPageIndex,
              value: {
                query,
                verdict: res.verdict,
              },
            })
          );
          dispatch(
            submitTestRequested({
              id: testId,
              response: response,
              isSubmit: false,
            })
          );
        }
      })
      .catch(() => setResult({ data: "Wrong Answer", type: "string" }))
      .finally(() => setLoading(false));
  };

  const isAdmin = () => {
    var type = localStorage["priksha_type"];
    return type === "A";
  };

  const isTeacher = () => {
    var type = localStorage["priksha_type"];
    return type === "T";
  };
  const isStudent = () => {
    var type = localStorage["priksha_type"];
    return type === "S";
  };

  const [tableData, setTableData] = useState("");
  const [openModal, setOpenModal] = useState("");
  const [openCsvModal, setOpenCsvModal] = useState(false);

  const showCsvModal = () => {
    setOpenCsvModal(true);
  };

  const showTable = (tableName) => {
    // console.log("inside table name=", tableName);
    setOpenModal(tableName);
    fetchTableHeaders({ tableName }).then((res) => {
      setTableData(res);
      // console.log(res , "line218");
    });
  };

  const [csvData, setCsvData] = useState("");
  const [tableName, setTableName] = useState("");

  const dropDownHandler = (event) => {
    // console.log(event.target.value , "line234");
    setTableName(event.target.value);
  };

  function importCSV(csvfile) {
    Papa.parse(csvfile, {
      complete: csvDataUpdate,
      header: true,
    });
  }

  const csvDataInput = (event) => {
    // console.warn("file rec" , event.target.files[0]);
    importCSV(event.target.files[0]);
  };

  function csvDataUpdate(result) {
    const info = result.data;
    setCsvData(info);
    const table = tableName;
    // console.warn("line246" , data);
    // console.log(info, "line248");
    insertData({ table, info }).then((res) => {
      // console.log(res);
      toast.success(res.message);
    });
  }

  function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const sendSocketEvent = debounce((value) =>
    socket.emit("send-postgres-events", {
      type: "query",
      state: value,
      userId: getUserIdFromPeerId(interviewerPeerId),
    })
  );

  const updatePostgres = (data) => {
    type === "I" && sendSocketEvent(data);
    dispatch(updatePostgresQuery(data));
  };

  console.log(tableData, tableName);

  return (
    <div className="flex gap-[35px] px-8 w-full flex-col">
      <Modal
        showCloseButton
        onClose={() => setOpenModal("")}
        isModal={openModal}
        className="w-[90vw] sm:w-[80vw] md:w-[65vw]"
      >
        <div>
          <h1 className="text-center w-full font-['nunito'] text-[1.5rem] font-semibold mb-3">
            {openModal}
          </h1>
          <div className="flex gap-[35px] px-10  w-full flex-col max-h-[70vh] scrollbar mt-5 overflow-y-auto xl:overflow-x-hidden">
            <table className="sm:text-sm text-left w-full ">
              <thead className="text-sm border uppercase">
                <tr className="w-full">
                  {tableData.column_name?.map((e, i) => {
                    return <th className="p-2">{e.column_name}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {tableData.column_data?.map((rowData) => {
                  // console.log("line 272", rowData);
                  return (
                    <tr className="w-full">
                      {tableData.column_name?.map((cellData) => {
                        // console.log("line277", cellData.column_name);
                        return (
                          <td className="p-1 border pl-2">
                            {rowData[cellData.column_name]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>

      <Modal
        onClose={() => setOpenCsvModal(false)}
        isModal={openCsvModal}
        showCloseButton
        className="w-[50vw] "
      >
        <div className="relative w-full lg:max-w-sm">
          <select
            className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
            onChange={dropDownHandler}
          >
            <option value="" disabled selected>
              Select Table
            </option>

            {!!tables &&
              tables?.map((e, i) => {
                return <option>{e.table_name}</option>;
              })}
          </select>
        </div>

        <div className="relative w-full lg:max-w-sm">
          <input
            type="file"
            className="p-2.5 block w-full text-sm text-slate-500
            file:mr-4 file:py-2 file:px-4
            file:rounded-full file:border-0
            file:text-sm file:font-semibold
            file:bg-violet-50 file:text-violet-700
            hover:file:bg-violet-100
            "
            accept=".csv"
            onChange={csvDataInput}
          />
        </div>
      </Modal>

      <div className="w-full ">
        <div className="w-full relative">
          {loading && (
            <div className="absolute w-full h-full flex justify-center items-center text-2xl bg-gray-300/20">
              Running Query
            </div>
          )}
          <textarea
            name="query"
            id="query"
            cols="2"
            rows="10"
            value={query}
            onChange={(e) => updatePostgres(e.target.value)}
            placeholder="Enter query here"
            className="p-[2%] w-full border outline-none"
          ></textarea>
        </div>
        <div>
          <div className="flex w-full justify-start gap-[20px] mt-2 ">
            <button
              onClick={handleRunQuery}
              disabled={loading}
              className="bg-blue-200 py-[5px] px-[10px] w-[100px]  rounded-md hover:bg-blue-300"
            >
              Run
            </button>
            {isStudent() && (
              <button
                disabled={loading}
                onClick={submitSQLSolution}
                className="bg-green-200 py-[5px] px-[10px]  w-[100px] rounded-md hover:bg-green-300"
              >
                Submit
              </button>
            )}
            <button
              disabled={loading}
              onClick={() => {
                updatePostgres("");
                setResult("");
                setError("");
              }}
              className="bg-red-200 py-[5px] px-[10px]  w-[100px] rounded-md hover:bg-red-300"
            >
              Clear
            </button>
          </div>

          <div className="mt-2 ">
            <button
              disabled={loading}
              onClick={() => {
                if (isStudent())
                  dispatch(createSchemaForStudent({ testId, type }));
                else dispatch(createAdminSchema());
              }}
              className="bg-red-200 py-[5px] px-[10px]  w-[130px] rounded-md hover:bg-red-300"
            >
              Reset Table
            </button>

            {/* <button
              disabled={loading}
              className="bg-green-200 py-[5px] px-[15px] w-[130px] rounded-md hover:bg-green-300 float-right"
              onClick={() => showCsvModal()}
            >
              Update Table
            </button> */}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between">
        <div className="w-2/3 max-w-[700px] ">
          <label className="font-bold">Query Result:</label>
          <div className="my-2 bg-gray-100 p-2 overflow-auto min-h-[200px] max-h-[500px] ">
            {console.log("loading", loading, result)}
            {loading ? (
              <span>Loading</span>
            ) : result !== null ? (
              <div>{showResult()}</div>
            ) : error?.length > 0 ? (
              <div className="w-full">{error}</div>
            ) : (
              <span className="text-gray-500 italic "> No output </span>
            )}
          </div>
        </div>
        {(isAdmin() || isTeacher()) && (
          <div className="w-1/3">
            <label className="font-bold">List of available tables:</label>
            <button
              disabled={loading}
              className=" bg-green-200 py-[5px] px-[15px] w-[125px] rounded-md hover:bg-green-300 float-right"
              onClick={() => showCsvModal()}
            >
              Update Table
            </button>
            <div className="my-2 py-2.5 max-h-[200px] w-full overflow-y-auto">
              <table className="sm:text-sm text-left w-full ">
                {!!tables ? (
                  tables.length ? (
                    <>
                      <thead className="text-sm border uppercase">
                        <th className="p-2">S.No</th>
                        <th className="p-2">Table Name</th>
                      </thead>
                      <tbody>
                        {!!tables &&
                          tables?.map((e, i) => {
                            return (
                              <tr className="w-full">
                                <td className="p-1 border pl-2">{i + 1}</td>
                                <td
                                  className="p-1 border pl-2 py-2"
                                  onClick={() => showTable(e.table_name)}
                                >
                                  <span className="p-1 pl-2 bg-violet-200  rounded-md hover:bg-violet-300 cursor-pointer flex-wrap">
                                    {e.table_name}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </>
                  ) : (
                    <div>No Records found</div>
                  )
                ) : null}
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
