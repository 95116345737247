import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import { Navigate, useNavigate } from "react-router-dom";
import TestService from "../../services/test.service";
import RenderQuestion from "./createTest/RenderQuestion";
import TestHeader from "./createTest/TestHeader";
import { toggleSidebar } from "../../store/actions/sidebar";
import load from "../../assets/img/svg/load.svg";
import { setSubmissionResponse } from "../../store/actions/test";
// import { updateTestQuestionDataWithKey } from "../../store/actions/test";

const ReviewTest = ({ id }) => {
  const dispatch = useDispatch();
  // const { opened: sideBarOpened } = useSelector((state) => state.sidebar);
  // const { id } = useParams();
  const [components, setComponents] = useState([
    {
      title: "",
      description: "",
      components: [],
    },
  ]);
  const [saving, setSaving] = useState(false);
  const [totalMarks, setTotalMarks] = useState(0);
  const [finalMarks, setFinalMarks] = useState(0);
  const [testId, setTestId] = useState(null);

  const [testData, setTestData] = useState({
    title: "",
    description: "",
    start_duration: null,
    end_duration: null,
    assign: [],
  });

  let [pageLoading, setPageLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
  });
  let [studentResponse, setStudentResponse] = useState({});
  const [marking, setMarking] = useState({});

  const changeScore = (sectionIndex, questionIndex, value) => {
    // let l = e.target.name.split(",");
    // let sectionIndex = l[0];
    // let questionIndex = l[1];
    if (!marking[sectionIndex]) {
      marking[sectionIndex] = {};
    }
    if (!marking[sectionIndex][questionIndex]) {
      marking[sectionIndex][questionIndex] = {};
      marking[sectionIndex][questionIndex] = { score: 0, review: "" };
    }

    let new_marking = { ...marking };
    // setFinalMarks((prevState) => prevState + parseInt(value));
    new_marking[sectionIndex][questionIndex]["score"] = value;
    // dispatch(
    //   updateTestQuestionDataWithKey({
    //     questionIndex: questionIndex,
    //     sectionIndex: sectionIndex,
    //     key: "score",
    //     value,
    //   })
    // );
    setMarking(new_marking);
  };

  const changeReview = (e) => {
    var l = e.target.name.split(",");
    l[0].toString();
    if (typeof l[1] != "undefined") l[1].toString();
    if (typeof marking[l[0]] === "undefined") {
      marking[l[0]] = {};
    }
    if (typeof marking[l[0]][l[1]] === "undefined") {
      marking[l[0]][l[1]] = { score: 0, review: "" };
    }
    setMarking(marking, (marking[l[0]][l[1]]["review"] = e.target.value));
  };

  const getTotalMarks = (comp) => {
    var totalMark = 0;
    for (let i = 0; i < comp.length; i++) {
      for (let j = 0; j < comp[i].components.length; j++) {
        totalMark += parseInt(comp[i].components[j].marks);
      }
    }
    setTotalMarks(totalMark);
  };

  let history = useNavigate();
  useEffect(() => {
    if (!id) return;
    setPageLoading(true);
    TestService.loadStudentResponse(id).then(
      async (response) => {
        dispatch(setSubmissionResponse({ response: response.data }));
        if (response.data.user) {
          setUserDetails({
            name: response.data.user.name,
            email: response.data.user.email,
          });
        } else {
          setUserDetails({
            name: response.data.name,
            email: response.data.email,
          });
        }
        setFinalMarks(response.data.marks);
        const quiz_response = response.data.quiz;
        setTestId(quiz_response._id);
        setTestData({
          title: quiz_response.title ? quiz_response.title : "",
          assign: quiz_response.assign,
          description: quiz_response.description
            ? quiz_response.description
            : "",
          start_duration: quiz_response.start_duration,
          end_duration: quiz_response.end_duration,
        });
        getTotalMarks(quiz_response.components);

        setComponents(quiz_response.components);
        const student_response = response.data.response;
        setStudentResponse(student_response);
        if (response.data.marking) {
          setMarking(response.data.marking);
        }

        setPageLoading(false);
      },
      (error) => {
        return <Navigate to="/dashboard" />;
      }
    );
  }, [id]);

  const previewTest = (e) => {
    saveReview(e, () => {
      history(`/tests/preview/${testId}`);
    });
  };

  const saveReview = (e, callback) => {
    e.preventDefault();
    setSaving(true);
    TestService.getAssignedUsers({ quiz: testId }).then(
      (res) => {
        var users = res.data;
        users.forEach((user) => {
          if (user["_id"] === id) {
            UserService.putUserReviewScore(user["_id"], marking);
            history(`/dashboard`);
          }
        });
      },
      (error) => {
        return <Navigate to="/dashboard" />;
      }
    );
    setSaving(false);
  };

  if (pageLoading) {
    return (
      <div className="hardCenter">
        <img
          alt="loading"
          src={load}
          className="spinner mr-2 w-6 h-6 text-primary "
        />
      </div>
    );
  }
  return (
    <>
      <div className="mt-24">
        <div>
          {pageLoading ? (
            <div
              className="justify-content-center align-items-end d-flex"
              style={{ minHeight: "250px" }}
            >
              <img
                alt="loading"
                src={load}
                className="spinner mr-2 w-6 h-6 text-primary "
              />
            </div>
          ) : (
            <>
              {/* <button
                type="button"
                class="text-white bg-gradient-to-r from-purple-500 via-purple-600 
 to-purple-700 hover:bg-gradient-to-br font-medium rounded-lg 
 sm:text-sm sm:px-4 sm:py-2 text-[10px] px-[7px] py-[5px] text-center mr-2"
                onClick={() => history(`/tests/students/${testId}`)}
              >
                Go Back
              </button> */}
              <TestHeader title="Test Details" />
              <hr className="mb-4" />
            </>
          )}
        </div>
        {!pageLoading && (
          <form onSubmit={(e) => saveReview(e)}>
            <div className="">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <h2 className="text-lg mt-3 font-semibold text-dark mb-0">
                    {testData.title}
                  </h2>
                </div>
                <div className="flex bg-green-200 px-3 py-1 items-center gap-x-2  rounded">
                  <span className="text-4xl font-bold">{`${finalMarks || 0
                    }`}</span>
                  <span className="text-lg font-semibold text-slate-700">
                    Marks out of
                  </span>
                  <span className="text-lg font-semibold text-slate-700">
                    {`${totalMarks} Marks`}
                  </span>
                </div>
                <div className="flex flex-col gap-x-3 items-start  px-3 py-1 ">
                  <div className="flex flex-col items-end py-1">
                    <div className="flex flex-col items-start">
                      <h4>
                        Name: <strong> {userDetails.name} </strong>
                      </h4>
                      <h4>
                        Email: <strong> {userDetails.email} </strong>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: testData.description,
                  }}
                  className="text-muted mb-0"
                ></p>
              </div>

              {components.map((section, sectionIndex) => (
                <div
                  key={sectionIndex}
                  className="bg-white rounded  p-lg-5 shadow-sm mt-4"
                >
                  <h4 className="text-left text-2xl font-bold mb-0">
                    {section.title}
                  </h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: section.description,
                    }}
                    className="text-muted text-center mb-0"
                  ></p>

                  {section.components.map((question, questionIndex) => (
                    <div key={questionIndex}>
                      <div className="mt-3 mb-4 rounded shadow-lg p-3">
                        <RenderQuestion
                          sectionIndex={sectionIndex}
                          type='T'
                          isTestCreation={false}
                          question_details={question}
                          index={questionIndex}
                          response={studentResponse}
                          marking={marking}
                          onReviewChange={changeReview}
                          onScoreChange={changeScore}
                        />

                        {/* <TestQuestionReview
                          questionData={question}
                          questionIndex={questionIndex}
                          updateQuestion={(data) =>
                            updateQuestion(sectionIndex, questionIndex, data)
                          }
                        /> */}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>

            {/* <div className="flex justify-center gap-5">
              <button
                disabled={saving}
                type="submit"
                className="rounded items-center py-2 px-3 bg-emerald-600 text-white font-bold"
              >
                {saving && (
                  <img
                    alt="loading"
                    src={load}
                    className="spinner mr-2 w-6 h-6 text-primary "
                  />
                )}
                Save Review
              </button>
              <button
                className="rounded py-2 px-3 bg-cyan-600 text-white font-bold"
                onClick={(e) => previewTest(e)}
              >
                Preview Test
              </button>
            </div> */}
          </form>
        )}
      </div>
    </>
  );
};

export default ReviewTest;
