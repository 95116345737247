import {
  ADD_QUESTION_FAILURE,
  ADD_QUESTION_REQUESTED,
  ADD_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILURE,
  DELETE_QUESTION_REQUESTED,
  DELETE_QUESTION_SUCCESS,
  FETCH_ALL_QUESTIONS_FAILURE,
  FETCH_ALL_QUESTIONS_REQUESTED,
  FETCH_ALL_QUESTIONS_SUCCESS,
  FETCH_ALL_TAGS_REQUESTED,
  FETCH_ALL_TAGS_SUCCESS,
  SET_QUESTION_VIEW_STATUS,
  UPDATE_QUESTION_FAILURE,
  UPDATE_QUESTION_FILTER,
  UPDATE_RECENT_QUESTION,
  UPDATE_QUESTION_MODAL,
  UPDATE_QUESTION_REQUESTED,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_WITH_KEY,
} from "../actionTypes/questionbank";

export const updateQuestionbankModal = (data) => {
  return {
    type: UPDATE_QUESTION_MODAL,
    payload: data,
  };
};

export const updateQuestionWithKey = (data) => {
  return {
    type: UPDATE_QUESTION_WITH_KEY,
    payload: data,
  };
};

export const fetchAllQuestionsRequested = (data) => {
  return {
    type: FETCH_ALL_QUESTIONS_REQUESTED,
    payload: data,
  };
};

export const fetchAllQuestionsSuccess = (data) => {
  return {
    type: FETCH_ALL_QUESTIONS_SUCCESS,
    payload: data,
  };
};

export const fetchAllQuestionsFailure = () => {
  return {
    type: FETCH_ALL_QUESTIONS_FAILURE,
  };
};

export const addQuestionRequested = (data) => {
  return {
    type: ADD_QUESTION_REQUESTED,
    payload: data,
  };
};

export const addQuestionSuccess = (data) => {
  return {
    type: ADD_QUESTION_SUCCESS,
    payload: data,
  };
};

export const addQuestionFailure = () => {
  return {
    type: ADD_QUESTION_FAILURE,
  };
};

export const updateQuestionRequested = (data) => {
  return {
    type: UPDATE_QUESTION_REQUESTED,
    payload: data,
  };
};

export const updateQuestionSuccess = (data) => {
  return {
    type: UPDATE_QUESTION_SUCCESS,
    payload: data,
  };
};

export const updateQuestionFailure = () => {
  return {
    type: UPDATE_QUESTION_FAILURE,
  };
};

export const deleteQuestionRequested = (data) => {
  return {
    type: DELETE_QUESTION_REQUESTED,
    payload: data,
  };
};

export const deleteQuestionSuccess = (data) => {
  return {
    type: DELETE_QUESTION_SUCCESS,
    payload: data,
  };
};

export const deleteQuestionFailure = () => {
  return {
    type: DELETE_QUESTION_FAILURE,
  };
};

export const fetchAllTagsRequested = () => {
  return {
    type: FETCH_ALL_TAGS_REQUESTED,
  };
};

export const fetchAllTagsSuccess = (data) => {
  return {
    type: FETCH_ALL_TAGS_SUCCESS,
    payload: data,
  };
};

export const setQuestionStatus = (data) => {
  return {
    type: SET_QUESTION_VIEW_STATUS,
    payload: data,
  };
};

export const updateQuestionFilter = (data) => {
  return {
    type: UPDATE_QUESTION_FILTER,
    payload: data,
  };
};

export const updateRecentQuestion = (data) => {
  return {
    type: UPDATE_RECENT_QUESTION,
    payload: data,
  };
};
