import { loader } from "@monaco-editor/react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { copyInsideCodeEditor } from "../../../../store/actions/codeeditor";
import { useRef } from "react";

export default function CodeEditor(props) {
  const dispatch = useDispatch();
  const { language, value, theme } = props;
  const [editor, setEditor] = useState(null);
  const editorRef = useRef();
  const { is_copy_inside_editor } = useSelector((state) => state.codeeditor);

  useEffect(() => {
    editorRef.current = is_copy_inside_editor;
  }, [is_copy_inside_editor])

  useEffect(() => {
    loader.init().then((monaco) => {
      // Register a new language
      monaco.languages.register({ id: "mySpecialLanguage" });
      const container = document.getElementById("container_editor");
      if (!container.children.length) {
        const tempMonaco = monaco.editor.create(
          document.getElementById("container_editor"),
          {
            theme: theme ? "vs-dark" : "vs",
            value: value,
            language: language,
            automaticLayout: true,
            selectOnLineNumbers: true,
            renderLineHighlight: "none",
            minimap: { enabled: false },
            renderIndentGuides: true,
            scrollBeyondLastLine: true,
            quickSuggestions: true,
          }
        );
        setEditor(tempMonaco);
        tempMonaco.onDidChangeModelContent(function (event) {
          // Handle the onchange event here
          const updatedValue = tempMonaco.getValue();
          // tempMonaco.setValue(updatedValue);
          props.updateCode(updatedValue);
        });

        // tempMonaco.getContainerDomNode().addEventListener(
        //   "paste",
        //   (event) => {
        //     console.log("checking paste listener")
        //     innerWidth
        //   },
        //   true
        // );
        tempMonaco.onKeyDown((event) => {
          const { keyCode, ctrlKey, metaKey } = event;
          if ((keyCode === 52) && (metaKey || ctrlKey)) {
            if (!editorRef.current) {
              if (props.is_disable_copy_paste) {
                event.preventDefault();
                event.stopPropagation();
              }
            } else dispatch(copyInsideCodeEditor(false));
          }
        });
        tempMonaco
          .getContainerDomNode()
          .addEventListener(
            "copy",
            () => {
              console.log("checking copy listener")
              dispatch(copyInsideCodeEditor(true))
            },
            true
          );

        tempMonaco
          .getContainerDomNode()
          .addEventListener(
            "cut",
            () => {
              console.log("checking cut listener")
              dispatch(copyInsideCodeEditor(true))
            },
            true
          );
      }
    });
  }, []);

  useEffect(() => {
    if (editor && value !== editor.getValue()) {
      editor.setValue(value);
    }
  }, [language, value]);

  useEffect(() => {
    if (editor) {
      editor._themeService.setTheme(theme ? "vs-dark" : "vs");
    }
  }, [theme]);

  return <div id="container_editor" className="h-full w-full" />;
}
