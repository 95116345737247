import React, { Component } from "react";
import TestHeader from "../tests/createTest/TestHeader";
import { connect } from "react-redux";
import { fetchProctoredUserDetailsRequested } from "../../store/actions/userdetails";
import { fetchTestScoreRequested } from "../../store/actions/test";
import { generatePeerId } from "./utils";
import { viewFile } from "../../services/upload.service";
import { fetchChatMsgsRequested } from "../../store/actions/chat";
import CandidateTestReview from "./CandidateTestReview";
import dummy from "../../assets/img/svg/dummypic.svg";
import { formateTime } from "./utils/TimeFormat";
import { withRouter } from "../common/WithRouter";
import { MinsToHrs } from "../common/utils";

class ProctoringReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ImageFilesUrl: "",
      IdFilesUrl: "",
    };
    this.fetchProctorAndTestDetails =
      this.fetchProctorAndTestDetails.bind(this);
  }
  //   componentDidMount() {
  //     const search = this.props.location.search;
  //     const queryParams = new URLSearchParams(search);
  //     const term = queryParams.get("public");
  //     if (term !== "true") {
  //       const studentPeerId = generatePeerId("student", {
  //         testId: this.props.params.testId,
  //         userId: this.props.params.id,
  //       });
  //       this.props.fetchProctoredUserDetailsRequested({
  //         testId: this.props.params.testId,
  //         userId: studentPeerId,
  //       });
  //     }
  //     this.props.fetchTestScoreRequested({
  //       testId: this.props.params.testId,
  //       userId: this.props.params.id,
  //     });
  //   }

  fetchProctorAndTestDetails = (testId, userId) => {
    const studentPeerId = generatePeerId("student", {
      testId,
      userId,
    });

    this.props.fetchProctoredUserDetailsRequested({
      testId,
      userId: studentPeerId,
    });

    this.props.fetchTestScoreRequested({
      testId,
      userId,
    });
  };

  getImageUrl(filename) {
    if (filename && filename.length) {
      viewFile(filename, "PROCTORING").then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          ImageFilesUrl: res,
        }));
      });
    }
  }

  getIdUrl(filename) {
    if (filename && filename.length) {
      viewFile(filename, "PROCTORING").then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          IdFilesUrl: res,
        }));
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.proctoredUserDetails !== prevProps.proctoredUserDetails) {
      this.getIdUrl(this.props.proctoredUserDetails.idCardImg);
      this.getImageUrl(this.props.proctoredUserDetails.studentPhotoImg);
    }

  }

  render() {
    const student = this.props.proctoredUserDetails;
    const score = this.props.testScoreDetails;

    return (
      <>
        {Object.keys(this.props.response)?.length ? (
          <div className="sm:p-5 p-2 sm:px-8">
            <TestHeader
              styles="text-2xl text-[#000000] font-medium"
              title="Candidate Details"
            />
            {this.props.response?.quiz?.is_proctoring && (
              <div className="mt-[1.5rem] mb-[2.5rem] flex flex-col sm:flex-row items-center justify-evenly gap-[1rem]">
                <img
                  src={this.state.ImageFilesUrl || dummy}
                  alt="studentImage"
                  className="border-2 border-slate-300"
                  title="User_Photo"
                />
                <img
                  src={this.state.IdFilesUrl || dummy}
                  alt="studentImage"
                  className="border-2 border-slate-300"
                  title="User Id"
                />
              </div>
            )}
            <div className="grid w-full px-3 md:px-0 lg:grid-cols-3 sm:grid-cols-2 gap-y-3 items-center md:items-start mb-[1rem] gap-2 px-3 md:px-0">
              <div className=" sm:p-3 p-1 px-2 ">
                <p className="pl-2 text-lg font-medium font-['Inter']">Name</p>
                <p
                  className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
                  style={{
                    border: "1.5px solid #E0E0E0",
                    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {this.props.response?.user?.name || this.props.response?.name}
                </p>
              </div>
              <div className=" sm:p-3  p-1 ">
                <p className="pl-2 text-lg font-medium font-['Inter']">Email</p>
                <p
                  className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
                  style={{
                    border: "1.5px solid #E0E0E0",
                    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {this.props.response?.user?.email ||
                    this.props.response?.email}
                </p>
              </div>
              {this.props.testScoreDetails?.submission?.college && (
                <div className=" sm:p-3 p-1 px-2 ">
                  <p className="pl-2 text-lg font-medium font-['Inter']">
                    Institution Name
                  </p>
                  <p
                    className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
                    style={{
                      border: "1.5px solid #E0E0E0",
                      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    {this.props.testScoreDetails?.submission?.college.length >
                      22
                      ? this.props.testScoreDetails?.submission?.college.slice(
                        0,
                        22
                      ) + "..."
                      : this.props.testScoreDetails?.submission?.college}
                  </p>
                </div>
              )}
              {this.props.testScoreDetails?.submission?.mobile && (
                <div className=" sm:p-3 p-1 px-2 ">
                  <p className="pl-2 text-lg font-medium font-['Inter']">
                    Mobile
                  </p>
                  <p
                    className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
                    style={{
                      border: "1.5px solid #E0E0E0",
                      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    {this.props.testScoreDetails?.submission?.mobile}
                  </p>
                </div>
              )}
              <div className=" sm:p-3 p-1 px-2 ">
                <p className="pl-2 text-lg font-medium font-['Inter']">
                  Time Zone
                </p>{" "}
                <p
                  className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
                  style={{
                    border: "1.5px solid #E0E0E0",
                    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  India Standard Time(GMT+5:30)
                </p>
              </div>
              <div className=" sm:p-3 p-1 px-2 ">
                <p className="pl-2 text-lg font-medium font-['Inter']">
                  Resume
                </p>
                {this.props.testScoreDetails?.submission?.cv_link ? (
                  <a
                    className="bg-cyan-300 mx-2 rounded px-2 py-1 font-medium text-xs text-white"
                    href={this.props.testScoreDetails?.submission?.cv_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Link
                  </a>
                ) : (
                  <span className="bg-red-300  mx-2 rounded px-2 py-1 font-medium text-xs text-white">
                    Not available
                  </span>
                )}
              </div>
            </div>
            <hr className="h-2" />

            <TestHeader
              styles="text-2xl text-[#000000] font-medium"
              title="Session Details"
            />
            <div className="w-full grid px-3 md:px-0 lg:grid-cols-3 sm:grid-cols-2 gap-y-3 items-center md:items-start">
              <div className="md:p-3 sm:p-2 p-1 px-2">
                <p className="pl-2 text-lg font-medium font-['Inter']">
                  Test Name
                </p>
                <p
                  className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
                  style={{
                    border: "1.5px solid #E0E0E0",
                    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {this.props.response?.quiz?.title}
                </p>
              </div>
              <div className="md:p-3 sm:p-2 p-1 px-2">
                <p className="pl-2 text-lg font-medium font-['Inter']">
                  Test Start Time
                </p>
                <p
                  className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
                  style={{
                    border: "1.5px solid #E0E0E0",
                    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {Boolean(this.props.response.start_time) ? formateTime(this.props.response?.start_time)

                    :

                    new Date(
                      this.props.response?.quiz?.start_duration
                    ).toLocaleDateString() +
                    " " +
                    new Date(
                      this.props.response?.quiz?.start_duration
                    ).toLocaleTimeString()}
                </p>
              </div>
              <div className="md:p-3 sm:p-2 p-1 px-2">
                <p className="pl-2 text-lg font-medium font-['Inter']">
                  Test End Time
                </p>
                <p
                  className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
                  style={{
                    border: "1.5px solid #E0E0E0",
                    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {Boolean(this.props.response.end_time) ? formateTime(this.props.response?.end_time)

                    :

                    new Date(
                      this.props.response?.quiz?.end_duration
                    ).toLocaleDateString() +
                    " " +
                    new Date(
                      this.props.response?.quiz?.end_duration
                    ).toLocaleTimeString()}
                </p>
              </div>
              <div className="md:p-3 sm:p-2 p-1 px-2">
                <p className="pl-2 text-lg font-medium font-['Inter']">
                  Test Duration
                </p>
                <p
                  className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
                  style={{
                    border: "1.5px solid #E0E0E0",
                    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {this.props.response?.quiz?.allow_flexible_exam_time ?
                    MinsToHrs(
                      (new Date(this.props.response?.start_time) -
                        new Date(this.props.response?.end_time)) /
                      60000
                    ) : MinsToHrs(
                      (new Date(this.props.response?.quiz?.end_duration) -
                        new Date(this.props.response?.quiz?.start_duration)) /
                      60000
                    )}
                </p>
              </div>
              <div className="md:p-3 sm:p-2 p-1 px-2">
                <p className="pl-2 text-lg font-medium font-['Inter']">
                  Time Taken
                </p>
                <p
                  className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
                  style={{
                    border: "1.5px solid #E0E0E0",
                    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {MinsToHrs(parseInt(score.time_taken))}
                </p>
              </div>

              <div className="md:p-3 sm:p-2 p-1 px-2">
                <p className="pl-2 text-lg font-medium font-['Inter']">Score</p>
                <p
                  className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
                  style={{
                    border: "1.5px solid #E0E0E0",
                    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {(score.submission && score.submission?.marks?.toFixed(2)) ||
                    0}
                  /{score.total_marks}
                </p>
              </div>
              <div className="md:p-3 sm:p-2 p-1 px-2">
                <p className="pl-2 text-lg font-medium font-['Inter']">
                  Normalized Score
                </p>
                <p
                  className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
                  style={{
                    border: "1.5px solid #E0E0E0",
                    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {Boolean(score?.normalized_score) ? score?.normalized_score?.toFixed(3, 0) : "-"}
                </p>
              </div>

              <div className="md:p-3 sm:p-2 p-1 px-2">
                <p className="pl-2 text-lg font-medium font-['Inter']">Rank</p>
                <p
                  className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
                  style={{
                    border: "1.5px solid #E0E0E0",
                    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {score.current_rank}/{score.num_participants}
                </p>
              </div>
              {/* {score?.grade && */}
              <div className="md:p-3 sm:p-2 p-1 px-2">
                <p className="pl-2 text-lg font-medium font-['Inter']">Grade</p>
                <p
                  className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
                  style={{
                    border: "1.5px solid #E0E0E0",
                    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {score?.submission?.grade ? score?.submission?.grade : "-"}
                </p>
              </div>
              {/* } */}
              {this.props.testScoreDetails.submission?.custom_total_students !==
                undefined && (
                  <div className="md:p-3 sm:p-2 p-1 px-2">
                    <p className="pl-2 text-lg font-medium font-['Inter']">
                      Overall Rank
                    </p>
                    <p
                      className="py-2 px-[1.5rem] rounded-[8px] bg-[#fff] text-base font-medium font-['Inter'] text-[#637381]"
                      style={{
                        border: "1.5px solid #E0E0E0",
                        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      {this.props.testScoreDetails?.submission?.custom_rank}/
                      {
                        this.props.testScoreDetails?.submission
                          ?.custom_total_students
                      }
                    </p>
                  </div>
                )}

              {/* {this.props.response?.quiz?.is_proctoring && (
              <>
                {this.props.testScoreDetails?.submission?.proctor_name && (
                  <div className="shadow-md rounded md:p-3 sm:p-2 p-1 px-2 mr-10 bg-slate-50">
                    <p className="text-slate-500 font-thin font-serif">
                      Proctor Name
                    </p>
                    <p
                      className={`flex items-center font-medium sm:text-lg md:text-xl text-md`}
                    >
                      {this.props.testScoreDetails.submission.proctor_name}
                    </p>
                  </div>
                )}
                {this.props.testScoreDetails?.submission?.proctor_email && (
                  <div className="shadow-md rounded md:p-3 sm:p-2 p-1 px-2 mr-10 bg-slate-50">
                    <p className="text-slate-500 font-thin font-serif">
                      Proctor Email
                    </p>
                    <p
                      className={`flex items-center font-medium sm:text-lg md:text-xl text-md`}
                    >
                      {this.props.testScoreDetails.submission.proctor_email}
                    </p>
                  </div>
                )}
              </>
            )} */}
            </div>
            {/* <hr className="h-2" /> */}

            {this.props.response?.quiz?.is_proctoring && (
              <>
                <TestHeader
                  styles="text-2xl text-[#000000] font-medium"
                  title="Proctor Comments"
                />
                {Boolean(
                  this.props.testScoreDetails?.submission?.proctor_comments
                    ?.length
                ) ? (
                  <div className="mb-4  py-2 rounded ">
                    <ul className=" text-slate-500 italic">
                      {this.props.testScoreDetails?.submission?.proctor_comments?.map(
                        (each, index) => (
                          <li key={`proctor-comments-${index}`}>
                            <span>{index + 1}</span>: {each}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                ) : (
                  "No Proctor Comments available"
                )}

                <hr className="h-2" />
                <TestHeader
                  styles="text-2xl text-[#000000] font-medium"
                  title="Proctoring Flags"
                />
                <div className="mb-4 pb-2 rounded ">
                  <ul className="text-slate-500 italic">
                    {Boolean(this.props.response?.proctoring_flags?.length)
                      ? this.props.response?.proctoring_flags?.map(
                        (each, index) => (
                          <li key={`proctor-flags-${index}`}>
                            <span>{index + 1}</span>: {each.name}
                          </li>
                        )
                      )
                      : "No Proctoring Flags available"}
                  </ul>
                </div>

                <hr className="h-2" />
                <TestHeader
                  styles="text-2xl text-[#000000] font-medium"
                  title="Cheating Flags"
                />
                <div className="mb-4 flex ">
                  <div className="rounded ">
                    <ul className="text-slate-500 italic">
                      {Boolean(this.props.response?.cheating_flags?.length)
                        ? this.props.response?.cheating_flags?.map(
                          (each, index) => {
                            return (
                              <li key={`cheating-flags-${each._id}`}>
                                <span>{index + 1}</span>: {each.name}
                              </li>
                            );
                          }
                        )
                        : "No Cheating Flags available"}
                    </ul>
                  </div>
                </div>
              </>
            )}
            <hr className="h-2" />
          </div>
        ) : null}

        <div className=" sm:px-8">
          <CandidateTestReview
            id={this.props.params.id}
            fetchProctorAndTestDetails={this.fetchProctorAndTestDetails}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ test, sidebar, turnserver, userdetails, chat }) => {
  return {
    isFetching: userdetails.isFetching,
    proctoredUserDetails: userdetails.proctoredUserDetails,
    testScoreDetails: test.testScoreDetails,
    chat,
    response: test.submission_response,
    chatMsgs: chat.messages,
    isMsgsLoading: chat.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProctoredUserDetailsRequested: (data) =>
      dispatch(fetchProctoredUserDetailsRequested(data)),
    fetchTestScoreRequested: (data) => dispatch(fetchTestScoreRequested(data)),
    fetchChatMsgsRequested: (data) => dispatch(fetchChatMsgsRequested(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProctoringReport));
