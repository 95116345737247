import React from "react";
import { Link } from "react-router-dom";
import CourseService from "../../services/course.service.js";
import UserService from "../../services/user.service.js";

import pencil from "../../assets/img/svg/pencil.svg";
import delete1 from "../../assets/img/svg/delete.svg";
import plus from "../../assets/img/svg/plus4.svg";
import load from "../../assets/img/svg/load.svg";
import { withRouter } from "../../components/common/WithRouter.jsx";

class AllCourses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allCourses: [],
      pageLoading: true,
      teachersList: [],
      value: "default",
    };
  }

  componentDidMount() {
    this.setState({ pageLoading: true });
    CourseService.getAllCourses().then((response) => {
      this.setState({ allCourses: response.data });
      this.setState({ pageLoading: false });
    });
    UserService.getAllUsers()
      .then((response) => {
        const teachers = response.data.filter((each) => each.type === "T");
        this.setState({ teachersList: teachers });
      })
      .catch((err) => console.log(err));
  }

  isAdmin = () => {
    var type = localStorage["priksha_type"];
    return type === "A";
  };

  deleteCourse = (id) => {
    this.props.history(`/allcourses/delete/${id}`);
  };

  displaySpecificCourses = async (e) => {
    e.preventDefault();
    this.setState({ pageLoading: true });
    let totalData;
    await CourseService.getAllCourses().then((response) => {
      totalData = response.data;
    });
    if (e.target.value === "default") {
      CourseService.getAllCourses().then((response) => {
        this.setState({ allCourses: totalData });
      });
    } else {
      const teacherCourses = totalData.filter((course) => {
        const list = course.teachers.filter(
          (teacher) => teacher._id === e.target.value
        );
        if (list.length > 0) return true;
        else return false;
      });
      this.setState({ allCourses: teacherCourses });
    }
    this.setState({ pageLoading: false });
  };

  render() {
    return (
      <>
        <div className="w-full py-3 px-4">
          <div className="flex flex-row justify-between">
            <div>
              <p className="m-2 text-3xl font-bold">All Courses</p>
            </div>
            <div>
              <Link
                to="/createcourse"
                className="flex items-center border border-gray-300 py-2 px-3 rounded text-dark justify-center"
              >
                Create Course
                <img src={plus} title="Create course" className="h-4 w-4 ml-2" px-2 alt="plus" />
              </Link>
            </div>
          </div>

          <hr />
          {this.isAdmin() ? (
            <>
              <div className="m-2">Filter by teacher:</div>
              <select
                name="teacherList"
                id="teachers"
                className="form-control border border-gray-300 p-2  mb-3"
                onChange={this.displaySpecificCourses}
                style={{ width: "25%" }}
                value={this.state.value}
              >
                <option value="default">All</option>
                {this.state.teachersList.map((teacher) => {
                  return (
                    <option
                      key={`${teacher._id}`}
                      value={`${teacher._id}`}
                    >{`${teacher.name} (${teacher.email})`}</option>
                  );
                })}
              </select>
            </>
          ) : (
            <> </>
          )}
          <table className="w-full text-sm text-left">
            <thead className="text-sm shadow-md uppercase border border-gray-300">
              <tr>
                <th className="p-3">Course Code</th>
                <th className="p-3">Course Title</th>
                <th className="p-3">Course Description</th>
                <th className="p-3">No of Students</th>
                <th className="p-3">No of Teachers</th>
                <th className="p-3">No of TAs</th>
                <th className="p-3">View Course</th>
                <th className="p-3">Edit or Delete</th>
              </tr>
            </thead>
            <tbody>
              {this.state.pageLoading ? (
                <>
                  <tr>
                    <td className="p-3">
                      <div
                        className="justify-center items-center flex"
                      // style={{ minHeight: "200px" }}
                      >

                        <img
                          alt="loading"
                          src={load}
                          className="spinner mr-2 w-6 h-6 text-primary " />
                      </div>
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  {this.state.allCourses.length > 0 ? (
                    this.state.allCourses.map((course) => {
                      return (
                        <tr className="border-b-2" key={course._id}>
                          <td className="p-3 border-x">{course.code}</td>
                          <td className="p-3 border-r">{course.title}</td>
                          <td className="p-3 border-r">{course.description}</td>
                          <td className="p-3 border-r">{course.students.length}</td>
                          <td className="p-3 border-r">{course.teachers.length}</td>
                          <td className="p-3 border-r">{course.tas.length}</td>
                          <td className="p-3 border-r">
                            <Link to={`/allcourses/${course._id}`}>
                              View Course
                            </Link>
                          </td>
                          <td className="flex justify-around p-3 border-r">
                            <img src={pencil} Title="Edit" className="h-4 w-4 ml-2" px-2 alt="plus" style={{ cursor: "pointer" }} />
                            <img src={delete1} title="Delete" onClick={() => this.deleteCourse(course._id)} className="h-5 w-6 ml-2" px-2 alt="plus" style={{ cursor: "pointer" }} />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>
                        <h4
                          style={{
                            position: "absolute",
                            width: "100%",
                            textAlign: "center",
                            margin: "20px 0",
                          }}
                        >
                          No Courses assigned to this teacher
                        </h4>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default withRouter(AllCourses);
