import axios from "axios";
import { BASE_URL } from "../assets/js/config";
import authHeader from "./auth-header";

export const getQuickStatsHr = ({ testId }) => {
  return axios
    .get(`${BASE_URL}api/quizproctoring/${testId}/proctoredusers`, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error.response.data);
    });
};

export const getGraphStatsHr = (data) => {
  return axios
    .post(`${BASE_URL}api/quiz/generatesummary`, data, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);

      throw Error(error.response.data);
    });
};

export const getSummaryStatsHr = (filter) => {
  let query_arr = [];
  for (let filter_key in filter) {
    const value = filter[filter_key];
    if (value !== null && value !== undefined) {
      query_arr.push(`${filter_key}=${value}`);
    }
  }
  const query_str = query_arr.join("&");
  return axios
    .get(`${BASE_URL}api/summary?${query_str}`, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error.response.data);
    });
};
