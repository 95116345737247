import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import { defaultToastSetting } from "../../assets/js/toast_config";
import {
  addQuestionService,
  deleteQuestionService,
  fetchAllTagsService,
  getAllQuestionsService,
  updateQuestionService,
} from "../../services/questionbank.service";
import {
  addQuestionFailure,
  addQuestionSuccess,
  deleteQuestionFailure,
  deleteQuestionSuccess,
  fetchAllQuestionsFailure,
  fetchAllQuestionsSuccess,
  fetchAllTagsSuccess,
  updateQuestionFailure,
  updateQuestionSuccess,
} from "../actions/questionbank";
import { deleteTestRequest, deleteTestSuccess } from "../actions/test";
import {
  ADD_QUESTION_REQUESTED,
  DELETE_QUESTION_REQUESTED,
  FETCH_ALL_QUESTIONS_REQUESTED,
  FETCH_ALL_TAGS_REQUESTED,
  UPDATE_QUESTION_REQUESTED,
} from "../actionTypes";
import { disableExtractedQuestion } from "../actions/extractQuestion";

function* fetchAllQuestionsSaga(action) {
  try {
    const allQuestions = yield call(getAllQuestionsService, action.payload);
    yield put(fetchAllQuestionsSuccess(allQuestions));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(fetchAllQuestionsFailure(e));
  }
}

function* addQuestionSaga(action) {
  try {
    let questionres;
    if (action.payload?.isExtractQuestion) {
      const { questions, setQuestionOrTest, index } = action.payload;
      questionres = yield call(addQuestionService, questions);

      yield put(
        disableExtractedQuestion({
          index: index,
          res: questionres,
        })
      );
      if (setQuestionOrTest) setQuestionOrTest(true);
    } else {
      questionres = yield call(addQuestionService, action.payload);
      yield put(addQuestionSuccess(questionres));
    }
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(addQuestionFailure(e));
  }
}

function* updateQuestionSaga(action) {
  try {
    const questionres = yield call(updateQuestionService, action.payload);
    yield put(updateQuestionSuccess(questionres));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(updateQuestionFailure(e));
  }
}

function* deleteQuestionSaga(action) {
  try {
    yield put(deleteTestRequest());
    const questionres = yield call(deleteQuestionService, action.payload);
    yield put(deleteTestSuccess());
    yield put(deleteQuestionSuccess(questionres));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(deleteQuestionFailure(e));
  }
}

function* fetchAllTagsRequestedSaga() {
  try {
    const TagsResponse = yield call(fetchAllTagsService);
    yield put(fetchAllTagsSuccess(TagsResponse));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
  }
}

function* questionsSaga() {
  yield takeEvery(FETCH_ALL_QUESTIONS_REQUESTED, fetchAllQuestionsSaga);
  yield takeEvery(ADD_QUESTION_REQUESTED, addQuestionSaga);
  yield takeEvery(DELETE_QUESTION_REQUESTED, deleteQuestionSaga);
  yield takeEvery(UPDATE_QUESTION_REQUESTED, updateQuestionSaga);
  yield takeEvery(FETCH_ALL_TAGS_REQUESTED, fetchAllTagsRequestedSaga);
}

export default questionsSaga;
