import React from "react";

export default class Courses extends React.Component {
  render() {
    return (
      <div fluid style={{ padding: "15px 60px" }}>
        <div>
          <div className="ml-3" md="9">
            <div>
              <h2 className="mt-3 font-weight-bold text-dark mb-0">
                Courses Assigned
              </h2>
            </div>
          </div>
        </div>
        <hr />
        <table>
          <thead>
            <tr>
              <th>Course No</th>
              <th>Course Name</th>
              <th>Course Department</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CST - 310</td>
              <td>Software Engineering</td>
              <td>CSE</td>
            </tr>
            <tr>
              <td>CST - 311</td>
              <td>Computer Networks</td>
              <td>CSE</td>
            </tr>
            <tr>
              <td>CST - 312</td>
              <td>Database Management System</td>
              <td>CSE</td>
            </tr>
            <tr>
              <td>CST - 313</td>
              <td>Operating System</td>
              <td>CSE</td>
            </tr>
            <tr>
              <td>CST - 314</td>
              <td>Mathematics I</td>
              <td>CSE</td>
            </tr>
            <tr>
              <td>CST - 315</td>
              <td>Machine Learning</td>
              <td>CSE</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

