import { FETCH_ALL_EVALUATORS_REPORTS_FAILURE, FETCH_ALL_EVALUATORS_REPORTS_REQUESTED, FETCH_ALL_EVALUATORS_REPORTS_SUCCESS, FETCH_REPORT_FAILURE, FETCH_REPORT_REQUESTED, FETCH_REPORT_SUCCESS, UPDATE_SCORE_REQUESTED } from "../actionTypes/evaluators";


const initialState = {
    reportsAssigned: null,
    loading: false,
    report: null,
};

const evaluatorsReducer = function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ALL_EVALUATORS_REPORTS_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case FETCH_ALL_EVALUATORS_REPORTS_SUCCESS:
            return {
                ...state,
                reportsAssigned: payload,
                loading: false,
            }

        case FETCH_ALL_EVALUATORS_REPORTS_FAILURE:
            return {
                ...state,
                loading: false
            }

        case FETCH_REPORT_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case FETCH_REPORT_SUCCESS:
            // const temp = payload.marking;
            // if (!temp[payload.currentsectionIndex]) {
            //     temp[payload.currentsectionIndex] = {};
            // }
            // if (!temp[payload.currentsectionIndex][payload.currentQuestionIndex]) {
            //     temp[payload.currentsectionIndex][payload.currentQuestionIndex] = { score: "", review: "", subscore: ["", "", ""] }
            // }
            return {
                ...state,
                report: { ...payload },
                loading: false,
            }

        case FETCH_REPORT_FAILURE:
            return {
                ...state,
                loading: false
            }

        case UPDATE_SCORE_REQUESTED:
            const tempMarking = state.report?.marking;
            if (!tempMarking[payload.currentsectionIndex]) {
                tempMarking[payload.currentsectionIndex] = {};
            }
            if (!tempMarking[payload.currentsectionIndex]?.[payload.currentQuestionIndex]?.subscore) {
                tempMarking[payload.currentsectionIndex][payload.currentQuestionIndex] = { score: "", review: "", subscore: ['', '', ''], unratable: false, technical_issue: false }
            }

            tempMarking[payload.currentsectionIndex][payload.currentQuestionIndex][payload.key] = payload.value;

            // tempMarking[payload.currentsectionIndex][payload.currentQuestionIndex] = { ...tempMarking[payload.currentsectionIndex][payload.currentQuestionIndex], [payload.key]: payload.value }
            return {
                ...state,
                report: { ...state.report, marking: { ...tempMarking } }
            }


        default:
            return state;
    }
};

export default evaluatorsReducer;
