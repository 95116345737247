import React, { useEffect } from "react";
import load from "../../assets/img/svg/load.svg";
import "../../assets/css/Dashboard.css";
import { useState } from "react";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import OngoingInterviewCards from "../../components/interviews/OngoingInterviewCards";
import { useDispatch } from "react-redux";
import { fetchAllInterviewsRequested } from "../../store/actions/interviewpanel";
import DeleteTest from "../../components/tests/createTest/DeleteTest";
import { updateTestDeleteModalActive } from "../../store/actions/test";
import UpcomingInterviews from "../../components/interviews/UpcomingInterviewCards";
import EndedInterviews from "../../components/interviews/EndedInterviewCards";
import Modal from "../../components/common/Modal/Modal";
import InterviewModal from "../../components/modals/InterviewModal";
import { getType } from "../../components/common/utils";

dayjs.extend(utc);
dayjs.extend(timezone);

const Interviews = () => {
  const [interviewId, setInterviewId] = useState("");
  const [interviewModal, setInterviewModal] = useState(false);
  const [editInterview, setEditInterview] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllInterviewsRequested({ type: "ongoing" }));
    dispatch(fetchAllInterviewsRequested({ type: "upcoming" }));
    dispatch(fetchAllInterviewsRequested({ type: "ended" }));
  }, []);

  const handleToastWarning = (msg) => {
    toast.warning(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const updateInterviewModal = () => {
    setInterviewModal(false);
    window.location.reload();
  };

  const handleDeleteTest = (testId) => {
    setInterviewId(testId);
    dispatch(updateTestDeleteModalActive(true));
  };

  return (
    <>
      <DeleteTest
        testId={interviewId}
        title="I"
        handleToast={handleToastWarning}
      />
      <div
        className="rounded px-4 py-3 flex flex-col lg:flex-row lg:items-center justify-between mx-[0.8rem] mt-[1.2rem]"
        style={{
          boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
          background: "linear-gradient(91deg, #C6FB63 2.1%, #94E005 98.82%)",
        }}
      >
        <p className="text-[1.1rem] sm:text-[1.5rem] font-semibold font-['poppins']">
          Warmest Welcome to Interview
        </p>
        {
          getType() === 'T' ? <button
          className="w-[10rem] bg-[#BCFB46] hover:bg-gray-800 hover:text-white font-bold uppercase text-xs px-4 py-3 mb-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
          onClick={() => setInterviewModal(true)}
        >
          Create Interview
        </button> :null
        }
        
      </div>
      {false ? (
        <div
          className="w-full h-full flex justify-center items-center"
          style={{ minHeight: "200px" }}
        >
          <img
            alt="loading"
            src={load}
            className="spinner mr-2 w-6 h-6 text-primary"
          />
        </div>
      ) : (
        <div className="w-full h-full px-3 flex flex-col  flex-start items-start mt-[1rem] mb-20">
          <OngoingInterviewCards
            handleDeleteTest={(data) => handleDeleteTest(data)}
            setEditInterview={setEditInterview}
          />
          <UpcomingInterviews
            handleDeleteTest={(data) => handleDeleteTest(data)}
            setEditInterview={setEditInterview}
          />
          <EndedInterviews
            handleDeleteTest={(data) => handleDeleteTest(data)}
            setEditInterview={setEditInterview}
          />
        </div>
      )}

      <Modal
        onClose={() => {
          setInterviewModal(false);
          setEditInterview({});
        }}
        isModal={interviewModal || Object.keys(editInterview).length}
        showCloseButton
        className={"lg:w-[55%]"}
      >
        <InterviewModal
          updateInterviewModal={() => updateInterviewModal()}
          // responseId={this.state.responseId}
          // email={this.state.responseEmail}
          startTimeInterview={editInterview.startTimeInterview}
          endTimeInterview={editInterview.endTimeInterview}
          interviewer_email={editInterview.interviewer_email}
          student_email={editInterview.student_email}
          remark={editInterview.remark}
          is_disable_copy_paste={editInterview.is_disable_copy_paste}
          is_disable_tab_switch={editInterview.is_disable_tab_switch}
          company={editInterview.company}
          group={editInterview.group}
          interview_title={editInterview.interview_title}
          status={editInterview.status}
          interview_id={editInterview._id}
        />
      </Modal>
    </>
  );
};

export default Interviews;
