import dayjs from "dayjs";

export const formateTime = (date) => {
  return dayjs(date).format("DD/MM/YYYY  hh:mm A");
};

export const formateDate = (date) => {
  return dayjs(date).format("DD/MM/YYYY");
};

export const findDuration = (start, end) => {
  const startTime = dayjs(start);
  const endTime = dayjs(end);
  const milliseconds = endTime.diff(startTime);
  let seconds = Math.floor((milliseconds / 1000) % 60);
  let minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  let hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
  let days = Math.floor(milliseconds / (1000 * 60 * 60) / 24);

  days = days < 10 ? "0" + days : days;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  let diff = ""
  if (parseInt(days) !== 0)
    diff = days + "Days " + hours + "Hrs " + minutes + "Mins "
  else if (parseInt(hours) !== 0)
    diff = hours + "Hrs " + minutes + "Mins "
  else
    diff = minutes + "Mins "

  return diff;
};

export const findDurationInHrsMins = (start, end) => {
  const startTime = dayjs(start);
  const endTime = dayjs(end);
  const milliseconds = endTime.diff(startTime);
  let minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  let hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return hours + " hrs " + minutes + " mins ";
};

