import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { ReactComponent as HamburgerIcon } from "../../assets/img/svg/hamburger.svg";
import { ReactComponent as NavBarCloseIcon } from "../../assets/img/svg/navbar_close.svg";
import "./Common.css";
import { logout } from "../../store/actions/auth";
import { toggleSidebar } from "../../store/actions/sidebar";
import KyronSideLetter from "../../assets/img/Kyron/kyron_logo.png";
import RoundedUser from "./RoundedUser";

const Navbar = ({ user }) => {
  const dispatch = useDispatch();

  const { opened: sideBarOpened } = useSelector((state) => state.sidebar);
  const logOut = () => {
    dispatch(logout());
    localStorage.clear();
  };

  const _toggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  return (
    <>
      {user && (
        <>
          <nav className="flex justify-between px-4 py-2 border-b border-border fixed w-[100%] z-[49] bg-white drop-shadow-[2px_0px_2px_rgba(0,0,0,0.25)]">
            {user && (
              <div
                className="flex items-center"
                type="button"
                onClick={_toggleSidebar}
              >
                <div className="ml-2 cursor-pointer">
                  {sideBarOpened ? (
                    <NavBarCloseIcon className="w-[100%]" />
                  ) : (
                    <HamburgerIcon className="w-[60%]" />
                  )}
                </div>



                <img
                  className="h-[38px] object-contain md:ml-5 ml-2"
                  src={KyronSideLetter}
                  title="Logo"
                  alt="kyron"
                />

              </div>
            )}
            <div className="nav-links">
              {user ? (
                <>
                  <RoundedUser logOut={logOut} user={user} />
                </>
              ) : (
                <>
                  <div className="nav-item space-x-4">
                    <Link to={"/login"} className="nav-login">
                      Login
                    </Link>
                  </div>
                </>
              )}
            </div>
          </nav>
          <div className="pb-12"></div>
        </>
      )}
    </>
  );
};

export default Navbar;
