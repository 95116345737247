import React, { useEffect, useRef, useState } from "react";
import "../common/Common.css";
import { ReactComponent as EyeCrossIcon } from "../../assets/img/svg/cross-eye.svg";
import { ReactComponent as EyeIcon } from "../../assets/img/svg/heye.svg";

import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";


import {
  loginSuccess,
  logout,
  signupRequested,
} from "../../store/actions/auth";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../assets/js/config";
import { defaultToastSetting } from "../../assets/js/toast_config";
import qs from "qs";
import { checkUser } from "../../services/user.service";
import GithubSignup from "./githubSignup";
import ReCAPTCHA from "react-google-recaptcha";
import CollegeModal from "../modals/CollegeModal";
import UniversalButton from "../common/StandardButton/UniversalButton";
import { useLocation, useNavigate } from "react-router-dom";

// let local_token = "";

const SignUp = ({ setLoading }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const { isSignupLoading, isSignedUp } = useSelector((state) => state.auth);

  const form = useRef();
  form.current = null;

  const [formdata, setFormdata] = useState({
    signup_type: "L",
    name: "",
    mobile: "",
    gender: "",
    email: "",
    password: "",
    college: "",
    token: "",
  });

  const [allData, setAllData] = useState({
    temp_college_data: null,
    temp_gender_data: null,
    showSignupModal: false,
    showGithubModal: false,
    // showAddCollegeModal: false,
    // isNewCollegeAddLoading: false,
    showPassword: false,
    githubUserData: null,
    githubUserEmail: "",
    // n_college_name: "",
    emailError: "",
    genderError: "",
    mobileError: "",
    passwordError: "",
    collegeError: "",
    userCredential: "",
    aicts_detail: null,
    aictsPlanId: "",
    loading: false,
  });

  useEffect(() => {
    //Ensuring google signup button gets loaded properly everytime
    if (window.google) {
      // All is good! Carry on
      googleButtonInitialize();
    }
    const googleScript = document.getElementById("googleScript");
    googleScript.addEventListener("load", () => {
      // Patiently waiting to do the thing
      googleButtonInitialize();
    });

    //For Dialog box during Github signup/login
    const githubCode = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).code;
    if (githubCode) {
      //Make an API call to backend to check whether this user already exists in database or not. If they do, redirect to login
      //else show Github dialog box for additional details page

      checkUser(githubCode)
        .then((res) => {
          if (res.checkUser.data.userExists) {
            history(`/login`);
            toast.warning("User Already Exists");
          } else {
            setAllData((prev) => {
              return {
                ...prev,
                showGithubModal: true,
                githubUserData: JSON.parse(res.userData.data.text),
                githubUserEmail: JSON.parse(res.userEmails.data.text)[0].email, //extracting primary email
              };
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // fetchTempToken();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const aicts_plan_id = params.get("aicts_plan");
    if (aicts_plan_id) {
      dispatch(logout());
      setAllData((prev) => {
        return {
          ...prev,
          aictsPlanId: aicts_plan_id,
        };
      });
      fetchAictsPlanDetail(aicts_plan_id);
    }

    return () => googleScript.removeEventListener("load");
  }, []);

  useEffect(() => {
    if (isSignedUp && !isSignupLoading) {
      history("/login");
    }
  }, [isSignedUp, isSignupLoading]); // dependencies of the effect

  const googleButtonInitialize = () => {
    window.google?.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCallbackResponse,
      context: "signup",
    });

    window.google?.accounts.id.renderButton(
      document.getElementById("signInGoogle"),
      {
        theme: "outline",
        size: "large",
        text: "signup_with",
        shape: "circle",
      }
    );
  };

  //callback used for google signup/signin button
  const handleCallbackResponse = (res) => {
    const encoded_credential = res.credential;

    //Verify the JWT Token we recieved by sending to backend
    // If its fine then redirect to form for additional details
    axios
      .post(
        `${BASE_URL}api/user/googleverify`,
        {},
        {
          //Send entire google JWT Token in headers and authenticate via googleauth middleware in backend.
          headers: {
            "x-google-token": encoded_credential,
          },
        }
      )
      .then((res) => {
        if (res.data.userExists) {
          history(`/login`);
          toast.warning("User already registered ", defaultToastSetting);
        } else {
          const auth = res.data;
          // setLoading(true);
          // setTimeout(() => {
          //   setLoading(false);
          //   history("/signup/details", {
          //     showSignupModal: true,
          //     userCredential: encoded_credential,
          //   });
          // }, 2000);
          // setAllData((prev) => {
          //   return {
          //     ...prev,
          //     showSignupModal: true,
          //     userCredential: encoded_credential,
          //   };
          // });
          localStorage.setItem("priksha_name", auth.name);
          localStorage.setItem("priksha_token", auth.token);
          localStorage.setItem("priksha_type", auth.type);
          localStorage.setItem("priksha_email", auth.email);

          dispatch(loginSuccess(auth));

          toast.success("Signup successful", defaultToastSetting);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    let new_form_data = { ...formdata };
    new_form_data[name] = value;
    setFormdata({ ...new_form_data });
    setAllData((prev) => {
      return { ...prev, [`${name}Error`]: "" };
    });
  };

  const handleCollegeChange = (e) => {
    let new_form_data = { ...formdata };
    new_form_data["college"] = e.college_id;
    setFormdata({ ...new_form_data });
    setAllData((prev) => {
      return { ...prev, collegeError: "", temp_college_data: e };
    });
  };

  const handleGenderChange = (e) => {
    let new_form_data = { ...formdata };
    new_form_data["gender"] = e.value;
    setFormdata({ ...new_form_data });
    setAllData((prev) => {
      return { ...prev, genderError: "", temp_gender_data: e };
    });
  };

  const validate = () => {
    let nameError = "";
    let emailError = "";
    let mobileError = "";
    let passwordError = "";
    let genderError = "";
    let collegeError = "";

    if (!formdata.name) {
      nameError = "Name cannot be blank.";
    }

    if (!formdata.gender) {
      genderError = "Please select the gender.";
    }
    if (!formdata.college) {
      collegeError = "Please select your college";
    }

    if (formdata.password.length < 6) {
      passwordError = "Password cannot be less than 6 Characters.";
    }
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (re.test(formdata.mobile) === false || formdata.mobile.length !== 10) {
      mobileError = "Invalid Mobile Number";
    }

    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formdata.email) ===
      false
    ) {
      emailError = "Invalid Email";
    }
    if (
      emailError ||
      nameError ||
      passwordError ||
      mobileError ||
      genderError ||
      collegeError ||
      !formdata.token
    ) {
      setAllData((prev) => {
        return {
          ...prev,
          emailError,
          nameError,
          mobileError,
          passwordError,
          genderError,
          collegeError,
        };
      });
      return false;
    }

    return true;
  };

  const handleNormalSignUp = () => {
    const isValid = validate();
    let temp_formdata = formdata;
    if (isValid) {
      if (allData.aictsPlanId)
        temp_formdata.plan_id = allData.aicts_detail?.["_id"];
      dispatch(signupRequested(temp_formdata));
    }
  };

  const fetchAictsPlanDetail = async (id) => {
    await axios
      .get(`${BASE_URL}api/plan/${id}`)
      .then((response) => {
        setAllData((prev) => {
          return {
            ...prev,
            aicts_detail: response.data,
          };
        });
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  // const fetchTempToken = async () => {
  //   await axios
  //     .post(`${BASE_URL}api/auth/temp`)
  //     .then((response) => {
  //       local_token = response?.data.token;
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data, {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //     });
  // };

  // const handleNewCollegeSave = async () => {
  //   setAllData((prev) => {
  //     return { ...prev, isNewCollegeAddLoading: true };
  //   });
  //   await axios
  //     .post(
  //       `${BASE_URL}api/college`,
  //       { name: allData.n_college_name },
  //       {
  //         headers: {
  //           "x-auth-token": local_token,
  //           "x-referer-sec-bool":
  //             "ZW50QHN0dWRlbnQuY29tIiwiX2lkIjoiNjI1ZWRhNmZkNTJmOTgwZjhmMGM2OTZjIiwidHlwZSI6IlMiLCJpYXQiOjE2NjI2MTYyMTcsImV4cCI6MTY2MjY1OTQxNywiYXVkIjoiaHR0cHM6Ly9hcHAua3lyb24uaW4vIiwiaXNzIjoiS1lST04i",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       setAllData((prev) => {
  //         return {
  //           ...prev,
  //           isNewCollegeAddLoading: false,
  //           showAddCollegeModal: false,
  //           n_college_name: "",
  //         };
  //       });
  //       toast.success("Your college successfully added", {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //     })
  //     .catch((err) => {
  //       setAllData((prev) => {
  //         return {
  //           ...prev,
  //           isNewCollegeAddLoading: false,
  //           showAddCollegeModal: false,
  //         };
  //       });
  //       toast.error(err.response.data, {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //     });
  // };

  const handleShowPassword = () => {
    setAllData((prev) => {
      return {
        ...prev,
        showPassword: !prev.showPassword,
      };
    });
  };

  const onChange = (value) => {
    let new_form_data = { ...formdata };
    new_form_data["token"] = value;

    setFormdata({ ...new_form_data });
    // if (value)
    //   axios
    //     .post(`${BASE_URL}api/auth/verifyrecaptcha`, {
    //       token: value,
    //     })
    //     .then((res) => {
    //       console.log(res.data.success);
    //       this.setState((prevState) => {
    //         return {
    //           ...prevState,
    //           isGoogleCaptchaVerified: res.data.success,
    //         };
    //       });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
  };

  // const promiseOptions = async (inputValue) => {
  //   return await axios
  //     .get(`${BASE_URL}api/college?name=${inputValue}`)
  //     .then((response) => {
  //       const arrayMap = response.data.slice(0, 20).map((data) => {
  //         return { value: data.name, label: data.name, college_id: data._id };
  //       });
  //       return arrayMap;
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data, {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //     });
  // };

  // const debounce = (f, interval) => {
  //   let timer = null;

  //   return (...args) => {
  //     clearTimeout(timer);
  //     return new Promise((resolve) => {
  //       timer = setTimeout(() => resolve(f(...args)), interval);
  //     });
  //   };
  // };

  return (
    <div className="relative">
      {/* Add college modal */}
      {/* <Modal
        onClose={() =>
          setAllData((prev) => {
            return { ...prev, showAddCollegeModal: false };
          })
        }
        isModal={allData.showAddCollegeModal}
        showCloseButton
        className={"w-[90%] md:w-[40%]"}
      >
        <h2 className="text-xl font-['poppins'] font-medium pb-4 ">
          Add your college
        </h2>
        <div className="mb-10">
          <label className="font-['poppins'] text-neutral-400 ">
            Your college name<span>*</span>
          </label>
          <br />
          <small>Make sure your college is not available in our list</small>
          <input
            name="college_name"
            placeholder="Enter your text here"
            className="login-form-emailInput uppercase"
            value={allData.n_college_name}
            onChange={(e) =>
              setAllData((prev) => {
                return { ...prev, n_college_name: e.target.value };
              })
            }
            oninput="this.value = this.value.toUpperCase()"
          />
        </div>

        <button
          onClick={() => handleNewCollegeSave()}
          className="text-white w-[150px] transition bg-[#009999]  hover:bg-gray-800 hover:text-white font-medium rounded-full  
          text-md px-3 py-1 flex justify-center mr-2 my-2 cursor-pointer font-['nunito'] ml-auto"
          type="submit"
        >
          {allData.isNewCollegeAddLoading ? <ButtonSpinner /> : "Add"}
        </button>
      </Modal> */}

      {/* Google Signup Modal */}
      {/* <Modal
          onClose={() =>
            this.setState({
              showSignupModal: false,
            })
          }
          isModal={this.state.showSignupModal}
          showCloseButton
          className={"w-[90%] md:w-[40%]"}
        >
          
        </Modal> */}
      {/* Github Signup Modal */}
      {allData.showGithubModal ? (
        <div
          className={`overflow-y-auto overflow-x-hidden  fixed flex flex-col z-50 justify-center 
            items-center h-full w-full top-0 left-0 `}
          id="popup-modal"
          style={{ backgroundColor: "rgb(128 ,128 ,128,0.7)" }}
        >
          <div className="opacity-90 w-[50vw] bg-white rounded-md">
            <GithubSignup
              githubUserData={allData.githubUserData}
              githubUserEmail={allData.githubUserEmail}
            />
          </div>
        </div>
      ) : null}
      <div className="login-input-div  md:px-10 md:py-4 my-2">
        {/* {!allData.aictsPlanId && (
          <div className="text-xs mb-12 text-right">
            Already have an account ?
            <span
              className="hover:text-kPrimaryDark hover:underline font-medium cursor-pointer"
              onClick={() => history("/login")}
            >
              Sign in
            </span>
          </div>
        )} */}
        {allData.aictsPlanId ? (
          <p className="text-lg font-['poppins']  pb-7">
            Subscribe for
            <span className="font-semibold">
              {allData.aicts_detail?.["title"]}
            </span>
            <br />
            Commencing Dates:
            <span className="font-semibold">
              {allData.aicts_detail?.["description"]}
            </span>
            <br />
            Amount: Rs.
            <span className="font-semibold">
              {allData.aicts_detail?.["amount"]}
            </span>
            /- only
          </p>
        ) : (
          <p className="text-[1.8rem] font-['Poppins'] font-bold pb-2">
            Sign up
          </p>
        )}

        {/* <div className="flex flex-col items-center justify-center">
          {allData.aictsPlanId ? (
            <></>
          ) : (
            <div
              id="signInGoogle"
              className="rounded-full flex gap-3 items-center justify-center py-2 font-['nunito'] text-[#333333] mb-2 "
            ></div>
          )}

          <div
            onClick={this.getToken}
            className="border-2 w-[240px] text-sm rounded-full flex gap-3 items-center justify-center py-2 font-['nunito'] text-[#333333] mb-4 cursor-pointer"
          >
            <img src={githubIcon} height="20px" />
            <a
              href={`https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GITHUB_REDIRECT_URL}signup&scope=read:user,user:email`}
            >
              Sign up with Github
            </a>
          </div>
        </div> */}

        {/* {allData.aictsPlanId ? (
          <></>
        ) : (
          <div className="relative flex py-2 items-center">
            <div className="flex-grow border-t border-gray-400"></div>
            <i className="flex-shrink mx-4 text-gray-400">OR</i>
            <div className="flex-grow border-t border-gray-400"></div>
          </div>

        )} */}

        {/* <form> */}
        <div className="mb-2">
          {/* <label className="font-['poppins'] text-neutral-400">Name</label> */}
          <input
            name="name"
            placeholder="Enter your name here"
            className="login-form-emailInput inputPlaceholder"
            value={formdata.name}
            onChange={(e) => handleChange(e)}
          />
          <div className="text-red-500 text-sm font-['poppins']">
            {allData.nameError}
          </div>
        </div>
        <div className="mb-2">
          {/* <label className="font-['poppins'] text-neutral-400">Email</label> */}
          <input
            name="email"
            placeholder="Enter your email here"
            className="login-form-emailInput inputPlaceholder"
            value={formdata.email}
            onChange={(e) => handleChange(e)}
          />
          <div className="text-red-500 text-sm font-['poppins']">
            {allData.emailError}
          </div>
        </div>
        <div className="mb-2">
          {/* <label className="font-['poppins'] text-neutral-400">
              Mobile Number
            </label> */}
          <input
            type="text"
            name="mobile"
            placeholder="Enter your mobile here"
            maxLength={10}
            className="login-form-passInput inputPlaceholder"
            value={formdata.mobile}
            onChange={(e) => handleChange(e)}
          />
          <div className="text-red-500 text-sm font-['poppins']">
            {allData.mobileError}
          </div>
        </div>
        <div className="mb-2">
          {/* <label className="font-['poppins'] text-neutral-400">Gender</label> */}
          <Select
            className="login-form-selectInput"
            value={allData.temp_gender_data}
            onChange={(e) => handleGenderChange(e)}
            placeholder="Select your gender"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: "none",
                background: "#eefecf",
                color: "#87cf00",
              }),
              singleValue: (baseStyles) => ({
                ...baseStyles,
                color: "#87cf00",
              }),
              placeholder: (baseStyles) => ({
                ...baseStyles,
                color: "rgba(135, 207, 0, 0.7)",
              }),
              input: (baseStyles) => ({
                ...baseStyles,
                color: "#87cf00",
              }),
            }}
            options={[
              { value: "M", label: "Male" },
              { value: "F", label: "Female" },
              { value: "T", label: "Transgender" },
              { value: "NB", label: "Non Binary" },
              { value: "NC", label: "Non Confirming" },
              { value: "O", label: "Others" },
            ]}
          />
          <div className="text-red-500 text-sm font-['poppins']">
            {allData.genderError}
          </div>
        </div>
        <div className="mb-2">
          {/* <label className="font-['poppins'] text-neutral-400">College</label> */}
          {/* <AsyncSelect
              menuPlacement="top"
              loadOptions={debounce(promiseOptions, 500)}
              value={allData.temp_college_data}
              onChange={(e) => handleCollegeChange(e)}
              className="login-form-selectInput"
              placeholder="Select your college"
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  border: "none",
                  background: "#eefecf",
                  color: "#87cf00",
                }),
                singleValue: (baseStyles) => ({
                  ...baseStyles,
                  color: "#87cf00",
                }),
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  color: "rgba(135, 207, 0, 0.7)",
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  color: "#87cf00",
                }),
              }}
              noOptionsMessage={({ inputValue }) =>
                !inputValue ? (
                  "Please enter keyword to search your college"
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setAllData((prev) => {
                        return { ...prev, showAddCollegeModal: true };
                      });
                    }}
                  >
                    No results found!
                    <span className="login-form-selectInput-button">
                      Click here
                    </span>
                    to add your college
                  </button>
                )
              }
            /> */}
          <CollegeModal
            handleCollegeChange={handleCollegeChange}
            collegeValue={allData.temp_college_data}
          />

          <div className="text-red-500 text-sm font-['poppins']">
            {allData.collegeError}
          </div>
        </div>

        <div className="mb-8 ">
          {/* <label className="font-['poppins'] text-neutral-400">
              Password
            </label> */}
          <div className="grid items-center relative">
            <input
              type={`${allData.showPassword ? "text" : "password"}`}
              name="password"
              placeholder="Enter your password here"
              className="login-form-passInput"
              value={formdata.password}
              onChange={(e) => handleChange(e)}
            />
            {allData.showPassword ? (
              <EyeCrossIcon
                onClick={() => handleShowPassword()}
                title="View"
                alt="view"
                className="absolute w-5 h-5 right-0  mr-4 cursor-pointer"
              />
            ) : (
              <EyeIcon
                fill="#666666"
                fillOpacity="0.8"
                onClick={() => handleShowPassword()}
                title="View"
                alt="view"
                className="absolute w-5 h-5 right-0  mr-4 cursor-pointer"
              />
            )}
          </div>
          <div className="text-red-500 text-sm font-['poppins']">
            {allData.passwordError}
          </div>
        </div>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
          onChange={(e) => onChange(e)}
        />

        {formdata.token && (
          // <button
          //   className="w-[100%] mt-3 text-[#5B8B00] transition bg-[#BCFB46] hover:bg-gray-800 hover:text-white font-medium rounded-[0.3rem] text-[1rem] px-6 py-3 flex justify-center mr-2 mb-2 cursor-pointer font-['Poppins']"
          //   style={{ boxShadow: "0px 0px 80px 0px rgba(188, 251, 70, 0.56)" }}
          //   // onClick={() => handleNormalSignUp()}
          // >
          //   {isSignupLoading ? <ButtonSpinner /> : "Sign up"}
          // </button>
          <UniversalButton
            onClickFunction={() => handleNormalSignUp()}
            title="Sign up"
            loading={isSignupLoading}
            classNames="w-full mt-[1.2rem]"
          />
        )}
        {/* </form> */}
        {allData.aictsPlanId ? (
          <></>
        ) : (
          <div className="relative flex py-2 items-center justify-center">
            {/* <div className="flex-grow border-t border-gray-400"></div> */}
            <h1 className="flex-shrink mx-4 text-[1rem] mt-[1rem] font-['Poppins'] text-[#B5B5B5] font-medium">
              or continue with
            </h1>
            {/* <div className="flex-grow border-t border-gray-400"></div> */}
          </div>
        )}

        <div className="flex flex-col items-center justify-center">
          {allData.aictsPlanId ? (
            <></>
          ) : (
            <div
              id="signInGoogle"
              className="rounded-full flex gap-3 items-center justify-center py-2 font-['nunito'] text-[#333333] mb-2 "
            ></div>
          )}

          {/* <div
              onClick={this.getToken}
              className="border-2 w-[240px] text-sm rounded-full flex gap-3 items-center justify-center py-2 font-['nunito'] text-[#333333] mb-4 cursor-pointer"
            >
              <img src={githubIcon} height="20px" />
              <a
                href={`https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GITHUB_REDIRECT_URL}signup&scope=read:user,user:email`}
              >
                Sign up with Github
              </a>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
