export const FETCH_PROCTORED_USERS_REQUESTED =
  "FETCH_PROCTORED_USERS_REQUESTED";

export const FETCH_PROCTORED_USERS_SUCCESS = "FETCH_PROCTORED_USERS_SUCCESS";

export const FETCH_PROCTORED_USERS_FAILURE = "FETCH_PROCTORED_USERS_FAILURE";

export const FETCH_ATTENDING_USERS_REQUESTED =
  "FETCH_ATTENDING_USERS_REQUESTED";

export const FETCH_ATTENDING_USERS_SUCCESS = "FETCH_ATTENDING_USERS_SUCCESS";

export const FETCH_ATTENDING_USERS_FAILURE = "FETCH_ATTENDING_USERS_FAILURE";

export const FETCH_PROCTORED_USERS_ANY_PROCTOR_REQUESTED =
  "FETCH_PROCTORED_USERS_ANY_PROCTOR_REQUESTED";

export const FETCH_PROCTORED_USERS_ANY_PROCTOR_FAILURE =
  "FETCH_PROCTORED_USERS_ANY_PROCTOR_FAILURE";

export const FETCH_PROCTORED_USERS_ANY_PROCTOR_SUCCESS =
  "FETCH_PROCTORED_USERS_ANY_PROCTOR_SUCCESS";
