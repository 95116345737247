import {
  SET_CODE_EDITOR_LANGUAGE,
  SET_CODE_EDITOR_CODE,
  SET_CODE_EDITOR_RESULTS,
  SET_CODE_EDITOR_INPUT,
  CODE_EDITOR_RUN_REQUESTED,
  CODE_EDITOR_RUN_SUCCESS,
  CODE_EDITOR_RUN_FAILURE,
  CODE_EDITOR_SUBMIT_REQUESTED,
  CODE_EDITOR_SUBMIT_SUCCESS,
  CODE_EDITOR_SUBMIT_FAILURE,
  UPDATE_CODE_RESPONSE,
  UPDATE_EDITOR_STATE,
  CODE_EDITOR_RUN_STATUS_REQUESTED,
  CODE_EDITOR_RUN_STATUS_SUCCESS,
  CODE_EDITOR_RUN_STATUS_FAILURE,
  CODE_EDITOR_RUN_CLEAR,
  COPY_INSIDE_CODE_EDITOR,
} from "../actionTypes";

export const updateCodeEditorLanguage = (data) => {
  return {
    type: SET_CODE_EDITOR_LANGUAGE,
    payload: data,
  };
};

export const updateEditorState = (data) => {
  return {
    type: UPDATE_EDITOR_STATE,
    payload: data,
  };
};

export const updateCodeEditorCode = () => {
  return {
    type: SET_CODE_EDITOR_CODE,
  };
};

export const updateCodeEditor = (data) => {
  return {
    type: UPDATE_CODE_RESPONSE,
    payload: data,
  };
};

export const updateCodeEditorResults = (data) => {
  return {
    type: SET_CODE_EDITOR_RESULTS,
    payload: data,
  };
};

export const updateCodeEditorInput = (data) => {
  return {
    type: SET_CODE_EDITOR_INPUT,
    payload: data,
  };
};

export const runCodeRequest = (data) => {
  return {
    type: CODE_EDITOR_RUN_REQUESTED,
    payload: data,
  };
};

export const runCodeSuccess = (data) => {
  return {
    type: CODE_EDITOR_RUN_SUCCESS,
    payload: data,
  };
};

export const runCodeFailure = (data) => {
  return {
    type: CODE_EDITOR_RUN_FAILURE,
    payload: data,
  };
};

export const submitCodeRequest = (data) => {
  return {
    type: CODE_EDITOR_SUBMIT_REQUESTED,
    payload: data,
  };
};

export const submitCodeSuccess = (data) => {
  return {
    type: CODE_EDITOR_SUBMIT_SUCCESS,
    payload: data,
  };
};

export const submitCodeFailure = (data) => {
  return {
    type: CODE_EDITOR_SUBMIT_FAILURE,
    payload: data,
  };
};

export const fetchCodeRunStatusRequest = (data) => {
  return {
    type: CODE_EDITOR_RUN_STATUS_REQUESTED,
    payload: data,
  };
};

export const fetchCodeRunStatusSuccess = (data) => {
  return {
    type: CODE_EDITOR_RUN_STATUS_SUCCESS,
    payload: data,
  };
};

export const fetchCodeRunStatusFailure = (data) => {
  return {
    type: CODE_EDITOR_RUN_STATUS_FAILURE,
    payload: data,
  };
};

export const clearCodeRunStatus = () => {
  return {
    type: CODE_EDITOR_RUN_CLEAR,
  };
};

export const copyInsideCodeEditor = (data) => {
  return {
    type: COPY_INSIDE_CODE_EDITOR,
    payload: data,
  };
};
