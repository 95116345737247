import { TemplateCode } from "../../components/tests/codeEditor/Utils/Constants";

import {
  SET_CODE_EDITOR_LANGUAGE,
  SET_CODE_EDITOR_CODE,
  SET_CODE_EDITOR_RESULTS,
  SET_CODE_EDITOR_INPUT,
  CODE_EDITOR_RUN_REQUESTED,
  CODE_EDITOR_RUN_SUCCESS,
  CODE_EDITOR_RUN_CLEAR,
  CODE_EDITOR_SUBMIT_REQUESTED,
  CODE_EDITOR_SUBMIT_SUCCESS,
  UPDATE_EDITOR_STATE,
  CODE_EDITOR_RUN_STATUS_REQUESTED,
  CODE_EDITOR_RUN_STATUS_SUCCESS,
  COPY_INSIDE_CODE_EDITOR,
  CODE_EDITOR_RUN_FAILURE,
  CODE_EDITOR_SUBMIT_FAILURE,
} from "../actionTypes";

const initialState = {
  language: null,
  code: localStorage.getItem("midas-code") || TemplateCode,
  stdin: "",
  results: null,
  editorState: false,
  is_copy_inside_editor: false,
  run_token: null,
};

export default function CodeEditor(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case SET_CODE_EDITOR_LANGUAGE:
      return {
        ...state,
        language: action.payload,
        editorState: false,
      };

    case UPDATE_EDITOR_STATE:
      return {
        ...state,
        editorState: action.payload,
      };

    case SET_CODE_EDITOR_CODE:
      return {
        ...state,
        code: action.payload,
      };

    case SET_CODE_EDITOR_RESULTS:
      return {
        ...state,
        results: action.payload,
      };

    case SET_CODE_EDITOR_INPUT:
      return {
        ...state,
        stdin: action.payload,
      };

    case CODE_EDITOR_RUN_REQUESTED:
      return {
        ...state,
        run_token: null,
        results: null,
      };

    case CODE_EDITOR_RUN_SUCCESS:
      return {
        ...state,
        run_token: action.payload.token,
        results: null,
      };

    case CODE_EDITOR_RUN_FAILURE:
      return {
        ...state,
        editorState:false,
      };

    case CODE_EDITOR_RUN_CLEAR:
      return {
        ...state,
        run_token: null,
        results: null,
      };

    case CODE_EDITOR_SUBMIT_REQUESTED:
      return {
        ...state,
        results: null,
      };

    case CODE_EDITOR_SUBMIT_SUCCESS:
      return {
        ...state,
        results: action.payload.judge_response,
      };

      case CODE_EDITOR_SUBMIT_FAILURE:
      return {
        ...state,
        editorState:false,
      };


    case CODE_EDITOR_RUN_STATUS_REQUESTED:
      return {
        ...state,
        results: null,
      };

    case CODE_EDITOR_RUN_STATUS_SUCCESS:
      return {
        ...state,
        results: action.payload.judge_response,
      };

    case COPY_INSIDE_CODE_EDITOR:
      return {
        ...state,
        is_copy_inside_editor: action.payload,
      };
    default:
      return state;
  }
}
