export const FETCH_ALL_EVALUATORS_REPORTS_REQUESTED =
  "FETCH_ALL_EVALUATORS_REPORTS_REQUESTED";

export const FETCH_ALL_EVALUATORS_REPORTS_SUCCESS =
  "FETCH_ALL_EVALUATORS_REPORTS_SUCCESS";

export const FETCH_ALL_EVALUATORS_REPORTS_FAILURE =
  "FETCH_ALL_EVALUATORS_REPORTS_FAILURE";

export const FETCH_REPORT_REQUESTED = "FETCH_REPORT_REQUESTED";

export const FETCH_REPORT_SUCCESS = "FETCH_REPORT_SUCCESS";

export const FETCH_REPORT_FAILURE = "FETCH_REPORT_FAILURE";

export const UPDATE_SCORE_REQUESTED = "UPDATE_SCORE_REQUESTED";

export const UPDATE_SCORE_SUCCESS = "UPDATE_SCORE_SUCCESS";

export const UPDATE_SCORE_FAILURE = "UPDATE_SCORE_FAILURE";

export const SEND_REMINDER_EVALUATOR_REQUEST =
  "SEND_REMINDER_EVALUATOR_REQUEST";
