import React, { Component } from "react";
import { connect } from "react-redux";
import warning from "../../../assets/img/svg/warning.png";
import UniversalButton from "../../common/StandardButton/UniversalButton";
import { withRouter } from "../../common/WithRouter";

class AlertModal extends Component {
  render() {
    return (
      <div
        className={`overflow-y-auto overflow-x-hidden  fixed flex z-50 justify-center 
        items-center h-full w-full top-0 left-0`}
        id="popup-modal"
        style={{ backgroundColor: "rgb(128 ,128 ,128,0.5)" }}
      >
        <div
          className="flex-col"
          style={{
            flexShrink: "1",
          }}
        >
          <div className="relative w-full top-0 left-0 max-w-md h-screen md:h-auto">
            <div className="flex relative bg-[#BCFB464A] shadow-sm shadow-slate-200  rounded-xl">
              <div className="flex-col p-5 text-center justify-center items-center">
                <div className="flex items-center justify-center">
                  <img
                    src={warning}
                    className="w-16 h-16"
                    alt="Warning"
                    title="warning"
                  />
                </div>
                <div className="p-2 bg-white shadow-sm shadow-rose-200 rounded-lg m-2 my-4">
                  <h3
                    className="mb-2 text-lg font-normal text-slate-700
                   "
                  >
                    Your video streaming has stopped and your responses are
                    saved.Please refresh your screen to start sharing again.
                  </h3>
                </div>
                <button
                  onClick={() => {
                    this.props.saveResponse();
                    window.location.reload();
                  }}
                  data-modal-toggle="popup-modal"
                  type="button"
                  className="w-[8rem] text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br font-semibold rounded-lg text-md py-1 mr-2 text-center"
                >
                  {!this.props.isVideo ? "Refresh" : "Refresh"}
                </button>
                <UniversalButton
                  title="Submit Test"
                  classNames="w-auto px-3 py-2"
                  onClickFunction={(e) => this.props.submitTest(e)}
                />
                {/* <button
                  onClick={(e) => this.props.submitTest(e)}
                  data-modal-toggle="popup-modal"
                  type="button"
                  className="text-white bg-gradient-to-r hover:bg-gradient-to-br from-green-400 via-green-500 to-green-600 
            rounded-lg  text-md font-semibold px-3  py-2"
                >
                  Submit Test
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    isVideo: test.isVideo,
    isAudio: test.isAudio,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AlertModal));
