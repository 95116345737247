/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { BASE_URL } from "../assets/js/config";
import authHeader from "./auth-header";

const register = (name, type, email, mobile, password) => {
  return axios.post(BASE_URL + "api/user", {
    name,
    type,
    mobile,
    email,
    password,
  });
};

export const forgotPassword = (data) => {
  return axios
    .post(BASE_URL + "api/user/forgotpassword", {
      data,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw Error(e.response.data);
    });
};

export const resetPassword = (data) => {
  return axios
    .post(BASE_URL + "api/user/resetpassword/" + data.resetToken, {
      data,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw Error(e.response.data);
    });
};

const login = (data) => {
  return axios.post(BASE_URL + "api/auth", data);
};

export const loginUser = (data) => {
  return axios
    .post(`${BASE_URL}api/auth`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

export const logoutUser = (data) => {
  return axios
    .post(`${BASE_URL}api/auth/logout`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

export const registerPublicUser = (data) => {
  return axios
    .post(`${BASE_URL}api/auth/public`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error.response.data);
    });
};

export default {
  register,
  login,
  logoutUser,
};
