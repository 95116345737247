import React from "react";

const SecondStep = ({ handleFile, handleChange, values }) => {
  return (
    <>
      <div className="flex flex-col space-y-4 p-2">
        <div className="mt-2">
          <div>
            <label className="bg-[#007f5f] rounded text-white py-2 px-3 hover:cursor-pointer">
              <input
                hidden
                type="file"
                accept="image/*"
                onChange={handleFile}
                name="photo_file"
                value={values.photo_file}
              ></input>
              Upload Photo
            </label>
          </div>
        </div>
        <div>
          <div className="">
            <span className="block my-2 text-neutral-650 font-[540]">
              How did you hear about us?
            </span>
            <select
              onChange={handleChange}
              name="hear_about_us"
              value={values.hear_about_us}
              className="border border-gray-300 outline-gray-200 p-2 w-full"
            >
              <option value="social_platform">social platform</option>
              <option value="google">google</option>
              <option value="other">other</option>
            </select>
          </div>
        </div>
        <div>
          <span className="block my-2 text-neutral-650 font-[540]">
            Have you been employed with XYZ before?
          </span>
          <div>
            <select
              onChange={handleChange}
              name="employed_before"
              value={values.employed_before}
              className="border border-gray-300 outline-gray-200 p-2 w-full"
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
        </div>
        <div>
          <div>
            <span className="block my-2 text-neutral-650 font-[540]">
              PWD ?
            </span>
            <select
              onChange={handleChange}
              name="is_pwd"
              value={values.is_pwd}
              className="border border-gray-300 outline-gray-200 p-2 w-full"
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
        </div>
        <div>
          <div>
            <span className="block my-2 text-neutral-650 font-[540] ">
              Gender
            </span>
            <div className="flex items-center">
              <label className="text-neutral-650 font-[500] mx-2"> Male </label>
              <input
                className="form-radio h-5 w-5 text-gray-600"
                checked={values.gender === "M" && true}
                onChange={handleChange}
                name="gender"
                type="checkbox"
                value="M"
              />
              <label className="text-neutral-650 font-[500] mr-2 ml-6">
                Female
              </label>
              <input
                className="form-radio h-5 w-5 text-gray-600 "
                checked={values.gender === "F" && true}
                onChange={handleChange}
                name="gender"
                type="checkbox"
                value="F"
              />

              <label className="text-neutral-650 font-[500] mr-2 ml-6">
                Other
              </label>
              <input
                className="form-radio h-5 w-5 text-gray-600"
                checked={values.gender === "O" && true}
                onChange={handleChange}
                name="gender"
                type="checkbox"
                value="O"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="">
            <label className="block">
              <span className="block my-2 text-neutral-650 font-[540]">
                Legal Age
              </span>
              <input
                onChange={handleChange}
                name="legal_age"
                value={values.legal_age}
                type="number"
                className="border border-gray-300 outline-gray-200 p-2 w-full"
              />
            </label>
          </div>
          <div className="">
            <label className="block">
              <span className="block my-2 text-neutral-650 font-[540]">
                Linkedin URL
              </span>
              <input
                onChange={handleChange}
                name="linkedin_url"
                value={values.linkedin_url}
                type="text"
                className="border border-gray-300 outline-gray-200 p-2 w-full"
              />
            </label>
          </div>
        </div>
        <div>
          <div className="">
            <label className="block">
              <span className="block my-2 text-neutral-650 font-[540]">
                Background Check Consent
              </span>
            </label>
            <textarea
              onChange={handleChange}
              name="consent"
              value={values.consent}
              className="border border-gray-300 outline-gray-200 p-2 w-full"
              cols="30"
              rows="3"
            ></textarea>
          </div>
        </div>
        <div>
          <label className="block">
            <span className="block my-2 text-neutral-650 font-[540]">
              Are you able to work on a daily basis in the work location listed
              for this position? If not, are you willing to relocate at your own
              expense to accept a position with XXX?
            </span>
          </label>
          <div>
            <textarea
              onChange={handleChange}
              name="daily_basis"
              value={values.daily_basis}
              className="border border-gray-300 outline-gray-200 p-2 w-full"
              cols="30"
              rows="4"
            ></textarea>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondStep;
