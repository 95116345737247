import React, { useEffect, useState, memo } from "react";
import Countdown from "react-countdown";

const CountDownAnswer = memo(({ totalTime, isCompleted }) => {

  const [timerColor, setTimerColor] = useState("green");
  const [timer, setTimer] = useState(totalTime);

  const countDownAnserRenderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      isCompleted();
      return <div className="text-md">Recording Started</div>;
    } else {
      // Change color of timer

      const _totalTime = Number(totalTime) / 1000;
      const remainingTime = minutes * 60 + seconds;
      // console.log(remainingTime, _totalTime);
      //   console.log(timer);
      if (
        _totalTime &&
        remainingTime > _totalTime / 3 &&
        remainingTime < (_totalTime * 2) / 3 &&
        timerColor !== "yellow"
      ) {
        setTimerColor("yellow");
        setTimer(remainingTime * 1000);
      } else if (
        _totalTime &&
        remainingTime < _totalTime / 3 &&
        remainingTime > 0 &&
        timerColor !== "red"
      ) {
        setTimerColor("red");
        setTimer(remainingTime * 1000);
      }

      return (
        <span>
          {("0" + minutes).slice(-2)}M {("0" + seconds).slice(-2)}S
        </span>
      );
    }
  };
  console.log("render")
  return (
    <div
      className={`sm:p-1 px-[3px] sm:text-[1em] md:text-[1.1em] lg:text-[1.2em]  shadow-lg 
      font-bold border rounded bg-${timerColor}-50 text-${timerColor}-900
      shadow-${timerColor}-100 border-${timerColor}-700 `}
    >
      <Countdown date={Date.now() + timer} renderer={countDownAnserRenderer} />
    </div>
  )
});

export default CountDownAnswer;
