import React, { Component } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ReactQuill from "react-quill";
import TestHeader from "./TestHeader";
import { toast } from "react-toastify";

// Store
import { connect } from "react-redux";
import { updateTestMainDataWithKey } from "../../../store/actions/test";
import CourseService from "../../../services/course.service";
import { modules } from "../utils/QuillModules";
import { findDuration, findDurationInMins } from "../utils/TimeFormat";
import { defaultToastSetting } from "../../../assets/js/toast_config";
import { ConvertTimeToCurrentTimezone } from "../../common/utils";

class TestDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleEnabled: false,
      temp_course: null,
      coursesList: [],
      runOnce: true,
    };
  }

  componentDidMount() {
    if (this.props.isKAInstructor && this.state.runOnce) {
      CourseService.getAllTACourses()
        .then((response) => {
          const arrayMap = response.data.data.data.map((data) => {
            return { title: data.title, _id: data._id };
          });
          this.setState({
            coursesList: arrayMap,
          });
        })
        .catch((err) => {
          toast.warning(err?.response?.data, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .finally((e) =>
          this.setState({
            runOnce: false,
          })
        );
    }
  }

  endTimeOnchangefn = (e) => {
    // console.log(e?._d?.getTime(), Date.parse(this.props.test.start_duration))
    if (!this.props.test.start_duration) toast.warning("Please select start time first", defaultToastSetting)
    else if (Date.parse(this.props.test.start_duration) > e?._d?.getTime()) toast.warning("End time must be greater than start time", defaultToastSetting)
    else this.props.updateTestMainDataWithKey({
      key: "end_duration",
      value: e?._d?.getTime(),
    })
  }


  render() {
    const { handleDurationStart, handleDurationEnd } = this.props;
    // console.log(this.props.isKAInstructor);
    return (
      <div>
        <div className="flex justify-between mt-4">
          <TestHeader title="Test Details" />
          <div className="flex float-right py-2">
            <label className="inline-flex relative items-center mr-5 cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={this.props.test.status}
                readOnly
              />
              <div
                onClick={() => {
                  this.props.updateTestMainDataWithKey({
                    key: "status",
                    value: !this.props.test.status,
                  });
                }}
                className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#a0db32]"
              ></div>
              <span className="ml-2 text-md  font-['poppins'] font-medium text-gray-900">
                {this.props.test.status ? "Active" : "InActive"}
              </span>
            </label>
          </div>
        </div>
        {/* <div class="flex justify-center">
          <div class="form-check form-switch">
            <input
              class="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked
              onChange={(e) => {
                this.props.updateTestMainDataWithKey({
                  key: "status",
                  value: !this.props.test.status,
                });
              }}
            />
            <label
              class="form-check-label inline-block text-gray-800"
              for="flexSwitchCheckChecked"
            >
              active
            </label>
          </div>
        </div> */}
        <div className="mb-4 mt-2">
          <h4 className="font-bold text-[1.1rem] font-['nunito']">
            Test Title<span className="text-red-500 mx-0.5">*</span>
          </h4>
          <input
            type="text"
            name="title"
            required
            value={this.props.test.title}
            onChange={(e) =>
              this.props.updateTestMainDataWithKey({
                key: "title",
                value: e.target.value,
              })
            }
            className="w-full border border-[#E0E0E0] outline-none p-2"
            placeholder="Test Title"
          />
        </div>
        <div className="md:mb-4 mb-16 h-[160px]">
          <h4 className="font-bold text-[1.1rem] font-['nunito']">
            Test Description
          </h4>
          <ReactQuill
            required
            modules={modules}
            value={this.props.test.description || ""}
            onChange={(value) =>
              this.props.updateTestMainDataWithKey({
                key: "description",
                value,
              })
            }
            className="h-[62%]"
            placeholder="Test Description"
          />
        </div>
        <div className="md:mb-4 mb-1">
          <h4 className="font-bold text-[1.1rem] font-['nunito']">
            Test Domain
          </h4>
          <input
            type="text"
            name="domain"
            required
            value={this.props?.test?.domain}
            onChange={(e) =>
              this.props.updateTestMainDataWithKey({
                key: "domain",
                value: e.target.value,
              })
            }
            className="w-full border border-gray-300 outline-gray-300 p-2"
            placeholder="Test Domain"
          />
        </div>
        <div className="flex gap-x-5">
          {this.props.isKAInstructor ? (
            <div className="mb-4">
              <h4 className="font-bold text-[1.1rem] font-['nunito'] mb-1">
                Select course
              </h4>
              <div className="relative">
                <select
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-2 px-3 pr-8 rounded leading-tight"
                  id="grid-state"
                  onChange={(e) =>
                    this.props.updateTestMainDataWithKey({
                      key: "course",
                      value: e.target.value,
                    })
                  }
                  value={this.props.test?.course?._id}
                >
                  <option>Select</option>
                  {this.state.coursesList.map((course, idx) => (
                    <option value={course._id} key={idx}>
                      {course.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : null}
          {!this.props.isKAInstructor ? (
            <div className="w-full flex md:gap-[3rem] gap-[1rem]">
              <div className="mb-4 flex-1">
                <h4 className="font-bold text-[1.1rem] font-['nunito'] mb-1">
                  Company Name<span className="text-red-500 mx-0.5">*</span>
                </h4>
                <div className="relative">
                  <select
                    className="block appearance-none w-full border outline-none border-[#E0E0E0] text-gray-700 py-2 px-3 pr-8 rounded leading-tight"
                    id="grid-state"
                    onChange={(e) =>
                      this.props.updateTestMainDataWithKey({
                        key: "company",
                        value: e.target.value,
                      })
                    }
                    value={this.props.test?.company?._id}
                  >
                    <option value="">Select</option>
                    {this.props.companies?.map((company, idx) => (
                      <option value={company._id} key={idx}>
                        {company.name}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="mb-4 flex-1">
                <h4 className="font-bold text-[1.1rem] font-['nunito'] mb-1">
                  Group Name
                </h4>
                <div className="relative">
                  <select
                    className="block appearance-none w-full border outline-none border-[#E0E0E0] text-gray-700 py-2 px-3 pr-8 rounded leading-tight"
                    id="grid-state"
                    onChange={(e) =>
                      this.props.updateTestMainDataWithKey({
                        key: "group",
                        value: e.target.value,
                      })
                    }
                    value={this.props.test.group}
                  >
                    <option>Select</option>
                    {this.props.groups.map((group, idx) => (
                      <option value={group._id} company={idx}>
                        {group.name}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="md:mt-4 mt-2">
          <div className="flex md:flex-row flex-col w-full md:gap-[3rem] gap-[0.2rem]">
            <div className="flex-1">
              <span className="mb-0">
                <h6 className="font-bold text-[1.1rem] font-['nunito']">
                  Start Date & Time<span className="text-red-500 mx-0.5">*</span>
                </h6>
              </span>
              <Datetime
                className="w-full"
                autoComplete="off"
                // name={`start_duration_${Math.random().toString(36).substring(7)}`}

                inputProps={{
                  className:
                    "border w-full outline-none border-[#E0E0E0] p-2 my-1",
                  placeholder: "Start Date & Time",
                  name: `start_duration_${Math.random().toString(36).substring(7)}`,
                }}
                // value={new Date(this.props.startDate)}
                value={new Date(this.props.startDate)}
                onChange={(e) =>
                  this.props.updateTestMainDataWithKey({
                    key: "start_duration",
                    value: e?._d?.getTime(),
                  })
                }
              />
            </div>
            <div className="flex-1">
              <span className=" mb-0">
                <h6 className="font-bold text-[1.1rem] font-['nunito']">
                  {this.props.test.allow_flexible_exam_time ? "Exam Duration(In minutes)" : "Exam Duration"}
                </h6>
              </span>
              {

                this.props.test.allow_flexible_exam_time ?

                  <input
                    className="border my-1 border-gray-300 block px-2.5 py-2.5 w-full text-sm  bg-transparent appearance-none focus:outline-none "
                    value={this.props.test.exam_duration
                    }
                    placeholder="Exam Duration"
                    onChange={(e) =>
                      this.props.updateTestMainDataWithKey({
                        key: "exam_duration",
                        value: e.target.value,
                      })}
                  /> :
                  <p className="py-2.5 my-1 border border-gray-300  px-2">{findDuration(this.props.startDate, this.props.endDate)}</p>
              }
              {/* <Datetime
                className="w-full"
                autoComplete="off"
                inputProps={{
                  className:
                    "border w-full outline-none border-[#E0E0E0] p-2 my-1",
                  placeholder: "End Date & Time",
                  name: `end_duration_${Math.random().toString(36).substring(7)}`,
                }}
                value={new Date(this.props.endDate)}
                onChange={(e) =>
                  this.props.updateTestMainDataWithKey({
                    key: "end_duration",
                    value: e?._d?.getTime(),
                  })
                }
              /> */}
            </div>
            <div className="flex-1">
              <span className=" mb-0">
                <h6 className="font-bold text-[1.1rem] font-['nunito']">
                  End Date & Time<span className="text-red-500 mx-0.5">*</span>
                </h6>
              </span>
              <Datetime
                className="w-full"
                autoComplete="off"
                inputProps={{
                  className:
                    "border w-full outline-none border-[#E0E0E0] p-2 my-1",
                  placeholder: "End Date & Time",
                  name: `end_duration_${Math.random().toString(36).substring(7)}`,
                }}
                value={new Date(this.props.endDate)}
                onChange={(e) => this.endTimeOnchangefn(e)
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    test: test.test,
    companies: test.companies,
    groups: test.allTestGroups,
    title: test.test.title,
    description: test.test.description,
    startDate: test.test.start_duration,
    endDate: test.test.end_duration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTestMainDataWithKey: (data) =>
      dispatch(updateTestMainDataWithKey(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TestDetails);
