import React from "react";
import load from "../../../assets/img/svg/load.svg";

import Modal from "../../common/Modal/Modal";

const ProctoringViewImageModal = ({
  isModalOpen,
  handleProctoringImageModal,
  isPhotoAndCard,
  studentPhotoImg,
  idCardImg,
}) => {
  return (
    <Modal
      onClose={() => handleProctoringImageModal(false)}
      isModal={isModalOpen}
      showCloseButton
      className={"w-[50vw]"}
    >
      <div className="flex justify-start gap-x-5">
        <div>
          <h5 className="text-2xl font-semibold">User</h5>
          {isPhotoAndCard && studentPhotoImg ? (
            <img
              className="border border-gray-300 my-3"
              style={{ width: "300px", height: "200px" }}
              title="Student photo"
              src={isPhotoAndCard ? studentPhotoImg : ""}
              alt="user"
            />
          ) : (
            <div
              style={{
                width: "200px",
                height: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="border border-gray-300 my-3"
            >
              <p> Loading </p>
              <img
                alt="loading"
                src={load}
                className="spinner ml-3 w-6 h-6 text-primary "
              />
            </div>
          )}
        </div>
        <div>
          <h5 className="text-2xl font-semibold">Id Card</h5>
          {isPhotoAndCard && idCardImg ? (
            <img
              className="border border-gray-300 my-3"
              style={{ width: "300px", height: "200px" }}
              title="Student Idcard"
              src={isPhotoAndCard ? idCardImg : ""}
              alt="idCard"
            />
          ) : (
            <div
              style={{
                width: "200px",
                height: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="border border-gray-300 my-3"
            >
              <p> Loading </p>
              <img
                alt="loading"
                src={load}
                className="spinner ml-3 w-6 h-6 text-primary "
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ProctoringViewImageModal;
