import {
  UPDATE_TEST_RESPONSE,
  FETCH_TEST_REQUESTED,
  FETCH_TEST_SUCCESS,
  FETCH_TEST_FAILURE,
  UPDATE_TEST_LOADING,
  UPDATE_TEST_STARTER_CARD_ACTIVE,
  SUBMIT_TEST_REQUESTED,
  SUBMIT_TEST_SUCCESS,
  SUBMIT_TEST_FAILURE,
  UPDATE_TEST_ACCORDIAN_INDEX,
  UPDATE_TEST_ACCORDIAN_PAGE_INDEX,
  UPDATE_TEST_SECTION_SUBMIT_MODAL_ACTIVE,
  UPDATE_TEST_SUBMITTED_SECTIONS_COUNT,
  UPDATE_TEST_MAIN_DATA_WITH_KEY,
  UPDATE_TEST_DELETE_MODAL_ACTIVE,
  DELETE_TEST_REQUESTED,
  DELETE_TEST_SUCCESS,
  DELETE_TEST_FAILURE,
  UPDATE_TEST_SECTION_DATA_WITH_KEY,
  DELETE_TEST_SECTION,
  DUPLICATE_TEST_SECTION,
  ADD_TEST_SECTION,
  UPDATE_TEST_QUESTION_DATA_WITH_KEY,
  ADD_TEST_QUESTION,
  DELETE_TEST_QUESTION,
  ASSIGN_TEST_REQUESTED,
  ASSIGN_TEST_SUCCESS,
  ASSIGN_TEST_FAILURE,
  DUPLICATE_TEST_QUESTION,
  UPDATE_USER_BANDWIDTH,
  SET_USER_SCREENSTREAM,
  SET_USER_VIDEOSTREAM,
  IS_AUDIO,
  IS_SCREEN,
  IS_VIDEO,
  FETCH_ALL_TESTS,
  FETCH_ALL_TESTS_SUCCESS,
  FETCH_ALL_TESTS_FAILURE,
  FETCH_STUDENT_RESPONSE,
  FETCH_STUDENT_RESPONSE_SUCCESS,
  FETCH_QUIZ_PROCTORING_DETAILS,
  FETCH_QUIZ_PROCTORING_DETAILS_SUCCESS,
  UPDATE_CURRENT_QUESTION_INDEX,
  UPDATE_CURRENT_SECTION_INDEX,
  FETCH_TEST_AUTO,
  FETCH_TEST_AUTO_SUCCESS,
  FETCH_TEST_AUTO_FAILURE,
  SET_TEST_STATUS,
  FETCH_STUDENT_RESPONSE_FAILURE,
  FETCH_QUIZ_PROCTORING_DETAILS_FAILURE,
  FETCH_TEST_SCORE_REQUESTED,
  FETCH_TEST_SCORE_SUCCESS,
  FETCH_TEST_SCORE_FAILURE,
  DELETE_RESPONSE_REQUESTED,
  DELETE_RESPONSE_SUCCESS,
  DELETE_RESPONSE_FAILURE,
  FETCH_COMPANIES_REQUESTED,
  FETCH_COMPANIES_SUCCESS,
  ASSIGN_TEST_PROCTOR_REQUESTED,
  ASSIGN_TEST_PROCTOR_SUCCESS,
  ASSIGN_TEST_PROCTOR_FAILURE,
  FETCH_TEST_ASSIGN_REQUESTED,
  FETCH_TEST_ASSIGN_SUCCESS,
  FETCH_TEST_ASSIGN_FAILURE,
  UPDATE_TEST_ASSIGN,
  SUBMIT_FEEDBACK_REQUESTED,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAILURE,
  SEND_EMAILS_REQUESTED,
  SEND_EMAILS_SUCCESS,
  SEND_EMAILS_FAILURE,
  SEND_TEST_INVITE_EMAILS_REQUESTED,
  SEND_REMINDER_EMAILS_REQUESTED,
  REARRANGE_ALL_TESTS,
  ADD_GROUP_DETAILS_REQUESTED,
  ADD_GROUP_DETAILS_SUCCESS,
  UPDATE_GROUP_DETAILS_REQUESTED,
  UPDATE_GROUP_DETAILS_SUCCESS,
  FETCH_ALL_GROUP_DETAILS_REQUESTED,
  FETCH_ALL_GROUP_DETAILS_SUCCESS,
  DELETE_GROUP_DETAILS_REQUESTED,
  DELETE_GROUP_DETAILS_SUCCESS,
  SET_SUBMISSION_RESPONSE,
  TOGGLE_RIGHT_SIDEBAR,
  UPDATE_PUBLIC_TEST_MODAL,
  UPDATE_PUBLIC_TEST_STUDENT_DETAILS,
  UPDATE_PUBLIC_TEST_REGISTERED_DETAILS,
  UPDATE_OVERALL_RANK_REQUESTED,
  UPDATE_TEST_DATA,
  FETCH_STUDENT_RESPONSE_FROM_REDIS,
  FETCH_STUDENT_RESPONSE_FAILURE_FROM_REDIS,
  FETCH_STUDENT_RESPONSE_SUCCESS_FROM_REDIS,
  FETCH_USER_TABLES_REQUESTED,
  FETCH_USER_TABLES_FAILURE,
  FETCH_USER_TABLES_SUCCESS,
  CREATE_STUDENT_SCHEMA,
  UPDATE_QUESTION_FROM_QUESTIONBANK,
  UPDATE_QUESTION_BANK_MODAL,
  UPDATE_AUDIO_RECORDING_STATUS,
  UPDATE_YTVIDEO_RECORDING_STATUS,
  UPDATE_TEST_DATA_ON_ARRANGE,
  UPDATE_TEST_END_TIME_REQUESTED,
  UPDATE_TEST_END_TIME_FAILURE,
  UPDATE_TEST_END_TIME_SUCCESS,
  CLONE_TEST_REQUESTED,
  CLONE_TEST_SUCCESS,
  CLONE_TEST_FAILURE,
  UPDATING_CUTOFF_REQUESTED,
  UPDATING_CUTOFF_SUCCESS,
  UPDATING_CUTOFF_FAILURE,
} from "../actionTypes";

export const DeleteResponseRequested = (data) => {
  return {
    type: DELETE_RESPONSE_REQUESTED,
    payload: data,
  };
};

export const updateOverallRankRequested = (data) => {
  return {
    type: UPDATE_OVERALL_RANK_REQUESTED,
    payload: data,
  };
};

export const updatePublicTestRegisteredDetails = (data) => {
  return {
    type: UPDATE_PUBLIC_TEST_REGISTERED_DETAILS,
    payload: data,
  };
};

export const updatePublicTestStudentDetails = (data) => {
  return {
    type: UPDATE_PUBLIC_TEST_STUDENT_DETAILS,
    payload: data,
  };
};

export const updatePublicTestModal = (data) => {
  return {
    type: UPDATE_PUBLIC_TEST_MODAL,
    payload: data,
  };
};

export const setSubmissionResponse = (data) => {
  return {
    type: SET_SUBMISSION_RESPONSE,
    payload: data,
  };
};

export const deleteGroupDetailsRequested = (data) => {
  return {
    type: DELETE_GROUP_DETAILS_REQUESTED,
    payload: data,
  };
};

export const deleteGroupDetailsSuccess = (data) => {
  return {
    type: DELETE_GROUP_DETAILS_SUCCESS,
  };
};

export const addGroupDetailsRequested = (data) => {
  return {
    type: ADD_GROUP_DETAILS_REQUESTED,
    payload: data,
  };
};

export const addGroupDetailsSuccess = (data) => {
  return {
    type: ADD_GROUP_DETAILS_SUCCESS,
    payload: data,
  };
};

export const updateGroupDetailsRequested = (data) => {
  return {
    type: UPDATE_GROUP_DETAILS_REQUESTED,
    payload: data,
  };
};

export const updateGroupDetailsSuccess = (data) => {
  return {
    type: UPDATE_GROUP_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchAllGroupDetailsRequested = (data) => {
  return {
    type: FETCH_ALL_GROUP_DETAILS_REQUESTED,
    payload: data,
  };
};

export const fetchAllGroupDetailsSuccess = (data) => {
  return {
    type: FETCH_ALL_GROUP_DETAILS_SUCCESS,
    payload: data,
  };
};

export const rearrangeAllTests = (data) => {
  return {
    type: REARRANGE_ALL_TESTS,
    payload: data,
  };
};

export const sendEmailsRequested = (data) => {
  return {
    type: SEND_EMAILS_REQUESTED,
    payload: data,
  };
};

export const sendReminderEmailsRequested = (data) => {
  return {
    type: SEND_REMINDER_EMAILS_REQUESTED,
    payload: data,
  };
};

export const sendTestInviteEmailsRequested = (data) => {
  return {
    type: SEND_TEST_INVITE_EMAILS_REQUESTED,
    payload: data,
  };
};

export const sendEmailsSuccess = (data) => {
  return {
    type: SEND_EMAILS_SUCCESS,
    payload: data,
  };
};

export const sendEmailsFailure = (data) => {
  return {
    type: SEND_EMAILS_FAILURE,
    payload: data,
  };
};

export const submitFeedbackRequested = (testId, data) => {
  return {
    type: SUBMIT_FEEDBACK_REQUESTED,
    payload: {
      testId,
      feedback: data,
    },
  };
};

export const submitFeedbackSuccess = (data) => {
  return {
    type: SUBMIT_FEEDBACK_SUCCESS,
    payload: data,
  };
};

export const submitFeedbackFailure = (data) => {
  return {
    type: SUBMIT_FEEDBACK_FAILURE,
    payload: data,
  };
};

export const fetchCompaniesRequested = (data) => {
  return {
    type: FETCH_COMPANIES_REQUESTED,
  };
};

export const fetchCompaniesSuccess = (data) => {
  return {
    type: FETCH_COMPANIES_SUCCESS,
    payload: data,
  };
};

export const deleteResponseSuccess = (data) => {
  return {
    type: DELETE_RESPONSE_SUCCESS,
    payload: data,
  };
};

export const deleteResponseFailure = (data) => {
  return {
    type: DELETE_RESPONSE_FAILURE,
    payload: data,
  };
};

export const fetchQuizProctoringDetailsFailure = (data) => {
  return {
    type: FETCH_QUIZ_PROCTORING_DETAILS_FAILURE,
    payload: data,
  };
};

export const fetchTestScoreRequested = (data) => {
  return {
    type: FETCH_TEST_SCORE_REQUESTED,
    payload: data,
  };
};

export const fetchTestScoreSuccess = (data) => {
  return {
    type: FETCH_TEST_SCORE_SUCCESS,
    payload: data,
  };
};

export const fetchTestScoreFailure = (data) => {
  return {
    type: FETCH_TEST_SCORE_FAILURE,
    payload: data,
  };
};

export const updateTestResponse = (data) => {
  return {
    type: UPDATE_TEST_RESPONSE,
    payload: data,
  };
};

export const fetchStudentResponseFailure = (data) => {
  return {
    type: FETCH_STUDENT_RESPONSE_FAILURE,
    payload: data,
  };
};

export const setTestStatus = (data) => {
  return {
    type: SET_TEST_STATUS,
    payload: data,
  };
};

export const fetchAllTestsFailure = (data) => {
  return {
    type: FETCH_ALL_TESTS_FAILURE,
    payload: data,
  };
};

export const fetchTestAuto = (data) => {
  return {
    type: FETCH_TEST_AUTO,
    payload: data,
  };
};

export const fetchTestAutoSuccess = (data) => {
  return {
    type: FETCH_TEST_AUTO_SUCCESS,
    payload: data,
  };
};

export const fetchTestAutoFailure = (data) => {
  return {
    type: FETCH_TEST_AUTO_FAILURE,
    payload: data,
  };
};

export const updateQuestionIndex = (data) => {
  return {
    type: UPDATE_CURRENT_QUESTION_INDEX,
    payload: data,
  };
};

export const updateSectionIndex = (data) => {
  return {
    type: UPDATE_CURRENT_SECTION_INDEX,
    payload: data,
  };
};

export const fetchQuizProctoringDetailsSuccess = (data) => {
  return {
    type: FETCH_QUIZ_PROCTORING_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchQuizProctoringDetails = (data) => {
  return {
    type: FETCH_QUIZ_PROCTORING_DETAILS,
    payload: data,
  };
};

export const fetchStudentResponseFromRedis = (data) => {
  return {
    type: FETCH_STUDENT_RESPONSE_FROM_REDIS,
    payload: data,
  };
};
export const fetchStudentResponseFailureFromRedis = (data) => {
  return {
    type: FETCH_STUDENT_RESPONSE_FAILURE_FROM_REDIS,
    payload: data,
  };
};
export const fetchStudentResponseSuccessFromRedis = (data) => {
  return {
    type: FETCH_STUDENT_RESPONSE_SUCCESS_FROM_REDIS,
    payload: data,
  };
};

export const fetchStudentResponse = (data) => {
  return {
    type: FETCH_STUDENT_RESPONSE,
    payload: data,
  };
};

export const fetchStudentResponseSuccess = (data) => {
  return {
    type: FETCH_STUDENT_RESPONSE_SUCCESS,
    payload: data,
  };
};

export const fetchAllTests = (data) => {
  return {
    type: FETCH_ALL_TESTS,
    payload: data,
  };
};

export const fetchAllTestsSuccess = (data, filter) => {
  return {
    type: FETCH_ALL_TESTS_SUCCESS,
    payload: {
      data,
      filter,
    },
  };
};

export const updateTestLoading = (data) => {
  return {
    type: UPDATE_TEST_LOADING,
    payload: data,
  };
};

export const setUserScreen = (data) => {
  return {
    type: SET_USER_SCREENSTREAM,
    payload: data,
  };
};

export const setUserVideo = (data) => {
  return {
    type: SET_USER_VIDEOSTREAM,
    payload: data,
  };
};
export const setIsVideo = (data) => {
  return {
    type: IS_VIDEO,
    payload: data,
  };
};
export const setIsAudio = (data) => {
  return {
    type: IS_AUDIO,
    payload: data,
  };
};
export const setIsScreen = (data) => {
  return {
    type: IS_SCREEN,
    payload: data,
  };
};

export const updateBandWidth = (data) => {
  return {
    type: UPDATE_USER_BANDWIDTH,
    payload: data,
  };
};

export const fetchTestRequest = (data, options) => {
  return {
    type: FETCH_TEST_REQUESTED,
    payload: {
      data,
      options,
    },
  };
};

export const updateTestData = (data) => {
  return {
    type: UPDATE_TEST_DATA,
    payload: data,
  };
};

export const fetchTestSuccess = (data, options) => {
  return {
    type: FETCH_TEST_SUCCESS,
    payload: {
      data,
      options,
    },
  };
};

export const fetchTestFailure = (data) => {
  return {
    type: FETCH_TEST_FAILURE,
    payload: data,
  };
};

export const updateStarterCardActive = (data) => {
  return {
    type: UPDATE_TEST_STARTER_CARD_ACTIVE,
    payload: data,
  };
};

export const submitTestRequested = (data) => {
  return {
    type: SUBMIT_TEST_REQUESTED,
    payload: data,
  };
};

export const submitTestSuccess = (data) => {
  return {
    type: SUBMIT_TEST_SUCCESS,
    payload: data,
  };
};

export const submitTestFailure = (data) => {
  return {
    type: SUBMIT_TEST_FAILURE,
    payload: data,
  };
};

export const updateTestAccordianIndex = (data) => {
  return {
    type: UPDATE_TEST_ACCORDIAN_INDEX,
    payload: data,
  };
};

export const updateTestAccordianPageIndex = (data) => {
  return {
    type: UPDATE_TEST_ACCORDIAN_PAGE_INDEX,
    payload: data,
  };
};

export const updateTestSectionSubmitModalActive = (data) => {
  return {
    type: UPDATE_TEST_SECTION_SUBMIT_MODAL_ACTIVE,
    payload: data,
  };
};

export const updateTestSubmittedSectionsCount = (data) => {
  return {
    type: UPDATE_TEST_SUBMITTED_SECTIONS_COUNT,
    payload: data,
  };
};

export const updateTestMainDataWithKey = (data) => {
  return {
    type: UPDATE_TEST_MAIN_DATA_WITH_KEY,
    payload: data,
  };
};

export const updateTestDeleteModalActive = (data) => {
  return {
    type: UPDATE_TEST_DELETE_MODAL_ACTIVE,
    payload: data,
  };
};

export const deleteTestRequest = (data) => {
  return {
    type: DELETE_TEST_REQUESTED,
    payload: data,
  };
};

export const deleteTestSuccess = (data) => {
  return {
    type: DELETE_TEST_SUCCESS,
    payload: data,
  };
};

export const deleteTestFailure = (data) => {
  return {
    type: DELETE_TEST_FAILURE,
    payload: data,
  };
};

export const updateTestSectionDataWithKey = (data) => {
  return {
    type: UPDATE_TEST_SECTION_DATA_WITH_KEY,
    payload: data,
  };
};

export const deleteTestSection = (data) => {
  return {
    type: DELETE_TEST_SECTION,
    payload: data,
  };
};

export const duplicateTestSection = (data) => {
  return {
    type: DUPLICATE_TEST_SECTION,
    payload: data,
  };
};

export const addTestSection = () => {
  return {
    type: ADD_TEST_SECTION,
  };
};

export const updateTestQuestionDataWithKey = (data) => {
  return {
    type: UPDATE_TEST_QUESTION_DATA_WITH_KEY,
    payload: data,
  };
};

export const addTestQuestion = (data) => {
  return {
    type: ADD_TEST_QUESTION,
    payload: data,
  };
};

export const deleteTestQuestion = (data) => {
  return {
    type: DELETE_TEST_QUESTION,
    payload: data,
  };
};

export const assignTestRequest = (id, data) => {
  return {
    type: ASSIGN_TEST_REQUESTED,
    payload: { id, data },
  };
};

export const assignTestSuccess = (data) => {
  return {
    type: ASSIGN_TEST_SUCCESS,
    payload: data,
  };
};

export const assignTestFailure = (data) => {
  return {
    type: ASSIGN_TEST_FAILURE,
    payload: data,
  };
};

export const assignTestProctorRequest = (id, data) => {
  return {
    type: ASSIGN_TEST_PROCTOR_REQUESTED,
    payload: { id, data },
  };
};

export const assignTestProctorSuccess = (data) => {
  return {
    type: ASSIGN_TEST_PROCTOR_SUCCESS,
    payload: data,
  };
};

export const assignTestProctorFailure = (data) => {
  return {
    type: ASSIGN_TEST_PROCTOR_FAILURE,
    payload: data,
  };
};

export const duplicateTestQuestion = (data) => {
  return {
    type: DUPLICATE_TEST_QUESTION,
    payload: data,
  };
};

export const fetchTestAssignRequested = (data) => {
  return {
    type: FETCH_TEST_ASSIGN_REQUESTED,
    payload: data,
  };
};

export const fetchTestAssignSuccess = (data) => {
  return {
    type: FETCH_TEST_ASSIGN_SUCCESS,
    payload: data,
  };
};

export const fetchTestAssignFailure = (data) => {
  return {
    type: FETCH_TEST_ASSIGN_FAILURE,
    payload: data,
  };
};

export const updateTestAssign = (data) => {
  return {
    type: UPDATE_TEST_ASSIGN,
    payload: data,
  };
};

export const toggleRightSidebar = () => {
  return {
    type: TOGGLE_RIGHT_SIDEBAR,
  };
};

export const fetchUserTablesRequested = () => {
  return {
    type: FETCH_USER_TABLES_REQUESTED,
  };
};

export const fetchUserTablesFailure = () => {
  return {
    type: FETCH_USER_TABLES_FAILURE,
  };
};

export const fetchUserTablesSuccess = (data) => {
  return {
    type: FETCH_USER_TABLES_SUCCESS,
    payload: data,
  };
};

export const createSchemaForStudent = (data) => {
  return {
    type: CREATE_STUDENT_SCHEMA,
    payload: data,
  };
};

export const updateQuestionModal = (data) => {
  return {
    type: UPDATE_QUESTION_BANK_MODAL,
    payload: data,
  };
};

export const updateQuestionFromQuestionBank = (data) => {
  return {
    type: UPDATE_QUESTION_FROM_QUESTIONBANK,
    payload: data,
  };
};

export const updateAudioRecordingStatus = (data) => {
  return {
    type: UPDATE_AUDIO_RECORDING_STATUS,
    payload: data,
  };
};

export const updateYTvideoRecordingStatus = (data) => {
  return {
    type: UPDATE_YTVIDEO_RECORDING_STATUS,
    payload: data,
  };
};

export const updateTestDataOnExchange = (data) => {
  return {
    type: UPDATE_TEST_DATA_ON_ARRANGE,
    payload: data,
  };
};

export const updateTestEndTimeRequest = (data) => {
  return {
    type: UPDATE_TEST_END_TIME_REQUESTED,
    payload: data,
  };
};

export const updateTestEndTimeSuccess = (data) => {
  return {
    type: UPDATE_TEST_END_TIME_SUCCESS,
    payload: data,
  };
};

export const updateTestEndTimeFailure = (data) => {
  return {
    type: UPDATE_TEST_END_TIME_FAILURE,
    payload: data,
  };
};

export const cloneTestRequest = (data) => {
  return {
    type: CLONE_TEST_REQUESTED,
    payload: data,
  };
};

export const cloneTestSuccess = (data) => {
  return {
    type: CLONE_TEST_SUCCESS,
    payload: data,
  };
};

export const cloneTestFailure = (data) => {
  return {
    type: CLONE_TEST_FAILURE,
    payload: data,
  };
};

export const updatingCutoffRequest = (data) => {
  return {
    type: UPDATING_CUTOFF_REQUESTED,
    payload: data,
  };
};

export const updatingCutoffSuccess = (data) => {
  return {
    type: UPDATING_CUTOFF_SUCCESS,
    payload: data,
  };
};

export const updatingCutoffFailure = (data) => {
  return {
    type: UPDATING_CUTOFF_FAILURE,
    payload: data,
  };
};
