import React from "react";
import { ReactComponent as ArrowUpIcon } from "../../../../assets/img/svg/arrow_up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../assets/img/svg/arrow_down.svg";
// Store
import { connect } from "react-redux";
import {
  updateTestAccordianIndex,
  updateTestAccordianPageIndex,
  updateTestSectionSubmitModalActive,
} from "../../../../store/actions/test";

class ForwardOnly extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
    };
    this.pageNumberArray = this.pageNumberArray.bind(this);
  }

  pageNumberArray(section) {
    let numberOfPages = Math.ceil(
      section.components.length / section.questions_per_page
    );
    let number_array = [];
    for (let i = 0; i < numberOfPages; i++) {
      number_array.push(i);
    }

    return number_array;
  }

  // render() {
  //   const { response } = this.props;
  //   return (
  //     <div className="accordian lg:w-[80%] w-full">
  //       {this.props.test.components.map((section, sectionIndex) => (
  //         <div
  //           key={`ACCORDIAN-SECTION${sectionIndex}`}
  //           className="accordian-item "
  //         >
  //           {/* <> */}
  //           <div
  //             onClick={() =>
  //               this.props.updateTestSectionSubmitModalActive(true)
  //             }
  //             className={`${
  //               sectionIndex > this.props.test.submitted_sections
  //                 ? ""
  //                 : "pointer-events-none "
  //             }accordian-header flex justify-between items-center px-3 py-2 w-full font-medium text-left text-gray-900 bg-[#e2f8e1]  border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 cursor-pointer`}
  //           >
  //             <div className="accordian-title pr-20">
  //               <span>{section.title}</span>
  //             </div>
  //             <div className="accordian-icon">
  //               <img
  //                 style={{ height: "20px", width: "20px" }}
  //                 src={addImg}
  //                 title="View section"
  //                 alt="opens section"
  //               />
  //             </div>
  //           </div>
  //           {this.props.test.submitted_sections === sectionIndex ? (
  //             this.pageNumberArray(section).map((page_num, index) =>
  //               section.components.length ===
  //               this.pageNumberArray(section).length ? (
  //                 <>
  //                   <div
  //                     key={`ACCORDIAN-SECTION-${sectionIndex}-QUESTION-${page_num}`}
  //                     className="accordian-child"
  //                   >
  //                     <div
  //                       className={`accordian-child-header flex justify-between items-center pl-5 pr-4
  //                   py-2 w-full font-medium text-left ${
  //                     this.props.currentAccordianPageIndex === page_num
  //                       ? "text-green-600"
  //                       : "text-gray-900"
  //                   } bg-white focus:ring-4
  //                   focus:ring-gray-200 dark:focus:ring-gray-800 cursor-pointer`}
  //                     >
  //                       <div className="flex items-center justify-center accordian-child-title">
  //                         <span>Question {page_num + 1}</span>
  //                         {/* {Boolean(response?.[sectionIndex]?.[page_num]) && (
  //                           <span className="w-3 h-3 ml-3 bg-green-800 rounded-full"></span>
  //                         )} */}
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </>
  //               ) : (
  //                 <div
  //                   key={`ACCORDIAN-SECTION-${sectionIndex}-PAGE-${page_num}`}
  //                   className="accordian-child"
  //                 >
  //                   <div
  //                     className={`accordian-child-header flex justify-between items-center pl-5 pr-4
  //                   py-2 w-full font-medium text-left ${
  //                     this.props.currentAccordianPageIndex === page_num
  //                       ? "text-green-600"
  //                       : "text-gray-900"
  //                   } bg-white focus:ring-4
  //                   focus:ring-gray-200 dark:focus:ring-gray-800 cursor-pointer`}
  //                   >
  //                     <div className="flex items-center justify-center accordian-child-title">
  //                       <span>Page {page_num + 1}</span>
  //                       {/* {Boolean(response?.[sectionIndex]?.[page_num]) && (
  //                         <span className="w-3 h-3 ml-3 bg-green-800 rounded-full"></span>
  //                       )} */}
  //                     </div>
  //                   </div>
  //                 </div>
  //               )
  //             )
  //           ) : (
  //             <> </>
  //           )}
  //         </div>
  //       ))}
  //     </div>
  //   );
  // }
  render() {
    const { response } = this.props;
    return (
      <div className="accordian  w-full">
        {this.props.test.components.map((section, sectionIndex) => (
          <div
            key={`ACCORDION-SECTION-${sectionIndex}`}
            className="accordian-item"
          >
            {this.pageNumberArray(section).length !== 0 ? (
              <>
                <div>
                  <div
                    // onClick={() => this.updateTestAccordianIndex(sectionIndex)}
                    className={`accordian-header flex justify-between items-center px-3 py-2 w-full font-bold text-left text-gray-900 ${
                      this.props.currentAccordianIndex === sectionIndex
                        ? `bg-[#e2f8e1]`
                        : `bg-gray-100`
                    }  border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800`}
                  >
                    <div
                      className={`accordian-title pr-20 ${
                        this.props.currentAccordianIndex === sectionIndex
                          ? "font-semimedium"
                          : "font-medium"
                      }`}
                    >
                      <span>{section.title}</span>
                    </div>
                    <div className="accordian-icon">
                      {this.props.currentAccordianIndex === sectionIndex ? (
                        <ArrowUpIcon title="View section" alt="opens section" />
                      ) : (
                        <ArrowDownIcon
                          title="View section"
                          alt="opens section"
                        />
                      )}
                    </div>
                  </div>

                  <div className="flex flex-row flex-wrap justify-center items-center">
                    {this.props.currentAccordianIndex === sectionIndex ? (
                      this.pageNumberArray(section).map((page_num, index) =>
                        section.components.length ===
                        this.pageNumberArray(section).length ? (
                          <div
                            // onClick={() =>
                            //   this.updateTestAccordianPageIndex(page_num)
                            // }
                            className="accordian-child"
                            key={`ACCORDION-SECTION-${sectionIndex}-PAGE-${page_num}`}
                          >
                            <div
                              className={`accordian-child-header py-2 px-1 font-medium text-left ${
                                this.props.currentAccordianPageIndex ===
                                page_num
                                  ? "text-green-600"
                                  : "text-[#666666]"
                              } bg-white focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 cursor-pointer`}
                            >
                              <div
                                className={` accordian-child-title border ${
                                  this.props.currentAccordianPageIndex ===
                                  page_num
                                    ? "border-green-600"
                                    : "border-[#666666]"
                                } rounded-full`}
                              >
                                <span className="p-1 px-2 ">
                                  {page_num + 1}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            // onClick={() =>
                            //   this.props.updateTestAccordianPageIndex(page_num)
                            // }
                            className="accordian-child"
                            key={`ACCORDION-SECTION-${sectionIndex}-PAGE-${page_num}`}
                          >
                            <div
                              className={`accordian-child-header flex justify-between items-center pl-5 pr-4 
                    py-2 w-full font-medium text-left ${
                      this.props.currentAccordianPageIndex === page_num
                        ? "text-green-600"
                        : "text-gray-900"
                    } bg-white focus:ring-4 
                    focus:ring-gray-200 dark:focus:ring-gray-800`}
                            >
                              <div className="flex items-center justify-center accordian-child-title">
                                <span>Page {page_num + 1}</span>
                                {/* {Boolean(
                                response?.[this.props.currentSectionIndex]?.[
                                  this.props.currentQuestionIndex
                                ]
                              ) && (
                                <span className="w-3 h-3 ml-3 bg-green-800 rounded-full"></span>
                              )} */}
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <> </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    test: test.test,
    response: test.response,
    currentAccordianIndex: test.currentAccordianIndex,
    currentAccordianPageIndex: test.currentAccordianPageIndex,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTestAccordianIndex: (data) =>
      dispatch(updateTestAccordianIndex(data)),
    updateTestAccordianPageIndex: (data) =>
      dispatch(updateTestAccordianPageIndex(data)),
    updateTestSectionSubmitModalActive: (data) =>
      dispatch(updateTestSectionSubmitModalActive(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForwardOnly);
