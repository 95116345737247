import {
  FETCH_PROCTORED_USERS_FAILURE,
  FETCH_PROCTORED_USERS_REQUESTED,
  FETCH_PROCTORED_USERS_SUCCESS,
  FETCH_ATTENDING_USERS_FAILURE,
  FETCH_ATTENDING_USERS_REQUESTED,
  FETCH_ATTENDING_USERS_SUCCESS,
  FETCH_PROCTORED_USERS_ANY_PROCTOR_FAILURE,
  FETCH_PROCTORED_USERS_ANY_PROCTOR_REQUESTED,
  FETCH_PROCTORED_USERS_ANY_PROCTOR_SUCCESS,
} from "../actionTypes";

const initialState = {
  isFetching: false,
  isFetchingAttendingDetails: false,
  proctoredUsers: [],
  attendingUsers: {},
};

const proctorReducer = function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case FETCH_PROCTORED_USERS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case FETCH_PROCTORED_USERS_REQUESTED:
      return {
        ...state,
        isFetching: true,
        proctoredUsers: [],
      };

    case FETCH_PROCTORED_USERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        proctoredUsers: action.payload,
      };
    case FETCH_ATTENDING_USERS_FAILURE:
      return {
        ...state,
        isFetchingAttendingDetails: false,
      };

    case FETCH_ATTENDING_USERS_REQUESTED:
      return {
        ...state,
        isFetchingAttendingDetails: true,
        attendingUsers: {},
      };

    case FETCH_ATTENDING_USERS_SUCCESS:
      return {
        ...state,
        isFetchingAttendingDetails: false,
        attendingUsers: {
          ...action.payload
        },
      };
    case FETCH_PROCTORED_USERS_ANY_PROCTOR_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case FETCH_PROCTORED_USERS_ANY_PROCTOR_REQUESTED:
      return {
        ...state,
        isFetching: true,
        proctoredUsers: [],
      };

    case FETCH_PROCTORED_USERS_ANY_PROCTOR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        proctoredUsers: action.payload,
      };
    default:
      return state;
  }
};

export default proctorReducer;
