import React, { useLayoutEffect, useState } from "react";
import { Tldraw, createTLStore, defaultShapeUtils, throttle } from '@tldraw/tldraw'
import load from "../../../assets/img/svg/load.svg";
import { defaultCanvas, getUserIdFromPeerId } from "../../../components/common/utils";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";



export default function WhiteBoard({ socket, store }) {
    const [loading, setLoading] = useState(false);
    const Persistence_key = useParams()?.id;
    const { interviewerPeerId } = useSelector(state => state.interviewpanelReducer)


    const [loadingState, setLoadingState] = useState({
        status: 'loading',
    })


    useLayoutEffect(() => {
        setLoadingState({ status: 'loading' })

        // Get persisted data from local storage
        const persistedSnapshot = localStorage.getItem(Persistence_key)

        if (persistedSnapshot) {
            try {
                const snapshot = JSON.parse(persistedSnapshot)
                store.loadSnapshot(snapshot)
                setLoadingState({ status: 'ready' })
            } catch (error) {
                setLoadingState({ status: 'error', error: error.message }) // Something went wrong
            }
        } else {
            setLoadingState({ status: 'ready' }) // Nothing persisted, continue with the empty store
        }

        // Each time the store changes, run the (debounced) persist function
        const cleanupFn = store.listen(
            throttle(() => {
                const snapshot = store.getSnapshot()
                if (interviewerPeerId) {
                    socket.emit("send-tldraw-state", {
                        state: JSON.stringify(snapshot),
                        userId: getUserIdFromPeerId(interviewerPeerId),
                        type: 'C'
                    });
                }
                localStorage.setItem(Persistence_key, JSON.stringify(snapshot))
            }, 1000)
        )

        return () => {
            cleanupFn()
        }
    }, [store])

    // [4]
    if (loadingState.status === 'loading') {
        return (
            <div className="tldraw__editor">
                <h2>Loading...</h2>
            </div>
        )
    }

    if (loadingState.status === 'error') {
        return (
            <div className="tldraw__editor">
                <h2>Error!</h2>
                <p>{loadingState.error}</p>
            </div>
        )
    }

    const onSaveEditorState = () => {
        const snapshot = store.getSnapshot()
        if (interviewerPeerId) {
            socket.emit("send-tldraw-state", {
                state: JSON.stringify(snapshot),
                userId: getUserIdFromPeerId(interviewerPeerId),
                type: 'C'
            });
        }
    }

    const onClearEditorState = () => {
        const snapshot = JSON.parse(defaultCanvas)
        store.loadSnapshot(snapshot)
        if (interviewerPeerId) {
            socket.emit("send-tldraw-state", {
                state: snapshot,
                userId: getUserIdFromPeerId(interviewerPeerId),
                type: 'C'
            });
        }
    }

    return (
        <div className="mx-2 relative">
            <div className="flex gap-x-2 justify-between items-center">
                <h1 className="font-['poppins'] font-medium text-[#37474F] text-xl">
                    Whiteboarding
                </h1>
                <div className="flex gap-3 mr-7">
                    <button
                        onClick={() => onSaveEditorState()}
                        className={`text-[#001437] border border-[#001437] hover:bg-[#001437] hover:text-[#a0db32]  rounded-lg 
                md:text-[16px] font-bold md:px-3 text-sm  px-1 py-1 text-center font-['nunito'] cursor-pointer ${loading ? "hover:bg-[#a0db32]" : ""
                            }`}>
                        {loading ? (
                            <img alt="loading" src={load} className="mx-auto spinner w-6 h-6" />
                        ) : (
                            "Save"
                        )}
                    </button>
                    <button
                        onClick={() => onClearEditorState()}
                        className={`text-red-600 border border-red-400  hover:bg-red-400 hover:text-white  rounded-lg 
                md:text-[16px] font-bold md:px-3 text-sm  px-1 py-1 text-center font-['nunito'] cursor-pointer ${loading ? "hover:bg-[#a0db32]" : ""
                            }`}>
                        Clear
                    </button>
                </div>
            </div>
            <div className="w-[100%] h-[70vh] pt-5">
                <Tldraw store={store} />
            </div>
        </div>
    );
}