import React from "react";
import { Languages } from "../Utils/Languages";
import { TemplateCode } from "../../../../components/tests/codeEditor/Utils/Constants";
import imgUpload from "../../../../assets/img/upload.svg";
// Store
import { connect } from "react-redux";
import {
  updateCodeEditorLanguage,
  updateCodeEditor,
} from "../../../../store/actions/codeeditor";
import { getUserIdFromPeerId } from "../../../common/utils";

class LanguageSelector extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeCodeEditorLanguage =
      this.onChangeCodeEditorLanguage.bind(this);
  }

  componentDidMount() {
    this.props.updateCodeEditorLanguage("cpp");
  }

  handleChange = (ejs) => {
    const file = ejs.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const ext = file.name.split(".").pop();
        const text = e.target.result;
        this.props.updateCodeEditorLanguage(ext);
        const upload = TemplateCode;
        this.props.updateCodeEditor({
          sectionIndex: this.props.sectionId,
          questionIndex: this.props.questionId,
          value: {
            ...upload,
            [ext]: text,
          },
        });
      };
      // this.props.isFileUploaded();
      reader.readAsText(file);
    }
  };

  onChangeCodeEditorLanguage = (data) => {
    if (this.props.type === "I" && this.props.interviewerPeerId) {
      // console.log("checking peer from user", this.props.interviewerPeerId);
      this.props.socket.emit("send-editor-language-state", {
        state: data,
        userId: getUserIdFromPeerId(this.props.interviewerPeerId),
      });
    }
    this.props.updateCodeEditorLanguage(data);
  };
  render() {
    const data = Object.keys(Languages).map((language) => ({
      name: Languages[language].name,
      value: language,
    }));
    return (
      <div className="my-1 flex flex-row items-center justify-center sm:gap-x-0 gap-x-16">
        {/* <div className="border bg-gray-700 rounded shadow px-3 py-2 hover:bg-slate-600 text-white cursor-pointer flex items-center">
          <img
            src={imgUpload}
            title="Upload file"
            alt="upload file"
            className="h-5 inline mr-3 "
          />
          <label
            className="text-xs font-medium cursor-pointer "
            htmlFor="user_avatar"
          >
            Upload as file
          </label>
          <input
            id="user_avatar"
            type="file"
            hidden
            accept=".js,.py,.java,.cpp"
            onChange={this.handleChange}
          />
        </div> */}
        <div className="mr-auto">
          <label
            htmlFor="languages"
            className="text-sm font-medium text-gray-400  mr-3"
          >
            Language
          </label>
          <select
            id="languages"
            value={this.props.language}
            onChange={(e) => this.onChangeCodeEditorLanguage(e.target.value)}
            className=" shadow text-xs rounded  px-3 py-2 bg-gray-700 text-white cursor-pointer"
          >
            {data.map((item) => (
              <option key={item.name} value={item.value}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ codeeditor, interviewpanelReducer }) => {
  return {
    language: codeeditor.language,
    code: codeeditor.code,
    interviewerPeerId: interviewpanelReducer.interviewerPeerId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCodeEditorLanguage: (data) =>
      dispatch(updateCodeEditorLanguage(data)),
    updateCodeEditor: (data) => dispatch(updateCodeEditor(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
