import React from "react";

const ViewFileModal = ({ file }) => {
  return (
    <div className="h-full mt-2 relative pt-[1.5rem] p-2 ">
      <object
        data={file}
        // type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>
          Alternative text - include a link{" "}
          <a href="https://africau.edu/images/default/sample.pdf">
            to the PDF!
          </a>
        </p>
      </object>
    </div>
  );
};

export default ViewFileModal;
