import React, { Component } from "react";

export default class TestHeader extends Component {
  render() {
    const { title, styles } = this.props;

    return (
      <h2
        className={`lg:text-2xl md:text-xl text-lg font-semibold text-left font-['poppins'] pb-1 ${styles}`}
      >
        {title}
      </h2>
    );
  }
}
