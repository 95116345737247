import React from "react";
import "../../assets/css/stepform.css";
import {
  CircleTick,
  CircleOne,
  CircleTwo,
} from "../../assets/img/progressbarsvg";

import { connect } from "react-redux";
import { setNewRegistration } from "../../store/actions/user";
import { uploadFormFile } from "../../services/upload.service";
import { createUser } from "../../services/user.service";

import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";

import {getDetails} from '../../services/user.service'

class RegisterUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      totalSteps: 1,
      formValues: {
        tech_stack: ['react']
      },
      tick: 0,
    };
  }

  componentDidMount(){
    this.setState((prevState) =>( {
      ...prevState,
      formValues: this.props.initialData
    }))

    getDetails().then((res) => {
      this.setState((prevState)=> ({
        ...prevState,
        formValues: res.data
      }))
    })

  }

  // ***** this will not work if formValues get changed by redux store ******

  // componentDidMount(){
  //   this.setState({
  //     formValues: this.props.initialData
  //   })
  // }

  // ***** this will not work because it is called on re-render

  // static getDerivedStateFromProps(props, state) {
  //   return {
  //     formValues: props.initialData,
  //   };
  // }

  handleBack = () => {
    this.setState({
      ...this.state,
      activeStep: this.state.activeStep - 1,
      tick: this.state.tick - 1,
    });
  };

  handleNext = () => {
    this.setState({
      ...this.state,
      activeStep: this.state.activeStep + 1,
      tick: this.state.tick + 1,
    });
  };

  handleFile = (e) => {
    const { name, files } = e.target;

    let new_form_values = {
      ...this.state.formValues,
    };

    uploadFormFile(files[0])
      .then((res) => {
        console.log(res);
      })
      .catch((e) => console.log(e));

    //write
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    let new_form_values = {
      ...this.state.formValues,
    };

    new_form_values[name] = value;

    this.setState({
      formValues: new_form_values,
    });
  };

  onCreateUser = () => {
    createUser(this.state.formValues);
  };

  removeTags = (e, indexToRemove) => {
    const { name } = e.target;
    let new_tags = {
      ...this.state.formValues.tech_stack,
    };
    new_tags[name] = [
      ...this.state.formValues.tech_stack.filter(
        (_, index) => index !== indexToRemove
      ),
    ];
    this.setState((prevState) => ({
      ...prevState,
      formValues: {
        ...prevState.formValues,
        tech_stack: new_tags,
      },
    }));
  };

  addTags = (e) => {
    if (e.target.value !== "") {
      this.setState((prevState) => ({
        ...prevState,
        formValues: {
          ...prevState.formValues,
          tech_stack: [...prevState.formValues.tech_stack, e.target.value],
        },
      }));
    }
    e.target.value = "";
  };

  handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <FirstStep
            handleFile={this.handleFile}
            handleChange={this.handleChange}
            removeTags={this.removeTags}
            addTags={this.addTags}
            values={this.state.formValues}
          />
        );
      case 1:
        return (
          <SecondStep
            handleFile={this.handleFile}
            handleChange={this.handleChange}
            values={this.state.formValues}
          />
        );
      default:
        break;
    }
  };

  render() {
    return (
      <div>
        {/* {JSON.stringify(this.state.formValues)} */}
        <div className="container flex-column p-5">
          <p className="text-3xl font-semibold">Profile Edit</p>
          <div className="progressbar my-3">
            <div className="step">
              {this.state.tick >= 1 ? <CircleTick /> : <CircleOne />}
            </div>
            <div className="step">
              {this.state.tick >= 2 ? <CircleTick /> : <CircleTwo />}
            </div>
          </div>

          <div>
            <div>{this.handleSteps(this.state.activeStep)}</div>
          </div>

          <div className="p-3 flex gap-3">
            <div>
              <button
                disabled={this.state.activeStep === 0 ? true : false}
                onClick={this.handleBack}
                className="border border-gray-500 rounded py-2 px-3"
              >
                Previous
              </button>
            </div>
            <div>
              <button
                onClick={this.onCreateUser}
                className="bg-[#007f5f] rounded text-white py-2 px-3 "
              >
                Update
              </button>
            </div>
            <div>
              <button
                disabled={
                  this.state.activeStep === this.state.totalSteps ? true : false
                }
                onClick={this.handleNext}
                className="border border-gray-500 rounded py-2 px-3"
              >
                Next
              </button>
            </div>
          </div>
          {/* <pre>{JSON.stringify(this.state.formValues, null, 2)}</pre> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    initialData: user.newRegistration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewRegistration: (newValues) => dispatch(setNewRegistration(newValues)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUser);
