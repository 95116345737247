import React, { Component } from "react";
import { ReactComponent as UserImg } from "../../../assets/img/user.svg";
import congo from "../../../assets/img/svg/congo.png";
// Store
import { connect } from "react-redux";
import { updateStarterCardActive } from "../../../store/actions/test";
import UniversalButton from "../../common/StandardButton/UniversalButton";

class StarterCard extends Component {
  constructor(props) {
    super(props);
    this.handleStartTest = this.handleStartTest.bind(this);
  }

  handleStartTest() {
    this.props.updateStarterCardActive(6);
    if (this.props.test.is_proctoring) {
      if (this.props.bandWidth === 0) {
        this.handleToast("You are offline");
        return;
      }
      if (this.props.isVideo && this.props.isAudio) {
        if (!this.props.isScreen) {
          alert("Please share your screen!");
        }
      } else {
        alert("Please enable your camera");
      }
    }
  }

  render() {
    if (!this.props.test) {
      return <></>;
    }
    return (
      <div
        className={`flex flex-col  p-4 gap-y-5 justify-center h-[90vh] items-center`}>
        <div className="flex gap-4  items-center  flex-wrap	 justify-center w-full">
          {this.props.proctoringDetails.isPhotoAndCard && (
            <div className="md:min-h-[60px] justify-center min-w-full xl:min-w-[600px] bg-rose-50 rounded-lg shadow shadow-rose-200  p-5 flex  align-center">
              <p className="text-start text-slate-600 font-[poppins] md:p-5">
                You have completed all the checks already before starting this
                test. <br />
                Please click on the proceed button to start the test.
                <br />
                <span className="text-xl flex justify-center my-2 font-semibold">
                  All the best!
                  <img
                    className="w-6 h-6 mx-2"
                    src={congo}
                    alt="congo"
                    title="Congratulations"
                  />
                </span>
              </p>
            </div>
          )}
          <div className="md:min-h-[60px]   min-w-full xl:min-w-[600px] bg-[#EEFECF] shadow-[#BCFB46] rounded-lg shadow p-5">
            <div className="text-center ">
              <div className="flex justify-center">
                <UserImg
                  title="Student image"
                  style={{ height: "20px", width: "20px", fill: "#009696" }}
                />
              </div>
              <h3 className="text-xl font-semibold p-2 font-[poppins] text-slate-600">
                {this.props.test.title}
              </h3>
            </div>
            <div className="text-lg flex items-center justify-center  mb-5 max-h-[70vh] scrollbar px-1  overflow-auto font-[poppins] text-slate-600">
              <p
                dangerouslySetInnerHTML={{
                  __html: this.props.test.description,
                }}
              ></p>
            </div>
          </div>
        </div>
        <div className="text-center">
          <UniversalButton
            onClickFunction={() => this.handleStartTest()}

            classNames="mb-6 mr-2"
            title={this.props.proctoringDetails.isPhotoAndCard ? "Resume" : "Proceed"}

          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    test: test.test,
    proctoringDetails: test.proctoringDetails,
    is_proctoring: test.is_proctoring,
    isVideo: test.isVideo,
    isAudio: test.isAudio,
    isScreen: test.isScreen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStarterCardActive: (data) => dispatch(updateStarterCardActive(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StarterCard);
