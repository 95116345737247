import React from "react";
import "../common/Common.css";
import KyronSideLetter from "../../assets/img/Kyron/KyronLetterSide.png";
import leftLandingImg from "../../assets/img/lftImg.png";
import rightLandingImg from "../../assets/img/rightLandingImg.png";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const history = useNavigate();
  return (
    <>
      <div
        className="home lg:h-[100vh]"
        style={{
          background:
            "linear-gradient(to bottom, #ecfeca, #ffffff 50%, #edfecc 75%",
        }}
      >
        <div className="w-full flex justify-between items-center md:px-5 ">
          <div className="w-[7rem] sm:w-[12rem] flex justify-center items-center">
            <img
              src={KyronSideLetter}
              className="h-[60px] object-contain"
              alt="logo"
            />
          </div>
          <div className="flex flex-1 gap-2 items-center justify-end ">

            <button
              type="button"
              onClick={() => history("/signup")}
              className="text-[#609300] px-4 font-['nunito'] transition bg-[#bcfb465c] hover:bg-gray-800 hover:text-white font-medium rounded text-lg py-2 text-center mr-2 cursor-pointer transition"
            >
              Sign up
            </button>
            <button
              onClick={() =>
                history({
                  pathname: "/login",
                })
              }
              type="button"
              className="text-[#0a0a0a] px-4 font-['nunito'] transition bg-[#bcfb46] hover:bg-gray-800 hover:text-white font-medium rounded text-lg py-2 text-center mr-2 cursor-pointer transition"
            >
              Sign in
            </button>
          </div>
        </div>

        <div className="w-[100%] pt-[4rem] flex flex-col lg:flex-row justify-between items-center lg:h-[calc(100vh-150px)] gap-[2rem] lg:gap-[0.8rem] lg:px-[5rem] xl:gap-[2rem]">

          {/* <div className="w-[100%] md:w-[60%] lg:w-[50%]"> */}
          <div className="flex-1">
            <img
              src={leftLandingImg}
              alt="LandingImage"
              className="w-[100%] bg-cover rounded-[0.7rem] "
            />
          </div>

          <div className="flex justify-center items-center flex-col flex-[1.5]">
            <h1 className="text-[#313131] font-['nunito'] text-[2.5rem] md:leading-[5rem] text-center md:text-[4rem] lg:text-[2.7rem] xl:text-[3.8rem] lg:leading-[2.8rem] xl:leading-[5rem] font-bold">
              Empower Yourself with
              <span className="text-[#bcfb46]"> Kyron</span>
            </h1>
            <p className="text-[#5F9004] font-['nunito'] leading-[30px] md:px-[4rem] py-[1.7rem] md:py-[3rem] lg:px-3 xl:px-[4rem] text-center font-medium text-[1.5rem]">
              Kyron is your ultimate destination for career advancement. We
              bridge the gap between talent and opportunity, making your dreams
              a reality.
            </p>
            <div className="flex items-center justify-evenly w-[100%]">
              <button
                className="bg-[#B8FB3C] font-['nunito'] text-lg font-medium border-none outline-none rounded px-[2rem] py-3 hover:bg-gray-800 hover:text-white"
                onClick={() => window.open("https://kyron.in/", "_blank")}
              >
                Get Started
              </button>
              <button
                className="bg-[#B8FB3C] font-['nunito'] text-lg font-medium border-none outline-none rounded px-[2rem] py-3 hover:bg-gray-800 hover:text-white"
                onClick={() =>
                  window.open("https://kyron.in/contact", "_blank")
                }
              >
                Book Demo
              </button>
            </div>
          </div>
          <div className="flex-1">
            <img
              src={rightLandingImg}
              alt="LandingImage"
              className="w-[100%]  bg-cover rounded-[0.7rem] "
            />
          </div>

        </div>
      </div>
    </>
  );
};

export default Home;

