import React, { Component } from "react";
import eye from "../../assets/img/svg/eye.svg";
import CourseService from "../../services/course.service";
import load from "../../assets/img/svg/load.svg";
import { withRouter } from "../../components/common/WithRouter";

class NewDeleteCourse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
    };
  }

  goBack = () => {
    this.props.history("/allcourses");
  };

  deleteCourse = async () => {
    const id = this.props.params.id;
    this.setState({ deleting: true });
    await CourseService.deleteOneCourse(id)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => console.log(err));
    this.setState({ deleting: true });
    this.goBack();
  };

  render() {
    return (
      <div className="container-fluid h-100">
        <div className="row pt-3 h-100">
          <div className="col d-flex align-items-center justify-content-center">
            <div className="card p-4 p-lg-5 rounded shadow text-center">
              <div>
                <img
                  alt="svg"
                  src={eye}
                  title="Course info"
                  className="w-6 h-6 text-danger mb-2"
                />
              </div>
              <h3>Are you sure you want to delete the Course?</h3>
              <p className="text-center text-muted">
                <br />
                <span className="text-danger">
                  Note: This action is not reversable!
                </span>
              </p>
              <div className="d-flex mt-3">
                <button
                  className="py-2 px-3 bg-emerald-500 text-white font-semibold"
                  onClick={this.goBack}
                >
                  Go Back
                </button>
                <button
                  disabled={this.state.deleting}
                  className="ml-auto flex items-center py-2 px-3 bg-red-600 text-white font-semibold"
                  onClick={this.deleteCourse}
                >
                  {this.state.deleting && (
                    <img
                      alt="loading"
                      src={load}
                      className="spinner mr-2 w-6 h-6 text-primary " />
                  )}
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NewDeleteCourse);
