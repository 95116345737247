import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../../../assets/img/svg/arrow_left.svg";

import styles from "./BackButton.module.css";

const BackButton = ({
  link,
  text,
  onClick,
  className,
  iconClass,
  iconColor,
}) => {
  const history = useNavigate();

  const handleOnClick = () => {
    if (onClick && typeof onClick === "function") onClick();
    else if (link) history(link);
    else history(-1);
  };

  return (
    <div
      className={`${styles.backBtn} ${className ?? ""}  `}
      onClick={handleOnClick}
    >
      <span>
        <ArrowLeft
          color={iconColor ? iconColor : ""}
          className={`${iconClass ? iconClass : ""}`}
        />
      </span>
      <span className={` ${className ? className : ""}  `}>
        {text ?? "Go Back"}
      </span>
    </div>
  );
};

export default BackButton;
