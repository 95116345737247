import {
  FETCH_TEST_ANALYTICS_SCORE,
  FETCH_TEST_ANALYTICS_DURATION,
  FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION,
  FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION,
  FETCH_TEST_ANALYTICS_SCORE_FAILURE,
  FETCH_TEST_ANALYTICS_SCORE_SUCCESS,
  FETCH_TEST_ANALYTICS_DURATION_FAILURE,
  FETCH_TEST_ANALYTICS_DURATION_SUCCESS,
  FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION_FAILURE,
  FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION_SUCCESS,
  FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION_FAILURE,
  FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION_SUCCESS,
} from "../actionTypes";

export const fetchTestAnalyticsScore = (data) => {
  return {
    type: FETCH_TEST_ANALYTICS_SCORE,
    payload: data,
  };
};
export const fetchTestAnalyticsDuration = (data) => {
  return {
    type: FETCH_TEST_ANALYTICS_DURATION,
    payload: data,
  };
};
export const fetchTestAnalyticsScoreDistribution = (data) => {
  return {
    type: FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION,
    payload: data,
  };
};
export const fetchTestAnalyticsDurationDistribution = (data) => {
  return {
    type: FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION,
    payload: data,
  };
};

// ON SUCCESS OR FAILURE
export const fetchTestAnalyticsScoreFailure = (data) => {
  return {
    type: FETCH_TEST_ANALYTICS_SCORE_FAILURE,
    payload: data,
  };
};
export const fetchTestAnalyticsScoreSuccess = (data) => {
  return {
    type: FETCH_TEST_ANALYTICS_SCORE_SUCCESS,
    payload: data,
  };
};
export const fetchTestAnalyticsDurationFailure = (data) => {
  return {
    type: FETCH_TEST_ANALYTICS_DURATION_FAILURE,
    payload: data,
  };
};
export const fetchTestAnalyticsDurationSuccess = (data) => {
  return {
    type: FETCH_TEST_ANALYTICS_DURATION_SUCCESS,
    payload: data,
  };
};
export const fetchTestAnalyticsScoreDistrinbutionFailure = (data) => {
  return {
    type: FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION_FAILURE,
    payload: data,
  };
};
export const fetchTestAnalyticsScoreDistributionSuccess = (data) => {
  return {
    type: FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION_SUCCESS,
    payload: data,
  };
};
export const fetchTestAnalyticsDurationDistributionFailure = (data) => {
  return {
    type: FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION_FAILURE,
    payload: data,
  };
};
export const fetchTestAnalyticsDurationDistributionSuccess = (data) => {
  return {
    type: FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION_SUCCESS,
    payload: data,
  };
};

