/* eslint-disable import/no-anonymous-default-export */
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SET_MESSAGE,
  SET_SUCCESS_MESSAGE,
  LOGIN_REQUESTED,
  SIGNUP_REQUESTED,
  UPDATE_ALREADY_LOGIN_MODEL,
  LOGOUT_SUCCESSFUL,
} from "../actionTypes/index";

const name = localStorage.getItem("priksha_name");
const token = localStorage.getItem("priksha_token");
const type = localStorage.getItem("priksha_type");
const email = localStorage.getItem("priksha_email");
let user = { name, type, token, email };
if (!name || !token || !type || !email) {
  user = null;
}

const initialState = user
  ? { isLoggedIn: true, user }
  : {
    isLoggedIn: false,
    isSignedUp: false,
    user: null,
    message: null,
    successmessage: null,
    isLoginLoading: false,
    isUserAlreadyLoggedIn: false,
    isSignupLoading: false,
  };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SIGNUP_REQUESTED:
      return {
        ...state,
        isSignupLoading: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isSignupLoading: false,
        isSignedUp: true,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isSignupLoading: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        isLoginLoading: false,
        isUserAlreadyLoggedIn: false,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        isLoginLoading: false,
        isUserAlreadyLoggedIn: action.payload.loggedin,
      };
    case LOGIN_REQUESTED:
      return {
        ...state,
        isLoginLoading: true,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: payload,
      };
    case SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successmessage: payload,
      };
    case LOGOUT_SUCCESSFUL:
      // Deleting all the localStorage items
      localStorage.removeItem("priksha_name");
      localStorage.removeItem("priksha_token");
      localStorage.removeItem("priksha_type");
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    case UPDATE_ALREADY_LOGIN_MODEL:
      return {
        ...state,
        isUserAlreadyLoggedIn: action.payload,
      };
    default:
      return state;
  }
}
