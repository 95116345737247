import React from "react";

export default function DashboardCard({ svg, value, text }) {
  return (
    <div className="flex  flex-col  justify-between p-5 w-full shadow-lg shadow-[#e1f7b5] min-h-[160px] rounded-xl">
      {svg}
      <div className="flex-end">
        <p className="font-['poppins'] font-medium text-[#37474F]">{value}</p>
        <p className="font-['poppins'] font-medium text-[#666666">{text}</p>
      </div>
    </div>
  );
}
