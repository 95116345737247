import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import sidebar from "./sidebar";
import user from "./user";
import test from "./test";
import codeeditor from "./codeeditor";
import analytics from "./analytics";
import turnserver from "./turnserver";
import chat from "./chat";
import proctor from "./proctor";
import userdetails from "./userdetails";
import hrdashboard from "./hrdashboard";
import questionbank from "./questionbank";
import emailtemplates from "./emailtemplates";
import interviewpanelReducer from "./interviewpanel";
import postgresReducer from "./postgres";
import parseResumeReducer from "./parseResume";
import extractQuestionReducer from "./extractQuestion";
import evaluatorsReducer from "./evaluators";
import SubscriptionReducer from "./subscription";

export default combineReducers({
  auth,
  message,
  sidebar,
  user,
  test,
  codeeditor,
  analytics,
  turnserver,
  chat,
  proctor,
  userdetails,
  hrdashboard,
  questionbank,
  emailtemplates,
  interviewpanelReducer,
  postgresReducer,
  parseResumeReducer,
  extractQuestionReducer,
  evaluatorsReducer,
  SubscriptionReducer
});
