import React, { Component } from "react";
import { Link } from "react-router-dom";
import CourseService from "../../services/course.service";
import { withRouter } from "../../components/common/WithRouter";

class NewCreateCourse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      courseData: {
        title: "",
        description: "",
        code: "",
        students: [],
        teachers: [],
        tas: [],
      },
    };
  }

  isAdmin = () => {
    var type = localStorage["priksha_type"];
    return type === "A" || type === "T";
  };

  handleTitle = (e) => {
    const title = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      courseData: {
        ...prevState,
        title,
      },
    }));
  };

  handleDescription = (e) => {
    const description = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      courseData: {
        ...prevState,
        description,
      },
    }));
  };

  handleCode = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      courseData: {
        ...prevState,
        code: e.target.value,
      },
    }));
  };

  handleStudentsAssign = (e) => {
    const emails = e.target.value;

    const emailArray = emails.split(",");

    this.setState((prevState) => ({
      ...prevState,
      courseData: {
        ...prevState,
        students: emailArray,
      },
    }));
  };

  handleTeachersAssign = (e) => {
    const emails = e.target.value;

    const emailArray = emails.split(",");

    this.setState((prevState) => ({
      ...prevState,
      courseData: {
        ...prevState,
        teachers: emailArray,
      },
    }));
  };
  handleAssistantsAssign = (e) => {
    const emails = e.target.value;

    const emailArray = emails.split(",");
    this.setState((prevState) => ({
      ...prevState,
      courseData: {
        ...prevState,
        tas: emailArray,
      },
    }));
  };

  saveCourse = (e, callback) => {
    e.preventDefault();

    CourseService.saveOneCourse(this.state.courseData)
      .then((response) => {
        this.props.history("/allcourses");
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div fluid style={{ padding: "15px 30px" }}>
        {!this.isAdmin() ? (
          <div>You are not Authorised here</div>
        ) : (
          <>
            <div style={{ margin: "10px 15px" }}>
              <h2>Add a Course</h2>
            </div>
            <hr />
            <div className="container-fluid background-white">
              <form onSubmit={(e) => this.saveCourse(e)}>
                <div>
                  <div className="mb-4">
                    <label>Course Title</label>
                    <input
                      type="text"
                      name="title"
                      required
                      value={this.state.courseData.title}
                      onChange={this.handleTitle}
                      className="border-primary py-2 w-50"
                      placeholder="Course Title"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="mb-1">Course Description</label>
                    <input
                      type="input"
                      required
                      value={this.state.courseData.description}
                      onChange={this.handleDescription}
                      className="border-primary py-2 w-50"
                      placeholder="Course Description"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="mb-1">Course Code</label>
                    <input
                      type="input"
                      requiredtextarea
                      value={this.state.courseData.code}
                      onChange={this.handleCode}
                      className="border-primary py-2 w-50"
                      placeholder="Course Code"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="mb-1">Assign Students</label>
                    <input
                      type="textarea"
                      required
                      value={this.state.courseData.students}
                      onChange={this.handleStudentsAssign}
                      className="border-primary py-2 w-50"
                      placeholder="Paste comma separated emails to assign"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="mb-1">Assign Teachers</label>
                    <input
                      type="textarea"
                      required
                      value={this.state.courseData.teachers}
                      onChange={this.handleTeachersAssign}
                      className="border-primary py-2 w-50"
                      placeholder="Paste comma separated emails to assign"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="mb-1">Assign Assistants</label>
                    <input
                      type="textarea"
                      required
                      value={this.state.courseData.tas}
                      onChange={this.handleAssistantsAssign}
                      className="border-primary py-2 w-50"
                      placeholder="Paste comma separated emails to assign"
                    />
                  </div>
                </div>
                <div className="mt-4 mb-4 mb-lg-5 d-flex">
                  <button
                    // disabled={saving}
                    type="submit"
                    className="flex items-center py-2 px-2 bg-emerald-500 text-white font-semibold"
                  >
                    {/* {saving && (
                    <Icon path={mdiLoading} className="spinner mr-2" size={1} />
                  )} */}
                    Save Course
                  </button>
                  <Link
                    to="/allcourses"
                    className="ml-3 d-flex align-items-center color-white background-blue "
                  >
                    Back to Courses
                  </Link>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(NewCreateCourse);
