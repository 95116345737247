/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import authHeader from "./auth-header";
import { BASE_URL } from "../assets/js/config";

export const createUser = (formValues) => {
  let profile = {};
  for (let user_key in formValues) {
    const profile_value = formValues[user_key];
    if (
      profile_value !== null &&
      profile_value !== "" &&
      profile_value !== undefined
    ) {
      profile[user_key] = profile_value;
    }
  }
  return axios
    .put(`${BASE_URL}api/user`, profile, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      console.log(res.data);
      // dispatch({
      //   type: "UPDATEFORMVALUES",
      //   payload: formValues,
      // });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const registerUser = async (form_data) => {
  return await axios
    .post(`${BASE_URL}api/user`, form_data)
    .then((res) => {
      if (res.data?.rzp_payment_link)
        window.location.href = res.data.rzp_payment_link;
      return res;
      // window.location.href = "/";
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

const getPublicContent = () => {
  return axios.get(BASE_URL);
};

const getAllUsers = (filter = {}) => {
  let query_arr = [];
  for (let key in filter) {
    query_arr.push(`${key}=${filter[key]}`);
  }
  const query_str = query_arr.join("&");
  return axios.get(`${BASE_URL}api/user?${query_str}`, {
    headers: authHeader(),
  });
};

// const getAssignedUsers = (id) => {
//   return axios.get(BASE_URL + "api/quizassign" + "?quiz=" + id, {
//     headers: authHeader(),
//   });
// };

// const putUserResponse = (id, response) => {
//   const body = { response: response };
//   return axios.put(BASE_URL + "api/quizassign/" + id, body, {
//     headers: authHeader(),
//   });
// };
const putUserReviewScore = (id, marking) => {
  const body = { marking: marking };
  return axios.put(BASE_URL + "api/quizassign/" + id, body, {
    headers: authHeader(),
  });
};
// const getOneResponse = (email) => {
//   return axios.get(BASE_URL + "api/quizassign/?email=" + email, {
//     headers: authHeader(),
//   });
// };
export const getDetails = () => {
  return axios
    .get(`${BASE_URL}api/user/me`, { headers: authHeader() })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const getUserSubscribedPlansService = () => {
  return axios
    .get(`${BASE_URL}api/plan/subscribed`, { headers: authHeader() })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

export const fetchProfile = () => {
  return axios
    .get(`${BASE_URL}api/user/me`, { headers: authHeader() })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

//Getting User details for Github signup/login
export const getUser = async (code) => {
  try {
    //First exchanging code for access token
    const accessTokenResponse = await axios.post(
      `${BASE_URL}api/user/getusertoken`,
      {
        code: code,
      }
    );
    if (accessTokenResponse.data.access_token) {
      //Then, using access token to get user details to be used during signup
      const userDataResponse = await axios.post(`${BASE_URL}api/user/getuser`, {
        token: accessTokenResponse.data.access_token,
      });
      //Using access token to get user email details to be used during signup
      const userEmailsResponse = await axios.post(
        `${BASE_URL}api/user/getuseremail`,
        {
          token: accessTokenResponse.data.access_token,
        }
      );

      const githubData = {
        userData: userDataResponse,
        userEmails: userEmailsResponse,
      };
      return githubData;
    } else {
      console.log("Did not get Access Token");
    }
  } catch (err) {
    console.log(err);
    throw Error(err.response.data);
  }
};

//Checking whether user exists for Github during Signup
export const checkUser = async (code) => {
  try {
    //First exchanging code for access token
    const accessTokenResponse = await axios.post(
      `${BASE_URL}api/user/getusertoken`,
      {
        code: code,
      }
    );
    if (accessTokenResponse.data.access_token) {
      //Using access token to get user email details to be used during signup
      const userDataResponse = await axios.post(`${BASE_URL}api/user/getuser`, {
        token: accessTokenResponse.data.access_token,
      });

      const userEmailsResponse = await axios.post(
        `${BASE_URL}api/user/getuseremail`,
        {
          token: accessTokenResponse.data.access_token,
        }
      );

      const checkUserResponse = await axios.post(
        `${BASE_URL}api/user/checkUser`,
        {
          email: JSON.parse(userEmailsResponse.data.text)[0].email,
        }
      );

      const githubData = {
        userData: userDataResponse,
        userEmails: userEmailsResponse,
        checkUser: checkUserResponse,
      };

      return githubData;
    } else {
      console.log("Did not get Access Token");
    }
  } catch (e) {
    console.log(e);
    throw Error(e.response.data);
  }
};

// const getAllTests = () => {
//   return axios.get(BASE_URL + "api/quiz", { headers: authHeader() });
// };

// const getOneTest = (id) => {
//   return axios.get(BASE_URL + "api/quiz/" + id, { headers: authHeader() });
// };

// const createOneTest = () => {
//   return axios.post(BASE_URL + "api/quiz", {}, { headers: authHeader() });
// };

// const saveOneTest = (data, id) => {
//   return axios.put(BASE_URL + "api/quiz/" + id, data, {
//     headers: authHeader(),
//   });
// };

// const deleteOneTest = (id) => {
//   return axios.delete(BASE_URL + "api/quiz/" + id, {
//     headers: authHeader(),
//   });
// };

// const assignEmail = (data, id) => {
//   return axios.put(
//     BASE_URL + "api/quizassign/assign/" + id,
//     { emails: data },
//     {
//       headers: authHeader(),
//     }
//   );
// };

// async function uploadFile(file, testId) {
//   let data = new FormData();
//   data.append("file", file);
//   data.append("type", "QUIZ");
//   data.append("id", testId);
//   return await axios.post(BASE_URL + "api/upload", data, {
//     headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
//   });
// }

export const updateUserApi = (data) => {
  return axios
    .put(`${BASE_URL}api/user`, data, { headers: authHeader() })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export default {
  getPublicContent,
  // getAssignedUsers,
  getAllUsers,
  updateUserApi,
  // putUserResponse,
  putUserReviewScore,
  // uploadFile,
  // getAllTests,
  // getOneTest,
  // getOneResponse,
  // saveOneTest,
  // createOneTest,
  // deleteOneTest,
  // assignEmail,
};
