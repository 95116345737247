import React from 'react'
import eye from "../../assets/img/svg/eye.svg";
import Tooltip from './Tooltip/Tooltip';


export default function UniversalCheckbox({ onChange, name, title, value, hovertext }) {
    return (
        <>
            <input
                className="h-4 w-4 mx-3"
                type="checkbox"
                name={name}
                role="switch"
                checked={value}
                onChange={(e) => onChange(!value)}
            />
            <label
                htmlFor={name}
                className="font-bold text-[1rem] font-['nunito']"
            >
                {title}
            </label>

            <Tooltip
                text={hovertext}
            >
                <img
                    src={eye}
                    alt="info"
                    className="ml-2 cursor-pointer"
                />
            </Tooltip>
        </>
    )
}
