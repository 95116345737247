import React from 'react'

export default function RangeBar({ step, height }) {
    return (
        <div className={`relative shadow-lg w-full h-[${height}px]  flex rounded-[29px] bg-[#fff] `}>
            <div className="flex items-center absolute w-full h-full">
                <div style={{ width: step + '%' }} className={`h-full transition-all ease-out duration-1000  ${step !== 100 ? "animate-pulse" : null} text-black font-bold font-raleway text-[10px] leading-[22px] text-center shadow-none rounded-[29px]   whitespace-nowrap  justify-center bg-[#B8FB3C] `}>
                    {step}%
                </div>
            </div>
        </div>
    )
}
