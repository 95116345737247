import React from "react";

const Dashboard = (props) => {
  return (
    <div className="flex-col md:p-8 p-3 sm:p-5 lg:p-10">
      <p className="text-2xl font-bold">Welcome {props.user.name}</p>
      {/* <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5 text-center pt-5">
        <div className="flex justify-between px-6 items-center shadow-md rounded p-3 mr-10 bg-purple-100">
          <p className="text-slate-600 text-xl px-2 font-medium">
            Total Applications
          </p>
          <p className="text-[30px] font-medium">100000</p>
        </div>
        <div className="flex justify-between px-6 items-center shadow-md rounded p-3 mr-10 bg-orange-100">
          <p className="text-slate-600 text-xl px-2 font-medium">Tests Done</p>
          <p className="text-[30px] font-medium">3000</p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5 text-center pt-5">
        <div className="flex justify-between px-6 items-center shadow-md rounded p-3 mr-10 bg-cyan-100">
          <p className="text-slate-600 text-xl px-2 font-medium">
            Applications Selected
          </p>
          <p className="text-[30px] font-medium">60000</p>
        </div>
        <div className="flex justify-between px-6 items-center shadow-md rounded p-3 mr-10 bg-yellow-100">
          <p className="text-slate-600 text-xl px-2 font-medium">
            Applications Rejected
          </p>
          <p className="text-[30px] font-medium">40000</p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5 text-center pt-5">
        <div className="flex justify-between px-6 items-center shadow-md rounded p-3 mr-10 bg-rose-100">
          <p className="text-slate-600 text-xl px-2 font-medium">
            Interview Invites Sent
          </p>
          <p className="text-[30px] font-medium">10000</p>
        </div>
        <div className="flex justify-between px-6 items-center shadow-md rounded p-3 mr-10 bg-indigo-100">
          <p className="text-slate-600 text-xl px-2 font-medium">
            Interview Declines Sent
          </p>
          <p className="text-[30px] font-medium">21000</p>
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
