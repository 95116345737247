import React, { Component } from "react";
import { formateTime } from "../utils/TimeFormat";

// Store
import { connect } from "react-redux";
import { fetchTestRequest } from "../../../store/actions/test";
import { withRouter } from "../../common/WithRouter";
import { toast } from "react-toastify";
import TestHeader from "./TestHeader";
import AssignStudents from "./AssignStudents";
import UniversalButton from "../../common/StandardButton/UniversalButton";

class AssignTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proctorAssignEffect: false,
    };
  }

  componentDidMount() {
    this.props.fetchTestRequest(this.props.params.id);
  }

  handleStudents = () => {
    this.setState({
      proctorAssignEffect: false,
    });
  };

  handleProctors = () => {
    this.setState({
      proctorAssignEffect: true,
    });
  };

  render() {
    const { proctorAssignEffect } = this.state;
    return (
      <>
        <div className="flex w-full justify-center items-center ">
          <div
            className="mt-5 rounded px-4 py-3 w-[99%] flex flex-col lg:flex-row justify-between"
            style={{
              boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
              background:
                "linear-gradient(91deg, #C6FB63 2.1%, #94E005 98.82%)",
            }}
          >
            <p className="text-[1.2rem] sm:text-[1.5rem] font-semibold font-['poppins']">
              Assign Test
            </p>
            <div className="flex gap-[1.5rem] items-center mt-2 lg:mt-0">
              <UniversalButton
                onClickFunction={this.handleStudents}
                title="Students"
                classNames={`w-auto py-2 ${
                  !proctorAssignEffect && "bg-[#001437] text-[#a0db32]"
                }`}
              />
              {this.props.is_proctoring ? (
                <UniversalButton
                  onClickFunction={this.handleProctors}
                  title="Proctors"
                  classNames={`w-auto py-2 ${
                    proctorAssignEffect && "bg-[#001437] text-[#a0db32]"
                  }`}
                />
              ) : null}
            </div>
          </div>
        </div>
        <p className="px-5 text-xl font-bold mt-[1.5rem] mb-1">
          {this.props.title}
          {` (${formateTime(this.props.start_duration)} to ${formateTime(
            this.props.end_duration
          )}) `}
        </p>
        <div className="flex-col justify-center items-center pb-4 px-4 gap-4">
          <hr className="py-[1px] rounded-full bg-[#BCFB46]" />
          {!proctorAssignEffect ? (
            <AssignStudents assignmentType="S" />
          ) : (
            <AssignStudents assignmentType="P" />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    test: test.test,
    title: test.test.title,
    start_duration: test.test.start_duration,
    end_duration: test.test.end_duration,
    is_proctoring: test.test.is_proctoring,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTestRequest: (data) => dispatch(fetchTestRequest(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssignTest));
