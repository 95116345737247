import React, { Component } from "react";
import micoff from "../../assets/img/svg/mic2off.svg";
import speakeroff from "../../assets/img/svg/speakeroff.svg";
import message from "../../assets/img/svg/message.svg";
import { ReactComponent as EyeIcon } from "../../assets/img/svg/info.svg";
import { ITEMS_IN_PAGE } from "./constants";
import { withRouter } from "../common/WithRouter";

class VideosMap extends Component {
  constructor() {
    super();
    this.state = {
      users: null,
    };
  }

  // componentDidMount() {
  //   const pageOffsetStart = (this.props.currentPage - 1) * ITEMS_IN_PAGE;
  //   const pageOffset = this.props.currentPage * ITEMS_IN_PAGE;
  //   this.setState({
  //     users: this.props.proctoredUsers.slice(pageOffsetStart, pageOffset),
  //   });
  // }

  // componentDidUpdate(prevProps) {
  //   if (this.props.currentPage === prevProps.currentPage) return;

  //   const pageOffsetStart = (this.props.currentPage - 1) * ITEMS_IN_PAGE;
  //   const pageOffset = this.props.currentPage * ITEMS_IN_PAGE;
  //   this.setState({
  //     users: this.props.proctoredUsers.slice(pageOffsetStart, pageOffset),
  //   });
  // }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.AllVideos.length !== nextProps.AllVideos.length) {
      return true;
    }

    return false;
  }

  render() {
    console.log("checking proctored users list of streams", this.props.proctoredUsers)
    return (
      <div
        className="flex w-full h-full flex-row gap-8 flex-wrap items-center justify-center sm:items-start sm:justify-start"
        id="allVideosContainer"
      >
        {this.props.proctoredUsers ? (
          this.props.proctoredUsers.map((eachStream, i) => {
            return (
              <VideoElem
                eachStream={this.props?.StreamsRef?.current?.[i]?.studentStream}
                index={i}
                key={`proctoring_video_${i}`}
                StreamsRef={this.props.StreamsRef}
                proctoredUsers={this.props.proctoredUsers}
                VideosRef={this.props.VideosRef}
                history={this.props.history}
                test_id={this.props.test_id}
                handleChat={this.props.handleChat}
                handleMute={this.props.handleMute}
                handleTeacherMute={this.props.handleTeacherMute}
                handleProctoringReportModal={
                  this.props.handleProctoringReportModal
                }
              />
            );
          })
        ) : (
          <div className="text-center w-full h-full flex justify-center items-center">
            <h5 className="text-[#EB4335] text-sm">
              There are no users! Please refresh.
            </h5>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(VideosMap);

class VideoElem extends Component {
  constructor() {
    super();
    this.state = {
      loader: true,
      warning: false,
    };
  }

  render() {
    const i = this.props.index;
    return this.props.StreamsRef?.current?.[i]?.id ? (
      <div
        key={`unique_${this.props.StreamsRef?.current?.[i]?.id} uniqueVideoDiv`}
        className={`div_${this.props.StreamsRef?.current?.[i]?.id} w-[230px] rounded-b-lg shadow-[0_4px_4px_rgba(0,0,0,0.25)] uniqueVideoDiv  flex flex-col justify-center items-center`}
      // style={{
      //   width: "230px",
      //   height: "230px",
      //   marginTop: "10px",
      //   boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      //   borderRadius: "8px",
      //   display: "flex",
      //   flexDirection: "column",
      //   alignItems: "center",
      //   position: "relative",
      // }}
      >
        {this.state.loader && (
          <div
            style={{
              width: "168px",
              height: "initial",
              borderRadius: "10px",
              margin: "10px",
              fontSize: "12px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="animate-pulse"
          >
            {/* <div className="dot-typing"></div> <br /> Loading video */}
            <div className="rounded-full mt-3 bg-gray-300 h-24 w-24"></div>
            {/* <div className="h-5 w-28 my-5 bg-gray-300 rounded md"></div>
            <div className="flex gap-3 mt-3 mb-5">
              <div className="h-5 w-5 bg-gray-300 rounded-md"></div>
              <div className="h-5 w-5 bg-gray-300 rounded-md"></div>
              <div className="h-5 w-5 bg-gray-300 rounded-md"></div>
              <div className="h-5 w-5 bg-gray-300 rounded-md"></div>
            </div> */}
          </div>
        )}
        <video
          key={i}
          // style={{ width: "175px", height: "125px", margin: "10px 0" }}
          // style={{
          //   width: "168px",
          //   height: "125px",
          //   borderRadius: "10px",
          //   margin: "10px",
          //   display: `${this.state.loader ? "none" : "block"}`,
          // }}
          className={`videoBox rounded-t-lg  ${this.state.loader ? "hidden" : "block"}`}
          ref={(VideoElem) => {
            if (VideoElem) {
              VideoElem.src = this.props.eachStream;
              this.props.VideosRef.current[i] = VideoElem;
            }
          }}
          autoPlay
          onLoadedData={() => {
            this.state.loader && this.setState({ loader: false });
          }}
          muted={true}
        />
        <>
          <p
            className="font-['poppins'] mt-1 w-full bg-[#e3f7be] text-[#001437] text-center break-all py-1"
            style={{
              fontSize: `${this.props.proctoredUsers.find(
                (eachUser) =>
                  eachUser.peerid === this.props?.StreamsRef?.current?.[i]?.id
              )?.["name"].length > 15
                ? "14px"
                : "1rem"
                }`,
            }}
          >
            {`${this.props.proctoredUsers.find(
              (eachUser) =>
                eachUser.peerid === this.props?.StreamsRef?.current?.[i]?.id
            )?.["name"]
              }`}
          </p>
          <div
            className={`flex flex-row items-center justify-center gap-2 py-[${this.props.proctoredUsers.find(
              (eachUser) =>
                eachUser.peerid === this.props?.StreamsRef?.current?.[i]?.id
            )?.["name"].length > 15
              ? "0rem"
              : "0.3rem"
              }]`}
          // style={{
          //   paddingX: `${this.props.proctoredUsers.find(
          //     (eachUser) =>
          //       eachUser.peerid === this.props?.StreamsRef?.current?.[i]?.id
          //   )?.["name"].length > 15
          //     ? "0rem"
          //     : "0.75rem"
          //     }`,
          // }}
          >
            <button
              className={`mic_${this.props?.StreamsRef?.current?.[i]?.id} cursor-pointer hover:bg-gray-100 p-2 rounded-md`}
              onClick={() =>
                this.props.handleTeacherMute(
                  this.props.StreamsRef?.current?.[i]?.id
                )
              }
            >
              {/* <MicOffIcon
                alt="Micoff"
                title="Mic off"
                className="w-6 h-6"
                id={`mic_${this.props.StreamsRef.current?.[i]?.id}`}
              /> */}
              {/* <Micoff title="Micoff" className="w-6 h-6" id={`mic_${this.props.StreamsRef.current?.[i]?.id}`} /> */}
              <img
                // onClick={() =>
                //   callStudent(this.props.StreamsRef?.current[i]?.id)
                // }
                src={micoff}
                alt="Micoff"
                title="Mic off"
                className="w-6 h-6"
                id={`mic_${this.props.StreamsRef.current?.[i]?.id}`}
              />
            </button>
            <button
              className={`mute_${this.props.StreamsRef?.current?.[i]?.id} cursor-pointer hover:bg-gray-100 p-2 rounded-md`}
              onClick={() =>
                this.props.handleMute(this.props.StreamsRef?.current?.[i]?.id)
              }
            >
              {/* <Speakeroff alt="speakeroff"
                title="Unmute this student" className="w-6 h-6"
                id={`muteImg_${this.props.StreamsRef.current?.[i]?.id}`} /> */}
              <img
                src={speakeroff}
                alt="speakeroff"
                title="Unmute this student"
                className="w-6 h-6"
                id={`muteImg_${this.props.StreamsRef.current?.[i]?.id}`}
              />
            </button>
            <button
              className={`chat_${this.props.StreamsRef.current?.[i]?.id} relative cursor-pointer  hover:bg-gray-100 p-2 rounded-md`}
              onClick={() =>
                this.props.handleChat(this.props.StreamsRef.current?.[i]?.id)
              }
            >
              <div
                className={`notify notify_${this.props.StreamsRef?.current?.[i]?.id}`}
              ></div>
              {/* <Message alt="chat"
                title="Send message"
                className="w-6 h-6" /> */}
              <img
                src={message}
                alt="chat"
                title="Send message"
                className="w-6 h-6"
              />
            </button>
            <button
              className={`details_${this.props.StreamsRef.current?.[i]?.id} cursor-pointer hover:bg-gray-100 p-2 rounded-md`}
              onClick={() => {
                localStorage.setItem(
                  "student-peer",
                  this.props.StreamsRef.current?.[i]?.id
                );
                this.props.handleProctoringReportModal(
                  this.props.StreamsRef.current?.[i]?.id
                );
                // this.props.history(
                //   `/tests/proctoring/${this.props.test_id}`
                // );
              }}
            >
              <EyeIcon
                className="w-6 h-6"
                alt="view"
                title="Proctoring"
                fill="#BCFB46"
              />
            </button>
          </div>
        </>
        {/* )} */}

        {this.state.warning ? (
          <div className="absolute top-[5px] right-[5px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="yellow"
              viewBox="0 0 24 24"
              stroke="currentCOlor"
              strokeWidth={1}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
        ) : null}
      </div>
    ) : null;
  }
}

export const VideoElem_withRouter = withRouter(VideoElem);
