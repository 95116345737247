export const UPDATE_TEST_RESPONSE = "UPDATE_TEST_RESPONSE";

export const UPDATE_USER_BANDWIDTH = "UPDATE_USER_BANDWIDTH";

export const FETCH_TEST_REQUESTED = "FETCH_TEST_REQUESTED";

export const FETCH_TEST_SUCCESS = "FETCH_TEST_SUCCESS";

export const UPDATE_TEST_DATA = "UPDATE_TEST_DATA";

export const FETCH_TEST_FAILURE = "FETCH_TEST_FAILURE";

export const UPDATE_TEST_LOADING = "UPDATE_TEST_LOADING";

export const UPDATE_TEST_STARTER_CARD_ACTIVE =
  "UPDATE_TEST_STARTER_CARD_ACTIVE";

export const SUBMIT_TEST_REQUESTED = "SUBMIT_TEST_REQUESTED";

export const SUBMIT_TEST_SUCCESS = "SUBMIT_TEST_SUCCESS";

export const SUBMIT_TEST_FAILURE = "SUBMIT_TEST_FAILURE";

export const UPDATE_TEST_ACCORDIAN_INDEX = "UPDATE_TEST_ACCORDIAN_INDEX";

export const UPDATE_TEST_ACCORDIAN_PAGE_INDEX =
  "UPDATE_TEST_ACCORDIAN_PAGE_INDEX";

export const UPDATE_TEST_SECTION_SUBMIT_MODAL_ACTIVE =
  "UPDATE_TEST_SECTION_SUBMIT_MODAL_ACTIVE";

export const UPDATE_TEST_SUBMITTED_SECTIONS_COUNT =
  "UPDATE_TEST_SUBMITTED_SECTIONS_COUNT";

export const UPDATE_TEST_MAIN_DATA_WITH_KEY = "UPDATE_TEST_MAIN_DATA_WITH_KEY";

export const UPDATE_TEST_DELETE_MODAL_ACTIVE =
  "UPDATE_TEST_DELETE_MODAL_ACTIVE";

export const DELETE_TEST_REQUESTED = "DELETE_TEST_REQUESTED";

export const DELETE_TEST_SUCCESS = "DELETE_TEST_SUCCESS";

export const DELETE_TEST_FAILURE = "DELETE_TEST_FAILURE";

export const UPDATE_TEST_SECTION_DATA_WITH_KEY =
  "UPDATE_TEST_SECTION_DATA_WITH_KEY";

export const DELETE_TEST_SECTION = "DELETE_TEST_SECTION";

export const DUPLICATE_TEST_SECTION = "DUPLICATE_TEST_SECTION";

export const ADD_TEST_SECTION = "ADD_TEST_SECTION";

export const UPDATE_TEST_QUESTION_DATA_WITH_KEY =
  "UPDATE_TEST_QUESTION_DATA_WITH_KEY";

export const ADD_TEST_QUESTION = "ADD_TEST_QUESTION";

export const DUPLICATE_TEST_QUESTION = "DUPLICATE_TEST_QUESTION";

export const DELETE_TEST_QUESTION = "DELETE_TEST_QUESTION";

export const ASSIGN_TEST_REQUESTED = "ASSIGN_TEST_REQUESTED";

export const ASSIGN_TEST_SUCCESS = "ASSIGN_TEST_SUCCESS";

export const ASSIGN_TEST_FAILURE = "ASSIGN_TEST_FAILURE";

export const ASSIGN_TEST_PROCTOR_REQUESTED = "ASSIGN_TEST_PROCTOR_REQUESTED";

export const ASSIGN_TEST_PROCTOR_SUCCESS = "ASSIGN_TEST_PROCTOR_SUCCESS";

export const ASSIGN_TEST_PROCTOR_FAILURE = "ASSIGN_TEST_PROCTOR_FAILURE";

export const SET_USER_SCREENSTREAM = "SET_USER_SCREENSTREAM";

export const SET_USER_VIDEOSTREAM = "SET_USER_VIDEOSTREAM";

export const IS_VIDEO = "IS_VIDEO";

export const IS_AUDIO = "IS_AUDIO";

export const IS_SCREEN = "IS_SCREEN";

export const FETCH_ALL_TESTS = "FETCH_ALL_TESTS";

export const FETCH_ALL_TESTS_SUCCESS = "FETCH_ALL_TESTS_SUCCESS";

export const FETCH_ALL_TESTS_FAILURE = "FETCH_ALL_TESTS_FAILURE";

export const FETCH_STUDENT_RESPONSE = "FETCH_STUDENT_RESPONSE";

export const FETCH_STUDENT_RESPONSE_SUCCESS = "FETCH_STUDENT_RESPONSE_SUCCESS";

export const FETCH_STUDENT_RESPONSE_FAILURE = "FETCH_STUDENT_RESPONSE_FAILURE";

export const FETCH_QUIZ_PROCTORING_DETAILS_FAILURE =
  "FETCH_QUIZ_PROCTORING_DETAILS_FAILURE";

export const FETCH_QUIZ_PROCTORING_DETAILS = "FETCH_QUIZ_PROCTORING_DETAILS";

export const FETCH_QUIZ_PROCTORING_DETAILS_SUCCESS =
  "FETCH_QUIZ_PROCTORING_DETAILS_SUCCESS";

export const UPDATE_CURRENT_QUESTION_INDEX = "UPDATE_CURRENT_QUESTION_INDEX";

export const UPDATE_CURRENT_SECTION_INDEX = "UPDATE_CURRENT_SECTION_INDEX";

export const FETCH_TEST_AUTO = "FETCH_TEST_AUTO";

export const FETCH_TEST_AUTO_SUCCESS = "FETCH_TEST_AUTO_SUCCESS";

export const FETCH_TEST_AUTO_FAILURE = "FETCH_TEST_AUTO_FAILURE";

export const FETCH_TEST_SCORE_REQUESTED = "FETCH_TEST_SCORE_REQUESTED";

export const FETCH_TEST_SCORE_SUCCESS = "FETCH_TEST_SCORE_SUCCESS";

export const FETCH_TEST_SCORE_FAILURE = "FETCH_TEST_SCORE_FAILURE";

export const SET_TEST_STATUS = "SET_TEST_STATUS";

export const DELETE_RESPONSE_REQUESTED = "DELETE_RESPONSE_REQUESTED";

export const DELETE_RESPONSE_SUCCESS = "DELETE_RESPONSE_SUCCESS";

export const DELETE_RESPONSE_FAILURE = "DELETE_RESPONSE_FAILURE";

export const FETCH_COMPANIES_REQUESTED = "FETCH_COMPANIES_REQUESTED";

export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";

export const FETCH_TEST_ASSIGN_REQUESTED = "FETCH_TEST_ASSIGN_REQUESTED";

export const FETCH_TEST_ASSIGN_SUCCESS = "FETCH_TEST_ASSIGN_SUCCESS";

export const FETCH_TEST_ASSIGN_FAILURE = "FETCH_TEST_ASSIGN_FAILURE";

export const UPDATE_TEST_ASSIGN = "UPDATE_TEST_ASSIGN";

export const SUBMIT_FEEDBACK_REQUESTED = "SUBMIT_FEEDBACK_REQUESTED";

export const SUBMIT_FEEDBACK_FAILURE = "SUBMIT_FEEDBACK_FAILURE";

export const SUBMIT_FEEDBACK_SUCCESS = "SUBMIT_FEEDBACK_SUCCESS";

export const SEND_EMAILS_REQUESTED = "SEND_EMAILS_REQUESTED";

export const SEND_EMAILS_SUCCESS = "SEND_EMAILS_SUCCESS";

export const SEND_EMAILS_FAILURE = "SEND_EMAILS_FAILURE";

export const SEND_TEST_INVITE_EMAILS_REQUESTED =
  "SEND_TEST_INVITE_EMAILS_REQUESTED";

export const SEND_TEST_INVITE_EMAILS_SUCCESS =
  "SEND_TEST_INVITE_EMAILS_SUCCESS";

export const SEND_TEST_INVITE_EMAILS_FAILURE =
  "SEND_TEST_INVITE_EMAILS_FAILURE";

export const SEND_REMINDER_EMAILS_REQUESTED = "SEND_REMINDER_EMAILS_REQUESTED";

export const SEND_REMINDER_EMAILS_SUCCESS = "SEND_REMINDER_EMAILS_SUCCESS";

export const SEND_REMINDER_EMAILS_FAILURE = "SEND_REMINDER_EMAILS_FAILURE";

export const REARRANGE_ALL_TESTS = "REARRANGE_ALL_TESTS";

export const DELETE_GROUP_DETAILS_REQUESTED = "DELETE_GROUP_DETAILS_REQUESTED";

export const DELETE_GROUP_DETAILS_SUCCESS = "DELETE_GROUP_DETAILS_SUCCESS";

export const ADD_GROUP_DETAILS_REQUESTED = "ADD_GROUP_DETAILS_REQUESTED";

export const ADD_GROUP_DETAILS_SUCCESS = "ADD_GROUP_DETAILS_SUCCESS";

export const UPDATE_GROUP_DETAILS_REQUESTED = "UPDATE_GROUP_DETAILS_REQUESTED";

export const UPDATE_GROUP_DETAILS_SUCCESS = "UPDATE_GROUP_DETAILS_SUCCESS";

export const FETCH_ALL_GROUP_DETAILS_REQUESTED =
  "FETCH_ALL_GROUP_DETAILS_REQUESTED";

export const FETCH_ALL_GROUP_DETAILS_SUCCESS =
  "FETCH_ALL_GROUP_DETAILS_SUCCESS";

export const SET_SUBMISSION_RESPONSE = "SET_SUBMISSION_RESPONSE";

export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";

export const UPDATE_PUBLIC_TEST_MODAL = "UPDATE_PUBLIC_TEST_MODAL";

export const UPDATE_PUBLIC_TEST_STUDENT_DETAILS =
  "UPDATE_PUBLIC_TEST_STUDENT_DETAILS";

export const UPDATE_PUBLIC_TEST_REGISTERED_DETAILS =
  "UPDATE_PUBLIC_TEST_REGISTERED_DETAILS";

export const UPDATE_OVERALL_RANK_REQUESTED = "UPDATE_OVERALL_RANK_REQUESTED";

export const FETCH_STUDENT_RESPONSE_FROM_REDIS =
  "FETCH_STUDENT_RESPONSE_FROM_REDIS";
export const FETCH_STUDENT_RESPONSE_FAILURE_FROM_REDIS =
  "FETCH_STUDENT_RESPONSE_FAILURE_FROM_REDIS";
export const FETCH_STUDENT_RESPONSE_SUCCESS_FROM_REDIS =
  "FETCH_STUDENT_RESPONSE_SUCCESS_FROM_REDIS";

export const CREATE_STUDENT_SCHEMA = "CREATE_STUDENT_SCHEMA";

export const UPDATE_QUESTION_BANK_MODAL = "UPDATE_QUESTION_BANK_MODAL";

export const UPDATE_QUESTION_FROM_QUESTIONBANK =
  "UPDATE_QUESTION_FROM_QUESTIONBANK";

export const UPDATE_QUESTION_BANK_QUESTIONS = "UPDATE_QUESTION_BANK_QUESTIONS";

export const UPDATE_AUDIO_RECORDING_STATUS = "UPDATE_AUDIO_RECORDING_STATUS";

export const UPDATE_YTVIDEO_RECORDING_STATUS =
  "UPDATE_YTVIDEO_RECORDING_STATUS";

export const UPDATE_TEST_DATA_ON_ARRANGE = "UPDATE_TEST_DATA_ON_ARRANGE";

export const UPDATE_TEST_END_TIME_REQUESTED = "UPDATE_TEST_END_TIME_REQUESTED";

export const UPDATE_TEST_END_TIME_SUCCESS = "UPDATE_TEST_END_TIME_SUCCESS";

export const UPDATE_TEST_END_TIME_FAILURE = "UPDATE_TEST_END_TIME_FAILURE";

export const CLONE_TEST_REQUESTED = "CLONE_TEST_REQUESTED";
export const CLONE_TEST_SUCCESS = "CLONE_TEST_SUCCESS";
export const CLONE_TEST_FAILURE = "CLONE_TEST_FAILURE";

export const UPDATING_CUTOFF_REQUESTED = "UPDATING_CUTOFF_REQUESTED";
export const UPDATING_CUTOFF_SUCCESS = "UPDATING_CUTOFF_SUCCESS";
export const UPDATING_CUTOFF_FAILURE = "UPDATING_CUTOFF_FAILURE";
