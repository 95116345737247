import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  updateStarterCardActive,
  setTestStatus,
  updateBandWidth,
} from "../../../store/actions/test";
import cam_permission from "../../../assets/systemCheck/cam_permission.png";
import permission_1 from "../../../assets/systemCheck/permission_denied.png";
import permission_2 from "../../../assets/systemCheck/permission_denied_2.png";
import screen_share from "../../../assets/systemCheck/entire_screen.png";
import { toggleSidebar } from "../../../store/actions/sidebar";
import { ReactComponent as CameraIcon } from "../../../assets/img/svg/cam1.svg";
import { ReactComponent as CameraOffIcon } from "../../../assets/img/svg/cam1off.svg";
import { ReactComponent as MicIcon } from "../../../assets/img/svg/mic2on.svg";
import { ReactComponent as MicOffIcon } from "../../../assets/img/svg/mic2off.svg";
import { ReactComponent as DesktopIcon } from "../../../assets/img/svg/desktop.svg";
import { ReactComponent as DesktopOffIcon } from "../../../assets/img/svg/desktop-slash.svg";
import { ReactComponent as Time } from "../../../assets/img/svg/Time.svg";
import { ReactComponent as ServerIcon } from "../../../assets/img/svg/server.svg";
import { ReactComponent as ArrowUpIcon } from "../../../assets/img/svg/arrow_up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../assets/img/svg/arrow_down.svg";
import tick from "../../../assets/img/svg/tick.png";
import cross from "../../../assets/img/svg/cross.png";
import load from "../../../assets/img/svg/load.svg";
import TestHeader from "../createTest/TestHeader";
import KyronSideLetter from "../../../assets/img/Kyron/kyron_logo.png";

// import NetworkSpeed from "network-speed";
import { withRouter } from "../../common/WithRouter";
// import ErrorBoundary from "../../common/ErrorBoundary";

// const testNetworkSpeed = new NetworkSpeed();

class CheckYourMetrics extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.screenRef = React.createRef();
    this.localStream = null;
    this.localScreenStream = null;
    this.handleDropdown = this.handleDropdown.bind(this);
    this.state = {
      audioInputDevices: [],
      audioOutputDevices: [],
      videoInputDevices: [],
      isScreenShared: false,
      isVideo: false,
      errorMessage: "",
      bandWidth: 0,
      isVideoVerified: false,
      isScreenVerified: false,
      isBandwidthVerified: false,
      isEntireScreenShared: false,
      currentAudioInputDevice: null,
      currentAudioOutputDevice: null,
      userTimeZone: null,
      faqsDropDown: [
        {
          status: false,
          heading: "How to allow camera and microphone permissions?",
          points: [
            {
              img: cam_permission,
              link: "",
              link_text: "",
              line_text:
                "Click on allow to give permission for both camera and audio.",
            },
          ],
          id: 0,
        },
        {
          status: false,
          heading: "How to share your entire screen?",
          points: [
            {
              img: screen_share,
              link: "",
              link_text: "",
              line_text:
                "Make sure you selected the entire screen tab as shown above.",
            },
            {
              img: null,
              link: "",
              link_text: "",
              line_text: "Now select the screen and click on Share button",
            },
          ],
          id: 1,
        },
        {
          status: false,
          heading: "How to resolve camera permission denied issue?",
          points: [
            {
              img: permission_1,
              link: "",
              link_text: "",
              line_text:
                "Click on the red cross video icon as shown above at the top right of the current site url.",
            },
            {
              img: permission_2,
              link: "",
              link_text: "",
              line_text: "Select the always allow option as shown above.",
            },
            {
              img: null,
              link: "",
              link_text: "",
              line_text: "Now click on save and refresh your page.",
            },
          ],
          id: 2,
        },
        {
          status: false,
          heading: "Troubleshoot your camera problems",
          points: [
            {
              img: null,
              link: "",
              link_text: "",
              line_text:
                "Make sure you are using Google Chrome with latest version (99 or greater).",
            },
            {
              img: null,
              link: "",
              link_text: "",
              line_text:
                "Make sure you must disable all your extensions before starting the test.",
            },
            {
              img: null,
              link: "",
              link_text: "",
              line_text:
                "Make sure your camera is not being used in any other application.",
            },
            {
              img: null,
              link: "https://support.google.com/meet/answer/10621292?hl=en",
              link_text: "Link",
              line_text: "Check this link if you are still facing some issues ",
            },
          ],
          id: 3,
        },
        {
          status: false,
          heading: "Troubleshoot your audio problems",
          points: [
            {
              img: null,
              link: "",
              link_text: "",
              line_text:
                "Make sure you are using Google Chrome with latest version (99 or greater).",
            },
            {
              img: null,
              link: "",
              link_text: "",
              line_text:
                "Make sure you must disable all your extensions before starting the test.",
            },
            {
              img: null,
              link: "",
              link_text: "",
              line_text:
                "Make sure your microphone is not being used in any other application.",
            },
            {
              img: null,
              link: "https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DAndroid",
              link_text: "Link",
              line_text: "Check this link if you are still facing some issues",
            },
          ],
          id: 4,
        },
        {
          status: false,
          heading: "Troubleshoot your screen sharing problems",
          points: [
            {
              img: null,
              link: "",
              link_text: "",
              line_text:
                "Make sure you are using Google Chrome with latest version (99 or greater).",
            },
            {
              img: null,
              link: "",
              link_text: "",
              line_text:
                "Make sure you must disable all your extensions before starting the test.",
            },
            {
              img: null,
              link: "",
              link_text: "",
              line_text:
                "Make sure your ENTIRE screen is shared while starting the test.",
            },
            {
              img: null,
              link: "https://support.google.com/meet/answer/10621292?hl=en",
              link_text: "Link",
              line_text: "Check this link if you are still facing some issues",
            },
          ],
          id: 5,
        },
        {
          status: false,
          heading: "Troubleshoot your bandwidth problems",
          points: [
            {
              img: null,
              link: "",
              link_text: "",
              line_text:
                "Make sure you have a stable Good Internet connection atleast 5 MBPS or greater.",
            },
          ],
          id: 6,
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.sidebar.opened) {
      this.props.toggleSidebar();
    }
    this.props.setTestStatus(true);

    //finding the timezone fo the user
    this.getUserTimeZone();


    // this.setMediaDevices();
    const getDownloadSpeed = async () => {
      const baseUrl = "https://eu.httpbin.org/stream-bytes/500000";
      const fileSizeInBytes = 500000;
      // try {
      //   const speed = await testNetworkSpeed.checkDownloadSpeed(
      //     baseUrl,
      //     fileSizeInBytes
      //   );
      //   return speed.mbps;
      // } catch (err) {
      //   console.log("tryerror", err);
      // }
      return 0;
    };
    this.timer = setInterval(() => {
      // console.log("System Status", navigator.onLine);
      if (navigator.onLine) {
        getDownloadSpeed()
          .then((res) =>
            this.setState({ bandWidth: res, isBandwidthVerified: true })
          )
          .catch((err) => {
            // console.log("error", err);
            this.setState({ bandWidth: 0, isBandwidthVerified: true });
          });
      } else {
        this.setState({ bandWidth: 0, isBandwidthVerified: true });
      }
    }, 1000);
    this.shareVideo();
  }

  getUserTimeZone = () => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log("timexone", userTimeZone)
    this.setState(prev => {
      return {
        ...prev,
        userTimeZone
      }
    })
  };

  setMediaDevices() {
    navigator.mediaDevices
      .enumerateDevices()
      .then((res) => this.gotDevices(res))
      .catch((err) => console.log("err", err));
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.videoInputDevices.length === 0) {
      navigator.permissions.query({ name: "camera" }).then((res) => {
        if (res.state === "granted") {
          this.setMediaDevices();
        }
      });
    }
    if (
      prevState.currentVideoDevice !== this.state.currentVideoDevice ||
      prevState.currentAudioInputDevice !==
      this.state.currentAudioInputDevice ||
      prevState.currentAudioOutputDevice !== this.state.currentAudioOutputDevice
    ) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            height: { exact: 144 },
            aspectRatio: 1.67777,
            deviceId: this.state.currentVideoDevice,
          },
          audio: {
            deviceId: this.state.currentAudioInputDevice,
            noiseSuppression: true,
            echoCancellation: true,
          },
        })
        .then((stream) => {
          this.setState((prev) => {
            return {
              ...prev,
              isVideo: true,
            };
          });
          this.localStream = stream;
          this.videoRef.current.srcObject = stream;
        })
        .catch((err) => {
          // console.log(err);
          this.setState((prevState) => {
            return {
              ...prevState,
              errorMessage: err.message,
            };
          });
        });
    }
  };

  async shareVideo() {
    this.shareScreen();
    await navigator.mediaDevices
      .getUserMedia({
        video: {
          height: { exact: 144 },
          deviceId: this.state.currentVideoDevice
            ? { exact: this.state.currentVideoDevice }
            : undefined,
        },
        audio: {
          deviceId: this.state.currentAudioInputDevice
            ? { exact: this.state.currentAudioInputDevice }
            : undefined,
          noiseSuppression: true,
          echoCancellation: true,
        },
      })
      .then((stream) => {
        this.setState((prev) => {
          return {
            ...prev,
            isVideo: true,
            isVideoVerified: true,
          };
        });
        this.localStream = stream;
        this.videoRef.current.srcObject = stream;
      })
      .catch((err) => {
        console.log(err);
        this.setState((prevState) => {
          return {
            ...prevState,
            errorMessage: err.message,
            isVideoVerified: true,
          };
        });
      });
  }

  async shareScreen() {
    await navigator.mediaDevices
      .getDisplayMedia()
      .then(async (screenStream) => {
        if (
          screenStream.getVideoTracks()[0].getSettings().displaySurface ===
          "monitor"
        ) {
          this.setState({
            isEntireScreenShared: true,
            isScreenShared: true,
            isScreenVerified: true,
          });
        } else {
          this.setState({
            isEntireScreenShared: false,
            isScreenShared: true,
            isScreenVerified: true,
          });
        }
        this.localScreenStream = screenStream;
        this.screenRef.current.srcObject = screenStream;
      })
      .catch((err) =>
        this.setState({
          isScreenVerified: true,
          ScreenErrorMessage: err.message,
        })
      );
  }

  gotDevices(deviceInfos) {
    const audioInputDevices = deviceInfos.filter(
      (eachDevice) => eachDevice.kind === "audioinput"
    );
    const audioOutputDevices = deviceInfos.filter(
      (eachDevice) => eachDevice.kind === "audiooutput"
    );
    const videoDevices = deviceInfos.filter(
      (eachDevice) => eachDevice.kind === "videoinput"
    );

    this.setState({
      audioInputDevices,
      audioOutputDevices,
      videoInputDevices: videoDevices,
    });

    deviceInfos.map((eachDevice) => {
      if (eachDevice.kind === "audioinput") {
        if (eachDevice.deviceId === "default") {
          this.handleAudioInputChange(eachDevice.deviceId);
        }
      } else if (eachDevice.kind === "audiooutput") {
        if (eachDevice.deviceId === "default") {
          this.handleAudioOutputChange(eachDevice.deviceId);
        }
      } else {
        if (this.state.currentVideoDevice === null) {
          this.handleVideoChange(eachDevice.deviceId);
        }
      }
    });
  }

  handleToast = (msg) => {
    toast.warning(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  attachSinkId(element, sinkId) {
    if (typeof element.sinkId !== "undefined") {
      element
        .setSinkId(sinkId)
        .then(() => {
          console.log(`Success, audio output device attached: ${sinkId}`);
        })
        .catch((error) => {
          let errorMessage = error;
          if (error.name === "SecurityError") {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          console.error(errorMessage);
          // Jump back to first output device in the list as it's the default.
          // audioOutputSelect.selectedIndex = 0;
        });
    } else {
      console.warn("Browser does not support output device selection.");
    }
  }

  handleAudioInputChange = (id) => {
    this.setState({ currentAudioInputDevice: id });
  };
  handleAudioOutputChange = (id) => {
    this.setState({ currentAudioOutputDevice: id });
  };
  handleVideoChange = (id) => {
    this.setState({ currentVideoDevice: id });
  };

  componentWillUnmount() {
    clearInterval(this.timer);
    if (this.localStream) {
      this.localStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    if (this.localScreenStream) {
      this.localScreenStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  handleDropdown = (index) => {
    this.setState((prev) => {
      return {
        ...prev,
        faqsDropDown: [
          ...prev.faqsDropDown.map((faq) => {
            if (faq.id === index) {
              return {
                ...faq,
                status: !faq.status,
              };
            }
            return {
              ...faq,
              status: false,
            };
          }),
        ],
      };
    });
  };

  allChecksPassed = () =>
    this.state.isVideo &&
    this.state.isEntireScreenShared &&
    this.state.isScreenShared &&
    this.state.bandWidth;

  render() {
    return (
      //   <ErrorBoundary>
      <div className={`flex flex-col h-100`}>
        <nav className="flex justify-between items-center px-4 py-2 border-b border-border fixed w-[100%] z-[999999] bg-white drop-shadow-[2px_0px_2px_rgba(0,0,0,0.25)]">
          <img
            className="h-[40px] object-contain"
            src={KyronSideLetter}
            title="Logo"
            alt="kyron"
          />
          <TestHeader
            title="Check Your System Campatibility"
            styles="text-start"
          />
        </nav>
        <div className="flex rounded-md bg-white flex-col items-center font-['poppins']">
          <h4 className="text-center font-['nunito'] text-xl text-[#666666] mt-20 ">
            {this.allChecksPassed() ? (
              <span className="text-[#a0db32] font-bold">
                {" "}
                All checks have been passed successfully. Your system is
                compatible for the test.
              </span>
            ) : (
              "We are verifying your device..."
            )}
          </h4>

          <div
            className="flex flex-col bg-white  lg:grid-cols-2 gap-8
          md:grid-rows justify-center items-center sm:mx-5 mx-2"
          >
            {/* Video and screen sharing */}
            <div className="flex flex-col md:flex-row mt-6 gap-5 justify-center items-center ">
              {this.state.isVideo ? (
                <>
                  <video
                    style={{ height: "220px" }}
                    className="rounded-md shadow shadow-md"
                    ref={this.videoRef}
                    autoPlay
                    muted
                  ></video>
                </>
              ) : (
                <div className="flex w-[367px] h-[220px] gap-2 text-red-800 justify-center items-center">
                  {this.state.errorMessage === "" ? (
                    <p>...Retrieving Metrics</p>
                  ) : (
                    <p>
                      {this.state.errorMessage}. Please enable your camera and
                      stop using your camera in other applications
                    </p>
                  )}
                  {/* <img
                      alt="loading"
                      src={load}
                      className="spinner mr-2 w-6 h-6 text-primary "
                    /> */}
                  <div className="mx-4">
                    <div className="dot-typing"></div>
                  </div>
                </div>
              )}
              {this.state.isScreenShared ? (
                <>
                  <video
                    style={{ height: "220px" }}
                    className="rounded-md shadow shadow-md"
                    ref={this.screenRef}
                    autoPlay
                    muted
                  ></video>
                </>
              ) : (
                <div className="flex w-[367px] h-[220px] gap-2 text-red-800 justify-center items-center">
                  {!this.state.isScreenVerified ? (
                    <p>...Retrieving Metrics</p>
                  ) : (
                    <p>{this.state.ScreenErrorMessage}</p>
                  )}
                  {/* <img
                      alt="loading"
                      src={load}
                      className="spinner mr-2 w-6 h-6 text-primary "
                    /> */}
                  <div className="mx-4">
                    <div className="dot-typing"></div>
                  </div>
                </div>
              )}
            </div>
            {/* Device options and Matrics */}
            <div className="flex flex-col md:flex-row  px-5 gap-5 mt-3 justify-center bg-white  items-center">
              {/* Device switch option */}
              <div className="flex flex-col w-full justify-center items-center gap-3 ">
                <div className=" h-35 border w-96 p-2 rounded shadow-md">
                  <p className="text-xl font-semibold py-1 font-['nunito']">
                    Microphone
                  </p>
                  <p>Select your microphone</p>
                  <select
                    id="audioInputSelect"
                    className="w-full rounded border p-1 my-1 border-slate-300"
                    value={this.state.currentAudioInputDevice || ""}
                    onChange={(e) =>
                      this.handleAudioInputChange(e.target.value)
                    }
                  >
                    {this.state.audioInputDevices.map((eachDevice) => {
                      return (
                        <option
                          value={eachDevice.deviceId}
                          key={eachDevice.deviceId}
                        >
                          {eachDevice.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="h-35 border w-96 p-2 rounded shadow-md">
                  <p className="text-xl font-semibold py-1 font-['nunito']">
                    Camera
                  </p>
                  <p>Select your camera</p>
                  <select
                    id="videoSelect"
                    className="w-full border  p-1 my-1 border-slate-300"
                    value={this.state.currentVideoDevice || ""}
                    onChange={(e) => this.handleVideoChange(e.target.value)}
                  >
                    {this.state.videoInputDevices.map((eachDevice) => {
                      return (
                        <option
                          value={eachDevice.deviceId}
                          key={eachDevice.deviceId}
                        >
                          {eachDevice.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="h-35 w-96 border p-2 rounded shadow-md">
                  <p className="text-xl font-semibold py-1 font-['nunito']">
                    Speaker
                  </p>
                  <p>Select your speaker</p>
                  <select
                    id="audioOutputSelect"
                    className="w-full border  p-1 my-1 border-slate-300"
                    value={this.state.currentAudioOutputDevice || ""}
                    onChange={(e) =>
                      this.handleAudioOutputChange(e.target.value)
                    }
                  >
                    {this.state.audioOutputDevices.map((eachDevice) => {
                      return (
                        <option
                          id={eachDevice.deviceId}
                          value={eachDevice.deviceId}
                          key={eachDevice.deviceId}
                        >
                          {eachDevice.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {/* Matrics */}
              <div className="flex flex-col w-full gap-y-2 justify-start items-start">
                {/* Video */}
                <div className="flex w-full gap-x-5 items-center justify-start">
                  <div
                    className={`flex-col w-16 flex justify-start items-center p-2  rounded-md
                    }`}
                  >
                    {this.state.isVideo ? (
                      <CameraIcon
                        title="Student video on"
                        alt="camera on"
                        height="15"
                      />
                    ) : (
                      <CameraOffIcon
                        title="Student video off"
                        alt="camera off"
                        height="15"
                      />
                    )}
                    <p
                      className={`text-[10px] font-semibold mt-1 font-['nunito'] ${this.state.isVideo ? "text-[#a0db32]" : "text-[#EB4335]"
                        }`}
                    >
                      VIDEO
                    </p>
                  </div>
                  {!this.state.isVideoVerified ? (
                    <>
                      <img
                        alt="loading"
                        src={load}
                        className="spinner mr-2 w-6 h-6"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={this.state.isVideo ? tick : cross}
                        alt="Verfied"
                        className="h-4 w-4"
                        title="status"
                      />
                      {this.state.isVideo ? (
                        <p className="px-2 w-[300px] py-1 bg-[#EEFECF] rounded-lg">
                          Your video sharing is verified.
                        </p>
                      ) : (
                        <p className="px-2 w-[300px] py-1 bg-rose-50 rounded-lg">
                          {this.state.errorMessage.toLowerCase() ===
                            "permission denied"
                            ? "Please allow the access of your camera and microphone"
                            : "Make sure you are not using the camera and microphone in any other application"}{" "}
                        </p>
                      )}
                    </>
                  )}
                </div>
                {/* Audio */}
                <div className="flex gap-x-5 items-center justify-start">
                  <div
                    className={`flex flex-col w-16 justify-start items-center p-2 rounded-md
                    }`}
                  >
                    {this.state.isVideo ? (
                      <MicIcon
                        title="Student audio on"
                        alt="Microphone on"
                        className="mx-auto"
                        height="17"
                      />
                    ) : (
                      <MicOffIcon
                        title="Student audio off"
                        alt="Microphone off"
                        className="mx-auto"
                        height="17"
                      />
                    )}
                    <p
                      className={`text-[10px]  font-semibold mt-1 font-['nunito'] ${this.state.isVideo ? "text-[#a0db32]" : "text-[#EB4335]"
                        }`}
                    >
                      AUDIO
                    </p>
                  </div>
                  {!this.state.isVideoVerified ? (
                    <>
                      <img
                        alt="loading"
                        src={load}
                        className="spinner mr-2 w-6 h-6"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={this.state.isVideo ? tick : cross}
                        alt="Verfied"
                        className="h-4 w-4"
                        title="status"
                      />
                      <p
                        className={`px-2 w-[300px] py-1 ${this.state.isVideo ? "bg-[#EEFECF]" : "bg-rose-50"
                          } rounded-lg`}
                      >
                        {this.state.isVideo
                          ? "Your audio is verified"
                          : "There is an error with your audio sharing"}
                      </p>
                    </>
                  )}
                </div>
                {/* Screen */}
                <div className="flex gap-x-5 items-center justify-start">
                  <div
                    className={`flex-col flex w-16 justify-start items-center p-2 rounded-md
                    }`}
                  >
                    {this.state.isEntireScreenShared ? (
                      <DesktopIcon
                        title="Screen share on"
                        alt="Screen share on"
                        className="mx-auto"
                        height="18"
                      />
                    ) : (
                      <DesktopOffIcon
                        title="Screen share off"
                        alt="Screen share off"
                        className="mx-auto"
                        height="18"
                      />
                    )}
                    <p
                      className={`text-[10px] font-semibold mt-1 font-['nunito'] ${this.state.isEntireScreenShared
                        ? "text-[#a0db32]"
                        : "text-[#EB4335]"
                        }`}
                    >
                      SCREEN
                    </p>
                  </div>
                  {!this.state.isScreenVerified ? (
                    <>
                      <img
                        alt="loading"
                        src={load}
                        className="spinner mr-2 w-6 h-6"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={this.state.isEntireScreenShared ? tick : cross}
                        alt="Verfied"
                        className="h-4 w-4"
                        title="status"
                      />
                      {this.state.isEntireScreenShared ? (
                        <p className="px-2 w-[300px] py-1 bg-[#EEFECF] rounded-lg">
                          Your screen sharing is verified.
                        </p>
                      ) : (
                        <p className="px-2 w-[300px] py-1 bg-rose-50 rounded-lg">
                          {this.state.isScreenShared
                            ? "You are requested to share your entire screen "
                            : "There is an error with your screen sharing "}
                          <a
                            href="#faq-section"
                            className="hover:underline text-sm"
                          >
                            Click to check FAQs
                          </a>
                        </p>
                      )}
                    </>
                  )}
                </div>
                {/* BandWidth */}
                {/* <div className="flex gap-x-5 items-center justify-start">
                  <div
                    className={`flex-col flex rounded-md  w-16 justify-start items-center p-2
                    }`}
                  >
                    <div className="flex items-center scale-3/4">
                      <ServerIcon
                        alt="server"
                        title="Bandwidth"
                        className="mr-1"
                        height="17"
                        fill={this.state.bandWidth ? "#000" : "#EB4335"}
                      />

                      {this.state.bandWidth && (
                        <span
                          className={`leading-4 font-medium text-xs ${this.state.bandWidth
                            ? "text-[#a0db32]"
                            : "text-[#EB4335]"
                            }`}
                        >
                          {parseInt(this.state.bandWidth)}
                          <br /> Mb/s
                        </span>
                      )}
                    </div>
                    <p
                      className={`text-[10px] font-semibold mt-1 font-['nunito'] ${this.state.bandWidth
                        ? "text-[#a0db32]"
                        : "text-[#EB4335]"
                        }`}
                    >
                      BANDWIDTH
                    </p>
                  </div>
                  {!this.state.isBandwidthVerified ? (
                    <>
                      <img
                        alt="loading"
                        src={load}
                        className="spinner mr-2 w-6 h-6"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={this.state.bandWidth ? tick : cross}
                        alt="Verfied"
                        className="h-4 w-4"
                        title="status"
                      />
                      <p
                        className={`px-2 w-[300px] py-1 ${this.state.bandWidth ? "bg-[#EEFECF]" : "bg-rose-50"
                          } rounded-lg`}
                      >
                        {this.state.bandWidth
                          ? "Your internet bandwidth is verified"
                          : "Please check your internet bandWidth"}
                      </p>
                    </>
                  )}
                </div> */}

                {/* System TimeZone */}
                <div className="flex gap-x-5 items-center justify-center">
                  <div
                    className={`flex-col flex rounded-md  w-16 justify-center items-center p-2
                    }`}
                  >
                    <Time
                      alt="System timezone"
                      title="Timezone"
                      height="20"
                      fill={this.state.userTimeZone === 'Asia/Calcutta' ? "#a0db32" : "#EB4335"}
                    />
                    <p
                      className={`text-[10px] text-center font-semibold mt-1 font-['nunito'] ${(this.state.userTimeZone === 'Asia/Calcutta')
                        ? "text-[#a0db32]"
                        : "text-[#EB4335]"
                        }`}
                    >
                      System Timezone
                    </p>
                  </div>
                  {!(this.state.userTimeZone !== null) ? (
                    <>
                      <img
                        alt="loading"
                        src={load}
                        className="spinner mr-2 w-6 h-6"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={this.state.userTimeZone === 'Asia/Calcutta' ? tick : cross}
                        alt="Verfied"
                        className="h-4 w-4"
                        title="status"
                      />
                      <p
                        className={`px-2  py-1 ${this.state.userTimeZone === 'Asia/Calcutta' ? "bg-[#EEFECF]" : "bg-rose-50"
                          } rounded-lg`}
                      >
                        {this.state.userTimeZone}{this.state.userTimeZone === 'Asia/Calcutta' ? " (Matched with India Timezone)" : " (Please change your timezone settings to 'Asia/Calcutta')"}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>

            <section
              id="faq-section"
              className="flex w-fu pt-10 pb-10 bg-[#EEFECF]"
            >
              <div className="container  min-w-[800px] mx-auto">
                <div className="mb-10 text-center">
                  <span className="text-2xl font-semibold text-black">
                    Do You Have Questions ?
                  </span>
                  <h2 className="mt-2 text-lg font-medium font-heading text-slate-600">
                    We have answers (well, most of the times!)
                  </h2>
                </div>
                <div className="mx-auto">
                  <ul className="w-full px-10 list-none">
                    {this.state.faqsDropDown.map((each, index) => (
                      <li
                        className="mb-4 w-full px-4 lg:px-8 py-2 bg-[#BCFB46] rounded-2xl transition-all"
                        key={`faqsDropdown-${index}`}
                      >
                        <div className="flex items-start justify-start w-full text-left transition-all">
                          <div className="w-auto mr-8">
                            <span className="flex items-center justify-center w-8 h-8 text-lg font-medium bg-white rounded-full">
                              {each.id + 1}
                            </span>
                          </div>
                          <div className="w-full">
                            <div
                              className="flex items-center justify-between bg-red relative z-50 cursor-pointer"
                              onClick={() => this.handleDropdown(each.id)}
                            >
                              <h3 className="font-['nunito'] text-xl font-semibold">
                                {each.heading}
                              </h3>
                              <span className="ml-4">
                                {each.status ? (
                                  <ArrowUpIcon
                                    title="Collapse"
                                    alt="collapse"
                                  />
                                ) : (
                                  <ArrowDownIcon title="Expand" alt="expand" />
                                )}
                              </span>
                            </div>

                            <ul
                              className={`${each.status
                                ? "list-decimal mt-6 mb-2 text-base opacity-100"
                                : "opacity-0 h-0 "
                                }  transition-all `}
                            >
                              {/* <span className="inline-block mr-6 h-2 w-2 rounded-full bg-blue-500"></span> */}
                              {each.points.map((text, idx) => {
                                return (
                                  <li
                                    key={`text-${idx}`}
                                    className="flex transition-all"
                                  >
                                    <div className="flex flex-col w-full">
                                      {text.img !== null && (
                                        <img
                                          src={text.img}
                                          className="max-w-[350px] text-center my-4 mx-auto"
                                          alt="img"
                                          title={text.line_text}
                                        />
                                      )}
                                      <div className="flex">
                                        <span className="mr-2">{idx + 1}.</span>
                                        <p className="mr-1">{text.line_text}</p>
                                        <a
                                          href={text.link}
                                          target="_blank"
                                          rel="noreferrer"
                                          className="text-blue-500"
                                        >
                                          {text.link_text}
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </section>
            <h2 className="mt-2 text-lg font-medium font-heading text-slate-600">
              Still having issues? Please fill this form. We will contact you
              asap.
            </h2>
            <iframe
              className=" mb-20 "
              src="https://docs.google.com/forms/d/e/1FAIpQLSeuGFPxXTpkKHBLpsrR3deR5uush01kyfpgB6086DdV1cDoGQ/viewform?embedded=true"
              width="640"
              height="1500"
            >
              Loading…
            </iframe>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test, sidebar }) => {
  return {
    test: test.test,
    sidebar: sidebar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStarterCardActive: (data) => dispatch(updateStarterCardActive(data)),
    toggleSidebar: () => dispatch(toggleSidebar()),
    setTestStatus: (data) => dispatch(setTestStatus(data)),
    updateBandWidth: (data) => dispatch(updateBandWidth(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CheckYourMetrics));
