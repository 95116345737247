import { call, put, take, takeEvery } from "redux-saga/effects";
import {
  FETCH_ALL_EVALUATORS_REPORTS_REQUESTED,
  FETCH_REPORT_REQUESTED,
  SEND_REMINDER_EVALUATOR_REQUEST,
  UPDATE_SCORE_REQUESTED,
} from "../actionTypes/evaluators";
import {
  fetchAllAssignedReports,
  fetchReportService,
  sendReminderEvaluatorApi,
  updateScoreService,
} from "../../services/evaluator.service";
import {
  fetchAllAssignedReportsFailure,
  fetchAllAssignedReportsSuccess,
  fetchReportFailure,
  fetchReportSuccess,
} from "../actions/evaluators";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../assets/js/toast_config";

function* fetchAllEvaluatorsReportsSaga(action) {
  try {
    const response = yield call(fetchAllAssignedReports, action.payload);
    if (response.success)
      yield put(fetchAllAssignedReportsSuccess(response.data));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(fetchAllAssignedReportsFailure(e));
  }
}

function* fetchReportSaga(action) {
  try {
    const response = yield call(fetchReportService, action.payload);
    if (response.success) yield put(fetchReportSuccess(response.data));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(fetchReportFailure(e));
  }
}

function* sendReminderEvaluator(action) {
  try {
    yield call(sendReminderEvaluatorApi, action.payload);
    toast.success("Email sent successfuly to evaluators", defaultToastSetting);
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
  }
}

function* evaluatorsSaga() {
  yield takeEvery(
    FETCH_ALL_EVALUATORS_REPORTS_REQUESTED,
    fetchAllEvaluatorsReportsSaga
  );
  yield takeEvery(FETCH_REPORT_REQUESTED, fetchReportSaga);
  yield takeEvery(SEND_REMINDER_EVALUATOR_REQUEST, sendReminderEvaluator);
}

export default evaluatorsSaga;
