import { call, put, takeEvery } from "redux-saga/effects";
import {
  CODE_EDITOR_RUN_REQUESTED,
  CODE_EDITOR_SUBMIT_REQUESTED,
  CODE_EDITOR_RUN_STATUS_REQUESTED,
} from "../actionTypes";
import {
  runCodeSuccess,
  runCodeFailure,
  submitCodeSuccess,
  submitCodeFailure,
  fetchCodeRunStatusSuccess,
  fetchCodeRunStatusFailure,
} from "../actions/codeeditor";
import { updateTestResponse } from "../actions/test";
import {
  runCode,
  submitCode,
  codeStatus,
} from "../../services/codeeditor.service";

function* runCodeSaga(action) {
  try {
    const code_results = yield call(runCode, action.payload);
    if (!code_results.waiting) {
      yield put(runCodeSuccess(code_results));
    }
  } catch (e) {
    yield put(runCodeFailure(e));
  }
}

function* submitCodeSaga(action) {
  try {
    const code_results = yield call(submitCode, action.payload);
    if (!code_results.waiting) {
      yield put(submitCodeSuccess(code_results));
      yield put(
        updateTestResponse({
          sectionIndex: code_results.sectionIndex,
          questionIndex: code_results.questionIndex,
          value: code_results,
        })
      );
    }
  } catch (e) {
    yield put(submitCodeFailure(e));
  }
}

function* runStatusCodeSaga(action) {
  try {
    const code_results = yield call(codeStatus, action.payload);
    if (
      code_results.judge_response.status &&
      ![1, 2].includes(code_results.judge_response.status.id)
    ) {
      yield put(fetchCodeRunStatusSuccess(code_results));
    }
  } catch (e) {
    yield put(fetchCodeRunStatusFailure(e));
  }
}

function* codeeditorSaga() {
  yield takeEvery(CODE_EDITOR_RUN_REQUESTED, runCodeSaga);
  yield takeEvery(CODE_EDITOR_SUBMIT_REQUESTED, submitCodeSaga);
  yield takeEvery(CODE_EDITOR_RUN_STATUS_REQUESTED, runStatusCodeSaga);
}

export default codeeditorSaga;
