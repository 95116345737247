import React, { Component } from "react";
import { formateTime } from "../../components/tests/utils/TimeFormat";

// Store
import { connect } from "react-redux";
import {
  updateTestMainDataWithKey,
  fetchTestRequest,
  assignTestRequest,
} from "../../store/actions/test";

import {
  fetchTestAnalyticsScore,
  fetchTestAnalyticsDuration,
  fetchTestAnalyticsScoreDistribution,
  fetchTestAnalyticsDurationDistribution,
} from "../../store/actions/analytics";

import HistogramChart from "../../components/analytics/HistogramChart";

class Analytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testId: this.props.params.id,
    };
  }

  componentDidMount() {
    this.props.fetchTestRequest(this.state.testId, { shuffle: false });
    this.props.fetchTestAnalyticsScore(this.state.testId);
    this.props.fetchTestAnalyticsDuration(this.state.testId);
    this.props.fetchTestAnalyticsScoreDistribution(this.state.testId);
    this.props.fetchTestAnalyticsDurationDistribution(this.state.testId);
  }
  render() {
    return (
      <div className="m-5">
        <div>
          <h2>Test Details</h2>
          <div >
            <div className="border-1 shadow-sm border-gray-400 p-4 text-dark font-medium">
              <h3>{this.props.title}</h3>
              <div className="d-flex gap-1">
                <div
                  dangerouslySetInnerHTML={{ __html: this.props.description }}
                />
              </div>
              <p>
                <b>Starts: </b> {formateTime(this.props.start_duration)}
              </p>
              <p>
                <b>Ends: </b> {formateTime(this.props.end_duration)}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <h2>Test Duration</h2>
          <div >
            <div className="border-1 border-gray-400 p-4 text-dark font-medium">
              <h3>Mean</h3>
              <h6 className="text-gray-600 mt-2">
                {this.props.analytics.duration_mean}
              </h6>
            </div>
          </div>
          <div>
            <div className="border-1 border-gray-400 p-4 text-dark font-medium">
              <h3>Median</h3>
              <h6 className="text-gray-600 mt-2">
                {this.props.analytics.duration_median}
              </h6>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <h2>Test Score</h2>
          <div >
            <div className="border-1 border-gray-400 p-4 text-dark font-medium">
              <h3>Mean</h3>
              <h6 className="text-gray-600 mt-2">
                {this.props.analytics.score_mean}
              </h6>
            </div>
          </div>
          <div>
            <div className="border-1 border-gray-400 p-4 text-dark font-medium">
              <h3>Median</h3>
              <h6 className="text-gray-600 mt-2">
                {this.props.analytics.score_median}
              </h6>
            </div>
          </div>
          <div>
            <div className="border-1 border-gray-400 p-4 text-dark font-medium">
              <h3>Max</h3>
              <h6 className="text-gray-600 mt-2">
                {this.props.analytics.score_max}
              </h6>
            </div>
          </div>
          <div>
            <div className="border-1 border-gray-400 p-4 text-dark font-medium">
              <h3>Min</h3>
              <h6 className="text-gray-600 mt-2">
                {this.props.analytics.score_min}
              </h6>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <h2>Test</h2>
          <div lg={6}>
            <h5>Score</h5>
            <HistogramChart />
          </div>
          <div lg={6}>
            <h5>duration</h5>
            <HistogramChart />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test, analytics }) => {
  return {
    test: test.test,
    course: test.test.course,
    assign: test.test.assign,
    title: test.test.title,
    description: test.test.description,
    start_duration: test.test.start_duration,
    end_duration: test.test.end_duration,
    analytics: analytics.analytics,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTestMainDataWithKey: (data) =>
      dispatch(updateTestMainDataWithKey(data)),
    fetchTestRequest: (data) => dispatch(fetchTestRequest(data)),
    assignTestRequest: (id, data) => dispatch(assignTestRequest(id, data)),
    fetchTestAnalyticsScore: (data) => dispatch(fetchTestAnalyticsScore(data)),
    fetchTestAnalyticsDuration: (data) =>
      dispatch(fetchTestAnalyticsDuration(data)),
    fetchTestAnalyticsScoreDistribution: (data) =>
      dispatch(fetchTestAnalyticsScoreDistribution(data)),
    fetchTestAnalyticsDurationDistribution: (data) =>
      dispatch(fetchTestAnalyticsDurationDistribution(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
