import React from "react";
import Editor from "./Editor";
import Results from "./Results";

// Store
import { connect } from "react-redux";
import { updateCodeEditorLanguage } from "../../../../store/actions/codeeditor";
import { toggleRightSidebar } from "../../../../store/actions/test";

class MidasEditor extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    const { title, question, description, section, questionId, response } = this.props;
    return (
      <>
        <div
          className={`flex ${this.props.isRightSidebar ? "flex-row gap-5" : "flex-col"
            }  font-poppins break-words`}
        >
          <div className={`${this.props.isRightSidebar && "w-1/2"}`}>
            <div className="pr-2 border-r-8">
              <h1
                className="my-2 font-['nunito'] text-wrap"
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              ></h1>
              <hr />
              <br />
              <h5
                className=""
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              ></h5>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex-col text-slate-600 font-semibold">
                <p>
                  Memory Limit :{" "}
                  <span>
                    {section.components[questionId].coding_memory_limit} (in MB)
                  </span>
                </p>
                <p>
                  Time Limit :{" "}
                  <span>
                    {section.components[questionId].coding_time_limit} (in
                    Seconds)
                  </span>
                </p>
              </div>

            </div>
          </div>
          <div className={`${this.props.isRightSidebar && "w-1/2"} py-4`}>
            <Editor
              type="T"
              sectionId={this.props.sectionId}
              questionId={this.props.questionId}
              testId={this.props.testId}
              response={response}
              is_disable_copy_paste={this.props.is_disable_copy_paste}
            />
            <Results
              type="T"
              sectionId={this.props.sectionId}
              questionId={this.props.questionId}
              question={question}
              testId={this.props.testId}
              response={response}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ codeeditor, test }) => {
  return {
    language: codeeditor.language,
    code: codeeditor.code,
    results: codeeditor.results,
    isRightSidebar: test.isRightSidebar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCodeEditorLanguage: (data) =>
      dispatch(updateCodeEditorLanguage(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MidasEditor);
