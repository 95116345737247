import React, { Component } from "react";
import { connect } from "react-redux";
import { updateStarterCardActive } from "../../../store/actions/test";
import TestHeader from "../createTest/TestHeader";
import { toast } from "react-toastify";
import kyronImg from "../../../assets/img/Kyron/KyronLogo_New.png";
import KyronSideLetter from "../../../assets/img/Kyron/KyronLetterSide.png";
import UniversalButton from "../../common/StandardButton/UniversalButton";
import { withRouter } from "../../common/WithRouter";

class RecordingNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedBoxes: new Map(),
      warning: false,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) => {
    var isChecked = e.target.checked;
    var item = e.target.value;
    this.setState((prevState) => ({
      checkedBoxes: prevState.checkedBoxes.set(item, isChecked),
      warning: !isChecked,
    }));
  };

  handleToast = (msg) => {
    toast.warning(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  onNextPage() {
    if (this.props.test.is_proctoring) {
      // if (this.props.bandWidth === 0) {
      //   this.handleToast("You are offline");
      //   return;
      // }
      if (this.props.isVideo && this.props.isAudio) {
        if (this.props.isScreen) {
          const val = this.state.checkedBoxes.values();
          let flag = 0;
          for (var ele of val) {
            if (ele === true) flag += 1;
          }
          if (flag === 1) {
            if (this.props.screenStream && this.props.screenStream.active) {
              // this.props.socket.emit("checks-before-test", {
              //   data: {
              //     isTerms: true,
              //   },
              //   userId: this.props.userId,
              //   testId: this.props.testId,
              //   type: "recording-notice",
              // });
              this.props.updateStarterCardActive(5);
            } else {
              this.handleToast("Please refresh and share your screen");
            }
          } else {
            this.setState((prevState) => ({
              ...prevState,
              warning: true,
            }));
          }
        } else {
          this.handleToast("Please refresh and share your screen");
        }
      } else {
        this.handleToast("Please enable your camera and refresh your page");
      }
    }
  }

  render() {
    return (
      <>
        {/* Header */}
        <nav className="flex justify-between items-center px-4 py-2 border-b border-border w-[100%] bg-white drop-shadow-[2px_0px_2px_rgba(0,0,0,0.25)]">
          <img
            className="h-[30px] object-contain"
            src={KyronSideLetter}
            title="Logo"
            alt="kyron"
          />
        </nav>
        <div className="mt-4 sm:mx-20 mx-5 font-['poppins']">
          <TestHeader title="Exam Session Recording Notice" />
          <p className="mt-5">
            Kyron will record your Exam Session for potential review and
            training purposes. This information is encrypted and can be
            accessesd only by Institution-Authorized individuals.
          </p>
          <p>
            Recording will begin after we check your system and environment.
            Following completion of exam (including final review of your exam)
            you MUST disconnect from your Kyron session by closing the
            application and your browser. The webcam will continue to record
            until you complete BOTH actions.
          </p>

          <ul className="list-disc m-2">
            <li>
              I consent to Kyron recording me via my computer's camera and
              microphone during my test.
            </li>
            <li>
              I consent to Kyron to use Biometrics Facial Recognition to prevent
              identity fraud during my test.
            </li>
            <li>
              I consent to Kyron to view my desktop, view my system information,
              run scripts, deploy Rescue Calling Card in the event I need
              technical support and invite technicians for collaborations, if
              needed.
            </li>
            <li>
              I understand that I must close both the application and my browser
              to end the recording of my test.
            </li>
            <li>
              For more detail. Please check the{" "}
              <a
                // href="https://docs.google.com/document/d/1pfomacOLwn-5FQwdocChZBAL6s90Ksih1SXJ1Vbqxaw/edit?usp=sharing"
                href="https://drive.google.com/file/d/1KIIKavp6rZVzT8zwl0lZ9NC7hlOtYg4S/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 dark:text-blue-500 hover:underline"
              >
                consent form
              </a>
              .
            </li>
          </ul>
          <p></p>

          <div className="pt-4 flex items-center">
            <input
              type="checkbox"
              value="1"
              required
              onChange={this.onChange}
              id="checkbox_id"
            />
            <label htmlFor="checkbox_id" className="ml-2 hover:cursor-pointer">
              I agree to all the above statements
            </label>
          </div>
          {this.state.warning ? (
            <span className="text-red-600 text-sm">*Required</span>
          ) : null}

          <div className="flex justify-center mt-7">
            <UniversalButton
              onClickFunction={() => this.onNextPage()}
              title="Continue"
              classNames="mb-6 mr-2"
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    test: test.test,
    screenStream: test.screenStream,
    isVideo: test.isVideo,
    isAudio: test.isAudio,
    isScreen: test.isScreen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStarterCardActive: (data) => dispatch(updateStarterCardActive(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RecordingNotice));
