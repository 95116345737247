import React, { useState, useEffect } from "react";
import TestHeader from "../components/tests/createTest/TestHeader";
import CurrentTestCard from "./CurrentTestCard";
import { ReactComponent as EmptyIcon } from "../assets/img/svg/empty.svg";
import Slider from "react-slick";
import Modal from "../components/common/Modal/Modal";
import "../components/common/Common.css";
import { slickSetting } from "../components/tests/Arrow";
import TestSeeAllModal from "../components/modals/TestSeeAllModal";

const EndedTestCards = ({
  isAdmin,
  isTeacher,
  isKAInstructor,
  handleToast,
  isAssistant,
  showDropDown,
  handleDropDown,
  handleDeleteTest,
  ended,
}) => {
  const [seeAllModal, setSeeAllModal] = useState(false);

  return (
    <>
      <div className="w-full m-auto">
        <div className="flex items-center justify-between w-full mt-8">
          <TestHeader title="Previous Tests" />
          {ended.length > 0 && (
            <h1
              className="text-[1rem] text-[#87CF00] font-medium mr-3 cursor-pointer"
              onClick={() => setSeeAllModal(true)}
            >
              See All
            </h1>
          )}
        </div>

        <div className="test mx-[1.5rem] mt-[0.8rem] w-[96%]">
          {ended.length > 0 ? (
            <Slider {...slickSetting}>
              {ended?.map((data, data_index) => (
                <CurrentTestCard
                  key={`CURRENT${data._id}`}
                  data={data}
                  data_index={data_index}
                  showDropDown={showDropDown}
                  handleDropDown={handleDropDown}
                  handleDeleteTest={handleDeleteTest}
                  isAdmin={isAdmin}
                  isTeacher={isTeacher}
                  isKAInstructor={isKAInstructor}
                  isAssistant={isAssistant}
                  type="ended"
                />
              ))}
            </Slider>
          ) : (
            <div className="w-full h-[23rem] flex flex-col justify-center items-center p-5">
              <EmptyIcon style={{ height: "100%" }} />
              <h3>No Tests to show</h3>
            </div>
          )}
        </div>
      </div>

      <Modal
        onClose={() => setSeeAllModal(false)}
        showCloseButton
        isModal={seeAllModal}
        className="w-[80%]"
      >
        <TestSeeAllModal
          data={ended}
          showDropDown={showDropDown}
          handleDropDown={handleDropDown}
          handleDeleteTest={handleDeleteTest}
          isAdmin={isAdmin}
          isTeacher={isTeacher}
          isKAInstructor={isKAInstructor}
          isAssistant={isAssistant}
        />
      </Modal>
    </>
  );
};

export default EndedTestCards;
