import React, { useEffect, useState } from 'react'
import UniversalButton from '../../components/common/StandardButton/UniversalButton';
import Modal from '../../components/common/Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSubscription, getAllSubscription, updateSubscription } from '../../store/actions/subscription';
import ResumeLoader from '../parseResume/ResumeLoader';
import Datetime from "react-datetime";
import DashBoardTable from '../../components/common/DashBoardTable';
import Tooltip from '../../components/common/Tooltip/Tooltip';
import eye from "../../assets/img/svg/eye.svg";


const Subscription = () => {
    const dispatch = useDispatch();

    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [whichField, setWhichField] = useState({
        interview: false,
        test: false,
        resume: false,
    });
    const [dataForUpdatingInterview, setDataForUpdatingInterview] = useState({
        interview_allowed: "",
        interview_validity_date_from: "",
        interview_validity_date_to: "",
    });
    const [dataForUpdatingTest, setDataForUpdatingTest] = useState({
        test_allowed: "",
        test_validity_date_from: "",
        test_validity_date_to: "",
        students_allowed: "",
    });
    const [dataForUpdatingResume, setDataForUpdatingResume] = useState({
        resume_allowed: "",
        resume_validity_date_from: "",
        resume_validity_date_to: "",

    });
    const [dataForUpdating, setDataForUpdating] = useState({
        userId: "",

    });
    const [addRemaining, setAddRemaining] = useState({
        add_remaining_interview: false,
        add_remaining_test: false,
        add_remaining_resume: false,
    });



    const [deleteModal, setDeleteModal] = useState(null);

    const { subscription, subscriptionLoading, deleteSubscriptionLoading, updateSubscriptionLoading } = useSelector(state => state.SubscriptionReducer)

    const deleteSubscriptionModal = (res) => {
        setDeleteModal(res._id)
    };

    const updateSubscriptionModal = (res) => {
        setDataForUpdating({
            userId: res.userId,
            students_allowed: res.students_allowed,
        })
        setDataForUpdatingInterview({
            interview_allowed: res.interview_allowed,
            interview_validity_date_from: res.interview_validity_date_from,
            interview_validity_date_to: res.interview_validity_date_to,
        })
        setDataForUpdatingResume({
            resume_allowed: res.resume_allowed,
            resume_validity_date_from: res.resume_validity_date_from,
            resume_validity_date_to: res.resume_validity_date_to
        })
        setDataForUpdatingTest({
            test_allowed: res.test_allowed,
            test_validity_date_from: res.test_validity_date_from,
            test_validity_date_to: res.test_validity_date_to,

        })


        setSubscriptionModal(true)
    };

    const onChangeFunction = (type, name, value) => {
        if (type === "interview") setDataForUpdatingInterview(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
        else if (type === "test") setDataForUpdatingTest(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
        if (type === "resume") setDataForUpdatingResume(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    useEffect(() => {
        dispatch(getAllSubscription())
    }, []);
    console.log(whichField)

    return (
        <>
            <div className='p-3'>
                <div className="flex mt-2">
                    <div
                        className="searchCreateTest rounded px-4 py-3 w-full flex flex-col sm:flex-row justify-between"
                        style={{
                            boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
                            background:
                                "linear-gradient(91deg, #C6FB63 2.1%, #94E005 98.82%)",
                        }}
                    >
                        <p className="text-[1.2rem] sm:text-[1.5rem] font-semibold font-['poppins']">
                            Subscription
                        </p>

                    </div>
                </div>
                {
                    subscriptionLoading ? <div className='flex w-full h-[75vh] items-center justify-center'><ResumeLoader /></div> :
                        <>
                            {
                                subscription.length ? <div className={`w-full font-['nunito'] my-4`}>
                                    <DashBoardTable
                                        studentList={subscription}
                                        type="S"
                                        updateSubscriptionModal={updateSubscriptionModal}
                                        deleteSubscriptionModal={deleteSubscriptionModal}
                                    />
                                </div> : null
                            }

                        </>
                }
            </div>

            <Modal
                onClose={() => setSubscriptionModal(false)}
                isModal={subscriptionModal}
                showCloseButton
                className="w-[92%] sm:w-[80%] md:w-[70%] lg:w-[65%] xl:w-[55%]"
            >
                <div className='w-full mt-4'>
                    <h1 className="w-full text-[1.5rem] mb-5 font-['poppins'] font-semibold text-center"> Update Subscription for {dataForUpdating.userId?.name}</h1>
                    <div className="flex flex-col gap-y-2 pb-2 overflow-y-auto max-h-[70vh] scrollbar">

                        <div className="mb-2">
                            <div className="mb-1 flex justify-between items-center w-full gap-3">
                                <div className="flex justify-start items-center gap-3">
                                    <input
                                        className="cursor-pointer"
                                        name="interview"
                                        checked={whichField.interview}
                                        onChange={(e) => setWhichField(prev => {
                                            return {
                                                ...prev,
                                                [e.target.name]: !prev.interview
                                            }
                                        })}
                                        type="checkbox"
                                    />
                                    <h1 className="font-['nunito'] font-semibold text-xl">Interview</h1>
                                    <Tooltip
                                        theme="RIGHT"
                                        text="Check this for updating the subscription for interview"
                                    >

                                        <img
                                            src={eye}
                                            alt="info"
                                            className="cursor-pointer"
                                        />

                                    </Tooltip>
                                </div>
                                <div className="flex gap-3 justify-center items-center">

                                    <input
                                        className="cursor-pointer"
                                        name="add_remaining_interview"
                                        checked={addRemaining.add_remaining_interview}
                                        onChange={(e) => setAddRemaining(prev => {
                                            return {
                                                ...prev,
                                                [e.target.name]: !prev.add_remaining_interview
                                            }
                                        })}
                                        type="checkbox"
                                    />
                                    <label className="font-['nunito'] font-semibold text-lg">Add remaining</label>

                                    <Tooltip
                                        theme="LEFT"
                                        text="Add any remaining interviews from the previous subscription to the current subscription."
                                    >

                                        <img
                                            src={eye}
                                            alt="info"
                                            className="cursor-pointer"
                                        />

                                    </Tooltip>
                                </div>
                            </div>
                            <div className='grid w-full gap-x-3 grid-cols-1 md:grid-cols-3'>
                                <div className="w-full">
                                    <label
                                        className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
                                        htmlFor="interview_allowed"
                                    >
                                        Interviews Limit
                                    </label>
                                    <div className="relative">
                                        <input
                                            className="border-2 border-gray-300 block px-2.5 pb-2 pt-2 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 peer"
                                            id="interview_allowed"
                                            name="interview_allowed"
                                            value={dataForUpdatingInterview.interview_allowed}
                                            onChange={(e) => onChangeFunction("interview", "interview_allowed", +e.target.value)}
                                            type="number"
                                            placeholder="Interview Allowed"
                                        />
                                    </div>
                                </div>
                                <div className="w-full">
                                    <label
                                        className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
                                        htmlFor="interview_validity_date_from"
                                    >
                                        Interview validity from
                                    </label>
                                    <Datetime
                                        className="font-normal w-full"
                                        inputProps={{
                                            className:
                                                "border-2 w-full focus:outline-none focus:ring-0  placeholder:font-light  text-sm outline-gray-300 border-gray-300 p-2 rounded font-['nunito']",
                                            placeholder: "Start Date & Time",
                                            name: "interview_validity_date_from",
                                        }}
                                        autoComplete="off"
                                        value={new Date(dataForUpdatingInterview?.interview_validity_date_from ?? "")}
                                        onChange={(e) => onChangeFunction("interview", "interview_validity_date_from", e?._d?.getTime())}
                                    />
                                </div>
                                <div className="w-full">
                                    <label
                                        className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
                                        htmlFor="interview_validity_date_to"
                                    >
                                        Interview Validity to
                                    </label>
                                    <Datetime
                                        className="font-normal w-full"
                                        autoComplete="off"
                                        inputProps={{
                                            className:
                                                "border-2 w-full focus:outline-none focus:ring-0  placeholder:font-light  text-sm outline-gray-300 border-gray-300 p-2 rounded font-['nunito']",
                                            placeholder: "Interview Validity to",
                                            name: "interview_validity_date_to",
                                        }}
                                        value={new Date(dataForUpdatingInterview?.interview_validity_date_to ?? "")}
                                        onChange={(e) => onChangeFunction("interview", "interview_validity_date_to", e?._d?.getTime())}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mb-2">
                            <div className="mb-1 flex justify-between items-center w-full gap-3">


                                <div className="flex justify-start items-center gap-3">
                                    <input
                                        className="cursor-pointer"
                                        name="test"
                                        checked={whichField.test}
                                        onChange={(e) => setWhichField(prev => {
                                            return {
                                                ...prev,
                                                [e.target.name]: !prev.test
                                            }
                                        })}
                                        type="checkbox"
                                    />
                                    <h1 className="font-['nunito'] font-semibold text-xl">Tests</h1>
                                    <Tooltip
                                        theme="RIGHT"
                                        text="Check this for updating the subscription for test"
                                    >
                                        <img
                                            src={eye}
                                            alt="info"
                                            className="cursor-pointer"
                                        />

                                    </Tooltip>
                                </div>
                                <div className="flex gap-3 justify-center items-center">

                                    <input
                                        className="cursor-pointer"
                                        name="add_remaining_test"
                                        checked={addRemaining.add_remaining_test}
                                        onChange={(e) => setAddRemaining(prev => {
                                            return {
                                                ...prev,
                                                [e.target.name]: !prev.add_remaining_test
                                            }
                                        })}
                                        type="checkbox"
                                    />



                                    <label className="font-['nunito'] font-semibold text-lg">Add remaining</label>
                                    <Tooltip
                                        theme="LEFT"
                                        text="Add any remaining tests and students from the previous subscription to the current subscription."
                                    >
                                        <img
                                            src={eye}
                                            alt="info"
                                            className="cursor-pointer"
                                        />

                                    </Tooltip>
                                </div>
                            </div>
                            <div className='grid w-full gap-x-3 gap-y-1 grid-cols-1 md:grid-cols-2'>
                                <div className="w-full">
                                    <label
                                        className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
                                        htmlFor="test_allowed"
                                    >
                                        Tests Limit
                                    </label>
                                    <div className="relative">
                                        <input
                                            className="border-2 border-gray-300 block px-2.5 pb-2 pt-2 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 peer"
                                            id="test_allowed"
                                            name="test_allowed"
                                            value={dataForUpdatingTest.test_allowed}
                                            onChange={(e) => onChangeFunction("test", "test_allowed", +e.target.value)}
                                            type="number"
                                            placeholder="Test Allowed"
                                        />
                                    </div>
                                </div>
                                <div className="w-full">
                                    <label
                                        className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
                                        htmlFor="students_allowed"
                                    >
                                        Students Limit
                                    </label>
                                    <div className="relative">
                                        <input
                                            className="border-2 border-gray-300 block px-2.5 pb-2 pt-2 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 peer"
                                            id="students_allowed"
                                            name="students_allowed"
                                            value={+dataForUpdatingTest.students_allowed}
                                            onChange={(e) => onChangeFunction("test", "students_allowed", e.target.value)}
                                            type="number"
                                            placeholder="Students Allowed"
                                        />
                                    </div>
                                </div>
                                <div className="w-full">
                                    <label
                                        className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
                                        htmlFor="test_validity_date_from"
                                    >
                                        Test validity from
                                    </label>
                                    <Datetime
                                        className="font-normal w-full"
                                        inputProps={{
                                            className:
                                                "border-2 w-full focus:outline-none focus:ring-0  placeholder:font-light  text-sm outline-gray-300 border-gray-300 p-2 rounded font-['nunito']",
                                            placeholder: "Start Date & Time",
                                            name: "test_validity_date_from",
                                        }}
                                        autoComplete="off"
                                        value={new Date(dataForUpdatingTest?.test_validity_date_from ?? "")}
                                        onChange={(e) => onChangeFunction("test", "test_validity_date_from", e?._d?.getTime())}
                                    />
                                </div>
                                <div className="w-full">
                                    <label
                                        className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
                                        htmlFor="test_validity_date_to"
                                    >
                                        Test Validity to
                                    </label>
                                    <Datetime
                                        className="font-normal w-full"
                                        autoComplete="off"
                                        inputProps={{
                                            className:
                                                "border-2 w-full focus:outline-none focus:ring-0  placeholder:font-light  text-sm outline-gray-300 border-gray-300 p-2 rounded font-['nunito']",
                                            placeholder: "Test Validity to",
                                            name: "test_validity_date_to",
                                        }}
                                        value={new Date(dataForUpdatingTest?.test_validity_date_to ?? "")}
                                        onChange={(e) => onChangeFunction("test", "test_validity_date_to", e?._d?.getTime())}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mb-1">
                            <div className="mb-1 flex justify-between items-center w-full gap-3">
                                <div className="flex justify-start items-center gap-3">
                                    <input
                                        className="cursor-pointer"
                                        name="resume"
                                        checked={whichField.resume}
                                        onChange={(e) => setWhichField(prev => {
                                            return {
                                                ...prev,
                                                [e.target.name]: !prev.resume
                                            }
                                        })}
                                        type="checkbox"
                                    />

                                    <h1 className="font-['nunito'] font-semibold text-xl">Resume</h1>
                                    <Tooltip
                                        theme="RIGHT"
                                        text="Check this for updating the subscription for resume"
                                    >
                                        <img
                                            src={eye}
                                            alt="info"
                                            className="cursor-pointer"
                                        />

                                    </Tooltip>
                                </div>
                                <div className="flex gap-3 justify-center items-center">

                                    <input
                                        className="cursor-pointer"
                                        name="add_remaining_resume"
                                        checked={addRemaining.add_remaining_resume}
                                        onChange={(e) => setAddRemaining(prev => {
                                            return {
                                                ...prev,
                                                [e.target.name]: !prev.add_remaining_resume
                                            }
                                        })}
                                        type="checkbox"
                                    />
                                    <label className="font-['nunito'] font-semibold text-lg">Add remaining</label>
                                    <Tooltip
                                        theme="LEFT"
                                        text="Add any remaining resumes from the previous subscription to the current subscription."
                                    ><img
                                            src={eye}
                                            alt="info"
                                            className="cursor-pointer"
                                        />

                                    </Tooltip>

                                </div>
                            </div>
                            <div className='grid w-full gap-x-3 grid-cols-1 md:grid-cols-3'>
                                <div className="w-full">
                                    <label
                                        className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
                                        htmlFor="resume_allowed"
                                    >
                                        Resumes Limit
                                    </label>
                                    <div className="relative">
                                        <input
                                            className="border-2 border-gray-300 block px-2.5 pb-2 pt-2 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 peer"
                                            id="resume_allowed"
                                            name="resume_allowed"
                                            value={dataForUpdatingResume.resume_allowed}
                                            onChange={(e) => onChangeFunction("resume", "resume_allowed", +e.target.value)}
                                            type="number"
                                            placeholder="Resume Allowed"
                                        />
                                    </div>
                                </div>
                                <div className="w-full">
                                    <label
                                        className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
                                        htmlFor="resume_validity_date_from"
                                    >
                                        Resume validity from
                                    </label>
                                    <Datetime
                                        className="font-normal w-full"
                                        inputProps={{
                                            className:
                                                "border-2 w-full focus:outline-none focus:ring-0  placeholder:font-light  text-sm outline-gray-300 border-gray-300 p-2 rounded font-['nunito']",
                                            placeholder: "Start Date & Time",
                                            name: "resume_validity_date_from",
                                        }}
                                        autoComplete="off"
                                        value={new Date(dataForUpdatingResume?.resume_validity_date_from ?? "")}
                                        onChange={(e) => onChangeFunction("resume", "resume_validity_date_from", e?._d?.getTime())}
                                    />
                                </div>
                                <div className="w-full">
                                    <label
                                        className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
                                        htmlFor="resume_validity_date_to"
                                    >
                                        Resume Validity to
                                    </label>
                                    <Datetime
                                        className="font-normal w-full"
                                        autoComplete="off"
                                        inputProps={{
                                            className:
                                                "border-2 w-full focus:outline-none focus:ring-0  placeholder:font-light  text-sm outline-gray-300 border-gray-300 p-2 rounded font-['nunito']",
                                            placeholder: "Resume Validity to",
                                            name: "resume_validity_date_to",
                                        }}
                                        value={new Date(dataForUpdatingResume?.resume_validity_date_to ?? "")}
                                        onChange={(e) => onChangeFunction("resume", "resume_validity_date_to", e?._d?.getTime())}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={`flex justify-center items-center mt-2`}>
                            <UniversalButton
                                title="Update"
                                loading={updateSubscriptionLoading}
                                onClickFunction={() => dispatch(
                                    updateSubscription({
                                        data: {
                                            userId: dataForUpdating.userId._id,
                                            ...(whichField.test && { ...dataForUpdatingTest, test_remaining_flag: addRemaining.add_remaining_test }),
                                            ...(whichField.interview && { ...dataForUpdatingInterview, interview_remaining_flag: addRemaining.add_remaining_interview }),
                                            ...(whichField.resume && { ...dataForUpdatingResume, resume_remaining_flag: addRemaining.add_remaining_resume }),
                                        },
                                        setSubscriptionModal
                                    }))}
                            />
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isModal={deleteModal}
                className="w-[92%] sm:w-[80%] md:w-[55%] lg:w-[40%]"
            >
                <div className="p-6 text-center">
                    <svg
                        className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            strokeWidth="2"
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                    </svg>

                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this subscription</h3>

                    <UniversalButton loading={deleteSubscriptionLoading} title="Yes, I'm sure" onClickFunction={() => dispatch(deleteSubscription({ setDeleteModal, id: deleteModal }))} classNames='text-white bg-red-600 hover:bg-red-800 hover:text-slate-300 w-auto mr-5' />

                    <UniversalButton title="No, cancel" onClickFunction={() => setDeleteModal(null)} classNames='text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-900 border-gray-300 w-auto' />

                </div>
            </Modal>

        </>

    )
}

export default Subscription

