import React from "react";
import LoginUser from "../components/login";
import KyronSideLetter from "../assets/img/Kyron/KyronLetterSide.png";
import { ReactComponent as DataProtection } from "../assets/img/data-protection.svg";
import "../components/common/Common.css";
import { useNavigate } from "react-router-dom";

// import { PROTOCOL } from "../assets/js/config";

const Login = () => {
  const history = useNavigate();
  // componentDidMount() {
  //   console.log(window.location.protocol);
  //   if(window.location.protocol !== PROTOCOL) {
  //     console.log('wrong protocol', window.location.host);
  //     window.location = (`https://${window.location.host}/login`);
  //   }
  // }

  // useEffect(() => {
  // console.log(window.location.protocol);
  //   if(window.location.protocol !== PROTOCOL) {
  //     console.log('wrong protocol', window.location.host);
  //     window.location = (`https://${window.location.host}/login`);
  //   }
  // }, []);

  return (
    <div className="">
      <a href="/">
        <img src={KyronSideLetter} alt="LOGO" className="w-[220px] ml-4 my-4" />
      </a>
      <div className="grid lg:grid-cols-2 gap-5 items-center mt-[5rem]">
        {/* left part */}
        {/* <div className="flex  h-full items-center md:px-7 py-7 md:py-0"> */}
        <div
          className="justify-start items-center md:px-10 px-6 lg:border-r-2 lg:border-[rgba(0, 0, 0, 0.24)]"
        // style={{ borderRight: "1px solid rgba(0, 0, 0, 0.24)" }}
        >
          {/* <p className="text-3xl sm:text-4xl font-['poppins'] font-semibold  py-3">
              How can we help ?
            </p> */}
          <p className="text-3xl sm:text-5xl font-['poppins'] font-semibold md:px-[4rem] lg:px-0 xl:px-[4rem] text-center py-3">
            "Welcome back to Kyron! Your gateway to career excellence.”
          </p>
          {/* <p
              className="lg:text-lg sm:text-sm md:text-lg text-justify 

             md:leading-8 md:tracking-[0.025em] font-['poppins'] font-normal "
            >
              Kyron is the state of the art system designed and programmed by
              computer science researchers, organizational behavior and human
              psychology experts to find the perfect talent for every job and
              finding appropriate jobs for a candidate's skill set. Kyron's
              journey starts with the skill identification for each job profile
              and our AI algorithm searches for the appropriate set of
              candidates from the candidate pool. The candidates then go through
              a uniquely curated and personalized assessment and screening
              process. The reports are then forwarded to the interviewers. Not
              just that!! Kyron maintains the test integrity through our
              revolutionary Human & AI proctor combo, while assuring that
              candidate experience is exceptional at each step. Welcome to the
              future of Recruiting Science…
            </p> */}
          <div className="mt-[2rem] relative w-[24rem]">
            <div className="text-[0.9rem] font-bold  flex items-start flex-col text-right absolute top-[1rem] right-0">
              <p> Don't have an account ?</p>
              <p>
                You can{" "}
                <span
                  className="text-[#0337EF] hover:underline font-medium cursor-pointer"
                  onClick={() => {
                    history("/signup");
                  }}
                >
                  Sign Up
                </span>{" "}
                here
              </p>
            </div>

            <DataProtection />
          </div>
        </div>
        {/* </div> */}

        {/* Right part */}
        <div className="flex-col md:mx-14 sm:mx-8 mx-5">
          <LoginUser type="Candidate" />
        </div>
      </div>
    </div>
  );
};

export default Login;
