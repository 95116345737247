import dayjs from "dayjs";
import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { fetchMyProfile, getUserSubscribedPlans } from "../store/actions/user";
import { getType } from "../components/common/utils";
import { ReactComponent as Edit } from "../assets/img/svg/editPencil.svg";
import UniversalButton from "../components/common/StandardButton/UniversalButton";
import Modal from "../components/common/Modal/Modal";
import EditProfileModal from "../components/modals/EditProfileModal";
import { withRouter } from "../components/common/WithRouter";
import { getSingleSubscription } from "../store/actions/subscription";
// import profilePhoto from "../assets/img/Kyron/Vector"

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editProfileModal: false,
    };
  }

  componentDidMount() {
    this.props.fetchMyProfile();
    if (getType() === "S") this.props.getUserSubscribedPlans();
    if (getType() === "T") this.props.getSingleSubscription();
  }

  render() {
    console.log(this.props.personalSubscription, this.props.personalSubscriptionLoading)
    const { profile, Userplans } = this.props;
    return (
      <>
        <p className="mt-5 ml-7 font-['poppins'] font-medium text-[#37474F]">
          Profile
        </p>

        <div className="mt-5 mb-10 ml-7 flex flex-row items-center">
          <img
            className="w-36 mr-5 h-36"
            src={`https://ui-avatars.com/api/?name=${profile?.name}&bold=true&rounded=true&background=random`}
            alt="profile_photo"
          />

          <div className="flex flex-col justify-start">
            <h3 className="font-bold font-['nunito'] text-2xl text-[#394759]">
              {profile?.name}
            </h3>
            <p className="font-['nunito'] font-semibold text-lg text-[#394759]">
              {profile?.email}
            </p>
            <p className="font-['nunito'] font-semibold mb-5 text-md text-[#394759]">
              Mobile:{" "}
              <span className="font-['poppins'] text-slate-600 text-sm">
                {profile?.mobile}
              </span>
            </p>
            <UniversalButton
              onClickFunction={() =>
                this.setState((prev) => {
                  return { ...prev, editProfileModal: true };
                })
              }
              title={
                <div className="w-auto flex items-center justify-cenetr gap-[1rem]">
                  <Edit />
                  <p>Edit profile</p>
                </div>
              }
              classNames="w-fit flex items-center justify-center"
            />
          </div>
        </div>
        {getType() === "S" && (
          <div className="ml-7 mb-15">
            <h2 className="font-['poppins'] text-2xl mb-3 font-medium not-italic pl-2.5 text-[#37474F]">
              My Subscriptions
            </h2>
            {Userplans && Userplans?.length !== 0 ? (
              <div className="flex gap-x-12">
                {Userplans.map((plan, index) => (
                  <div
                    key={`${index}-plan`}
                    className="max-w-[300px] rounded-xl box-border bg-[#FFFFFF] border-[1px] border-[rgba(218, 220, 224, 0.24)]"
                  >
                    <div className="rounded-t-xl	bg-cyan-400 text-white font-['poppins'] font-bold p-2">
                      {" "}
                      {plan?.plan_id?.title}
                    </div>
                    <div className="p-2.5">
                      {/* <h2 className="font-['open sans'] font-normal font-semibold text-sm text-[#394759]">
                      {plan?.plan_id?.title}
                    </h2> */}
                      <p className="font-['open sans'] font-normal font-semibold text-xs text-[#8193A8]">
                        {plan?.plan_id?.description}
                      </p>
                      <p className="font-['open sans'] font-normal font-semibold text-xs text-[#8193A8]">
                        <span className="text-slate-700 font-['nunito']">
                          Payment Date :{" "}
                        </span>
                        {dayjs(plan?.success_at).format("DD/MM/YYYY  HH:mm A")}
                      </p>
                      <p className="font-['open sans'] font-normal font-semibold text-xs text-[#8193A8]">
                        <span className="text-slate-700 font-['nunito']">
                          Payment Id :{" "}
                        </span>
                        {plan?.payment_id}
                      </p>
                      <p className="font-['open sans'] font-normal font-semibold text-xs text-[#8193A8]">
                        <span className="text-slate-700 font-['nunito']">
                          Reference Id :{" "}
                        </span>
                        {plan?.reference_id}
                      </p>
                      <div className="flex justify-between items-center mt-5 px-2.5">
                        <div className="flex flex-col justify-between items-center">
                          <p className="font-['open sans'] font-normal text-xs text-[#567191]">
                            Amount
                          </p>
                          <span
                            className={`font-['nunito'] font-medium bg-rose-400
                          } font-semibold text-sm  py-1 px-2.5 rounded-xl text-white`}
                          >
                            ₹ {plan?.amount}
                          </span>
                        </div>

                        <div className="flex flex-col justify-between items-center">
                          <p className="font-['nunito'] font-normal text-xs text-[#567191]">
                            Payment Status
                          </p>
                          <span
                            className={`font-['nunito'] font-medium ${plan?.payment_status === "SUCCESS"
                              ? "bg-teal-300 "
                              : "bg-red-500"
                              } font-semibold text-sm  py-1 px-2.5 rounded-xl text-white`}
                          >
                            {plan?.payment_status}
                          </span>
                        </div>

                        <div className="flex flex-col justify-between items-center">
                          <p className="font-['nunito'] font-normal text-xs text-[#567191]">
                            Current Status
                          </p>
                          <span
                            className={`font-['open sans'] font-medium ${plan?.current_status === "ACTIVE"
                              ? "bg-teal-300 "
                              : "bg-red-500"
                              } font-semibold text-sm  py-1 px-2.5 rounded-xl text-white`}
                          >
                            {plan?.current_status}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="flex justify-center items-center font-['poppins']">
                No Subscriptions Available
              </p>
            )}
          </div>
        )}

        {
          getType() === "T" && !this.props.personalSubscriptionLoading ? <div className="ml-7 rounded-lg p-3 w-[24rem] bg-[#BCE74F]">

            <div className="flex items-center justify-between mb-3">
              <p className="font-semibold text-[1.1rem]">Subscription</p>
            </div>
            <div className="flex items-center justify-between">
              <p >Test Allowed (Remaining)</p>
              <p>{this.props.personalSubscription[0]?.test_allowed}({this.props.personalSubscription[0]?.test_remaining})</p>
            </div>
            <div className="flex items-center justify-between">
              <p >Interview Allowed (Remaining)</p>
              <p>{this.props.personalSubscription[0]?.interview_allowed}({this.props.personalSubscription[0]?.interview_remaining})</p>
            </div>
            <div className="flex items-center justify-between">
              <p >Resume Allowed (Remaining)</p>
              <p>{this.props.personalSubscription[0]?.resume_allowed}({this.props.personalSubscription[0]?.resume_remaining})</p>
            </div>
            <div className="flex items-center justify-between">
              <p >Students Allowed (Remaining)</p>
              <p>{this.props.personalSubscription[0]?.students_allowed}({this.props.personalSubscription[0]?.students_remaining})</p>
            </div>

          </div> : null
        }

        <Modal
          isModal={this.state.editProfileModal}
          showCloseButton
          onClose={() =>
            this.setState((prev) => {
              return { ...prev, editProfileModal: false };
            })
          }
        >
          <EditProfileModal
            data={profile}
            closeModal={() =>
              this.setState((prev) => {
                return { ...prev, editProfileModal: false };
              })
            }
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({ user, SubscriptionReducer }) => {
  return {
    profile: user.profile,
    Userplans: user.userSubscribedPlans,
    personalSubscription: SubscriptionReducer.personalSubscription,
    personalSubscriptionLoading: SubscriptionReducer.personalSubscriptionLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMyProfile: () => dispatch(fetchMyProfile()),
    getUserSubscribedPlans: () => dispatch(getUserSubscribedPlans()),
    getSingleSubscription: () => dispatch(getSingleSubscription()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Profile));
