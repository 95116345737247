import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchTestRequest,
  setTestStatus,
  fetchStudentResponseFromRedis,
  updateTestEndTimeRequest,
  updateStarterCardActive,
} from "../../../store/actions/test";
import { toggleSidebar } from "../../../store/actions/sidebar";
import { fetchTurnServerCreds } from "../../../store/actions/turnserver";
import { toast } from "react-toastify";
import { findDuration, formateTime } from "../utils/TimeFormat";
import PublicTestModal from "../publicTest/PublicTestModal";
import dayjs from "dayjs";
import { updateQuizSet } from "../../../services/test.service";
import { getType } from "../../common/utils";
import UniversalButton from "../../common/StandardButton/UniversalButton";
import load from "../../../assets/img/svg/load.svg";
import { useState } from "react";
import { useEffect } from "react";

import { defaultToastSetting } from "../../../assets/js/toast_config";
import { withRouter } from "../../common/WithRouter";
import { useNavigate, useParams } from "react-router-dom";

const WelcomePage = () => {
  const [selectedTest, setSelectedTest] = useState("");
  const dispatch = useDispatch();
  const quizId = useParams()?.id;
  const { quizAssignDetails, test, isEndTimeUpdateApiCalled } = useSelector((state) => state.test);
  const { opened } = useSelector((state) => state.sidebar);
  const { credentials } = useSelector((state) => state.turnserver);
  const history = useNavigate();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (opened) {
      dispatch(toggleSidebar());
      // this.props.toggleSidebar();
    }
    dispatch(updateStarterCardActive(2));
    dispatch(setTestStatus(false));
    dispatch(fetchTestRequest(quizId, { shuffle: true }));
    dispatch(fetchStudentResponseFromRedis(quizId));
    dispatch(fetchTurnServerCreds());
    // this.props.setTestStatus(false);
    // this.props.fetchTestRequest(this.props.params.id, { shuffle: true });
    // this.props.fetchStudentResponse(this.props.params.id);
    // this.props.fetchMyProfile();
    // this.props.fetchTurnServerCreds();
    // this.props.fetchQuizProctoringDetails(this.props.params.id);
  }, []);

  useEffect(() => {
    if (quizAssignDetails?.quiz_set && quizAssignDetails?.quiz_set !== quizId) {
      history(`/tests/welcome-page/${quizAssignDetails?.quiz_set}`);
    }

    if (isEndTimeUpdateApiCalled && Boolean(quizAssignDetails?.start_time) && Boolean(quizAssignDetails?.end_time)) {
      nextPageProcess();
    }
  }, [quizAssignDetails]);
  // componentDidMount() {
  //   if (this.props.sidebar.opened) {
  //     this.props.toggleSidebar();
  //   }
  //   this.props.setTestStatus(false);
  //   this.props.fetchTestRequest(this.props.params.id, { shuffle: true });
  //   this.props.fetchStudentResponse(this.props.params.id);
  //   // this.props.fetchMyProfile();
  //   this.props.fetchTurnServerCreds();
  //   // this.props.fetchQuizProctoringDetails(this.props.params.id);
  // }

  // componentDidUpdate(prevProps) {
  //   if (
  //     this.props.quizAssign !== prevProps.quizAssign &&
  //     this.props.quizAssign.quiz_set &&
  //     this.props.quizAssign.quiz_set !== this.props.params.id
  //   ) {
  //     this.props.history(
  //       `/tests/welcome-page/${this.props.quizAssign.quiz_set}`
  //     );
  //   }
  //   if (
  //     getType() === "S" &&
  //     this.props.quizAssign._id &&
  //     this.props.response === null &&
  //     this.props.loading === false
  //   ) {
  //     this.props.fetchStudentResponseFromRedis(this.props.quizAssign._id);
  //   }
  // }

  const addDurationToEnd = (examDuration) => {
    const endDate = new Date();
    endDate.setMinutes(endDate.getMinutes() + parseInt(examDuration, 10));
    const result = endDate.toISOString();
    return result;
  }

  const nextPageProcess = () => {
    if (test.is_public) {
      history(`/tests/giveTest/${quizId}?public=true`);
    } else if (test?.allow_set_choose) {
      if (selectedTest === "") {
        toast.warning("Please choose a set", defaultToastSetting);
      } else {
        updateQuizSet({
          id: quizId,
          quiz_set: selectedTest,
        })
          .then((res) => {
            if (res.success) {
              history(`/tests/welcome-page/${selectedTest}`);
              window.location.reload();
            }
          })
          .catch((e) => {
            toast.error(e.response.data.msg, defaultToastSetting);
          });
      }
    } else {
      setLoading(false)
      history(`/tests/giveTest/${quizId}`);
    }
  }


  const handleNextPage = () => {
    setLoading(true);
    if (getType() !== 'S' || checkGiveTestEligibility()) {
      if (getType() === 'T' || (!test?.allow_flexible_exam_time && (Boolean(quizAssignDetails?.start_time))) || (test?.allow_flexible_exam_time && (Boolean(quizAssignDetails?.start_time) && Boolean(quizAssignDetails?.end_time)))) {
        nextPageProcess();
      } else {
        const start_time = new Date();
        const end_time = addDurationToEnd(test?.exam_duration)
        dispatch(updateTestEndTimeRequest({ id: quizAssignDetails?._id, data: { end_time: test?.allow_flexible_exam_time ? end_time : test?.end_time, start_time } }))
      }
    } else {
      toast.error("Quiz is ended!");
    }
  };

  const decreaseMinutesFromDate = (date, x) => {
    // Copy the original date to avoid modifying the original object
    const newDate = new Date(date);

    // Subtract x minutes from the new date
    newDate.setMinutes(newDate.getMinutes() - x);

    return newDate;
  }

  const checkGiveTestEligibility = () => {
    if(getType() === "T")return true
    console.log("checking give tes eleibility", quizAssignDetails?.end_time)
    let end_duration = (test?.allow_flexible_exam_time && Boolean(quizAssignDetails?.start_time)) ? quizAssignDetails?.end_time : test?.end_duration;
    if (test?.allow_flexible_exam_time && !Boolean(quizAssignDetails?.start_time)) {
      end_duration = decreaseMinutesFromDate(end_duration, test?.exam_duration)
    }
    return new Date(end_duration).getTime() > new Date().getTime()
  };

  const showTime = (data) => {
    let date = dayjs(data);
    return date.format("dddd, MMMM D YYYY, h:mm a");
  };

  // render() {
  // const { test } = this.props;
  return (
    <div
      className="flex flex-col px-3 md:px-0 justify-between md:justify-start overflow-y-auto items-center h-[93vh] w-full"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}+ "/bg.JPEG"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {!test?.public_test_registered_data?.isSubmitted && <PublicTestModal />}
      <div className="flex w-full flex-col md:flex-row justify-center gap-1 md:gap-5 lg:py-10 sm:py-5 py-2 md:px-8 lg:px-14 sm:px-5">
        {!test.is_public && (
          <div className="flex lg:w-1/2 md:order-first order-last justify-start items-center px-2 md:border-r-2 md:border-[#a0db32]">
            <div className="flex justify-center full  py-5">
              <div className="flex-col items-center justify-center">
                <h1 className="text-xl  font-semibold font-['poppins']">
                  General Instructions
                </h1>
                <hr className="w-full py-1" />
                <ul>
                  <li className="flex items-start justify-center">
                    <span className="p-1 m-2 rounded-full bg-black"></span>
                    <p className="mx-2 font-medium font-['nunito']">
                      Please note that this is a proctored test, and you are
                      required to show an identity card and share your live feed
                      and screen during the test.
                    </p>
                  </li>
                  <li className="flex items-start justify-start">
                    <span className="p-1 m-2 rounded-full bg-black"></span>
                    <p className="mx-2 font-medium font-['nunito']">
                      Since this is a proctored test, we can see and listen to
                      all your activities.
                    </p>
                  </li>
                  <li className="flex items-start justify-center">
                    <span className="p-1 m-2 rounded-full bg-black"></span>
                    <p className="mx-2 font-medium font-['nunito']">
                      You are requested not to use unfair means during the test,
                      such as taking help from someone, using unauthorized
                      resources, etc. These could lead to your disqualification
                      from the test.
                    </p>
                  </li>
                  <li className="flex items-start justify-center">
                    <span className="p-1 m-2 rounded-full bg-black"></span>
                    <p className="mx-2 font-medium font-['nunito']">
                      Your camera should clearly show your face and background.
                      Your ears must be visible and uncovered (no headphones or
                      earphones).
                    </p>
                  </li>
                  <li className="flex items-start justify-center">
                    <span className="p-1 m-2 rounded-full bg-black"></span>
                    <p className="mx-2 font-medium font-['nunito']">
                      If you violate any of these rules, Kyron reserves the
                      right to invalidate your test results, and you may also be
                      banned from any future tests on the Kyron platform for our
                      partner companies.
                    </p>
                  </li>
                  <li className="flex items-start justify-start">
                    <span className="p-1 m-2 rounded-full bg-black"></span>
                    <p className="mx-2 font-medium font-['nunito']">
                      We will collect your data to provide you a better user
                      experience with the platform.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
        <div className="flex w-auto lg:w-1/2 justify-start items-start pb-0 pt-5 md:py-5 pl-5">
          <div className="flex-col text-start">
            <h1 className="text-xl font-semibold font-['poppins']">
              Test for {test.title}
            </h1>
            <hr className="w-full py-1" />
            <p className="text-lg font-['nunito']">
              By <span className="font-semibold">{test?.company?.name}</span>
            </p>
            {test.is_public ? (
              <>
                <p className=" font-['poppins']">
                  Start Date:{" "}
                  <span className="font-medium">
                    {showTime(test?.start_duration)}
                  </span>
                </p>
                <p className=" font-['poppins']">
                  End Date:{" "}
                  <span className="font-medium">
                    {showTime(test?.end_duration)}
                  </span>
                </p>
              </>
            ) : (
              <>
                {/* {test?._id && credentials?.username && credentials?.password ? */}

                <>
                  {console.log("checking ininsi", test, quizAssignDetails)}
                  <p className=" font-['poppins']">
                    Start Time:{" "}
                    <span className="font-medium">
                      {(test?.allow_flexible_exam_time && Boolean(quizAssignDetails?.start_time)) ? formateTime(quizAssignDetails?.start_time) : formateTime(test?.start_duration)}
                    </span>
                  </p>
                  <p className=" font-['poppins']">
                    End Time:{" "}
                    <span className="font-medium">
                      {(test?.allow_flexible_exam_time && Boolean(quizAssignDetails?.end_time)) ? formateTime(quizAssignDetails?.end_time) : formateTime(test?.end_duration)}

                    </span>
                  </p>
                  <p className=" font-['poppins']">
                    Duration:{" "}
                    <span className="font-medium">
                      {test?.allow_flexible_exam_time ? Boolean(quizAssignDetails?.end_time) ? findDuration(quizAssignDetails?.start_time, quizAssignDetails?.end_time) : test?.exam_duration + " Minutes" : findDuration(test?.start_duration, test?.end_duration)}
                    </span>
                  </p>
                  {!Boolean(quizAssignDetails?.end_time) && getType() !== "T" &&
                    <p className=" font-['poppins']">
                      Note:{" "}
                      <span className="font-medium">
                        Please note that once you start the test, you are expected to complete it within the above duration. Once initiated, the test cannot be paused, resumed, or exited. Ensure that you have sufficient time available to complete the entire examination before starting. Good luck!
                      </span>
                    </p>
                  }
                </>
                {/* : null} */}
              </>
            )}
            {test.is_public ? (
              <>
                {checkGiveTestEligibility() ? (
                  <>
                    {!test.public_test_registered_data.isSubmitted ? (
                      <>
                        <button
                          onClick={() => handleNextPage()}
                          className="text-[#001437] bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32] font-bold rounded-lg 
                            md:text-[16px] md:px-6 text-sm  text-lg px-3 py-2 text-center mr-2 font-['nunito'] cursor-pointer ]"
                        >
                          <span className="font-semibold">
                            {Boolean(quizAssignDetails?.end_time) ? "Resume Test" : "Let's get started"}

                          </span>
                        </button>
                      </>
                    ) : (
                      <>
                        <p className="text-[#009696] hover:text-white hover:bg-[#009696] text-lg  font-semibold px-3 py-1  rounded border-2 border-[#009696] my-2">
                          Your response has been recorded.
                        </p>
                      </>
                    )}
                  </>
                ) : (
                  <p className="text-[#009696] hover:text-white hover:bg-[#009696] text-lg  font-semibold px-3 py-1  rounded border-2 border-[#009696] my-2">
                    Form has been closed please contact the owner of the form.
                  </p>
                )}
              </>
            ) : (
              <>
                {!quizAssignDetails?.isSubmitted ? (
                  <>
                    {test?._id && credentials?.username && credentials?.password ? (
                      <>
                        <div className="mt-2">
                          {!quizAssignDetails?.quiz_set &&
                            test?.allow_set_choose && (
                              <>
                                <h1 className="font-['poppins'] font-semibold">
                                  Select a set to continue
                                </h1>
                                <select
                                  onChange={(e) =>
                                    setSelectedTest(e.target.value)
                                  }
                                  value={selectedTest}
                                  className="border bg-gray-50 font-['poppins'] text-black shadow-md rounded-lg px-2 py-1 my-2"
                                >
                                  <option>Choose a set</option>
                                  {test?.sets?.map((test) => (
                                    <option
                                      value={test._id}
                                      key={`test-${test._id}`}
                                    >
                                      {test.title}
                                    </option>
                                  ))}
                                </select>
                                <p className="font-['nunito'] pb-1">
                                  <span className="text-red-600 font-medium font-['nunito']">
                                    Note:{" "}
                                  </span>
                                  You are allowed to select the set only once.
                                </p>
                              </>
                            )}

                          {checkGiveTestEligibility() ?
                            <button
                              onClick={() => handleNextPage()}
                              className="text-[#001437] bg-[#BCFB46] hover:bg-[#001437] hover:text-[#a0db32] font-bold rounded-lg 
                            md:text-[16px] md:px-6 text-sm  min-w-[200px]  px-3 py-1.5 text-center mr-2 font-['nunito'] cursor-pointer ]"
                            >
                              {loading ? (
                                <img
                                  alt="loading"
                                  src={load}
                                  className="spinner my-1 min-2- mx-auto w-5 h-5 text-white "
                                />
                              ) : (
                                <span className="font-semibold">
                                  {Boolean(quizAssignDetails?.end_time) ? "Resume Test" : "Let's get started"}

                                </span>
                              )}

                            </button>
                            :
                            <p className="mt-2 font-['poppins'] text-[#A0DB32]  text-lg  font-semibold ">
                              Test login period has ended.
                            </p>
                          }
                          {/* <UniversalButton
                            onClickFunction={() => handleNextPage()}
                            title={Boolean(quizAssignDetails?.end_time) ? "Resume Test" : "Let's get started"}
                            classNames="mb-6 mr-2"
                          /> */}
                        </div>
                      </>
                    ) : (
                      <p className="mt-2 font-['poppins'] text-[#A0DB32]  text-lg  font-semibold ">Please wait test is loading</p>
                    )}
                  </>
                ) : (
                  <p className="mt-4 text-[#A0DB32] hover:text-white hover:bg-[#009696] text-lg  font-semibold px-3 py-1  rounded border-2 border-[#A0DB32]">
                    You have already submitted the test
                  </p>
                )}

              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row w-full justify-center gap-5 pb-5 md:pl-16 sm:px-5 px-2">
        <div className="flex-col sm:w-1/2 items-center">
          <div className="flex  w-full">
            <div className="flex-col">
              <h1 className="text-xl  font-semibold font-['poppins']">
                Help & Support
              </h1>
              <hr className="w-full py-1" />
              <ul>
                <li className="font-['nunito'] font-medium">
                  Please contact the test administrator at{" "}
                  <a
                    href="mailto: helpdesk@kyron.in"
                    className=" text-[#97cf30]"
                  >
                    helpdesk@kyron.in
                  </a>{" "}
                  or{" "}
                  <a
                    href="tel:+91-8800966082"
                    className=" text-[#97cf30] text-sm"
                  >
                    +91-8800966082
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {!test?.is_public && (
          <div className="flex sm:w-1/2 justify-start items-start">
            <div className="flex-col text-start">
              <div className="w-full">
                <h1 className="text-xl  font-semibold font-['poppins']">
                  Recommended before your test
                </h1>
                <hr className="w-full py-1" />
              </div>
              <ul>
                <li className="font-['nunito'] font-medium text-[#97cf30]">
                  <a href="/systemcompatibility">Take Kyron's System Check</a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// const mapStateToProps = ({ test, sidebar, turnserver, user }) => {
//   console.log({ test });
//   return {
//     error: test.errorMessage,
//     test: test.test,
//     public_test_details: test.public_test_registered_data,
//     isSubmitted: test.isSubmitted,
//     sidebar: sidebar,
//     credentials: turnserver.credentials,
//     profile: user.profile,
//     response: test.response,
//     quizAssign: test.quizAssignDetails,
//     loading: test.loading,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     updateStarterCardActive: (data) => dispatch(updateStarterCardActive(data)),
//     fetchQuizProctoringDetails: (data) =>
//       dispatch(fetchQuizProctoringDetails(data)),
//     toggleSidebar: () => dispatch(toggleSidebar()),
//     fetchTurnServerCreds: () => dispatch(fetchTurnServerCreds()),
//     fetchAllTests: (data) => dispatch(fetchAllTests(data)),
//     fetchTestRequest: (data, options) =>
//       dispatch(fetchTestRequest(data, options)),
//     fetchStudentResponseFromRedis: (data) =>
//       dispatch(fetchStudentResponseFromRedis(data)),
//     fetchStudentResponse: (data) => dispatch(fetchStudentResponse(data)),
//     fetchMyProfile: () => dispatch(fetchMyProfile()),
//     setTestStatus: (data) => dispatch(setTestStatus(data)),
//   };
// };

export default connect()(withRouter(WelcomePage));
// mapStateToProps,
// mapDispatchToProps
