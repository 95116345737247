import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as UploadDocument } from "../../assets/img/svg/Upload_Document.svg";
import { ReactComponent as Close } from "../../assets/img/svg/navbar_close.svg";
import { ReactComponent as File } from "../../assets/img/svg/file.svg";
// import plus from "../../../assets/img/svg/plus4.svg";
import { ReactComponent as Delete } from "../../assets/img/svg/deletecolor.svg";
import load from "../../assets/img/svg/load.svg";
import { uploadFile, viewFile } from "../../services/upload.service";
import {
  extractQuestionRequest,
  uploadFileRequest,
} from "../../store/actions/extractQuestion";
import { fetchMyProfile } from "../../store/actions/user";
import "./ExtractQuestion.css";
import RenderQuestionAnswer from "./components/RenderQuestionAnswer";
import UniversalButton from "../../components/common/StandardButton/UniversalButton";
import { defaultToastSetting } from "../../assets/js/toast_config";
import { toast } from "react-toastify";

const ExtractQuestion = () => {
  const dispatch = useDispatch();
  let inputRef = useRef(null);

  const [showParsingButton, setShowParsingButton] = useState(false);
  const [uploadFileOrQuestion, setUploadFileOrQuestion] = useState(false);
  const [qNumberType, setQNumberType] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileURL, setFileURL] = useState(null);
  const [fileData, setFileData] = useState(null);

  const { profile } = useSelector((state) => state.user);
  const { fileToken, uploadFileLoading, extractQuestionLoading } = useSelector(
    (state) => state.extractQuestionReducer
  );

  const handleExtract = () => {
    for (const t of qNumberType) {
      if (t.type === "") {
        toast.warning(
          " question type should not be empty",
          defaultToastSetting
        );
        return;
      }
      if (+t.number === 0) {
        toast.warning(
          "number of question should not be 0",
          defaultToastSetting
        );
        return;
      }
    }

    if (fileToken.sourceId) {
      setUploadFileOrQuestion(true);
      dispatch(
        extractQuestionRequest({
          sourceId: fileToken.sourceId,
          question_details: qNumberType,
        })
      );
    }
  };
  const fileChangeHandler = (file, cb) => {
    if (file) {
      setFileData(file);

      setFileLoading(true);
      uploadFile(file, {
        type: "USER-RESUMES",
        id: profile._id,
      })
        .then((filename) => {
          setFileName(filename);
        })
        .finally(() => setFileLoading(false));
    }

    if (cb && typeof cb === "function") {
      cb();
    }
  };

  const handleUploadClick = () => {
    setQNumberType([{ number: "", type: "" }]);
    if (fileURL)
      dispatch(uploadFileRequest({ url: fileURL, setShowParsingButton }));
  };

  useEffect(() => {
    dispatch(fetchMyProfile());
  }, []);

  useEffect(() => {
    if (fileName)
      viewFile(fileName, "USER-RESUMES").then((res) => {
        setFileURL(res);
      });
  }, [fileName]);

  return (
    <>
      <div className="w-[100%] ">
        {/* <RenderQuestionAnswer questionAnswer={questionAnswer} /> */}
        {uploadFileOrQuestion ? (
          <div className="py-[2rem] w-[100%] bg-[#efefef] h-auto gap-4 flex flex-col justify-start items-center">
            {extractQuestionLoading ? (
              [...Array(4).keys()].map((_, index) => (
                <div className="skeletonLoader" key={index} />
              ))
            ) : (
              <RenderQuestionAnswer />
            )}
          </div>
        ) : (
          <div className="w-[100%] h-[90vh] gap-4 flex flex-col justify-center items-center">
            <div
              className={`w-[90%] md:w-[60%] lg:w-[30%] h-[14rem] rounded-lg flex flex-col items-center border-dashed border-[#1f1f1f] border-2 text-[#8193A8] ${
                !fileLoading ? "justify-end" : "justify-center"
              }`}
            >
              {fileLoading ? (
                <img
                  alt="loading"
                  src={load}
                  className="spinner mr-2 w-6 h-6 text-primary "
                />
              ) : (
                <>
                  <button
                    className="w-[100%] flex items-center flex-col justify-center gap-1 cursor-auto"
                    onClick={() => inputRef.click()}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      fileChangeHandler(e.dataTransfer.files[0]);
                    }}
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        // console.log(e.target.files[0]);
                        fileChangeHandler(e.target.files[0], () => {
                          e.target.value = null;
                        });
                      }}
                      ref={(fileInput) => (inputRef = fileInput)}
                      accept="application/pdf"
                    />
                    <div className="flex flex-col justify-center items-center">
                      <UploadDocument
                        style={{ width: "3rem", height: "3rem" }}
                      />
                      <p>Drag and drop file here</p>
                      <p>or</p>
                      <p className="text-[#2b2ef0] cursor-pointer font-semibold">
                        Browse file
                      </p>
                    </div>
                  </button>
                  <p className="mt-[1rem] font-semibold text-[0.8rem] text-[#333333]">
                    Supported formats
                  </p>
                  <p className="px-4 bg-[#333333] pb-[0.15rem] text-[#d1d1d1] text-[0.7rem] rounded-xl mb-2">
                    pdf
                  </p>
                </>
              )}
            </div>

            {fileURL ? (
              <div className="w-[90%] md:w-[60%] lg:w-[30%] px-[1rem] py-[0.5rem] bg-[#fafafa] flex  rounded-[1rem] items-center relative gap-[1rem]">
                <File style={{ width: "3rem", height: "3rem" }} />
                <div className="flex items-start flex-col">
                  <p>{fileData?.name}</p>
                  <p className="text-[#8193A8]">
                    File size : {fileData?.size / 1000} KB
                  </p>
                </div>
                <Close
                  className="w-[1rem] h-[1rem] rounded-[50%] cursor-pointer absolute right-[0.6rem] top-[0.6rem]"
                  onClick={() => {
                    setFileURL(null);
                    setFileName(null);
                    setFileData(null);
                  }}
                />
              </div>
            ) : null}

            {showParsingButton && !uploadFileLoading && fileURL ? (
              <div className="w-[90%] md:w-[60%] lg:w-[30%]">
                {qNumberType.map((_, ind) => (
                  <div
                    key={ind}
                    className="flex justify-between items-center relative"
                  >
                    <div
                      className="w-[47%] text-[#1b2709] rounded font-medium text-[1rem] my-3"
                      style={{
                        boxShadow:
                          " rgba(0, 0, 0, 0.2) 0px 8px 16px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px",
                      }}
                    >
                      <select
                        className="w-[100%] p-2 rounded-[1rem] outline-none"
                        name="type"
                        value={qNumberType[ind].type}
                        onChange={(e) =>
                          setQNumberType((prev) => {
                            const newState = [...prev];
                            newState[ind].type = e.target.value;
                            return newState;
                          })
                        }
                      >
                        <option value="">Choose Question Type...</option>
                        <option value="O"> Objective</option>
                        <option value="S">Subjective</option>
                      </select>
                    </div>
                    <div
                      className="w-[47%] text-[#1b2709] rounded font-medium text-[1rem] my-3"
                      style={{
                        boxShadow:
                          " rgba(0, 0, 0, 0.2) 0px 8px 16px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px",
                      }}
                    >
                      <input
                        type="text"
                        name="number"
                        className="w-[100%] p-2 rounded-[1rem] outline-none"
                        value={qNumberType[ind].number}
                        placeholder="Number of questions"
                        onChange={(e) =>
                          setQNumberType((prev) => {
                            const newState = [...prev];
                            newState[ind].number = e.target.value;
                            return newState;
                          })
                        }
                      />
                    </div>
                    <div
                      className="flex cursor-pointer items-center justify-end rounded-full absolute top-[1.5rem] right-[-2.3rem]"
                      onClick={() => {
                        setQNumberType((prev) => {
                          const newState = [...prev];
                          newState.splice(ind, 1);
                          return newState;
                        });
                      }}
                    >
                      <Delete />
                    </div>
                  </div>
                ))}
              </div>
            ) : null}

            <div className="flex items-center justify-center gap-[2rem]">
              {!showParsingButton && fileName ? (
                <UniversalButton
                  classNames="py-3"
                  loading={uploadFileLoading}
                  title="Upload Document"
                  onClickFunction={() => handleUploadClick()}
                />
              ) : null}
              {showParsingButton && !uploadFileLoading && fileURL ? (
                <>
                  <UniversalButton
                    classNames="py-3"
                    loading={uploadFileLoading}
                    title="Add More"
                    onClickFunction={() =>
                      setQNumberType((prev) => {
                        return [...prev, { number: "", type: "" }];
                      })
                    }
                  />
                  <UniversalButton
                    classNames="py-3"
                    loading={uploadFileLoading}
                    title="Extract Question"
                    onClickFunction={() => handleExtract()}
                  />
                </>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ExtractQuestion;
