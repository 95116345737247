import React, { Component } from "react";

// Store
import { connect } from "react-redux";

import "react-toastify/dist/ReactToastify.css";
import { registerPublicTestToken } from "../../../store/actions/auth";
import { updatePublicTestStudentDetails } from "../../../store/actions/test";
import { withRouter } from "../../common/WithRouter";

class PublicTestModal extends Component {
  constructor() {
    super();
    this.state = {
      details: {
        name: "",
        email: "",
        mobile: "",
        nameError: "",
        emailError: "",
        mobileError: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let details = localStorage.getItem("registered_details");
    // console.log("didmount", JSON.parse(details));
    if (details !== null) {
      this.setState({ details: JSON.parse(details) });
    }
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    let new_form_data = { ...this.state.details };
    new_form_data[name.split("-")[0]] = value;
    new_form_data[name.split("-")[1]] = "";
    this.setState({ details: new_form_data });
    localStorage.setItem("registered_details", JSON.stringify(new_form_data));
  };

  validate = () => {
    let nameError = "";
    let emailError = "";
    let mobileError = "";

    if (!this.state.details.name) {
      nameError = "Name cannot be blank.";
    }
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (
      re.test(this.state.details.mobile) === false ||
      this.state.details.mobile.length !== 10
    ) {
      mobileError = "Invalid Mobile Number";
    }

    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        this.state.details.email
      ) === false
    ) {
      emailError = "Invalid Email";
    }
    if (emailError || nameError || mobileError) {
      this.setState((prev) => {
        return {
          ...prev,
          details: {
            ...prev.details,
            emailError,
            nameError,
            mobileError,
          },
        };
      });
      return false;
    }

    return true;
  };

  handleSubmit() {
    if (this.validate()) {
      this.props.updatePublicTestStudentDetails({
        email: this.state.details.email,
        name: this.state.details.name,
        mobile: this.state.details.mobile,
        quiz: this.props.test._id,
      });
      this.props.registerPublicTestToken({
        email: this.state.details.email,
        name: this.state.details.name,
        mobile: this.state.details.mobile,
      });
    }
  }

  render() {
    return (
      <>
        {this.props.public_test_modal && (
          <div
            className={`flex justify-center items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-40 w-full md:inset-0 h-full md:h-full`}
            id="popup-modal"
            style={{ backgroundColor: "rgb(0 ,0 ,0,0.1)" }}
          >
            <div className="flex justify-center items-center px-4 w-full h-full md:h-auto">
              <div className="flex justify-center items-center relative w-full min-w-xs max-w-xl h-full md:h-auto">
                <div className=" relative w-full rounded-lg bg-white shadop-2">
                  <div className="flex justify-between items-center w-full p-4">
                    <h1 className="text-center p-1.5 pl-3.5 font-poppins tracking-[0.18px] font-semibold text-[#989898] text-[20px]">
                      Register
                    </h1>
                  </div>
                  <div className="w-full">
                    <div className="rounded p-5 pt-0">
                      <div className="mb-4">
                        <div className="relative">
                          <input
                            className="border-2 border-gray-300 block px-2.5 pb-2 pt-3 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 focus:border-[#009696] peer"
                            id="name"
                            name="name-nameError"
                            value={this.state.details.name}
                            onChange={(e) => this.handleChange(e)}
                            type="text"
                            placeholder=" "
                          />
                          <label
                            htmlFor="Titlename"
                            className="font-poppins absolute text-sm text-[#2D333A] duration-300 transform -translate-y-4 scale-75 top-2  origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[#009696] 
                                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                                peer-focus:scale-75 peer-focus:-translate-y-4 left-2"
                          >
                            Name
                          </label>
                        </div>
                        {this.state.nameError !== "" && (
                          <p className="text-red-500 text-xs ml-2 pt-1">
                            {this.state.details.nameError}
                          </p>
                        )}
                      </div>

                      <div className="mb-4">
                        <div className="relative">
                          <input
                            className="border-2 border-gray-300 block px-2.5 pb-2 pt-3 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 focus:border-[#009696] peer"
                            id="email"
                            name="email-emailError"
                            value={this.state.details.email}
                            onChange={(e) => this.handleChange(e)}
                            type="text"
                            placeholder=" "
                          />
                          <label
                            htmlFor="Titlename"
                            className="font-poppins absolute text-sm text-[#2D333A] duration-300 transform -translate-y-4 scale-75 top-2  origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[#009696] 
                                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                                peer-focus:scale-75 peer-focus:-translate-y-4 left-2"
                          >
                            Email
                          </label>
                        </div>
                        {this.state.details.emailError !== " " && (
                          <p className="text-red-500 text-xs ml-2 pt-1">
                            {this.state.details.emailError}
                          </p>
                        )}
                      </div>

                      <div className="mb-4">
                        <div className="relative">
                          <input
                            className="border-2 border-gray-300 block px-2.5 pb-2 pt-3 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 focus:border-[#009696] peer"
                            id="mobile"
                            name="mobile-mobileError"
                            value={this.state.details.mobile}
                            onChange={(e) => this.handleChange(e)}
                            type="text"
                            placeholder=" "
                          />
                          <label
                            htmlFor="Titlename"
                            className="font-poppins absolute text-sm text-[#2D333A] duration-300 transform -translate-y-4 scale-75 top-2  origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[#009696] 
                                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                                peer-focus:scale-75 peer-focus:-translate-y-4 left-2"
                          >
                            Mobile
                          </label>
                        </div>
                        {this.state.details.mobileError !== " " && (
                          <p className="text-red-500 text-xs ml-2 pt-1">
                            {this.state.details.mobileError}
                          </p>
                        )}
                      </div>

                      <div
                        className={`flex justify-end
                       items-center gap-x-3`}
                      >
                        <button
                          onClick={() => this.handleSubmit()}
                          className=" font-Manrope bg-[#009696] rounded-[5px] text-[15px] font-medium text-white font-semibold py-2 px-5 font-Helvetica"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    public_test_modal: test.public_test_modal,
    test: test.test,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePublicTestStudentDetails: (data) =>
      dispatch(updatePublicTestStudentDetails(data)),
    registerPublicTestToken: (data) => dispatch(registerPublicTestToken(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PublicTestModal));
