import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ErrorImg } from "../../assets/img/svg/404_banner.svg";

const Error_page = () => {
  let history = useNavigate();

  return (
    <>
      <div className="w-full h-[93vh] grid place-items-center">
        <div className="flex flex-col items-center pb-5">
          <ErrorImg alt="error image" className="mb-2 w-[500px] h-[500px]" />

          <p className="font-['Poppins'] text-[25px] leading-[38px] font-medium text-[#808080]">
            Ooops! The page you were looking for could not be found
          </p>

          <button
            className="mt-3 border-[1px] border-solid border-[#009696] rounded-[10px] text-[20px] font-semibold px-8 py-1 leading-[27px] text-[#009696] font-['Nunito'] cursor-pointer hover:bg-[#009696] hover:text-[white] transition-all duration-300 ease-in-out"
            onClick={() => history("/dashboard")}
          >
            Go back to dashboard
          </button>
        </div>
      </div>
    </>
  );
};

export default Error_page;
