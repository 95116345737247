import React, { Component } from "react";

import CountDown from "react-countdown";

// Store
import { connect } from "react-redux";

class CountDownBar extends Component {
  // constructor() {
  //   super();
  //   // this.isTimeLeft = this.isTimeLeft.bind(this);
  // }

  // isTimeLeft() {
  //   const endTime = Date.parse(this.props.endDuration);
  //   const currentTime = new Date();
  //   return currentTime.getTime() <= endTime;
  // }

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      this.props.saveAutomatic();
      return <div className="text-xl">Time Exceeded</div>;
    } else {
      return (
        <span>
          {(days)}D {(hours)}H{" "}
          {(minutes)}M {(seconds)}S
        </span>
      );
    }
  };

  render() {
    return (
      <div className="sm:text-[1em] md:text-[1.1em] lg:text-[1.3em] font-semibold font-[nunito] ">
        <CountDown
          key={this.props.endDate?.toString()}
          date={Date.now() + (Date.parse(this.props.endDate) - Date.now())}
          renderer={this.renderer}
        >
          {/* <div className="text-xl">Time Exceeded</div> */}
        </CountDown>
      </div>
    );
  }
}



export default connect(null, null)(CountDownBar);
