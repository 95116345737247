import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../assets/js/toast_config";
import {
  EXTRACT_QUESTION_REQUEST,
  GET_QUIZ_DETAILS,
  INSERT_QUESTIONS,
  UPLOAD_FILE_REQUEST_QUESTION,
} from "../actionTypes/extractQuestion";
import {
  uploadFileSuccess,
  uploadFileFailed,
  extractQuestionSuccess,
  extractQuestionFailed,
  getQuizDetailsSuccess,
} from "../actions/extractQuestion";
import {
  extractQuestionRequestApi,
  getQuizDetailsApi,
  insertQuestionsApi,
  uploadFileRequestApi,
} from "../../services/extractQuestion.service";

function* uploadFileRequest(action) {
  try {
    const res = yield call(uploadFileRequestApi, { url: action.payload.url });
    yield put(uploadFileSuccess(res));
    action.payload.setShowParsingButton(true);
    toast.success("File uploaded successfully", defaultToastSetting);
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(uploadFileFailed(e));
  }
}

function* extractQuestionRequest(action) {
  try {
    const res = yield call(extractQuestionRequestApi, action.payload);
    yield put(extractQuestionSuccess(res));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    yield put(extractQuestionFailed(e));
  }
}

function* getQuizDetails(action) {
  try {
    const res = yield call(getQuizDetailsApi, action.payload);
    yield put(getQuizDetailsSuccess(res));
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    // yield put(extractQuestionFailed(e));
  }
}

function* insertQuestions(action) {
  try {
    const res = yield call(insertQuestionsApi, action.payload);
    // yield put(getQuizDetailsSuccess(res));
    toast.success(
      "question inserted to test successfully",
      defaultToastSetting
    );
  } catch (e) {
    toast.error(e.message, defaultToastSetting);
    // yield put(extractQuestionFailed(e));
  }
}

function* extractQuestionSaga() {
  yield takeEvery(UPLOAD_FILE_REQUEST_QUESTION, uploadFileRequest);
  yield takeEvery(EXTRACT_QUESTION_REQUEST, extractQuestionRequest);
  yield takeEvery(GET_QUIZ_DETAILS, getQuizDetails);
  yield takeEvery(INSERT_QUESTIONS, insertQuestions);
}

export default extractQuestionSaga;
