import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchAttendingUsersRequested } from "../../../store/actions/proctor";
import { useState } from "react";
import Modal from "../../common/Modal/Modal";
import DashBoardTable from "../../common/DashBoardTable";

const ProctorDashboard = () => {
  const { id: testId } = useParams();

  const dispatch = useDispatch();
  const [modalType, setModalType] = useState(null);

  const { attendingUsers: summary, isFetchingAttendingDetails } = useSelector(
    (state) => state.proctor
  );
  const test = useSelector((state) => state.test.test);

  useEffect(() => {
    dispatch(fetchAttendingUsersRequested({ testId }));
  }, []);

  const checkTestTiming = () => {
    if (!test) return;
    const startTime = new Date(test.start_duration).getTime();
    const endTime = new Date(test.end_duration).getTime();
    const currentTime = new Date().getTime();
    if (currentTime >= startTime && currentTime <= endTime) return "Attending";
    return "Attended";
  };



  return (
    <div>
      <div className="bg-[#F5F5F5] w-full rounded-lg mb-1 mt-2 flex justify-between items-center px-2 py-2">
        {isFetchingAttendingDetails ? (
          <div>Loading...</div>
        ) : (
          <div className="w-full items-center justify-between flex sm:flex-row flex-col">
            <Modal
              onClose={() => setModalType(null)}
              isModal={modalType !== null}
              showCloseButton
              className={"lg:w-[80%]"}
            >

              <div className="flex flex-col justify-center items-start px-4 pt-5 font-['nunito']">
                <h1 className="font-semibold py-2  text-lg">List of students {modalType === "assignedStudents" ? "assigned" : modalType === "attendingStudents" ? "attended" : "submitted"}</h1>
                <DashBoardTable studentList={summary[modalType]} type="P" />
                {/* <table className="w-full text-sm text-left text-gray-500 ">
                  <thead className="text-xs text-[#001437] uppercase bg-[#BCFB46]  ">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Student name:
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Student email
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Student mobile
                      </th>
                    </tr>
                  </thead>
                  <tbody className="h-[300px] overflow-auto overflow-hidden">

                    {summary[modalType]?.map((student, index) =>
                      <tr className="bg-[#EEFECF] border-b hover:bg-gray-200 " key={`summary_${modalType}_index`}>
                        <th
                          scope="row"
                          className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap "
                        >
                          {student.name || "Not available"}
                        </th>
                        <td className="px-6 py-3">{student.email || "Not available"}</td>
                        <td className="px-6 py-3">{student.mobile || "Not available"}</td>
                      </tr>
                    )}
                  </tbody>
                </table> */}
              </div>
            </Modal>
            <div onClick={() => summary.assigned !== 0 && setModalType('assignedStudents')} className={`flex bg-white px-2 py-1 ${summary.assigned !== 0 && " cursor-pointer hover:bg-[#E3F7BE]"}  gap-2 w-[72%] sm:w-auto  flex-row`}>
              <h5 className="font-semibold text-sm sm:text-md w-full ">
                Students Assigned :
              </h5>
              <h4>{summary.assigned}</h4>
            </div>
            <div onClick={() => summary.attending !== 0 && setModalType('attendingStudents')} className={`flex bg-white px-2 py-1 ${summary.attending !== 0 && " cursor-pointer hover:bg-[#E3F7BE]"}  gap-2 w-[72%] sm:w-auto  flex-row`}>
              <h5 className="font-semibold text-sm sm:text-md w-full ">
                Students Attended:
              </h5>
              <h4>{summary.attending}</h4>
            </div>

            <div onClick={() => summary.submitted !== 0 && setModalType('submittedStudents')} className={`flex bg-white px-2 py-1 ${summary.submitted !== 0 && " cursor-pointer hover:bg-[#E3F7BE]"}  gap-2 w-[72%] sm:w-auto  flex-row`}>
              <h5 className="font-semibold text-sm sm:text-md w-full ">
                Students Submitted
              </h5>
              <h4>{summary.submitted}</h4>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProctorDashboard;
