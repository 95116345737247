import React from "react";
import RegisterUser from "../components/register";
import "../components/common/Common.css";
import { withRouter } from "../components/common/WithRouter";

const Register = () => {
  return (
    <div className="sm:p-5 p-2 sm:px-8">
      <a href="/tests">
        <button
          type="button"
          className="text-white bg-gradient-to-r from-purple-500 via-purple-600 
        to-purple-700 hover:bg-gradient-to-br font-medium rounded-lg 
        text-sm px-4 py-2 text-center mr-2 mb-2"
        >
          Go Back
        </button>
      </a>
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 sm:gap-y-3">
        <RegisterUser />
      </div>
    </div>
  );
};

export default withRouter(Register);
