export const SET_CODE_EDITOR_LANGUAGE = "SET_CODE_EDITOR_LANGUAGE";

export const SET_CODE_EDITOR_CODE = "SET_CODE_EDITOR_CODE";

export const SET_CODE_EDITOR_RESULTS = "SET_CODE_EDITOR_RESULTS";

export const SET_CODE_EDITOR_INPUT = "SET_CODE_EDITOR_INPUT";

export const CODE_EDITOR_RUN_REQUESTED = "CODE_EDITOR_RUN_REQUESTED";

export const CODE_EDITOR_RUN_SUCCESS = "CODE_EDITOR_RUN_SUCCESS";

export const CODE_EDITOR_RUN_FAILURE = "CODE_EDITOR_RUN_FAILURE";

export const CODE_EDITOR_SUBMIT_REQUESTED = "CODE_EDITOR_SUBMIT_REQUESTED";

export const CODE_EDITOR_SUBMIT_SUCCESS = "CODE_EDITOR_SUBMIT_SUCCESS";

export const CODE_EDITOR_SUBMIT_FAILURE = "CODE_EDITOR_SUBMIT_FAILURE";

export const CODE_EDITOR_RUN_STATUS_REQUESTED =
  "CODE_EDITOR_RUN_STATUS_REQUESTED";

export const CODE_EDITOR_RUN_STATUS_SUCCESS = "CODE_EDITOR_RUN_STATUS_SUCCESS";

export const CODE_EDITOR_RUN_STATUS_FAILURE = "CODE_EDITOR_RUN_STATUS_FAILURE";

export const UPDATE_CODE_RESPONSE = "UPDATE_CODE_RESPONSE";

export const UPDATE_EDITOR_STATE = "UPDATE_EDITOR_STATE";

export const CODE_EDITOR_RUN_CLEAR = "CODE_EDITOR_RUN_CLEAR";

export const COPY_INSIDE_CODE_EDITOR = "COPY_INSIDE_CODE_EDITOR";
