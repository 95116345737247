import React, { Component } from "react";
import warning from "../../../assets/img/svg/warning.svg";
class TabSwitchModal extends Component {
  render() {
    const { counter, tabSwitchCount,type } = this.props;
    return (
      <div className="z-50 flex justify-center items-center overflow-auto left-0 top-0 bottom-0 right-0 w-full h-full fixed">
        <div className="z-50 relative p-3 mx-auto my-0 max-w-md min-w-xs">
          <div className="font-['nunito'] bg-white rounded shadow-lg border flex flex-col overflow-hidden">
            {counter > 0 && counter < tabSwitchCount + 1 && (
              <div className="flex justify-end p-2">
                <button
                  type="button"
                  onClick={() => this.props.handleCloseTabSwitchModal()}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  data-modal-toggle="popup-modal"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            )}
            <div className="flex flex-col justify-center items-center py-5 px-5">
              <img
                src={warning}
                alt="!"
                title="warning"
                className="w-20 h-20 mb-3"
              />
              {counter > tabSwitchCount ? (
                <>
                  <p className="text-center font-bold text-lg font-poppins font-light text-gray-700 mb-2">
                    {`As we has observed a frequent switching of tabs. You will be
                    redirected to ${type==="T"?"tests":"interviews"} page for now and disqualified if the same
                    repeats.`}
                  </p>
                  <button
                    onClick={() => this.props.handleCloseTabSwitchModal()}
                    className="mt-4 text-white transition bg-gradient-to-b from-[#80D5D5] to-[#009696] 
                               font-medium rounded-lg text-md px-6 py-2 flex justify-centers cursor-pointer
                               font-['nunito']"
                  >
                    <span className="font-semibold">Save & Exit</span>
                  </button>
                </>
              ) : (
                <>
                  <p className="text-center font-bold text-lg font-poppins font-light text-gray-700 mb-2">
                    {`You have ${
                      tabSwitchCount - counter === 0
                        ? "no chances "
                        : tabSwitchCount - counter + " chances "
                    } left.`}
                  </p>
                  <p className="text-center font-poppins font-light text-gray-700">
                    {`Please don't click outside of this tab else your ${type==="T"?"test":"interview"} will
                    be submitted automatically with current responses`}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="z-40 overflow-auto left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-black opacity-50"></div>
      </div>
    );
  }
}

export default TabSwitchModal;
