import React, { Component, createRef } from "react";
import "../common/Common.css";
import { connect } from "react-redux";
import { signupRequested } from "../../store/actions/auth";
import { toast } from "react-toastify";
import { ReactComponent as ButtonSpinner } from "../../assets/img/svg/button-spinner.svg";
import KyronSideLetter from "../../assets/img/Kyron/KyronLetterSide.png";
import axios from "axios";
import { BASE_URL } from "../../assets/js/config";
import { defaultToastSetting } from "../../assets/js/toast_config";
import githubIcon from "../../assets/img/svg/github.svg";
import { withRouter } from "../common/WithRouter";

class githubSignUp extends Component {
  constructor(props) {
    super(props);

    this.form = createRef();
    this.form.current = null;
    this.state = {
      formdata: {
        name: "",
        mobile: "",
        gender: "",
      },
      nameError: "",
      mobileError: "",
      genderError: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isSignedUp &&
      !this.props.isSignupLoading &&
      !prevProps.isSignedUp
    ) {
      this.props.history("/login");
    }
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    let new_form_data = { ...this.state.formdata };
    new_form_data[name] = value;

    this.setState({ formdata: new_form_data, [`${name}Error`]: "" });
  };

  validate = () => {
    let nameError = "";
    let mobileError = "";
    let genderError = "";

    if (!this.state.formdata.name) {
      nameError = "Name cannot be blank.";
    }

    if (!this.state.formdata.gender) {
      genderError = "Please select the gender.";
    }

    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (
      re.test(this.state.formdata.mobile) === false ||
      this.state.formdata.mobile.length !== 10
    ) {
      mobileError = "Invalid Mobile Number";
    }

    if (nameError || mobileError || genderError) {
      this.setState({ nameError, mobileError, genderError });
      return false;
    }

    return true;
  };

  handleGithubSignUp = (e) => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      axios
        .post(`${BASE_URL}api/user/githubsignup`, {
          name: this.props.githubUserData.name,
          email: this.props.githubUserEmail,
          mobile: this.state.formdata.mobile,
          githubId: this.props.githubUserData.node_id,
        })
        .then((res) => {
          if (res.data.userExists) {
            this.props.history(`/login`);
            toast.warning("User already registered ", defaultToastSetting);
          } else {
            this.props.history(`/login`);
            toast.success("Account Created", defaultToastSetting);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    return (
      <div className=" flex flex-col w-[50vw] items-center justify-center login-input-div  md:px-10 md:py-10 my-5">
        <img src={KyronSideLetter} alt="LOGO" className="w-[220px] mb-4" />
        <img src={githubIcon} height="40px" width="40px" />
        <div className="font-['poppins'] text-neutral-400 text-lg mb-4">
          Enter Additional Details
        </div>
        <form onSubmit={this.handleGithubSignUp}>
          <div className="mb-2">
            <label className="font-['poppins'] text-neutral-400">Name</label>
            <input
              name="name"
              placeholder="Name"
              className="login-form-emailInput"
              value={this.state.formdata.name}
              onChange={this.handleChange}
            />
          </div>
          <div className="text-red-500 text-sm font-['poppins']">
            {this.state.nameError}
          </div>

          <div className="mb-2">
            <label className="font-['poppins'] text-neutral-400">
              Mobile Number
            </label>
            <input
              type="text"
              name="mobile"
              placeholder="Mobile"
              maxLength={10}
              className="login-form-passInput"
              value={this.state.formdata.mobile}
              onChange={this.handleChange}
            />
            <div className="text-red-500 text-sm font-['poppins']">
              {this.state.mobileError}
            </div>
          </div>

          <div className="mb-2">
            <label className="font-['poppins'] text-neutral-400">Gender</label>
            <select
              onChange={this.handleChange}
              className="login-form-emailInput"
              // className="border border-gray-300 my-1 outline-gray-300 p-1"
              value={this.state.formdata.gender}
              name="gender"
            >
              <option defaultValue>Select Your Gender</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
              <option value="F">Transgender</option>
              <option value="NB">Non Binary</option>
              <option value="NC">Non Confirming</option>
              <option value="O">Others</option>
            </select>
            <div className="text-red-500 text-sm font-['poppins']">
              {this.state.genderError}
            </div>
          </div>

          <div className="flex justify-center">
            <button
              className="text-white w-[200px] transition bg-[#009999]  hover:bg-gray-800 hover:text-white font-medium rounded-full  
            text-md px-6 py-2 flex justify-center mr-2 mb-2 cursor-pointer font-['nunito']"
              type="submit"
            >
              {this.props.isSignupLoading ? (
                <ButtonSpinner />
              ) : (
                "Create Account"
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    isSignupLoading: auth.isSignupLoading,
    isSignedUp: auth.isSignedUp,
    // message: auth.message,
    // successMessage: auth.successmessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signupRequested: (data) => dispatch(signupRequested(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(githubSignUp));
