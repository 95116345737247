import {
  DELETE_EXTRACTED_QUESTION,
  DISABLE_EXTRACTED_QUESTION,
  EXTRACT_QUESTION_FAILED,
  EXTRACT_QUESTION_REQUEST,
  EXTRACT_QUESTION_SUCCESS,
  GET_QUIZ_DETAILS,
  GET_QUIZ_DETAILS_SUCCESS,
  INSERT_QUESTIONS,
  UPDATE_EXTRACTED_QUESTION,
  UPLOAD_FILE_FAILED_QUESTION,
  UPLOAD_FILE_REQUEST_QUESTION,
  UPLOAD_FILE_SUCCESS_QUESTION,
} from "../actionTypes/extractQuestion";

export const uploadFileRequest = (data) => {
  return { type: UPLOAD_FILE_REQUEST_QUESTION, payload: data };
};

export const uploadFileSuccess = (data) => {
  return { type: UPLOAD_FILE_SUCCESS_QUESTION, payload: data };
};

export const uploadFileFailed = (data) => {
  return { type: UPLOAD_FILE_FAILED_QUESTION, payload: data };
};

export const extractQuestionRequest = (data) => {
  return { type: EXTRACT_QUESTION_REQUEST, payload: data };
};

export const extractQuestionSuccess = (data) => {
  return { type: EXTRACT_QUESTION_SUCCESS, payload: data };
};

export const extractQuestionFailed = (data) => {
  return { type: EXTRACT_QUESTION_FAILED, payload: data };
};

export const updateExtractedQuestion = (data) => {
  return { type: UPDATE_EXTRACTED_QUESTION, payload: data };
};

export const deleteExtractedQuestion = (data) => {
  return { type: DELETE_EXTRACTED_QUESTION, payload: data };
};

export const disableExtractedQuestion = (data) => {
  return { type: DISABLE_EXTRACTED_QUESTION, payload: data };
};

export const getQuizDetails = (data) => {
  console.log("action", data);
  return { type: GET_QUIZ_DETAILS, payload: data };
};
export const getQuizDetailsSuccess = (data) => {
  return { type: GET_QUIZ_DETAILS_SUCCESS, payload: data };
};
export const insertQuestions = (data) => {
  return { type: INSERT_QUESTIONS, payload: data };
};
