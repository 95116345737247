import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../common/Common.css";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { register } from "../../store/actions/auth";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger mt-2" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger mt-2" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const validMobile = (value) => {
  if (!(value.length === 10)) {
    return (
      <div className="alert alert-danger mt-2" role="alert">
        This is not a valid mobile. Should be 10 digits!
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger mt-2" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const Register = () => {
  const form = useRef();
  const checkBtn = useRef();

  const [state, setState] = useState({
    name: "",
    type: "S",
    email: "",
    mobile: "",
    password: "",
  });

  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);

  const { message } = useSelector((state) => state.message);
  // const { isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onChangeForm = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setSuccessful(false);
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(
        register(
          state.name,
          state.type,
          state.email,
          state.mobile,
          state.password
        )
      )
        .then(() => {
          setSuccessful(true);
          setLoading(false);
        })
        .catch(() => {
          setSuccessful(false);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  // if (isLoggedIn) {
  //   return <Navigate  to="/dashboard" />;
  // }

  return (
    <div className="flex flex-col justify-start md:mx-10 mx-2">
      <p className="text-2xl font-semibold">Register New User</p>

      <Form onSubmit={handleRegister} ref={form} className="">
        <div className="flex flex-col gap-3 mt-3">
          <div>
            <label className="">
              <h6>Full Name</h6>
            </label>
            <Input
              type="text"
              className="border rounded border-gray-300 p-2 outline-gray-200 my-2 w-2/3"
              name="name"
              value={state.name}
              onChange={onChangeForm}
              validations={[required]}
            />
          </div>
          {/* </div> */}
          <div className="">
            <label>Email</label>
            <Input
              type="email"
              autoComplete="username"
              className="border rounded border-gray-300 p-2 outline-gray-200 my-2 w-2/3"
              name="email"
              value={state.email}
              onChange={onChangeForm}
              validations={[required, validEmail]}
            />
          </div>
          <div className="">
            <label>Mobile</label>
            <Input
              type="number"
              className="border rounded border-gray-300 p-2 outline-gray-200 my-2 w-2/3"
              name="mobile"
              value={state.mobile}
              onChange={onChangeForm}
              validations={[required, validMobile]}
            />
          </div>
          <div>
            <label className="">User Type</label>
          </div>
          <div className="flex space-x-4">
            <div className="flex jusitify-center items-center gap-2">
              <input
                className="w-4 h-4"
                type="radio"
                name="type"
                value="S"
                defaultChecked
                onChange={onChangeForm}
              />
              <label className="" htmlFor="type">
                Student
              </label>
            </div>
            <div className="flex jusitify-center items-center gap-2">
              <input
                className="w-4 h-4"
                type="radio"
                name="type"
                value="T"
                onChange={onChangeForm}
              />
              <label className="" htmlFor="type">
                Teacher
              </label>
            </div>
            <div className="flex jusitify-center items-center gap-2">
              <input
                className="h-4 w-4"
                type="radio"
                name="type"
                value="TA"
                onChange={onChangeForm}
              />
              <label className="" htmlFor="type">
                TA
              </label>
            </div>
            <div className="flex jusitify-center items-center gap-2">
              <input
                className="h-4 w-4"
                type="radio"
                name="type"
                value="A"
                onChange={onChangeForm}
              />
              <label className="" htmlFor="type">
                Admin
              </label>
            </div>
          </div>
          <div className="">
            <label>Password</label>
            <Input
              type="password"
              className="border rounded border-gray-300 p-2 outline-gray-200 my-2 w-2/3"
              autoComplete="new-password"
              name="password"
              value={state.password}
              onChange={onChangeForm}
              validations={[required, vpassword]}
            />
          </div>

          <div className="">
            <button
              className="my-3 bg-emerald-500 text-white font-semibold py-2 px-3 rounded"
              disabled={loading || (successful && !loading)}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}{" "}
              <span>Sign Up</span>
            </button>
          </div>

          {message && (
            <div className="">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </div>
      </Form>
    </div>
  );
};

export default Register;
