import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAssignedReportsRequested, fetchReportRequested, updateScoreRequested } from '../../store/actions/evaluators';
import RenderQuestion from '../../components/tests/createTest/RenderQuestion';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { defaultToastSetting } from '../../assets/js/toast_config';
import { toast } from 'react-toastify';
import { submitEvaluation, updateScoreService } from '../../services/evaluator.service';
import load from "../../assets/img/svg/load.svg";
import { calculateGrade } from '../../components/common/utils';

export default function BlindReportEvaluation() {
    const dispatch = useDispatch();
    const reportId = useParams()?.id;
    const [currentsectionIndex, setCurrentSectionIndex] = useState(0);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const { report, loading } = useSelector((state) => state.evaluatorsReducer);
    const [sloading, setsLoading] = useState(false);


    const history = useNavigate();

    useEffect(() => {
        dispatch(fetchReportRequested(reportId));
    }, [])

    const onChangeScoreDescriptive = (e, key, marks) => {
        const tempMarking = report?.marking;
        const temp = tempMarking?.[currentsectionIndex]?.[currentQuestionIndex]?.subscore ? [...tempMarking?.[currentsectionIndex]?.[currentQuestionIndex]?.subscore] : ["", "", ""];
        temp[key] = parseInt(e.target.value);
        dispatch(updateScoreRequested({ currentsectionIndex, currentQuestionIndex, key: "subscore", value: temp }));
        calculateScore(marks, temp);

    }



    const calculateScore = (totalMarks, temp) => {
        const marks = parseFloat(totalMarks / 3);
        let score = 0;
        temp.forEach(x => {
            if (x !== "") {
                let currentOptionMarks = marks * (4 - x) * 0.25;
                score += currentOptionMarks;
            }
        })
        const grade = calculateGrade(parseFloat(score / totalMarks) * 100)
        dispatch(updateScoreRequested({ currentsectionIndex, currentQuestionIndex, key: "score", value: score }));
        dispatch(updateScoreRequested({ currentsectionIndex, currentQuestionIndex, key: "grade", value: grade }));
    }


    const onSaveData = () => {
        setsLoading(true);
        updateScoreService({ id: report?.quiz_assign_id, marking: report?.marking }).then(res => {
            if (res.success) {
                setsLoading(false);
                toast.success("Data saved successfully!", defaultToastSetting);

            }
        }).catch(e => {
            setsLoading(false);
            toast.error(e.message, defaultToastSetting);
        })
    }

    const submit = () => {
        onSaveData();
        submitEvaluation({ id: reportId }).then(res => {
            if (res.success) {
                toast.success(res.message, defaultToastSetting);
                setTimeout(() => {
                    history(`/blind-assessment`);
                }, 3000);
            }
        }).catch(e => {
            toast.error(e.message, defaultToastSetting);
        })
    }

    const onChangeFlagsHandler = (e) => {
        const { name, value } = e.target;
        dispatch(updateScoreRequested({ currentsectionIndex, currentQuestionIndex, key: name, value: value === "true" ? false : true }));
    }





    return (
        <div className="font-['nunito'] relative">
            <div className="flex px-5  z-10  justify-between items-center py-4 fixed bg-[#BBE74B] w-full">
                <h1 className="font-medium text-lg font-['poppins']">Kyron Blind Assessment</h1>
            </div>
            {
                !loading ?
                    <>
                        <div className="flex md:flex-row gap-3 flex-col border-b w-full bg-white   px-5 pt-16">
                            <div className="flex  font-['poppins'] justify-start uppercase items-center">
                                {report?.quiz_components?.map((section, index) =>
                                    <h1 onClick={() => setCurrentSectionIndex(index)} key={`sections-${index}}`} className={`font-medium ${currentsectionIndex === index ? "border-[#BBE74B] text-[#BBE74B] bg-[#011538]" : ""} hover:cursor-pointer text-md px-3 py-1 border border-sm`}>
                                        {section.title !== "" ? section.title : "Section-" + parseInt(index + 1)}
                                    </h1>
                                )}
                            </div>
                            <div className="flex  gap-x-3 ml-auto  font-['nunito'] mb-1">
                                <button onClick={() => onSaveData()} className="w-[100px] bg-[#1F2937] border border-[#1F2937] hover:bg-white hover:text-[#1F2937] text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                >{sloading ? <img
                                    alt="loading"
                                    src={load}
                                    className="spinner my-auto mx-auto w-5 h-5 text-white "
                                /> : "Save"}</button>

                                <button onClick={() => history(`/blind-assessment`)} className="w-[100px] bg-[#828282] border border-[#828282] hover:bg-white hover:text-[#828282] text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                >Back</button>
                                <button onClick={() => submit()} className="w-[100px] bg-[#BCFB46] border border-[#a0db32] hover:bg-white hover:text-[#a0db32] text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                >Submit</button>
                            </div>
                        </div>

                        <div className="pt-2 px-5  mb-4 rounded shadow-lg">
                            {report?.quiz_components?.[currentsectionIndex].components.length > 0
                                ? <>
                                    {report?.quiz_components?.[currentsectionIndex].components.map((question, index) =>
                                        <>
                                            <div
                                                id="accordion-flush"
                                                data-accordion="collapse"
                                                data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white"
                                                data-inactive-classes="text-gray-500 dark:text-gray-400"
                                                key={"section_" + index}
                                                className={`hover:cursor-pointer transition-all hover:bg-[#BBE74B] border ${currentQuestionIndex === index ? "border-[#BBE74B] bg-[#BBE74B] text-[#011538]" : "border-[#BBE74B] text-[#BBE74B] "}`}
                                                onClick={() => {
                                                    currentQuestionIndex === index ? setCurrentQuestionIndex(null) : setCurrentQuestionIndex(index)

                                                }}
                                            >
                                                <h2 id="accordion-flush-heading-1 transition-all">
                                                    <div
                                                        className="flex items-center justify-between w-full text-sm px-3 py-1.5 text-left text-gray-800 border-b border-gray-200"
                                                        data-accordion-target="#accordion-flush-body-1"
                                                        aria-expanded="true"
                                                        aria-controls="accordion-flush-body-1"
                                                    >
                                                        <p>
                                                            Question - {index + 1}{" "}

                                                        </p>
                                                        <div className="flex gap-x-1 items-center justify-center">
                                                            <svg
                                                                data-accordion-icon
                                                                className={`w-6 h-6 shrink-0 ${index !== parseInt(0) && "rotate-180"
                                                                    }`}
                                                                fill="currentColor"

                                                                viewBox="0 0 20 20"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                                    clipRule="evenodd"
                                                                ></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </h2>

                                            </div>
                                            {currentQuestionIndex === index ?
                                                <div className="px-3 mb-5">
                                                    <RenderQuestion
                                                        sectionIndex={currentsectionIndex}
                                                        question_details={question}
                                                        index={currentQuestionIndex}
                                                        response={report?.report_response}
                                                        isTestCreation={false}
                                                        type='E'
                                                    />
                                                    <div className="py-2 shadow-lg">
                                                        <h1 className="font-['nunito'] font-semibold py-1">Rating</h1>
                                                        <hr />

                                                        <div className="flex gap-x-3 pt-2">
                                                            <div className="flex pr-10 gap-2 justify-center items-center">
                                                                <input
                                                                    type="number"

                                                                    disabled
                                                                    value={report?.marking[currentsectionIndex]?.[currentQuestionIndex]?.score?.toFixed(2)}
                                                                    placeholder="Score"
                                                                    className="border max-w-[150px] border-gray-300 my-1 outline-gray-300 p-2 h-8"
                                                                />
                                                                <p>Of {question?.marks} points</p>
                                                            </div>
                                                            {report?.quiz_components?.[currentsectionIndex].section_type === 'G' && (question?.type === 'description' || question?.type === 'integer') ?
                                                                <>
                                                                    <div className="flex pr-10 gap-2 justify-center items-center">
                                                                        <input
                                                                            type="text"

                                                                            disabled
                                                                            value={report?.marking[currentsectionIndex]?.[currentQuestionIndex]?.grade}
                                                                            placeholder="Grade"
                                                                            className="border max-w-[150px] border-gray-300 my-1 outline-gray-300 p-2 h-8"
                                                                        />
                                                                        <label className="">Grade</label>
                                                                    </div>
                                                                    <div className="flex gap-2 justify-center items-center">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="unratable"
                                                                            name="unratable"
                                                                            className="outline-none bg-none"
                                                                            value={report?.marking[currentsectionIndex]?.[currentQuestionIndex]?.unratable || false}
                                                                            checked={report?.marking[currentsectionIndex]?.[currentQuestionIndex]?.unratable || false}
                                                                            onChange={(e) => onChangeFlagsHandler(e)}
                                                                        />
                                                                        <label className="">Unratable</label>
                                                                    </div>
                                                                    <div className="flex gap-2 justify-center  items-center">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="technical_issue"
                                                                            name="technical_issue"
                                                                            className="outline-none bg-none"
                                                                            value={report?.marking[currentsectionIndex]?.[currentQuestionIndex]?.technical_issue || false}
                                                                            checked={report?.marking[currentsectionIndex]?.[currentQuestionIndex]?.technical_issue || false}
                                                                            onChange={(e) => onChangeFlagsHandler(e)}
                                                                        />
                                                                        <label className="">Technical issue</label>
                                                                    </div>
                                                                </> : null
                                                            }
                                                        </div>
                                                        {report?.quiz_components?.[currentsectionIndex].section_type === 'G' && (question?.type === 'description' || question?.type === 'integer') ?
                                                            <div className='px-2'>
                                                                <div className="grid grid-cols-5 gap-x-2  pt-2">
                                                                    <p></p>
                                                                    <p className="border flex justify-center p-2 shadow-lg">Excellent</p>
                                                                    <p className="border flex justify-center p-2 shadow-lg">Good</p>
                                                                    <p className="border flex justify-center p-2 shadow-lg">Fair</p>
                                                                    <p className="border flex justify-center p-2 shadow-lg">Poor</p>
                                                                </div>

                                                                <div className="grid grid-cols-5 gap-x-2  pt-2">
                                                                    <p>Organization</p>
                                                                    {
                                                                        [...Array(4)].map((x, i) =>
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    key={i}
                                                                                    name={`organization-${i}-0`}
                                                                                    className="outline-none bg-none w-5 h-5 mx-auto"
                                                                                    value={i}
                                                                                    checked={(report?.marking?.[currentsectionIndex]?.[currentQuestionIndex]?.subscore?.[0]) === i}
                                                                                    onChange={(e) => { onChangeScoreDescriptive(e, 0, question?.marks) }}
                                                                                />
                                                                            </>
                                                                        )}

                                                                </div>

                                                                <div className="grid grid-cols-5 gap-x-2  pt-2">
                                                                    <p></p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">Arrange the information in a way that makes sense and keeps the reader interested and able to understand it easily.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">The student shares information in a clear order that makes it easy for the reader to understand.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">The reader finds it hard to understand the student's work because it's all over the place.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">It's tough to understand the order of the information.</p>
                                                                </div>

                                                                <div className="grid grid-cols-5 gap-x-2  pt-2">
                                                                    <p>Content Knowledge</p>
                                                                    {
                                                                        [...Array(4)].map((x, i) =>
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    key={i}
                                                                                    name={`Content-${i}-1`}
                                                                                    className="outline-none bg-none w-5 h-5 mx-auto"
                                                                                    value={i}
                                                                                    checked={(report?.marking?.[currentsectionIndex]?.[currentQuestionIndex]?.subscore?.[1]) === i}
                                                                                    onChange={(e) => { onChangeScoreDescriptive(e, 1, question?.marks) }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                </div>

                                                                <div className="grid grid-cols-5 gap-x-2  pt-2">
                                                                    <p></p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">The student showed they knew a lot, even more than necessary.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">The student is comfortable with the material but doesn't provide enough details or explanations.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">The student isn't confident with the material but can show basic concepts.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">The student doesn't understand the information and can't answer questions about the topic.</p>
                                                                </div>

                                                                <div className="grid grid-cols-5 gap-x-2  pt-2">
                                                                    <p>Grammer and Spelling</p>
                                                                    {
                                                                        [...Array(4)].map((x, i) =>
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    key={i}
                                                                                    name={`Grammer-${i}-2`}
                                                                                    className="outline-none bg-none w-5 h-5 mx-auto"
                                                                                    value={i}
                                                                                    checked={(report?.marking?.[currentsectionIndex]?.[currentQuestionIndex]?.subscore?.[2]) === i}
                                                                                    onChange={(e) => { onChangeScoreDescriptive(e, 2, question?.marks) }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                </div>

                                                                <div className="grid grid-cols-5 gap-x-2  pt-2">
                                                                    <p></p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">The presentation doesn't contain any spelling or grammar mistakes.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">The presentation has at most two spelling or grammar mistakes.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">The presentation contains three spelling or grammar mistakes.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">The work includes four or more spelling and/or grammar errors.</p>
                                                                </div>

                                                            </div> : null}

                                                        {question?.type === "record" ?
                                                            <div className='px-2'>
                                                                <div className="grid grid-cols-5 gap-x-2  pt-2">
                                                                    <p></p>
                                                                    <p className="border flex justify-center p-2 shadow-lg">Distinguished (IH)</p>
                                                                    <p className="border flex justify-center p-2 shadow-lg">Proficient Plus (IM)</p>
                                                                    <p className="border flex justify-center p-2 shadow-lg">Proficient (IL)</p>
                                                                    <p className="border flex justify-center p-2 shadow-lg">No Rating</p>
                                                                </div>

                                                                <div className="grid grid-cols-5 gap-x-2  pt-2">
                                                                    <p>Audience (IH)</p>
                                                                    {
                                                                        [...Array(4)].map((x, i) =>
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    key={i}
                                                                                    name={`Audience-${i}-0`}
                                                                                    className="outline-none bg-none w-5 h-5 mx-auto"
                                                                                    value={i}
                                                                                    checked={(report?.marking?.[currentsectionIndex]?.[currentQuestionIndex]?.subscore?.[0]) === i}
                                                                                    onChange={(e) => { onChangeScoreDescriptive(e, 0, question?.marks) }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                </div>

                                                                <div className="grid grid-cols-5 gap-x-2  pt-2">
                                                                    <p></p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">
                                                                        It can usually be understood, even by people who are not used to interacting with language learners.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">A native speaker familiar with language learners can understand it.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">A person accustomed to working with language learners can understand it.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">The student did not show proficiency at the "Proficient" level or above.</p>
                                                                </div>

                                                                <div className="grid grid-cols-5 gap-x-2  pt-2">
                                                                    <p>Response Types (IH)</p>
                                                                    {
                                                                        [...Array(4)].map((x, i) =>
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    key={i}
                                                                                    name={`response-${i}-1`}
                                                                                    className="outline-none bg-none w-5 h-5 mx-auto"
                                                                                    value={i}
                                                                                    checked={(report?.marking?.[currentsectionIndex]?.[currentQuestionIndex]?.subscore?.[1]) === i}
                                                                                    onChange={(e) => { onChangeScoreDescriptive(e, 1, question?.marks) }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                </div>

                                                                <div className="grid grid-cols-5 gap-x-2  pt-2">
                                                                    <p></p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">
                                                                        They can respond with basic paragraph-length discussions. They can start to narrate, describe, summarize, and make comparisons or contrasts, but they are unable to maintain these aspects consistently.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">They respond by crafting a range of more complex sentences and asking questions. They are capable of sustaining basic communication tasks and engaging in brief conversations.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">They respond with a variety of simple sentences and questions, but they can only minimally maintain communicative tasks. They are capable of providing very basic descriptions.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">The student did not show proficiency at the "Proficient" level or above.</p>
                                                                </div>

                                                                <div className="grid grid-cols-5 gap-x-2  pt-2">
                                                                    <p>Topics (IH)</p>
                                                                    {
                                                                        [...Array(4)].map((x, i) =>
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    key={i}
                                                                                    name={`topics-${i}-2`}
                                                                                    className="outline-none bg-none w-5 h-5 mx-auto"
                                                                                    value={i}
                                                                                    checked={(report?.marking?.[currentsectionIndex]?.[currentQuestionIndex]?.subscore?.[2]) === i}
                                                                                    onChange={(e) => { onChangeScoreDescriptive(e, 2, question?.marks) }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                </div>

                                                                <div className="grid grid-cols-5 gap-x-2  pt-2">
                                                                    <p></p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">They manage complex communication tasks with some degree of success. They can initiate, maintain, and conclude a general conversation on various concrete topics.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">They can manage survival topics and a range of social situations, including courtesy and social etiquette such as accepting or refusing invitations, greetings, and meetings.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">
                                                                        They can handle a limited set of social situations and basic survival topics. For example, they can ask for and give directions to a place or describe basic health problems, but their proficiency is restricted to these fundamental areas.</p>
                                                                    <p className="border flex justify-center p-3 shadow-lg">The student did not show proficiency at the "Proficient" level or above.</p>
                                                                </div>

                                                            </div> : null
                                                        }
                                                    </div>
                                                </div>
                                                : null
                                            }
                                        </>
                                    )}
                                </> : <div className="flex justify-center items-center py-2">Section is Empty</div>
                            }
                        </div>
                    </>
                    :
                    <img
                        alt="loading"
                        src={load}
                        className="spinner my-1 mx-auto w-5 h-5 text-white "
                    />
            }
        </div >
    )
}
