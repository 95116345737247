import axios from "axios";
import { resumeHeader } from "./auth-header";
import { BASE_URL } from "../assets/js/config";
import authHeader from "./auth-header";
import { viewFile } from "./upload.service";

export const uploadFileRequestApi = async (payloadData) => {
  try {
    console.log("service", payloadData);

    const data = new FormData();
    for (let file of payloadData.files) data.append("resume", file);

    data.append("link", payloadData.link);
    data.append("email", localStorage.getItem("priksha_email"));

    const resume_uploaded = await axios.post(
      "https://resume-parsing.kyron.in/v1/api/upload_resumes",
      data,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    // if (resume_uploaded.data?.token)
    //   await axios.post(
    //     "https://resume-parsing.kyron.in/v1/api/generate_embeddings",
    //     {},
    //     {
    //       timeout: 300000,
    //       headers: { Authorization: resume_uploaded.data.token },
    //     }
    //   );
    // axios
    //   .post(
    //     "https://resume-parsing.kyron.in/v1/api/generate_embeddings",
    //     {},
    //     { timeout: 5000 }
    //   )
    //   .then((res) => {
    //     console.log(res);
    //     // return res.data;
    //   })
    //   .catch((err) => {
    //     // if (axios.isCancel(err)) throw Error("Request timed out");
    //     throw Error(err.response.data);
    //     // else throw Error(err.response.data);
    //   });

    return resume_uploaded.data;
  } catch (err) {
    console.log(err);
    if (err.response) throw Error(err.response.data);
    else throw Error("timed out");
  }
};

// export const getDepartmentApi = () => {
//   return axios
//     .get("https://resume-parsing.kyron.in/v1/api/get_all_departments")
//     .then((res) => {
//       return res.data;
//     })
//     .catch((err) => {
//       throw Error(err.response.data);
//     });
// };

// export const getProfileApi = (data) => {
//   return axios
//     .post("https://resume-parsing.kyron.in/v1/api/get_all_profiles", data)
//     .then((res) => {
//       return res.data;
//     })
//     .catch((err) => {
//       throw Error(err.response.data);
//     });
// };

// export const parseResumeApi = (data) => {
//   return axios
//     .post("https://resume-parsing.kyron.in/v1/api/filter_resumes", data, {
//       headers: resumeHeader(),
//     })
//     .then((res) => {
//       return res.data;
//     })
//     .catch((err) => {
//       throw Error(err.response.data);
//     });
// };

export const askQuestionRequestApi = (data) => {
  return axios
    .post(`${BASE_URL}api/resume-parsing/prompt`, data, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

export const sendEmailApi = (data) => {
  return axios
    .post(`${BASE_URL}api/email-template/custombodyemail`, data, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

export const getSessionIdForResumes = (data) => {
  return axios
    .post(`${BASE_URL}api/resume-parsing/get-session-id`, data, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const getDetailsFromSessionId = (sessionId) => {
  return axios
    .get(`${BASE_URL}api/resume-parsing/${sessionId}/details`, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res?.data?.data;
    })
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const reparseResumeApi = ({ sessionId, retry }) => {
  return axios
    .post(
      `${BASE_URL}api/resume-parsing/${sessionId}/retry`,
      { retry },
      {
        headers: { ...authHeader() },
      }
    )
    .then((res) => {
      return res?.data?.data;
    })
    .catch((err) => {
      throw err?.response.data;
    });
};

export const getHeaderFromSessionId = (sessionId) => {
  return axios
    .get(`${BASE_URL}api/resume-parsing/${sessionId}`, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res?.data?.data;
    })
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const putSignedUrlsForResumes = (data) => {
  return axios
    .post(`${BASE_URL}api/resume-parsing/put-signed-urls-resumes`, data, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const updateStatusOfResumeApi = (data) => {
  return axios
    .put(
      `${BASE_URL}api/resume-parsing/${data.id}/save-resume-details`,
      { status: data.status },
      {
        headers: { ...authHeader() },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const exportCSVApi = (data) => {
  return axios
    .post(
      `${BASE_URL}api/resume-parsing/${data.token}/download-csv`,
      {
        status: data.status,
        parameters: data.parameters,
        resumes: data.resumes,
      },
      {
        headers: { ...authHeader() },
      }
    )
    .then(async (res) => {
      const fileURL = await viewFile(res.data.csv_file, "TEMPORARY");
      window.open(fileURL);
    })
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const totalParsedResumeApi = (token) => {
  return axios
    .get(`${BASE_URL}api/resume-parsing/${token}/counter`, {
      headers: { ...authHeader() },
    })
    .then(async (res) => res.data)
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const assignInterviewApi = (data) => {
  return axios
    .put(`${BASE_URL}api/interview-assign/assign-multiple`, data, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const getChatApi = (id) => {
  return axios
    .get(`${BASE_URL}api/resumechat/${id}`, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const createChatApi = (data) => {
  return axios
    .post(`${BASE_URL}api/resumechat`, data, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const updateChatApi = ({ message, chat_id }) => {
  return axios
    .put(
      `${BASE_URL}api/resumechat/${chat_id}`,
      { message },
      {
        headers: { ...authHeader() },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err?.response?.data;
    });
};
