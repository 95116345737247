import React from "react";
import { useSelector } from "react-redux";

import StudentDashboard from "../components/common/StudentDashboard";
import ProctorDashboard from "../components/common/ProctorDashboard";
import { ReactComponent as StartLaunchIcon } from "../assets/img/svg/start_launch.svg";
import AdminDashboard from "../components/common/AdminDashboard";
import HrDashboard from "../components/common/HrDashboard";

const Dashboard = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  switch (currentUser?.type) {
    case "S":
      return (
        <>
          <StudentDashboard user={currentUser} />
          <div className="flex justify-center">
            <StartLaunchIcon />
          </div>
        </>
      );
    case "A":
      return (
        <>
          <div
            style={{ height: "90vh", userSelect: "none" }}
            className="flex justify-center items-center"
          >
            Welcome Admin!!
          </div>
          <div className="flex justify-center">
            <StartLaunchIcon />
          </div>
        </>
      );
    case "T":
      return (
        <>
          <div className="text-2xl font-bold p-5">
            Welcome {currentUser.name}!!
          </div>
          <div className="flex justify-center">
            <StartLaunchIcon />
          </div>
        </>
      );
    case "KA-I":
      return (
        <>
          <div className="text-2xl font-bold p-5">
            Welcome {currentUser.name}!!
          </div>
          <div className="flex justify-center">
            <StartLaunchIcon />
          </div>
        </>
      );
    case "HR":
      return <HrDashboard currentUser={currentUser.name} />;
    case "TA":
      return (
        <>
          <div
            style={{ height: "90vh", userSelect: "none" }}
            className="flex justify-center items-center"
          >
            Welcome Assistant!!
          </div>
          <div className="flex justify-center">
            <StartLaunchIcon />
          </div>
        </>
      );
    case "P":
      return (
        <>
          <ProctorDashboard user={currentUser} />
          <div className="flex justify-center">
            <StartLaunchIcon />
          </div>
        </>
      );
    case "SP":
      return (
        <>
          <ProctorDashboard user={currentUser} />
          <div className="flex justify-center">
            <StartLaunchIcon />
          </div>
        </>
      );
    case "E":
      return (
        <>
          <div className="text-2xl font-bold p-5">
            Welcome {currentUser.name}!!
          </div>
          <div className="flex justify-center">
            <StartLaunchIcon />
          </div>
        </>
      );

    default:
      return <div>Some error occured!</div>;
  }
};

export default Dashboard;
