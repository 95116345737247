import React from "react";
import ResetUser from "../components/resetPassword";
import KyronLogo_New from "../assets/img/Kyron/KyronLogo_New.png";

import "../components/common/Common.css";
export default class Login extends React.Component {
  render() {
    return (
      <div className="flex justify-center h-screen">
        <div className="grid lg:grid-cols-2 gap-5 items-center bg-[#009999]">
          <div className="flex-col md:mx-14 sm:mx-8 mx-5">
            <div className="p-2">
              <div className="flex justify-start items-center">
                <img
                  src={KyronLogo_New}
                  alt="LOGO"
                  className="w-[50px] h-[60px] mr-5"
                />
                <p className="text-3xl font-semibold text-slate-300">
                  A New Era of Recruitment
                </p>
              </div>
            </div>
            <ResetUser />
          </div>
          <div className="flex md:h-screen bg-gray-900 items-center md:px-7">
            <div className="justify-start items-center">
              <p className="text-4xl font-semibold text-white md:px-10 px-6 py-3">
                How can we help{" "}
              </p>
              <p
                className="lg:text-lg sm:text-sm md:text-lg text-justify 
              text-gray-400 md:px-10 px-6 md:leading-8 md:tracking-[0.025em]"
              >
                Kyron is the state of the art system designed and programmed by
                computer science researchers, organizational behavior and human
                psychology experts to find the perfect talent for every job and
                finding appropriate jobs for a candidate's skill set. Kyron's
                journey starts with the skill identification for each job
                profile and our AI algorithm searches for the appropriate set of
                candidates from the candidate pool. The candidates then go
                through a uniquely curated and personalized assessment and
                screening process. The reports are then forwarded to the
                interviewers. Not just that!! Kyron maintains the test integrity
                through our revolutionary Human & AI proctor combo, while
                assuring that candidate experience is exceptional at each step.
                Welcome to the future of Recruiting Science…
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
