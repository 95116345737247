import {
  SET_POSTGRES_ERROR,
  SET_POSTGRES_TABLE,
  UPDATE_LOADING_RESPONSE,
  UPDATE_POSTGRES_QUERY,
  UPDATE_POSTGRES_RESULT,
} from "../actionTypes/postgres";

const initialState = {
  query: "",
  loading: false,
  result: null,
  error: "",
  tables: null,
};

const postgresReducer = function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_POSTGRES_QUERY:
      return {
        ...state,
        query: payload,
      };

    case UPDATE_LOADING_RESPONSE:
      return {
        ...state,
        loading: payload,
      };

    case UPDATE_POSTGRES_RESULT:
      return {
        ...state,
        result: payload,
      };

    case SET_POSTGRES_ERROR:
      return {
        ...state,
        error: payload,
      };

    case SET_POSTGRES_TABLE:
      return{
        ...state,
        tables: payload
      }

    default:
      return state;
  }
};

export default postgresReducer;
