import { useDispatch } from "react-redux";
import { TemplateCode } from "../../components/tests/codeEditor/Utils/Constants";
import {
  FETCH_ALL_INTERVIEWS_SUCCESS,
  FETCH_INTERVIEW_DETAILS_SUCCESS,
  UPDATE_CURRENT_QUESTIONINDEX,
  UPDATE_CURRENT_SECTIONINDEX,
  UPDATE_INTERVIEW_CODE_EDITOR_CODE,
  UPDATE_INTERVIEW_CODE_EDITOR_LANGUAGE,
  GET_CURRENT_SOCKET_PEER_SUCCESS,
  UPDATE_INTERVIEW_QUESTION_SUCCESS,
  SET_CURRENT_SECTION,
  SET_CURRENT_QUESTION_SCORE,
  SET_CURRENT_QUESTION_SCORE_SUCCESS,
  UPDATE_INTERVIEW_RESPONSE_REQUESTED,
  UPDATE_INTERVIEW_PAGE,
  UPDATE_CUSTOM_QUESTION_MODAL,
  FETCH_INTERVIEW_DETAILS_REQUESTED,
  SUBMIT_OVERALL_FEEDBACK_REQUESTED,
  SUBMIT_OVERALL_FEEDBACK_SUCCESS,
  SUBMIT_OVERALL_FEEDBACK_FAILURE,
  UPDATE_OVERALL_FEEDBACK,
} from "../actionTypes/interviewpanel";
import { updateInterviewCodeEditorLanguage } from "../actions/interviewpanel";

const initialState = {
  currentLanguage: localStorage.getItem("currentLanguage") || "cpp",
  customQuestionModal: false,
  interviewerPeerId: "",
  code: TemplateCode,
  interviews: {
    ongoing: {},
    ended: {},
    upcoming: {},
  },
  response: {},
  interviewDetails: {
    components: [],
  },
  loader: false,
  currentSectionIndex: localStorage.getItem("currentSectionIndex") || null,
  currentQuestionIndex: localStorage.getItem("currentQuestionIndex") || null,
  pageNumber: localStorage.getItem("priksha_type") === "S" ? 1 : 3,
};

const interviewpanelReducer = function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_INTERVIEW_CODE_EDITOR_LANGUAGE:
      localStorage.setItem("currentLanguage", payload);
      return { ...state, currentLanguage: payload };

    case UPDATE_INTERVIEW_CODE_EDITOR_CODE:
      return {
        ...state,
        code: { ...state.code, [state.currentLanguage]: payload.code },
      };

    case UPDATE_CUSTOM_QUESTION_MODAL:
      return {
        ...state,
        customQuestionModal: payload.value,
      };

    case FETCH_ALL_INTERVIEWS_SUCCESS:
      return {
        ...state,
        interviews: {
          ...state.interviews,
          [payload.type]: payload.interviews,
        },
      };

    case UPDATE_CURRENT_SECTIONINDEX:
      localStorage.setItem("currentSectionIndex", payload !== null ? payload.index : null);
      let new_components = [...state.interviewDetails.components];
      if (payload !== null) {
        new_components[payload?.index].isReleased = payload.isReleased;
      }
      return {
        ...state,
        currentSectionIndex: payload !== null ? payload.index : null,
        interviewDetails: {
          components: [...new_components],
          ...state.interviewDetails
        }
      };

    case UPDATE_CURRENT_QUESTIONINDEX:
      localStorage.setItem("currentQuestionIndex", payload);
      return {
        ...state,
        currentQuestionIndex: payload,
      };

    case FETCH_INTERVIEW_DETAILS_SUCCESS:
      let newDetails = []
      if (payload.components) {
        newDetails = payload.components.map((section) => {
          return {
            components: section.components.map((question) => {
              return {
                ...question,
                marks: parseInt(question.marks) ? parseInt(question.marks) : 0,
              };
            }),
            isReleased: section.isReleased,
          };
        });
      }
      return {
        ...state,
        interviewDetails: {
          ...payload,
          components: [...newDetails],
        },
        loader: false,
        response: { ...payload?.response },
      };

    // case SET_CURRENT_QUESTION:
    //   const { components, currentQuestionIndex, currentSectionIndex } = state;
    //   let _components = [...components];
    //   _components[currentSectionIndex].components = [
    //     ...components[currentSectionIndex].components,
    //     { ...payload },
    //   ];
    //   return {
    //     ...state,
    //     components: _components,
    //   };

    case UPDATE_INTERVIEW_QUESTION_SUCCESS:
      let newInterviewComponents = payload.map((section) => {
        return {
          components: section.components.map((question) => {
            return {
              ...question,
              marks: parseInt(question.marks) ? parseInt(question.marks) : 0,
            };
          }),
          isReleased: section.isReleased
        };
      });
      return {
        ...state,
        interviewDetails: {
          ...state.interviewDetails,
          components: [...newInterviewComponents],
        },
        response: { ...payload?.response },
      };

    case GET_CURRENT_SOCKET_PEER_SUCCESS:
      return {
        ...state,
        interviewerPeerId: payload,
      };

    case SET_CURRENT_SECTION:
      return {
        ...state,
        interviewDetails: {
          ...state.interviewDewtails,
          components: [
            ...state.interviewDetails.components,
            { components: [] },
          ],
        },
      };

    case SET_CURRENT_QUESTION_SCORE:
      let newScores = [...state.interviewDetails.components];
      newScores[state.currentSectionIndex].components[
        state.currentQuestionIndex
      ] = {
        ...newScores[state.currentSectionIndex].components[
        state.currentQuestionIndex
        ],
        [payload.key]: payload.value,
      };
      return {
        ...state,
        interviewDetails: {
          ...state.interviewDetails,
          components: [...newScores],
        },
      };

    case SET_CURRENT_QUESTION_SCORE_SUCCESS:
      return {
        ...state,
        interviewDetails: {
          ...payload,
          components: [...payload],
        },
      };

    case UPDATE_INTERVIEW_RESPONSE_REQUESTED:
      const new_response = { ...state.response };
      if (!new_response[payload.sectionIndex]) {
        new_response[payload.sectionIndex] = {};
      }
      new_response[payload.sectionIndex][payload.questionIndex] = payload.value;

      return {
        ...state,
        response: { ...new_response },
      };

    case UPDATE_INTERVIEW_PAGE:
      return {
        ...state,
        pageNumber: payload,
      };

    case FETCH_INTERVIEW_DETAILS_REQUESTED:
      return {
        ...state,
        loader: true,
      }

    // case GET_INTERVIEW_RESPONSE_SUCCESS:
    //   console.log("reducer response", payload.response);
    //   return {
    //     ...state,
    //     response: { ...payload?.response },
    //   };

    case SUBMIT_OVERALL_FEEDBACK_REQUESTED:
      return {
        ...state,
        loader: true,
      }

    case SUBMIT_OVERALL_FEEDBACK_SUCCESS:
      return {
        ...state,
        loader: false,
      }


    case SUBMIT_OVERALL_FEEDBACK_FAILURE:
      return {
        ...state,
        loader: false,
      }

    case UPDATE_OVERALL_FEEDBACK:
      return {
        ...state,
        interviewDetails: {
          ...state.interviewDetails,
          [action.payload.key]: action.payload.value
        }
      }

    default:
      return state;
  }
};

export default interviewpanelReducer;
