import React, { Component } from "react";
import "../common/Common.css";
import CourseService from "../../services/course.service";

import AddSection from "./createTest/AddSection";
import TestDetails from "./createTest/TestDetails";
import CreateTestFooter from "./createTest/Footer";
import Loading from "./createTest/Loading";
import DeleteTest from "./createTest/DeleteTest";
import { toast } from "react-toastify";

// Store
import { connect } from "react-redux";
import {
  updateTestMainDataWithKey,
  updateTestLoading,
  fetchTestRequest,
  fetchCompaniesRequested,
  fetchAllGroupDetailsRequested,
} from "../../store/actions/test";
import { toggleSidebar } from "../../store/actions/sidebar";
import { withRouter } from "../common/WithRouter";

class CreateTest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      isCourseSelected: false,
      courses: [],
      testData: {
        title: "",
        course: null,
        start_duration: null,
        end_duration: null,
        description: "",
        assign: [],
        data: {},
        navigation_type: "A",
        is_sections_random: false,
        is_public: false,
        is_proctoring: false,
        is_recording: false,
        components: [
          {
            title: "",
            description: "",
            section_type: "G",
            components: [],
            is_questions_random: false,
            questions_per_page: 10,
          },
        ],
      },
      testId: this.props.params.id,
    };
  }
  componentDidMount() {
    console.log("checkingsdgsd", this.props)
    this.props.fetchCompaniesRequested();
    if (this.props.sidebar.opened) {
      this.props.toggleSidebar();
    }

    this.props.updateTestLoading(true);
    this.props.fetchAllGroupDetailsRequested({ type: "T" });
    // Loading all courses
    CourseService.getAllCourses()
      .then((res) => this.setState({ courses: res.data }))
      .catch((err) => console.log("Error", err));

    // Loading test details
    this.props.fetchTestRequest(this.state.testId);
  }

  updateSectionOrder = (newState) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        testData: { ...prevState.testData, components: newState },
      };
    });
  };
  updateQuestionOrder = (updatedQuestionOrder, sectionIndex) => {
    let testData = this.state.testData;
    let _components = [...testData.components];
    _components[sectionIndex].components = updatedQuestionOrder;
    testData.components = _components;
    this.setState((prevState) => {
      return { ...prevState, testData };
    });
  };

  isAdmin = () => {
    var type = localStorage["priksha_type"].toString();
    return type === "A";
  };

  isTeacher = () => {
    var type = localStorage["priksha_type"].toString();
    return type === "T";
  };
  isKAInstructor = () => {
    var type = localStorage["priksha_type"].toString();
    return type === "KA-I";
  };

  handleToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  handleToastWarning = (msg) => {
    toast.warning(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  render() {
    // console.log(this.props.test);
    return (
      <div>
        {!this.isAdmin() && !this.isTeacher() && !this.isKAInstructor() ? (
          <div>You are not Authorised here</div>
        ) : (
          <>
            <DeleteTest
              testId={this.state.testId}
              title="test"
              handleToast={this.handleToastWarning}
            />
            <div className="md:mx-4 sm:mx-2 mx-1 md:p-4 sm:p-3 p-2 flex-column bg-white">
              {this.props.loading ? (
                <Loading />
              ) : (
                <>
                  <TestDetails
                    companies={this.props.companies}
                    isKAInstructor={this.isKAInstructor()}
                  />
                  <AddSection
                    updateSectionOrder={this.updateSectionOrder}
                    updateQuestionOrder={this.updateQuestionOrder}
                    duplicateQuestion={this.duplicateQuestion}
                    handleToast={this.handleToast}
                  />
                </>
              )}
            </div>
            {!this.props.loading ? (
              <CreateTestFooter
                handleToast={this.handleToast}
                handleToastWarning={this.handleToastWarning}
                testResponseStatus={this.state.testData}
                isKAInstructor={this.isKAInstructor()}
              />
            ) : null}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ test, sidebar }) => {
  return {
    companies: test.companies,
    error: test.errorMessage,
    test: test.test,
    sidebar: sidebar,
    loading: test.loading,
    deleteTestModalActive: test.deleteTestModalActive,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTestMainDataWithKey: (data) =>
      dispatch(updateTestMainDataWithKey(data)),
    updateTestLoading: (data) => dispatch(updateTestLoading(data)),
    fetchTestRequest: (data) => dispatch(fetchTestRequest(data)),
    toggleSidebar: () => dispatch(toggleSidebar()),
    fetchAllGroupDetailsRequested: (data) =>
      dispatch(fetchAllGroupDetailsRequested(data)),
    fetchCompaniesRequested: () => dispatch(fetchCompaniesRequested()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateTest));
