import React from "react";

const ProctoringUserDetails = ({ userDetails }) => {
  return (
    <div className="p-2">
      <div className="flex">
        <div className="w-1/3 font-semibold text-sm">
          User <span className="float-right">:</span>
        </div>
        <div className="w-2/3 pl-3">{userDetails?.name}</div>
      </div>
      <div className="flex">
        <div className="w-1/3 font-semibold text-sm">
          Mobile <span className="float-right">:</span>
        </div>
        <div className="w-2/3 pl-3">{userDetails?.mobile}</div>
      </div>
      <div className="flex">
        <div className="w-1/3 font-semibold text-sm">
          Email <span className="float-right">:</span>
        </div>
        <div className="w-2/3 pl-3">{userDetails?.email}</div>
      </div>
      <div className="flex">
        <div className="w-1/3 font-semibold text-sm">
          Institution <span className="float-right">:</span>
        </div>
        <div className="w-2/3 pl-3">{userDetails?.college}</div>
      </div>
      {/* <div className="flex">
        <div className="w-1/3 font-semibold text-sm">
          Time Zone <span className="float-right">:</span>
        </div>
        <div className="w-2/3 pl-3">{userDetails?.timezone}</div>
      </div>
      <div className="flex">
        <div className="w-1/3 font-semibold text-sm">
          Exam Name<span className="float-right">:</span>
        </div>
        <div className="w-2/3 pl-3"> {userDetails?.title}</div>
      </div>
      <div className="flex">
        <div className="w-1/3 font-semibold text-sm">
          Exam Start Time <span className="float-right">:</span>
        </div>
        <div className="w-2/3 pl-3">{userDetails?.start_duration}</div>
      </div>
      <div className="flex">
        <div className="w-1/3 font-semibold text-sm">
          Exam End Time <span className="float-right">:</span>
        </div>
        <div className="w-2/3 pl-3">{userDetails?.end_duration}</div>
      </div>
      <div className="flex">
        <div className="w-1/3 font-semibold text-sm">
          Exam Duration <span className="float-right">:</span>
        </div>
        <div className="w-2/3 pl-3">{userDetails?.test_duration}</div>
      </div> */}
      <div className="flex">
        <div className="w-1/3 font-semibold text-sm">
          Browser <span className="float-right">:</span>
        </div>
        <div className="w-2/3 pl-3">{userDetails?.userAgent}</div>
      </div>
    </div>
  );
};

export default ProctoringUserDetails;
