import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Send } from "../../assets/img/svg/send.svg";
import { ReactComponent as UploadDocument } from "../../assets/img/svg/Upload.svg";
import { ReactComponent as UploadResume } from "../../assets/img/svg/UploadResume.svg";
import { ReactComponent as FormsResume } from "../../assets/img/svg/FormsResume.svg";
import { ReactComponent as File } from "../../assets/img/svg/file.svg";
import { ReactComponent as Close } from "../../assets/img/svg/navbar_close.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  askQuestionRequest,
  askingQuestionType,
  assignInterviewRequest,
  assignTestRequest,
  createChatRequest,
  exportCSVRequest,
  getChatRequest,
  getResumeDetailsRequest,
  // getDepartmentRequest,
  // getProfileRequest,
  parseResumeRequest,
  reparseResumeRequest,
  sendEmailRequest,
  uploadFileRequest,
  uploadFileSuccess,
} from "../../store/actions/parseResume";
import UniversalButton from "../../components/common/StandardButton/UniversalButton";
import Modal from "../../components/common/Modal/Modal";
import { ReactComponent as Cross } from "../../assets/img/svg/cross.svg";
import { showTime } from "../../components/common/utils";
import ResumeTable from "./ResumeTable";
import {
  getSessionIdForResumes,
  putSignedUrlsForResumes,
  totalParsedResumeApi,
} from "../../services/parseResume.service";
import { UploadFileSingle } from "../../services/upload.service";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../assets/js/toast_config";
import RangeBar from "../../components/common/RangeBar";
import DashBoardTable from "../../components/common/DashBoardTable";
import {
  fetchAllGroupDetailsRequested,
  fetchAllTests,
  fetchCompaniesRequested,
} from "../../store/actions/test";
import Datetime from "react-datetime";
import userService from "../../services/user.service";
import load from "../../assets/img/svg/load.svg";
import AsyncSelect from "react-select/async";
import { BASE_URL } from "../../assets/js/config";
import axios from "axios";
import authHeader from "../../services/auth-header";
import { toggleSidebar } from "../../store/actions/sidebar";
import ResumeLoader from "./ResumeLoader";
import styles from "./ResumeLoader.module.css";
import ViewFileModal from "../../components/modals/ViewFileModal";

let payloadFiles = [];

const CallTable = ({
  list,
  setInterviewStudents,
  setinterviewModal,
  setTestModal,
  setEmailModal,
  setExportModal,
  setShowStoryModal,
  // setSelectAll,
  // selectAll,
  // setSelectedStudentMail,
  // selectedStudentMail,
  setresumeSeen,
  chat_id,
}) => {
  const dispatch = useDispatch();
  const urlQuery = new URLSearchParams(window.location.search);
  const { reparseLoading } = useSelector((state) => state.parseResumeReducer);

  const [studentList, setStudentList] = useState(list);
  const [filter, setFilter] = useState("");
  const [selectedStudentMail, setSelectedStudentMail] = useState([]);
  const [selectAll, setSelectAll] = useState(0);

  useEffect(() => {
    setStudentList(
      list.filter((res) => filter === "" || res.status === filter)
    );
  }, [list, filter]);

  return (
    <>
      <div className="flex gap-2 items-center justify-center mb-[1rem] flex-wrap">
        {/* <div className="flex w-auto gap-2"> */}
        <UniversalButton
          title="Schedule Interview"
          onClickFunction={() => {
            setInterviewStudents(
              list
                .filter((std) => std.status === "I")
                .map((std) => {
                  return {
                    startTimeInterview: "",
                    endTimeInterview: "",
                    student_email: std.email,
                  };
                })
            );

            setinterviewModal(true);
          }}
          classNames="!bg-[#001437] !text-[#a0db32] !px-0 !w-[9rem] sm:!w-[12rem] py-2"
        />
        <UniversalButton
          title="Schedule Test"
          onClickFunction={() => setTestModal(true)}
          classNames="!bg-[#001437] !text-[#a0db32] !px-0 !w-[9rem] sm:!w-[12rem] py-2"
        />
        {/* </div>
        <div className="flex w-auto gap-2"> */}
        <UniversalButton
          title=" Send email"
          onClickFunction={() => setEmailModal(true)}
          classNames="!bg-[#001437] !text-[#a0db32] !px-0 !w-[9rem] sm:!w-[12rem] py-2"
        />
        <UniversalButton
          title="Export"
          onClickFunction={() => {
            // const flt =
            setExportModal(list);
          }}
          classNames="!bg-[#001437] !text-[#a0db32] !px-0 !w-[9rem] sm:!w-[12rem] py-2"
        />
        {/* </div>
        <div className="flex w-auto gap-2"> */}
        <UniversalButton
          title="Reparse"
          loading={reparseLoading}
          onClickFunction={() =>
            dispatch(
              reparseResumeRequest({
                sessionId: urlQuery.get("token"),
                chat_id,
                retry: list
                  .filter((std) => selectedStudentMail.includes(std._id))
                  .map((std) => {
                    return {
                      _id: std._id,
                      resume: std.resume,
                    };
                  }),
              })
            )
          }
          classNames={`${reparseLoading ? "!bg-[#a0db32]" : "!bg-[#001437] !text-[#a0db32]"
            } !px-0 !w-[9rem] sm:!w-[12rem] py-2`}
        />
        <select
          className="w-auto cursor-pointer font-['nunito'] p-2 rounded-[0.3rem] outline-none border border-[#BCFB46]"
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
        >
          <option value="">Choose Status </option>
          <option value="T">Select for test</option>
          <option value="I">Select for Interview</option>
          <option value="R">Reject</option>
        </select>
        {/* </div> */}
      </div>
      {studentList.length ? (
        <DashBoardTable
          studentList={studentList}
          type="R"
          setShowStoryModal={setShowStoryModal}
          setSelectAll={setSelectAll}
          selectAll={selectAll}
          setSelectedStudentMail={setSelectedStudentMail}
          selectedStudentMail={selectedStudentMail}
          setresumeSeen={setresumeSeen}
          chat_id={chat_id}
        />
      ) : (
        <p className="font-['nunito'] text-[1.3rem]">
          No student had been selected according to your filter
        </p>
      )}
    </>
  );
};

const ParseResume = () => {
  const dispatch = useDispatch();
  let inputRef = useRef(null);
  const urlQuery = new URLSearchParams(window.location.search);

  const [driveLink, setDriveLink] = useState("");
  const [question, setQuestion] = useState("");
  const { opened } = useSelector((state) => state.sidebar);
  const [qfParsing, setQfParsing] = useState({
    // count: "",
    // profile: "",
    skill: "",
    query: "",
    title: "",
    experience: "",
    education: "",
  });
  // const [skills, setSkills] = useState([]);
  // const [topResumes, setTopResumes] = useState([]);
  // const [selectAll, setSelectAll] = useState(0);
  // const [selectedStudentMail, setSelectedStudentMail] = useState([]);
  const [page, setPage] = useState(urlQuery.get("token") ? "resume" : "filter");
  const [linkFileOrFolder, setLinkFileOrFolder] = useState("file");
  const [resumeSeen, setresumeSeen] = useState("");
  const [fileOrFolderName, setFileOrFolderName] = useState({});
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [LoadResumes, setLoadResumes] = useState(0);
  const [transferLoading, setTransferLoading] = useState(false);
  const [showStoryModal, setShowStoryModal] = useState("");
  const [sendEmail, setSendEmail] = useState({ message: "", subject: "" });
  const [emailModal, setEmailModal] = useState(false);
  const [testModal, setTestModal] = useState(false);
  const [exportModal, setExportModal] = useState("");
  const [interviewModal, setinterviewModal] = useState();
  const [statusOfStudent, setStatusOfStudent] = useState("");
  const [selectedTestID, setSelectedTestID] = useState("");
  const [interviewDetails, setInterviewDetails] = useState({
    title: "",
    is_disable_copy_paste: false,
    is_disable_tab_switch: false,
    company: "",
    group: "",
    buffer: "",
    start_time: "",
    time: "",
    status: false,
  });
  const [interviewStudents, setInterviewStudents] = useState([]);
  const [allInterviewer, setAllInterviewer] = useState([]);
  const [exportingTableColumn, setExportingTableColumn] = useState({
    name: true,
    email: true,
    story: true,
    llm_confidence_score: true,
    skills: true,
    education: true,
    experience: true,
    mobile: true,
    status: true,
  });
  const [resumeParsingLoading, setResumeParsingLoading] = useState(true);
  const [callApiForLoader, setCallApiForLoader] = useState(false);
  const [parsedTillNow, setParsedTillNow] = useState(0);

  const {
    uploadFileLoading,
    headers,
    questionAnswer,
    parseResumeLoading,
    createChatLoading,
    sendEmailLoading,
    exportCSVLoading,
    assignTestLoading,
    questioning_type,
    reparseLoading,
    selectedMail,
  } = useSelector((state) => state.parseResumeReducer);
  const { allTests, allTestGroups, companies } = useSelector(
    (state) => state.test
  );

  const askQuestionfn = () => {
    if (questioning_type === "Comparison_Query" && selectedMail.length > 5) {
      toast.warning("please select only 5 resume for comparing query", defaultToastSetting)
      return
    }
    dispatch(
      createChatRequest({
        prompt: question,
        session_id: urlQuery.get("token"),
        ...(selectedMail.length
          ? {
            resumes: questionAnswer[0].message.result
              .filter((std) => selectedMail.includes(std._id))
              .map((std) => {
                return {
                  _id: std._id,
                  resume: std.resume,
                };
              }),
          }
          : {
            resumes: questionAnswer[0].message.result.map((std) => {
              return {
                _id: std._id,
                resume: std.resume,
              };
            }),
          }),
      })
    );
    dispatch(askingQuestionType(""))
    setQuestion("");
  };

  const fileChangeHandler = (files, cb) => {
    setFileOrFolderName({ name: files[0].name, size: files[0].size });
    console.log(files);
    for (let index = 0; index < files.length; index++)
      payloadFiles.push(files[index]);

    if (cb && typeof cb === "function") {
      cb();
    }
  };

  const handleFolderChange = (files, cb) => {
    const folderName = files[0].webkitRelativePath.split("/")[0];
    let sz = 0;
    for (let i = 0; i < files.length; i++) {
      if (files[i] && files[i].type === "application/pdf") {
        payloadFiles.push(files[i]);
        sz += files[i].size;
      }
    }
    setFileOrFolderName({ name: folderName, size: sz });

    if (cb && typeof cb === "function") cb();
  };

  // const handleParseResume = () => {
  //   // if (qfParsing.count === "" || +qfParsing.count === 0) {
  //   //   toast.warning(
  //   //     "Number field should not be empty or 0",
  //   //     defaultToastSetting
  //   //   );
  //   //   return;
  //   // }
  //   // if (qfParsing.profile === "") {
  //   //   toast.warning("profile field should not be empty", defaultToastSetting);
  //   //   return;
  //   // }
  //   dispatch(parseResumeRequest({ setPage, ...qfParsing }));
  //   setTopResumes((prev) => {
  //     return [...prev, 0];
  //   });
  // };

  const handleUploadClick = async () => {
    if (linkFileOrFolder === "drive_link") {
      dispatch(
        uploadFileRequest({
          link: driveLink,
          setPage,
          setLoadingPopup,
        })
      );
    } else {
      try {
        setTransferLoading(true);
        const response1 = await getSessionIdForResumes({
          // no_of_resumes: payloadFiles.length,
          job_description: qfParsing.query,
          title: qfParsing.title,
          education: qfParsing.education,
          experience: qfParsing.experience,
          skills: qfParsing.skill,
          // skills: skills.join(", "),

          // job_description:
          //   "Software engineering is a branch of computer science that involves designing, developing, testing, and maintaining software applications. Software engineers use engineering principles and programming languages to create software solutions for users.",
          // title: "Backend Developer",
          // skills: "Python, Java, C",
          // education: "B.Tech in CSE",
          // experience: "3 months",
        });
        const session_id = response1?.data?.session_id;
        if (session_id && payloadFiles.length) {
          const response2 = await putSignedUrlsForResumes({
            session_id,
            no_of_resumes: payloadFiles.length,
          });

          const signedUrls = response2?.signedUrls;
          if (
            signedUrls.length > 0 &&
            payloadFiles.length === signedUrls.length
          ) {
            const two_D_array = [];
            for (let i = 0; i < signedUrls.length; i += 10)
              two_D_array.push(signedUrls.slice(i, i + 10));

            for (let i = 0; i < two_D_array.length; i++) {
              let error = false;

              await Promise.all(
                two_D_array[i].map(async (URL, ind) => {
                  try {
                    await UploadFileSingle(
                      URL?.url,
                      payloadFiles[i * 10 + ind]
                    );
                  } catch (e) {
                    console.error("Error uploading file:", e);
                    toast.error("Something went wrong!", defaultToastSetting);
                  }
                })
              )
                .then(() =>
                  setLoadResumes(((i + 1) / two_D_array?.length) * 100)
                )
                .catch((e) => {
                  toast.error("Something went wrong!", defaultToastSetting);
                  error = true;
                });

              if (error) break;
            }
            if (opened) {
              dispatch(toggleSidebar());
            }
            // window.location.href = `http://localhost:3000/askyourresume?token=${session_id}`;
            window.location.href = `https://app.kyron.in/askyourresume?token=${session_id}`;
          } else {
            toast.error("Something went wrong!", defaultToastSetting);
          }
        } else {
          toast.error("Something went wrong!", defaultToastSetting);
        }
      } catch (error) {
        toast.error(error?.message, defaultToastSetting);
      } finally {
        setTransferLoading(false);
      }
    }
  };

  const debounce = (f, interval) => {
    let timer = null;

    return (...args) => {
      clearTimeout(timer);
      return new Promise((resolve) => {
        timer = setTimeout(() => resolve(f(...args)), interval);
      });
    };
  };

  const promiseOptions = async (inputValue) => {
    return await axios
      .get(`${BASE_URL}api/user/user-filter?val=${inputValue}&type=P`, {
        headers: { ...authHeader() },
      })
      .then((response) => {
        const arrayMap = response.data.data.slice(0, 20).map((data) => {
          return { value: data.email, label: data.email };
        });

        console.log(arrayMap);
        return arrayMap;
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  useEffect(() => {
    if (opened) {
      dispatch(toggleSidebar());
    }
    // if (urlQuery.has("token"))
    //   dispatch(parseResumeRequest(urlQuery.get("token")));
  }, []);

  useEffect(() => {
    if (urlQuery.has("token") && resumeParsingLoading) {
      dispatch(parseResumeRequest(urlQuery.get("token")));
      totalParsedResumeApi(urlQuery.get("token")).then((res) => {
        if (headers?.no_of_resumes !== res.data) {
          setParsedTillNow(res.data);
          setTimeout(() => setCallApiForLoader(!callApiForLoader), 3000);
        } else {
          setResumeParsingLoading(false);
          dispatch(getChatRequest(urlQuery.get("token")));
        }
      });
    }
  }, [callApiForLoader]);

  useEffect(() => {
    if (testModal) dispatch(fetchAllTests({ quiz_type: "upcoming" }));
  }, [testModal]);

  useEffect(() => {
    async function fetchInterviewer() {
      const res = await userService.getAllUsers({ type: "P" });
      setAllInterviewer(res.data);
    }
    if (interviewModal) {
      // fetchInterviewer();
      dispatch(fetchCompaniesRequested());
      dispatch(fetchAllGroupDetailsRequested({ type: "I" }));
    }
  }, [interviewModal]);

  const fun = () => {
    switch (page) {
      case "upload":
        return (
          <div className="flex flex-col md:flex-row gap-[3rem] mt-[2rem] overflow-y-auto overflow-x-hidden md:overflow-hidden items-center justify-between w-full h-[89vh] md:px-[1.5rem] xl:px-[8rem]">
            <div className="flex flex-col items-start gap-4 px-8 md:px-[0px] w-full md:w-[47%] xl:w-[40%] items-center">
              <h1 className="font-['nunito'] capitalize leading-[2rem] text-[1.8rem] md:leading-[2.7rem] md:text-[2.4rem] font-extrabold">
                Unlock Resume secret with
                <span className="text-[#BCFB46] "> Kyron AI</span>
              </h1>
              <p className="font-['nunito'] text-[1.2rem]">
                Engage in a conversation with pool of resumes! Our AI model
                provides quick responses to your queries about skills, location,
                qualifications, and more. Select the best resumes out of
                multiple resumes in couple of Minutes.
              </p>
              <UploadResume className="w-[19rem] scale-150 mt-[2rem]" />
            </div>
            <div className="w-full px-3 md:px-[0px] md:w-[60%] flex flex-col items-center gap-[1rem] pb-[1rem] ">
              <div
                style={{ boxShadow: "0px 0px 15px 0px #BCFB46" }}
                className="w-full  rounded-[10px] border border-[#BCFB46] flex flex-col gap-2 p-5"
              >
                <div
                  className={`w-full h-[19rem] rounded-lg flex flex-col gap-[3rem] justify-center items-center border-dashed border-[#BCFB46] border-2`}
                >
                  {linkFileOrFolder === "folder" ? (
                    <div
                      className="w-full h-full flex justify-center items-center"
                      onDragOver={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // handleFolderChange(e.dataTransfer.files);
                      }}
                    >
                      <button
                        className="flex items-center flex-col justify-center gap-2 "
                        onClick={() => inputRef.click()}
                      >
                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            handleFolderChange(e.target.files, () => {
                              e.target.value = null;
                            });
                          }}
                          ref={(fileInput) => (inputRef = fileInput)}
                          directory=""
                          webkitdirectory=""
                        />

                        <UploadDocument
                          style={{ width: "3rem", height: "3rem" }}
                        />
                        <p className="text-[#2b2ef0]">
                          Click here to upload folder or drag and drop
                        </p>
                      </button>
                    </div>
                  ) : null}

                  {linkFileOrFolder === "file" ? (
                    <div
                      className="w-full h-full flex justify-center items-center"
                      onDragOver={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        fileChangeHandler(e.dataTransfer.files);
                      }}
                    >
                      <button
                        className="flex items-center flex-col justify-center gap-2 "
                        onClick={() => inputRef.click()}
                      >
                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            fileChangeHandler(e.target.files, () => {
                              e.target.value = null;
                            });
                          }}
                          ref={(fileInput) => (inputRef = fileInput)}
                          accept="application/pdf"
                          multiple
                        />

                        <UploadDocument
                          style={{ width: "3rem", height: "3rem" }}
                        />
                        <p className="text-[#2b2ef0]">
                          Click here to upload file or drag and drop
                        </p>
                      </button>
                    </div>
                  ) : null}

                  {linkFileOrFolder === "drive_link" ? (
                    <div className="w-full px-[1rem] flex justify-center flex-col items-start font-['nunito']">
                      <p className="text-[1.1rem] font-semibold">
                        Enter your resume drive link
                      </p>
                      <input
                        type="text"
                        value={driveLink}
                        onChange={(e) => setDriveLink(e.target.value)}
                        placeholder="Enter drive link"
                        className="w-[95%] pl-3 mt-2 border border-[#BCFB46] rounded-[0.3rem] py-[0.4rem] px-3 outline-none"
                      />
                    </div>
                  ) : null}

                  {fileOrFolderName?.name ? (
                    <div className="w-[19rem]  px-[1rem] py-[0.5rem] bg-[#fafafa] flex rounded-[1rem] items-center relative gap-[1rem]">
                      <File style={{ width: "3rem", height: "3rem" }} />
                      <div className="flex items-start flex-col">
                        <p>{fileOrFolderName?.name}</p>
                        <p className="text-[#8193A8]">
                          Size : {fileOrFolderName?.size / 1000} KB
                        </p>
                      </div>
                      <Close
                        className="w-[1rem] h-[1rem] rounded-[50%] cursor-pointer absolute right-[0.6rem] top-[0.6rem]"
                        onClick={() => {
                          payloadFiles = [];
                          setFileOrFolderName("");
                        }}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="flex items-center justify-evenly rounded-lg border border-[2px]">
                  <p
                    className={`py-3 px-5 text-center cursor-pointer w-full ${linkFileOrFolder === "file" && "bg-[#BCFB46]"
                      }`}
                    onClick={() => {
                      setLinkFileOrFolder("file");
                      setFileOrFolderName({});
                    }}
                  >
                    File
                  </p>
                  <p
                    className={`py-3 px-5 cursor-pointer text-center w-full ${linkFileOrFolder === "folder" && "bg-[#BCFB46]"
                      }`}
                    onClick={() => {
                      setLinkFileOrFolder("folder");
                      setFileOrFolderName({});
                    }}
                  >
                    Folder
                  </p>
                  <p
                    className={`py-3 px-5 cursor-pointer text-center w-full ${linkFileOrFolder === "drive_link" && "bg-[#BCFB46]"
                      }`}
                    onClick={() => {
                      setLinkFileOrFolder("drive_link");
                      setFileOrFolderName({});
                    }}
                  >
                    Drive Link
                  </p>
                </div>
              </div>
              {LoadResumes !== 0 ? (
                <RangeBar step={LoadResumes} height={19} />
              ) : null}
              <UniversalButton
                loading={uploadFileLoading || transferLoading}
                classNames="py-3"
                title="Upload Document"
                onClickFunction={() => handleUploadClick()}
              />
            </div>
          </div>
        );

      case "filter":
        return (
          <div className="flex flex-col overflow-y-auto gap-[3rem] mt-[2rem] overflow-x-hidden md:overflow-hidden md:flex-row items-center justify-between w-full h-[89vh] md:px-[1.5rem] xl:px-[8rem]">
            <div className="flex flex-col items-start gap-4 px-8 md:px-[0px] w-full md:w-[47%] xl:w-[45%] items-center">
              <h1 className="font-['nunito'] capitalize leading-[2rem] text-[1.8rem] md:leading-[2.7rem] md:text-[2.4rem] font-extrabold">
                Personalize Your Resume Search: Select Your Priority with
                <span className="text-[#BCFB46] "> Ask Resume</span>
              </h1>
              <p className="font-['nunito'] text-[1.2rem]">
                Engage in a conversation with pool of resumes! Our AI model
                provides quick responses to your queries about skills, location,
                qualifications, and more. Select the best resumes out of
                multiple resumes in couple of Minutes.
              </p>
              <FormsResume className="w-[19rem] scale-150 mt-[2rem]" />
            </div>
            <div className="w-full px-3 md:px-[0px] md:w-[45%] flex flex-col items-center gap-[2rem] pb-[1rem] ">
              <div
                style={{ boxShadow: "0px 0px 15px 0px #BCFB46" }}
                className="rounded-[10px] w-full border border-[#BCFB46] flex flex-col gap-3 p-5 py-[2rem]"
              >
                <div className="flex flex-1 w-full gap-2 flex-col sm:flex-row">
                  <div className="flex-1">
                    <p className="mb-1 text-[1rem] font-['nunito'] pl-1 font-semibold">
                      Job Title
                    </p>
                    <input
                      name="title"
                      value={qfParsing.title}
                      onChange={(e) =>
                        setQfParsing((prev) => {
                          return { ...prev, [e.target.name]: e.target.value };
                        })
                      }
                      placeholder="eg: Frontend Developer"
                      className="w-full border border-[#BCFB46] rounded-[0.3rem] p-2 px-3 outline-none"
                    />
                  </div>
                  <div className="flex-1">
                    <p className="mb-1 text-[1rem] font-['nunito'] pl-1 font-semibold">
                      Experience(In months)
                    </p>
                    <input
                      name="experience"
                      value={qfParsing.experience}
                      onChange={(e) =>
                        setQfParsing((prev) => {
                          return { ...prev, [e.target.name]: e.target.value };
                        })
                      }
                      placeholder="eg: 6 months"
                      className="w-full border border-[#BCFB46] rounded-[0.3rem] p-2 px-3 outline-none"
                    />
                  </div>
                  <div className="flex-1">
                    <p className="mb-1 text-[1rem] font-['nunito'] pl-1 font-semibold">
                      Education
                    </p>
                    <input
                      name="education"
                      value={qfParsing.education}
                      onChange={(e) =>
                        setQfParsing((prev) => {
                          return { ...prev, [e.target.name]: e.target.value };
                        })
                      }
                      placeholder="eg: Bachelor of Technology"
                      className="w-full border border-[#BCFB46] rounded-[0.3rem] p-2 px-3 outline-none"
                    />
                  </div>
                </div>
                <div className="flex-1 w-full">
                  <p className="mb-1 text-[1rem] font-['nunito'] pl-1 font-semibold">
                    Skills
                  </p>
                  <textarea
                    name="skill"
                    value={qfParsing.skill}
                    onChange={(e) =>
                      setQfParsing((prev) => {
                        return { ...prev, [e.target.name]: e.target.value };
                      })
                    }
                    rows="2"
                    placeholder="Please enter skills (comma seperated)"
                    className="w-full border border-[#BCFB46] rounded-[0.3rem] p-2 px-3 outline-none"
                  />
                </div>
                <div className="flex-1 w-full">
                  <p className="mb-1 text-[1rem] font-['nunito'] pl-1 font-semibold">
                    Your Job Description
                  </p>
                  <textarea
                    name="query"
                    value={qfParsing.query}
                    onChange={(e) =>
                      setQfParsing((prev) => {
                        return { ...prev, [e.target.name]: e.target.value };
                      })
                    }
                    rows="5"
                    placeholder="Please enter your job description"
                    className="w-full border border-[#BCFB46] rounded-[0.3rem] p-2 px-3 outline-none"
                  />
                </div>
              </div>

              <UniversalButton
                loading={parseResumeLoading}
                classNames="py-3 font-['nunito']"
                title="Next"
                onClickFunction={() => {
                  if (
                    qfParsing?.query === "" ||
                    qfParsing.education === "" ||
                    qfParsing.experience === "" ||
                    qfParsing.skill === "" ||
                    qfParsing.title === ""
                  ) {
                    toast.warning(
                      "Please fill all the fields",
                      defaultToastSetting
                    );
                    return;
                  } else setPage("upload");
                }}
              />
            </div>
          </div>
        );

      case "resume":
        return (
          <>
            {resumeParsingLoading ? (
              <>
                <div className="w-full items-center justify-center flex h-[85vh]">
                  {/* <img src={load} alt="loading" className="spinner" /> */}
                  {/* <div class="relative flex justify-center items-center">
                    <div class="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-[#BCFB46]"></div>
                    <img
                      src="https://www.svgrepo.com/show/509001/avatar-thinking-9.svg"
                      class="rounded-full h-28 w-28"
                    />
                  </div> */}
                  <ResumeLoader
                    total={headers?.no_of_resumes}
                    parsedTill={parsedTillNow}
                  />
                </div>
              </>
            ) : (
              <div className="flex gap-[1rem]">
                <div className={`w-[100%]`}>
                  <div className="w-[100%] flex-1 mt-2 scrollbar overflow-y-auto flex flex-col gap-3 text-[#141414] py-[1rem] px-1 sm:px-4 md:px-[2rem] pb-[7rem]">
                    <div className="bg-[#B8FB3C] w-full rounded-[0.3rem] py-4 px-1 sm:px-5">
                      <h1 className="font-['nunito'] text-center text-[1.4rem] font-semibold">
                        Total {headers?.no_of_resumes} resumes are requested for
                      </h1>
                      <div className="bg-[#f7f7f7] my-1 px-3 py-2 rounded font-['nunito'] text-[1.1rem] font-semibold">
                        <h1>
                          <span className="text-[1.2rem] font-bold">
                            Job Title:{" "}
                          </span>
                          {headers?.title}
                        </h1>
                        <h1>
                          <span className="text-[1.2rem] font-bold">
                            Experience:{" "}
                          </span>
                          {headers?.experience}
                        </h1>
                        <h1>
                          <span className="text-[1.2rem] font-bold">
                            Job Description:{" "}
                          </span>
                          {headers?.job_description}
                        </h1>
                      </div>

                      {/* <p className="font-['nunito'] mt-1 text-[0.9rem] font-bold">
                        {
                          questionAnswer?.[0]?.message.filter(
                            (q) => q.progress_counter === 100
                          ).length
                        }
                        resumes are completly parsed
                      </p> */}
                    </div>
                    {questionAnswer?.map((qa) => (
                      <div
                        className={`w-full rounded-[1rem] py-4 px-1 sm:px-5 ${qa.from ===
                          `user_${localStorage.getItem("priksha_email")}`
                          ? "self-end bg-[#F2F7FB] !w-auto rounded-tr-[0rem]"
                          : qa.message.type === "table"
                            ? "self-start bg-[#B8FB3C] !rounded-tl-[0rem]"
                            : "self-start bg-[#B8FB3C] !w-auto !rounded-tl-[0rem]"
                          }`}
                      >
                        {qa.from ===
                          `user_${localStorage.getItem("priksha_email")}` ? (
                          <>
                            <p className={`max-w-[100%] rounded-[1rem] w-auto`}>
                              {qa.message.result}
                            </p>
                            <div className="w-[100%] mt-2 gap-3 flex justify-between text-[#4287f5]">
                              <p>{showTime(qa.created_at)}</p>
                            </div>
                          </>
                        ) : (
                          <>
                            {qa.message.type === "table" ? (
                              <CallTable
                                list={qa.message.result}
                                setInterviewStudents={setInterviewStudents}
                                setinterviewModal={setinterviewModal}
                                setTestModal={setTestModal}
                                setEmailModal={setEmailModal}
                                setExportModal={setExportModal}
                                setShowStoryModal={setShowStoryModal}
                                // setSelectAll={setSelectAll}
                                // selectAll={selectAll}
                                // setSelectedStudentMail={setSelectedStudentMail}
                                // selectedStudentMail={selectedStudentMail}
                                setresumeSeen={setresumeSeen}
                                chat_id={qa._id}
                              />
                            ) : (
                              <p className={`rounded-[1rem] !w-auto`}>
                                {qa.message.result}
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    ))}

                    {createChatLoading ? (
                      // <div className="flex ml-5 my-[1.2rem] gap-4">
                      //   <div className="self-start animate-ping bg-[#000] p-1 rounded-full"></div>
                      //   <div className="self-start animate-ping bg-[#000] p-1 rounded-full"></div>
                      //   <div className="self-start animate-ping bg-[#000] p-1 rounded-full"></div>
                      // </div>

                      <div className={styles.skeletonLoader} />
                    ) : null}
                  </div>
                  <div
                    className={`fixed w-[100%] py-2 px-[1.5rem] gap-5 flex flex-col sm:flex-row rounded bg-[#edf0ee] bottom-0`}
                  >
                    <select
                      className="w-auto cursor-pointer font-['nunito'] p-2 rounded-[0.3rem] outline-none border border-[#BCFB46]"
                      onChange={(e) => dispatch(askingQuestionType(e.target.value))}
                      value={questioning_type}
                    >
                      <option value="">Choose Query Type </option>
                      <option value="Comparison_Query">Comparison Query</option>
                      <option value="General_Query">General Query </option>
                    </select>
                    <div className="flex-1 relative ">
                      <input
                        type="text"
                        placeholder="Ask any question about your document"
                        className="w-[100%] p-3 rounded-[0.5rem] bg-[#fff] outline-none border-[2px] border-[#B8FB3C]"
                        name="question"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        onKeyDown={(e) => e.key === "Enter" && askQuestionfn()}
                      />
                      <div
                        className="w-auto p-[0.8rem] rounded-[2rem] absolute top-1 cursor-pointer right-[2.5rem]"
                        onClick={() => askQuestionfn()}
                      >
                        <Send
                          color="#a0db32"
                          style={{ transform: "scale(1.5)" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        );

      default:
        break;
    }
  };

  return (
    <>
      <div>{fun()}</div>

      <Modal
        onClose={() => setLoadingPopup(false)}
        isModal={loadingPopup}
        showCloseButton
        className={"w-[92%] sm:w-[80%] md:w-[65%] lg:w-[50%] xl:w-[35%]"}
      >
        <h1 className="w-full mb-4 mt-[3rem] text-[1.2rem] font-semibold text-center px-[1rem]">
          You will receive an email once the uploaded resumes are processed and
          your top candidates have been selected.
        </h1>
      </Modal>

      <Modal
        onClose={() => setEmailModal(false)}
        isModal={emailModal}
        showCloseButton
        className={"w-[92%] sm:w-[80%] md:w-[65%] lg:w-[50%] xl:w-[35%]"}
      >
        <div className="w-full">
          <h1 className="w-full text-[1.7rem] mb-3 font-semibold text-center">
            Send Email
          </h1>
          <div className="mb-3">
            <label className="block text-gray-700 text-sm font-bold mb-1">
              Select Status of student
            </label>
            <select
              className="w-full cursor-pointer font-['nunito'] p-2 rounded-[0.3rem] outline-none border border-[#BCFB46]"
              onChange={(e) => setStatusOfStudent(e.target.value)}
            >
              <option value=""> Status of student </option>
              <option value="T">Select for test students</option>
              <option value="I">Select for Interview students</option>
              <option value="R">Rejected students</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="block text-gray-700 text-sm font-bold mb-1">
              Subject
            </label>
            <input
              className="border-2 p-2 border-gray-100 w-full rounded"
              placeholder="Type your subject"
              value={sendEmail.subject}
              name="subject"
              onChange={(e) =>
                setSendEmail((prev) => {
                  return {
                    ...prev,
                    [e.target.name]: e.target.value,
                  };
                })
              }
            />
          </div>
          <div className="mb-3">
            <label className="block text-gray-700 text-sm font-bold mb-1">
              Message
            </label>
            <textarea
              className="border-2 p-2 border-gray-100 w-full rounded"
              placeholder="Type your message here..."
              value={sendEmail.message}
              name="message"
              onChange={(e) =>
                setSendEmail((prev) => {
                  return {
                    ...prev,
                    [e.target.name]: e.target.value,
                  };
                })
              }
              rows={6}
            />
          </div>
          <div className="w-full flex items-center justify-center">
            <UniversalButton
              title="Send Emails"
              onClickFunction={() => {
                if (
                  sendEmail.subject !== "" &&
                  sendEmail.message !== "" &&
                  statusOfStudent !== ""
                ) {
                  dispatch(
                    sendEmailRequest({
                      emails: questionAnswer[0].message.result
                        .filter((std) => {
                          if (
                            std.status === undefined &&
                            statusOfStudent === "R"
                          )
                            return true;
                          else if (std.status === statusOfStudent) return true;
                          return false;
                        })
                        .map((std) => std.email),
                      ...sendEmail,
                      setSendEmail,
                    })
                  );
                  setEmailModal(false);
                } else {
                  toast.warning(
                    "Message should not be empty",
                    defaultToastSetting
                  );
                }
              }}
              loading={sendEmailLoading}
            />
          </div>
        </div>
      </Modal>

      <Modal
        onClose={() => setTestModal(false)}
        isModal={testModal}
        showCloseButton
        className={"w-[92%] sm:w-[80%] md:w-[65%] lg:w-[50%] xl:w-[35%]"}
      >
        <div className="w-full">
          <h1 className="w-full text-[1.7rem] mb-3 font-semibold text-center">
            Schedule Test
          </h1>
          <div className="mb-3">
            <label className="block text-gray-700 text-sm font-bold mb-1">
              Select Tests
            </label>
            <select
              className="w-full cursor-pointer font-['nunito'] p-2 rounded-[0.3rem] outline-none border border-[#BCFB46]"
              onChange={(e) => setSelectedTestID(e.target.value)}
            >
              <option value="">Choose Tests</option>
              {Array.isArray(allTests?.upcoming)
                ? allTests?.upcoming?.map((t, ind) => (
                  <option key={`${t}-${ind}`} value={`${t._id}`}>
                    {t.title}
                  </option>
                ))
                : null}
            </select>
          </div>
          <div className="w-full flex items-center justify-center">
            <UniversalButton
              title="Schedule Test"
              loading={assignTestLoading}
              onClickFunction={() => {
                dispatch(
                  assignTestRequest({
                    id: selectedTestID,
                    setTestModal,
                    assign: questionAnswer[0].message.result
                      .filter((std) => std.status && std.status === "T")
                      .map((std) => {
                        return { email: std.email, name: std.name };
                      }),
                  })
                );
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        onClose={() => setExportModal("")}
        isModal={exportModal}
        showCloseButton
        className={"w-[92%] sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%]"}
      >
        <div className="w-full">
          <h1 className="w-full text-[1.7rem] mb-3 font-semibold text-center">
            Exposrt CSV
          </h1>
          <div className="mb-3">
            <label className="block text-gray-700 text-sm font-bold mb-1">
              Select Status of student
            </label>
            <select
              className="w-full cursor-pointer font-['nunito'] p-2 rounded-[0.3rem] outline-none border border-[#BCFB46]"
              onChange={(e) => setStatusOfStudent(e.target.value)}
            >
              <option value=""> Status of student </option>
              <option value="A">All students</option>
              <option value="T">Select for test students</option>
              <option value="I">Select for Interview students</option>
              <option value="R">Rejected students</option>
            </select>
          </div>
          <p className="block text-gray-700 text-sm font-bold mb-1">
            Select columns
          </p>
          <div className="mb-4 flex flex-wrap items-center gap-3 w-full">
            <div className="flex gap-2">
              <input
                checked={exportingTableColumn.name}
                type="checkbox"
                className="scale-[1.2] cursor-pointer"
                onChange={() =>
                  setExportingTableColumn((prev) => {
                    return {
                      ...prev,
                      name: !prev.name,
                    };
                  })
                }
              />
              <label className="font-['nunito'] text-[1.1rem] font-normal">
                Name
              </label>
            </div>
            <div className="flex gap-2">
              <input
                checked={exportingTableColumn.email}
                type="checkbox"
                className="scale-[1.2] cursor-pointer"
                onChange={() =>
                  setExportingTableColumn((prev) => {
                    return {
                      ...prev,
                      email: !prev.email,
                    };
                  })
                }
              />
              <label className="font-['nunito'] text-[1.1rem] font-normal">
                Email
              </label>
            </div>
            <div className="flex gap-2">
              <input
                checked={exportingTableColumn.mobile}
                type="checkbox"
                className="scale-[1.2] cursor-pointer"
                onChange={() =>
                  setExportingTableColumn((prev) => {
                    return {
                      ...prev,
                      mobile: !prev.mobile,
                    };
                  })
                }
              />
              <label className="font-['nunito'] text-[1.1rem] font-normal">
                Mobile
              </label>
            </div>
            <div className="flex gap-2">
              <input
                checked={exportingTableColumn.story}
                type="checkbox"
                className="scale-[1.2] cursor-pointer"
                onChange={() =>
                  setExportingTableColumn((prev) => {
                    return {
                      ...prev,
                      story: !prev.story,
                    };
                  })
                }
              />
              <label className="font-['nunito'] text-[1.1rem] font-normal">
                Story
              </label>
            </div>
            <div className="flex gap-2">
              <input
                checked={exportingTableColumn.llm_confidence_score}
                type="checkbox"
                className="scale-[1.2] cursor-pointer"
                onChange={() =>
                  setExportingTableColumn((prev) => {
                    return {
                      ...prev,
                      llm_confidence_score: !prev.llm_confidence_score,
                    };
                  })
                }
              />
              <label className="font-['nunito'] text-[1.1rem] font-normal">
                Score
              </label>
            </div>
            <div className="flex gap-2">
              <input
                checked={exportingTableColumn.education}
                type="checkbox"
                className="scale-[1.2] cursor-pointer"
                onChange={() =>
                  setExportingTableColumn((prev) => {
                    return {
                      ...prev,
                      education: !prev.education,
                    };
                  })
                }
              />
              <label className="font-['nunito'] text-[1.1rem] font-normal">
                Education
              </label>
            </div>
            <div className="flex gap-2">
              <input
                checked={exportingTableColumn.experience}
                type="checkbox"
                className="scale-[1.2] cursor-pointer"
                onChange={() =>
                  setExportingTableColumn((prev) => {
                    return {
                      ...prev,
                      experience: !prev.experience,
                    };
                  })
                }
              />
              <label className="font-['nunito'] text-[1.1rem] font-normal">
                Experience
              </label>
            </div>
            <div className="flex gap-2">
              <input
                checked={exportingTableColumn.status}
                type="checkbox"
                className="scale-[1.2] cursor-pointer"
                onChange={() =>
                  setExportingTableColumn((prev) => {
                    return {
                      ...prev,
                      status: !prev.status,
                    };
                  })
                }
              />
              <label className="font-['nunito'] text-[1.1rem] font-normal">
                Status
              </label>
            </div>
          </div>
          <div className="w-full flex items-center justify-center">
            <UniversalButton
              title="Export"
              loading={exportCSVLoading}
              onClickFunction={() =>
                dispatch(
                  exportCSVRequest({
                    setExportModal,
                    token: urlQuery.get("token"),
                    status: statusOfStudent,
                    parameters: Object.keys(exportingTableColumn).filter(
                      (key) => exportingTableColumn[key]
                    ),
                    resumes: exportModal
                      .filter(
                        (ls) =>
                          statusOfStudent === "A" ||
                          ls.status === statusOfStudent
                      )
                      .map((ls) => ls._id),
                    resumes_all: exportModal.map((ls) => ls._id),
                  })
                )
              }
            />
          </div>
        </div>
      </Modal>

      <Modal
        onClose={() => setShowStoryModal("")}
        isModal={showStoryModal}
        showCloseButton
        className={"w-[92%] sm:w-[80%] md:w-[70%] lg:w-[65%] xl:w-[55%]"}
      >
        <h1 className="w-full text-[1.7rem] font-['poppins'] font-semibold text-center">
          {showStoryModal?.name} Profile
        </h1>
        <h1 className="text-[1.2rem] font-['nunito'] font-medium">Story:</h1>
        <div className="mt-[0.2rem] bg-[#BCFB46] rounded-t p-3 w-full scrollbar max-h-[25vh] overflow-y-auto">
          <h1 className="w-full text-[1rem] font-['nunito'] font-normal text-justify px-2">
            {showStoryModal?.story}
          </h1>
        </div>
        <h1 className="text-[1.2rem] font-['nunito'] font-medium">
          Education:
        </h1>
        <div className="mt-[0.2rem] bg-[#BCFB46] p-3 w-full scrollbar max-h-[20vh] overflow-y-auto">
          <h1 className="w-full text-[1rem] font-['nunito'] font-normal text-justify px-2">
            {showStoryModal?.education}
          </h1>
        </div>
        <h1 className="text-[1.2rem] font-['nunito'] font-medium">
          Experience:
        </h1>
        <div className="mt-[0.2rem] bg-[#BCFB46] rounded-b p-3 w-full scrollbar max-h-[20vh] overflow-y-auto">
          <h1 className="w-full text-[1rem] font-['nunito'] font-normal text-justify px-2">
            {showStoryModal?.experience}
          </h1>
        </div>
      </Modal>

      <Modal
        onClose={() => {
          setinterviewModal(false);
          setInterviewDetails({
            title: "",
            is_disable_copy_paste: false,
            is_disable_tab_switch: false,
            company: "",
            buffer: "",
            start_time: "",
            time: "",
          });
        }}
        isModal={interviewModal}
        showCloseButton
        className={"w-[92%] sm:w-[80%] md:w-[70%] lg:w-[65%] xl:w-[55%]"}
      >
        <div className="w-full ">
          <h1 className="w-full text-[1.7rem] mb-3 font-semibold text-center">
            Schedule Interview
          </h1>
          <div className="w-full flex justify-end pt-3">
            <div className="w-auto">
              <label className="inline-flex relative items-center mr-5 cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={interviewDetails.status}
                  readOnly
                />
                <div
                  onClick={() => {
                    setInterviewDetails((prev) => {
                      return {
                        ...prev,
                        status: !prev.status,
                      };
                    });
                  }}
                  className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#a0db32]"
                ></div>
                <span className="ml-2 text-md  font-['poppins'] font-medium text-gray-900">
                  {interviewDetails.status ? "Active" : "InActive"}
                </span>
              </label>
            </div>
          </div>
          <div className="flex w-full items-center gap-3 mb-4">
            <div className="flex-1">
              <label className="block text-gray-700 text-sm font-bold mb-1">
                Interview Title
              </label>
              <input
                className="border-2 outline-none p-2 border-gray-100 w-full rounded"
                placeholder="Interview Title"
                name="title"
                value={interviewDetails.title}
                onChange={(e) =>
                  setInterviewDetails((prev) => {
                    return { ...prev, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
            <div className="flex-1">
              <label
                className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
                htmlFor="company"
              >
                Company Name
              </label>
              <div className="relative">
                <select
                  className="border-2 w-full focus:outline-none focus:ring-0  placeholder:font-light text-sm outline-gray-300 border-gray-300 p-2 rounded font-['nunito']"
                  id="company"
                  onChange={(e) =>
                    setInterviewDetails((prev) => {
                      return {
                        ...prev,
                        company: e.target.value,
                      };
                    })
                  }
                  value={interviewDetails.company}
                >
                  <option>Select</option>
                  {companies?.map((company, idx) => (
                    <option value={company._id} key={idx}>
                      {company.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="flex w-full items-center gap-3 mb-4">
            <div className="flex-1">
              <label className="block text-gray-700 text-sm font-bold mb-1">
                Start time
              </label>
              <Datetime
                className="font-normal overflow-visible flex-1 w-full"
                autoComplete="off"
                inputProps={{
                  className:
                    "border-2 w-full focus:outline-none focus:ring-0  placeholder:font-light  text-sm outline-gray-300 border-gray-300 p-2 rounded font-['nunito']",
                  placeholder: "Start Date & Time",
                  name: "start_time",
                }}
                value={new Date(interviewDetails.start_time)}
                onChange={(e) =>
                  setInterviewDetails((prev) => {
                    return { ...prev, start_time: e?._d?.getTime() };
                  })
                }
              />
            </div>
            <div className="flex-1">
              <label className="block text-gray-700 text-sm font-bold mb-1">
                Interview Time
              </label>
              <input
                className="border-2 outline-none p-2 border-gray-100 w-full rounded"
                placeholder="Interview Time (in mnts)"
                name="time"
                value={interviewDetails.time}
                onChange={(e) => {
                  setInterviewDetails((prev) => {
                    return { ...prev, [e.target.name]: e.target.value };
                  });
                  setInterviewStudents((prev) =>
                    prev.map((preStd, ind) => {
                      return {
                        ...preStd,
                        startTimeInterview:
                          interviewDetails.start_time +
                          ind * e.target.value * 60 * 1000,
                        endTimeInterview:
                          interviewDetails.start_time +
                          (ind + 1) * e.target.value * 60 * 1000,
                      };
                    })
                  );
                }}
              />
            </div>
            <div className="flex-1">
              <label className="block text-gray-700 text-sm font-bold mb-1">
                Buffer Time
              </label>

              <input
                className="border-2 outline-none p-2 border-gray-100 w-full rounded"
                placeholder="Interview Buffer time (in mnts)"
                name="buffer"
                value={interviewDetails.buffer}
                onChange={(e) => {
                  setInterviewDetails((prev) => {
                    return { ...prev, [e.target.name]: e.target.value };
                  });
                  setInterviewStudents((prev) =>
                    prev.map((preStd, ind) => {
                      return {
                        ...preStd,
                        startTimeInterview:
                          preStd.startTimeInterview +
                          ind * e.target.value * 60 * 1000,
                        endTimeInterview:
                          preStd.endTimeInterview +
                          ind * e.target.value * 60 * 1000,
                      };
                    })
                  );
                }}
              />
            </div>
          </div>

          <div className="flex justify-start gap-[3rem] pl-1 mb-3">
            <div className="w-full md:flex-1">
              <label
                className="block px-1 font-semibold text-gray-400 font-['nunito'] text-md"
                htmlFor="company"
              >
                Group Name
              </label>
              <div className="relative">
                <select
                  className="border-2 w-full focus:outline-none focus:ring-0  placeholder:font-light text-sm outline-gray-300 border-gray-300 p-2 rounded font-['nunito']"
                  id="company"
                  name="group"
                  onChange={(e) =>
                    setInterviewDetails((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                  value={interviewDetails.group}
                >
                  <option>Select</option>
                  {allTestGroups?.map((company, idx) => (
                    <option value={company._id} key={idx}>
                      {company.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex-1 flex justify-end md:pt-5 items-center gap-[1rem]">
              <div className="flex gap-2">
                <input
                  checked={interviewDetails.is_disable_copy_paste}
                  type="checkbox"
                  onChange={(e) =>
                    setInterviewDetails((prev) => {
                      return {
                        ...prev,
                        is_disable_copy_paste: !prev.is_disable_copy_paste,
                      };
                    })
                  }
                />
                <label className="">Disable Copy/Paste</label>
              </div>
              <div className="flex gap-2">
                <input
                  checked={interviewDetails.is_disable_tab_switch}
                  type="checkbox"
                  onChange={(e) =>
                    setInterviewDetails((prev) => {
                      return {
                        ...prev,
                        is_disable_tab_switch: !prev.is_disable_tab_switch,
                      };
                    })
                  }
                />
                <label className="">Disable Tab Switch</label>
              </div>
            </div>
          </div>

          <div className="flex justify-start gap-2 items-center">
            <p className="flex-1 text-gray-700 text-sm font-bold font-['nunito'] ml-1">
              Student Email
            </p>
            <p className="flex-1 text-gray-700 text-sm font-bold font-['nunito'] ml-2">
              Start Time
            </p>
            <p className="flex-1 text-gray-700 text-sm font-bold font-['nunito']">
              End Time
            </p>
            <p className="flex-1 text-gray-700 text-sm font-bold font-['nunito']">
              Interviewer
            </p>
          </div>
          <div className="scrollbar max-h-[40vh] overflow-y-auto pb-[7rem]">
            {interviewStudents.map((std) => (
              <div className="flex gap-2 items-center justify-between mb-4 overflow-visible">
                <input
                  className="border-2 flex-1 p-2 border-gray-100 w-full rounded"
                  value={std.student_email}
                  disabled
                />
                <Datetime
                  autoComplete="off"
                  inputProps={{
                    className:
                      "border-2 flex-1 focus:outline-none focus:ring-0  placeholder:font-light text-sm outline-gray-300 border-gray-300 p-2 rounded font-['nunito']",
                    placeholder: "Start Date & Time",
                    name: "start_duration",
                  }}
                  value={new Date(std.startTimeInterview)}
                  onChange={(e) =>
                    setInterviewStudents((prev) => {
                      return prev.map((preStd) => {
                        if (preStd.student_email === std.student_email)
                          return {
                            ...preStd,
                            startTimeInterview: e?._d?.getTime(),
                          };
                        return preStd;
                      });
                    })
                  }
                />
                <Datetime
                  autoComplete="off"
                  inputProps={{
                    className:
                      "border-2 flex-1 focus:outline-none focus:ring-0  placeholder:font-light  text-sm outline-gray-300 border-gray-300 p-2 rounded font-['nunito']",
                    placeholder: "End Date & Time",
                    name: "end_duration",
                  }}
                  value={new Date(std.endTimeInterview)}
                  onChange={(e) =>
                    setInterviewStudents((prev) => {
                      return prev.map((preStd) => {
                        if (preStd.student_email === std.student_email)
                          return {
                            ...preStd,
                            endTimeInterview: e?._d?.getTime(),
                          };
                        return preStd;
                      });
                    })
                  }
                />

                <AsyncSelect
                  loadOptions={debounce(promiseOptions, 500)}
                  value={std.interviewer_email}
                  onChange={(e) => {
                    console.log(e);
                    setInterviewStudents((prev) => {
                      return prev.map((preStd) => {
                        if (preStd.student_email === std.student_email)
                          return {
                            ...preStd,
                            interviewer_email: e,
                          };
                        return preStd;
                      });
                    });
                  }}
                  className="flex-1"
                  placeholder="Select your interviewer"
                  noOptionsMessage={({ inputValue }) =>
                    !inputValue ? (
                      "Please enter keyword to search your college"
                    ) : (
                      <span className="login-form-selectInput-button">
                        No results found!
                      </span>
                    )
                  }
                />
              </div>
            ))}
          </div>

          <div className="w-full flex items-center justify-center">
            <UniversalButton
              title="Schedule Interview"
              // loading={exportCSVLoading}
              onClickFunction={() =>
                dispatch(
                  assignInterviewRequest({
                    interview_details: {
                      interview_title: interviewDetails.title,
                      is_disable_copy_paste:
                        interviewDetails.is_disable_copy_paste,
                      is_disable_tab_switch:
                        interviewDetails.is_disable_tab_switch,
                      company: interviewDetails.company,
                      group: interviewDetails.group,
                      status: interviewDetails.status,
                    },
                    students: interviewStudents.map((std) => {
                      return {
                        ...std,
                        interviewer_email: std.interviewer_email.value,
                      };
                    }),
                    setinterviewModal,
                  })
                )
              }
            />
          </div>
        </div>
      </Modal>

      <Modal
        onClose={() => setresumeSeen("")}
        isModal={resumeSeen}
        showCloseButton
        className="md:h-[80%] h-[45%] w-[92%]   xl:w-[60%]"
      >
        <ViewFileModal file={resumeSeen} />
      </Modal>
    </>
  );
};

export default ParseResume;

// {questionAnswer?.map((qa, ind) => (
//   <div
//     className={`max-w-[100%] sm:max-w-[80%] lg:max-w-[75%] w-auto rounded-[0.3rem] w-auto py-4 px-1 sm:px-5 ${
//       qa.type === "q"
//         ? "self-end bg-[#F2F7FB]"
//         : "self-start bg-[#B8FB3C]"
//     }`}
//   >
//     {
//       // ind === 0 ? (
//       //   callTable(qa.resume_list)
//       // ) :
//       qa.type === "q" ? (
//         <>
//           <p
//             className={`max-w-[100%] w-auto rounded-[1rem] w-auto`}
//           >
//             {qa.question}
//           </p>
//           <div className="w-[100%] mt-2 gap-3 flex justify-between text-[#4287f5]">
//             <p>{showTime(qa.time)}</p>
//           </div>
//         </>
//       ) : (
//         <>
//           {/* <p
// className={`max-w-[100%] w-auto rounded-[1rem] w-auto`}
// >
// {qa.response}
// </p>
// <div className="w-[100%] mt-2 gap-3 flex justify-between text-[#4287f5]">
// <p>{showTime(qa.time)}</p>
// </div> */}

//           {
//             <ResumeTable
//               index={ind}
//               setresumeSeen={setresumeSeen}
//               topResumes={topResumes}
//               setTopResumes={setTopResumes}
//               list={qa.resume_list}
//             />
//           }
//         </>
//       )
//     }
//   </div>
// ))}

/* {resumeSeen !== "" ? (
            <div className="w-[100%] md:w-[50%] lg:w-[35%] my-3 relative mr-2 pt-[1.5rem] h-[90vh] p-2 border border-[#B8FB3C]">
              <object
                data={resumeSeen}
                type="application/pdf"
                width="100%"
                height="100%"
              >
                <p>
                  Alternative text - include a link{" "}
                  <a href="https://africau.edu/images/default/sample.pdf">
                    to the PDF!
                  </a>
                </p>
              </object>
              <div
                className="absolute top-[0.2rem] right-[0.2rem] cursor-pointer"
                onClick={() => setresumeSeen("")}
              >
                <Cross />
              </div>
            </div>
          ) : null} */

/* <div className="flex flex-1 gap-3 mt-2 w-full flex-col sm:flex-row">
  <div className="flex-1 relative">
    <p className="mb-1 text-[1rem] font-['nunito'] pl-1 font-semibold">
      Skills
    </p>
    <input
      type="text"
      name="skill"
      value={qfParsing.skill}
      onChange={(e) =>
        setQfParsing((prev) => {
          return { ...prev, [e.target.name]: e.target.value };
        })
      }
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          setSkills((prev) => {
            return [...prev, e.target.value];
          });
          setQfParsing((prev) => {
            return { ...prev, [e.target.name]: "" };
          });
        }
      }}
      placeholder="Enter  skills (optional)"
      className="w-full border border-[#BCFB46] rounded-[0.3rem] p-2 px-3 outline-none"
    />
    <div className="mt-2 w-full overflow-x-auto scrollbar absolute bottom-[-2.3rem] pb-1 flex gap-2 items-center justify-start">
      {skills.map((sk, ind) => (
        <div className="flex bg-[#ebebeb] font-['nunito'] rounded px-2 items-center justify-start gap-3">
          <p>{sk}</p>
          <Cross
            className="w-[0.7rem] flex items-center justify-center h-[0.7rem] cursor-pointer"
            onClick={() =>
              setSkills((prev) => prev.filter((_, index) => index !== ind))
            }
          />
        </div>
      ))}
    </div>
  </div>
</div>; */
