import React, { Component, useState } from "react";
import SignUpUser from "../components/signup";
import KyronLogo_New from "../assets/img/Kyron/KyronLogo_New.png";
import KyronSideLetter from "../assets/img/Kyron/KyronLetterSide.png";
import "../components/common/Common.css";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ButtonSpinner } from "../assets/img/svg/button-spinner.svg";
import { ReactComponent as SignInImg } from "../assets/img/svg/signinImg.svg";

export const SignUp = () => {
  const history = useNavigate();
  const [loading, setLoading] = useState(null);

  return (
    <div className="">
      <img src={KyronSideLetter} alt="LOGO" className="w-[220px]  ml-4 my-4" />
      {loading ? (
        <div className="flex justify-center items-center h-screen w-full">
          <ButtonSpinner />
        </div>
      ) : (
        <div className="grid lg:grid-cols-2 gap-5 items-center mt-[2.5rem]">
          {/* left part */}

          {/* <div className="flex h-full items-center md:px-7 py-7 md:py-0 primaryBgGradient">
            <div className="justify-start items-center md:px-10 px-6 text-white">
              <p className="text-4xl font-['poppins'] font-semibold  py-3">
                How can we help ?
              </p>
              <p
                className="lg:text-lg sm:text-sm md:text-lg text-justify 
           md:leading-8 md:tracking-[0.025em]   font-['poppins'] font-normal "
              >
                Kyron is the state of the art system designed and programmed by
                computer science researchers, organizational behavior and human
                psychology experts to find the perfect talent for every job and
                finding appropriate jobs for a candidate's skill set. Kyron's
                journey starts with the skill identification for each job
                profile and our AI algorithm searches for the appropriate set of
                candidates from the candidate pool. The candidates then go
                through a uniquely curated and personalized assessment and
                screening process. The reports are then forwarded to the
                interviewers. Not just that!! Kyron maintains the test integrity
                through our revolutionary Human & AI proctor combo, while
                assuring that candidate experience is exceptional at each step.
                Welcome to the future of Recruiting Science - by kyron
              </p>
            </div>
          </div> */}
          {/* <div></div> */}
          <div className="justify-start items-center md:px-10 px-6 lg:border-r-2 lg:border-[rgba(0, 0, 0, 0.24)]">
            {/* <p className="text-3xl sm:text-4xl font-['poppins'] font-semibold  py-3">
              How can we help ?
            </p> */}
            <p className="text-3xl sm:text-5xl font-['poppins'] font-semibold md:px-[4rem] lg:px-0 xl:px-[4rem] text-center py-3">
              "Ready to unlock your potential? Join Kyron and take the first
              step toward your dream career."
            </p>

            <div className="relative w-[25rem]">
              <div className="text-[0.9rem] font-bold flex items-start flex-col text-right absolute top-[6.2rem] right-0">
                <p className="mb-1"> Have an account ?</p>
                <p>
                  You can{" "}
                  <span
                    className="text-[#0337EF] hover:underline font-medium cursor-pointer"
                    onClick={() => {
                      console.log("jngfcnb");
                      history("/login");
                    }}
                  >
                    Sign in
                  </span>{" "}
                  here
                </p>
              </div>

              <SignInImg />
            </div>
          </div>

          {/* Right part */}
          <div className="flex-col md:mx-14 sm:mx-8 mx-5">
            <SignUpUser setLoading={setLoading} />
          </div>
        </div>
      )}
    </div>

    // <div className="flex justify-center h-[100vh]">
    //   <div className="grid lg:grid-cols-2 gap-5 items-center bg-[#009999] h-[100vh]">
    //     <div className="flex-col md:mx-14 sm:mx-8 mx-5 ">
    //       <div className="p-2">
    //         <div className="flex justify-start items-center">
    //           <img
    //             src={KyronSideLetter}
    //             alt="LOGO"
    //             className="w-[220px] mr-5"
    //           />
    //           <p className="text-3xl font-semibold text-slate-300">
    //             A New Era of Recruitment
    //           </p>
    //         </div>
    //       </div>
    //       <SignUpUser />
    //     </div>
    //     <div className="flex md:none md:h-full bg-gray-900 items-center justify-center md:px-7 h-[100vh]">
    //       <div className="justify-start items-center">
    //         <p className="text-4xl font-semibold text-white md:px-10 px-6 py-3">
    //           How can we help{" "}
    //         </p>
    //         <p
    //           className="lg:text-lg sm:text-sm md:text-lg text-justify
    //         text-gray-400 md:px-10 px-6 md:leading-8 md:tracking-[0.025em]"
    //         >
    //           Kyron is the state of the art system designed and programmed by
    //           computer science researchers, organizational behavior and human
    //           psychology experts to find the perfect talent for every job and
    //           finding appropriate jobs for a candidate's skill set. Kyron's
    //           journey starts with the skill identification for each job
    //           profile and our AI algorithm searches for the appropriate set of
    //           candidates from the candidate pool. The candidates then go
    //           through a uniquely curated and personalized assessment and
    //           screening process. The reports are then forwarded to the
    //           interviewers. Not just that!! Kyron maintains the test integrity
    //           through our revolutionary Human & AI proctor combo, while
    //           assuring that candidate experience is exceptional at each step.
    //           Welcome to the future of Recruiting Science…
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default SignUp;
