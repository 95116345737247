import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_ALL_CHAT_MSGS_REQUESTED,
  FETCH_CHAT_MSGS_REQUESTED,
} from "../actionTypes";
import {
  fetchChatMsgsFailure,
  fetchChatMsgsSuccess,
  fetchAllChatMsgsFailure,
  fetchAllChatMsgsSuccess,
} from "../actions/chat";
import { fetchChatOfQuiz, fetchChatOfUser } from "../../services/chat.service";

function* fetchChatMsgs(action) {
  try {
    const allMsgs = yield call(fetchChatOfUser, action.payload);
    yield put(fetchChatMsgsSuccess(allMsgs));
  } catch (e) {
    yield put(fetchChatMsgsFailure(e));
  }
}

function* fetchAllChatMsgs(action) {
  try {
    const allMsgs = yield call(fetchChatOfQuiz, action.payload);
    yield put(fetchAllChatMsgsSuccess(allMsgs));
  } catch (e) {
    yield put(fetchAllChatMsgsFailure(e));
  }
}

function* chatSaga() {
  yield takeEvery(FETCH_CHAT_MSGS_REQUESTED, fetchChatMsgs);
  yield takeEvery(FETCH_ALL_CHAT_MSGS_REQUESTED, fetchAllChatMsgs);
}

export default chatSaga;
