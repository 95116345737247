import React, { useState } from "react";
import { ReactComponent as Plus } from "../../../assets/img/svg/plus5.svg";
import { ReactComponent as Delete } from "../../../assets/img/svg/deletecolor.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateQuestionbankModal } from "../../../store/actions/questionbank";
import Modal from "../../../components/common/Modal/Modal";
import AddQuestion from "../../QuestionBank/AddQuestion";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../../assets/js/toast_config";
import UniversalButton from "../../../components/common/StandardButton/UniversalButton";
import { deleteExtractedQuestion } from "../../../store/actions/extractQuestion";
import AddQuestionModal from "./AddQuestionModal";

const RenderQuestionAnswer = () => {
  const dispatch = useDispatch();

  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currInd, setCurrInd] = useState(-1);
  const [selectedQuestion, setSelectedQuestion] = useState([]);
  const [totalQuestion, setTotalQuestion] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [questionTags, setQuestionTags] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [addAllModal, setAddAllModal] = useState(false);

  const { questionAnswer } = useSelector(
    (state) => state.extractQuestionReducer
  );
  const { questionModal } = useSelector((state) => state.questionbank);

  const handleMultipleAdd = () => {
    if (!selectedQuestion.length)
      toast.warning("please select atleast one question", defaultToastSetting);
    else {
      setSelectedTags([]);
      setQuestionTags([]);
      setAddAllModal(true);
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      const allIndex = [];
      const allQueetions = [];
      let i = 0;
      for (let q of questionAnswer) {
        if (!q?.isDisabled) {
          allIndex.push(i);
          allQueetions.push(q);
        }
        i++;
      }
      setSelectedQuestion(allIndex);
      setTotalQuestion(allQueetions);
    } else {
      setTotalQuestion([]);
      setSelectedQuestion([]);
    }
    setSelectAll((prev) => !prev);
  };

  const handleTagChange = (data) => {
    const tags = data.map((tag) => tag.tag_id);
    setSelectedTags([...data]);
    setQuestionTags([...tags]);
  };

  // console.log(selectAll, selectedQuestion, totalQuestion);
  // console.log(selectedTags, questionTags);

  return (
    <>
      <div className="w-[93%] mb-2 flex justify-between items-center">
        <p className="text-[1.7rem] font-semibold font-['nunito']">
          Here your extracted question
        </p>
        <div className="flex justify-center items-center">
          <label className="inline-flex relative items-center mr-5 cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={selectAll}
              readOnly
            />
            <div
              onClick={() => handleSelectAll()}
              className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#a0db32]"
            ></div>
            <span className="ml-2 text-md  font-['poppins'] font-medium text-gray-900">
              select all
            </span>
          </label>
          <UniversalButton
            title="Add Question"
            onClickFunction={() => handleMultipleAdd()}
            classNames="py-3"
          />
        </div>
      </div>
      {questionAnswer?.map((Q, ind) => {
        return (
          <div
            key={`questionAnswer-${ind}`}
            className={`w-[85%]  rounded-[0.5rem] flex p-5 px-[4rem] gap-[2rem] relative ${
              Q?.isDisabled ? "bg-[#fafafa]" : "bg-[#ffffff]"
            }`}
          >
            <div className="text-[#2b2ef0] text-[1.1rem] font-semibold mt-[2.5rem]">
              {ind + 1}.
            </div>
            <div className="flex-1 mt-[2.5rem]">
              <p
                className="pb-1 w-[100%] font-semibold text-[1rem]"
                style={{ borderBottom: "1px solid #2b2ef0" }}
                dangerouslySetInnerHTML={{ __html: Q?.question }}
              ></p>
              {Q.type === "checkbox" ? (
                <div>
                  {Q.options.map((option, index) => (
                    <div
                      key={`Options-${index}-${ind}`}
                      className="pb-1 flex items-center justify-start w-[100%] mt-3 ml-2 font-semibold text-[0.9rem]"
                      style={{ borderBottom: "1px solid #2b2ef0" }}
                    >
                      <input
                        type="checkbox"
                        className="mr-3"
                        onChange={() => {}}
                        checked={Q?.ans_mcq[option?.optionIndex]}
                      />

                      <p dangerouslySetInnerHTML={{ __html: option.name }}></p>
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <div className="text-[#2b2ef0] mt-[1.2rem] text-[1.1rem] pl-2 font-semibold text-[1rem]">
                    Answer
                  </div>
                  <p
                    className="pb-1 w-[100%] pl-2 font-semibold text-[1rem]"
                    style={{ borderBottom: "1px solid #2b2ef0" }}
                    dangerouslySetInnerHTML={{ __html: Q?.answer }}
                  ></p>
                </>
              )}
            </div>
            <div className="flex items-center justify-center gap-4 absolute right-[0.5rem] top-[0.5rem]">
              <UniversalButton
                title="Edit Question"
                onClickFunction={() => {
                  setCurrentQuestion(Q);
                  setCurrInd(ind);
                  dispatch(updateQuestionbankModal({ value: true }));
                }}
                disabled={Q?.isDisabled}
                classNames=" w-auto"
              />
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (selectedQuestion.includes(ind)) {
                    setSelectedQuestion((prev) => {
                      let newState = prev;
                      newState.pop();
                      return newState;
                    });
                    setTotalQuestion((prev) => {
                      let newState = prev;
                      newState = newState.filter(
                        (qst) => qst.question !== Q.question
                      );
                      return newState;
                    });
                  }
                  dispatch(deleteExtractedQuestion(ind));
                }}
              >
                <Delete />
              </div>
            </div>

            <div className="absolute  top-[0.8rem] left-[0.8rem]">
              <input
                type="checkbox"
                checked={Q.isDisabled ? true : selectedQuestion.includes(ind)}
                onChange={() => {
                  if (!selectedQuestion.includes(ind)) {
                    setSelectedQuestion((prev) => {
                      return [...prev, ind];
                    });
                    setTotalQuestion((prev) => {
                      const newState = prev;
                      newState.push(Q);
                      return newState;
                    });
                  } else {
                    setSelectedQuestion((prev) => {
                      const newState = prev.filter((i) => i !== ind);
                      return newState;
                    });
                    setTotalQuestion((prev) => {
                      let newState = prev;
                      newState = newState.filter(
                        (qst) => qst.question !== Q.question
                      );
                      return newState;
                    });
                  }
                }}
                disabled={Q?.isDisabled}
              />
            </div>
          </div>
        );
      })}

      <Modal
        onClose={() => {
          dispatch(updateQuestionbankModal({ value: false }));
        }}
        isModal={questionModal}
        showCloseButton
        className={"md:w-[70%]"}
      >
        <AddQuestion
          currentQuestion={currentQuestion}
          type="EEQ"
          ind={currInd}
        />
      </Modal>
      <Modal
        onClose={() => setAddAllModal(false)}
        isModal={addAllModal}
        showCloseButton
        className={"md:w-[70%]"}
      >
        <AddQuestionModal
          selectedTags={selectedTags}
          selectedQuestion={selectedQuestion}
          questionTags={questionTags}
          totalQuestion={totalQuestion}
          handleTagChange={handleTagChange}
          setAddAllModal={setAddAllModal}
          setSelectedQuestion={setSelectedQuestion}
          setTotalQuestion={setTotalQuestion}
        />
      </Modal>
    </>
  );
};

export default RenderQuestionAnswer;
