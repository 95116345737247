import MidasEditor from "./MidasEditor";

function Main({
  testId,
  title,
  section,
  question,
  description,
  questionId,
  sectionId,
  response,
  is_disable_copy_paste
}) {
  // 	const title = 'Dummy Question Title';
  // 	const question = `A paragraph with *emphasis* and **strong importance**.

  // > A block quote with ~strikethrough~ and a URL: https://reactjs.org.

  // ## Lists
  // * [ ] todo
  // * [x] done

  // A table:

  // | a | b |
  // | - | - |

  // Lift ($L$) can be determined by Lift Coefficient ($C_L$) like the following
  // equation.

  // $$
  // L = \\frac{1}{2} \\rho v^2 S C_L
  // $$

  // `;

  return (
    <MidasEditor
      title={title}
      section={section}
      question={question}
      description={description}
      questionId={questionId}
      sectionId={sectionId}
      testId={testId}
      response={response}
      is_disable_copy_paste={is_disable_copy_paste}
    />
  );
}

export default Main;
