import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addGroupDetailsRequested,
  updateGroupDetailsRequested,
} from "../store/actions/test";
import { toast } from "react-toastify";
import load from "../assets/img/svg/load.svg";
import { defaultToastSetting } from "../assets/js/toast_config";
import UniversalButton from "../components/common/StandardButton/UniversalButton";
import { withRouter } from "../components/common/WithRouter";

class GroupFormModal extends Component {
  constructor() {
    super();
    this.state = {
      group: {
        name: "",
        remarks: "",
        company: "",
        type: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { group, isEdit } = this.props;
    console.log(group);
    if (isEdit) {
      this.setState((prev) => {
        return {
          ...prev,
          group: {
            name: group.name,
            remarks: group.remarks,
            company: group?.company?._id,
            type: group?.type,
          },
        };
      });
    }
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    let new_form_data = { ...this.state.group };
    new_form_data[name] = value;
    this.setState({ group: new_form_data });
  };

  validate = () => {
    const { name, company } = this.state.group;
    if (company === "" || name === "") {
      return false;
    }
    return true;
  };

  handleSubmit() {
    if (this.validate()) {
      if (!this.props.isEdit) {
        this.props.addGroupDetailsRequested(this.state.group);
      } else {
        this.props.updateGroupDetailsRequested({
          id: this.props.group._id,
          data: { ...this.state.group },
        });
      }
    } else {
      toast.error("Do not leave empty fields", defaultToastSetting);
    }
  }

  render() {
    // console.log(this.props.groups);
    return (
      <div
        className={`flex justify-center items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full md:h-full`}
        id="popup-modal"
        style={{ backgroundColor: "rgb(0 ,0 ,0,0.1)" }}
      >
        <div className="flex justify-center items-center p-4 w-full h-full md:h-auto">
          <div className="flex justify-center items-center relative w-full min-w-xs max-w-sm h-full md:h-auto">
            <div className=" relative w-full rounded-lg bg-white shadop-2">
              <div className="flex justify-end p-2">
                <button
                  type="button"
                  onClick={() => this.props.handleShowModal()}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  data-modal-toggle="popup-modal"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <div className="w-full">
                <div className="rounded p-5 pt-0 mb-4">
                  <h1 className="text-center font-poppins tracking-[0.18px] font-semibold text-[#989898] text-xl">
                    {this.props.isEdit
                      ? "Update Test Group"
                      : "Create Test Group"}
                  </h1>
                  <div className="my-3">
                    {/* <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="Email"
                          >
                            Name
                          </label> */}
                    <div className="relative">
                      <input
                        className="border-2 border-gray-300 block px-2.5 pb-2 pt-3 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 focus:border-[#009696] peer"
                        id="Name"
                        type="text"
                        name="name"
                        value={this.state.group.name}
                        onChange={(e) => this.handleChange(e)}
                        placeholder=" "
                      />
                      <label
                        htmlFor="otp"
                        className="font-roboto absolute text-sm text-[#2D333A] duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[#009696] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2"
                      >
                        Name
                      </label>
                    </div>
                  </div>
                  <div className="mb-2">
                    {/* <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="Email"
                          >
                            Remarks
                          </label> */}
                    <div className="relative">
                      <textarea
                        className="border-2 border-gray-300 block px-2.5 pb-2 pt-3 w-full text-sm  bg-transparent rounded appearance-none focus:outline-none focus:ring-0 focus:border-[#009696] peer"
                        placeholder=" "
                        id="remarks"
                        name="remarks"
                        value={this.state.group.remarks}
                        onChange={(e) => this.handleChange(e)}
                        rows={5}
                      />
                      <label
                        htmlFor="summary"
                        className="font-roboto absolute text-sm text-[#2D333A] duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[#009696] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-0 peer-placeholder-shown:top-3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2"
                      >
                        Remarks
                      </label>
                    </div>
                  </div>
                  <div className="mb-6">
                    <div className="relative">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-1"
                        htmlFor="Email"
                      >
                        Company
                      </label>
                      <select
                        className="block font-poppins appearance-none w-full bg-gray-100 border focus:outline-none focus:ring-0 focus:border-[#009696] text-gray-700 py-2 px-3 pr-8 rounded leading-tight"
                        id="grid-state"
                        name="company"
                        value={this.state.group.company}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option>Select Company</option>
                        {this.props.companies.map((company) => {
                          return (
                            <option value={company._id} key={company._id}>
                              {company.name}
                            </option>
                          );
                        })}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 top-[1.7rem] right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    <div className="relative">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-1 mt-3"
                        htmlFor="Email"
                      >
                        Type
                      </label>
                      <select
                        className="block font-poppins appearance-none w-full bg-gray-100 border focus:outline-none focus:ring-0 focus:border-[#009696] text-gray-700 py-2 px-3 pr-8 rounded leading-tight"
                        id="grid-state"
                        name="type"
                        value={this.state.group.type}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option>Select Type</option>
                        <option value="I">Group for Interview</option>
                        <option value="T">Group for Test</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 top-[1.7rem] right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <UniversalButton
                    onClickFunction={() => this.handleSubmit()}
                    title={`${this.props.isEdit ? "Update" : "Create"} Group`}
                    classNames="w-full"
                    loading={this.props.loading}
                  />
                  {/* <button
                    className="flex justify-center items-center w-full bg-[#009696] text-white font-bold py-2 rounded-[3px]"
                    type="button"
                    onClick={() => this.handleSubmit()}
                  >
                    {this.props.isEdit ? (
                      <div className="flex">
                        {this.props.loading && (
                          <img
                            alt="loading"
                            src={load}
                            className="spinner mr-2 w-6 h-6"
                          />
                        )}
                        <div>Update Group</div>
                      </div>
                    ) : (
                      <div className="flex">
                        {this.props.loading && (
                          <img
                            alt="loading"
                            src={load}
                            className="spinner mr-2 w-6 h-6"
                          />
                        )}
                        <div>Create Group</div>
                      </div>
                    )}
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    companies: test.companies,
    groups: test.allTestGroups,
    loading: test.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addGroupDetailsRequested: (data) =>
      dispatch(addGroupDetailsRequested(data)),
    updateGroupDetailsRequested: (data) =>
      dispatch(updateGroupDetailsRequested(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GroupFormModal));
