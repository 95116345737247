import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCurrentQuestionScoreRequested,
  updateCurrentScore,
} from "../../store/actions/interviewpanel";
import { ReactComponent as Delete } from "../../assets/img/svg/delete.svg";
import { ReactComponent as Update } from "../../assets/img/svg/update.svg";
import { ReactComponent as Lock } from "../../assets/img/svg/Lock.svg";
import { ReactComponent as Unlock } from "../../assets/img/svg/Unlock.svg";
import { updateSectionLock } from "../../services/interviewpanel.service";
import { defaultToastSetting } from "../../assets/js/toast_config";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export default function InterviewAccordian(props) {
  const dispatch = useDispatch();
  const interviewId = useParams()?.id;
  const { interviewDetails, currentSectionIndex, currentQuestionIndex } =
    useSelector((state) => state.interviewpanelReducer);

  const handleUpdateScore = () => {
    let value =
      interviewDetails?.components[currentSectionIndex]?.components[
        currentQuestionIndex
      ]?.score;
    dispatch(
      updateCurrentQuestionScoreRequested({
        data: {
          key: "score",
          value,
          section_index: currentSectionIndex,
          question_index: currentQuestionIndex,
        },
        id: interviewId,
      })
    );
  };

  const handleUpdateMarks = () => {
    let value =
      interviewDetails?.components[currentSectionIndex]?.components[
        currentQuestionIndex
      ]?.marks;
    dispatch(
      updateCurrentQuestionScoreRequested({
        data: {
          key: "marks",
          value,
          section_index: currentSectionIndex,
          question_index: currentQuestionIndex,
        },
        id: interviewId,
      })
    );
  };

  const handleScore = (value, key) => {
    dispatch(updateCurrentScore({ key, value }));
  };

  const getType = () => {
    return localStorage.getItem("priksha_type");
  };

  const handleUpdateSection = ({ index, isReleased, isLock }) => {
    if (isLock) {
      updateSectionLock(
        {
          id: interviewId,
          data: { key: "isReleased", value: isReleased, section_index: index }
        }).then(res => {
          if (res.success) {
            toast.success("Section lock updated successfully", defaultToastSetting);
          }
        }).catch(err => toast.error(err.response.data, defaultToastSetting));
    }
    getType() !== "S" && props.updateSectionIndex({ index, isReleased });
  };

  return (
    <>
      {interviewDetails?.components.map((section, index) => (
        <div
          id="accordion-flush"
          data-accordion="collapse"
          data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white"
          data-inactive-classes="text-gray-500 dark:text-gray-400"
          key={"section_" + index}
        >
          <h2 id="accordion-flush-heading-1">
            <div
              className="flex items-center justify-between w-full text-sm px-3 py-1.5 text-left text-gray-800 border-b border-gray-200"
              data-accordion-target="#accordion-flush-body-1"
              aria-expanded="true"
              aria-controls="accordion-flush-body-1"
            >
              <p>
                Section - {index + 1}{" "}
                {(getType() === 'P' || (getType() === 'S' && section?.isReleased)) &&
                  <span>
                    {section.components.length > 0 &&
                      "(" + section.components.length + ")"}
                  </span>}
              </p>
              <div className="flex gap-x-1 items-center justify-center">
                {getType() !== "S" &&
                  <>
                    {section?.isReleased ? (
                      <Unlock
                        fill="#666666"
                        fill-opacity="0.8"
                        onClick={() => handleUpdateSection({ index, isReleased: false, isLock: true })}
                        title="View"
                        alt="view"
                        className=" w-4 h-4 right-0  mr-4 cursor-pointer"
                      />
                    ) : (
                      <Lock
                        onClick={() => handleUpdateSection({ index, isReleased: true, isLock: true })}
                        title="View"
                        alt="view"
                        className=" w-4 h-4 right-0  mr-4 cursor-pointer"
                      />
                    )}

                  </>
                }
                {getType() !== "S" && (
                  <Delete
                    className="w-4 h-4"
                    onClick={() => {
                      props.handleDeleteSection(index);
                    }}
                  />
                )}
                <svg
                  data-accordion-icon
                  className={`w-6 h-6 shrink-0 ${index !== parseInt(currentSectionIndex) && "rotate-180"
                    }`}
                  fill="currentColor"
                  onClick={() => handleUpdateSection({ index, isReleased: section?.isReleased, isLock: false })}
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
          </h2>
          {getType() === "P" &&
            currentQuestionIndex !== null && currentQuestionIndex !== "null" &&
            index == currentSectionIndex && (
              <div
                id="accordion-flush-body-1"
                className="display border-b border-gray-300 bg-white pt-1 px-3"
                aria-labelledby="accordion-flush-heading-1"
              >
                <div className="flex mb-2">
                  <div>
                    <h2 className="text-sm">Score: </h2>
                    <div className="flex gap-x-2 ">
                      <input
                        name="score"
                        value={parseInt(section.components[currentQuestionIndex]?.score) || 0}
                        onChange={(e) => handleScore(e.target.value, "score")}
                        placeholder="Enter number here"
                        className="p-2 w-full bg-[#FAFAFA] text-xs text-black placeholder-[#666666] placeholder-opacity-50 placeholder:text-xs focus:outline-none"
                      ></input>
                      <button
                        onClick={() => handleUpdateScore()}
                      >
                        <Update className="w-7 h-7 text-[#a0db32] hover:bg-[#a0db32] rounded-xl hover:text-[#fff] cursor-pointer" />
                      </button>
                    </div>

                  </div>
                  <div>
                    <h1 className="text-sm">Marks: </h1>
                    <div className="flex gap-x-2">
                      <input
                        name="marks"
                        value={parseInt(section.components[currentQuestionIndex]?.marks) || 0}
                        onChange={(e) => handleScore(e.target.value, "marks")}
                        placeholder="Enter number here"
                        className="p-2 w-full bg-[#FAFAFA] text-xs text-black placeholder-[#666666] placeholder-opacity-50 placeholder:text-xs focus:outline-none"
                      ></input>
                      <button
                        onClick={() => handleUpdateMarks()}
                      >
                        <Update className="w-7 h-7 text-[#a0db32] hover:bg-[#a0db32] rounded-xl hover:text-[#fff] cursor-pointer" />
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            )}
        </div>
      ))}
    </>
  );
}
