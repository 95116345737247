import {
  FETCH_PROCTORED_USER_DETAILS_FAILURE,
  FETCH_PROCTORED_USER_DETAILS_REQUESTED,
  FETCH_PROCTORED_USER_DETAILS_SUCCESS,
} from "../actionTypes";

const initialState = {
  isFetching: false,
  proctoredUserDetails: {},
};

const userDetailsReducer = function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case FETCH_PROCTORED_USER_DETAILS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case FETCH_PROCTORED_USER_DETAILS_REQUESTED:
      return {
        ...state,
        isFetching: true,
        proctoredUserDetails: {},
      };

    case FETCH_PROCTORED_USER_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        proctoredUserDetails: action.payload,
      };

    default:
      return state;
  }
}

export default userDetailsReducer
