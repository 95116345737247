import React from "react";
import Editor, { useMonaco } from "@monaco-editor/react";
import LanguageSelector from "./LanguageSelector";
import { TemplateCode } from "../../../../components/tests/codeEditor/Utils/Constants";

// Store
import { connect } from "react-redux";
import { updateCodeEditor } from "../../../../store/actions/codeeditor";
import {
  toggleRightSidebar,
  updateTestResponse,
} from "../../../../store/actions/test";
import { getType, getUserIdFromPeerId } from "../../../common/utils";
import { updateInterviewResponseRequested } from "../../../../store/actions/interviewpanel";
import { useEffect } from "react";
import CodeEditor from "./CodeEditor";
import { ReactComponent as Dark } from "../../../../assets/img/svg/dark.svg";
import { ReactComponent as Light } from "../../../../assets/img/svg/light.svg";
import Tooltip from "../../../common/Tooltip/Tooltip";

// Code
class Editor1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lineNumber: "off",
      check: false,
      theme: false,
    };
    this.updateCode = this.updateCode.bind(this);
    this.sendSocketEvent = this.sendSocketEvent.bind(this);
  }

  componentDidMount() {
    const { sectionId, questionId, codeResponse, response } = this.props;
    const question_savedResponse =
      response && response[sectionId] && response[sectionId][questionId]
        ? response[sectionId][questionId]
        : null;
    const question_codeResponse =
      codeResponse &&
        codeResponse[sectionId] &&
        codeResponse[sectionId][questionId]
        ? codeResponse[sectionId][questionId]
        : null;

    // console.log(
    //   "response editor",
    //   response,
    //   question_savedResponse,
    //   question_codeResponse
    // );
    if (question_codeResponse === null && Boolean(question_savedResponse)) {
      if (question_savedResponse.code) {
        const newTemplate = {
          ...question_savedResponse.code,
        };
        this.props.updateCodeEditor({
          sectionIndex: sectionId,
          questionIndex: questionId,
          value: newTemplate,
        });
        this.props.type === "T" &&
          this.props.updateTestResponse({
            sectionIndex: sectionId,
            questionIndex: questionId,
            value: { code: newTemplate },
          });
      } else {
        const newTemplate = {
          ...TemplateCode,
          [question_savedResponse.judge_response.language]:
            question_savedResponse.judge_response.code,
        };

        this.props.updateCodeEditor({
          sectionIndex: sectionId,
          questionIndex: questionId,
          value: newTemplate,
        });
      }
    } else if (!Boolean(question_codeResponse)) {
      this.props.updateCodeEditor({
        sectionIndex: sectionId,
        questionIndex: questionId,
        value: TemplateCode,
      });
      this.props.type === "T" &&
        this.props.updateTestResponse({
          sectionIndex: sectionId,
          questionIndex: questionId,
          value: { code: TemplateCode },
        });
    }
  }

  debounce(func, timeout = 3000) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  sendSocketEvent = this.debounce((value) => {
    this.props.socket.emit("send-editor-code-state", {
      state: value,
      userId: getUserIdFromPeerId(this.props.interviewerPeerId),
    });
  });

  updateCode(value) {
    const { sectionId, questionId, codeResponse, language, response } =
      this.props;
    const question_codeResponse =
      codeResponse[sectionId] && codeResponse[sectionId][questionId]
        ? codeResponse[sectionId][questionId]
        : null;
    const upload = Boolean(question_codeResponse) && question_codeResponse;
    if (this.props.type === "I" && this.props.interviewerPeerId) {
      if (getType() === "S") {
        this.sendSocketEvent({
          sectionIndex: sectionId,
          questionIndex: questionId,
          value: {
            ...upload,
            [language]: value,
          },
        });
      }
      this.props.updateInterviewResponseRequested({
        sectionIndex: sectionId,
        questionIndex: questionId,
        value: { code: { ...upload, [language]: value } },
      });
    } else {
      const question_savedResponse =
        response && response[sectionId] && response[sectionId][questionId]
          ? response[sectionId][questionId]
          : null;
      if (Boolean(question_savedResponse) && question_savedResponse.code) {
        this.props.updateTestResponse({
          sectionIndex: sectionId,
          questionIndex: questionId,
          value: { code: { ...upload, [language]: value } },
        });
      }
    }
    this.props.updateCodeEditor({
      sectionIndex: sectionId,
      questionIndex: questionId,
      value: {
        ...upload,
        [language]: value,
      },
    });
  }

  render() {
    const { sectionId, questionId, codeResponse, response, type, socket } =
      this.props;
    const question_codeResponse =
      codeResponse &&
        codeResponse[sectionId] &&
        codeResponse[sectionId][questionId]
        ? codeResponse[sectionId][questionId]
        : null;

    return (
      <div className="pr-3 ">
        <div className="flex justify-start gap-x-3 items-center">
          <LanguageSelector
            sectionId={this.props.sectionId}
            questionId={this.props.questionId}
            testId={this.props.testId}
            type={type}
            socket={socket}
          />
          {this.state.theme ? (
            <Light
              className="cursor-pointer"
              onClick={() =>
                this.setState((prev) => {
                  return {
                    ...prev,
                    theme: !prev.theme,
                  };
                })
              }
            />
          ) : (
            <Dark
              className="cursor-pointer"
              onClick={() =>
                this.setState((prev) => {
                  return {
                    ...prev,
                    theme: !prev.theme,
                  };
                })
              }
            />
          )}
          {this.props.type === "T" && (
            <Tooltip
              text="Click here to read question effectively"
              className="ml-auto"
            >
              <button
                type="button"
                onClick={() => this.props.toggleRightSidebar()}
                className={`text-[#a0db32] border border-[#a0db32] hover:bg-[#a0db32]
                            px-2 py-2 mr-3  hover:text-white font-semibold uppercase text-xs hidden
                            rounded outline-none focus:outline-none ease-linear transition-all duration-150 
                            md:block
                            `}
              >
                Change Code Editor View
              </button>
            </Tooltip>
          )}
        </div>
        {question_codeResponse && this.props.language && (
          <div
            className="border shadow-md "
            style={{
              height: "56vh",
              width: "99%",
            }}
          >
            <CodeEditor
              language={this.props.language}
              is_disable_copy_paste={this.props.is_disable_copy_paste}
              value={
                question_codeResponse
                  ? question_codeResponse[this.props.language]
                  : null
              }
              theme={this.state.theme}
              updateCode={(data) => this.updateCode(data)}
            />
          </div>
        )}
        <hr />
      </div>
    );
  }
}

const mapStateToProps = ({ codeeditor, test, interviewpanelReducer }) => {
  return {
    language: codeeditor.language,
    isRightSidebar: test.isRightSidebar,
    code: codeeditor.code,
    stdin: codeeditor.stdin,
    codeResponse: test.codeResponse,
    interviewerPeerId: interviewpanelReducer.interviewerPeerId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCodeEditor: (data) => dispatch(updateCodeEditor(data)),
    updateTestResponse: (data) => dispatch(updateTestResponse(data)),
    updateInterviewResponseRequested: (data) =>
      dispatch(updateInterviewResponseRequested(data)),
    toggleRightSidebar: () => dispatch(toggleRightSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Editor1);
