import React from "react";
import { ReactComponent as DeleteIcon } from "../../assets/img/svg/delete.svg";
import { ReactComponent as StartIcon } from "../../assets/img/svg/start.svg";
import { ReactComponent as SessionIcon } from "../../assets/img/svg/proctor.svg";
import { ReactComponent as EditIcon } from "../../assets/img/svg/edit.svg";

import { ReactComponent as DefaultCompanyIcon } from "../../assets/img/svg/default_company_logo.svg";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MockBadge } from "../../assets/img/svg/mock.svg";

dayjs.extend(utc);
dayjs.extend(timezone);

const InterviewCard = (props) => {
  const history = useNavigate();
  // const [interviewModal, setInterviewModal] = useState(false);

  const showTime = () => {
    let date = dayjs(props.data.startTimeInterview).tz(dayjs.tz.guess());
    return date.format("dddd, MMMM D YYYY, h:mm a");
  };

  const checkGiveTestEligibility = () => {
    return true;
    // return new Date(props.data.date_time).getTime() <= new Date().getTime();
  };

  const onNextPage = (id) => {
    history(`/interviews/interviewpanel/${id}`);
  };

  //   const handleClickOutside = (event) => {
  //     const { data_index } = this.props;
  //     if (
  //       this.hideDropDown[data_index] &&
  //       !this.hideDropDown[data_index].contains(event.target)
  //     ) {
  //       if (!this.showDropDown[data_index].classList.contains("hidden")) {
  //         this.handleDropDown(data_index);
  //       }
  //     }
  //   };

  const handleReviewInterview = (id) => {
    history(`/interviews/candidatereport/${id}`);
  };

  const isTeacher = () => {
    var type = localStorage["priksha_type"];
    return type === "T";
  };

  const isProctor = () => {
    var type = localStorage["priksha_type"];
    return type === "P";
  };

  const isStudent = () => {
    var type = localStorage["priksha_type"];
    return type === "S";
  };

  return (
    <div className="relative py-2">
      {!props.data?.status && (
        <MockBadge className="absolute right-0 top-[8px]" />
      )}
      <div className="rounded  border shadow-md">
        <div>
          {props?.data?.company?.url ? (
            <img
              src={props?.data?.company?.url}
              alt="logo"
              title="Company"
              className="block w-full h-24  flex-none object-contain p-2 self-center"
            />
          ) : (
            <DefaultCompanyIcon className="block w-full h-24 flex-none object-contain p-2 self-center" />
          )}
        </div>

        <div className="grow justify-center items-center bg-white rounded-b-none rounded-r px-4 py-3 flex flex-col leading-normal font-['Poppins']">
          <p className="w-full text-center text-[1rem] font-semibold text-ellipsis whitespace-nowrap overflow-hidden leading-tight">
            {props?.data?.interview_title}
          </p>
          <p className="text-[#666666] text-[0.9rem] whitespace-nowrap overflow-hidden">
            {isStudent()
              ? "Interviewer : " + props.data?.interviewer_email.split("@")[0]
              : "Interviewee : " + props.data?.student_email.split("@")[0]}
          </p>
          <p className="text-[#666666] text-[0.75rem] mt-1 mb-3 text-ellipsis whitespace-nowrap overflow-hidden">
            {showTime()}
          </p>

          <div className="flex w-full h-6 my-1 items-center justify-center">
            <>
              {checkGiveTestEligibility() && (
                <div className="flex w-full items-center justify-center gap-x-[2rem]">
                  {(isProctor() || isStudent()) && (
                    <button
                      className="hover:bg-gray-100 rounded-lg"
                      onClick={() => onNextPage(props.data._id)}
                    >
                      <StartIcon
                        className="w-5 h-5"
                        alt="svg"
                        color="#a0db32"
                        title="Start Interview"
                      />
                    </button>
                  )}
                  {(isTeacher() || isProctor()) && (
                    <>
                      <button
                        className="hover:bg-gray-100 rounded-lg"
                        onClick={() => handleReviewInterview(props.data._id)}
                      >
                        <SessionIcon
                          className="w-6 h-6"
                          color="#a0db32"
                          alt="svg"
                          title="Session Details"
                        />
                      </button>
                      <button
                        className="hover:bg-gray-100 rounded-lg"
                        onClick={() => props.setEditInterview(props.data)}
                      >
                        <EditIcon
                          className="w-5 h-5 "
                          alt="svg"
                          color="#a0db32"
                          title="Edit Test"
                        />
                      </button>
                      <button
                        className="hover:bg-gray-100 rounded-lg"
                        onClick={() => props.handleDeleteTest(props.data._id)}
                      >
                        <DeleteIcon
                          className="w-5 h-5 "
                          alt="svg"
                          title="Delete Test"
                        />
                      </button>
                    </>
                  )}
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewCard;
