import {
  FETCH_ALL_EVALUATORS_REPORTS_FAILURE,
  FETCH_ALL_EVALUATORS_REPORTS_REQUESTED,
  FETCH_ALL_EVALUATORS_REPORTS_SUCCESS,
  FETCH_REPORT_FAILURE,
  FETCH_REPORT_REQUESTED,
  FETCH_REPORT_SUCCESS,
  SEND_REMINDER_EVALUATOR_FAILED,
  SEND_REMINDER_EVALUATOR_REQUEST,
  SEND_REMINDER_EVALUATOR_SUCCESS,
  UPDATE_SCORE_REQUESTED,
} from "../actionTypes/evaluators";

export const fetchAllAssignedReportsRequested = (data) => {
  return {
    type: FETCH_ALL_EVALUATORS_REPORTS_REQUESTED,
    payload: data,
  };
};

export const fetchAllAssignedReportsSuccess = (data) => {
  return {
    type: FETCH_ALL_EVALUATORS_REPORTS_SUCCESS,
    payload: data,
  };
};

export const fetchAllAssignedReportsFailure = () => {
  return {
    type: FETCH_ALL_EVALUATORS_REPORTS_FAILURE,
  };
};

export const fetchReportRequested = (data) => {
  return {
    type: FETCH_REPORT_REQUESTED,
    payload: data,
  };
};

export const fetchReportSuccess = (data) => {
  return {
    type: FETCH_REPORT_SUCCESS,
    payload: data,
  };
};

export const fetchReportFailure = () => {
  return {
    type: FETCH_REPORT_FAILURE,
  };
};

export const updateScoreRequested = (data) => {
  return {
    type: UPDATE_SCORE_REQUESTED,
    payload: data,
  };
};

export const sendReminderEvaluatorRequest = (data) => {
  return {
    type: SEND_REMINDER_EVALUATOR_REQUEST,
    payload: data,
  };
};
