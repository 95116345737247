import React, { Component } from "react";
import { ReactComponent as UpdateRank } from "../assets/img/svg/updateRank.svg";
import { ReactComponent as EditIcon } from "../assets/img/svg/edit.svg";
import { ReactComponent as DeleteIcon } from "../assets/img/svg/delete.svg";
import { ReactComponent as DownloadIcon } from "../assets/img/svg/download.svg";

import dayjs from "dayjs";
import { toggleSidebar } from "../store/actions/sidebar";
import { connect } from "react-redux";
import { fetchTestsGroupSubmissionsCSV } from "../services/test.service";
import { viewFile } from "../services/upload.service";
import { updateOverallRankRequested } from "../store/actions/test";
import { withRouter } from "../components/common/WithRouter";

class TestGroupsCard extends Component {
  showTime = () => {
    let date = dayjs(this.props.data.start_duration);
    return date.format("dddd, MMMM D YYYY, h:mm a");
  };

  downloadCSVReport = (id) => {
    fetchTestsGroupSubmissionsCSV(id).then(async (data) => {
      const fileURL = await viewFile(data.csv_file, "TEST");
      window.open(fileURL);
    });
  };

  render() {
    const { group } = this.props;
    return (
      <div className="flex w-full py-2">
        <div className="flex flex-col h-auto sm:flex-row w-full rounded sm:h-[7rem] border shadow shadow-md px-3 py-2 items-center gap-4">
          <div>
            <img
              src={group?.company?.url}
              alt="logo"
              title="Company"
              className="block w-full h-full flex-none object-contain p-2 "
            />
          </div>

          <div className="h-full w-full gap-2 sm:gap-0 px-4 sm:px-0 grow bg-white rounded-b-none rounded-r py-3 flex flex-col items-start justify-between leading-normal font-['poppins'] text-[#666666]">
            <div className=" text-lg text-right leading-tight">
              {group.name}
            </div>
            <div className="w-full flex justify-between sm:justify-end gap-x-5">
              <button
                className="hover:bg-gray-100 rounded-lg p-1"
                onClick={() => this.props.handleShowEditModal(group)}
              >
                <EditIcon
                  className="w-5 h-5 text-red"
                  alt="svg"
                  color="#a0db32"
                  title="Edit Test"
                />
              </button>
              <button
                className="hover:bg-gray-100 rounded-lg p-1"
                onClick={() =>
                  this.props.updateOverallRankRequested({
                    quiz_group: group._id,
                  })
                }
              >
                <UpdateRank
                  className="w-5 h-5 text-red"
                  alt="svg"
                  color="#a0db32"
                  title="Update Overall Ranks"
                />
              </button>
              <button
                className="hover:bg-gray-100 rounded-lg p-1"
                onClick={() => this.downloadCSVReport(group._id)}
              >
                <DownloadIcon
                  className="w-5 h-5 text-red"
                  color="#a0db32"
                  alt="svg"
                  title="Download Report"
                />
              </button>
              <button
                className="hover:bg-gray-100 rounded-lg p-1"
                onClick={() => this.props.handleDeleteGroup(group._id)}
              >
                <DeleteIcon
                  className="w-5 h-5 text-red"
                  alt="svg"
                  title="Delete Test"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ test, sidebar }) => {
  return {
    test: test.test,
    sidebar: sidebar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSidebar: () => dispatch(toggleSidebar()),
    updateOverallRankRequested: (data) =>
      dispatch(updateOverallRankRequested(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TestGroupsCard));
