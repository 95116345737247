import React, { Component } from "react";
import { ReactComponent as ArrowUpIcon } from "../../../../assets/img/svg/arrow_up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../assets/img/svg/arrow_down.svg";

// Store
import { connect } from "react-redux";
import {
  updateTestAccordianIndex,
  updateTestAccordianPageIndex,
} from "../../../../store/actions/test";

class AnywhereNavigate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
    };
    this.pageNumberArray = this.pageNumberArray.bind(this);
  }

  pageNumberArray(section) {
    let numberOfPages = Math.ceil(
      section.components.length / section.questions_per_page
    );
    let number_array = [];
    for (let i = 0; i < numberOfPages; i++) {
      number_array.push(i);
    }

    return number_array;
  }

  updateTestAccordianIndex(sectionIndex) {
    this.props.updateTestAccordianIndex(sectionIndex);
    this.props.updateTestAccordianPageIndex(0);
    this.props.saveResponse();
  }

  updateTestAccordianPageIndex(page) {
    this.props.updateTestAccordianPageIndex(page);
    this.props.saveResponse();
  }

  isResponded(dummysection, dummyquestion) {
    const { response } = this.props;
    // console.log("dummy", dummysection, dummyquestion)
    const section = this.props.test?.components[dummysection]?.sectionIndex;
    const question =
      this.props.test?.components[dummysection]?.components[dummyquestion]
        ?.questionIndex;
    // console.log("original", section, question)
    const type =
      this.props.test?.components[dummysection]?.components[dummyquestion]?.type;

    // console.log("checking", this.props.test.components, type, response, section, question)
    if (
      (type !== "coding" &&
        response &&
        response[section] &&
        Boolean(response[section][question])) ||
      (type === "coding" &&
        response &&
        response[section] &&
        response[section][question]?.judge_response)
    ) {
      return true;
    }
    return false;
  }

  isRespondedForPageNumberArray(dummysection) {
    const { response } = this.props;
    const section = this.props.test?.components[dummysection]?.sectionIndex;
    if (response && response[section]) {
      for (var i = 0; i < response[section].length; i++) {
        const type = this.props.test.components[section]?.components[i]?.type;
        if (
          (type !== "coding" &&
            response &&
            response[section] &&
            response[section][i] !== undefined) ||
          (type === "coding" &&
            response &&
            response[section] &&
            response[section][i]?.judge_response)
        )
          return false;
      }
      return true;
    }
    return false;
  }

  render() {
    const { response, onMouseOverEvent, test } = this.props;
    return (
      <div
        className={`accordian font-['nunito']  w-full ${test?.is_proctoring ? "h-[14rem]" : "h-[31rem]"
          }  overflow-auto h-auto pr-1 scrollbar`}
      >
        {test?.components.map((section, sectionIndex) => (
          <div
            key={`ACCORDION-SECTION-${sectionIndex}`}
            className="accordian-item"
          >
            {this.pageNumberArray(section).length !== 0 ? (
              <>
                <div>
                  <div
                    onClick={() => {
                      if (
                        (!this.props.AudioRecordingStatus
                          ?.startPreparationTime ||
                          this.props.AudioRecordingStatus
                            ?.isAnswerTimeCompleted) &&
                        this.props.YTRecordingStatus !== "PLAYING"
                      ) {
                        this.updateTestAccordianIndex(sectionIndex);
                      }
                    }}
                    // rgba(239, 252, 216, 0.04)
                    className={`accordian-header shadow-[0_0px_30px_rgba(188,251,70,0.7)] flex justify-between items-center px-3 py-2 w-full font-bold text-left text-gray-900 ${this.props.currentAccordianIndex === sectionIndex
                      ? `bg-[#BCFB46]`
                      : `bg-gray-50`
                      }   focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 cursor-pointer`}
                    onMouseOver={(e) =>
                      onMouseOverEvent(e, `sidebar-body-acordion-body`)
                    }
                  >
                    <div
                      className={`accordian-title pr-20 ${this.props.currentAccordianIndex === sectionIndex
                        ? "font-medium"
                        : "font-normal"
                        }`}
                      onMouseOver={(e) =>
                        onMouseOverEvent(e, `sidebar-body-acordion-body-title`)
                      }
                    >
                      <span>{section.title}</span>
                    </div>
                    <div
                      className="accordian-icon"
                      onMouseOver={(e) =>
                        onMouseOverEvent(e, `sidebar-body-acordion-body-arrow`)
                      }
                    >
                      {this.props.currentAccordianIndex === sectionIndex ? (
                        <ArrowUpIcon title="View section" alt="opens section" />
                      ) : (
                        <ArrowDownIcon
                          title="View section"
                          alt="opens section"
                        />
                      )}
                    </div>
                  </div>

                  <div
                    className={`${this.props.currentAccordianIndex === sectionIndex
                      ? "flex"
                      : "hidden"
                      } flex-row flex-wrap justify-start items-center px-2 py-1 md:py-2`}
                  >
                    {this.props.currentAccordianIndex === sectionIndex ||
                      true ? (
                      this.pageNumberArray(section).map((page_num, index) =>
                        section.components.length ===
                          this.pageNumberArray(section).length ? (
                          <div
                            onClick={() => {
                              if (
                                (!this.props.AudioRecordingStatus
                                  ?.startPreparationTime ||
                                  this.props.AudioRecordingStatus
                                    ?.isAnswerTimeCompleted) &&
                                this.props.YTRecordingStatus !== "PLAYING"
                              ) {
                                this.updateTestAccordianIndex(sectionIndex);
                                this.updateTestAccordianPageIndex(page_num);
                              }
                            }}
                            className="accordian-child"
                            key={`ACCORDION-SECTION-${sectionIndex}-PAGE-${page_num}`}
                          >
                            <div
                              className={`accordian-child-header py-2 px-1 font-medium text-left ${this.props.currentAccordianPageIndex ===
                                page_num &&
                                this.props.currentAccordianIndex ===
                                sectionIndex
                                ? "text-white"
                                : this.isResponded(sectionIndex, index)
                                  ? "text-[#a0db32]"
                                  : "text-[#FF0000]"
                                }  focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 cursor-pointer`}
                              onMouseOver={(e) =>
                                onMouseOverEvent(
                                  e,
                                  `sidebar-body-page-${page_num + 1}`
                                )
                              }
                            >
                              <div
                                className={`justify-center flex items-center accordian-child-title border  ${this.props.currentAccordianPageIndex ===
                                  page_num &&
                                  this.props.currentAccordianIndex ===
                                  sectionIndex
                                  ? "bg-[#BCFB46]"
                                  : this.isResponded(sectionIndex, index)
                                    ? "border-[#a0db32] bg-[#EEFFCF]"
                                    : "border-[#886f6f] bg-[#FFEBEB]"
                                  }  rounded-full h-[1.6rem] w-[1.9rem]`}
                              >
                                <span className="text-center p-1 ">
                                  {page_num + 1}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              if (
                                (!this.props.AudioRecordingStatus
                                  ?.startPreparationTime ||
                                  this.props.AudioRecordingStatus
                                    ?.isAnswerTimeCompleted) &&
                                this.props.YTRecordingStatus !== "PLAYING"
                              ) {
                                this.updateTestAccordianIndex(sectionIndex);
                                this.props.updateTestAccordianPageIndex(
                                  page_num
                                );
                              }
                            }}
                            className="accordian-child"
                            key={`ACCORDION-SECTION-${sectionIndex}-PAGE-${page_num}`}
                            onMouseOver={(e) =>
                              onMouseOverEvent(
                                e,
                                `sidebar-body-page-${page_num + 1}`
                              )
                            }
                          >
                            <div
                              className={`accordian-child-header flex justify-between items-center 
                                          pl-5 pr-4 py-2  font-medium text-left cursor-pointer
                                          
                                          
                                          bg-white focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800`}
                            >
                              <div
                                className={`flex px-2 border  rounded-full ${this.props.currentAccordianPageIndex ===
                                  page_num &&
                                  this.props.currentAccordianIndex ===
                                  sectionIndex
                                  ? "bg-[#BCFB46]"
                                  : this.isRespondedForPageNumberArray(
                                    sectionIndex
                                  )
                                    ? "border-[#a0db32] bg-[#EEFFCF] text-[#a0db32]"
                                    : "border-[#FF0000] bg-[#FFEBEB] text-[#FF0000]"
                                  } items-center justify-center accordian-child-title`}
                              >
                                <span>Page {page_num + 1}</span>
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <> </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({ test }) => {
  return {
    test: test.test,
    AudioRecordingStatus: test,
    YTRecordingStatus: test.YTRecordingStatus,
    response: test.response,
    currentAccordianIndex: test.currentAccordianIndex,
    currentAccordianPageIndex: test.currentAccordianPageIndex,
    currentQuestionIndex: test.currentQuestionIndex,
    currentSectionIndex: test.currentSectionIndex,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTestAccordianIndex: (data) =>
      dispatch(updateTestAccordianIndex(data)),
    updateTestAccordianPageIndex: (data) =>
      dispatch(updateTestAccordianPageIndex(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnywhereNavigate);
