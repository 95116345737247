import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Navigate, useLocation } from "react-router-dom";
import { loginSuccess } from "../../store/actions/auth";

const Authentication = ({ children, ...rest }) => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const search = useLocation().search;
  const isAictsPlanId = new URLSearchParams(search).get("aicts_plan");

  const isKA = new URLSearchParams(search).get("isKA");
  const type = localStorage.getItem("priksha_type");

  if (isKA && type !== "KA-I") {
    const token = new URLSearchParams(search).get("token");
    const name = new URLSearchParams(search).get("name");
    const email = new URLSearchParams(search).get("email");
    const type = new URLSearchParams(search).get("type");
    localStorage.setItem("priksha_name", name);
    localStorage.setItem("priksha_token", token);
    localStorage.setItem("priksha_type", type);
    localStorage.setItem("priksha_email", email);
    dispatch(loginSuccess({ user: { token, name, email, type } }));
  }

  return isKA ? children : currentUser && !isAictsPlanId ? children : <Navigate to="/login" />
  // (
  //   <Route
  //     {...rest}
  //     render={(props) =>
  //       isKA ? (
  //         Component
  //       ) : currentUser && !isAictsPlanId ? (
  //         Component
  //       ) : (
  //         <Navigate to="/login" />
  //       )
  //     }
  //   />
  // );
};

export default Authentication;
