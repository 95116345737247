import React, { useState } from "react";
import Slider from "react-slick";
import UniversalButton from "../../../common/StandardButton/UniversalButton";
import { toast } from "react-toastify";
import { defaultToastSetting } from "../../../../assets/js/toast_config";
import { useDispatch } from "react-redux";
import Modal from "../../../common/Modal/Modal";
import { sendReminderEvaluatorRequest } from "../../../../store/actions/evaluators";
import { slickSetting } from "../../Arrow";

export default function EvaluatorsStatsSlider({ setEvaluatorsStats }) {
  const dispatch = useDispatch();

  const [selectedEvaluators, setSelectedEvaluators] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [sendReminderModal, setSendReminderModal] = useState(false);

  const handleSelectAll = () => {
    if (!selectAll) {
      const allEvaluators = setEvaluatorsStats.map((e) => e.evaluator_id);
      setSelectedEvaluators(allEvaluators);
    } else setSelectedEvaluators([]);

    setSelectAll((prev) => !prev);
  };

  const handleSendReminder = () => {
    if (selectedEvaluators.length < 1)
      toast.warning(
        "please select atleast one evaluators",
        defaultToastSetting
      );
    else setSendReminderModal(true);
  };

  return (
    <>
      <div className="mx-[1.5rem] font-['nunito'] pt-2 mt-[0.8rem] w-[96%]">
        <div className="flex flex-col sm:flex-row items-end sm:items-center pr-4 mb-3 gap-3">
          <div className="flex justify-between items-center w-full">
            <p className="font-semibold font-['poppins'] text-[1.3rem]">
              Evaluators Assigned
            </p>
            <label className="flex relative items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={selectAll}
                readOnly
              />
              <div
                onClick={() => handleSelectAll()}
                className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#a0db32]"
              ></div>
              <span className="ml-2 text-md  font-['poppins'] font-medium text-gray-900">
                select all
              </span>
            </label>
          </div>
          <UniversalButton
            title="Send Reminder"
            onClickFunction={() => handleSendReminder()}
            classNames="w-[200px] font-medium text-[14px]"
          />
        </div>

        <Slider {...slickSetting}>
          {setEvaluatorsStats?.length > 0 &&
            setEvaluatorsStats.map((evaluator, index) => (
              <div
                key={`evaluator-${evaluator.evaluator_id}`}
                className=" mb-5 py-3 px-3 shadow-lg shadow-gray-600 rounded"
              >
                <div className="flex pb-2 items-center justify-between w-full">
                  <p className="text-md font-medium">
                    {evaluator.evaluator_name}
                  </p>
                  <input
                    type="checkbox"
                    checked={selectedEvaluators.includes(
                      evaluator.evaluator_id
                    )}
                    onChange={() => {
                      if (!selectedEvaluators.includes(evaluator.evaluator_id))
                        setSelectedEvaluators((prev) => {
                          return [...prev, evaluator.evaluator_id];
                        });
                      else
                        setSelectedEvaluators((prev) => {
                          const newState = prev.filter(
                            (id) => id !== evaluator.evaluator_id
                          );
                          return newState;
                        });
                    }}
                  />
                </div>
                <div className="relative w-full pt-1">
                  <div className="flex mb-2 items-center justify-between">
                    <div>
                      <span className="text-[10px] font-semibold inline-block py-1 px-2 uppercase rounded-full  bg-[#001437] text-[#a0db32]">
                        Progress
                      </span>
                    </div>
                    <div className="text-right">
                      <span className="text-[10px] bg-[#001437] p-1 rounded font-semibold inline-block text-[#a0db32]">
                        {evaluator.noOfReportsEvaluated}/
                        {evaluator.noOfReportsAssigned}
                      </span>
                    </div>
                  </div>
                  <div className="overflow-hidden h-2 text-[10px] flex rounded bg-[#BCFB46]">
                    <div
                      style={{
                        width:
                          (evaluator.noOfReportsEvaluated /
                            evaluator.noOfReportsAssigned) *
                            100 +
                          "%",
                      }}
                      className="min-w-[1%] shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-[#001437]"
                    ></div>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>

      <Modal
        isModal={sendReminderModal}
        onClose={() => setSendReminderModal(false)}
      >
        <div className="p-6 text-center">
          <svg
            className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            Are you sure you want to send reminder to Evaluators?
          </h3>

          <UniversalButton
            title="Yes, I'm sure"
            onClickFunction={() => {
              dispatch(
                sendReminderEvaluatorRequest({ assign_ids: selectedEvaluators })
              );
              setSendReminderModal(false);
            }}
            classNames="w-auto mr-3"
          />

          <button
            onClick={() => setSendReminderModal(false)}
            className="text-gray-500 bg-white hover:bg-gray-100 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 "
          >
            No, cancel
          </button>
        </div>
      </Modal>
    </>
  );
}
