// import "react-select/dist/react-select.css";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";

export const DropDown = (props) => {
  const options = props.multi
    ? [{ label: "Select All", value: "all" }, ...props.options]
    : props.options;

  const handleChange = (selected) => {
    props.multi &&
    selected.length &&
    selected.find((option) => option.value === "all")
      ? props.handleChange(options.slice(1))
      : !props.multi
      ? props.handleChange((selected && selected.value) || null)
      : props.handleChange(selected);
  };

  //   const colourStyles = {
  //     option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  //       // const color = chroma(data.color);
  //       console.log("styles", { data, isDisabled, isFocused, isSelected });
  //       return {
  //         ...styles,
  //         backgroundColor: isFocused ? "#999999" : null,
  //         color: "#333333",
  //       };
  //     },
  //   };

  return (
    <div
      className={`react-select-wrapper ${props.multi ? "multi" : ""} min-w-[${
        props?.width
      }px]`}
    >

      {props.isCreatable ? (
        <CreatableSelect
          options={options}
          isMulti={props.multi}
          value={props.value ? props.value : null}
          onChange={(selected) => handleChange(selected)}
          placeholder={<div>{props.placeholder}</div>}
        />
      ) : (
        <Select
          name="example"
          options={options}
          isMulti={props.multi}
          value={props.value ? props.value : null}
          onChange={(selected) => handleChange(selected)}
          placeholder={<div>{props.placeholder}</div>}
          // styles={colourStyles}
          // components={{ ValueContainer }}
        />
      )}
    </div>
  );
};

// const ValueContainer = ({ children, ...props }) => {
//   let [values, input] = children;
//   if (Array.isArray(values)) {
//     const val = (i = Number) => values[i].props.children;
//     const { length } = values;
//     switch (length) {
//       case 1:
//         values = `${val(0)} `;
//         break;
//       default:
//         const otherCount = length - 1;
//         values = `${val(0)}+ ${otherCount} `;
//         break;
//     }
//   }
//   return (
//     <components.ValueContainer {...props}>
//       {values}
//       {input}
//     </components.ValueContainer>
//   );
// };
