import axios from "axios";
import { BASE_URL } from "../assets/js/config";
import authHeader from "./auth-header";

export const runCode = ({
  code,
  lang,
  inp,
  testId,
  questionId,
  sectionId,
  type,
  kind,
}) => {
  return axios
    .post(
      "https://api.kyron.in/" + "api/codeeditor/run",
      {
        code,
        lang,
        inp,
        testId,
        questionIndex: questionId,
        sectionIndex: sectionId,
        type,
        kind,
      },
      {
        headers: {
          ...authHeader(),
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error.response.data);
    });
};

export const submitCode = ({
  code,
  lang,
  inp,
  testId,
  questionId,
  sectionId,
  token,
  kind,
}) => {
  return axios
    .post(
      "https://api.kyron.in/" + "api/codeeditor/submit",
      {
        code,
        lang,
        inp,
        testId,
        questionIndex: questionId,
        sectionIndex: sectionId,
        token,
        kind,
      },
      {
        headers: {
          ...authHeader(),
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error.response.data);
    });
};

export const codeStatus = ({ token }) => {
  return axios
    .get("https://api.kyron.in/" + `api/codeeditor/check-status/${token}`, {
      headers: {
        ...authHeader(),
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error.response.data);
    });
};
