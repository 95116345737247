import axios from "axios";
import React, { Component } from "react";
import { useSelector } from "react-redux";
import warning from "../../../assets/img/svg/warning.png";
import { BASE_URL } from "../../../assets/js/config";
import load from "../../../assets/img/svg/load.svg";
import authHeader from "../../../services/auth-header";

const SubmitModal = ({ submitTest, handleSubmitModal, type }) => {
  const {
    currentAccordianIndex,
    currentAccordianPageIndex,
    quizAssignDetails,
    submitLoading
  } = useSelector((state) => state.test);
  return (
    <div
      className="overflow-y-auto fixed overflow-x-hidden top-0 left-0 flex z-50 justify-center 
                        items-center h-full w-full"
      style={{
        backgroundColor: "rgb(128 ,128 ,128,0.5)",
      }}
    >
      <div
        className="flex-col"
        style={{
          flexShrink: "1",
        }}
      >
        <div className="relative w-full max-w-md h-screen top-0 left-0 md:h-auto bg-white shadow-sm shadow-slate-200  rounded-md py-8 px-12 text-center">
          <img
            src={warning}
            className="w-16 h-16 mx-auto"
            alt="Warning"
            title="warning"
          />
          <div className="my-2">
            {!submitLoading &&
              <h3 className="text-[#1A1A25] font-[poppins] text-lg font-medium">
                Confirm Submission
              </h3>}

            <h3 className="mt-2 text-md font-normal text-slate-700 font-['poppins']">
              {submitLoading ? "Please wait while the test is being submitted. Thank you for your patience." : "Are you sure you want to submit ?"}

            </h3>
          </div>

          <button
            onClick={(e) => {
              if (type === "T" && quizAssignDetails._id) {
                axios
                  .put(
                    `${BASE_URL}api/quizassign/${quizAssignDetails._id}/update-response-question-time`,
                    {
                      e: {
                        sectionIndex: currentAccordianIndex,
                        questionIndex: currentAccordianPageIndex,
                      },
                    },
                    {
                      headers: {
                        ...authHeader(),
                      },
                    }
                  )
                  .finally(() => submitTest());
              } else {
                submitTest();
              }
            }}
            disabled={submitLoading}
            type="button"
            className="text-[#a0db32] border border-[#a0db32] hover:bg-[#a0db32]
              hover:text-white font-semibold uppercase text-xs
              px-4 py-2 mb-1 rounded outline-none focus:outline-none
              ease-linear transition-all duration-150 block w-full mt-6"
          >
            {submitLoading ? <img alt="loading" src={load} className="mx-auto spinner w-6 h-6" /> : 'Yes'}
          </button>
          {!submitLoading &&
            <button
              onClick={() => handleSubmitModal()}
              type="button"
              className="text-[#EB4335] border border-[#EB4335] hover:bg-[#EB4335]
              hover:text-white font-semibold uppercase text-xs
              px-4 py-2 mb-1 rounded outline-none focus:outline-none
              ease-linear transition-all duration-150 block w-full mt-2                       
          "
            >
              No
            </button>}

          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default SubmitModal;
