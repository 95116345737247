import {
  DELETE_EXTRACTED_QUESTION,
  DISABLE_EXTRACTED_QUESTION,
  EXTRACT_QUESTION_FAILED,
  EXTRACT_QUESTION_REQUEST,
  EXTRACT_QUESTION_SUCCESS,
  GET_QUIZ_DETAILS_SUCCESS,
  UPDATE_EXTRACTED_QUESTION,
  UPLOAD_FILE_FAILED_QUESTION,
  UPLOAD_FILE_REQUEST_QUESTION,
  UPLOAD_FILE_SUCCESS_QUESTION,
} from "../actionTypes/extractQuestion";

const initialState = {
  fileToken: {},
  questionAnswer: [],
  addToTestQuestions: [],
  section: [],
  uploadFileLoading: false,
  extractQuestionLoading: false,
};

const extractQuestionReducer = function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPLOAD_FILE_REQUEST_QUESTION:
      return { ...state, uploadFileLoading: true };

    case UPLOAD_FILE_SUCCESS_QUESTION:
      return {
        ...state,
        uploadFileLoading: false,
        fileToken: payload,
      };

    case UPLOAD_FILE_FAILED_QUESTION:
      return { ...state, uploadFileLoading: false };

    case EXTRACT_QUESTION_REQUEST:
      return { ...state, extractQuestionLoading: true };

    case EXTRACT_QUESTION_SUCCESS:
      return {
        ...state,
        extractQuestionLoading: false,
        questionAnswer: [...state.questionAnswer, ...payload],
      };

    case EXTRACT_QUESTION_FAILED:
      return { ...state, extractQuestionLoading: false };

    case UPDATE_EXTRACTED_QUESTION: {
      const { data, ind } = action.payload;
      const newQusetionAns = state.questionAnswer;
      newQusetionAns[ind] = data;

      return { ...state, questionAnswer: newQusetionAns };
    }
    case DELETE_EXTRACTED_QUESTION: {
      const newQusetionAns = state.questionAnswer;
      newQusetionAns.splice(action.payload, 1);

      return { ...state, questionAnswer: newQusetionAns };
    }

    case DISABLE_EXTRACTED_QUESTION: {
      const { index, res } = action.payload;
      const newQusetionAns = state.questionAnswer;

      index.map(
        (ind) =>
          (newQusetionAns[ind] = { ...newQusetionAns[ind], isDisabled: true })
      );

      return {
        ...state,
        questionAnswer: newQusetionAns,
        addToTestQuestions: res,
      };
    }

    case GET_QUIZ_DETAILS_SUCCESS:
      return {
        ...state,
        section: action.payload,
      };

    default:
      return state;
  }
};

export default extractQuestionReducer;
