import axios from "axios";
import { BASE_URL, POSTGRES_URL } from "../assets/js/config";
import authHeader from "./auth-header";

export const getAllQuestionsService = async (filter) => {
  let query_arr = [];
  for (let filter_key in filter) {
    const value = filter[filter_key];
    if (value !== null && value !== undefined && value !== "") {
      query_arr.push(`${filter_key}=${value}`);
    }
  }
  const query_str = query_arr.join("&");
  console.log("checking service", authHeader())
  return await axios
    .get(`${BASE_URL}api/question?${query_str}`, {
      headers: { ...authHeader() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const addQuestionService = async (data) => {
  return await axios
    .post(
      `${BASE_URL}api/question`,
      { questions: data },
      {
        headers: { ...authHeader() },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const updateQuestionService = (data) => {
  const { updated_value, id } = data;

  return axios
    .put(BASE_URL + `api/question/${id}`, updated_value, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const deleteQuestionService = ({ id }) => {
  return axios
    .delete(`${BASE_URL}api/question/${id}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const checkIfSchemaExists = ({ testId }) => {
  return axios
    .get(`${POSTGRES_URL}api/psql/user-schema/?testId=${testId}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err.response, err);
      throw err?.response?.data;
    });
};
export const getAllTables = () => {
  return axios
    .get(`${POSTGRES_URL}api/psql/schema-tables`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};
export const createSchema = ({ testId, type }) => {
  return axios
    .post(
      `${POSTGRES_URL}api/psql/user-schema/${testId}`,
      { type },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      console.log("services", res);
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const createAdminSchema = () => {
  return axios
    .post(
      `${POSTGRES_URL}api/psql/test-user-schema/`,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const runQuery = ({ query, testId }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${POSTGRES_URL}api/psql/run-query`,
        { query, testId },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);

        // throw err.response.data;
      });
  });
};

export const fetchAllTagsService = (query) => {
  return axios
    .get(`${BASE_URL}api/tag?name=${query}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const fetchTableHeaders = ({ tableName }) => {
  return axios
    .get(`${POSTGRES_URL}api/psql/get-table-headers/?table=${tableName}`, {
      headers: authHeader(),
    })
    .then((res) => {
      // console.log(res.data.data , "line169 api");
      return res.data.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

export const insertData = ({ table, info }) => {
  // console.log(info, table, "line175 api");
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${POSTGRES_URL}api/psql/insert-data`,
        { table, info },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);

        // throw err.response.data;
      });
  });
};
export const createTag = (data) => {
  return axios
    .post(`${BASE_URL}api/tag/`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};
