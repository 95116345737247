import {
  FETCH_HR_GRAPH_STATS_SUCCESS,
  FETCH_HR_QUICK_STATS_SUCCESS,
  FETCH_HR_SUMMARY_STATS_SUCCESS,
} from "../actionTypes";
import {
  FETCH_HR_GRAPH_STATS_FAILURE,
  FETCH_HR_GRAPH_STATS_REQUESTED,
} from "../actionTypes/hrdashboard";

const initialState = {
  quick_stats: [],
  graph_stats: [],
  summary: {},
  loading: false,
  graph_data: {
    // labels: [0.75, 1.25, 1.75, 2.25],
    datasets: [
      {
        label: "x-axis",
        data: [],
        borderWidth: 1,
        // borderColor: "black",
        backgroundColor: "#B8FB3C",
        barPercentage: 0.8,
        categoryPercentage: 1,
        // hoverBackgroundColor: "red"
      },
    ],
  },
  graph_options: {
    responsive: true,
    scales: {
      x: {
        beginAtZero: false,
        type: "linear",
        ticks: {
          stepSize: 0.1,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 5,
        },
      },
    },
  },
};

const hrdashboard = function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case FETCH_HR_QUICK_STATS_SUCCESS:
      return {
        ...state,
        quick_stats: [],
      };

    case FETCH_HR_GRAPH_STATS_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case FETCH_HR_GRAPH_STATS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case FETCH_HR_GRAPH_STATS_SUCCESS:
      let g_data = { ...action.payload.bins };
      var result = Object.keys(g_data).map((key) => {
        return { x: Number(key), y: g_data[key] };
      });
      return {
        ...state,
        graph_stats: { ...action.payload },
        loading: false,
        graph_data: {
          datasets: [
            {
              label: "x-axis",
              data: [...result],
              borderWidth: 1,
              // borderColor: "black",
              backgroundColor: "#B8FB3C",
              barPercentage: 0.8,
              categoryPercentage: 1,
              // hoverBackgroundColor: "red"
            },
          ],
        },
      };

    case FETCH_HR_SUMMARY_STATS_SUCCESS:
      return {
        ...state,
        summary: { ...action.payload },
      };

    default:
      return state;
  }
};

export default hrdashboard;
