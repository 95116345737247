export const FETCH_TEST_ANALYTICS_SCORE = "FETCH_TEST_ANALYTICS_SCORE";

export const FETCH_TEST_ANALYTICS_DURATION = "FETCH_TEST_ANALYTICS_DURATION";

export const FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION =
  "FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION";

export const FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION =
  "FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION";

// ON SUCCESS AND FAILURE
export const FETCH_TEST_ANALYTICS_SCORE_FAILURE =
  "FETCH_TEST_ANALYTICS_SCORE_FAILURE";

export const FETCH_TEST_ANALYTICS_SCORE_SUCCESS =
  "FETCH_TEST_ANALYTICS_SCORE_SUCCESS";

export const FETCH_TEST_ANALYTICS_DURATION_FAILURE =
  "FETCH_TEST_ANALYTICS_DURATION_FAILURE";

export const FETCH_TEST_ANALYTICS_DURATION_SUCCESS =
  "FETCH_TEST_ANALYTICS_DURATION_SUCCESS";

export const FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION_FAILURE =
  "FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION_FAILURE";

export const FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION_SUCCESS =
  "FETCH_TEST_ANALYTICS_SCORE_DISTRIBUTION_SUCCESS";

export const FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION_FAILURE =
  "FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION_FAILURE";

export const FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION_SUCCESS =
  "FETCH_TEST_ANALYTICS_DURATION_DISTRIBUTION_SUCCESS";
